/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextInputWithErrorComponent from "../../components/common/text-input/text-input-with-error.component";
import { isEmptyObject, rupeeConverter } from "../../global/function";
// import "./payment.style.scss";

export default function PaymentDetailsComponent({ paymentType }) {
	// const [paymentType, setPaymentType] = useState(1);
	const { bookingPayment, eventPayment } = useSelector(
		(state) => state.payment
	);
	const [totalAmount, setTotalAmount] = useState(0);
	const [totalPaid, setTotalPaid] = useState(0);
	const [totalRemaining, setTotalRemaining] = useState(0);
	const [totalAdvRemaining, setTotalAdvRemaining] = useState(0);

	const [totalBill, setTotalBill] = useState(0);
	const [totalVat, setTotalVat] = useState(0);
	const [totalDiscount, setTotalDiscount] = useState(0);

	useEffect(() => {
		if (paymentType === "booking") {
			if (!isEmptyObject(bookingPayment)) {
				setTotalAmount(
					rupeeConverter(bookingPayment.totalBookingCost.toFixed(2))
				);
				setTotalPaid(
					rupeeConverter(
						bookingPayment.totalBookingPayment.toFixed(2)
					)
				);
				setTotalRemaining(
					rupeeConverter(bookingPayment.totalBalance.toFixed(2))
				);
				setTotalAdvRemaining(
					rupeeConverter(
						(
							bookingPayment.totalBookingCost -
							bookingPayment.totalBookingPayment
						).toFixed(2)
					)
				);
				setTotalBill(
					rupeeConverter(bookingPayment.totalBillAmount.toFixed(2))
				);
				setTotalVat(
					rupeeConverter(bookingPayment.vatAmount.toFixed(2))
				);
				setTotalDiscount(
					rupeeConverter(
						bookingPayment.totalBookingDiscount.toFixed(2)
					)
				);
			}
		} else if (paymentType === "event") {
			setTotalAmount(
				rupeeConverter(eventPayment.eventTotalCost.toFixed(2))
			);
			setTotalPaid(
				rupeeConverter(eventPayment.eventTotalPayment.toFixed(2))
			);
			setTotalRemaining(
				rupeeConverter(
					eventPayment.eventTotalPaymentRemaining.toFixed(2)
				)
			);
			setTotalBill(
				rupeeConverter(eventPayment.eventTotalBillAmount.toFixed(2))
			);
			setTotalVat(rupeeConverter(eventPayment.eventTotalVat.toFixed(2)));
			setTotalDiscount(
				rupeeConverter(eventPayment.eventTotalDiscount.toFixed(2))
			);
		}
	}, [paymentType, bookingPayment, eventPayment]);

	return (
		<div className="main-inner-container ">
			<div className="inner-content rows ">
				<TextInputWithErrorComponent
					variant="form-lg"
					label="Total Bill"
					value={totalBill}
					disabled
				/>
			</div>
			<div className="inner-content rows ">
				<TextInputWithErrorComponent
					variant="form-md"
					label="Total Cost"
					value={totalAmount}
					disabled
				/>
				<TextInputWithErrorComponent
					variant="form-md"
					label="VAT Amount"
					value={totalVat}
					disabled
				/>
			</div>
			<div className="inner-content rows ">
				<TextInputWithErrorComponent
					variant="form-md"
					label="Payments"
					value={totalPaid}
					disabled
				/>
				<TextInputWithErrorComponent
					variant="form-md"
					label="Discount"
					value={totalDiscount}
					disabled
				/>
			</div>
			<div className="inner-content rows ">
				<TextInputWithErrorComponent
					variant="form-md"
					label="Adv. Balance"
					value={totalAdvRemaining}
					disabled
				/>
				<TextInputWithErrorComponent
					variant="form-md"
					label="Balance"
					value={totalRemaining}
					disabled
				/>
			</div>
		</div>
	);
}

/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
	fetchSearchCustomer,
	fetchCustomer,
	fetchCustomerRequestSuccess,
} from "../../redux/actions/customer-setting.action";
import { useDispatch } from "react-redux";
// import SearchClient from "./search.component";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
// import strings from "../../global/strings";

function SearchCustomerComponent({ type, handleAdd }) {
	const dispatch = useDispatch();

	const [searchCustomer, setCustomerSearch] = useState({
		customer_id: "",
		customer_fname: "",
		customer_lname: "",
		customer_mobile: "",
		customer_email: "",
	});

	const customerSearch = (e) => {
		setCustomerSearch({
			...searchCustomer,
			[e.target.name]: e.target.value,
		});
	};
	const _handleSearch = async () => {
		if (
			searchCustomer.customer_id ||
			searchCustomer.customer_fname ||
			searchCustomer.customer_lname ||
			searchCustomer.customer_mobile ||
			searchCustomer.customer_email
		) {
			await dispatch(fetchSearchCustomer(searchCustomer));
			// setCustomerList(searchCustomerList);
		}
	};
	const handleClearSearch = () => {
		setCustomerSearch({
			customer_id: "",
			customer_fname: "",
			customer_lname: "",
			customer_mobile: "",
			customer_email: "",
		});
		if (type === "calendar") {
			dispatch(fetchCustomerRequestSuccess());
		} else {
			dispatch(fetchCustomer());
		}
	};
	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Customer</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Enter Customer ID"
					label="Customer ID"
					type="number"
					name="customer_id"
					value={searchCustomer.customer_id}
					onChange={(e) => customerSearch(e)}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Enter First Name"
					label="First Name"
					name="customer_fname"
					value={searchCustomer.customer_fname}
					onChange={(e) => customerSearch(e)}
				/>

				<SimpleTextInput
					variant="form-md edit"
					placeholder="Enter Last Name"
					label="Last Name"
					name="customer_lname"
					value={searchCustomer.customer_lname}
					onChange={(e) => customerSearch(e)}
				/>

				<SimpleTextInput
					variant="form-lg edit"
					placeholder="Enter Mobile Number"
					label="Mobile Number"
					name="customer_mobile"
					type="number"
					value={searchCustomer.customer_mobile}
					onChange={(e) => customerSearch(e)}
				/>

				<SimpleTextInput
					variant="form-lg edit"
					placeholder="Enter Email"
					label="Email"
					name="customer_email"
					value={searchCustomer.customer_email}
					onChange={(e) => customerSearch(e)}
					type="email"
				/>
			</div>
			<div className="flex gap-x-15px justify-between">
				<div>
					<button className="button-primary" onClick={handleAdd}>
						Add Customer
					</button>
				</div>
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={handleClearSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={_handleSearch}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}
export default SearchCustomerComponent;

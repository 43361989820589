//import * as actions from '../action-types'
import {
	FETCH_EMPLOYEE_REQUEST,
	FETCH_EMPLOYEE_SUCCESS,
	FETCH_EMPLOYEE_ERROR,
	FETCH_EMPLOYEE_SUCCESS_REQUEST,
	FETCH_USER_INFO_SUCCESS,
	FETCH_DUPLICATE_EMPLOYEE_ERROR,
	FETCH_DELETE_EMPLOYEE_SUCCESS,
} from "../action-types";

const initial_state = {
	isLoading: false,
	employees: [],
	userInfo: {},
	duplicateUser: {},
	empToAdd: {},
	error: "",
	deletedEmployee: "",
};
export default function employeeSettingReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_EMPLOYEE_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
				deletedEmployee: "",
			};
		case FETCH_EMPLOYEE_SUCCESS_REQUEST:
			return {
				...state,
				isLoading: false,
				error: "",
				deletedEmployee: "",
			};
		case FETCH_EMPLOYEE_SUCCESS:
			return {
				...state,
				isLoading: false,
				employees: action.payload,
				error: "",
				duplicateUser: {},
				empToAdd: {},
			};
		case FETCH_USER_INFO_SUCCESS:
			return {
				...state,
				isLoading: false,
				userInfo: action.payload,
			};
		case FETCH_EMPLOYEE_ERROR:
			return {
				...state,
				isLoading: false,
				employees: state.employees,
				error: action.payload,
			};
		case FETCH_DUPLICATE_EMPLOYEE_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload.message,
				duplicateUser: action.payload.user,
				empToAdd: action.payload.addedEmp,
			};
		case FETCH_DELETE_EMPLOYEE_SUCCESS:
			return {
				...state,
				isLoading: false,
				deletedEmployee: action.payload,
			};
		default:
			return state;
	}
}

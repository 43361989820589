import React, { useEffect, useState } from "react";
import MenuSvg from "../../../assets/images/icons/menu svg/menu-svg";
import "./menu-item-container.style.scss";

export default function MenuItemContainer({
	item,
	addOnClick,
	type,
	fromBooking = false,
}) {
	const [menuInfo, setMenuInfo] = useState({});

	useEffect(() => {
		if (item.menu_category) {
			setMenuInfo(item);
		} else {
			setMenuInfo(item.menu);
		}
	}, [item]);

	function addClick() {
		if (fromBooking && type === "selected") {
			//(True)removing selected in Booking menu add
			addOnClick(item, true);
		} else addOnClick(item);
	}

	return (
		<div
			className={`menu item-container bg-default ${type}`}
			onClick={addClick}
		>
			<div
				className={`item-icon bg-default ${menuInfo.item_type} ${type}`}
			>
				<MenuSvg
					name={menuInfo.item_type}
					type={
						type === "selected"
							? "selected"
							: type === "saved"
							? "selected"
							: "unselected"
					}
				/>
			</div>
			<div className="item-name capitalize">{menuInfo.menu_item}</div>
			<div className="item-cartilage">
				{menuInfo.menu_cartilage === "WB" && (
					<i className="fas fa-bone"></i>
				)}
			</div>
			<div className="item-price">Rs. {item.menu_price}</div>
			<div className="item-type capitalize">{menuInfo.item_type}</div>
		</div>
	);
}

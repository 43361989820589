import {
	FETCH_COMPANY_SUBSCRIPTION_REQUEST,
	FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
	ADD_COMPANY_SUBSCRIPTION,
	UPDATE_COMPANY_SUBSCRIPTION,
	DELETE_COMPANY_SUBSCRIPTION,
	FETCH_COMPANY_SUBSCRIPTION_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
	latestSubscription: {},
	subscriptions: [],
	error: "",
};
export default function catermeSubscriptionReducer(
	state = initial_state,
	action
) {
	switch (action.type) {
		case FETCH_COMPANY_SUBSCRIPTION_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ADD_COMPANY_SUBSCRIPTION:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_COMPANY_SUBSCRIPTION:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_COMPANY_SUBSCRIPTION:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_COMPANY_SUBSCRIPTION_SUCCESS:
			return {
				isLoading: false,
				latestSubscription: action.payload.latestSubscription,
				subscriptions: action.payload.subscriptions,
				error: "",
			};
		case FETCH_COMPANY_SUBSCRIPTION_ERROR:
			return {
				isLoading: false,
				subscriptions: state.subscriptions,
				latestSubscription: state.latestSubscription,
				error: action.payload,
			};

		default:
			return state;
	}
}

import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import "./open-shop.style.scss";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const OpenShopModal = (props) => {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};
	const [values, setValues] = useState(Array(6).fill(""));
	const ref = useRef(null);
	const [focused, setFocused] = useState(0);

	useEffect(() => {
		setValues(Array(6).fill(""));
	}, [props.login]);

	//focus next after input and reset check states
	const _handleChange = (e) => {
		let currentId = parseInt(e.target.id);
		let tempArray = values;
		tempArray[currentId] = e.target.value;
		if (e.target.value !== "") {
			if (currentId !== 5) {
				ref.current.children[currentId + 1].focus();
			}
		} else {
			if (currentId !== 0) {
				ref.current.children[currentId - 1].focus();
			}
		}
		setValues(tempArray);
	};

	const _handleSubmit = () => {
		let pin = values.join("");
		if (pin.length === 6) {
			props.loginSubmit({ ...props.login, pin });
		}
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Open Shop</div>
				</div>
				<div className="openshop">
					<i className="fas fa-store-slash"></i>
				</div>
				<div className="openshop" ref={ref}>
					{values.map((val, index) => (
						<input
							className="inputbox"
							key={index}
							id={index}
							type={focused === index ? "text" : "password"}
							placeholder={""}
							defaultValue={val}
							maxLength={1}
							onFocus={(e) => {
								e.target.select();
								setFocused(parseInt(index));
							}}
							onChange={_handleChange}
						></input>
					))}
				</div>
				<div className="open-shop-buttons">
					<button
						className="button-primary"
						onClick={props.handleClose}
					>
						Cancel
					</button>
					<button className="button-primary" onClick={_handleSubmit}>
						Submit
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default OpenShopModal;

import {
	FETCH_PAYMENT_REQUEST,
	FETCH_EVENT_PAYMENT_SUCCESS,
	FETCH_BOOKING_PAYMENT_SUCCESS,
	FETCH_REFUND_PAYMENT_SUCCESS,
	FETCH_PAYMENT_ERROR,
	FETCH_PAYMENT_HISTORY_SUCCESS,
	UPDATE_BOOKING_PAYMENT_SELECTION_STATUS,
	FETCH_PAYMENT_REQUEST_SUCCESS,
	FETCH_VAT_HISTORY_SUCCESS,
	// sanat
	SET_PAYMENT_SEARCH_PARAMETERS,
	SET_VAT_SEARCH_PARAMETERS,
} from "../action-types";

import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const setPaymentSearchParameters = (paymentSearchParameters) => {
	return {
		type: SET_PAYMENT_SEARCH_PARAMETERS,
		payload: paymentSearchParameters,
	};
};

export const setVatSearchParameters = (vatSearchParameters) => {
	return {
		type: SET_VAT_SEARCH_PARAMETERS,
		payload: vatSearchParameters,
	};
};

export const fetchPaymentRequest = () => {
	return {
		type: FETCH_PAYMENT_REQUEST,
	};
};
export const fetchPaymentRequestSuccess = () => {
	return {
		type: FETCH_PAYMENT_REQUEST_SUCCESS,
	};
};

export const fetchEventPaymentSuccess = (payment, paymentFor) => {
	return {
		type: FETCH_EVENT_PAYMENT_SUCCESS,
		payload: payment,
		paymentFor,
	};
};

export const fetchRefundPaymentSuccess = (success) => {
	return {
		type: FETCH_REFUND_PAYMENT_SUCCESS,
		payload: success,
	};
};

export const fetchPaymentHistorySuccess = (paymentList) => {
	return {
		type: FETCH_PAYMENT_HISTORY_SUCCESS,
		payload: paymentList,
	};
};

export const fetchBookingPaymentSuccess = (payment, paymentFor) => {
	return {
		type: FETCH_BOOKING_PAYMENT_SUCCESS,
		payload: payment,
		paymentFor,
	};
};
export const updateBookingPaymentSelectionStatus = (
	bookingInfoId,
	value,
	paymentFor
) => {
	return {
		type: UPDATE_BOOKING_PAYMENT_SELECTION_STATUS,
		payload: { bookingInfoId, value, paymentFor },
	};
};

export const fetchPaymentError = (error) => {
	return {
		type: FETCH_PAYMENT_ERROR,
		payload: error,
	};
};

export const fetchVatHistorySuccess = (vat) => {
	return {
		type: FETCH_VAT_HISTORY_SUCCESS,
		payload: vat,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchPaymentError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchPaymentError(error.message));
	}
}

export const fetchPayment = (payment_info_id) => {
	return async (dispatch) => {
		// 	dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}payment/id?payment_info_id=${payment_info_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					// await dispatch(fetchEventPaymentSuccess(res.data.result));
					return res.data.result;
				} else {
					// await dispatch(fetchPaymentError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/* action for payment of an event [all bookings] */

export const fetchEventPayment = (event_info_id, paymentFor) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}eventInfo/payment/info?event_info_id=${event_info_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(
						fetchEventPaymentSuccess(res.data.result, paymentFor)
					);
					return res.data;
				} else {
					await dispatch(fetchPaymentError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchBookingPayment = (booking_info_id, paymentFor) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/payment/info?booking_info_id=${booking_info_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(
						fetchBookingPaymentSuccess(res.data.result, paymentFor)
					);
					//paradise special
					return res.data.result;
				} else {
					await dispatch(fetchPaymentError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const initiatePayment = (paymentDetails) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}payment/add`, paymentDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					dispatch(fetchPaymentRequestSuccess());
					showToastMessage(response.data.message, "S");
					return response.data;
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// paradise special
export const addQuickPayment = (paymentDetails) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}payment/quick/add`, paymentDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					dispatch(fetchPaymentRequestSuccess());
					showToastMessage(response.data.message, "S");
					return true;
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
// paradise special

export const getPaymentHistory = (limit, page) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}payment/all?limit=${limit}&page=${page}`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchPaymentHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/*  */
export const recordVatDetails = (vatDetails) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}vat/add`, vatDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					dispatch(fetchPaymentRequestSuccess());
					showToastMessage(response.data.message, "S");
					return response.data;
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/* search payment history */
export const searchPaymentHistory = (limit, page, searchInfo) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());

		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}payment/history?payment_id=${searchInfo.payment_id}&booking_id=${searchInfo.booking_id}&event_id=${searchInfo.event_id}
			&date_from=${searchInfo.date_from}&date_to=${searchInfo.date_to}
			&customer_fname=${searchInfo.customer_fname}&customer_lname=${searchInfo.customer_lname}
			&payment_type=${searchInfo.payment_type}&transaction_type=${searchInfo.transaction_type}&venue_info_id=${searchInfo.venue_info_id}
			&limit=${limit}&page=${page}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(setPaymentSearchParameters(searchInfo));

					await dispatch(
						fetchPaymentHistorySuccess(response.data.result)
					);
					return response.data.result;
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/* refund payment */
export const refundPayment = (refundPayment) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}payment/refund/add`, refundPayment, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					dispatch(fetchRefundPaymentSuccess(response.data.message));
					showToastMessage(response.data.message, "S");
					// return response.data;
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/*  */
export const editPayment = (paymentInfo) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}payment/update`, paymentInfo, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(getPaymentHistory(10, 1));
					showToastMessage(res.data.message, "S");
				} else {
					await dispatch(fetchPaymentError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/* vat history */
export const getVatHistory = (limit, page) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}vat/company?limit=${limit}&page=${page}`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchVatHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchVatHistory = (limit, page, searchInfo) => {
	return async (dispatch) => {
		dispatch(fetchPaymentRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}vat/history?vat_payment_id=${searchInfo.vat_payment_id}&vat_bill_number=${searchInfo.vat_bill_number}&event_id=${searchInfo.event_id}
			&date_from=${searchInfo.date_from}&date_to=${searchInfo.date_to}
			&customer_fname=${searchInfo.customer_fname}&customer_lname=${searchInfo.customer_lname}
			&limit=${limit}&page=${page}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(setVatSearchParameters(searchInfo));

					await dispatch(
						fetchVatHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteVat = (id, bookings) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}payment/vat?vat_payment_info_id=${id}&limit=10&page=1`,
				bookings,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					// dispatch(getVatHistory(10, 1));
					await dispatch(
						fetchVatHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deletePayment = (id, venue_info_id, booking_info_id, limit, page) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}payment/amount?payment_info_id=${id}&booking_info_id=${booking_info_id}&venue_info_id=${venue_info_id}&limit=${limit}&page=${page}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage("Payment Information Deleted", "S");
					await dispatch(
						fetchPaymentHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchPaymentError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const sendPaymentEmail = (paymentDetails) => {
	let dataCleanUp = {
		company_email: paymentDetails.company_email,
		customer_email: paymentDetails.customer_email,
		status: "Received",
		booked_date_english: paymentDetails.booked_date_english,
		booked_date_nepali: paymentDetails.booked_date_nepali,
		book_time: paymentDetails.book_time,
		customer_name: paymentDetails.customer_name,
		paid_amount: paymentDetails.paid_amount,
		payment_type: paymentDetails.payment_type,
	};
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}payment/sendEmail`, dataCleanUp, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

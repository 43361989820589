import React, { useEffect } from "react";
import "./booking-smv-form.style.scss";

// form handle
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// global strings and functions
import strings from "../../../global/strings";
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";
import { getMonthlyDateRange } from "../../../global/function";

// components
import FormTextInputComponent from "../../common/text-input/form-text-input.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import FormTextareaInputComponent from "../../common/text-input/form-textarea-input.component";

// redux
import { useDispatch } from "react-redux";
import { updateBookingSideModal } from "../../../redux/actions/booking-action";
import { fetchBookingByDateRange } from "../../../redux/actions/calendar-info.action";

const bookingSchema = yup.object().shape({
	e_pax: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	e_rate: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	booking_type: yup.string().required("Booking Type Required"),
	booking_catering: yup.string().required("Catering Type Required"),
	booking_notes: yup.string(),
});

export default function BookingDetailsForm({
	booking,
	closeAccordion,
	modalFrom,
	onAfterSuccessCall,
}) {
	const dispatch = useDispatch();

	const { register, handleSubmit, reset, errors } = useForm({
		mode: "onSubmit",
		defaultValues: {},
		resolver: yupResolver(bookingSchema),
	});

	useEffect(() => {
		reset({
			e_pax: booking.e_pax,
			e_rate: booking.e_rate,
			booking_type: booking.booking_type,
			booking_catering: booking.booking_catering,
			booking_notes: booking.booking_notes,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [booking]);

	const handleBookingUpdate = async (updatedBooking) => {
		let newUpdatedBooking = { ...booking, ...updatedBooking };

		const resData = await dispatch(
			updateBookingSideModal(newUpdatedBooking, "details")
		);

		if (resData.success) {
			// if (modalFrom === "monthlyBooking") {
			// 	let newViewDateObj = new NibjarNepaliDate(
			// 		new Date(newUpdatedBooking.booked_date_english)
			// 	).getBS();
			// 	const dateRange = getMonthlyDateRange(newViewDateObj);
			// 	await dispatch(fetchBookingByDateRange(dateRange));
			// }
			closeAccordion();
		}
		onAfterSuccessCall(newUpdatedBooking.booked_date_english);
	};
	return (
		<div className="booking-smv-form-container">
			<div className="bsmv-form-row">
				<div className="bsmv-form-row">
					<FormTextInputComponent
						variant="bsmv-form-input"
						inputvariant="bsmv-form-input-field"
						label="Estd. Pax"
						name="e_pax"
						ref={register}
						error={errors?.e_pax?.message}
						type="number"
					/>
					<FormTextInputComponent
						variant="bsmv-form-input"
						inputvariant="bsmv-form-input-field"
						label="Estd. Rate"
						name="e_rate"
						ref={register}
						error={errors?.e_rate?.message}
						type="number"
					/>
				</div>
				<div className="bsmv-form-row">
					<FormSelectBox
						variant="bsmv-form-input"
						label="Booking Type*"
						inputvariant="bsmv-form-input-field"
						options={strings.eventType}
						name="booking_type"
						ref={register}
						error={errors?.booking_type?.message}
					/>
					<FormSelectBox
						variant="bsmv-form-input"
						label="Catering Type*"
						inputvariant="bsmv-form-input-field"
						options={strings.cateringType}
						name="booking_catering"
						ref={register}
						error={errors?.booking_catering?.message}
					/>
				</div>
			</div>

			<div className="bsmv-form-row">
				<FormTextareaInputComponent
					// label="Terms & Condition"
					variant="bsmv-form-input bsmv-form-textarea"
					name="booking_notes"
					ref={register}
					placeholder="Notes"
				/>
			</div>

			<div className="bsmv-form-buttons">
				<button
					className="bsmv-button"
					onClick={handleSubmit(handleBookingUpdate)}
				>
					Update Booking
				</button>
			</div>
		</div>
	);
}

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "./invite-employee-modal.style.scss";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-modal";
// form set up
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { isEmptyObject, sessionInfo } from "../../global/function";
import { toast } from "react-toastify";
import strings from "../../global/strings";
import {
	saveEmployee,
	updateEmpDetails,
} from "../../redux/actions/employee-setting.action";

const employeeSchema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	// pan_number: yup.string().required("required"),
	user_mobile: yup.string().required("required"),
	company_role: yup.string().required("required"),
});

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function InviteEmployeeModal(props) {
	const dispatch = useDispatch();
	const { userInfo, empToAdd } = useSelector(
		(state) => state.employeeSetting
	);
	const { venueDropDownList } = useSelector((s) => s.venue);

	const { register, handleSubmit, control, errors, reset, watch } = useForm({
		defaultValues: {
			venue_employee: [
				{
					venue_info_id: "",
					venue_role: "",
				},
			],
		},
		mode: "onSubmit",
		resolver: yupResolver(employeeSchema),
	});
	const { fields, remove, prepend } = useFieldArray({
		control,
		name: "venue_employee",
	});

	const [venueRole, setVenueRole] = useState([strings.venueRole]);
	const companyRoleValue = watch(["company_role"]);

	const roleLevels = {
		viewer: 0,
		manager: 1,
		admin: 2,
	};

	useEffect(() => {
		if (!isEmptyObject(empToAdd)) {
			reset(empToAdd);
		} else {
			reset({
				venue_employee: [
					{
						venue_info_id: "",
						venue_role: "",
					},
				],
			});
		}
	}, [empToAdd, reset]);

	useEffect(() => {
		if (props.actionType === "Update") {
			let ven = [
				{
					venue_info_id: "",
					venue_role: "",
				},
			];
			let userDetails = userInfo?.userData;

			let companyRole =
				companyRoleValue.company_role ||
				userDetails?.company_employees[0]?.company_role;

			switch (companyRole) {
				case "admin":
					setVenueRole(strings.adminRole);
					break;
				case "manager":
					setVenueRole(strings.employeeRole);
					break;
				case "viewer":
					setVenueRole(strings.viewerRole);
					break;

				default:
					break;
			}

			reset({
				first_name: userDetails?.first_name,
				last_name: userDetails?.last_name,
				company_role: userDetails?.company_employees[0]?.company_role,
				user_email: userDetails?.user_email,
				user_mobile: userDetails?.user_mobile,
				pan_number: userDetails?.company_employees[0].pan_number,
				venue_employee:
					userDetails?.company_employees[0].venue_employees.length ===
					0
						? ven
						: userDetails?.company_employees[0].venue_employees,
			});
		}
	}, [props, reset, userInfo]);

	const _handleVenueRole = () => {
		switch (companyRoleValue.company_role) {
			case "admin":
				setVenueRole(strings.adminRole);
				break;
			case "manager":
				setVenueRole(strings.employeeRole);
				break;
			case "viewer":
				setVenueRole(strings.viewerRole);
				break;
		}

		if (props.actionType === "Add") {
			reset({
				venue_employee: [
					{
						venue_info_id: "",
						venue_role: "",
					},
				],
			});
		} else {
			let venueEmp =
				userInfo?.userData?.company_employees[0]?.venue_employees.map(
					(ve) => {
						return {
							...ve,
							venue_role: "",
						};
					}
				);

			reset({
				venue_employee: venueEmp,
			});
		}
	};

	async function addEmployee(data) {
		let sessionData = await sessionInfo();
		// console.log(
		// 	roleLevels[sessionData.company_role],
		// 	roleLevels[userInfo?.userData?.company_employees[0]?.company_role],
		// 	props.actionType === "Add"
		// );
		//condition to limit updates to roles below current user only
		if (
			roleLevels[sessionData.company_role] >
				roleLevels[
					userInfo?.userData?.company_employees[0]?.company_role
				] ||
			sessionData.company_role === "admin" ||
			props.actionType === "Add"
		) {
			data.venue_employee = data.venue_employee.filter(
				(venue) => venue.venue_info_id !== "" && venue.venue_role
			);

			let newEmployee = {
				...data,
				user_status: "inactive",
				password: data.user_mobile,
				company_info_id: sessionData.company_info_id,
			};

			const venueIdArray = newEmployee.venue_employee.map((venue) => {
				return venue.venue_info_id;
			});

			const duplicateVenue = venueIdArray.some((ven, idx) => {
				return venueIdArray.indexOf(ven) !== idx;
			});

			if (
				!Array.isArray(newEmployee.venue_employee) ||
				!newEmployee.venue_employee.length
			) {
				return toast.error("Error! Assign at least one venue");
			} else if (duplicateVenue) {
				return toast.error("Error! Duplicate venue for same user");
			}
			//deleting empty venue emp id and status so that it adds value instead of updating
			newEmployee.venue_employee.map((venue) => {
				if (venue.venue_employee_id === "") {
					delete venue.venue_employee_id;
					delete venue.venue_employee_status;
				}
			});

			if (props.actionType === "Add") {
				await dispatch(saveEmployee(newEmployee));
			} else {
				data["user_info_id"] = userInfo.userData.user_info_id;
				data["company_info_id"] = sessionData.company_info_id;
				data["company_employee_id"] =
					userInfo.userData.company_employees[0].company_employee_id;

				await dispatch(updateEmpDetails(data));
			}
			props.handleClose();
			reset();
		} else {
			return toast.error("No permission to make changes");
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">
						{props.actionType} Employee
					</div>
				</div>

				<div className="form-body">
					<div className={`form-input-container ${props.actionType}`}>
						<FormTextInput
							label="First Name*"
							placeholder="Enter First Name"
							variant="form-lg"
							className=" text-input"
							type="text"
							ref={register}
							name="first_name"
							error={errors?.first_name?.message}
						/>

						<FormTextInput
							label="Last Name*"
							placeholder="Enter Last Name"
							variant="form-lg"
							className=" text-input"
							type="text"
							ref={register}
							name="last_name"
							error={errors?.last_name?.message}
						/>
					</div>

					<div className={`form-input-container ${props.actionType}`}>
						<FormTextInput
							placeholder="Enter Email"
							label="Email*"
							variant="modal-lg"
							className="text-input"
							type="email"
							ref={register}
							name="user_email"
							error={errors?.user_email?.message}
						/>
						<FormTextInput
							placeholder="Enter Mobile"
							label="Mobile*"
							variant="modal-lg"
							className="text-input"
							type="number"
							ref={register}
							name="user_mobile"
							error={errors?.user_mobile?.message}
						/>
					</div>

					{/* {props.actionType === "Update" && ( */}
					<div className="form-input-container">
						<FormSelectBox
							label="Company Role*"
							variant="form-sm"
							options={strings.companyRole}
							ref={register}
							name="company_role"
							error={errors?.company_role?.message}
							onChange={_handleVenueRole}
						/>
					</div>
					{/* )} */}

					<div className="form-description-title">Grant Access</div>

					<div className="form-input-container">
						<div className="venue-role-container">
							<div className="dropdown-title-container">
								<div>Venue</div>
								<div style={{ marginLeft: "117px" }}>Role</div>
							</div>
							{fields.map((item, index) => {
								return (
									<div key={item.id}>
										<div
											style={{
												display: "none",
												backgroundColor: "green",
											}}
											key={index}
										>
											<FormTextInput
												variant="form-lg"
												ref={register}
												name={`venue_employee[${index}].venue_employee_status`}
												defaultValue={
													item.venue_employee_status
												}
											/>
											<FormTextInput
												variant="form-lg"
												ref={register}
												name={`venue_employee[${index}].venue_employee_id`}
												defaultValue={
													item.venue_employee_id
												}
											/>
										</div>

										<div className="venue-role-content">
											<FormSelectBox
												variant="venue-name"
												name={`venue_employee[${index}].venue_info_id`}
												defaultValue={`${item?.venue_info?.venue_info_id}`}
												options={venueDropDownList}
												ref={register()}
											/>
											<FormSelectBox
												variant="venue-role"
												name={`venue_employee[${index}].venue_role`}
												defaultValue={item.venue_role}
												ref={register()}
												options={venueRole}
											/>

											{index === 0 ? (
												<button
													className="add-venue-permission-view-btn button-wrapper"
													onClick={() => {
														prepend({
															venue_info_id: "",
															venue_role: "",
														});
													}}
												>
													<span>
														<i className="fas fa-plus"></i>
													</span>
												</button>
											) : (
												<button
													className="add-venue-permission-view-btn button-wrapper"
													onClick={() =>
														remove(index)
													}
												>
													<span>
														<i className="fas fa-minus"></i>
													</span>
												</button>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div className="form-footer two">
					<button
						onClick={() => {
							reset({
								venue_employee: [
									{
										venue_info_id: "",
										venue_role: "",
									},
								],
							});
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>

					<button
						type="submit"
						onClick={handleSubmit(addEmployee)}
						className="button-primary"
					>
						{props.actionType}
					</button>
				</div>
			</div>
		</Modal>
	);
}

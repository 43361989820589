/* eslint-disable array-callback-return */
import {
	FETCH_REQUEST_REQUEST,
	FETCH_REQUEST_SUCCESS,
	FETCH_SINGLE_REQUEST_SUCCESS,
	CHANGE_REQUEST,
	FETCH_REQUEST_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
	requestList: [],
	requestItem: { request_list: {}, requested_by: [] },
	error: "",
};
export default function requestReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_REQUEST_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				requestList: action.payload,
			};

		case FETCH_SINGLE_REQUEST_SUCCESS:
			let requestList = action.payload.request_list;
			Object.keys(requestList).map((cat) => {
				requestList[cat].map((list) => {
					list.request_change_quantity = 0;
					list.old_req_qty = list.requested_quantity;
					list.requested_quantity = list.requested_quantity;
				});
			});

			return {
				...state,
				isLoading: false,
				requestItem: { ...action.payload, request_list: requestList },
			};

		case FETCH_REQUEST_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		case CHANGE_REQUEST:
			let reqItem = state.requestItem.request_list;
			let index = action.payload.index;
			let value = action.payload.value;
			let key = action.payload.key;
			let field = action.payload.field;
			let selectedState = reqItem[key][index];
			let newState = {};
			if (field === "qty") {
				if (value < 0 || isNaN(value) || value === "") {
					newState = {
						...selectedState,
						error: "Invalid",
					};
				} else {
					newState = {
						...selectedState,
						requested_quantity: parseInt(value),
						request_change_quantity:
							value - selectedState.old_req_qty,
						error: "",
					};
				}
			} else if (field === "status") {
				newState = {
					...selectedState,
					item_status: value,
				};
			}

			reqItem[key].splice(index, 1, newState);
			return {
				...state,
				reqItem: reqItem,
			};

		default:
			return state;
	}
}

import React, { forwardRef } from "react";
import "./text-input.style.scss";

function TextInputWithError(props, ref) {
	return (
		<div className={`text-input-container ${props.variant}`}>
			{props.label && (
				<label
					htmlFor={`${props.label}`}
					className="input-label text-fontDark"
				>
					{props.label}
				</label>
			)}
			<input
				{...props}
				ref={ref}
				className={`${props.variant} text-input`}
			/>
			<span className="validation-error">{props.error}</span>
		</div>
	);
}

export default forwardRef(TextInputWithError);

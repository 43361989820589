import {
	FETCH_EMPLOYEE_REQUEST,
	FETCH_EMPLOYEE_SUCCESS,
	FETCH_EMPLOYEE_ERROR,
	FETCH_EMPLOYEE_SUCCESS_REQUEST,
	FETCH_USER_INFO_SUCCESS,
	FETCH_DUPLICATE_EMPLOYEE_ERROR,
	FETCH_DELETE_EMPLOYEE_SUCCESS,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError, logout } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchEmployeeRequest = () => {
	return {
		type: FETCH_EMPLOYEE_REQUEST,
	};
};

export const fetchEmployeeSuccessRequest = () => {
	return {
		type: FETCH_EMPLOYEE_SUCCESS_REQUEST,
	};
};

export const fetchEmployeeSuccess = (employees) => {
	return {
		type: FETCH_EMPLOYEE_SUCCESS,
		payload: employees,
	};
};

export const fetchEmployeeError = (error) => {
	return {
		type: FETCH_EMPLOYEE_ERROR,
		payload: error,
	};
};

export const fetchDuplicateEmployeeError = (error) => {
	return {
		type: FETCH_DUPLICATE_EMPLOYEE_ERROR,
		payload: {
			addedEmp: error.addedEmp,
			message: error.message,
			user: error.user,
		},
	};
};

const fetchUserInfoSuccess = (info) => {
	return {
		type: FETCH_USER_INFO_SUCCESS,
		payload: info,
	};
};

export const fetchDeleteEmployeeSuccess = (response) => {
	return {
		type: FETCH_DELETE_EMPLOYEE_SUCCESS,
		payload: response,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchEmployeeError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchEmployeeError(error.message));
	}
}

export const companyEmployeeInfo = (company_employee_id) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}user/employee/id?company_employee_id=${company_employee_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					return response.data.result;
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchEmployees = () => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}user?company_info_id=${sessionData.company_info_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchEmployeeSuccess(res.data.result));
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchSearchEmployee = (employee) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		var url = `${strings.url}user/searchBy?company_info_id=${sessionData.company_info_id}&user_id=${employee.user_id}&first_name=${employee.first_name}&last_name=${employee.last_name}&user_email=${employee.user_email}&user_mobile=${employee.user_mobile}&venue_info_id=${employee.venue_info_id}&role=${employee.employee_role}`;
		axios
			.get(url, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchEmployeeSuccess(res.data.result));
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const saveEmployee = (val) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}user/add`, val, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchEmployees());
				} else {
					showToastMessage(response.data.message, "E");
					if (response.data.message === "Error! Duplicate User") {
						let duplicateUser = {
							...val,
							user_info_id:
								response.data.duplicateUser.user_info_id,
							company_role: val.company_role,
						};
						await dispatch(
							fetchDuplicateEmployeeError({
								addedEmp: duplicateUser,
								message: response.data.message,
								user: response.data.duplicateUser,
							})
						);
					} else {
						await dispatch(
							fetchEmployeeError(response.data.message)
						);
					}
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const saveDuplicateEmployeeToCompany = () => {
	return async (dispatch, getState) => {
		const { empToAdd } = getState().employeeSetting;
		const sessionData = await sessionInfo();

		dispatch(fetchEmployeeRequest());
		return axios
			.post(`${strings.url}user/add/duplicate`, empToAdd, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					showToastMessage(res.data.message, "S");
					await dispatch(fetchEmployees());
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getMyDetails = (userId) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}user/id?user_info_id=${userId}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchUserInfoSuccess(res.data.result));

					/* changing user image only if updated user is logged in user */
					if (
						sessionData.user_info_id ===
						res.data.result.userData.user_info_id
					) {
						localStorage.setItem(
							"user_image",
							res.data.result.userData.user_image
						);
					}
				} else {
					await dispatch(fetchEmployeeError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateEmpDetails = (empDetails) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}user/update`, empDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					// await dispatch(fetchEmployeeSuccessRequest());
					showToastMessage("Details Successfully Updated", "S");
					// await dispatch(getMyDetails(empDetails.user_info_id));
					await dispatch(fetchUserInfoSuccess(response.data.result));
					// return response.data.result;
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const changeEmployeeVenueStatus = (empDetails) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}user/venue/employee/update`, empDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					// await dispatch(fetchEmployeeSuccessRequest());
					showToastMessage("Details Successfully Updated", "S");
					// await dispatch(getMyDetails(empDetails.user_info_id));
					await dispatch(fetchUserInfoSuccess(response.data.result));
					// return response.data.result;
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const resendEmailVerification = (val) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		axios
			.post(`${strings.url}user/resend/verification/email`, val, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchEmployeeSuccessRequest());
					showToastMessage("Verification Email Sent", "S");
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteEmployee = (companyCustomerId, companyId) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}user/delete?company_employee_id=${companyCustomerId}&company_info_id=${companyId}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchEmployeeSuccess(response.data.result));
					await dispatch(
						fetchDeleteEmployeeSuccess(response.data.message)
					);
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const logoutEmployee = (user_info_id) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(`${strings.url}user/logout?user_info_id=${user_info_id}`, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					dispatch(fetchEmployeeSuccessRequest());
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const transferOwnership = (details) => {
	return async (dispatch) => {
		dispatch(fetchEmployeeRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}user/transfer/ownership`, details, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchEmployeeSuccessRequest());
					await dispatch(logout());
					// return response.data.result;
				} else {
					await dispatch(fetchEmployeeError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

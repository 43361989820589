import React, { useEffect } from "react";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import FormSelectInput from "../../components/common/select-box/form-select-input.component";
import strings from "../../global/strings";
import { fetchVenue } from "../../redux/actions/venue-action";
import { useSelector, useDispatch } from "react-redux";
import {
	addInventories,
	fetchCategoriesByLocationId,
} from "../../redux/actions/inventory.action";
import { useHistory } from "react-router-dom";
import showToastMessage from "../../global/showToastMessage";
import Loader from "../../components/loader/loader.component";

const itemSchema = yup.object().shape({
	location_id: yup.string().required("required"),
	inventories: yup.array().of(
		yup.object().shape({
			item_name: yup.string().required("required"),
			unit: yup.string().required("Unit required"),
			total_quantity: yup
				.number()
				.typeError("Must be a number")
				.min(0, "Min value 0.")
				.transform((value) => (value ? value : 0))
				.when("rate", {
					is: (value) => value > 0,
					then: yup
						.number()
						.required()
						.typeError("Must be a number")
						.min(0, "Min value 0.")
						.transform((value) => (value ? value : 0)),
				}),
			rate: yup
				.number()
				.typeError("Must be a number")
				.min(0, "Min value 0.")
				.transform((value) => (value ? value : 0)),
			min_quantity: yup
				.number()
				.typeError("Must be a number")
				.min(0, "Min value 0.")
				.transform((value) => (value ? value : 0)),
		})
	),
});

function AddInventoryItem() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { dropdownCat, isLoading } = useSelector((s) => s.inventory);

	const { register, errors, control, handleSubmit } = useForm({
		defaultValues: { inventories: [{}] },
		mode: "onSubmit",
		resolver: yupResolver(itemSchema),
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "inventories",
	});

	useEffect(() => {
		dispatch(fetchVenue());
		dispatch(
			fetchCategoriesByLocationId(localStorage.getItem("location_id"))
		);
	}, [dispatch]);

	const _saveInventory = async (data) => {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}
		data.category_set = [];
		if (data?.inventories) {
			let status = await dispatch(addInventories(data));
			if (status) {
				_back();
			}
		} else {
			showToastMessage("Please add inventory item", "W");
		}
	};

	const _back = () => {
		history.push({
			pathname: "/inventory",
			state: {
				tab: 1,
			},
		});
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => _back()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt;{" "}
					<span className="current-page-path">
						Add Bulk Inventory
					</span>
				</div>
			</div>
			<div className="inner-container full mt-52px items-end">
				<button className="button-primary" onClick={append}>
					Add
				</button>
				<FormTextInput
					variant="form-md hidden"
					name={`location_id`}
					ref={register}
					defaultValue={localStorage.getItem("location_id")}
				/>
			</div>
			<div className="inner-container full">
				<table className="table-container">
					<thead>
						<tr>
							<td className="table-header h-2/8">
								<div className="table-header-container">
									Item Name
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Brand Name
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Unit
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Category
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Sub Type
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Quantity
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Rate
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Min Quantity
								</div>
							</td>
							<td
								className="table-header "
								style={{ width: "3.5%" }}
							></td>
						</tr>
					</thead>
					<tbody>
						{fields.map((item, i) => (
							<tr key={item.id}>
								<td>
									<FormTextInput
										type="text"
										placeholder="Item Name"
										variant="form-md"
										name={`inventories[${i}].item_name`}
										ref={register()}
										error={
											errors?.inventories?.[i]?.item_name
												?.message
										}
									/>
								</td>

								<td>
									<FormTextInput
										placeholder="Brand Name"
										variant="form-md"
										type="text"
										name={`inventories[${i}].item_description.brand`}
										ref={register()}
										error={
											errors?.inventories?.[i]
												?.item_description.brand
												?.message
										}
									/>
								</td>
								<td>
									<FormSelectInput
										variant="form-sm"
										options={strings.unitList}
										name={`inventories[${i}].unit`}
										ref={register()}
										type="Unit"
										error={
											errors?.inventories?.[i]?.unit
												?.message
										}
									/>
								</td>
								<td>
									<FormSelectInput
										variant="form-sm"
										options={dropdownCat}
										name={`inventories[${i}].item_category`}
										ref={register()}
										type="Category"
										error={
											errors?.inventories?.[i]
												?.item_category?.message
										}
									/>
								</td>
								<td>
									<FormTextInput
										placeholder="Enter Sub Type"
										variant="form-md"
										type="text"
										name={`inventories[${i}].item_description.sub_type`}
										ref={register()}
										error={
											errors?.inventories?.[i]
												?.item_description.sub_type
												?.message
										}
									/>
								</td>
								<td>
									<FormTextInput
										placeholder="Enter Quantity"
										variant="form-md"
										type="number"
										name={`inventories[${i}].total_quantity`}
										ref={register()}
										error={
											errors?.inventories?.[i]
												?.total_quantity?.message
										}
									/>
								</td>
								<td>
									<FormTextInput
										placeholder="Enter Rate"
										variant="form-md"
										type="number"
										name={`inventories[${i}].rate`}
										ref={register()}
										error={
											errors?.inventories?.[i]?.rate
												?.message
										}
									/>
								</td>
								<td>
									<FormTextInput
										placeholder="Enter Minimum"
										variant="form-md"
										type="number"
										name={`inventories[${i}].min_quantity`}
										ref={register()}
										error={
											errors?.inventories?.[i]
												?.min_quantity?.message
										}
									/>
								</td>
								<td className="">
									<button
										className="button-primary-icon"
										onClick={() => remove(i)}
									>
										<i className="fas fa-trash"></i>
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="inner-container full flex items-end">
				<button
					className="button-primary"
					onClick={handleSubmit(_saveInventory)}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default AddInventoryItem;

import React from "react";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(Tooltip, Legend, ArcElement);

function DoughnutGraph({
	dataValues,
	dataLabel,
	colors,
	doughnutStyle,
	tooltip = false,
}) {
	const data = {
		labels: dataLabel,
		datasets: [
			{
				label: "test",
				data: dataValues,
				backgroundColor: colors,
				hoverBackgroundColor: colors,
				borderColor: "#f8f8fc", // Set the stroke color
			},
		],
	};
	const donutOptions = {
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: tooltip,
			},
		},
		responsive: true,
		cutout: "60%",
	};
	return (
		<Doughnut
			type="doughnut"
			data={data}
			options={donutOptions}
			style={doughnutStyle}
		/>
	);
}

export default DoughnutGraph;

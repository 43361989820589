import React from "react";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import { useForm } from "react-hook-form";
import SelectBox from "../common/select-box/form-select-input.component";
import { useDispatch } from "react-redux";
import strings from "../../global/strings";
import {
	clearVendorOnEdit,
	fetchVendors,
	searchVendor,
} from "../../redux/actions/vendor.action";

export default function SearchVendorComponent({ addVendorClick }) {
	const dispatch = useDispatch();
	const { register, handleSubmit, reset } = useForm();

	const _clearVendorSearch = () => {
		reset({
			vendor_code: "",
			vendor_name: "",
			vendor_status: "",
			contact_number: "",
		});
		dispatch(fetchVendors());
	};

	function _searchVendor(data) {
		dispatch(searchVendor(data));
	}
	const _addVendor = () => {
		addVendorClick();
		dispatch(clearVendorOnEdit());
	};

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Vendor</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-lg hidden"
					name="location_id"
					ref={register}
					defaultValue={localStorage.getItem("location_id")}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Vendor ID"
					placeholder="Enter Vendor ID"
					type="text"
					name="vendor_code"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-lg edit"
					label="Vendor Name"
					placeholder="Enter Vendor Name"
					type="text"
					name="vendor_name"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Vendor Contact Number"
					placeholder="Enter Vendor Contact"
					type="number"
					name="contact_number"
					ref={register}
				/>

				<SelectBox
					options={strings.vendorStatus}
					variant="search"
					label="Vendor Status"
					name="vendor_status"
					type="Vendor Status"
					ref={register}
				/>
			</div>
			<div className="flex gap-x-15px justify-between">
				<button className="button-primary" onClick={_addVendor}>
					Add Vendor
				</button>

				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearVendorSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={handleSubmit(_searchVendor)}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}

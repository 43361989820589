import {
	FETCH_ANALYTICS_REQUEST,
	FETCH_ANALYTICS_REQUEST_SUCCESS,
	FETCH_ANALYTICS_ERROR,
	FETCH_CUSTOMER_ANALYTICS_REQUEST,
	FETCH_CUSTOMER_ANALYTICS_REQUEST_SUCCESS,
	FETCH_CUSTOMER_ANALYTICS_ERROR,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";

import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchAnalyticsRequest = () => {
	return {
		type: FETCH_ANALYTICS_REQUEST,
	};
};

export const fetchCustomerAnalyticsRequest = () => {
	return {
		type: FETCH_CUSTOMER_ANALYTICS_REQUEST,
	};
};

export const fetchAnalyticsRequestSuccess = (analyticsData) => {
	return {
		type: FETCH_ANALYTICS_REQUEST_SUCCESS,
		payload: analyticsData,
	};
};

export const fetchCustomerAnalyticsRequestSuccess = (customerAnalyticsData) => {
	return {
		type: FETCH_CUSTOMER_ANALYTICS_REQUEST_SUCCESS,
		payload: customerAnalyticsData,
	};
};

export const fetchAnalyticsError = (error) => {
	return {
		type: FETCH_ANALYTICS_ERROR,
		payload: error,
	};
};

export const fetchCustomerAnalyticsError = (error) => {
	return {
		type: FETCH_CUSTOMER_ANALYTICS_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchAnalyticsError(error.response.data.message));
	} else if (status === 401) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchAnalyticsError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchAnalyticsError(error.message));
	}
}

export const getBookingAnalytics = (year, month, venue_info_id) => {
	let requestURL;

	requestURL = `${strings.url}analytics?start_date=${year}&end_date=${month}&venue_info_id=${venue_info_id}`;
	// if (year !== "" && month !== "") {
	// 	// requestURL = `${strings.url}analytics/booking/date?year=${year}&month=${month}&venue_info_id=${venue_info_id}`;
	// } else {
	// 	// requestURL = `${strings.url}analytics/booking/date?year=${year}&venue_info_id=${venue_info_id}`;
	// 	requestURL = `${strings.url}analytics/test?year=${year}&venue_info_id=${venue_info_id}`;
	// }

	return async (dispatch) => {
		const sessionData = await sessionInfo();

		dispatch(fetchAnalyticsRequest());
		return axios
			.get(`${requestURL}`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchAnalyticsRequestSuccess(response.data));
				} else {
					await dispatch(fetchAnalyticsError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getCustomerAnalytics = (company_customer_id) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		dispatch(fetchCustomerAnalyticsRequest());
		return axios
			.get(
				`${strings.url}analytics/booking/customer?company_customer_id=${company_customer_id}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchCustomerAnalyticsRequestSuccess(response.data)
					);
				} else {
					await dispatch(
						fetchCustomerAnalyticsError(response.data.message)
					);
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

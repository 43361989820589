import React, { useState, forwardRef } from "react";
import "./en-np-datepicker.style.scss";

// global function
import { fromAdToBs } from "../../../global/function";

// Nepali calendar components
import NepaliCalendarPopUp from "../calendar/nepali-cal-popup";

function EnNpDatePicker(props, ref) {
	const [isNepaliDatePicker, setIsNepaliDatePicker] = useState(false);
	return (
		<div className="en-np-input-wrapper">
			{props?.label && (
				<label className="en-np-input-label">{props.label}</label>
			)}
			<div className="en-np-input-date-wrapper">
				{isNepaliDatePicker ? (
					<NepaliCalendarPopUp
						type="en"
						dateSubmitFunction={(date) => {
							props.onChange(props.name, date);
						}}
						field={true}
						// min={props.min}
						date={props.value ? fromAdToBs(props.value) : ""}
					/>
				) : (
					<input
						{...props}
						ref={ref}
						className={`date-input ${props.variant}`}
						onChange={(e) => {
							props.onChange(props.name, e.target.value);
						}}
					/>
				)}

				<div className="date-type-options">
					<div
						className={`type-btn ${!isNepaliDatePicker && "en"}`}
						onClick={() => setIsNepaliDatePicker(false)}
					>
						Eng
					</div>
					<div
						className={`type-btn ${isNepaliDatePicker && "np"}`}
						onClick={() => setIsNepaliDatePicker(true)}
					>
						Nep
					</div>
				</div>
			</div>
			{props?.error && (
				<div className="validation-error">{props?.error}</div>
			)}
		</div>
	);
}

export default forwardRef(EnNpDatePicker);

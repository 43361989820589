import React, { useMemo } from "react";
import "./side-modal.style.scss";

// for date purpose
import moment from "moment";
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";

// global functions
import { isEmptyObject } from "../../../global/function";

// components
import BookingSideModalView from "../../booking-side-modal-view/booking-side-modal-view.component";

export default function SideModalBooking({
	isVisible,
	onModalClose,
	onAfterSuccessCall,
	containerStyle,
	title = "Modal Title",
	sideModalData, // should be array for uniformity and dynamicity
	modalType,
	onAddBooking = () => {},
}) {
	const hasBookings = useMemo(
		() => Array.isArray(sideModalData) && sideModalData.length > 0,
		[sideModalData]
	);
	const bookingDates = useMemo(() => {
		if (hasBookings) {
			return {
				en: sideModalData[0]?.booked_date_english,
				np: sideModalData[0]?.booked_date_nepali,
			};
		} else {
			return sideModalData; // this is an object containing dates en and np
		}
	}, [hasBookings, sideModalData]);

	return (
		<div className={`s-modal-wrapper ${isVisible ? "expanded" : ""}`}>
			<div className="s-modal_overlay" onClick={onModalClose}></div>
			<div className="s-modal-container" style={containerStyle}>
				<div className="s-modal-headder">
					<div className="s-modal-header-items">
						<div
							className="p-[10px] cursor-pointer"
							onClick={onModalClose}
						>
							<i className="fas fa-arrow-left"></i>
						</div>
						<div
							className="p-[10px] cursor-pointer"
							onClick={onModalClose}
						>
							{" "}
							{title}
						</div>
					</div>
					{modalType === "monthlyBooking" && (
						<div className="s-modal-header-items">
							<div
								className="header-action-button"
								onClick={() => onAddBooking(bookingDates.en)}
							>
								Check Availability
								<i className="fas fa-arrow-right"></i>
							</div>
						</div>
					)}
				</div>

				{hasBookings && (
					<div className="s-modal-body">
						{!isEmptyObject(bookingDates) && (
							<div className="flex items-center justify-between gap-[16px] sticky -top-[10px] p-[10px] mb-[10px] bg-fontWhite z-10">
								<div className="text-[1.3em] font-[500] text-primaryDark">
									{moment(bookingDates?.en).format(
										"DD MMMM, YYYY"
									)}
								</div>
								<div className="text-[1.2em] font-[600] text-[#9d9d9d]">
									{new NibjarNepaliDate(
										bookingDates?.np
									).format("DD MMMM, YYYY")}
								</div>
							</div>
						)}
						<div className="flex flex-col gap-24px">
							{sideModalData.map((booking, index) => {
								return (
									<BookingSideModalView
										key={index}
										modalFrom={modalType}
										booking={booking}
										onAfterSuccessCall={onAfterSuccessCall}
									/>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

import React, { useEffect } from "react";
import "./calendar-monthly-booking.style.scss";

// components
import Loader from "../../../components/loader/loader.component";
import CalendarController from "../calendar-controller/calendar-controller.component";
import MonthlyCalendar from "./monthly-calendar.component";

// for date purpose
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";
import { fromBsToAd, getMonthlyDateRange } from "../../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchBookingByDateRange } from "../../../redux/actions/calendar-info.action";

export default function CalendarMonthlyBooking({ onAddBooking }) {
	const calendarInfo = useSelector((state) => state.calendarInfo);

	const dispatch = useDispatch();

	useEffect(() => {
		async function getMonthlyBookings() {
			let currentNepDateObj = new NibjarNepaliDate(
				new Date(calendarInfo.currDate)
			).getBS();

			const dateRange = getMonthlyDateRange(currentNepDateObj);

			await dispatch(fetchBookingByDateRange(dateRange));
		}
		getMonthlyBookings();
	}, [dispatch]);

	return (
		<div className="inner-container full flex-1">
			{calendarInfo.isLoading && <Loader />}

			<CalendarController controlType="monthly" />

			<div className="monthly-calendar-wrapper">
				<MonthlyCalendar onAddBooking={onAddBooking} />
			</div>
		</div>
	);
}

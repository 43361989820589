/* eslint-disable array-callback-return */
import {
	SET_TEMP_BOOKING,
	SAVE_BOOKING,
	DELETE_BOOKING,
	SET_SELECTED_CUSTOMER,
	CLEAR_ALL_BOOKING_INFO,
} from "../action-types";

const initial_state = {
	savedBookings: [],
	selectedCustomer: {},
	bookingError: "",
};

export default function eventSaveReducer(state = initial_state, action) {
	switch (action.type) {
		case SET_TEMP_BOOKING:
			let val = action.payload;
			let selectedEventArray = state.savedBookings;
			let availableHallArray = [];

			val.halls.map((hall) => {
				availableHallArray.push({
					hall_info_id: hall.hall_info_id,
					hall_name: hall.hall_name,
				});
			});

			let bookingDetails = {
				tempBookingId: val.tempBookingId,
				bookedDay: val.day,
				availableHallArray,
				bookedVenue: val.venue,
				venue_info_id: val.venue_info_id,
				colorCode: val.colorCode,
				booking_description: "",
				booked_date_english: val.booked_date_english,
				booked_date_nepali: val.booked_date_nepali,
				booked_time: val.time,
				e_pax: 0,
				e_rate: 0,
				vat: 0,
				discount: 0,
				payments: "Pending",
				booking_type: "",
				booking_catering: "",
				booking_notes: "",
				booking_status: "inquiry",
				halls: [],
				saved: false,
			};

			let addedBooking = selectedEventArray.filter(
				(select) =>
					select.booked_date_english === val.booked_date_english &&
					select.bookedVenue === val.venue &&
					select.booked_time === val.time
			);
			if (addedBooking.length === 0) {
				return {
					savedBookings: [...selectedEventArray, bookingDetails],
					bookingError: "",
					selectedCustomer: state.selectedCustomer,
				};
			} else {
				return {
					savedBookings: [...selectedEventArray],
					bookingError:
						"This date is already selected for an event below",
					selectedCustomer: state.selectedCustomer,
				};
			}

		case SAVE_BOOKING:
			const savedBookings = [...state.savedBookings];
			let bookingIndex = savedBookings.findIndex(
				(booking) =>
					booking.tempBookingId === action.payload.tempBookingId
			);
			const selectedBooking = savedBookings[bookingIndex];
			const bookingToSave = {
				...selectedBooking,
				...action.payload,
			};
			savedBookings.splice(bookingIndex, 1, bookingToSave);
			return {
				savedBookings: savedBookings,
				selectedCustomer: state.selectedCustomer,
				bookingError: "",
			};

		case DELETE_BOOKING:
			let bookingId = action.payload;
			let unfilteredList = state.savedBookings.filter(
				(selected) => selected.tempBookingId !== bookingId
			);

			return {
				savedBookings: unfilteredList,
				selectedCustomer: state.selectedCustomer,
				bookingError: "",
			};
		case SET_SELECTED_CUSTOMER:
			return {
				savedBookings: state.savedBookings,
				bookingError: "",
				selectedCustomer: action.payload,
			};
		case CLEAR_ALL_BOOKING_INFO:
			return {
				savedBookings: [],
				bookingError: "",
				selectedCustomer: {},
			};

		default:
			return state;
	}
}

import React from "react";
import { useDispatch } from "react-redux";
import FormTextInputComponent from "../common/text-input/text-input-with-error.component";
import "../../components/booking/booking-summary/booking-summary.component.scss";
import { editOrder } from "../../redux/actions/order.action";

export default function UpdateOrderTable({ list, cat }) {
	const dispatch = useDispatch();
	return (
		<div>
			<table cellPadding={0} cellSpacing={0} className="table-container">
				<thead>
					<tr>
						<td className="table-header ">
							<div className="table-header-container">
								Item Name
							</div>
						</td>

						<td className="table-header ">
							<div className="table-header-container">Type</div>
						</td>

						<td className="table-header ">
							<div className="table-header-container">Brand</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Inventory
							</div>
						</td>
						<td className="table-header " style={{ width: "5%" }}>
							<div className="table-header-container">Unit</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Ordered
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">Rate</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								New Quantity
							</div>
						</td>
						<td className="table-header" style={{ width: "8%" }}>
							<div className="table-header-container">Status</div>
						</td>
					</tr>
				</thead>

				<tbody>
					{list.length > 0 &&
						list.map((list, key) => (
							<tr
								className="data-table-row inventory text-capitalize text-fontSemiDark"
								key={key}
							>
								<td>{list.item_name}</td>

								<td>{list?.item_description?.sub_type}</td>

								<td>{list?.item_description?.brand}</td>

								<td>-</td>
								<td>{list.unit}</td>
								<td>{list.old_order_qty}</td>
								<td>
									<FormTextInputComponent
										variant={
											list.item_status
												? "form-sm "
												: "form-sm edit"
										}
										type="number"
										value={list.ordered_price}
										onChange={(e) =>
											dispatch(
												editOrder(
													e.target.value,
													key,
													cat,
													"rate"
												)
											)
										}
										error={list.rate_error}
										disabled={list.item_status}
									/>
								</td>
								<td>
									<FormTextInputComponent
										variant={
											list.item_status
												? "form-sm "
												: "form-sm edit"
										}
										type="number"
										value={list.new_ordered_quantity}
										onChange={(e) =>
											dispatch(
												editOrder(
													e.target.value,
													key,
													cat,
													"qty"
												)
											)
										}
										error={list.qty_error}
										disabled={list.item_status}
									/>
								</td>
								<td>
									<div className="bg-inset payment-check-container">
										<span className="payment-balance-select-container">
											<input
												type="checkbox"
												className="payment-balance-checkbox"
												id={list._id}
												value={
													list.item_status
														? false
														: true
												}
												name="item_status"
												onChange={(e) =>
													dispatch(
														editOrder(
															!list.item_status,
															key,
															cat,
															"status"
														)
													)
												}
												checked={list.item_status}
											/>
											<label
												htmlFor={list._id}
												className="payment-balance-label"
											/>
										</span>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}

import {
    FETCH_COMPLETE_REGISTRATION_REQUEST,
    FETCH_COMPLETE_REGISTRATION_ERROR,
    FETCH_COMPLETE_REGISTRATION_SUCCESS,
} from "../action-types";
const initial_state = {
    isLoading: false,
    userInfo: {},
    companyInfo: {},
    venueInfo: {},
    error: "",
};

export default function completeRegistrationReducer(
    state = initial_state,
    action
) {
    switch (action.type) {
        case FETCH_COMPLETE_REGISTRATION_REQUEST:
            return { ...state, isLoading: true, error: "" };

        case FETCH_COMPLETE_REGISTRATION_SUCCESS:
            return { ...state, isLoading: false, error: "" };

        // case FETCH_COMPLETE_REGISTRATION_USER_SUCCESS:
        //     return{...state, isLoading:false, userInfo:action.payload,error:''}

        // case FETCH_COMPLETE_REGISTRATION_COMPANY_SUCCESS:
        //     return{...state, isLoading:false, companyInfo:action.payload,error:''}

        case FETCH_COMPLETE_REGISTRATION_ERROR:
            return { ...state, isLoading: false, error: action.payload };

        default:
            return state;
    }
}

import { FETCH_VENUE_SUCCESS } from "../action-types";

const initial_state = {
	isLoading: false,
	venueList: [],
	venueDropDownList: [],
	error: "",
};

export default function venueReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_VENUE_SUCCESS:
			return {
				isLoading: false,
				venueList: action.payload.venueInfo,
				venueDropDownList: action.payload.venueDropDown,
				error: "",
			};

		default:
			return state;
	}
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import SelectBox from "../common/select-box/form-select-input.component";
import { fetchVenue } from "../../redux/actions/venue-action";
import strings from "../../global/strings";
import { useForm } from "react-hook-form";
import { sessionInfo } from "../../global/function";

export default function SearchBookingHistoryComponent({
	resetSearch,
	searchBooking,
}) {
	const dispatch = useDispatch();

	const { venueDropDownList } = useSelector((s) => s.venue);
	const { bookingSearchParameters } = useSelector((state) => state.booking);

	const { register, handleSubmit, reset } = useForm();
	const [companyRole, setCompanyRole] = useState("");

	useEffect(() => {
		async function getRole() {
			await sessionInfo().then((res) => setCompanyRole(res.company_role));
		}
		if (companyRole === "") {
			getRole();
		}
		dispatch(fetchVenue());
		reset(bookingSearchParameters);
	}, [dispatch, bookingSearchParameters]);

	const _clearBookingHistorySearch = () => {
		reset({
			booking_id: "",
			date_from: "",
			date_to: "",
			customer_fname: "",
			customer_lname: "",
			venue_info_id: "",
			booking_type: "",
			booking_catering: "",
			booking_status: "",
			payment_status: "",
			vat_status: "",
		});
		resetSearch();
	};

	function _searchBookingHistory(data) {
		//role control to add unseen filter data
		if (companyRole === "viewer") {
			data["payment_status"] = "";
			data["vat_status"] = "";
		}
		searchBooking(data);
	}
	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Booking</label>
			<div className="flex my-10px gap-15px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Enter Booking ID"
					label="Booking ID"
					type="number"
					name="booking_id"
					ref={register}
				/>

				<SimpleTextInput
					type="date"
					variant="form-md edit"
					placeholder="Enter Date From"
					label="Date From"
					name="date_from"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					type="date"
					placeholder="Enter Date To"
					label="Date To"
					name="date_to"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Customer First Name"
					label="Customer First Name"
					name="customer_fname"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Customer Last Name"
					label="Customer Last Name"
					name="customer_lname"
					ref={register}
				/>
				<SelectBox
					variant="search"
					name="venue_info_id"
					options={venueDropDownList}
					ref={register}
					label="Venue"
				/>
				<SelectBox
					variant="search"
					options={strings.eventType}
					name="booking_type"
					ref={register}
					label="Event Type"
				/>

				<SelectBox
					variant="search"
					options={strings.cateringType}
					name="booking_catering"
					label="Catering Type"
					ref={register}
				/>
				<SelectBox
					variant="search"
					options={strings.bookingStatus}
					name="booking_status"
					label="Booking Status"
					ref={register}
				/>
				{companyRole !== "viewer" && (
					<>
						<SelectBox
							variant="search"
							options={strings.paymentStatus}
							name="payment_status"
							label="Payment Status"
							ref={register}
						/>
						<SelectBox
							variant="search"
							options={strings.vat_status}
							name="vat_status"
							label="Vat Status"
							ref={register}
						/>
					</>
				)}
			</div>
			<div className="flex gap-x-15px justify-end">
				<button
					className="button-primary-icon"
					onClick={_clearBookingHistorySearch}
				>
					<i className="fas fa-eraser"></i>
				</button>
				<button
					className="button-primary-icon"
					onClick={handleSubmit(_searchBookingHistory)}
				>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	);
}

import React, { useState, useEffect, useMemo, useCallback } from "react";
import dashImg from "../../assets/images/no-record.png";

// for date purpose
import moment from "moment";
import NibjarNepaliDate from "../../global/nibjar-nepali-date/nibjar-nepali-converter.js";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getBookingAnalytics } from "../../redux/actions/analytics.action.js";

// global functions and strings
import { currencyFormat, isEmptyObject } from "../../global/function";

import "../../assets/css/main.scss";
import "./analytics.style.scss";

// icons
import { bookingTypeMapper } from "../../assets/images/icons/iconList/icon-distributer.map.js";

// Components
import Loader from "../../components/loader/loader.component.jsx";
import ErrorPage from "../extra-page/extra.page";
import LineHistoryGraph from "./components/line-graph.component.jsx";
import DifferenceChart from "./components/difference-chart.component.jsx";
import ScatterGraph from "./components/scatter-graph.component.jsx";
import BlockGraph from "./components/block-graph.component.jsx";
import SideModalList from "../../components/modals/side-modal/side-modal-lists.modal.jsx";
import DoughnutGraph from "./components/doughnut-graph.component.jsx";
import AnalyticsSummaryText from "./components/analytics-summary-text.component.jsx";
import StackedBarChartWithGroups from "./components/stacked-bar-group.component.jsx";
import SelectInput from "../../components/common/select-box/select-input.component.jsx";

// charts
// import {
// 	Chart as ChartJS,
// 	CategoryScale,
// 	LinearScale,
// 	PointElement,
// 	LineElement,
// 	Title,
// 	Tooltip,
// 	Legend,
// } from "chart.js";
// import zoomPlugin from "chartjs-plugin-zoom";
// import { Doughnut, getElementAtEvent, Line } from "react-chartjs-2";

// import { createLogger } from "redux-logger";
// import GraphLine from "../../components/analytics-components/graph-line.component.jsx";
// import { data } from "jquery";

// ChartJS.register(
// 	CategoryScale,
// 	LinearScale,
// 	PointElement,
// 	LineElement,
// 	Title,
// 	Tooltip,
// 	Legend,
// 	zoomPlugin
// );

// export const options = {
// 	maintainAspectRatio: false,
// 	elements: {
// 		line: {
// 			tension: 0.1,
// 		},
// 	},
// 	responsive: true,
// 	plugins: {
// 		legend: {
// 			position: "top",
// 		},
// 		title: {
// 			display: true,
// 			text: "Booking Yearly",
// 		},
// 		zoom: {
// 			pan: {
// 				enabled: true,
// 				mode: "x",
// 				// threshold: 5,
// 			},
// 			zoom: {
// 				mode: "x",
// 				// overScaleMode: "x", //only zoom in x label
// 				wheel: {
// 					enabled: true,
// 					speed: 0.1,
// 				},
// 				pinch: {
// 					enabled: true,
// 				},
// 				// drag: {
// 				// 	enabled: true,
// 				// 	threshold: 150, // minimum zoom distance
// 				// 	backgroundColor: "#52356317",
// 				// 	borderColor: "#523563",
// 				// 	borderWidth: 1,
// 				// },
// 			},
// 		},
// 	},
// };

const month = [
	"",
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const nepMonth = [
	"",
	"Baisakh",
	"Jestha",
	"Ashad",
	"Shrawan",
	"Bhadra",
	"Ashoj",
	"Kartik",
	"Mangsir",
	"Poush",
	"Magh",
	"Falgun",
	"Chaitra",
];

export default function Analytics(props) {
	// const { venueDropDownList } = useSelector((s) => s.venue);
	const dispatch = useDispatch();

	const { isLoading, bookingAnalytics, error } = useSelector(
		(state) => state.analytics
	);

	const [currentView, setCurrentView] = useState({
		viewYear: new NibjarNepaliDate().getYear(),
		viewType: "yearly",
	});

	const [isOpen, setIsOpen] = useState(false);
	const [sideModalData, setSideModalData] = useState({});

	useEffect(() => {
		// Get the last date of the current year
		const endDate = moment().endOf("year").format("YYYY-MM-DD");

		// Get the first date of 5 years before the current year
		const startDate = moment()
			.subtract(2, "years")
			.startOf("year")
			.format("YYYY-MM-DD");

		dispatch(getBookingAnalytics(startDate, endDate, ""));
	}, [dispatch]);

	const graphAnalytics = useMemo(
		() =>
			!isEmptyObject(bookingAnalytics) &&
			bookingAnalytics?.graphData[currentView?.viewType],
		[bookingAnalytics, currentView?.viewType]
	);

	const computeRevenue = useCallback(
		(year) => {
			return (
				graphAnalytics[year]?.sumOfSales +
				graphAnalytics[year]?.past_sumOfExcessAmount +
				graphAnalytics[year]?.future_sumOfExcessAmount -
				graphAnalytics[year]?.sumOfOutOfPocket
			);
		},
		[graphAnalytics]
	);

	const DataStructurerView = useCallback(() => {
		let current = currentView.viewYear;
		let past = current - 1;
		if (currentView.viewType === "fiscal") {
			let temp = current.split(".");
			past = `${temp[0] - 1}.${temp[0]}`;
		}

		function computeChange(prevData, newData) {
			if (prevData === 0) {
				return null;
			} else {
				return (newData - prevData) / prevData;
			}
		}

		let structure = {
			...graphAnalytics[current],
			totalRevenue: computeRevenue(current),
			revGraph: {
				[current]: {
					monthlyRevenue: graphAnalytics[current].monthlyRevenue,
					monthlyBookingCount: graphAnalytics[current].monthlyBooking,
				},
			},
		};
		if (graphAnalytics.hasOwnProperty(`${past}`)) {
			let change_bookingCount = computeChange(
				graphAnalytics[past]?.past_bookingCount +
					graphAnalytics[past]?.future_bookingCount,
				graphAnalytics[current]?.past_bookingCount +
					graphAnalytics[current]?.future_bookingCount
			);

			let change_sumOfVat = computeChange(
				graphAnalytics[past]?.sumOfVat,
				graphAnalytics[current]?.sumOfVat
			);

			let change_totalRevenue = computeChange(
				computeRevenue(past),
				structure?.totalRevenue
			);

			let change_totalPaymentCollection = computeChange(
				graphAnalytics[past]?.sumOfCollectedRevenue,
				graphAnalytics[current]?.sumOfCollectedRevenue
			);

			let change_totalPending = computeChange(
				graphAnalytics[past]?.sumOfPendingRevenue,
				graphAnalytics[current]?.sumOfPendingRevenue
			);

			structure = {
				...structure,
				change_bookingCount,
				change_sumOfVat,
				change_totalRevenue,
				change_totalPaymentCollection,
				change_totalPending,
				revGraph: {
					[past]: {
						monthlyRevenue: graphAnalytics[past].monthlyRevenue,
						monthlyBookingCount:
							graphAnalytics[past].monthlyBooking,
					},
					...structure.revGraph,
				},
			};
		}
		return structure;
	}, [computeRevenue, currentView, graphAnalytics]);

	const DataStructurerOveralls = useCallback(() => {
		let years = Object.keys(graphAnalytics);

		let overalls = {
			overallBookingWithCost: 0,
			overallBookingWithoutCost: 0,
			overallBookingWithVat: 0,
			overallBooking: 0,

			// overall revs
			overallBookingSales: 0,
			overallBookingRevenue: 0,
			overallCollections: 0,

			overallVatDeclared: 0,

			overallOutOfPocket: 0,

			// overall pendings
			overallSalesBalance: 0,
			overallTaxBalance: 0,

			// yearly or fiscal yearly
			totalBookingSalesByYear: {},
			totalRevByYear: {},
			totalPendingsByYear: {},
			totalVatByYear: {},
			totalEstVatByYear: {},
			totalOutOfPocketByYear: {},

			trendRevGraph: {},
		};
		years.forEach((year) => {
			overalls.overallBookingWithCost +=
				graphAnalytics[year].bookingWithCost;
			overalls.overallBookingWithoutCost +=
				graphAnalytics[year].bookingWithoutCost;
			overalls.overallBookingWithVat +=
				graphAnalytics[year].bookingWithVat;
			overalls.overallBooking +=
				graphAnalytics[year].past_bookingCount +
				graphAnalytics[year].future_bookingCount;

			overalls.overallBookingSales += graphAnalytics[year].sumOfSales;
			overalls.overallBookingRevenue += computeRevenue(year);
			overalls.overallCollections +=
				graphAnalytics[year]?.sumOfCollectedRevenue;

			overalls.overallVatDeclared += graphAnalytics[year].sumOfVat;

			overalls.overallOutOfPocket +=
				graphAnalytics[year].sumOfOutOfPocket;

			overalls.overallSalesBalance +=
				graphAnalytics[year].sumOfSalesBalance;
			overalls.overallTaxBalance += graphAnalytics[year].sumOfTaxBalance;

			overalls.totalBookingSalesByYear[year] =
				graphAnalytics[year]?.sumOfSales;
			overalls.totalRevByYear[year] = computeRevenue(year);
			overalls.totalPendingsByYear[year] =
				graphAnalytics[year]?.sumOfPendingRevenue;
			overalls.totalVatByYear[year] = graphAnalytics[year]?.sumOfVat;
			overalls.totalEstVatByYear[year] =
				graphAnalytics[year]?.sumOfSales * 0.13;
			overalls.totalOutOfPocketByYear[year] =
				graphAnalytics[year]?.sumOfOutOfPocket;

			overalls.trendRevGraph[year] = {
				monthlyRevenue: graphAnalytics[year]?.monthlyRevenue,
				monthlyBookingCount: graphAnalytics[year]?.monthlyBooking,
			};
		});
		return overalls;
	}, [computeRevenue, graphAnalytics]);

	const structuredData = useMemo(
		() =>
			currentView?.viewYear !== "all" && !isEmptyObject(graphAnalytics)
				? DataStructurerView()
				: {},
		[graphAnalytics, currentView?.viewYear, DataStructurerView]
	);
	const overAlls = useMemo(
		() => (!isEmptyObject(graphAnalytics) ? DataStructurerOveralls() : {}),
		[DataStructurerOveralls, graphAnalytics]
	);

	function _renderAnalyticsFilter() {
		const fiscalYearArray = Object.keys(
			bookingAnalytics.graphData["fiscal"]
		).map((year) => {
			let parts = year.split(".");
			let formattedFiscalYear = parts[0] + "/" + parts[1];
			return {
				id: JSON.stringify({ viewYear: year, viewType: "fiscal" }),
				value: formattedFiscalYear,
			};
		});

		const yearArray = Object.keys(bookingAnalytics.graphData["yearly"]).map(
			(year) => ({
				id: JSON.stringify({ viewYear: year, viewType: "yearly" }),
				value: year,
			})
		);

		const overallArray = [
			{
				id: JSON.stringify({ viewYear: "all", viewType: "yearly" }),
				value: "Overall By Year",
			},
			{
				id: JSON.stringify({ viewYear: "all", viewType: "fiscal" }),
				value: "Overall By Fiscal Year",
			},
		];

		const combinedArr = [...fiscalYearArray, ...yearArray, ...overallArray];

		return (
			<div className="ana-filter-wrapper">
				<SelectInput
					name="filterYear"
					onChange={(e) => {
						let obj = JSON.parse(e.target.value);
						setCurrentView(obj);
					}}
					value={JSON.stringify(currentView)}
					options={combinedArr}
				/>
			</div>
		);
	}

	function _renderRevenueHighlightBlock() {
		let percentChange = structuredData?.change_totalRevenue
			? structuredData?.change_totalRevenue * 100
			: null;
		let excessAmountAvailable =
			structuredData.past_sumOfExcessAmount +
			structuredData.future_sumOfExcessAmount -
			structuredData.sumOfOutOfPocket;
		return (
			<div
				className="ana-highlight-block highlight-block"
				style={{
					background:
						"linear-gradient(140deg, #3498db 0%, #1c1222 100%)",
				}}
			>
				<div className="highlight-icon-container">
					<i className="fas fa-chart-line"></i>
				</div>
				<div className="highlight-details">
					<div className="highlight-main-value">
						<span>Rs. </span>{" "}
						{currencyFormat(structuredData.totalRevenue)}
					</div>
					<div className="highlight-main-label">
						Revenue this year
					</div>
					{percentChange && (
						<div className="highlight-change-data">
							{percentChange > 0 && (
								<i className="fas fa-arrow-circle-up"></i>
							)}
							{percentChange < 0 && (
								<i className="fas fa-arrow-circle-down"></i>
							)}
							<span>{Math.abs(percentChange)?.toFixed(2)}%</span>
						</div>
					)}
				</div>
				<div className="highlight-breakdowns">
					<div className="breakdown-container">
						<div className="breakdown-value">
							<span>Rs. </span>{" "}
							{currencyFormat(structuredData.sumOfSales)}
						</div>
						<div className="breakdown-label">Booking Sales</div>
					</div>
					<div className="breakdown-container">
						<div
							className="breakdown-value"
							style={{
								color:
									excessAmountAvailable < 0
										? "var(--pending)"
										: "",
							}}
						>
							<span>Rs. </span>{" "}
							{currencyFormat(excessAmountAvailable)}
						</div>
						<div className="breakdown-label">Excess Available</div>
					</div>
				</div>
			</div>
		);
	}

	function _renderSummaryBreakdowns() {
		let pastBookingPayments =
			structuredData.past_sumOfPayment -
			structuredData.sumOfRefund -
			structuredData.past_sumOfExcessAmount -
			structuredData.past_sumOfClientPaidTax;

		let futureBookingPayments =
			structuredData.future_sumOfPayment -
			structuredData.future_sumOfExcessAmount -
			structuredData.future_sumOfClientPaidTax;

		let pastExcessCollections =
			structuredData.past_sumOfExcessAmount +
			structuredData.past_sumOfClientPaidTax;

		let futureExcessCollections =
			structuredData.future_sumOfExcessAmount +
			structuredData.future_sumOfClientPaidTax;
		const dataPast = {
			labels: ["Former Bookings"],

			datasets: [
				{
					label: "Booking Payments",
					data: [pastBookingPayments],
					backgroundColor: "#52be7f",
					stack: "Stack 0",
				},
				{
					label: "Booking Pendings",
					data: [structuredData.past_sumOfSalesBalance],
					backgroundColor: "#FF877444",
					stack: "Stack 0",
				},
				{
					label: "Excess Collections",
					data: [pastExcessCollections],
					backgroundColor: "#3498db",
					stack: "Stack 1",
				},
			],
		};
		const dataFuture = {
			labels: ["Upcoming Bookings"],

			datasets: [
				{
					label: "Booking Payments",
					data: [futureBookingPayments],
					backgroundColor: "#52be7f",
					stack: "Stack 0",
				},
				{
					label: "Booking Pendings",
					data: [structuredData.future_sumOfSalesBalance],
					backgroundColor: "#FF877444",
					stack: "Stack 0",
				},
				{
					label: "Excess Collections",
					data: [futureExcessCollections],
					backgroundColor: "#3498db",
					stack: "Stack 1",
				},
			],
		};
		return (
			<div className="w-box ana-summary-block quarter-block">
				<div className="summary-block-header">
					Bookings Sales and Payments Breakdowns
				</div>
				<div className="summary-breakdown-wrapper">
					<div className="summary-breakdown-container">
						<div className="s-breakdown-chart">
							<StackedBarChartWithGroups
								data={dataPast}
								barStyle={{
									maxWidth: "100%",
									height: "180px",
									maxHeight: "180px",
								}}
							/>
							<div className="s-breakdown-main">
								<span>Rs. </span>
								{currencyFormat(structuredData.past_sumOfSales)}
							</div>
						</div>
						<div className="s-breakdown-details">
							<div className="data-sub-container">
								<div
									className="label-indicator"
									style={{
										backgroundColor: "#52be7f",
									}}
								/>
								<div className="data-col">
									<div className="data-sub-label">
										Booking Payments
									</div>
									<div className="data-sub-data">
										<span>Rs. </span>
										{currencyFormat(pastBookingPayments)}
									</div>
								</div>
							</div>
							<div className="data-sub-container">
								<div
									className="label-indicator"
									style={{
										backgroundColor: "#FF877444",
									}}
								/>
								<div className="data-col">
									<div className="data-sub-label">
										Pendings from Booking sales
									</div>
									<div className="data-sub-data">
										<span>Rs. </span>
										{currencyFormat(
											structuredData.past_sumOfSalesBalance
										)}
									</div>
								</div>
							</div>
							<div className="data-sub-container">
								<div
									className="label-indicator"
									style={{
										backgroundColor: "#3498db",
									}}
								/>
								<div className="data-col">
									<div className="data-sub-label">
										Excess Collections
									</div>
									<div className="data-sub-data">
										<span>Rs. </span>
										{currencyFormat(pastExcessCollections)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="summary-breakdown-container">
						<div className="s-breakdown-chart">
							<StackedBarChartWithGroups
								data={dataFuture}
								barStyle={{
									maxWidth: "100%",
									height: "180px",
									maxHeight: "180px",
								}}
							/>
							<div className="s-breakdown-main">
								<span>Rs. </span>
								{currencyFormat(
									structuredData.future_sumOfSales
								)}
							</div>
						</div>
						<div className="s-breakdown-details">
							<div className="data-sub-container">
								<div
									className="label-indicator"
									style={{
										backgroundColor: "#52be7f",
									}}
								/>
								<div className="data-col">
									<div className="data-sub-label">
										Advance Booking Payments
									</div>
									<div className="data-sub-data">
										<span>Rs. </span>
										{currencyFormat(futureBookingPayments)}
									</div>
								</div>
							</div>
							<div className="data-sub-container">
								<div
									className="label-indicator"
									style={{
										backgroundColor: "#FF877444",
									}}
								/>
								<div className="data-col">
									<div className="data-sub-label">
										Pendings from Future Bookings
									</div>
									<div className="data-sub-data">
										<span>Rs. </span>
										{currencyFormat(
											structuredData.future_sumOfSalesBalance
										)}
									</div>
								</div>
							</div>
							<div className="data-sub-container">
								<div
									className="label-indicator"
									style={{
										backgroundColor: "#3498db",
									}}
								/>
								<div className="data-col">
									<div className="data-sub-label">
										Advance Excess Collections
									</div>
									<div className="data-sub-data">
										<span>Rs. </span>
										{currencyFormat(
											futureExcessCollections
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	function _renderVATBreakdowns() {
		const vatBreakdowns = [
			{
				label: "Client Paid Tax",
				description: "This represents the amount paid by the clients.",
				color: "#523563",
				value:
					structuredData.past_sumOfClientPaidTax +
					structuredData.future_sumOfClientPaidTax,
			},
			{
				label: "Paid Out of Pocket",
				description: "This represents the amount paid by the company.",
				color: "#52356344",
				value: structuredData.sumOfOutOfPocket,
			},
			{
				label: "Tax Pendings",
				description:
					"This represents the amount that is declared, which may or may not be paid by the client.",
				color: "#FF877466",
				value: structuredData.sumOfTaxBalance,
			},
		];
		return (
			<div className="ana-vat-block ana-block w-box">
				<div className="vat-graph-container">
					{structuredData.sumOfVat > 0 ? (
						<DoughnutGraph
							colors={["#523563", "#52356344", "#FF877466"]}
							dataValues={[
								structuredData.past_sumOfClientPaidTax +
									structuredData.future_sumOfClientPaidTax,
								structuredData.sumOfOutOfPocket,
								structuredData.sumOfTaxBalance,
							]}
							dataLabel={[
								"Client Paid",
								"Out of Pocket",
								"Tax Pending",
							]}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={true}
						/>
					) : (
						<DoughnutGraph
							colors={["#e7e7e7"]}
							dataValues={[100]}
							dataLabel={[""]}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={false}
						/>
					)}
					<div className="vat-main-container">
						<div className="vat-main-data">
							<span>Rs. </span>
							{currencyFormat(structuredData.sumOfVat)}
						</div>
						<div className="vat-main-label">Total Declared VAT</div>
					</div>
				</div>
				<div className="vat-breakdowns-wrapper">
					{vatBreakdowns.map((vatData, i) => (
						<div className="vat-breakdown-container" key={i}>
							<div
								className="label-indicator"
								style={{
									backgroundColor: vatData.color,
								}}
							/>
							<div className="wrap-row">
								<div className="breakdown-label-container">
									<div className="breakdown-label">
										{vatData.label}
									</div>
									<div className="breakdown-description">
										{vatData.description}
									</div>
								</div>
								<div className="breakdown-data">
									<span>Rs. </span>
									{currencyFormat(vatData.value)}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	function _renderPendingsHighlightBlock() {
		return (
			<div
				className="ana-highlight-block highlight-block"
				style={{
					background:
						"linear-gradient(140deg, #ff8774 0%, #693a41 55%, #1c1222 100%)",
				}}
			>
				<div className="highlight-icon-container">
					<i className="fas fa-search-dollar"></i>
				</div>
				<div className="highlight-details">
					<div className="highlight-main-value">
						<span>Rs. </span>{" "}
						{currencyFormat(
							overAlls.overallSalesBalance +
								overAlls.overallTaxBalance
						)}
					</div>
					<div className="highlight-main-label">
						Total Receivables
					</div>
				</div>
				<div className="highlight-breakdowns">
					<div className="breakdown-container">
						<div className="breakdown-value">
							<span>Rs. </span>{" "}
							{currencyFormat(overAlls.overallSalesBalance)}
						</div>
						<div className="breakdown-label">
							Receivalbles from Bookings
						</div>
					</div>
					<div className="breakdown-container">
						<div className="breakdown-value">
							<span>Rs. </span>{" "}
							{currencyFormat(overAlls.overallTaxBalance)}
						</div>
						<div className="breakdown-label">
							Receivables to cover declared tax
						</div>
					</div>
				</div>
			</div>
		);
	}

	function _renderPendingByYear() {
		const colors = [
			"#FF877499",
			"#FF8774",
			"#c8685d",
			"#924f4f",
			"#733f4b",
			"#522a3a",
			"#1c1222",
		];
		return (
			<div className="ana-vat-block ana-block w-box">
				<div className="vat-graph-container">
					{overAlls.overallSalesBalance + overAlls.overallTaxBalance >
					0 ? (
						<DoughnutGraph
							colors={colors}
							dataValues={Object.values(
								overAlls.totalPendingsByYear
							)}
							dataLabel={Object.keys(
								overAlls.totalPendingsByYear
							)}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={true}
						/>
					) : (
						<DoughnutGraph
							colors={["#e7e7e7"]}
							dataValues={[100]}
							dataLabel={[""]}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={false}
						/>
					)}
					<div className="vat-main-container">
						<div className="vat-main-data">
							<span>Rs. </span>
							{currencyFormat(
								overAlls.overallSalesBalance +
									overAlls.overallTaxBalance
							)}
						</div>
						<div className="vat-main-label">
							Overall Payment Pendings
						</div>
					</div>
				</div>
				<div className="vat-breakdowns-wrapper">
					{Object.keys(overAlls.totalPendingsByYear).map(
						(pendingYear, i) => (
							<div className="vat-breakdown-container" key={i}>
								<div
									className="label-indicator"
									style={{
										backgroundColor: colors[i],
									}}
								/>
								<div className="wrap-row noWrap">
									<div className="breakdown-label light">
										{pendingYear}
									</div>
									<div className="breakdown-data">
										<span>Rs. </span>
										{currencyFormat(
											overAlls.totalPendingsByYear[
												pendingYear
											]
										)}
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		);
	}

	function _renderVatByYear() {
		const colors = ["#ae7dd4", "#7b48a2", "#523563", "#352041", "#1c1222"];
		return (
			<div className="ana-vat-block ana-block w-box">
				<div className="vat-graph-container">
					{overAlls.overallVatDeclared > 0 ? (
						<DoughnutGraph
							colors={colors}
							dataValues={Object.values(overAlls.totalVatByYear)}
							dataLabel={Object.keys(overAlls.totalVatByYear)}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={true}
						/>
					) : (
						<DoughnutGraph
							colors={["#e7e7e7"]}
							dataValues={[100]}
							dataLabel={[""]}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={false}
						/>
					)}
					<div className="vat-main-container">
						<div className="vat-main-data">
							<span>Rs. </span>
							{currencyFormat(overAlls.overallVatDeclared)}
						</div>
						<div className="vat-main-label">
							Overall VAT Declared
						</div>
					</div>
				</div>
				<div className="vat-breakdowns-wrapper">
					{Object.keys(overAlls.totalVatByYear).map(
						(declaredYear, i) => (
							<div className="vat-breakdown-container" key={i}>
								<div
									className="label-indicator"
									style={{
										backgroundColor: colors[i],
									}}
								/>
								<div className="wrap-row noWrap">
									<div className="breakdown-label light">
										{declaredYear}
									</div>
									<div className="breakdown-data">
										<span>Rs. </span>
										{currencyFormat(
											overAlls.totalVatByYear[
												declaredYear
											]
										)}
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		);
	}

	function _renderEstVatByYear() {
		const colors = ["#352041", "#523563", "#7b48a2", "#ae7dd4"];
		return (
			<div className="ana-vat-block ana-block w-box">
				<div className="vat-graph-container">
					{overAlls.overallBookingSales > 0 ? (
						<DoughnutGraph
							colors={colors}
							dataValues={Object.values(
								overAlls.totalEstVatByYear
							)}
							dataLabel={Object.keys(overAlls.totalEstVatByYear)}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={true}
						/>
					) : (
						<DoughnutGraph
							colors={["#e7e7e7"]}
							dataValues={[100]}
							dataLabel={[""]}
							doughnutStyle={{
								maxWidth: "160px",
								maxHeight: "160px",
							}}
							tooltip={false}
						/>
					)}
					<div className="vat-main-container">
						<div className="vat-main-data">
							<span>Rs. </span>
							{currencyFormat(
								overAlls.overallBookingSales * 0.13
							)}
						</div>
						<div className="vat-main-label">
							Overall Est-based VAT
						</div>
					</div>
				</div>
				<div className="vat-breakdowns-wrapper">
					{Object.keys(overAlls.totalEstVatByYear).map(
						(estYear, i) => (
							<div className="vat-breakdown-container" key={i}>
								<div
									className="label-indicator"
									style={{
										backgroundColor: colors[i],
									}}
								/>
								<div className="wrap-row noWrap">
									<div className="breakdown-label light">
										{estYear}
									</div>
									<div className="breakdown-data">
										<span>Rs. </span>
										{currencyFormat(
											overAlls.totalEstVatByYear[estYear]
										)}
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		);
	}

	if (error) {
		<ErrorPage accessDenied={true} message={error} />;
	}

	return (
		<div className="ana-wrapper">
			{isLoading && <Loader />}

			{!isEmptyObject(bookingAnalytics) && (
				<div className="ana-left-wrapper">
					<div className="ana-left-container">
						<div className="ana-section-container">
							<h5 className="section-title">
								Analytics Dashboard
							</h5>
							<p className="section-description">
								Welcome to the Analytics Dashboard—your hub for
								tracking operational, financial, and customer
								data.
							</p>
							<p className="section-description">
								Optimize catering business insights, manage
								finances, and tailor services. Generate custom
								reports effortlessly, guiding informed
								decisions. Unleash growth and success through
								data-driven precision.
							</p>
							<p className="section-description">
								Your business, elevated.
							</p>
						</div>

						{bookingAnalytics?.eventList.length > 0 && (
							<div
								className="ana-section-container ana-crm-container"
								style={{ overflow: "hidden" }}
							>
								<div className="section-title">
									Greet Your Customers!
								</div>
								<div className="crm-list-container">
									{bookingAnalytics?.eventList?.map(
										(event, i) => (
											<div className="crm-item" key={i}>
												<div className="item-icon">
													{bookingTypeMapper(
														event[0]
													)}
												</div>
												<div className="item-details">
													{event[2]}
												</div>
												<div className="item-days">
													{event[3]} Days
												</div>
											</div>
										)
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			<div className="ana-right-wrapper">
				{currentView?.viewYear === "all" ? (
					<>
						{!isEmptyObject(overAlls) && (
							<div className="ana-body-wrapper">
								<div className="full-block">
									<div className="section-title">
										<h2>Business Overview</h2>
										{_renderAnalyticsFilter()}
									</div>
								</div>

								<div
									className="full-block"
									style={{ padding: "0 24px 24px" }}
								>
									<LineHistoryGraph
										anaDataSet={overAlls.trendRevGraph}
										type={"trend"}
									/>
								</div>

								{_renderPendingsHighlightBlock()}
								<div className="ana-flex-wrapper flex-block">
									<div className="ana-flex-group">
										<AnalyticsSummaryText
											label={"Total Overall Revenue"}
											data={
												overAlls?.overallBookingRevenue
											}
											changeData={false}
											type="revenue"
										/>
										<AnalyticsSummaryText
											label={"Overall Declared VAT"}
											data={overAlls?.overallVatDeclared}
											changeData={false}
											type="vat"
										/>
									</div>

									<div className="ana-flex-group">
										<AnalyticsSummaryText
											label={"Total Overall Collections"}
											data={overAlls?.overallCollections}
											changeData={false}
											type="collection"
										/>
										<AnalyticsSummaryText
											label={"Total Overall Bookings"}
											data={overAlls?.overallBooking}
											changeData={false}
											type="booking"
										/>
									</div>
								</div>

								{_renderPendingByYear()}
								{_renderVatByYear()}
								{_renderEstVatByYear()}

								<div className="half-block w-box">
									<DifferenceChart
										anaDataSet={overAlls.totalRevByYear}
										type="year"
										label="Revenue"
									/>
								</div>

								<div className="half-block w-box">
									<DifferenceChart
										anaDataSet={
											overAlls.totalOutOfPocketByYear
										}
										type="year"
										label="Tax Paid OOP"
									/>
								</div>
							</div>
						)}
					</>
				) : (
					<>
						{!isEmptyObject(structuredData) && (
							<div className="ana-body-wrapper">
								<div className="full-block">
									<div className="section-title">
										<h2>Business Overview</h2>
										{_renderAnalyticsFilter()}
									</div>
								</div>
								<div
									className="full-block"
									style={{ padding: "0 24px 24px" }}
								>
									<LineHistoryGraph
										anaDataSet={structuredData.revGraph}
										type={"compare"}
									/>
								</div>

								{_renderRevenueHighlightBlock()}
								<div className="ana-flex-wrapper flex-block">
									<div className="ana-flex-group">
										<AnalyticsSummaryText
											label={"Total Bookings"}
											data={
												structuredData.past_bookingCount +
												structuredData.future_bookingCount
											}
											changeData={
												structuredData?.change_bookingCount
											}
											type="booking"
										/>
										<AnalyticsSummaryText
											label={"Declared VAT"}
											data={structuredData.sumOfVat}
											changeData={
												structuredData?.change_sumOfVat
											}
											type="vat"
										/>
									</div>

									<div className="ana-flex-group">
										<AnalyticsSummaryText
											label={"Total Payment Collections"}
											data={
												structuredData.sumOfCollectedRevenue
											}
											changeData={
												structuredData?.change_totalPaymentCollection
											}
											type="collection"
										/>
										<AnalyticsSummaryText
											label={"Payments Pending"}
											data={
												structuredData.sumOfPendingRevenue
											}
											changeData={
												structuredData?.change_totalPending
											}
											type="pending"
										/>
									</div>
								</div>

								{_renderSummaryBreakdowns()}
								{_renderVATBreakdowns()}
								<div className="half-block w-box">
									<BlockGraph
										anaDataSet={
											structuredData?.bookingTypes
										}
									/>
								</div>
								<div className="half-block w-box">
									<ScatterGraph
										anaDataSet={
											structuredData?.paxRateGraph
										}
									/>
								</div>
								{Object.keys(structuredData.revGraph).length >
									1 && (
									<div className="compare-block w-box">
										<DifferenceChart
											anaDataSet={structuredData.revGraph}
										/>
									</div>
								)}
								<div className="half-block w-box">
									<DifferenceChart
										anaDataSet={structuredData.paxRange}
										type="list"
										label="Pax Range"
										toolTipType=""
									/>
								</div>
								<div className="half-block w-box">
									<DifferenceChart
										anaDataSet={structuredData.rateRange}
										type="list"
										label="Rate Range"
										barColor="#3498db"
										toolTipType=""
									/>
								</div>
							</div>
						)}
					</>
				)}
				{isEmptyObject(graphAnalytics) && (
					<div className="ana-left-wrapper">
						<div className="ana-empty-container">
							<img src={dashImg} alt="empty" />
							<div className="empty-message">
								Looks like you don't have any records. Analytics
								will be generated once you've added bookings and
								recorded payments.
							</div>
						</div>
					</div>
				)}
			</div>

			<SideModalList
				isVisible={isOpen}
				onModalClose={() => setIsOpen(!isOpen)}
				containerStyle={{ minWidth: "310px" }}
				title="Booking List"
				sideModalData={sideModalData}
				modelFor="payment"
			></SideModalList>
		</div>
	);
}

import {
	FETCH_LOGIN_REQUEST,
	FETCH_LOGIN_SUCCESS,
	FETCH_LOGIN_ERROR,
	FETCH_FORGOT_PASSWORD_SUCCESS,
	FETCH_LOGOUT,
	SET_DATEFIX,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import { sessionInfo } from "../../global/function";

import showToastMessage from "../../global/showToastMessage";
import history from "../history";
import { isEmptyObject } from "../../global/function";
import { clearAllBookings } from "./event-save.action";

// import { socket } from "../../Socket";

export const fetchLoginRequest = () => {
	return {
		type: FETCH_LOGIN_REQUEST,
	};
};

export const fetchLoginSuccess = (login) => {
	return {
		type: FETCH_LOGIN_SUCCESS,
		payload: login,
	};
};
export const fetchForgotPasswordSuccess = () => {
	return {
		type: FETCH_FORGOT_PASSWORD_SUCCESS,
	};
};

export const fetchLoginError = (error) => {
	return {
		type: FETCH_LOGIN_ERROR,
		payload: error,
	};
};

export const fetchLogoutRequest = () => {
	return {
		type: FETCH_LOGOUT,
	};
};
export const setDateFixReduxState = (state) => {
	return {
		type: SET_DATEFIX,
		payload: state,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchLoginError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchLoginError(error.message));
	}
}

export const processLogin = (loginInfo, client) => {
	return (dispatch) => {
		loginInfo = { ...loginInfo, app: "web" };
		dispatch(fetchLoginRequest());
		let URL = client
			? `${strings.url}support/client/login`
			: `${strings.url}user/login`;

		return axios
			.post(URL, loginInfo, {
				headers: { "content-type": "application/json" },
			})
			.then(async (response) => {
				if (response.data.success) {
					localStorage.clear();
					localStorage.setItem("token", response.data.token);

					if (response.data.bookingFix) {
						localStorage.setItem("dateFixState", "true");
						dispatch(setDateFixReduxState(true));
					}

					localStorage.setItem("isLoggedIn", true);

					localStorage.setItem(
						"user_image",
						response.data.result.user_image
					);
					localStorage.setItem(
						"location_id",
						response.data.venues[0].venue_info_id
					);
					let venuesArray = [];

					response.data.venues.map((v) => {
						if (v.venue_employee_status === "active") {
							venuesArray.push(v.venue_info_id);
						}
						return null;
					});
					// joining room for logged in user
					// socket.emit(
					// 	"joinRoom",
					// 	`${sessionData.company_info_id}`,
					// 	venuesArray
					// );

					await dispatch(
						fetchLoginSuccess({
							result: response.data.result,
						})
					);
					return response.data;
				} else {
					await dispatch(fetchLoginError(response.data.message));
					showToastMessage(response.data.message, "E");

					return response.data;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updatePassword = (passwordInfo) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}user/change/password`, passwordInfo, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					history.push({ pathname: "/" });
					return true;
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const forgotPassword = (passwordInfo) => {
	return (dispatch) => {
		dispatch(fetchLoginRequest());
		return axios
			.post(`${strings.url}user/forgot/password`, passwordInfo, {
				headers: { "content-type": "application/json" },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					dispatch(fetchForgotPasswordSuccess());
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					dispatch(fetchForgotPasswordSuccess());
				}
			})
			.catch(async (error) => {
				dispatch(fetchForgotPasswordSuccess());
				await _handleError(error, dispatch);
			});
	};
};

export const resetPassword = (passwordInfo) => {
	return (dispatch) => {
		dispatch(fetchLoginRequest());
		return axios
			.put(`${strings.url}user/reset/password`, passwordInfo, {
				headers: {
					"content-type": "application/json",
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					dispatch(fetchForgotPasswordSuccess());
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					dispatch(fetchForgotPasswordSuccess());
				}
			})
			.catch(async (error) => {
				dispatch(fetchForgotPasswordSuccess());
				await _handleError(error, dispatch);
			});
	};
};

export const logout = () => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();
		return axios
			.delete(`${strings.url}user/logout`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					history.push({ pathname: "/" });
					await dispatch(fetchLogoutRequest());
					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const switchToViewer = () => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}user/switch/role`,
				{},
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					dispatch(clearAllBookings());
					return response.data;
				} else {
					showToastMessage(response.data.message, "E");
					return response.data;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

import {
	FETCH_LOGIN_REQUEST,
	FETCH_LOGIN_SUCCESS,
	FETCH_LOGOUT,
	FETCH_LOGIN_ERROR,
	FETCH_FORGOT_PASSWORD_SUCCESS,
	FETCH_RESET_PASSWORD_SUCCESS,
	SET_DATEFIX,
} from "../action-types";

const initial_state = {
	isLoading: false,
	loginDetails: {},
	error: "",
	dateFixReduxState: false,
};

export default function loginReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_LOGIN_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				loginDetails: action.payload.result,
				error: "",
			};
		case FETCH_LOGIN_ERROR:
		case FETCH_LOGOUT:
			localStorage.clear();
			return {
				...state,
				isLoading: false,
				loginDetails: {},
				error: action.payload,
			};
		case FETCH_RESET_PASSWORD_SUCCESS:
		case FETCH_FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case SET_DATEFIX:
			return {
				...state,
				dateFixReduxState: action.payload,
			}

		default:
			return state;
	}
}

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "./calendar-event.style.scss";
import CalendarEventDetail from "../calendar-event-detail/calendar-event-detail.component";
import { nepMonthFullDate } from "../../../global/function";
import moment from "moment";

function CalendarEvent(props) {
	const currentDate = moment().format("YYYY-MM-DD");
	const [dateEng, setDateEng] = useState("");
	const [dateNep, setDateNep] = useState("");
	const [day, setDay] = useState("");
	const [todayFlag, setTodayFlag] = useState(false);

	useEffect(() => {
		setDateEng(props.date.split("|")[0]);
		setDateNep(nepMonthFullDate(props.date.split("|")[1]));
		setDay(moment(props.date.split("|")[0]).format("ddd"));
		if (props.date.split("|")[0] === moment().format("YYYY-MM-DD")) {
			setTodayFlag(true);
		}
	}, [props]);

	//tithi render to display as a string if valid values
	const _renderTithi = (tithi) => {
		if (tithi !== "No Tithi") {
			return (
				<span className="cal-day-label-date-np tithi tithi-highlight">
					{tithi.join(" | ")}
				</span>
			);
		} else {
			return <span className="cal-day-label-date-np tithi">{"-"}</span>;
		}
	};

	return (
		<div className="date-event-detail bg-default">
			<div
				className={
					todayFlag ? "cal-day-label selected" : "cal-day-label"
				}
			>
				<span className="cal-day-label-date-en">
					{moment(dateEng).format("DD MMMM YYYY")}
				</span>
				<span className="cal-day-label-date-np">{dateNep} </span>
				<span
					className={
						todayFlag
							? "cal-day-label-day selected"
							: "cal-day-label-day"
					}
				>
					{day}{" "}
				</span>
				{props.type !== "upcoming"
					? _renderTithi(props.data[0])
					: _renderTithi(props.bookingData[0])}
			</div>
			<hr
				className={
					todayFlag
						? "date-divider selected h-1"
						: "date-divider h-10"
				}
			/>
			<div className="event-container">
				{props.type === "upcoming"
					? props.bookingData.map((arr, index) => {
							if (index > 0) {
								return (
									<CalendarEventDetail
										key={`${arr.booking_info_id} `}
										type={props.type}
										onClick={props.parentCallBack}
										data={arr}
										colorCode={arr.venue_color_code}
									/>
								);
							}
					  })
					: props.data.map((arr, venueName) =>
							Object.keys(arr).map((venue) =>
								Object.keys(arr[venue]).map((time, key) => {
									if (
										arr[venue][time].length >= 1 &&
										Array.isArray(arr[venue][time]) === true
									) {
										return (
											<CalendarEventDetail
												disabled={
													props.user_comp_role ===
														"admin" ||
													props.date >= currentDate
														? false
														: true
												}
												key={`${venue}-${time}-${key} `}
												date_en={
													props.date.split("|")[0]
												}
												date_np={
													props.date.split("|")[1]
												}
												day={day}
												time={time}
												type={props.type}
												venue={venue}
												halls={arr[venue][time]}
												colorCode={
													arr[venue]["colorCode"]
												}
												venueName={Object.keys(arr)[0]}
												venueID={
													arr[venue]["venue_info_id"]
												}
												scroll={props.scroll}
											/>
										);
									}
								})
							)
					  )}
			</div>
		</div>
	);
}

export default CalendarEvent;

import React, { useEffect, useState } from "react";
import FormSelectInputComponent from "../../components/common/select-box/form-select-input.component";
import { useSelector, useDispatch } from "react-redux";
import { fetchVenue } from "../../redux/actions/venue-action";
import {
	fetchInventoriesByLocationId,
	fetchInventoriesCount,
	setInventoryOnEdit,
} from "../../redux/actions/inventory.action";
import { useHistory } from "react-router-dom";
import InventoryTab from "./inventory-tab/inventory.tab";
import RequestTab from "./request-tab/request.tab";
import OrderTab from "./order-tab/order.tab";
import Loader from "../../components/loader/loader.component";
import AddInventoryItemModal from "../../components/modals/add-inventory-item.modal";

function InvenoryPage(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { venueDropDownList } = useSelector((s) => s.venue);
	const { itemCount } = useSelector((s) => s.inventory);
	const i = useSelector((s) => s.inventory);
	const r = useSelector((s) => s.request);
	const [venue, setVenue] = useState("");
	const [view, setView] = useState(1);
	const [showItemModal, setShowItemModal] = useState(false);

	useEffect(() => {
		setVenue(localStorage.getItem("location_id"));
		dispatch(fetchVenue());
	}, [dispatch]);

	useEffect(() => {
		if (props.location.state === undefined) {
			setView(1);
		} else {
			setView(props.location.state.tab);
		}
	}, [props]);

	useEffect(() => {
		if (venue) {
			dispatch(fetchInventoriesByLocationId(venue));
			dispatch(fetchInventoriesCount(venue));
		}
	}, [venue, dispatch]);

	const toggleShowItemModal = () => {
		setShowItemModal(true);
	};

	const toggleHideItemModal = () => {
		setShowItemModal(false);
	};

	const component = (title, label, value) => {
		return (
			<div className="bg-default h-119 w-135 relative rounded-sm">
				<label
					htmlFor=""
					className="absolute left-10px top-10px text-sm"
				>
					{title}
				</label>
				<label
					htmlFor=""
					className="absolute right-10px bottom-47px text-sm text-fontSemiDark"
				>
					{label}
				</label>
				<label htmlFor="" className="absolute right-10px bottom-20px">
					{value}
				</label>
			</div>
		);
	};

	function _renderHeaderSelection(viewId, viewTitle) {
		return (
			<div
				onClick={() => _changeView(viewId)}
				className={
					view === viewId
						? "settings-view-title selected"
						: "settings-view-title"
				}
			>
				{viewTitle}
			</div>
		);
	}
	const _changeView = (val) => {
		setView(val);
	};

	const _changeVenue = (val) => {
		localStorage.setItem("location_id", val);
		setVenue(val);
	};

	const _addInventoryItem = () => {
		dispatch(setInventoryOnEdit({}));
		toggleShowItemModal();
	};

	return (
		<div className="main-container">
			{i.isLoading && <Loader />}
			{r.isLoading && <Loader />}
			<div className="profile-navigation-container">
				<div className="navigator-content">
					{_renderHeaderSelection(1, "Inventory ")}
					{_renderHeaderSelection(2, "Request ")}
					{_renderHeaderSelection(3, "Order ")}
				</div>
			</div>
			<div className="inner-container full mt-20px">
				<FormSelectInputComponent
					label="Venue Name"
					variant="form-sm"
					name="location_id"
					options={venueDropDownList}
					value={venue}
					onChange={(e) => _changeVenue(e.target.value)}
					disabled={view !== 1}
				/>
			</div>
			<div className="inner-container  ">
				{/* Inventory */}
				<div className="main-inner-container inventory flex flex-cols gap-15px">
					{component(
						"Inventory",
						"Total Items",
						itemCount.inventoryCount
					)}

					<div className="inner-content cols">
						<ButtonComponent
							title={"New Inventory"}
							label="Bulk"
							icon="add"
							_function={() =>
								history.push({
									pathname: "/inventory/add/item",
								})
							}
						/>
						<ButtonComponent
							title={"New Inventory"}
							label="Item"
							icon="add"
							_function={_addInventoryItem}
						/>
					</div>
				</div>

				{/* Order */}
				<div className="main-inner-container inventory flex flex-cols gap-15px">
					{component("Orders", "Pending", itemCount.orderCount)}

					<div className="inner-content cols">
						<ButtonComponent
							title={"New Order"}
							label="Staff"
							icon="add"
							_function={() =>
								history.push({
									pathname: "order/add",
									state: {
										type: "user",
									},
								})
							}
						/>
						<ButtonComponent
							title={"New Order"}
							label="Venue"
							icon="add"
							_function={() =>
								history.push({
									pathname: "order/add",
									state: {
										type: "venue",
									},
								})
							}
						/>
					</div>
				</div>

				{/* Request */}
				<div className="main-inner-container inventory flex flex-cols gap-15px">
					{component("Request", "Pending", itemCount.requestCount)}

					<div className="inner-content cols">
						<ButtonComponent
							title={"New Request"}
							label="Staff"
							icon="add"
							_function={() =>
								history.push({
									pathname: "request/add",
									state: {
										type: "user",
									},
								})
							}
						/>
						<ButtonComponent
							title={"New Request"}
							label="Venue"
							icon="add"
							_function={() =>
								history.push({
									pathname: "request/add",
									state: {
										type: "venue",
									},
								})
							}
						/>
					</div>
				</div>

				{/* Vendor */}
				<div className="main-inner-container inventory flex flex-cols gap-15px">
					{component("Vendor", "Active", itemCount.vendorCount)}

					<div className="inner-content cols">
						<ButtonComponent
							title={"New Vendor"}
							label="Add New"
							icon="add"
							_function={() =>
								history.push({ pathname: "/vendor" })
							}
						/>
						<ButtonComponent
							title={"Active Vendor"}
							label="View"
							icon="user"
							_function={() =>
								history.push({ pathname: "/vendor" })
							}
						/>
					</div>
				</div>
			</div>

			{view === 1 && (
				<InventoryTab toggleShowItemModal={toggleShowItemModal} />
			)}
			{view === 2 && <RequestTab />}
			{view === 3 && <OrderTab />}

			<AddInventoryItemModal
				handleShow={toggleShowItemModal}
				handleClose={toggleHideItemModal}
				isVisible={showItemModal}
			/>
		</div>
	);
}

function ButtonComponent({ title, label, icon, _function }) {
	return (
		<div className="h-52 w-135 relative">
			<div className=" absolute text-sm text-fontSemiDark">{title}</div>
			<div className=" absolute bottom-0px text-lg">{label}</div>
			<button
				className="button-primary-icon absolute bottom-0px right-0px "
				onClick={_function}
			>
				{icon === "add" ? (
					<i className="fas fa-plus text-buttonSuccess"></i>
				) : (
					<i className="fas fa-user text-buttonFailed"></i>
				)}
			</button>
		</div>
	);
}

export default InvenoryPage;

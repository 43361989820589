import React, { useEffect, useState } from "react";
import "./boooking-history-table.style.scss";
import moment from "moment";
import "./table.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setErrorTrue } from "../../redux/actions/support-action";
import {
	currencyFormat,
	nepMonthDate,
	rupeeConverter,
	sessionInfo,
} from "../../global/function";
import SideModalBooking from "../modals/side-modal/side-modal-booking.modal";
import {
	getBookingHistory,
	getBookingHistoryByCustomer,
	searchBookingHistory,
} from "../../redux/actions/booking-action";

export default function BookingHistoryTable({
	bookingList,
	filterObject = {},
	from = "",
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [company_role, setCompanyRole] = useState();
	const [bookingArray, setBookingArray] = useState([]);

	const [sidemodalBookingData, setSidemodalBookingData] = useState({
		index: null,
		isOpened: false,
	});

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, [dispatch]);

	useEffect(() => {
		try {
			setBookingArray(bookingList);
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [dispatch, bookingList]);

	const onClickEvent = (list, index) => {
		setSidemodalBookingData({ index, isOpened: true });
		// history.push({
		// 	pathname: "/booking-event",
		// 	search: `?event_id=${list.event_id}`,
		// 	state: {
		// 		event_info_id: list.event_info_id,
		// 		booking_info_id: list.booking_info_id,
		// 	},
		// });
	};

	const mobileBookingView = (info, index) => {
		return (
			<div
				className={`h-119 w-135 bodered rounded-sm relative`}
				key={info.booking_info_id}
				onClick={() => onClickEvent(info, index)}
			>
				<span className="absolute h-20 w-20 top-10px left-10px">
					{info.booked_time === "morning" ? (
						<i className="fas fa-cloud text-morning"></i>
					) : info.booked_time === "afternoon" ? (
						<i className="fas fa-sun text-afternoon"></i>
					) : (
						<i className="fas fa-moon text-evening"></i>
					)}
				</span>
				<span className=" absolute h-23 w-65 top-10px right-10px border-b pb-7px border-fontWhite text-sm text-right">
					{nepMonthDate(info.booked_date_nepali)}
				</span>
				<span className=" absolute h-10 w-20 top-38px left-10px text-xs">
					{info.e_pax}
				</span>
				<span className=" absolute h-10 w-65 top-38px right-10px text-xs text-right overflow-hidden">
					{info.venue_name}
				</span>
				<span className=" absolute h-10 w-65 top-52px right-10px text-xs text-right">
					hall
				</span>
				<span className=" absolute h-10 w-119 bottom-44px right-10px text-xs text-right overflow">
					{info.customer_fname} {info.customer_lname}
				</span>
				<span className=" absolute h-10 w-65 bottom-30px right-10px text-xs text-right">
					Rs. {info.e_rate}
				</span>
				<span
					className={`absolute h-12 bottom-10px right-10px left-10px text-xs text-center text-fontWhite bg-fontWhite rounded-lg booking-status-view ${info.booking_status} capitalize`}
				>
					{info.booking_status}
				</span>
			</div>
		);
	};

	function _renderPendingCalcAmt(booking, type) {
		let additionals =
			(booking?.totalDrinkAmount || 0) +
			(booking?.totalServiceAmount || 0);
		let adjPayment = booking?.totalPayment || 0;
		let vatPaid = booking?.totalVat || 0;
		let balance =
			booking?.e_pax * booking?.e_rate + additionals - adjPayment;

		balance = balance - (booking?.totalDiscount || 0);

		if (type === "pending") {
			return booking?.payment_status === "complete" ||
				booking?.booking_status === "canceled" ||
				balance < 0
				? 0
				: balance;
		} else {
			if (booking?.booking_status === "canceled") {
				if (booking?.payment_status === "refund") {
					balance = adjPayment - (booking?.totalRefund || 0);
				} else {
					balance = adjPayment > 0 ? -adjPayment : 0;
				}
			} else {
				if (balance < 0 && Math.abs(balance) > vatPaid) {
					balance = Math.abs(balance) - vatPaid;
				} else {
					balance = 0;
				}
			}

			return balance;
		}
	}

	// function to call after updates inside sidemodal
	const reSearchBooking = async (bookingDate) => {
		switch (from) {
			case "customerProfile":
				await dispatch(
					getBookingHistoryByCustomer(
						filterObject?.companyCustomerId,
						filterObject?.dataPerPage,
						filterObject?.currentPage
					)
				);
				break;
			case "userProfile":
				dispatch(
					getBookingHistory(
						filterObject?.dataPerPage,
						filterObject?.currentPage,
						filterObject?.companyEmployeeId
					)
				);
				break;
			default:
				await dispatch(
					searchBookingHistory(
						filterObject?.dataPerPage,
						filterObject?.currentPage,
						filterObject?.bookingSearchParameters
					)
				);
				break;
		}
	};
	return (
		<div>
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						<td className="table-header booking-time"></td>
						<td className="table-header booking-id">
							<div className="table-header-container">BID</div>
						</td>
						<td className="table-header booking-date">
							<div className="table-header-container">Date</div>
						</td>
						{/* <td className="table-header booking-date">
							<div className="table-header-container">
								Np Date
							</div>
						</td> */}
						<td className="table-header booking-venue-name">
							<div className="table-header-container">
								Venue Name
							</div>
						</td>
						<td className="table-header booking-customer-name">
							<div className="table-header-container">
								Customer Name
							</div>
						</td>
						<td className="table-header booking-event">
							<div className="table-header-container">Event</div>
						</td>
						<td className="table-header booking-catering">
							<div className="table-header-container">Type</div>
						</td>
						<td className="table-header booking-pax">
							<div className="table-header-container">PAX</div>
						</td>
						<td className="table-header booking-rate">
							<div className="table-header-container">RATE</div>
						</td>
						<td className="table-header booking-status"></td>
						{company_role !== "viewer" && (
							<>
								<td className="table-header booking-rate">
									<div className="table-header-container">
										Pendings
									</div>
								</td>
								<td className="table-header booking-rate">
									<div className="table-header-container">
										Excess / Liable
									</div>
								</td>
							</>
						)}
					</tr>
				</thead>

				<tbody>
					{bookingArray.length > 0 &&
						bookingArray.map((list, index) => (
							<tr
								className="bg-default data-table-row text-capitalize"
								key={list.booking_info_id}
								onClick={() => onClickEvent(list, index)}
							>
								<td
									className={`booking-time-cell ${list.booked_time}`}
								>
									{list.booked_time.charAt(0).toUpperCase()}
								</td>
								<td>
									{list.booking_id}
									{list.vat_status === "exists" && "."}
								</td>
								<td
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<span>
										{moment(
											list.booked_date_english
										).format("DD MMM YYYY")}
									</span>
									<span>
										{nepMonthDate(list.booked_date_nepali)}{" "}
										{list.booked_date_nepali.split("-")[0]}
									</span>
								</td>
								{/* <td>
									{nepMonthDate(list.booked_date_nepali)}{" "}
									{list.booked_date_nepali.split("-")[0]}
								</td> */}
								<td>{list.venue_name}</td>
								<td>
									{list.customer_fname} {list.customer_lname}
								</td>
								<td>{list.booking_type}</td>
								<td>{list.booking_catering}</td>
								<td>
									{list.vat_status === "complete" ||
									company_role === "viewer"
										? list.a_pax
										: list.e_pax || 0}
								</td>
								<td className={`body-table-cell e_rate`}>
									{list.vat_status === "complete" ||
									company_role === "viewer"
										? rupeeConverter(
												list?.a_rate.toString()
										  ) || 0
										: rupeeConverter(
												list?.e_rate.toString()
										  ) || 0}
								</td>

								<td className="booking-status-cell">
									<div className="booking-status-wrapper bg-inset">
										<div
											className={`booking-status-content ${list.booking_status}`}
										>
											{list.booking_status}
										</div>
									</div>
								</td>

								{company_role !== "viewer" && (
									<>
										<td
											className={`body-table-cell e_rate ${
												_renderPendingCalcAmt(
													list,
													"pending"
												) > 0 && "pending"
											}`}
										>
											Rs.{" "}
											{currencyFormat(
												_renderPendingCalcAmt(
													list,
													"pending"
												)
											)}
										</td>
										<td
											className={`body-table-cell e_rate ${
												_renderPendingCalcAmt(
													list,
													"excess"
												) > 0 && "excess"
											} ${
												_renderPendingCalcAmt(
													list,
													"excess"
												) < 0 && "pending"
											}`}
										>
											Rs.{" "}
											{currencyFormat(
												Math.abs(
													_renderPendingCalcAmt(
														list,
														"excess"
													)
												)
											)}
										</td>
									</>
								)}
							</tr>
						))}
				</tbody>
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{bookingArray.map((list, index) =>
					mobileBookingView(list, index)
				)}
			</div>
			<SideModalBooking
				isVisible={sidemodalBookingData.isOpened}
				onModalClose={() => {
					setSidemodalBookingData({
						index: null,
						isOpened: false,
					});
				}}
				onAfterSuccessCall={reSearchBooking}
				containerStyle={{ minWidth: "310px" }}
				title="Bookings"
				sideModalData={
					sidemodalBookingData.isOpened && [
						bookingArray[sidemodalBookingData.index],
					]
				}
				modalType="bookingHistory" // to determine from where the sidemodal is opened
			/>
		</div>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import CalendarDetail from "../../../components/calendar/calendar-event/calendar-event.component";
import CalendarController from "../calendar-controller/calendar-controller.component";
// import BookingSummary from "../../../components/booking/booking-summary/booking-summary.component";
import AddCustomerModal from "../../../components/modals/add-customer-modal/add-customer.modal";
import SearchCustomerComponent from "../../../components/search/search-customer.component";
import { isEmptyObject, sessionInfo } from "../../../global/function";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerSuccess } from "../../../redux/actions/customer-setting.action";
import Loader from "../../../components/loader/loader.component";

import {
	addBooking,
	addBookingQueue,
	addBookingToExistingEvent,
} from "../../../redux/actions/booking-action";
import {
	clearAllBookings,
	deleteBooking,
	setSelectedCustomer,
} from "../../../redux/actions/event-save.action";
import DuplicateCustomerModal from "../../../components/modals/duplicate-customer.modal";

import SwitchCustomerModal from "../../../components/modals/switch-customer.modal";
// import moment from "moment";
// import Profile from "../../../components/client-profile/profile";
import CustomerTable from "../../../components/tables/table-customer-list.component";
import showToastMessage from "../../../global/showToastMessage";
import { setErrorTrue } from "../../../redux/actions/support-action";

// import { fetchCalendarInfo } from "../../../redux/actions/calendar-info.action";
import AddBookingInfo from "../../../components/booking/booking-info/add-booking.component";
import ProfileSummary from "../../../components/profile/profile-summary.component";
// import { socket } from "../../../Socket";
import { fetchCalendarInfo } from "../../../redux/actions/calendar-info.action";
// import BookingInfoComponent from "../../../components/booking/booking-info/booking-info.component";
import ConfirmationModal from "../../../components/modals/confirmation-modal.component";

function CalendarAvailability(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const calendarInfo = useSelector((state) => state.calendarInfo);

	const { error, customerList, isLoading } = useSelector(
		(state) => state.customerSetting
	);
	const booking = useSelector((state) => state.booking);
	const { savedBookings, bookingError, selectedCustomer } = useSelector(
		(state) => state.savedBooking
	);

	const [show, setShow] = useState(false);
	const [showSwitchCustomerModal, setShowSwitchCustomerModal] =
		useState(false);
	const [showDuplicateCustomer, setShowDuplicateCustomer] = useState(false);
	const [sessionData, setSessionData] = useState({});

	// when duplicate booking case occurs
	const [queueBookingModal, setQueueBookingModal] = useState(false);
	const [existingBookings, setExistingBooking] = useState([]);
	const [queueCustomer, setQueueCustomer] = useState("");

	useEffect(() => {
		sessionInfo().then((data) => {
			setSessionData({
				comp_role: data.company_role,
			});
		});
		try {
			dispatch(setSelectedCustomer(props.selectedCustomer));
			dispatch(fetchCustomerSuccess([]));
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
		window.addEventListener("popstate", alertUser);
		return () => {
			window.removeEventListener("popstate", alertUser);
		};
	}, [dispatch, props]);

	// socket useEffect
	// useEffect(() => {
	// 	async function fetchSessionData() {
	// 		await sessionInfo().then((data) => {
	// 			setSessionData({
	// 				company_employee_id: data.company_employee_id,
	// 				comp_role: data.company_role,
	// 			});
	// 		});
	// 	}

	// 	fetchSessionData();

	// 	socket.on("bookingCreated", (data) => {
	// 		if (data.success) {
	// 			if (calendarInfo.weekStart !== "") {
	// 				dispatch(
	// 					fetchCalendarInfo(
	// 						calendarInfo.weekStart,
	// 						calendarInfo.weekEnd,
	// 						calendarInfo.filters,
	// 						"socket"
	// 					)
	// 				);
	// 			}
	// 		}
	// 	});

	// 	socket.on("bookingUpdated", (data) => {
	// 		if (data.success) {
	// 			if (calendarInfo.weekStart !== "") {
	// 				dispatch(
	// 					fetchCalendarInfo(
	// 						calendarInfo.weekStart,
	// 						calendarInfo.weekEnd,
	// 						calendarInfo.filters,
	// 						"socket"
	// 					)
	// 				);
	// 			}
	// 		}
	// 	});

	// 	socket.on("connect_error", (err) => {
	// 		console.log(`connect_error due to ${err.message}`);
	// 	});
	// }, [dispatch]);

	useEffect(() => {
		if (error === "Error! Duplicate Customer") {
			setShowDuplicateCustomer(true);
			setShow(false);
		}
		if (bookingError !== "") {
			showToastMessage(bookingError, "W");
		}
	}, [error, bookingError]);

	// to update calendar
	useEffect(() => {
		if (calendarInfo.weekStart !== "") {
			dispatch(
				fetchCalendarInfo(
					calendarInfo.weekStart,
					calendarInfo.weekEnd,
					calendarInfo.filters
				)
			);
		}
	}, [queueBookingModal]);

	window.onbeforeunload = (e) => {
		e.preventDefault();
		e.returnValue = "";
	};

	const alertUser = (e) => {
		e.preventDefault();
		e.returnValue = "";
	};
	const handleDuplicateModalClose = () => {
		setShowDuplicateCustomer(false);
	};
	const handleDuplicateModalShow = () => setShowDuplicateCustomer(true);

	function onScroll() {
		const s = document.getElementById("bottom");
		s.scrollIntoView({ behavior: "smooth" });
	}

	function renderBookingView() {
		return savedBookings.map((event, key) => (
			<AddBookingInfo
				event={event}
				type="add"
				key={event.tempBookingId}
			/>
		));
	}

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleSwitchCustomerClose = () => setShowSwitchCustomerModal(false);
	const handleSwitchCustomerShow = () => setShowSwitchCustomerModal(true);

	const selectCustomer = (val) => {
		/* saving company_customer_id outside of company customer array as same as of duplicate customer */
		let company_customer_id = val.company_customer_id;
		val = { ...val, company_customer_id };

		dispatch(setSelectedCustomer(val));
	};

	// booking queue confirmation model
	const _handleBookingQueueConfirmationModalShow = () =>
		setQueueBookingModal(true);

	const addBookingToQueue = async (modelResponse) => {
		// need both savedBookings and existingBookings to check the duplicated booking and add to queue
		// savedBookings.halls[] || existingBookings.booking_halls[].hall_info_id

		existingBookings.forEach((oldBooking) => {
			savedBookings.forEach(async (newBooking) => {
				let hallMatched = false;

				oldBooking.booking_halls.find((hall) => {
					hallMatched = newBooking.halls.includes(hall.hall_info_id); // if true skip loop
					return hallMatched;
				});

				if (
					newBooking.booked_time === oldBooking.booked_time &&
					newBooking.booked_date_english ===
						oldBooking.booked_date_english &&
					hallMatched
				) {
					// removing from UI of the calendar
					dispatch(deleteBooking(newBooking.tempBookingId));
					// adding to booking queue
					let data = {
						booking_info_id: oldBooking.booking_info_id,
						venue_info_id: oldBooking.venue_info_id,
						company_customer_id: queueCustomer,
						booking_type: newBooking.booking_type,
						booking_catering: newBooking.booking_catering,
						e_pax: newBooking.e_pax,
						e_rate: newBooking.e_rate,
					};

					if (modelResponse === "add") {
						let queuedBooking = await dispatch(
							addBookingQueue(data)
						);

						history.push({
							pathname: "/booking-event",
							search: `?event_id=${queuedBooking[0].event_id}`,
							state: {
								event_info_id: queuedBooking[0].event_info_id,
								id: queuedBooking[0].event_id,
							},
						});
					}
				}
			});
		});
		setQueueBookingModal(false);
	};

	const _routeToNext = async () => {
		let validation = validateBookings();
		if (validation) {
			if (
				selectedCustomer.event_info_id === undefined ||
				selectedCustomer.event_info_id === null ||
				selectedCustomer.event_info_id === ""
			) {
				setQueueCustomer(selectedCustomer.company_customer_id);

				let eventArray = {
					bookings: savedBookings,
					company_customer_id: selectedCustomer.company_customer_id,
					event_name: selectedCustomer.customer_fname,
					venues: [], // socket testing purpose
				};

				const responseData = await dispatch(addBooking(eventArray));

				if (!responseData.success) {
					setQueueBookingModal(true);
					setExistingBooking(responseData.existingBookings);
				}
			} else {
				let eventArray = {
					bookings: savedBookings,
					event_info_id: selectedCustomer.event_info_id,
					company_employee_id: sessionData.company_employee_id,
					venues: [], // socket testing purpose
				};

				dispatch(addBookingToExistingEvent(eventArray));
				dispatch(clearAllBookings());
			}
		}
	};

	function validateBookings() {
		let validation = true;
		savedBookings.map((b) => {
			if (!b.saved) {
				showToastMessage("Bookings are not saved", "E");
				validation = false;
			}
			return validation;
		});
		return validation;
	}

	function restSelectedCustomer() {
		handleSwitchCustomerClose();
		dispatch(setSelectedCustomer({}));
	}

	return (
		<div>
			<div className="inner-container full">
				<CalendarController controlType="weekly" />

				<div className="calendar-content">
					{!isEmptyObject(calendarInfo.calendarInfo) &&
						Object.keys(calendarInfo.calendarInfo.availability).map(
							(date) => {
								return (
									<CalendarDetail
										user_comp_role={sessionData.comp_role}
										date={date}
										key={date}
										type="available"
										data={
											calendarInfo.calendarInfo
												.availability[date]
										}
										scroll={onScroll}
									/>
								);
							}
						)}
				</div>
			</div>
			<div className="inner-container full">
				{isEmptyObject(selectedCustomer) || customerList.length < 0 ? (
					<SearchCustomerComponent
						type="calendar"
						handleAdd={handleShow}
					/>
				) : (
					<div className="inner-container mt-30px">
						<ProfileSummary
							// handleClick={handleShow}
							details={selectedCustomer}
							switchCustomer={handleSwitchCustomerShow}
						/>
					</div>
				)}

				{isEmptyObject(selectedCustomer) && customerList.length > 0 && (
					<div className="inner-container full">
						<CustomerTable
							data={customerList}
							onCustomerSelect={selectCustomer}
						/>
					</div>
				)}
				{savedBookings.length > 0 && <hr className="default-hr" />}

				{savedBookings.length > 0 && (
					<div className="inner-container mt-30px">
						{renderBookingView()}
					</div>
				)}

				{savedBookings.length > 0 && (
					<div
						className={`mt-15px w-screen text-right ${
							savedBookings.length <= 0 ? "hidden" : ""
						}`}
					>
						<button
							disabled={isEmptyObject(selectedCustomer) === true}
							className={
								isEmptyObject(selectedCustomer)
									? `button-primary`
									: `colored-button-wrapper bg-buttonSuccess text-fontWhite`
							}
							onClick={_routeToNext}
						>
							Next
						</button>
					</div>
				)}

				<AddCustomerModal
					isVisible={show}
					handleClose={handleClose}
					handleShow={handleShow}
					actionType="Add"
					from="calendar"
				/>

				<SwitchCustomerModal
					isVisible={showSwitchCustomerModal}
					handleClose={handleSwitchCustomerClose}
					handleShow={handleSwitchCustomerShow}
					yesOnClick={restSelectedCustomer}
				/>
			</div>
			{calendarInfo.isLoading && <Loader />}
			{booking.isLoading && <Loader />}
			{isLoading && <Loader />}

			<ConfirmationModal
				isVisible={queueBookingModal}
				handleClose={() => addBookingToQueue("close")}
				handleShow={_handleBookingQueueConfirmationModalShow}
				onClickConfirmation={() => addBookingToQueue("add")}
				type="addQueueBooking"
			/>

			<DuplicateCustomerModal
				isVisible={showDuplicateCustomer}
				handleClose={handleDuplicateModalClose}
				handleShow={handleDuplicateModalShow}
				onClickNo={handleShow}
				from="calendar"
				type="customer"
			/>
			{/* div only used for scrolling */}
			<div id="bottom" />
		</div>
	);
}

export default CalendarAvailability;

import React, { forwardRef } from "react";
import "./booking-halls.style.scss";

function BookingHallsCheckbox(props, ref) {
	return (
		<div className="bookings-halls-wrapper">
			<div className="bookings-halls-container">
				{props.halls.map((h, key) => (
					<span
						key={h.hall_info_id}
						className="booking-halls-content"
					>
						<input
							type="checkbox"
							ref={ref}
							id={`hall-${h.hall_info_id}${props.tempBookingId}`}
							value={h.hall_info_id}
							name={`${props.name}`}
							className="booking-halls-checkbox"
							disabled={props.disabled}
						/>
						<label
							htmlFor={`hall-${h.hall_info_id}${props.tempBookingId}`}
							className="booking-halls-label bodered"
						>
							{h.hall_name}
						</label>
					</span>
				))}
			</div>
			<div className="bookings-halls-error">{props.error}</div>
		</div>
	);
}

export default forwardRef(BookingHallsCheckbox);

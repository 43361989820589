/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { sessionInfo } from "../../global/function";
import { updateEmpDetails } from "../../redux/actions/employee-setting.action";

const employeeSchema = yup.object().shape({
	// company_employee_role: yup.string().required("role required"),
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	pan_number: yup.string().required("required"),
	user_phone: yup
		.number()
		.typeError("Must be a number")
		.transform((value) => (value ? value : 0)),
	user_mobile: yup
		.number()
		.typeError("must be number")
		.required("mobile number is required")
		.positive("Must be greater than zero"),

	user_dob: yup.string().required("date of birth required"),
	user_gender: yup.string().required("gender required"),
	user_address: yup.string().required("address required"),
	user_city: yup.string().required("address required"),
	user_country: yup.string().required("address required"),
});
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function AddEmployeeModal(props) {
	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.employeeSetting);

	const { register, errors, handleSubmit, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(employeeSchema),
	});

	useEffect(() => {
		reset();

		let empDetails = userInfo?.userData;
		if (empDetails?.identification) {
			let i = empDetails.identification;

			reset({
				first_name: empDetails.first_name,
				last_name: empDetails.last_name,
				user_mobile: empDetails.user_mobile,
				user_phone: empDetails.user_phone,
				user_email: empDetails.user_email,
				user_dob: empDetails.user_dob,
				user_gender: empDetails.user_gender,
				user_city: empDetails.user_city,
				user_country: empDetails.user_country,
				user_address: empDetails.user_address,
				identification_number: i.identification_number,
				identification_type: i.identification_type,
				pan_number: i.pan_number,
			});
		} else {
			reset(empDetails);
		}
	}, [props]);

	async function updateDetail(empData) {
		let sessionData = await sessionInfo();

		empData = {
			...empData,
			identification: {
				identification_id:
					userInfo.userData.identification.identification_id,
				user_type: userInfo.userData.identification.user_type,
				identification_type: empData.identification_type || null,
				identification_number: empData.identification_number || null,
				pan_number: empData.pan_number || null,
			},
		};

		empData["company_info_id"] = sessionData.company_info_id;
		empData["user_info_id"] = userInfo.userData.user_info_id;

		delete empData["identification_type"];
		delete empData["identification_number"];
		delete empData["pan_number"];

		await dispatch(updateEmpDetails(empData));
		props.handleClose();
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Update My Details</div>
				</div>
				<div className="form-body">
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<FormTextInput
								type="text"
								placeholder="First Name"
								label="First Name*"
								variant="form-md"
								name="first_name"
								ref={register}
								error={errors?.first_name?.message}
							/>

							<FormTextInput
								label="Last Name*"
								placeholder="Last Name"
								variant="form-md"
								type="text"
								name="last_name"
								ref={register}
								error={errors?.last_name?.message}
							/>
						</div>
						<div className="form-input-container inner">
							<FormTextInput
								label="DOB"
								placeholder="(DD/MM/YYYY)"
								variant="form-md"
								type="date"
								name="user_dob"
								ref={register}
								error={errors?.user_dob?.message}
							/>

							<FormSelectBox
								label="Gender"
								variant="form-sm"
								options={strings.gender}
								name="user_gender"
								ref={register}
								type="Gender"
								error={errors?.user_gender?.message}
							/>
						</div>
					</div>
					<div className="form-input-container">
						<FormTextInput
							label="Email*"
							placeholder="Enter Email Address"
							variant="form-lg"
							type="email"
							name="user_email"
							ref={register}
							error={errors?.user_email?.message}
						/>

						<div className="form-input-container inner">
							<FormTextInput
								label="Mobile No. *"
								placeholder="Mobile Number"
								variant="form-md"
								type="number"
								name="user_mobile"
								ref={register}
								error={errors?.user_mobile?.message}
							/>

							<FormTextInput
								label="Phone No."
								placeholder="Phone Number"
								variant="form-md"
								type="number"
								name="user_phone"
								ref={register}
								error={errors?.user_phone?.message}
							/>
						</div>
					</div>

					<div className="form-input-container address">
						<FormTextInput
							label="Address*"
							placeholder="Address"
							variant="form-lg"
							type="text"
							name="user_address"
							ref={register}
							error={errors?.user_address?.message}
						/>
						<div className="form-input-container inner">
							<FormSelectBox
								label="Country*"
								variant="form-sm"
								options={strings.country}
								name="user_country"
								ref={register}
								type="Country"
								error={errors?.user_country?.message}
							/>

							<FormSelectBox
								label="City*"
								variant="form-sm"
								options={strings.city}
								name="user_city"
								ref={register}
								type="City"
								error={errors?.user_city?.message}
							/>
						</div>
					</div>

					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormSelectBox
								label="Identification Type"
								variant="form-sm"
								options={strings.identification}
								name="identification_type"
								ref={register}
								error={errors?.identification_type?.message}
							/>

							<FormTextInput
								label="ID Number"
								placeholder="Identification Number"
								variant="form-md"
								name="identification_number"
								type="text"
								ref={register}
								error={errors?.identification_number?.message}
							/>
						</div>
						<FormTextInput
							label="PAN No.*"
							placeholder="PAN Number"
							variant="form-lg"
							name="pan_number"
							ref={register}
							error={errors?.pan_number?.message}
						/>
					</div>
				</div>

				<div className="form-footer two">
					<button
						onClick={() => {
							reset({});
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						onClick={handleSubmit(updateDetail)}
						className="button-primary"
					>
						Complete
					</button>
				</div>
			</div>
		</Modal>
	);
}

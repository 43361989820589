import React from "react";

export default function Transportation({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512"
			style={style}
		>
			<path d="M628.9 210.7L494.4 49.3A48 48 0 0 0 457.5 32H32C14.3 32 0 46.3 0 64v288c0 17.7 14.3 32 32 32h32c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32V241.4c0-11.2-3.9-22.1-11.1-30.7zM64 192V96h96v96H64zm96 240c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm160-240h-96V96h96v96zm160 240c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm-96-240V96h66l80 96H384z" />
		</svg>
	);
}

import React, { useState, useEffect } from "react";
import "./booking-info.style.scss";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import showToastMessage from "../../../global/showToastMessage";
import TextInputWithErrorComponent from "../../common/text-input/text-input-with-error.component";
import FormTextArea from "../../common/text-input/form-textarea-input.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import strings from "../../../global/strings";
import BookingHallsCheckbox from "../../common/checkbox-input/booking-halls/booking-halls.checkbox";
import { setErrorTrue } from "../../../redux/actions/support-action";
import { useDispatch, useSelector } from "react-redux";
import { nepMonthFullDate } from "../../../global/function";
import moment from "moment";

import { isEmptyObject } from "jquery";
import {
	updateBooking,
	updateEventInfo,
} from "../../../redux/actions/booking-action";
import ProfileSummary from "../../profile/profile-summary.component";

//new code by sanat
import Loader from "../../loader/loader.component";
import AddCustomerModal from "../../modals/add-customer-modal/add-customer.modal";
import SearchCustomerComponent from "../../search/search-customer.component";
import SwitchCustomerModal from "../../modals/switch-customer.modal";
import CustomerTable from "../../tables/table-customer-list.component";
import { fetchCustomerSuccess } from "../../../redux/actions/customer-setting.action";
import { setSelectedCustomer } from "../../../redux/actions/event-save.action";
//new code

const bookingSchema = yup.object().shape({
	e_pax: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	e_rate: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	booking_type: yup.string().required("Booking Type Required"),
	booking_catering: yup.string().required("Catering Type Required"),
	booking_notes: yup.string(),
	halls: yup
		.mixed()
		.transform((_, value) =>
			typeof value === "string"
				? [value]
				: value.length > 0
				? value
				: null
		)
		.required("Booking Halls Required"),
});

function BookingInfoComponent({
	event,
	type,
	company_role,
	closeBooking,
	event_vat_status,
}) {
	// new code sanat
	let { selectedCustomer } = useSelector((state) => state.savedBooking);
	const { customerList, isLoading } = useSelector(
		(state) => state.customerSetting
	);
	const [show, setShow] = useState(false);
	const [searchCustomer, setSearchCustomer] = useState(true);
	const [showSwitchCustomerModal, setShowSwitchCustomerModal] =
		useState(false);

	//old code
	const { register, handleSubmit, reset, errors } = useForm({
		mode: "onSubmit",
		defaultValues: { halls: [] },
		resolver: yupResolver(bookingSchema),
	});
	const dispatch = useDispatch();
	const { bookingData, bookingList } = useSelector((state) => state.booking);
	const payment = useSelector((state) => state.payment);
	const [eventCreatorName, setEventCreatorName] = useState("");
	const [week, setWeek] = useState("");
	const [venue, setVenue] = useState("");
	const [availabelHalls, setAvailableHalls] = useState([]);
	const [venueColorCode, setVenueColorCode] = useState("");
	const [nepDate, setNepDate] = useState([]);
	const [editableFlag, setEditableFlag] = useState(false);
	const [createdDate, setCreatedDate] = useState("");

	useEffect(() => {
		try {
			// sanat - replace bookingData with event
			if (!isEmptyObject(event)) {
				setNepDate(
					nepMonthFullDate(event.booked_date_nepali).split(" ")
				);
				setEventCreatorName(
					event.bookingEmployee_first_name +
						" " +
						event.bookingEmployee_last_name
				);
				setWeek(moment(event.booked_date_english).format("ddd"));
				// setVenue(
				// 	event.booking_halls[0].hall_info.v_venue.venue_name
				// );
				// setVenueColorCode(
				// 	event.booking_halls[0].hall_info.v_venue
				// 		.venue_color_code
				// );

				setVenue(event.venue_name);
				setVenueColorCode(event.venue_color_code);
				setAvailableHalls(event.availableHallArray);
				setCreatedDate(moment(event.createdAt).format("DD/MM/YYYY"));
			}

			let bookingUpdateData = {
				e_pax: event?.e_pax,
				e_rate: event?.e_rate,
				booking_type: event?.booking_type,
				booking_catering: event?.booking_catering,
				booking_notes: event?.booking_notes,
				halls: event?.halls,
			};

			reset({ ...bookingUpdateData });
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [event, type, dispatch, reset, bookingData]);

	const style = {
		backgroundColor: venueColorCode,
	};

	const statusStyle = {
		"--bg": `var(--${event.booking_status})`,
	};

	const timeStyle = {
		"--bg": `var(--${event.booked_time})`,
	};

	const _saveBooking = async (booking) => {
		let updatedBooking = { ...bookingData, ...booking };
		/* setting up data as per backend demands */
		for (var i = 0; i < updatedBooking.availableHallArray.length; i++) {
			for (var j = 0; j < updatedBooking.halls.length; j++) {
				if (
					updatedBooking.availableHallArray[i].hall_info_id ===
					updatedBooking.halls[j]
				) {
					updatedBooking.availableHallArray[i].checked = true;
					break;
				} else {
					updatedBooking.availableHallArray[i].checked = false;
				}
			}
		}
		updatedBooking.booking_halls = updatedBooking.availableHallArray;
		//new code by sanat for specifying which has been updated
		delete updatedBooking.booking_snacks;
		delete updatedBooking.booking_desserts;
		delete updatedBooking.booking_drinks;
		delete updatedBooking.booking_services;

		// new code sanat
		let eventInfo = {
			event_info_id: updatedBooking.event_info_id,
			company_customer_id: selectedCustomer.company_customer_id,
			venue_info_id: bookingData.venue_info_id,
		};

		!isEmptyObject(selectedCustomer) &&
			(await dispatch(updateEventInfo(eventInfo)));

		await dispatch(updateBooking(updatedBooking, null, null, "details"));
		setEditableFlag(false);
	};

	const _onCancelClick = () => {
		// new code sanat
		setShow(false);
		setEditableFlag(false);
		setSearchCustomer(true);
		// new code sanat
		dispatch(fetchCustomerSuccess([]));
		dispatch(setSelectedCustomer({}));
		reset();
	};

	/// new code
	const _handleAddCustomerModalClose = () => setShow(false);
	const _handleAddCustomerModalShow = () => setShow(true);
	const _handleSwitchCustomerClose = () => setShowSwitchCustomerModal(false);
	const _handleSwitchCustomerShow = () => setShowSwitchCustomerModal(true);
	const _handleCompleteBooking = () => {
		closeBooking();
	};

	const selectCustomer = (val) => {
		/* saving company_customer_id outside of company customer array as same as of duplicate customer */
		let company_customer_id = val.company_customer_id;
		val = { ...val, company_customer_id };
		dispatch(setSelectedCustomer(val));
	};

	function restSelectedCustomer() {
		// new code sanat
		setShow(false);
		setSearchCustomer(false);
		_handleSwitchCustomerClose();
		// new code sanat
		dispatch(fetchCustomerSuccess([]));
		dispatch(setSelectedCustomer({}));
	}

	const _renderDetailsDisplay = () => {
		let viewer_rate = bookingData.e_rate;
		let viewer_pax = bookingData?.e_pax;
		if (company_role === "viewer") {
			if (bookingData?.e_rate > 600) {
				viewer_rate = `${
					bookingData.e_rate - Math.round(bookingData.e_rate * 0.5)
				} - ${bookingData.e_rate}`;
			}
			if (bookingData.e_pax > 200) {
				viewer_pax = `${
					bookingData.e_pax - Math.round(bookingData.e_pax * 0.5)
				} - ${bookingData.e_pax}`;
			}
		}
		/*Render Conditions:
		All users will see Estimates when booking is open
		Viewers will not see Estimates if booking is open and vat is recorded and payment is complete
		All users will see Actuals only when vat is recorded */
		return (
			<div className="main-inner-container">
				{!editableFlag && event.vat_status !== "none" && (
					<>
						<div className="inner-content rows ">
							<TextInputWithErrorComponent
								variant="form-md"
								label="Act. Pax"
								value={bookingData.a_pax}
								type="number"
								disabled
							/>
							<TextInputWithErrorComponent
								variant="form-md"
								label="Act. Rate"
								value={bookingData.a_rate}
								type="number"
								disabled
							/>
						</div>
						<div className="inner-content rows ">
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Act. Service"
								disabled
								value={bookingData.a_service}
							/>
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Booking Type"
								name="booking_type"
								ref={register}
								disabled
							/>
						</div>
					</>
				)}
				{!editableFlag
					? (event.vat_status === "none" ||
							company_role !== "viewer" ||
							payment.bookingPayment.payment_status !==
								"complete") && (
							<div className="inner-content rows ">
								<TextInputWithErrorComponent
									variant={"form-md"}
									label="Est. Pax*"
									disabled
									value={viewer_pax}
								/>
								<TextInputWithErrorComponent
									variant={"form-md"}
									label="Est. Rate*"
									disabled
									value={viewer_rate}
								/>
							</div>
					  )
					: payment.bookingPayment.payment_status !== "complete" && (
							<div className="inner-content rows ">
								<TextInputWithErrorComponent
									variant={
										editableFlag
											? "form-md edit "
											: "form-md"
									}
									label="Estd. Pax"
									name="e_pax"
									ref={register}
									error={errors?.e_pax?.message}
									type="number"
									disabled={!editableFlag}
								/>
								<TextInputWithErrorComponent
									variant={
										editableFlag
											? "form-md edit "
											: "form-md"
									}
									label="Estd. Rate"
									name="e_rate"
									ref={register}
									error={errors?.e_rate?.message}
									type="number"
									disabled={!editableFlag}
								/>
							</div>
					  )}

				{editableFlag ? (
					<div className="inner-content rows ">
						<FormSelectBox
							label="Booking Type*"
							variant="form-sm"
							options={strings.eventType}
							name="booking_type"
							ref={register}
							error={errors?.booking_type?.message}
							disabled={!editableFlag}
						/>
						<FormSelectBox
							label="Catering Type*"
							variant="form-sm"
							options={strings.cateringType}
							name="booking_catering"
							ref={register}
							error={errors?.booking_catering?.message}
							disabled={!editableFlag}
						/>
					</div>
				) : (
					event.vat_status === "none" && (
						<div className="inner-content rows ">
							<FormSelectBox
								label="Booking Type*"
								variant="form-sm"
								options={strings.eventType}
								name="booking_type"
								ref={register}
								error={errors?.booking_type?.message}
								disabled={!editableFlag}
							/>
							<FormSelectBox
								label="Catering Type*"
								variant="form-sm"
								options={strings.cateringType}
								name="booking_catering"
								ref={register}
								error={errors?.booking_catering?.message}
								disabled={!editableFlag}
							/>
						</div>
					)
				)}

				<div className="inner-content cols">
					<FormTextArea
						// label="Terms & Condition"
						variant={
							event.vat_status === "none" || editableFlag
								? "form-notes"
								: "form-notes-sm"
						}
						name="booking_notes"
						ref={register}
						placeholder="Notes"
						disabled={!editableFlag}
					/>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="inner-container mt-30px">
				<ProfileSummary
					// handleClick={handleShow}
					details={
						isEmptyObject(selectedCustomer)
							? bookingList
							: selectedCustomer
					}
					switchCustomer={
						editableFlag &&
						!event_vat_status &&
						_handleSwitchCustomerShow
					}
				/>
				<SwitchCustomerModal
					isVisible={showSwitchCustomerModal}
					handleClose={_handleSwitchCustomerClose}
					handleShow={_handleSwitchCustomerShow}
					yesOnClick={restSelectedCustomer}
				/>
				<div className="main-inner-container ">
					<div className="inner-content cols">
						<div className="venue-name-wrapper bg-default">
							<div
								className="venue-color-container bodered"
								style={style}
							/>
							<label
								htmlFor=""
								className="venue-name-label overflow"
							>
								{venue}
							</label>
						</div>

						<div className="booking-halls-wrapper mt-15px">
							<div className="form-label">Hall Selection</div>
							<BookingHallsCheckbox
								tempBookingId={
									type === "add"
										? event.tempBookingId
										: bookingData.booking_info_id
								}
								halls={availabelHalls}
								name="halls[]"
								ref={register}
								error={errors?.halls?.message}
								disabled={!editableFlag}
							/>
						</div>

						<div className="inner-content booking-day-wrapper mt-15px rows">
							<div
								className="booking-day-container bodered"
								style={timeStyle}
							>
								<div className="booking-day-icon">
									<i className="fa fa-2x fa-sun"></i>
								</div>
								<label className="booking-day-label">
									{week}
								</label>
								<label className="booking-time-label">
									{event.booked_time}
								</label>
							</div>

							<div className="inner-content booking-date-container cols">
								<div className="booking-date-content bg-default">
									<div className="booking-day">
										{moment(
											event?.booked_date_english
										).format("DD")}
									</div>
									<div className="booking-year">
										{moment(
											event?.booked_date_english
										).format("YYYY")}
									</div>
									<div className="booking-month">
										{moment(
											event?.booked_date_english
										).format("MMMM")}
									</div>
								</div>
								<div className="booking-date-content bg-default">
									<div className="booking-day">
										{nepDate[0]}
									</div>
									<div className="booking-year">
										{nepDate[2]}
									</div>
									<div className="booking-month">
										{nepDate[1]}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{!isEmptyObject(bookingData) && _renderDetailsDisplay()}
				<div className="main-inner-container">
					<div className="inner-content cols">
						<div
							className="booking-status-container bodered"
							style={statusStyle}
						>
							{event.booking_status}
						</div>
					</div>

					<div className="inner-content rows mt-15px gap-3">
						<div className="booking-id-wrapper bg-default">
							<div className="id-icon-container">
								<i className="fa fa-2x fa-hashtag"></i>
							</div>
							<div className="id-container">
								<label className="id-title-label">
									Booking ID
								</label>
								<label className="id-label">
									{event.booking_id || "-"}
								</label>
							</div>
						</div>

						{company_role !== "viewer" && (
							<div className="booking-id-wrapper bg-default">
								<div className="id-icon-container">
									<i className="fa fa-2x fa-hashtag"></i>
								</div>
								<div className="id-container">
									<label className="id-title-label">
										Event ID
									</label>
									<label className="id-label">
										{bookingList.event_id || "-"}
									</label>
								</div>
							</div>
						)}
					</div>
					<div className="inner-content rows mt-30px">
						<TextInputWithErrorComponent
							variant={"form-md"}
							label="Sales"
							disabled
							defaultValue={eventCreatorName}
						/>
						<TextInputWithErrorComponent
							variant={"form-md"}
							label="Created Date"
							disabled
							defaultValue={createdDate}
						/>
					</div>

					{editableFlag ? (
						<div className="inner-content cols button">
							<button
								className="button-primary"
								onClick={_onCancelClick}
							>
								Cancel
							</button>
							<button
								className="button-primary"
								onClick={handleSubmit(_saveBooking)}
							>
								Save
							</button>
						</div>
					) : (
						<div className="inner-content cols button">
							{payment.bookingPayment.payment_status ===
								"complete" &&
							event.vat_status !== "none" &&
							bookingData.booking_status !== "complete" ? (
								<div
									className="persistent-button-wrapper"
									onClick={_handleCompleteBooking}
								>
									<div className="persistent-button">
										Complete Booking
									</div>
								</div>
							) : (
								<button
									className="button-primary booking-action-button"
									onClick={() => setEditableFlag(true)}
									disabled={
										bookingData.booking_status ===
											"canceled" ||
										bookingData.booking_status ===
											"complete" ||
										payment.bookingPayment
											.payment_status === "complete"
									}
								>
									Edit
								</button>
							)}
						</div>
					)}
				</div>
			</div>
			{/* new code sanat */}
			<div hidden={searchCustomer}>
				{isLoading && <Loader />}

				<div className="inner-container full">
					{isEmptyObject(selectedCustomer) ||
					customerList.length < 0 ? (
						<SearchCustomerComponent
							type="calendar"
							handleAdd={_handleAddCustomerModalShow}
						/>
					) : (
						<></>
						// <div className="inner-container mt-30px">
						// 	<ProfileSummary
						// 		// handleClick={handleShow}
						// 		details={selectedCustomer}
						// 		switchCustomer={_handleSwitchCustomerShow}
						// 	/>
						// </div>
					)}

					{isEmptyObject(selectedCustomer) &&
						customerList.length > 0 && (
							<div className="inner-container full">
								<CustomerTable
									data={customerList}
									onCustomerSelect={selectCustomer}
								/>
							</div>
						)}
				</div>

				<AddCustomerModal
					isVisible={show}
					handleClose={_handleAddCustomerModalClose}
					handleShow={_handleAddCustomerModalShow}
					actionType="Add"
					from="calendar"
				/>
			</div>
			{/*  new code sanat */}
		</>
	);
}

export default BookingInfoComponent;

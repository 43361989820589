import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextInputWithErrorComponent from "../common/text-input/text-input-with-error.component";
import PaymentTypeRadio from "../common/radio-button/payment-type.radio";
import strings from "../../global/strings";
import {
	addQuickPayment,
	fetchBookingPayment,
} from "../../redux/actions/payment-action";
import { getQuickPaymentBooking } from "../../redux/actions/booking-action";
import { isEmptyObject, rupeeConverter } from "../../global/function";
import "./quick-add-payment.style.scss";
import moment from "moment";

const paymentSchema = yup.object().shape(
	{
		// booking_id: yup.string().required("Please enter booking id"),
		payment_amount: yup.string().when("discount_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),
			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
		payment_option: yup.string().required("Please select payment option"),
		cheque_number: yup.string().when("payment_option", {
			is: (value) => value === "cheque",
			then: yup.string().required("Please enter cheque number"),
		}),
		discount_amount: yup.string().when("payment_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),
			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
	},
	["payment_amount", "discount_amount", "payment_option"]
);

export default function QuickAddPaymentModal(props) {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, watch } = useForm({
		mode: "onSubmit",
		defaultValues: {},
		resolver: yupResolver(paymentSchema),
	});

	const [checkBooking, setCheckBooking] = useState({
		booking_id: "0",
		selected: false,
		payAllowed: false,
	});
	const [bookingDetails, setBookingDetails] = useState({});
	const [bookingPayment, setBookingPayment] = useState({});

	let paymentOption = watch("payment_option");

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
		h2: {
			color: "red",
		},
	};

	useEffect(() => {
		if (!isEmptyObject(bookingPayment)) {
			setCheckBooking({
				booking_id: "0",
				selected: true,
				payAllowed: false,
			});
		}
	}, [bookingPayment]);

	//Storing booking id from field entry
	const _handleBooking = (e) => {
		setCheckBooking({
			booking_id: e.target.value,
			selected: false,
			payAllowed: false,
		});
	};
	//clearing data on booking id field entry
	const _handleOnFocusBooking = () => {
		if (!isEmptyObject(bookingPayment)) {
			setBookingPayment({});
		}
	};

	//check if booking exists
	const _handleBookingCheck = async () => {
		if (checkBooking.booking_id !== "0" && checkBooking.booking_id !== "") {
			let checkBookingState = checkBooking;
			let booking = await dispatch(
				getQuickPaymentBooking(checkBooking.booking_id)
			);

			if (!isEmptyObject(booking)) {
				setCheckBooking({
					booking_id: "0",
					selected: false,
					payAllowed: false,
				});
				setBookingDetails(booking);
			} else {
				setBookingDetails({});
				setCheckBooking({
					...checkBookingState,
					placeholderMessage: "Booking does not exist",
				});
			}
		}
	};
	//check if booking is selected
	const _handleBookingSelection = async () => {
		if (
			bookingDetails.booking_status !== "canceled" &&
			bookingDetails.payment_status !== "complete"
		) {
			await dispatch(
				fetchBookingPayment(bookingDetails.booking_info_id)
			).then((result) => {
				setBookingPayment(result);
			});
		} else {
			setCheckBooking({
				booking_id: "0",
				selected: true,
				payAllowed: true,
			});
			setBookingPayment({});
		}
	};

	//control all data clear on close
	const _handleCancel = async (data) => {
		setCheckBooking({
			booking_id: "0",
			selected: false,
			payAllowed: false,
		});
		setBookingDetails({});
		setBookingPayment({});
		props.handleClose();
	};

	//final payment submission
	const _handlePaymentSubmit = async (data) => {
		let payment = {
			...data,
			booking_info_id: bookingDetails.booking_info_id,
		};
		let response = await dispatch(addQuickPayment(payment));
		//Print the quick payment receipt
		if (response) {
			let printdata = {
				customer_name: `${bookingDetails.customer_fname} ${bookingDetails.customer_lname}`,
				contact_info: "",
				booked_date_nepali: bookingDetails.booked_date_nepali,
				booked_date_english: bookingDetails.booked_date_english,
				book_time: bookingDetails.booked_time,
				customerEmail: "",
				booking_id: bookingDetails.booking_id,
				payment_id: "",
				transaction_type: "payment",
				payment_type: payment.payment_option,
				payment_date: moment(data.createdAt).format("YYYY-MM-DD"),
				paid_amount: payment.payment_amount,
			};
			props.paymentPrintView(printdata);
		}
		_handleCancel();
	};
	function _renderBalances() {
		let g_Bal =
			bookingPayment.totalEstimatedBookingCost -
			bookingPayment.totalHoldingPayment;
		let a_Bal =
			bookingPayment.totalEstimatedBookingCost +
			bookingPayment.vatAmount -
			bookingPayment.totalHoldingPayment;

		if (bookingPayment.vat_status !== "none") {
			return (
				<div className="booking-content right">
					<p>{rupeeConverter(g_Bal.toFixed(2), "summary")}</p>
					<p>{rupeeConverter(a_Bal.toFixed(2), "summary")}</p>
				</div>
			);
		} else {
			return (
				<div className="booking-content right">
					<p>{rupeeConverter(g_Bal.toFixed(2), "summary")}</p>
					<p>{rupeeConverter(a_Bal.toFixed(2), "summary")}</p>
				</div>
			);
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Add Payment</div>
				</div>
				<div className="form-body">
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								name="booking_id"
								type="number"
								variant="form-md edit "
								placeholder="Booking ID"
								label="Booking ID"
								value={checkBooking.booking_id}
								// ref={register}
								onChange={(e) => _handleBooking(e)}
								onFocus={_handleOnFocusBooking}
							/>
							<button
								className="special-button button-primary"
								onClick={_handleBookingCheck}
							>
								Check Booking
							</button>
						</div>

						{!isEmptyObject(bookingDetails) ? (
							<div className="form-input-container inner">
								<div
									className={`quick-add-booking bg-default ${
										checkBooking.selected && "selected"
									}`}
									onClick={_handleBookingSelection}
								>
									<div className="booking">
										<div className="booking-content capitalize">
											<p>
												<b>
													{bookingDetails.venue_name}
												</b>
											</p>
											<p>
												{bookingDetails.customer_fname +
													" " +
													bookingDetails.customer_lname}
											</p>
										</div>
										<div className="booking-content right">
											<p>Gross Rs. </p>
											<p>Total Rs. </p>
										</div>
										{!isEmptyObject(bookingPayment) &&
											_renderBalances()}
									</div>
									<hr />
									<div className="booking">
										<div className="booking-content">
											<p>
												<b>BID:</b>{" "}
												{bookingDetails.booking_id}
											</p>
											<p>
												<b>TIME:</b>{" "}
												{bookingDetails.booked_time}
											</p>
										</div>
										<div className="booking-content">
											<p>
												<b>Date ENG:</b>
											</p>
											<p>
												<b>Date AD:</b>
											</p>
										</div>
										<div className="booking-content">
											<p>
												{
													bookingDetails.booked_date_english
												}
											</p>
											<p>
												{
													bookingDetails.booked_date_nepali
												}
											</p>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="form-input-container inner">
								<div className="quick-booking-placeholder bg-default">
									{checkBooking?.placeholderMessage ||
										"Search a booking ID"}
								</div>
							</div>
						)}
					</div>
					<hr />
					{checkBooking.selected &&
						!isEmptyObject(bookingPayment) && (
							<>
								<div className="form-input-container ">
									<div className="form-input-container inner">
										<TextInputWithErrorComponent
											name="cheque_number"
											type="number"
											variant="form-md edit "
											placeholder="Cheque Number"
											label="Cheque Number"
											ref={register}
											error={
												errors?.cheque_number?.message
											}
											disabled={
												paymentOption !== "cheque"
											}
										/>
									</div>
									<div className="form-input-container inner">
										<PaymentTypeRadio
											name="payment_option"
											ref={register}
											paymentType={strings.paymentType}
											error={
												errors?.payment_option?.message
											}
										/>
									</div>
								</div>
								<div className="form-input-container ">
									<div className="form-input-container inner">
										<TextInputWithErrorComponent
											name="payment_amount"
											type="number"
											variant="form-md edit "
											placeholder="Payment Amount"
											label="Payment Amount"
											ref={register}
											error={
												errors?.payment_amount?.message
											}
										/>
										<TextInputWithErrorComponent
											name="discount_amount"
											type="number"
											variant="form-md edit "
											placeholder="Discount Amount"
											label="Discount Amount"
											ref={register}
											error={
												errors?.discount_amount?.message
											}
										/>
									</div>
									<div className="form-input-container inner">
										<TextInputWithErrorComponent
											name="notes"
											variant="form-lg edit "
											placeholder="Notes"
											label="Payment Notes"
											ref={register}
										/>
									</div>
								</div>
							</>
						)}
					{checkBooking.payAllowed && (
						<div className="form-input-container">
							<div className=" bg-default clearwarn">
								Booking is either canceled or all cleared
							</div>
						</div>
					)}
				</div>
				<div className="form-footer two">
					<button className="button-primary" onClick={_handleCancel}>
						Cancel
					</button>
					<button
						className="button-primary"
						onClick={handleSubmit(_handlePaymentSubmit)}
						disabled={!checkBooking.selected}
					>
						Add Payment
					</button>
				</div>
			</div>
		</Modal>
	);
}

/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
// import "./payment.style.scss";
import { recordVatDetails } from "../../redux/actions/payment-action";
import { useDispatch, useSelector } from "react-redux";
import BookingSummaryPayment from "../../components/booking/booking-summary/booking-summary-payment-component";
import { isEmptyObject } from "../../global/function";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInputWithErrorComponent from "../../components/common/text-input/text-input-with-error.component";
import FormTextareaInputComponent from "../../components/common/text-input/form-textarea-input.component";

const vatSchema = yup.object().shape({
	issue_date: yup.string().required("Issue Date is required"),
	vat_bill_number: yup
		.number()
		.required("VAT Bill Number is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	vat_amount: yup
		.number()
		.required("VAT Amount is required. Please select booking")
		.positive("Must be greater than zero. Please select booking"),
	// customer_vat_number: yup.number().typeError("Must be a number"),
});

export default function VatPaymentComponent(props) {
	const dispatch = useDispatch();
	const { bookingPayment, eventPayment } = useSelector(
		(state) => state.payment
	);

	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(vatSchema),
	});

	const [paymentType, setPaymentType] = useState(1);
	const [blockStatus, setBlockStatus] = useState(false);
	const [blockMessage, setBlockMessage] = useState("Cannot initiate payment");

	useEffect(() => {
		setPaymentType(props.paymentType);

		if (props.paymentType === "booking") {
			if (bookingPayment.vat_status === "exists") {
				setBlockStatus(true);
				setBlockMessage("VAT Bill has been recorded");
			}
			if (bookingPayment.totalBalance > 0) {
				setBlockStatus(true);
				setBlockMessage(
					"You need to clear the payment first to record VAT Bill"
				);
			}
		} else if (props.paymentType === "event") {
			setBlockStatus(true);
			setBlockMessage(
				"VAT Bill has been recorded or Please clear all due payments"
			);
			if (!isEmptyObject(eventPayment)) {
				eventPayment.bookingPayments.map((payment) => {
					if (
						payment.selection_status === "unselected" ||
						payment.selection_status === "selected"
					) {
						setBlockStatus(false);
					}
				});
			}
		}
	}, [props, eventPayment, bookingPayment]);

	const payOnClick = async (data) => {
		let bookings = [];
		if (props.paymentType === "booking") {
			bookings.push(bookingPayment.booking_info_id);
			data["vat_amount"] = bookingPayment.vatAmount;
			data["event_info_id"] = bookingPayment.event_info_id;
			data["venue_info_id"] = bookingPayment.venue_info_id;
			// data["issue_date"] = bookingPayment.issue_date;
		} else {
			eventPayment.bookingPayments.map((booking) => {
				if (booking.selection_status === "selected") {
					bookings.push(booking.booking_info_id);
				}
			});
			data["vat_amount"] = eventPayment.selectedTotalVat;
			data["event_info_id"] = eventPayment.event_info_id;
			data["venue_info_id"] = eventPayment.venue_info_id;
			// data["issue_date"] = eventPayment.issue_date;
		}

		data["bookings"] = bookings;
		data["booking_vat_status"] = "exists";

		let success = await dispatch(recordVatDetails(data));

		if (!isEmptyObject(success)) {
			props.setPaymentSuccessFlag(true);
		}
	};

	const _renderBookingDetailsView = () => {
		return (
			<div className="inner-container mt-30px">
				{props.detailsView}
				<div className="main-inner-container">
					<div className="inner-content cols ">
						<TextInputWithErrorComponent
							variant="form-lg edit "
							placeholder="VAT Issue Date"
							label="Date of Issue"
							name="issue_date"
							ref={register}
							error={errors?.issue_date?.message}
							type="date"
						/>
						<TextInputWithErrorComponent
							variant="form-lg edit "
							placeholder="VAT Bill Number"
							label="VAT Bill Number"
							name="vat_bill_number"
							ref={register}
							error={errors?.vat_bill_number?.message}
							type="number"
						/>
						<div className="hidden">
							<TextInputWithErrorComponent
								name="vat_amount"
								ref={register}
								value={
									props.paymentType === "booking"
										? bookingPayment.totalBillAmount
										: eventPayment.selectedTotalPayment +
										  eventPayment.selectedTotalVat
								}
								readOnly={true}
							/>
						</div>
						<TextInputWithErrorComponent
							variant="form-lg edit "
							placeholder="VAT Amount"
							label="VAT Amount"
							value={
								props.paymentType === "booking"
									? bookingPayment.totalBillAmount
									: eventPayment.selectedTotalPayment +
									  eventPayment.selectedTotalVat
							}
							error={errors?.vat_amount?.message}
							disabled={true}
						/>
					</div>
				</div>
				<div className="main-inner-container">
					<div className="inner-content cols ">
						<TextInputWithErrorComponent
							variant="form-lg edit "
							label="Customer VAT/PAN"
							placeholder="Customer VAT Number"
							name="customer_vat_number"
							ref={register}
							type="text"
						/>
						<FormTextareaInputComponent
							label="Payment Notes"
							variant="form-notes"
							name="notes"
							ref={register}
							placeholder="Additional Notes Here…"
							// disabled={!editableFlag}
						/>
					</div>
				</div>
				<div className="main-inner-container text-md">
					<span>Selected VAT Details</span>

					<div className="inner-content cols mt-15px">
						<span htmlFor="" className="paying-amount-title vat">
							Total Gross Amount :
							<span style={{ float: "right" }}>
								Rs.{" "}
								{props.paymentType === "booking"
									? bookingPayment.totalBookingCost
									: eventPayment.selectedTotalPayment}
							</span>
						</span>
						<span htmlFor="" className="paying-amount-title vat">
							Total VAT :{" "}
							<span style={{ float: "right" }}>
								Rs.{" "}
								{props.paymentType === "booking"
									? bookingPayment.vatAmount
									: eventPayment.selectedTotalVat}
							</span>
						</span>
						<span htmlFor="" className="paying-amount-title vat">
							Total Net Amout :
							<span style={{ float: "right" }}>
								Rs.{" "}
								{props.paymentType === "booking"
									? bookingPayment.totalBillAmount
									: eventPayment.selectedTotalVat +
									  eventPayment.selectedTotalPayment}
							</span>
						</span>
					</div>
					{!blockStatus && (
						<div className="inner-content button">
							<button className="button-primary">Clear</button>
							<button
								className="colored-button-wrapper bg-buttonSuccess text-fontWhite"
								onClick={handleSubmit(payOnClick)}
							>
								Submit
							</button>
						</div>
					)}
				</div>

				{blockStatus && (
					<div className="payment-button-container message">
						{blockMessage}
					</div>
				)}
			</div>
		);
	};
	return (
		<div>
			<div className="new-payment-header">Bookings</div>
			<div className="inner-container full">
				<div className="flex flex-wrap flex-row gap-15px ">
					{paymentType === "booking"
						? !isEmptyObject(bookingPayment) && (
								<BookingSummaryPayment event={bookingPayment} />
						  )
						: !isEmptyObject(eventPayment) &&
						  eventPayment.bookingPayments.map((b, key) => {
								return (
									<BookingSummaryPayment
										event={b}
										key={key}
										paymentFor="vat"
									/>
								);
						  })}
				</div>
			</div>
			{_renderBookingDetailsView()}
		</div>
	);
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleTextInput from "../../components/common/text-input/text-input-with-error.component";
import Loader from "../../components/loader/loader.component";
import DeleteConfirmation from "../../components/modals/delete-confirmation.modal";
import UpdateRequestTable from "../../components/tables/update-request.table";
import { isEmptyObject } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";
import strings from "../../global/strings";
import {
	deleteRequest,
	fetchSingleRequest,
	updateRequest,
} from "../../redux/actions/request.action";

function UpdateRequestPage(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { requestItem, isLoading } = useSelector((s) => s.request);
	const [modalFlag, setModalFlag] = useState(false);

	const showModal = () => {
		setModalFlag(true);
	};
	const hideModal = () => {
		setModalFlag(false);
	};

	useEffect(() => {
		dispatch(
			fetchSingleRequest(
				props.location.state.location_id,
				props.location.state.id
			)
		);
	}, [dispatch, props]);

	const _updateRequest = async () => {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}
		let status = await dispatch(
			updateRequest({
				...requestItem,
				location_id: localStorage.getItem("location_id"),
			})
		);
		if (status) {
			_back();
		}
	};

	const _deleteRequest = async () => {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}
		let status = await dispatch(
			deleteRequest(
				{
					...requestItem,
					location_id: localStorage.getItem("location_id"),
				},
				requestItem._id
			)
		);
		if (status) {
			_back();
		}
	};
	const _back = () => {
		history.push({
			pathname: "/inventory",
			state: {
				tab: 2,
			},
		});
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => _back()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt;{" "}
					<span className="current-page-path">Update Request</span>
				</div>
			</div>

			{!isEmptyObject(requestItem) && (
				<div className="inner-container mt-52px full">
					<div className="flex flex-row bg-default w-full mt-30px p-15px rounded-sm flex-wrap gap-24px">
						<SimpleTextInput
							variant="form-md"
							label="Request ID"
							defaultValue={requestItem.request_id}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="Request Date"
							defaultValue={
								moment(requestItem.created_at).format(
									"YYYY-MM-DD"
								) || 0
							}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="Due Date"
							defaultValue={
								moment(requestItem.due_date).format(
									"YYYY-MM-DD"
								) || 0
							}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="Request Status"
							defaultValue={requestItem.request_status || 0}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="User "
							defaultValue={
								requestItem?.requested_by[0]?.name || 0
							}
							disabled
						/>

						<div className="h-52 w-135 relative">
							<div className=" absolute text-sm text-fontSemiDark">
								Order
							</div>
							<div className=" absolute bottom-0px text-lg">
								New Order
							</div>
							<button className="button-primary-icon absolute bottom-0px right-0px ">
								<i className="fas fa-plus text-buttonSuccess"></i>
							</button>
						</div>
					</div>
				</div>
			)}
			<div className="inner-container full">
				{!isEmptyObject(requestItem) &&
					Object.keys(requestItem.request_list).map((item) => (
						<UpdateRequestTable
							list={requestItem.request_list[item]}
							cat={item}
							key={item}
						/>
					))}
			</div>
			<div className="inner-container full ">
				<div className="flex flex-row gap-15px justify-end button">
					<button
						className="colored-button-wrapper bg-buttonSuccess text-whiteText"
						onClick={_updateRequest}
					>
						Update
					</button>
					<button
						className="colored-button-wrapper bg-buttonFailed text-whiteText"
						onClick={showModal}
					>
						Delete
					</button>
				</div>
			</div>
			<DeleteConfirmation
				strings={strings.deleteRequest}
				isVisible={modalFlag}
				handleClose={hideModal}
				handleShow={showModal}
				confirmButton={_deleteRequest}
			/>
		</div>
	);
}

export default UpdateRequestPage;

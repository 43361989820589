import React, { useEffect } from "react";
import NavBar from "../components/navbar/nav-bar/nav-bar.component";
import SideNavComponent from "../components/side-navigation/side-navigation.component";
import ContactSupportModal from "../components/modals/contact-support.modal";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { processLogin } from "../redux/actions/login-action";

export default function ProtectedRoute(props) {
	const history = useHistory();
	const dispatch = useDispatch();

	let isLoggedIn = localStorage.getItem("isLoggedIn");
	let navigation = true;
	if (props.navigation === false) {
		navigation = false;
	}

	useEffect(() => {
		let search = props.location.search;
		let params = new URLSearchParams(search);
		let user = params.get("user");
		let company = params.get("company");

		async function clientLogin() {
			await dispatch(
				processLogin(
					{ user_info_id: user, company_info_id: company },
					true
				)
			);
		}
		user &&
			company &&
			clientLogin().then(() => {
				history.push({ pathname: "/dashboard" });
			});
	}, []);

	function eventListenerToggle(e) {
		var sideNav = document.getElementById("side-navigation-container");
		var version = document.getElementById("version-content");
		var btnText = document.getElementsByClassName("btn-menu-sidenav");
		if (e.target?.id !== "nav") {
			if (sideNav.style.width === "200px") {
				sideNav.style.width = "0px";
				version.style.display = "none";
				for (let i = 0; i < btnText.length; i++) {
					btnText[i].style.display = "none";
				}
				window.removeEventListener("click", eventListenerToggle);
			}
		}
	}

	const toggleMenu = () => {
		var sideNav = document.getElementById("side-navigation-container");
		var version = document.getElementById("version-content");
		var btnText = document.getElementsByClassName("btn-menu-sidenav");

		if (sideNav.style.width === "200px") {
			sideNav.style.width = "0px";
			version.style.display = "none";
			for (let i = 0; i < btnText.length; i++) {
				btnText[i].style.display = "none";
			}
			window.removeEventListener("click", eventListenerToggle);
		} else {
			sideNav.style.width = "200px";
			version.style.display = "block";

			for (let i = 0; i < btnText.length; i++) {
				btnText[i].style.display = "flex";
				btnText[i].style.width = "100%";
			}
			window.addEventListener("click", eventListenerToggle);
		}
	};

	return (
		<Route
			path={props.path}
			render={(data) =>
				isLoggedIn ? (
					navigation === true ? (
						<div className="main-wrapper">
							<div className="nav-wrapper">
								<NavBar toggleSideNav={toggleMenu} />
							</div>

							<div className="content-wrapper">
								<div className="side-nav-wrapper">
									<SideNavComponent />
								</div>

								<ContactSupportModal from={props.path} />
								<props.component {...data}></props.component>
							</div>
						</div>
					) : (
						<props.component {...data}></props.component>
					)
				) : (
					<Redirect to="/" />
				)
			}
		></Route>
	);
}

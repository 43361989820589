/* eslint-disable array-callback-return */
import React from "react";
import MenuItemContainer from "../../../components/add-menu-services/menu-item-container/menu-item-container.component";
import ServiceItemContainer from "../../../components/add-menu-services/service-item-container/service-item-container.component";
import DrinkItemContainer from "../../../components/add-menu-services/drink-item-container/drink-item-container.component";

export function SelectedMenuServiceComponent({ list, listTitle }) {
	return (
		<div className="">
			<label className="capitalize text-fontSemiDark text-md">
				{listTitle}
			</label>

			<div className=" flex flex-row flex-wrap gap-10px">
				{(listTitle === "snacks" ||
					listTitle === "meals" ||
					listTitle === "soup" ||
					listTitle === "salad" ||
					listTitle === "dessert") &&
					list.map((menu) => {
						if (menu.menu_category === listTitle) {
							return (
								<MenuItemContainer
									item={menu}
									type="saved"
									key={menu.menu_info_id}
								/>
							);
						}
					})}

				{listTitle === "service" &&
					list.map((item) => (
						<ServiceItemContainer
							item={item}
							key={item.service_info_id}
							service_type={item.service_type_name}
							type="saved"
							view={true}
						/>
					))}

				{listTitle === "drink" &&
					list.map((item) => (
						<DrinkItemContainer
							item={item}
							key={item.drink_info_id}
							drink_type={item.drink_type}
							type="saved"
							view={true}
						/>
					))}
			</div>
		</div>
	);
}

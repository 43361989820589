/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./booking-summary.component.scss";
import { isEmptyObject } from "../../../global/function";
import { useDispatch } from "react-redux";
import { updateBookingPaymentSelectionStatus } from "../../../redux/actions/payment-action";
import moment from "moment";

export default function BookingSummaryPayment({ event, paymentFor }) {
	const dispatch = useDispatch();
	const [time, setTime] = useState("M");
	const [date, setDate] = useState("");
	const [venue, setVenue] = useState("");
	const [venueColor, setVenueColor] = useState("");

	useEffect(() => {
		setTime(event.booked_time);
		setDate(event.booked_date_english);
		setVenue(event.venue_name);
		setVenueColor(event.venue_color_code);
	}, [event]);

	const style = {
		backgroundColor: venueColor,
	};
	const statusStyle = {
		"--bg": `var(--${event.booking_status})`,
	};

	return (
		<div className="booking-summary bg-default">
			<div className="inquiry select">
				<div className="bg-inset payment-check-container">
					{event.selection_status === "disabled" ? (
						<span className="payment-balance-select-container disabled">
							<label className="payment-balance-label disabled" />
						</span>
					) : (
						<span className="payment-balance-select-container">
							<input
								type="checkbox"
								className="payment-balance-checkbox"
								id={event.booking_info_id}
								value={
									event.selection_status === "selected"
										? "unselected"
										: "selected"
								}
								name={event.booking_info_id}
								onChange={(e) =>
									dispatch(
										updateBookingPaymentSelectionStatus(
											event.booking_info_id,
											e.target.value,
											paymentFor
										)
									)
								}
								checked={event.selection_status === "selected"}
							/>
							<label
								htmlFor={event.booking_info_id}
								className="payment-balance-label"
							/>
						</span>
					)}
				</div>
			</div>
			{!isEmptyObject(event) && (
				<div className={`booked-time ${time} md:hidden`}>
					<span>{time.charAt(0).toUpperCase()}</span>
				</div>
			)}

			{!isEmptyObject(event) && (
				<div className="booked-date">
					{moment(date).format("DD MMM YYYY")}
				</div>
			)}

			<div className="booked-venue">
				<div className="venue-color-container">
					<div className="venue-color-content" style={style} />
				</div>
				<span>{venue}</span>
			</div>

			<div className="status">
				<div className="booking-summary-status-wrapper">
					<div
						className="booking-summary-status text-capitalize"
						style={statusStyle}
					>
						{event.booking_status}
					</div>
				</div>
			</div>

			<div className="balance">
				<span className="title-span">Balance</span>
				<label className="des-label amount">
					Rs. {event?.totalBalance?.toFixed(2) || 0}
				</label>
			</div>
		</div>
	);
}

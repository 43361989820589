/* eslint-disable array-callback-return */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormTextInput from "../../components/common/text-input/text-input-with-error.component";
import Loader from "../../components/loader/loader.component";
import { isEmptyObject, sessionInfo } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";
import {
	changeAddRequest,
	fetchInventoriesByLocationId,
} from "../../redux/actions/inventory.action";
import { addRequest } from "../../redux/actions/request.action";
import { fetchVenue } from "../../redux/actions/venue-action";

function AddRequest(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [locationId, setLocationId] = useState("");

	const { inventories, isLoading } = useSelector((s) => s.inventory);
	const req = useSelector((s) => s.request);
	const [requestedType, setRequestedType] = useState("");
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		setLocationId(localStorage.getItem("location_id"));
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setUserInfo({
					user_info_id: data.user_info_id,
					first_name: data.first_name,
					last_name: data.last_name,
				});
			});
		}

		fetchSessionData();

		dispatch(fetchVenue());
		dispatch(
			fetchInventoriesByLocationId(localStorage.getItem("location_id"))
		);
		setRequestedType(props.location.state.type);
	}, [dispatch, props]);

	const saveRequest = () => {
		let validation = true;
		if (isEmptyObject(inventories)) {
			showToastMessage("No inventories", "W");
			validation = false;
			return;
		}

		Object.keys(inventories).map((cat) => {
			for (var i = 0; i < inventories[cat].length; i++) {
				if (inventories[cat][i].error !== "") {
					validation = false;
					break;
				} else {
					validation = true;
				}
			}
		});
		return validation;
	};

	const validation = async () => {
		if (saveRequest()) {
			if (localStorage.getItem("location_id") === null) {
				showToastMessage(
					"Venue not selected. Please return to inventory page and select venue",
					"W"
				);
				return;
			}
			let requestObj = {};
			requestObj.location_id = locationId;
			requestObj.request_status = "requested";
			requestObj.request_list = inventories;
			requestObj.request_type = requestedType;
			requestObj.requested_by = [
				{
					user_info_id: userInfo.user_info_id,
					name: userInfo.first_name + " " + userInfo.last_name,
				},
			];
			if (requestedType === "user") {
				requestObj.due_date = moment()
					.add(7, "days")
					.format("YYYY-MM-DD");
				requestObj.requested_id = userInfo.user_info_id;
			}
			if (requestedType === "venue") {
				requestObj.due_date = moment()
					.add(7, "days")
					.format("YYYY-MM-DD");
				requestObj.requested_id = locationId;
			}
			if (requestedType === "booking") {
				requestObj.due_date = props.location.state.dueDate;
				requestObj.requested_id = props.location.state.requestedId;
			}
			let status = await dispatch(addRequest(requestObj));
			if (status) {
				_back();
			}
		}
	};
	const _back = () => {
		history.push({
			pathname: "/inventory",
			state: {
				tab: 2,
			},
		});
	};

	return (
		<div className="main-container mt-30px">
			{req.isLoading && <Loader />}
			{isLoading && <Loader />}

			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => _back()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt; <span className="current-page-path">Add Request</span>
				</div>
			</div>

			{!isEmptyObject(inventories) ? (
				Object.keys(inventories).map((cat, i) => (
					<div className="inner-container full" key={i}>
						<label htmlFor="">{cat}</label>
						<table
							cellPadding={0}
							cellSpacing={0}
							className="table-container md:hidden"
						>
							<thead>
								<tr>
									<td className="table-header ">
										<div className="table-header-container">
											ID
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Item Name
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Item Type
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Brand
										</div>
									</td>

									<td className="table-header ">
										<div className="table-header-container">
											Unit
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Inventory
										</div>
									</td>

									<td className="table-header ">Request</td>
								</tr>
							</thead>

							<tbody>
								{inventories[cat].length > 0 &&
									inventories[cat].map((list, i) => (
										<tr
											className="data-table-row inventory text-capitalize text-fontSemiDark"
											key={i}
										>
											<td>{i + 1}</td>
											<td>{list.item_name}</td>
											<td>
												{
													list?.item_description
														?.sub_type
												}
											</td>
											<td>
												{list?.item_description?.brand}
											</td>
											<td>{list.unit}</td>
											<td>{list.total_quantity}</td>
											<td>
												<FormTextInput
													variant="form-sm edit"
													type="number"
													value={list.add_quantity}
													name="add_quantity"
													onChange={(e) =>
														dispatch(
															changeAddRequest(
																e.target.value,
																i,
																cat
															)
														)
													}
													error={list.error}
												/>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				))
			) : (
				<div className="inner-container full">No Inventories Added</div>
			)}

			<div className="inner-container button">
				<button
					className="colored-button-wrapper bg-buttonSuccess text-whiteText"
					onClick={validation}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default AddRequest;

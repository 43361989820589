import {
	FETCH_CALENDAR_REQUEST,
	FETCH_CALENDAR_SUCCESS,
	FETCH_MONTHLY_CALENDAR_SUCCESS,
	FETCH_CALENDAR_ERROR,
	SET_CALENDAR_DATE,
	SET_CALENDAR_FILTER,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import { isEmptyObject, sessionInfo } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";
import history from "../history";
// for logout
import { fetchLoginError } from "./login-action";
import moment from "moment";

export const fetchCalendarInfoRequest = () => {
	return {
		type: FETCH_CALENDAR_REQUEST,
	};
};

export const fetchCalendarInfoSuccess = (calendarData) => {
	let date = getMonths(calendarData);
	return {
		type: FETCH_CALENDAR_SUCCESS,
		payload: calendarData,
		date,
	};
};

export const fetchMonthlyCalendarInfoSuccess = (calendarData) => {
	return {
		type: FETCH_MONTHLY_CALENDAR_SUCCESS,
		payload: calendarData,
	};
};


export const fetchCalendarInfoError = (error) => {
	return {
		type: FETCH_CALENDAR_ERROR,
		payload: error,
	};
};

export const getMonths = (dates) => {
	var datesArray = [];
	var month = "";
	var year = "";

	Object.keys(dates.availability).forEach((date) => datesArray.push(date));

	let firstDate = moment(datesArray[0].split("|")[0]).format("DD MMMM YYYY");

	let lastDate = moment(datesArray[6].split("|")[0]).format("DD MMMM YYYY");

	let firstMonth = firstDate.split(" ")[1];
	let firstYear = firstDate.split(" ")[2];

	let lastMonth = lastDate.split(" ")[1];
	let lastyear = lastDate.split(" ")[2];

	if (firstMonth === lastMonth) {
		month = firstMonth;
	} else {
		month = firstMonth + "-" + lastMonth;
	}

	if (firstYear === lastyear) {
		year = firstYear;
	} else {
		year = firstYear + "-" + lastyear;
	}
	// date = month+" "+year
	return month + " " + year;
};

export async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(
			fetchLoginError(error.response.data.message)
		);
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(
			fetchCalendarInfoError(error.response.data.message)
		);
	} else {
		await dispatch(fetchCalendarInfoError(error.message));
		showToastMessage(error.message, "E");
	}
}

export const fetchCalendarInfo = (
	booking_from,
	booking_to,
	venues,
	from = null,
	nepali_date = null
) => {
	return async (dispatch) => {
		let sessionData = await sessionInfo();

		if (from === null) {
			dispatch(fetchCalendarInfoRequest());
		}
		axios
			.get(
				`${strings.url}bookingInfo/information?booking_from=${booking_from}&booking_to=${booking_to}&nepali_date=${nepali_date}&venues=${venues}`,

				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCalendarInfoSuccess(res.data, "week"));
				} else {
					await dispatch(fetchCalendarInfoError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};


// for monthly booking fetch
export const fetchBookingByDateRange = (dateRange) => {
	return async (dispatch) => {
		dispatch(fetchCalendarInfoRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/monthly?booking_from=${dateRange?.booking_from}&booking_to=${dateRange?.booking_to}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchMonthlyCalendarInfoSuccess(res.data.result))
				} else {
					await dispatch(fetchCalendarInfoError(res.data.message));
				}
				return res.data.success;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const setCalendarDate = (selectedDate = null) => {
	var currentDate;
	if (selectedDate === null) {
		currentDate = moment();
	} else {
		currentDate = moment(selectedDate);
	}
	return {
		type: SET_CALENDAR_DATE,
		payload: currentDate,
	};
};

export const setCalendarFilter = (filter) => {
	return {
		type: SET_CALENDAR_FILTER,
		payload: filter,
	};
};

import {
	SHOW_SUPPORT_MODAL,
	HIDE_SUPPORT_MODAL,
	FETCH_SUPPORT_REQUEST,
	FETCH_SUPPORT_SUCCESS,
	SET_ERROR_TRUE,
} from "../action-types";

const initial_state = {
	modalStatus: false,
	isLoading: false,
	error: false,
	errorMessage: {},
};

export default function eventSaveReducer(state = initial_state, action) {
	switch (action.type) {
		case SHOW_SUPPORT_MODAL:
			return {
				...state,
				modalStatus: true,
			};
		case HIDE_SUPPORT_MODAL:
			return {
				...state,
				modalStatus: false,
				error: false,
			};
		case FETCH_SUPPORT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_SUPPORT_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case SET_ERROR_TRUE:
			return {
				...state,
				error: true,
				errorMessage: action.payload,
			};

		default:
			return state;
	}
}

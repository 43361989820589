import { isEmptyObject, sessionInfo } from "../../global/function";
import {
	FETCH_ORDER_REQUEST,
	FETCH_ORDER_SUCCESS,
	FETCH_ORDER_ERROR,
	FETCH_SINGLE_ORDER_SUCCESS,
	EDIT_ORDER,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";
import { fetchLoginError } from "./login-action";

export const fetchOrderRequest = () => {
	return {
		type: FETCH_ORDER_REQUEST,
	};
};

export const fetchOrderSuccess = (vendor) => {
	return {
		type: FETCH_ORDER_SUCCESS,
		payload: vendor,
	};
};

export const fetchOrderError = (error) => {
	return {
		type: FETCH_ORDER_ERROR,
		payload: error,
	};
};

export const fetchSingleOrderSuccess = (order) => {
	return {
		type: FETCH_SINGLE_ORDER_SUCCESS,
		payload: order,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchOrderError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchOrderError(error.message));
	}
}

export const fetchOrderByLocationId = (location_id) => {
	return async (dispatch) => {
		dispatch(fetchOrderRequest());
		const sessionData = await sessionInfo();

		axios
			.get(`${strings.inventoryURL}order?location_id=${location_id}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchOrderSuccess(res.data.result));
				} else {
					await dispatch(fetchOrderError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addOrderItem = (order) => {
	return async (dispatch) => {
		dispatch(fetchOrderRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.inventoryURL}order`, order, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchOrderByLocationId(order.location_id));
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchOrderError(response.data.message));
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchSingleOrder = (location_id, order_id) => {
	return async (dispatch) => {
		dispatch(fetchOrderRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.inventoryURL}order/id?location_id=${location_id}&orderId=${order_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchSingleOrderSuccess(res.data.result));
				} else {
					await dispatch(fetchOrderError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const editOrder = (value, index, key, field) => {
	return {
		type: EDIT_ORDER,
		payload: { value, index, key, field },
	};
};

export const updateOrder = (request) => {
	return async (dispatch) => {
		dispatch(fetchOrderRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}order/update`, request, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchOrderByLocationId(request.location_id));
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchOrderError(response.data.message));
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deletOrder = (order, id) => {
	return async (dispatch) => {
		dispatch(fetchOrderRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.inventoryURL}order/delete?deleteOrder=true&_id=${id}`,
				order,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchOrderByLocationId(order.location_id));
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchOrderError(response.data.message));
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchOrder = (order) => {
	return async (dispatch) => {
		dispatch(fetchOrderRequest());
		const sessionData = await sessionInfo();
		const url = `${strings.inventoryURL}order/search?order_id=${order.order_id}&order_type=${order.order_type}&vendor_name=${order.vendor_name}&due_days=${order.due_days}&order_status=${order.order_status}&location_id=${order.location_id}`;

		axios
			.get(url, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchOrderSuccess(res.data.result));
				} else {
					await dispatch(fetchOrderError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

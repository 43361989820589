import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";

function RequestTable({ list }) {
	const history = useHistory();
	const requestMobile = (info) => {
		return (
			<div
				className={`h-119 w-135  relative bg-default rounded-sm capitalize`}
				key={info.request_id}
			>
				<span className="absolute h-20 w-52 top-10px left-10px text-fontSemiDark text-md">
					{info.request_id}
				</span>
				<span className=" absolute h-23 w-65 top-10px right-10px text-sm text-right text-fontSemiDark"></span>

				<span className=" absolute h-10 w-119 top-52px right-10px left-10px text-xs text-right overflow text-fontSemiDark">
					{info.request_type}
				</span>
				<span className=" absolute h-10 w-65 bottom-30px right-10px text-xs text-right text-fontSemiDark">
					{moment
						.duration(
							moment(info.due_date, "YYYY-MM-DD").diff(
								moment().startOf("day")
							)
						)
						.asDays()}{" "}
					Days
				</span>
				<span
					className={`absolute h-12 bottom-10px right-10px left-10px text-xs text-right`}
				>
					{info.request_status}
				</span>
			</div>
		);
	};
	return (
		<div>
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						<td className="table-header ">
							<div className="table-header-container">
								Request ID
							</div>
						</td>

						<td className="table-header ">
							<div className="table-header-container">
								Request Type
							</div>
						</td>

						<td className="table-header ">
							<div className="table-header-container">User</div>
						</td>

						<td className="table-header ">
							<div className="table-header-container">
								Created Date
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Due Date
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Days Remaining
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">Status</div>
						</td>
						<td
							className="table-header"
							style={{ width: "4%" }}
						></td>
					</tr>
				</thead>

				<tbody>
					{list.length > 0 &&
						list.map((list, key) => (
							<tr
								className="data-table-row inventory text-capitalize text-fontSemiDark"
								key={key}
							>
								<td>{list.request_id}</td>

								<td>{list.request_type}</td>
								<td>{list?.requested_by[0]?.name}</td>

								<td>
									{moment(list.created_at).format(
										"DD/MM/YYYY"
									)}
								</td>
								<td>
									{moment(list.due_date).format("DD/MM/YYYY")}
								</td>
								<td>
									{moment
										.duration(
											moment(
												list?.due_date,
												"YYYY-MM-DD"
											).diff(moment().startOf("day"))
										)
										.asDays()}{" "}
									Days
								</td>
								<td>{list.request_status}</td>
								<td>
									<div className="flex gap-10px justify-end pr-7px">
										<button
											className="button-primary-icon"
											onClick={() =>
												history.push({
													pathname: "request/update",
													state: {
														id: list._id,
														location_id:
															list.location_id,
													},
													search: `?request_id=${list.request_id}`,
												})
											}
										>
											<i className="fas fa-eye"></i>
										</button>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{list.map((list) => requestMobile(list))}
			</div>
		</div>
	);
}

export default RequestTable;

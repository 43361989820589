import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Basic from "./basic";
import Advanced from "./advanced";
import { useDispatch, useSelector } from "react-redux";
import {
	saveCustomer,
	updateCustomerDetails,
} from "../../../redux/actions/customer-setting.action";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

/* 
    @Description
        Modal Component that adds customer
    @propsTypes:
        isVisible[bool] // Checks visibillty value of modal 
        handleClose[function] // function to close modal
        handleShow[function] // function to open modal
        actionType[string] // either add or update
        from[string] // either list or calendar only user for customer type

*/

export default function AddCustomerModal(props) {
	const dispatch = useDispatch();
	const [modalTitle, setModalTitle] = useState("Add New Customer");
	const [processStep, setProcessStep] = useState(1);
	const { customerDetails } = useSelector((state) => state.customerSetting);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
		h2: {
			color: "red",
		},
	};
	useEffect(() => {
		if (props.actionType === "Update") {
			setModalTitle("Update Customer");
		}
	}, [props]);

	const addCustomer = (customerData) => {
		/* checking to see if any value is empty and setting null if empty */
		Object.keys(customerData).map((key) => {
			if (customerData[key] === "") {
				delete customerData[key];
			}
			return null;
		});

		if (
			customerData.identification_type ||
			customerData.pan_number ||
			customerData.vat_number
		) {
			customerData = {
				...customerData,
				identification: {
					identification_type:
						customerData.identification_type || null,
					identification_number:
						customerData.identification_number || null,
					pan_number: customerData.pan_number || null,
					vat_number: customerData.vat_number || null,
					user_type: "customer",
				},
			};
		}
		delete customerData["identification_type"];
		delete customerData["identification_number"];
		delete customerData["pan_number"];
		delete customerData["vat_number"];

		if (props.actionType === "Add") {
			if (customerData.customer_type === "business") {
				customerData["customer_lname"] = "";
			}
			dispatch(saveCustomer(customerData, props.from));
		} else if (props.actionType === "Update") {
			customerData["customer_info_id"] = customerDetails.customer_info_id;
			customerData["company_customer_id"] =
				customerDetails.company_customer_id;
			customerData["company_info_id"] = customerDetails.company_info_id;
			if (customerData.identification) {
				customerData.identification["identification_id"] =
					customerDetails.customer_info.identification_id;
			}
			dispatch(updateCustomerDetails(customerData));
		}
		props.handleClose();
	};

	function _renderAddCustomerModal() {
		return (
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">{modalTitle}</div>
				</div>
				{processStep === 1 ? (
					<Basic
						setProcessStep={setProcessStep}
						handleClose={props.handleClose}
						actionType={props.actionType}
						onClickSave={addCustomer}
					/>
				) : (
					<Advanced
						setProcessStep={setProcessStep}
						handleClose={props.handleClose}
						actionType={props.actionType}
						onClickSave={addCustomer}
					/>
				)}
			</div>
		);
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			{_renderAddCustomerModal()}
		</Modal>
	);
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject } from "../../global/function";
import {
	getVatHistory,
	searchVatHistory,
	setVatSearchParameters,
} from "../../redux/actions/payment-action";
import Loader from "../../components/loader/loader.component";

import PaginationReact from "../../components/paginationReact/paginationReact.component";

import VatHistoryTable from "../../components/tables/vat-history.table";
import SearchVatHistoryComponent from "../../components/search/search-vat-history.component";
import ExportCsvModal from "../../components/modals/export-csv-modal";
function VatHistory(props) {
	const dataPerPage = 20;
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState(false);

	const { isLoading, vatHistoryList, vatHistoryCount, vatSearchParameters } =
		useSelector((state) => state.payment);

	useEffect(() => {
		if (props.location.state) {
			//search if navigating from booking
			let search = {
				...vatSearchParameters,
				event_id: props.location.state.event_id,
			};
			dispatch(searchVatHistory(dataPerPage, currentPage, search));
		} else {
			if (isEmptyObject(vatSearchParameters)) {
				dispatch(getVatHistory(dataPerPage, currentPage));
			} else {
				dispatch(
					searchVatHistory(
						dataPerPage,
						currentPage,
						vatSearchParameters
					)
				);
			}
		}
	}, [dispatch, currentPage, dataPerPage, props.location.state]);

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;

		setCurrentPage(currentPage);
	};

	const searchPayment = (searchData) => {
		setCurrentPage(1);
		dispatch(searchVatHistory(dataPerPage, 1, searchData));
	};
	const resetSearch = () => {
		let search = {
			vat_payment_id: "",
			vat_bill_number: "",
			event_id: "",
			date_from: "",
			date_to: "",
			customer_fname: "",
			customer_lname: "",
		};
		dispatch(setVatSearchParameters(search));
		dispatch(searchVatHistory(dataPerPage, 1, search));
		setCurrentPage(1);
	};

	const toggleShowModal = () => {
		setShowModal(true);
	};

	const toggleHideModal = () => {
		setShowModal(false);
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="inner-container full mt-44px">
				<SearchVatHistoryComponent
					searchPayment={searchPayment}
					resetSearch={resetSearch}
				/>

				<div className="payment-history-content">
					<span className="payment-history-title ">VAT History</span>
					{vatHistoryList.length >= 1 ? (
						<VatHistoryTable vatHistoryList={vatHistoryList} />
					) : (
						<div className="no-data-display">
							<i className="far fa-frown" />
							No Data
						</div>
					)}
					<div className="table-controller-container">
						<PaginationReact
							totalCount={vatHistoryCount}
							itemsPerPage={dataPerPage}
							onPageClick={onPageChange}
							forcePage={currentPage - 1}
						/>

						<button
							className="button-primary float-right md:hidden"
							onClick={toggleShowModal}
						>
							Export CSV
						</button>
					</div>
				</div>
			</div>

			<ExportCsvModal
				filterData={vatSearchParameters}
				handleShow={toggleShowModal}
				handleHide={toggleHideModal}
				isVisible={showModal}
				tableName="Vat"
			/>
		</div>
	);
}

export default VatHistory;

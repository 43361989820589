import React from "react";
import "./menu-svg.scss";

export default function Fish({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 20 10.39"
            id="fish">
            <g>
                <path d="M19.33,4.37,20,.76a.58.58,0,0,0-1-.43L16.14,2.9C14.78,1.18,11.88,0,8.51,0,3.81,0,0,3.83,0,5.19s3.81,5.2,8.51,5.2c3.35,0,6.25-1.18,7.61-2.89L19,10.06a.58.58,0,0,0,1-.43l-.67-4A4,4,0,0,1,19.33,4.37Zm-15,.11a.79.79,0,1,1,.79-.79A.8.8,0,0,1,4.33,4.48Z" />
            </g>
        </svg>
    );
}

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "./assets/css/main.scss";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignUp from "./pages/signup/signup.page";

import TestDesign from "./pages/test-designs/test-design.page";
import Dashboard from "./pages/dashboard/dashboard.page.jsx";
import Login from "./pages/login/login.page.jsx";
import CompleteRegistration from "./pages/complete-registration/complete-registration.page.jsx";
import BookingEvent from "./pages/booking-event/booking-event.page.jsx";
import CustomerDetails from "./pages/customer-profile/customer-profile.page.jsx";
import Settings from "./pages/settings/settings.page.jsx";
import AddService from "./pages/add-menu-services/add-menu-services.page.jsx";
import EmailVerification from "./pages/email-verification/email-verification.page.jsx";
import EmployeeVerification from "./pages/employee-verification/employee-verification.page.jsx";
import EmployeeDetails from "./pages/employee-profile/employee-profile.page.jsx";
import BookingHistory from "./pages/booking-history/booking-history.page.jsx";
import ResetPassword from "./pages/reset-password/reset-password.page.jsx";
import SwitchUser from "./pages/switch-user/switch-user.page.jsx";
import Payment from "./pages/payment/payment.page.jsx";
import InventoryPage from "./pages/inventory/inventory.page.jsx";
import YearlyDateFix from "./pages/yearly-date-fix/yearly-date-fix.page";

import ProtectedRoute from "./routes/ProtectedRoute.jsx";
import PaymentHistory from "./pages/payment-history/payment-history.page";
import PageNotFound from "./pages/extra-page/extra.page.jsx";
import ActivityMonitor from "./pages/activity-monitor/activity-monitor.page";
import VatHistory from "./pages/vat-history/vat-history.page";
import SubscriptionPage from "./pages/extra-page/subscription.page";

import Analytics from "./pages/analytics/analytics.page.jsx";

// import { SocketContext } from "./Socket";
import { sessionInfo } from "./global/function";
import VendorPage from "./pages/vendor/vendor.page";
import AddInventoryItem from "./pages/add-inventory/add-inventory-item.page";
import AddInventoryCategory from "./pages/add-inventory-category/add-inventory-category.page";
import AddRequest from "./pages/add-request/add-request.page";
import UpdateRequestPage from "./pages/update-request/update-request.page";
import AddOrderPage from "./pages/add-order/add-order.page";
import UpdateOrderPage from "./pages/update-order/update-order.page";
import UpdateInventoryCategory from "./pages/update-category/update-category.page";
import PrintPage from "./pages/print-display/print-display.page";

import withClearCache from "./ClearCache";
import BookingRequest from "./pages/add-request/booking-request.page";
import { useDispatch, useSelector } from "react-redux";
import { setDateFixReduxState } from "./redux/actions/login-action.js";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	const dispatch = useDispatch();
	const [company_role, setCompanyRole] = useState();
	const [venue, setVenue] = useState([]);

	const { dateFixReduxState } = useSelector((state) => state.login);

	// const socket = useContext(SocketContext);]

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setVenue(data.venues);
				setCompanyRole(data.company_role);
			});

			if (localStorage.getItem("dateFixState")) {
				dispatch(
					setDateFixReduxState(
						JSON.parse(localStorage.getItem("dateFixState"))
					)
				);
			}
			let venuesArray = [];
			venue.map((v) => {
				if (v.venue_employee_status === "active") {
					venuesArray.push(v.venue_info_id);
				}
				return null;
			});

			// joining room for logged in user
			// socket.emit("joinRoom", company_info_id, venuesArray);
			// socket.on("notification"); //
			// socket.on("connect_error", (err) => {
			// 	console.log(err);
			// 	console.log(`app connect_error due to ${err.message}`);
			// });
		}

		fetchSessionData();
	}, []);

	return (
		<div className="App">
			{dateFixReduxState ? (
				<Switch>
					<Route exact path="/" component={Login} />
					<ProtectedRoute
						exact
						path="/yearly-date-fix"
						component={YearlyDateFix}
						navigation={true}
					/>
					<ProtectedRoute component={PageNotFound} />
				</Switch>
			) : company_role === "viewer" ? (
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/signup" component={SignUp} />
					<ProtectedRoute
						exact
						path="/request-test"
						component={BookingRequest}
						navigation={true}
					/>
					<ProtectedRoute
						exact
						path="/print"
						component={PrintPage}
						navigation={true}
					/>
					<ProtectedRoute
						exact
						path="/dashboard"
						component={Dashboard}
						navigation={true}
					/>
					<ProtectedRoute
						exact
						path="/user/complete-registration"
						component={CompleteRegistration}
						navigation={false}
					/>
					<ProtectedRoute
						exact
						path="/booking-event"
						component={BookingEvent}
					/>
					<ProtectedRoute
						exact
						path="/customer-profile"
						component={CustomerDetails}
					/>
					<ProtectedRoute
						exact
						path="/settings"
						component={Settings}
					/>
					<ProtectedRoute
						exact
						path="/add-service"
						component={AddService}
					/>
					<ProtectedRoute
						exact
						path="/employee-profile"
						component={EmployeeDetails}
					/>
					<ProtectedRoute
						exact
						path="/booking-history"
						component={BookingHistory}
					/>
					<Route
						exact
						path="/user/verification/:token/:email"
						component={EmailVerification}
					/>
					<Route
						exact
						path="/employee/verification/:token/:email"
						component={EmployeeVerification}
					/>
					<ProtectedRoute
						exact
						path="/switch-company"
						component={SwitchUser}
					/>
					<ProtectedRoute
						exact
						path="/vat-history"
						component={VatHistory}
					/>
					<ProtectedRoute exact path="/payment" component={Payment} />
					<Route
						exact
						path="/user/reset-password/:verificationToken/:user_email"
						component={ResetPassword}
					/>
					<ProtectedRoute component={PageNotFound} />
				</Switch>
			) : (
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/signup" component={SignUp} />
					<ProtectedRoute
						exact
						path="/analytics"
						component={Analytics}
						navigation={true}
					/>
					<ProtectedRoute
						exact
						path="/request-test"
						component={BookingRequest}
						navigation={true}
					/>

					{/* <ProtectedRoute
					exact
					path="/test"
					component={TestDesign}
					navigation={true}
				/> */}
					<ProtectedRoute
						exact
						path="/print"
						component={PrintPage}
						navigation={true}
					/>
					<ProtectedRoute
						exact
						path="/dashboard"
						component={Dashboard}
						navigation={true}
					/>
					<ProtectedRoute
						exact
						path="/user/complete-registration"
						component={CompleteRegistration}
						navigation={false}
					/>
					<ProtectedRoute
						exact
						path="/booking-event"
						component={BookingEvent}
					/>
					<ProtectedRoute
						exact
						path="/customer-profile"
						component={CustomerDetails}
					/>
					<ProtectedRoute
						exact
						path="/settings"
						component={Settings}
					/>
					<ProtectedRoute
						exact
						path="/add-service"
						component={AddService}
					/>
					<ProtectedRoute
						exact
						path="/employee-profile"
						component={EmployeeDetails}
					/>

					<ProtectedRoute
						exact
						path="/booking-history"
						component={BookingHistory}
					/>
					<ProtectedRoute
						exact
						path="/inventory"
						component={InventoryPage}
					/>
					<ProtectedRoute
						exact
						path="/inventory/add/item"
						component={AddInventoryItem}
					/>
					<ProtectedRoute
						exact
						path="/inventory/add/category"
						component={AddInventoryCategory}
					/>
					<ProtectedRoute
						exact
						path="/inventory/update/category"
						component={UpdateInventoryCategory}
					/>

					<ProtectedRoute
						exact
						path="/vendor"
						component={VendorPage}
					/>

					<ProtectedRoute
						exact
						path="/request/add"
						component={AddRequest}
					/>
					<ProtectedRoute
						exact
						path="/request/update"
						component={UpdateRequestPage}
					/>

					<ProtectedRoute
						exact
						path="/order/add"
						component={AddOrderPage}
					/>
					<ProtectedRoute
						exact
						path="/order/update"
						component={UpdateOrderPage}
					/>

					<Route
						exact
						path="/user/verification/:token/:email"
						component={EmailVerification}
					/>
					<Route
						exact
						path="/employee/verification/:token/:email"
						component={EmployeeVerification}
					/>
					<ProtectedRoute
						exact
						path="/switch-company"
						component={SwitchUser}
					/>
					<ProtectedRoute
						exact
						path="/payment-history"
						component={PaymentHistory}
					/>
					<ProtectedRoute
						exact
						path="/notification"
						component={ActivityMonitor}
					/>
					<ProtectedRoute
						exact
						path="/vat-history"
						component={VatHistory}
					/>
					<ProtectedRoute exact path="/payment" component={Payment} />
					<ProtectedRoute
						exact
						path="/subscription"
						component={SubscriptionPage}
					/>
					<Route
						exact
						path="/user/reset-password/:verificationToken/:user_email"
						component={ResetPassword}
					/>
					<ProtectedRoute component={PageNotFound} />
				</Switch>
			)}
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
			/>
		</div>
	);
}

export default App;

import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import "./add-booking-queue.style.scss";

import TextInputWithErrorComponent from "../common/text-input/text-input-with-error.component";
import {
	deleteBookingQueue,
	swapBooking,
} from "../../redux/actions/booking-action";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function ViewBookingQueueModal(props) {
	const dispatch = useDispatch();

	const [switchConfirmation, setSwitchConfirmation] = useState(true);

	const _handleSwitch = async () => {
		const swapBookingData = await dispatch(
			swapBooking({
				booking_info_id: props.data.booking_info_id,
				company_customer_id: props.data.company_customer_id,
				booking_queue_id: props.data.booking_queue_id,
			})
		);
		props.handleSwap(swapBookingData);
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Booking On Queue</div>
				</div>
				<div className="form-body">
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant={"form-lg"}
								label="Customer"
								disabled
								defaultValue={`${props.data?.customer_fname} ${props.data?.customer_lname}`}
							/>
						</div>
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Contact Mobile"
								disabled
								defaultValue={props.data?.customer_mobile}
							/>
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Contact Phone"
								disabled
								defaultValue={props.data?.customer_phone}
							/>
						</div>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Catering type"
								disabled
								defaultValue={props.data?.booking_catering}
							/>
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Booking Type"
								disabled
								defaultValue={props.data?.booking_type}
							/>
						</div>
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Est. Pax"
								disabled
								defaultValue={props.data?.e_pax}
							/>
							<TextInputWithErrorComponent
								variant={"form-md"}
								label="Est. Rate"
								disabled
								defaultValue={props.data?.e_rate}
							/>
						</div>
					</div>
					<hr />
					<div className="form-input-container">
						<div className="form-input-container inner">
							<div
								className={` bg-default clickable-message ${
									!switchConfirmation && "selected"
								}`}
								onClick={() =>
									setSwitchConfirmation(!switchConfirmation)
								}
							>
								Replace current booking with this booking
							</div>
						</div>
					</div>
				</div>
				<div className="form-footer two">
					<button
						onClick={() => {
							dispatch(
								deleteBookingQueue(
									props.data.booking_queue_id,
									props.data.booking_info_id,
									props.venueID
								)
							);
							props.handleClose();
						}}
						className="button-primary button-red"
					>
						Delete
					</button>
					<div className="three-button">
						<button
							onClick={() => _handleSwitch()}
							disabled={switchConfirmation}
							className={`button-primary ${
								!switchConfirmation && "button-green"
							}`}
						>
							Switch
						</button>
						<button
							onClick={() => props.handleClose()}
							className="button-primary"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

import React, { useEffect, useState } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import {
	addInventories,
	fetchCategoriesByLocationId,
	updateInventory,
} from "../../redux/actions/inventory.action";
import { isEmptyObject } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";

const itemSchema = yup.object().shape({
	item_name: yup.string().required("required"),
	unit: yup.string().required("Unit required"),
	item_category: yup.string().required("Category required"),
	total_quantity: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Min value 0.")
		.transform((value) => (value ? value : 0)),

	average_rate: yup
		.number()
		.typeError("Must be a number")
		.transform((value) => (value ? value : 0))
		.min(0, "Min value 0.")
		.when("total_quantity", {
			is: (value) => value > 0,
			then: yup
				.number()
				.required()
				.typeError("Must be a number")
				.transform((value) => (value ? value : 0)),
		}),
	min_quantity: yup
		.number()
		.typeError("Must be a number")
		.min(0, "Min value 0.")
		.transform((value) => (value ? value : 0)),
});

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function AddInventoryItemModal(props) {
	const dispatch = useDispatch();
	const [type, setType] = useState("edit");
	const { dropdownCat, inventoryToEdit } = useSelector((s) => s.inventory);

	const { register, errors, handleSubmit, reset, watch } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(itemSchema),
	});

	let qty = watch("total_quantity");

	useEffect(() => {
		if (isEmptyObject(inventoryToEdit)) {
			setType("add");
		} else {
			setType("edit");
		}
		reset(inventoryToEdit);
	}, [inventoryToEdit, type, reset]);

	useEffect(() => {
		dispatch(
			fetchCategoriesByLocationId(localStorage.getItem("location_id"))
		);
	}, [dispatch, inventoryToEdit]);

	async function _addItem(inventory) {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}
		if (type === "edit") {
			inventory = {
				inventory,
				location_id: localStorage.getItem("location_id"),
			};
			dispatch(updateInventory(inventory));
		} else if (type === "add") {
			let data = {};
			let inventories = [];
			let category_set = []; // sending empty to avoid error from backend
			delete inventory["item_id"];
			delete inventory["_id"];
			inventories.push(inventory);
			data = {
				location_id: localStorage.getItem("location_id"),
				inventories,
				category_set,
			};
			dispatch(addInventories(data));
		}

		props.handleClose();
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title capitalize">{type} Item</div>
				</div>
				<div className="form-body">
					<div className="form-input-container ">
						<div className="hidden">
							<FormTextInput
								variant="form-md"
								name="item_id"
								ref={register}
							/>
							<FormTextInput
								variant="form-md"
								name="_id"
								ref={register}
							/>
						</div>
						<div className="form-input-container inner">
							<FormTextInput
								type="text"
								placeholder="Item Name"
								label="Item Name*"
								variant="form-lg"
								name="item_name"
								ref={register}
								error={errors?.item_name?.message}
							/>
						</div>
						<div className="form-input-container inner">
							<FormTextInput
								label="Enter Brand Name*"
								placeholder="Brand Name"
								variant="form-lg"
								type="text"
								name="item_description.brand"
								ref={register}
								error={errors?.item_description?.brand?.message}
							/>
						</div>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormSelectBox
								label="Unit"
								variant="form-sm"
								options={strings.unitList}
								name="unit"
								ref={register}
								type="Unit"
								error={errors?.unit?.message}
							/>

							<FormSelectBox
								label="Category*"
								variant="form-sm"
								options={dropdownCat}
								name="item_category"
								ref={register}
								type="Category"
								error={errors?.item_category?.message}
							/>
						</div>

						<div className="form-input-container inner">
							<FormTextInput
								label="Sub Type"
								placeholder="Enter Sub Type"
								variant="form-lg"
								type="text"
								name="item_description.sub_type"
								ref={register}
								error={errors?.sub_type?.message}
							/>
						</div>
					</div>

					<div className={` ${type === "edit" && "hidden"}`}>
						<label htmlFor="" className="form-description-title">
							Inventory
						</label>
						<div className={`form-input-container`}>
							<div className="form-input-container inner">
								<FormTextInput
									label="Quantity "
									placeholder="Enter Quantity"
									variant="form-md"
									type="number"
									name="total_quantity"
									ref={register}
									error={errors?.total_quantity?.message}
								/>
								<FormTextInput
									label="Rate "
									placeholder="Enter Rate"
									variant="form-md"
									type="number"
									name="average_rate"
									ref={register}
									error={errors?.average_rate?.message}
									disabled={qty === ""}
								/>
							</div>
							<div className="form-input-container inner">
								<FormTextInput
									label="Minimum "
									placeholder="Enter Minimum"
									variant="form-md"
									type="number"
									name="min_quantity"
									ref={register}
									error={errors?.min_quantity?.message}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="form-footer two">
					<button
						onClick={() => {
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						onClick={handleSubmit(_addItem)}
						className="button-primary capitalize"
					>
						{type}
					</button>
				</div>
			</div>
		</Modal>
	);
}

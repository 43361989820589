import {
	ADD_CUSTOMER_BASIC_UPDATE,
	ADD_CUSTOMER_ADVANCE_UPDATE,
	ADD_CUSTOMER_CANCELED,
} from "../action-types";

const addCustomerReducer = (
	state = {
		basic: {},
		advance: {},
	},
	action
) => {
	switch (action.type) {
		case ADD_CUSTOMER_BASIC_UPDATE:
			return { ...state, basic: action.payload };
		case ADD_CUSTOMER_ADVANCE_UPDATE:
			return { ...state, advance: action.payload };
		case ADD_CUSTOMER_CANCELED:
			return { basic: {}, advance: {} };
		default:
			return state;
	}
};

export default addCustomerReducer;

import React, { useEffect, useState } from "react";
import "./service-item-container.style.scss";

import SimpleTextInput from "../../common/text-input/simple-text.component";
import { isEmptyObject } from "../../../global/function";
import { serviceTypeMapper } from "../../../assets/images/icons/iconList/icon-distributer.map";

export default function ServiceItemContainer(props) {
	const [serviceDetails, setServiceDetails] = useState({});
	const [serviceName, setServiceName] = useState("");
	const [serviceType, setServiceType] = useState("name");
	const [servicePrice, setServicePrice] = useState("");

	const [editState, setEditState] = useState(false);
	const [override, setOverride] = useState(false);

	useEffect(() => {
		setServiceDetails(props.item);
		setServiceName(props.item.service_name);
		if (isEmptyObject(props.item.service)) {
			if (!isEmptyObject(props.item.service_type)) {
				setServiceType(props.item.service_type.service_type_name);
			} else {
				setServiceType("Other");
			}
		} else {
			if (isEmptyObject(props.item.service.service_type)) {
				setServiceType("Entertainment");
			} else {
				setServiceType(
					props.item.service.service_type.service_type_name
				);
			}
		}

		setServicePrice(
			props.item?.hasOwnProperty("booking_service_price")
				? props.item?.booking_service_price
				: props.item?.service_price
		);

		props.item.service
			? setOverride(props.item.service.override)
			: setOverride(props.item.override);
	}, [props, serviceDetails]);

	function addClick() {
		props.addOnClick(serviceDetails);
	}

	const _handleSave = (e, service, newServicePrice) => {
		e.stopPropagation();
		props.overrideService(service, newServicePrice);
		setEditState(!editState);
	};

	const _handleEdit = (e) => {
		e.stopPropagation();
		setEditState(!editState);
	};

	const _savePrice = (value) => {
		setServicePrice(value);
	};

	return (
		<div
			className={`service-item-wrapper bg-default ${props.type}`}
			onClick={() => {
				!props.action && addClick();
			}}
		>
			<div className="service-info-container">
				<div className={`service-icon bg-default ${props.type}`}>
					{/* {serviceType === "Entertainment" ? (
						<i className="fas fa-guitar"></i>
					) : serviceType === "Transportation" ? (
						<i className="fas fa-shuttle-van"></i>
					) : (
						<i className="fas fa-ellipsis-h"></i>
					)} */}
					{serviceTypeMapper(props?.service_type || serviceType)}
				</div>
				<div className="service-info">
					<div
						className="item-name capitalize"
						disabled={props.type === "selected"}
					>
						{serviceName}
					</div>
					<div className="item-price">
						{props.type === "selected" &&
						override &&
						!props.view &&
						editState ? (
							<SimpleTextInput
								type="number"
								variant="servicePrice edit"
								value={servicePrice}
								name={serviceDetails.service_item}
								onChange={(e) => _savePrice(e.target.value)}
								onClick={(e) => e.stopPropagation()}
								disabled={!editState}
							/>
						) : (
							<div className="text-fontSemiDark text-md">
								Rs.{servicePrice}
							</div>
						)}
					</div>
				</div>
			</div>

			{props.action && !props.view && (
				<div className="service-action-wrapper">
					{editState
						? override && (
								<div
									className="save-service bg-default"
									id={props.itemId}
									onClick={(e) => {
										_handleSave(
											e,
											serviceDetails,
											servicePrice
										);
									}}
								>
									Save
								</div>
						  )
						: override && (
								<div
									className="edit-service bg-default"
									id={props.itemId}
									onClick={(e) => {
										_handleEdit(e);
									}}
								>
									Edit
								</div>
						  )}

					<div
						className="delete-service bg-default"
						id={props.itemId}
						onClick={addClick}
					>
						Delete
					</div>
				</div>
			)}
		</div>
	);
}

import React, { useEffect } from "react";
import FormTextInput from "../../components/common/text-input/form-text-input.component";

import { useForm } from "react-hook-form";
import FormSelectBox from "../../components/common/select-box/form-select-input.component";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
	updateSignupStepTwo,
	submitSignUp,
} from "../../redux/actions/signupActions";
import { useHistory } from "react-router-dom";
import strings from "../../global/strings";

const schema = yup.object().shape({
	company_venue_number: yup.string("Not Selected").required("Not Selected"),
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
});

const SignupStepTwo = ({ setProcessStep, nav }) => {
	const dispatch = useDispatch();
	const { stepTwo } = useSelector((state) => state.signup);
	const history = useHistory();
	const { register, getValues, handleSubmit, errors, reset } = useForm({
		mode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		reset(stepTwo);
	}, [stepTwo, reset]);

	const onSubmit = (data) => {
		dispatch(submitSignUp(data, history));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-main-content bg-default">
				<div className="form-header">{nav()}</div>
				<div className="form-body">
					<div className="form-description-title">Create Account</div>
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="password"
							placeholder="Password"
							label="Password*"
							name="password"
							ref={register}
							error={errors?.password?.message}
						/>
						<FormTextInput
							variant="form-lg"
							type="password"
							placeholder="Confirm Password"
							label="Confirm Password*"
							name="confirm_password"
							ref={register}
							error={errors?.confirm_password?.message}
						/>
					</div>
					<div className="form-description-title">Subscription</div>
					<div className="form-input-container">
						<div className="venue-number-selection-container">
							<FormSelectBox
								label="Number of Venues"
								options={strings.venueNumber}
								name="company_venue_number"
								variant="venue-number"
								ref={register}
								error={errors?.company_venue_number?.message}
							/>
							<span className="price-indicator-label">
								Rs. 5500/venue/month
							</span>
						</div>
						<div className="subscription-selection-container">
							<div className="subscription-cost-container ">
								<div className="bodered subscription-cost-content-selected">
									<div className="subscription-cost-title selected">
										Free Trial
									</div>
									<div className="subscription-cost-amount selected">
										3 Months
									</div>
								</div>

								<div className="bg-default subscription-cost-content-monthly">
									<div className="subscription-cost-title">
										Monthly
									</div>
									<div className="subscription-cost-amount">
										Rs. 5,500.00
									</div>
								</div>

								<div className="bg-default subscription-cost-content-anually">
									<div className="subscription-cost-title">
										Anually
									</div>
									<div className="subscription-cost-amount">
										Rs. 60,000.00
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-footer two">
					<button
						className="button-primary"
						onClick={() => {
							dispatch(updateSignupStepTwo(getValues()));
							setProcessStep(1);
						}}
					>
						Back
					</button>

					<button className="colored-button-wrapper confirm-signup-button">
						Confirm
					</button>
				</div>
			</div>
		</form>
	);
};

export default SignupStepTwo;

import React, { useState, useEffect } from "react";
import RegisterUserInformation from "./register-user-information.component";
import RegisterCompanyInformation from "./register-company-information.component";
import RegisterVenueInformation from "./register-venue-information.component";
import RegisterSettingsInformation from "./register-settings-information.component";
import Loader from "../../components/loader/loader.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchVenue } from "../../redux/actions/venue-action";
import SkipVenueComponent from "./skip-venue.component";

export default function CompleteRegistration(props) {
	const dispatch = useDispatch();
	const { venueList } = useSelector((s) => s.venue);
	const { isLoading } = useSelector((state) => state.completeRegistration);

	const [processStep, setProcessStep] = useState(1);
	const [venueProcessStep, setVenueProcessStep] = useState(0);

	useEffect(() => {
		dispatch(fetchVenue());
	}, [dispatch]);

	const _renderNextFormView = () => {
		let view = [];
		if (processStep === 1) {
			view.push(
				<RegisterUserInformation
					setProcessStep={setProcessStep}
					processStep={processStep}
					key="user"
				/>
			);
		} else if (processStep === 2) {
			view.push(
				<RegisterCompanyInformation
					setProcessStep={setProcessStep}
					processStep={processStep}
					key="company"
				/>
			);
		} else if (processStep === 3) {
			view.push(
				<SkipVenueComponent
					setProcessStep={setProcessStep}
					processStep={processStep}
					key="skip"
				/>
			);
		} else if (processStep === 4) {
			let venue_info_id = venueList[venueProcessStep].venue_info_id;

			view.push(
				<RegisterVenueInformation
					venueInfoId={venue_info_id}
					setProcessStep={setProcessStep}
					processStep={processStep}
					setVenueProcessStep={setVenueProcessStep}
					venueProcessStep={venueProcessStep}
					venueCount={venueList.length}
					key={venue_info_id}
					venueNav={_renderVenueNaviagatorContainer}
				/>
			);
		} else if (processStep === 5) {
			view.push(<RegisterSettingsInformation key="Complete" />);
		}
		return view;
	};

	const _renderVenueNaviagatorContainer = () => {
		return (
			<div className="form-navigator-wrapper venue">
				{venueList.map((venue, key) => {
					return (
						<div
							className={
								venueProcessStep === key
									? "form-navigator-title selected"
									: "form-navigator-title"
							}
							key={key}
						>
							{venue.venue_name}
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className="signin-page-wrapper">
			{isLoading && <Loader />}
			<div className="signup-main-container">
				<div className="company-title">CATER ME</div>
				{_renderNextFormView()}
			</div>
		</div>
	);
}

import {
	FETCH_COMPANY_SUBSCRIPTION_REQUEST,
	FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
	ADD_COMPANY_SUBSCRIPTION,
	UPDATE_COMPANY_SUBSCRIPTION,
	DELETE_COMPANY_SUBSCRIPTION,
	FETCH_COMPANY_SUBSCRIPTION_ERROR,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchCompanySubscriptionRequest = (type) => {
	return {
		type,
	};
};
export const fetchCompanySubscriptionSuccess = (caterSubscription) => {
	return {
		type: FETCH_COMPANY_SUBSCRIPTION_SUCCESS,
		payload: caterSubscription,
	};
};

export const fetchCompanySubscriptionError = (error) => {
	return {
		type: FETCH_COMPANY_SUBSCRIPTION_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(
			fetchCompanySubscriptionError(error.response.data.message)
		);
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCompanySubscriptionError(error.message));
	}
}

export const fetchCompanyCaterSubscription = () => {
	return async (dispatch) => {
		dispatch(
			fetchCompanySubscriptionRequest(FETCH_COMPANY_SUBSCRIPTION_REQUEST)
		);
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}cater/subscription/company?company_info_id=${sessionData.company_info_id}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(
						fetchCompanySubscriptionSuccess(res.data.result)
					);
					return res.data.result;
				} else {
					await dispatch(
						fetchCompanySubscriptionError(res.data.message)
					);
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addCaterSubscription = (subscriptionDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanySubscriptionRequest(ADD_COMPANY_SUBSCRIPTION));
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}cater/subscription`, subscriptionDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchCompanySubscriptionSuccess(response.data.result)
					);
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(
						fetchCompanySubscriptionError(response.data.message)
					);
					showToastMessage(response.data.message, "E");
				}
				return response.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateCaterSubscription = (subscriptionDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanySubscriptionRequest(UPDATE_COMPANY_SUBSCRIPTION));
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}cater/subscription`, subscriptionDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchCompanySubscriptionSuccess(response.data.result)
					);
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(
						fetchCompanySubscriptionError(response.data.message)
					);
					showToastMessage(response.data.message, "E");
				}
				return response.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteCaterSubscription = (caterSubscriptionId) => {
	return async (dispatch) => {
		dispatch(fetchCompanySubscriptionRequest(DELETE_COMPANY_SUBSCRIPTION));
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}cater/subscription?cater_subscription_id=${caterSubscriptionId}&company_info_id=${sessionData.company_info_id}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchCompanySubscriptionSuccess(response.data.result)
					);
				} else {
					await dispatch(
						fetchCompanySubscriptionError(response.data.message)
					);
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

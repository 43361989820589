import React, { useEffect } from "react";
import strings from "../../global/strings";
import FormSelectBox from "../../components/common/select-box/form-select-input.component";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
	getCompanyData,
	updateInfo,
} from "../../redux/actions/complete-registration.action";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
	company_name: yup.string().required("required"),
	company_phone: yup
		.number()
		.required("required")
		.typeError("Must be a number"),
	company_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	company_address: yup.string().required("required"),
	company_city: yup.string().required("required"),
	company_country: yup.string().required("required"),
});

export default function RegisterCompanyInformation({
	setProcessStep,
	processStep,
}) {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: { company_website: null },
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});
	const dispatch = useDispatch();

	useEffect(() => {
		const _getCompanyData = async () => {
			let companyData = await dispatch(getCompanyData());
			reset(companyData);
		};
		_getCompanyData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const _returnCompanyInfo = async (companyInfo) => {
		if (companyInfo.company_website === "") {
			companyInfo["company_website"] = null;
		}
		let updateStatus = await dispatch(
			updateInfo(strings.COMPANY_UPDATE_URL, companyInfo)
		);
		if (updateStatus) {
			setProcessStep(3);
		}
	};
	const onPrevClick = () => {
		setProcessStep(processStep - 1);
	};

	return (
		<form onSubmit={handleSubmit(_returnCompanyInfo)}>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Company Information</div>
				</div>
				<div className="form-body">
					<div className="form-description-title">
						Company Details Information
					</div>

					<div
						className="form-input-container"
						style={{ display: "none" }}
					>
						<FormTextInput name="company_info_id" ref={register} />
					</div>
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Company Name*"
							label="Company Name*"
							name="company_name"
							ref={register}
							error={errors?.company_name?.message}
						/>

						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Company Number*"
							label="Company Number*"
							name="company_phone"
							ref={register}
							error={errors?.company_phone?.message}
						/>
					</div>
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="email"
							placeholder="Company Email*"
							label="Company Email*"
							name="company_email"
							ref={register}
							error={errors?.company_email?.message}
						/>
						<FormTextInput
							variant="form-lg"
							placeholder="Company Website"
							label="Company Website"
							name="company_website"
							ref={register}
							error={errors?.company_website?.message}
						/>
					</div>

					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Address "
							label="Company address*"
							name="company_address"
							ref={register}
							error={errors?.company_address?.message}
						/>
						<div className="form-input-container inner">
							<FormSelectBox
								label="Country"
								variant="form-sm"
								options={strings.country}
								name="company_country"
								ref={register}
								type="Country"
								error={errors?.company_country?.message}
							/>

							<FormSelectBox
								label="City"
								variant="form-sm"
								options={strings.city}
								name="company_city"
								ref={register}
								type="City"
								error={errors?.company_city?.message}
							/>
						</div>
					</div>
				</div>
				<div className="form-footer two">
					<button className="button-primary" onClick={onPrevClick}>
						Back
					</button>
					<button className="button-primary">Next</button>
				</div>
			</div>
		</form>
	);
}

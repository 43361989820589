import React, { useEffect, useState } from "react";
import strings from "../../global/strings";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	updateInfo,
	getUserData,
} from "../../redux/actions/complete-registration.action";

export default function RegisterSettingsInformation() {
	const [userInfo, setUserInfo] = useState();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const _getUserData = async () => {
			let user = await dispatch(getUserData());
			setUserInfo(user);
		};
		_getUserData();
	}, [dispatch]);

	const _toDashboad = async () => {
		let settingsData = {
			email: userInfo.user_email,
			phone: userInfo.user_mobile,
			win_loss_id: userInfo.wid,
		};

		let updateStatus = await dispatch(
			updateInfo(strings.SETTINGS_UPDATE_URL, settingsData)
		);
		if (updateStatus === true) {
			history.push({
				pathname: "/dashboard",
			});
		}
	};

	return (
		<div className="form-main-content bg-default">
			<div className="form-notice-title">WELCOME TO FOOVENT</div>

			<div className="form-notice-message">
				Let's get you to your dashboard
			</div>

			<div className="form-notice-controller one">
				<button className="button-primary" onClick={_toDashboad}>
					Let's Go
				</button>
			</div>
		</div>
	);
}

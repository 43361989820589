import React from "react";
import "./boooking-history-table.style.scss";
import "./table.scss";
import { useDispatch } from "react-redux";
import { setVendorOnEdit } from "../../redux/actions/vendor.action";

export default function VendorTable({ list, onEditClick, onClickDelete }) {
	const dispatch = useDispatch();
	const vendorMobile = (info) => {
		return (
			<div
				className={`h-119 w-135  relative bg-default rounded-sm capitalize`}
				key={info.vendor_code}
			>
				<span className="absolute h-20 w-52 top-10px left-10px text-fontSemiDark text-md">
					{info.vendor_code}
				</span>
				<span className=" absolute h-23 w-65 top-10px right-10px text-sm text-right text-fontSemiDark">
					{info.contact_number[0]}
				</span>

				<span className=" absolute h-10 w-119 top-52px right-10px left-10px text-xs text-right overflow text-fontSemiDark">
					{info.vendor_name}
				</span>
				<span className=" absolute h-10 w-65 bottom-30px right-10px text-xs text-right text-fontSemiDark">
					{info.contact_person[0].name}
				</span>
				<span
					className={`absolute h-12 bottom-10px right-10px left-10px text-xs text-right`}
				>
					{info.vendor_status}
				</span>
			</div>
		);
	};

	const _editClick = (details) => {
		onEditClick();
		dispatch(setVendorOnEdit(details));
	};

	const _deleteClick = (details) => {
		onClickDelete();
		dispatch(setVendorOnEdit(details));
	};
	return (
		<div>
			{list.length > 0 ? (
				<table
					cellPadding={0}
					cellSpacing={0}
					className="table-container md:hidden"
				>
					<thead>
						<tr>
							<td
								className="table-header "
								style={{ width: "6%" }}
							>
								<div className="table-header-container">ID</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Vendor Name
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Address
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Primary Number
								</div>
							</td>

							<td className="table-header ">
								<div className="table-header-container">
									Secondary Number
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Contact Person
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Contact Person Number
								</div>
							</td>

							<td className="table-header ">
								<div className="table-header-container">
									Status
								</div>
							</td>
							<td
								className="table-header "
								style={{ width: "7%" }}
							></td>
						</tr>
					</thead>

					<tbody>
						{list.length > 0 &&
							list.map((list) => (
								<tr
									className="data-table-row inventory text-capitalize text-fontSemiDark"
									key={list._id}
								>
									<td>{list?.vendor_code}</td>
									<td>{list?.vendor_name}</td>
									<td>{list?.location?.address}</td>
									<td>{list?.contact_number[0]}</td>

									<td>
										{list?.contact_number?.length > 1
											? list?.contact_number[1]
											: "-"}
									</td>

									<td>{list?.contact_person[0]?.name}</td>
									<td>
										{list?.contact_person[0]?.mobile_number}
									</td>

									<td>{list?.vendor_status}</td>
									<td>
										<div className="flex gap-10px justify-end pr-7px">
											<button
												className="button-primary-icon"
												onClick={() => _editClick(list)}
											>
												<i className="fas fa-edit edit"></i>
											</button>
											<button
												className="button-primary-icon"
												onClick={() =>
													_deleteClick(list)
												}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			) : (
				<div>No Vendors</div>
			)}
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{list?.map((list) => vendorMobile(list))}
			</div>
		</div>
	);
}

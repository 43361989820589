/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputText from "../../common/text-input/form-text-input.component";
import FormInputTextArea from "../../common/text-input/form-textarea-input.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import strings from "../../../global/strings";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerAdvanceUpdate } from "../../../redux/actions/add-customer.action";

const customerSchema = yup.object().shape({
	customer_notes: yup.string(),
	identification_type: yup.string(),
	identification_number: yup
		.number()
		.typeError("Must be a number")
		.positive("Must be greater than zero")
		.transform((value) => (isNaN(value) ? undefined : value))
		.when("identification_type", {
			is: (value) => value !== "",
			then: yup
				.number()
				.required()
				.typeError("Must be a number")
				.positive("Must be greater than zero")
				.transform((value) => (isNaN(value) ? undefined : value)),
		}),
});

export default function Advanced(props) {
	const dispatch = useDispatch();
	const { basic, advance } = useSelector((state) => state.addCustomer);

	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {
			customer_gender: "",
			identification_type: "",
		},
		mode: "onSubmit",
		resolver: yupResolver(customerSchema),
	});
	// let customer_type = watch("customer_type");

	useEffect(() => {
		reset(advance);
	}, [props, reset]);

	async function returnCustomer(customerInfo) {
		let customerData = { ...basic, ...customerInfo };
		dispatch(addCustomerAdvanceUpdate(customerData));
		props.onClickSave(customerData);
	}
	const basicProcess = (data) => {
		props.setProcessStep(1);
		dispatch(addCustomerAdvanceUpdate(data));
	};

	function formStepTwo() {
		return (
			<form onSubmit={handleSubmit(returnCustomer)}>
				<div className="form-body">
					<div className="form-input-container">
						<FormInputText
							placeholder="(DD/MM/YYYY)"
							label="DOB"
							variant="form-md"
							type="date"
							name="customer_dob"
							ref={register}
							error={errors?.customer_dob?.message}
							disabled={basic.customer_type === "business"}
						/>
						<FormInputText
							placeholder="Enter Phone Number"
							label="Phone No."
							variant="form-lg"
							type="text"
							name="customer_phone"
							ref={register}
							error={errors?.customer_phone?.message}
						/>
					</div>
					<div className="form-input-container">
						<FormInputText
							label="PAN No."
							placeholder="PAN Number"
							variant="form-lg"
							name="pan_number"
							ref={register}
							error={errors?.pan_number?.message}
						/>

						<FormInputText
							placeholder="VAT Number"
							label="VAT No."
							variant="form-lg"
							name="vat_number"
							ref={register}
							error={errors?.vat_number?.message}
							disabled={basic.customer_type !== "business"}
						/>
					</div>

					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormSelectBox
								label="Identification Type"
								variant="form-sm"
								options={strings.identification}
								name="identification_type"
								ref={register}
								type="ID Type"
								error={errors?.identification_type?.message}
							/>

							<FormInputText
								label="ID Number"
								placeholder="Identification Number"
								variant="form-md"
								name="identification_number"
								type="text"
								ref={register}
								error={errors?.identification_number?.message}
							/>
						</div>
						<FormInputTextArea
							label="Notes"
							placeholder="Notes"
							variant="form-notes"
							name="customer_notes"
							ref={register}
							error={errors?.customer_notes?.message}
						/>
					</div>
				</div>
				<div className="form-footer two">
					<button
						onClick={() => {
							reset({});
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<div className="three-button">
						<button
							onClick={handleSubmit(basicProcess)}
							className="button-primary"
						>
							Basic
						</button>
						<button
							className="button-primary"
							onClick={handleSubmit(returnCustomer)}
						>
							{props.actionType}
						</button>
					</div>
				</div>
			</form>
		);
	}

	return formStepTwo();
}

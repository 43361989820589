import React, { useMemo } from "react";
import "./analytics-component.style.scss";
// charts
import {
	Chart as ChartJS,
	RadialLinearScale,
	Legend,
	ArcElement,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";

ChartJS.register(RadialLinearScale, Legend, ArcElement);

export default function BlockGraph({ anaDataSet, type = "PolarArea" }) {
	const structuredDataObj = useMemo(() => {
		let objReturn = { data: [], label: [], total: 0 };
		objReturn.data = Object.values(anaDataSet);
		objReturn.label = Object.keys(anaDataSet);
		objReturn.total = objReturn.data.reduce((a, b) => a + b, 0);
		return objReturn;
	}, [anaDataSet]);

	const colors = [
		"rgba(255, 99, 132, 0.8)",
		"rgba(75, 192, 192, 0.8)",
		"rgba(255, 205, 86, 0.8)",
		"rgba(54, 162, 235, 0.8)",
		"rgba(153, 102, 255, 0.8)",
		"rgba(86, 98, 137,1)",
		"rgba(242, 180, 6,1)",
		"rgba(66, 186, 119,1)",
		"rgba(249, 200, 205,1)",
		"rgba(242, 184, 23,1)",
	];

	const data = {
		labels: structuredDataObj.label,
		datasets: [
			{
				data: structuredDataObj.data,
				backgroundColor: colors,
			},
		],
	};
	const options = {
		scales: {
			r: {
				ticks: {
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};
	return (
		<div className="ana-polar-wrapper">
			<div className="ana-polar-header">Bookings Category</div>
			<div className="ana-polar-container">
				<div className="ana-polar-chart">
					<PolarArea data={data} options={options} />
				</div>
				<div className="polar-data-list">
					{structuredDataObj.label.map((label, key) => (
						<div className="pd-list-block" key={key}>
							<div className="pd-l-label">
								<div
									className="pd-l-icon"
									style={{
										backgroundColor: colors[key],
									}}
								></div>
								<div className="pd-l-text">{label}</div>
							</div>
							<div className="pd-l-data-bar">
								<div
									style={{
										width: `${
											(structuredDataObj.data[key] *
												100) /
											structuredDataObj.total
										}%`,
										backgroundColor: colors[key],
									}}
								></div>
								<div
									style={{
										width: `${
											((structuredDataObj.total -
												structuredDataObj.data[key]) *
												100) /
											structuredDataObj.total
										}%`,
										backgroundColor: "#d8d8d87f",
									}}
								></div>
							</div>
							<div className="pd-l-data">
								{structuredDataObj.data[key]}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./table-employee-list.style.scss";
import "./table.scss";
import moment from "moment";

import EmployeeVenuePermission from "../employee-venue-permission/employee-venue-permission.component";
import PaginationReact from "../paginationReact/paginationReact.component";

export default function TableEmployeeList(props) {
	const history = useHistory();
	const [detailsShown, setDetailShown] = useState([]);

	const [currentEmployeePage, setCurrentEmployeePage] = useState(1);
	const [listPerEmployeePage] = useState(8);

	const indexOfLastEmployeeList = currentEmployeePage * listPerEmployeePage;
	const indexOfFirstEmployeeList =
		indexOfLastEmployeeList - listPerEmployeePage;
	const currentEmployeeList = props.data.slice(
		indexOfFirstEmployeeList,
		indexOfLastEmployeeList
	);

	useEffect(() => {
		setCurrentEmployeePage(1);
	}, [props]);

	const toggleNew = (rowId) => {
		const shownState = detailsShown.slice();
		const index = shownState.indexOf(rowId);
		if (index >= 0) {
			shownState.splice(index, 1);
			setDetailShown(shownState);
		} else {
			shownState.push(rowId);
			setDetailShown(shownState);
		}
	};

	const _renderVenuePermissionRow = (info) => {
		return (
			<EmployeeVenuePermission
				type="disabled"
				key={info.venue_employee_id}
				venueName={info.venue_info.venue_name}
				role={props.role !== "viewer" ? info.venue_role : "Staff"}
				venueColor={info.venue_info.venue_color_code}
				startDate={moment(info.createdAt).format("DD/MM/YYYY")}
				status={info.venue_employee_status}
			/>
		);
	};
	function viewEmployee(employeeId, employeeInfoId) {
		history.push({
			pathname: "/employee-profile",
			search: `?employee_id=${employeeId}`,
			state: { employee_info_id: employeeInfoId },
		});
	}

	const onClickStyle = {
		cursor: "pointer",
	};

	const mobileEmpView = (info) => {
		return (
			<div
				className={`h-255 w-135 bodered flex flex-col rounded-sm relative `}
				key={info.user_info_id}
			>
				<span className="h-119 w-135 bg-default rounded-sm flex justify-center items-center overflow-hidden">
					{info.user_image ? (
						<img src={info.user_image} alt="" />
					) : (
						<i className="fas fa-user-alt fa-5x"></i>
					)}
				</span>
				<span className="h-23 text-sm mt-10px text-center capitalize mx-7px">
					{info.first_name} {info.last_name}
				</span>
				<span className="text-xs border-t border-fontLight mx-7px text-center text-fontSemiDark pt-7px mt-7px">
					{info.user_mobile}
				</span>
				<span className="text-xs mt-5px text-center px-7px text-fontSemiDark overflow">
					{info.user_email}
				</span>

				<button
					className={`absolute button-primary-icon bottom-15px left-0px right-0px m-auto `}
					onClick={() =>
						viewEmployee(info.user_id, info.user_info_id)
					}
				>
					{info.user_verified ? (
						<i className="fas fa-check text-buttonSuccess"></i>
					) : (
						<i className="fas fa-times text-buttonFailed"></i>
					)}
				</button>
			</div>
		);
	};

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentEmployeePage(currentPage);
	};

	return (
		<div className="table-wrapper">
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						<td className="table-header employee-image"></td>
						<td className="table-header employee-id">
							<div className="table-header-container">ID</div>
						</td>
						<td className="table-header employee-name">
							<div className="table-header-container">Name</div>
						</td>
						<td className="table-header employee-phone">
							<div className="table-header-container">Phone</div>
						</td>
						<td className="table-header employee-email">
							<div className="table-header-container">Email</div>
						</td>
						<td className="table-header employee-role">
							<div className="table-header-container">Roles</div>
						</td>
						<td className="table-header employee-action">
							<div className="table-header-container">Venues</div>
						</td>
					</tr>
				</thead>

				{currentEmployeeList.map((row) => (
					<tbody key={row.user_id}>
						<tr
							className="data-table-row bg-default"
							key={row.user_id}
						>
							<td
								className="image-cell"
								onClick={() =>
									viewEmployee(row.user_id, row.user_info_id)
								}
							>
								{row.user_image ? (
									<img
										src={row.user_image}
										alt=""
										height="50"
										width="50"
									/>
								) : (
									<i className="fas fa-user-alt fa-2x"></i>
								)}
							</td>
							<td
								onClick={() =>
									viewEmployee(row.user_id, row.user_info_id)
								}
								style={onClickStyle}
								className="id-cell"
							>
								{row.user_id}
							</td>
							<td
								onClick={() =>
									viewEmployee(row.user_id, row.user_info_id)
								}
								style={onClickStyle}
							>
								{row.first_name} {row.last_name}
							</td>
							<td
								onClick={() =>
									viewEmployee(row.user_id, row.user_info_id)
								}
							>
								{row.user_mobile}
							</td>
							<td
								onClick={() =>
									viewEmployee(row.user_id, row.user_info_id)
								}
							>
								{row.user_email}
							</td>
							<td
								className="text-capitalize"
								onClick={() =>
									viewEmployee(row.user_id, row.user_info_id)
								}
							>
								{props.role !== "viewer"
									? `${row.company_employees[0].company_role}`
									: "Employee"}
							</td>
							<td
								className={
									row.company_employees[0]
										.company_employee_status === "active"
										? "venue-cell"
										: "venue-cell inactive"
								}
								onClick={() => toggleNew(row.user_id)}
							>
								{
									row.company_employees[0].venue_employees
										.length
								}
							</td>
						</tr>
						{detailsShown.includes(row.user_id) && (
							<tr
								// className="venue-permission-row"
								id={`permission-row-${row.user_id}`}
							>
								<td colSpan="7">
									<div className="venue-permission-wrapper">
										{row.company_employees[0].venue_employees.map(
											(per) =>
												_renderVenuePermissionRow(per)
										)}
									</div>
								</td>
							</tr>
						)}
					</tbody>
				))}
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{currentEmployeeList.map((list) => mobileEmpView(list))}
			</div>
			<PaginationReact
				totalCount={props.data.length}
				itemsPerPage={listPerEmployeePage}
				onPageClick={onPageChange}
				forcePage={currentEmployeePage - 1}
			/>
		</div>
	);
}

import {
	SIGNUP_STEP_ONE_UPDATE,
	SIGNUP_STEP_TWO_UPDATE,
	SIGNUP_SUBMIT_START,
	SIGNUP_SUBMIT_SUCCESS,
	SIGNUP_SUBMIT_FAILED,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { isEmptyObject } from "../../global/function";

export const updateSignupStepOne = (payload) => ({
	type: SIGNUP_STEP_ONE_UPDATE,
	payload: payload,
});

export const updateSignupStepTwo = (payload) => ({
	type: SIGNUP_STEP_TWO_UPDATE,
	payload: payload,
});

export const signUpFailed = (payload) => ({
	type: SIGNUP_STEP_TWO_UPDATE,
	payload: payload,
});

export const submitSignUp = (payload) => {
	return (dispatch, getState) => {
		const { stepOne } = getState().signup;
		const data = { ...stepOne, ...payload };
		delete data.confirm_password;
		dispatch({
			type: SIGNUP_SUBMIT_START,
			payload: payload,
		});

		return axios
			.post(`${strings.url}user/signup`, data, {
				headers: { "content-type": "application/json" },
			})
			.then((res) => {
				if (res.data.success) {
					dispatch({ type: SIGNUP_SUBMIT_SUCCESS });
				} else {
					dispatch({
						type: SIGNUP_SUBMIT_FAILED,
						payload: res.data.message,
					});
					showToastMessage(res.data.message, "E");
				}
			})
			.catch((error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					dispatch({
						type: SIGNUP_SUBMIT_FAILED,
						payload: error.response.data.message,
					});

					showToastMessage(error.response.data.message, "E");
				} else {
					dispatch({
						type: SIGNUP_SUBMIT_FAILED,
						payload: error.message,
					});
					showToastMessage(error.message, "E");
				}
			});
	};
};

import React, { useEffect, useState } from "react";
// import "./boooking-history-table.style.scss";
import "./payment-history-table.style.scss";
import moment from "moment";
import "./table.scss";
import { useHistory } from "react-router-dom";
import { sessionInfo, rupeeConverter } from "../../global/function";

import { useDispatch } from "react-redux";
import { deletePayment, editPayment } from "../../redux/actions/payment-action";

import NibjarNepaliDate from "../../global/nibjar-nepali-date/nibjar-nepali-converter";

import ConfirmationModal from "../../components/modals/confirmation-modal.component";
import ConfirmationModalOnInput from "../modals/confirmation-modalOnInput.modal";

export default function PaymentHistoryTable({
	paymentHistoryList,
	onEditClick,
	dataPerPage,
	currentPage,
	paymentPrintView,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [detailsShown, setDetailShown] = useState(0);
	const [company_role, setCompanyRole] = useState();
	const [confirmationModalFlag, setConfirmationModalFlag] = useState(false);
	// void payment model
	const [voidConfirmationModalFlag, setVoidConfirmationModalFlag] =
		useState(false);

	const handleDeletePaymentModalShow = () => setConfirmationModalFlag(true);

	const handleDeletePaymentModalClose = () => {
		setConfirmationModalFlag(false);
	};

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, []);

	const toggleNew = (rowId) => {
		// const shownState = detailsShown.slice();
		// const index = shownState.indexOf(rowId);
		// if (index >= 0) {
		// 	shownState.splice(index, 1);
		// 	setDetailShown(shownState);
		// } else {
		// 	shownState.push(rowId);
		// 	setDetailShown(shownState);
		// }
		setDetailShown(rowId);
	};

	const onClickStyle = {
		cursor: "pointer",
	};

	const viewBooking = (booking) => {
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${booking.event_id}`,
			state: {
				event_info_id: booking.event_info_id,
				booking_info_id: booking.booking_info_id,
			},
		});
	};

	const onDeleteClick = async (row) => {
		dispatch(
			deletePayment(
				row.payment_info_id,
				row.venue_info_id,
				row.booking_info_id,
				dataPerPage,
				currentPage
			)
		);
		handleDeletePaymentModalClose();
	};

	const handlePaymentVoidModalShow = () => setVoidConfirmationModalFlag(true);
	const handleVoidPayment = async (row, reason, revert = false) => {
		const voidPaymentData = {
			booking_info_id: row.booking_info_id,
			venue_info_id: row.venue_info_id,
			payment_info_id: row.payment_info_id,
			transaction_type: revert ? "void revert" : "void",
			payment_notes: reason || moment().format("YYYY-MM-DD"),
			cheque_number: row.cheque_number,
		};

		setVoidConfirmationModalFlag(false);
		dispatch(await editPayment(voidPaymentData));
	};

	const bookingDetailsComponent = (title, value) => {
		if (title === "Note") {
			return (
				<div className="payment-detail-dropdown-container payment-notes">
					<div className="payment-history-dropdown-title">
						{title}
					</div>
					<div className="payment-note-block">
						{value.split("\n").map((text, idx) => (
							<span>
								{text}
								<br />
							</span>
						))}
					</div>
				</div>
			);
		}
		return (
			<div className="payment-detail-dropdown-container">
				<div className="payment-history-dropdown-title">{title}</div>
				<div
					className={
						title === "Booking Status"
							? `booking-status-content ${value}`
							: "payment-history-dropdown-value"
					}
				>
					{value}
				</div>
			</div>
		);
	};
	const mobilePaymentView = (info) => {
		const paymentDateEng = moment(info.createdAt).format("YYYY-MM-DD");

		let decreasedMonth = moment(paymentDateEng)
			.subtract(1, "month")
			.format("YYYY-MM-DD");
		let a = decreasedMonth.split("-");
		let paymentDateNep = new NibjarNepaliDate(new Date(a[0], a[1], a[2]));

		return (
			<div
				className={`h-119 w-135 bodered rounded-sm relative text-fontSemiDark`}
				key={info.payment_id}
				onClick={() => {
					viewBooking(info);
				}}
			>
				<span className="absolute h-20 w-20 top-10px left-10px">
					{info?.transaction_type?.charAt(0).toUpperCase()}
				</span>
				<span className=" absolute h-23 w-119 top-10px right-10px border-b pb-7px border-fontWhite text-sm text-right">
					{paymentDateNep.format("DD MMMM")}{" "}
					{paymentDateNep.format("YYYY")}
				</span>
				<span className=" absolute h-10 w-20 top-38px left-10px text-xs">
					{info.payment_id}
				</span>
				<span className=" absolute h-10 w-119 top-38px right-10px text-xs text-right overflow-hidden">
					{info?.venue_name}
				</span>
				{
					<span className=" absolute h-10 w-65 top-52px right-10px text-xs text-right">
						{info?.customer_fname} {info?.customer_lname}
					</span>
				}
				<span className=" absolute h-10 w-119 bottom-44px right-10px text-xs text-right overflow"></span>
				<span className=" absolute h-10 w-65 bottom-30px right-10px text-xs text-right capitalize">
					{info.transaction_type}
				</span>
				<span
					className={`absolute h-12 bottom-10px right-10px left-10px text-xs text-right capitalize text-primaryBlack`}
				>
					{rupeeConverter(info.paid_amount.toFixed(2))}
					{/* {`Rs. ${parseFloat(info.paid_amount).toFixed(2)}`} */}
				</span>
			</div>
		);
	};

	return (
		<div className="table-wrapper">
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						<td className="table-header booking-time"></td>
						<td className="table-header booking-id">
							<div className="table-header-container">BID</div>
						</td>
						<td className="table-header booking-id">
							<div className="table-header-container">PID</div>
						</td>
						<td className="table-header booking-date">
							<div className="table-header-container">
								EN Date
							</div>
						</td>
						<td className="table-header booking-date">
							<div className="table-header-container">
								NP DATE
							</div>
						</td>
						<td className="table-header booking-venue-name">
							<div className="table-header-container">Venue</div>
						</td>
						<td className="table-header booking-customer-name">
							<div className="table-header-container">
								Customer
							</div>
						</td>

						<td className="table-header booking-catering w-16">
							<div className="table-header-container">Type</div>
						</td>
						<td className="table-header booking-pax">
							<div className="table-header-container">Emp</div>
						</td>
						<td className="table-header booking-rate">
							<div className="table-header-container">Amount</div>
						</td>
					</tr>
				</thead>

				{paymentHistoryList.length > 0 &&
					paymentHistoryList.map((row) => {
						const paymentDateEng = moment(row.createdAt).format(
							"YYYY-MM-DD"
						);

						let decreasedMonth = moment(paymentDateEng)
							.subtract(1, "month")
							.format("YYYY-MM-DD");
						let a = decreasedMonth.split("-");
						let paymentDateNep = new NibjarNepaliDate(
							new Date(a[0], a[1], a[2])
						);
						let rowDeleteButtonCondition =
							row?.payment_status !== "complete" &&
							row?.transaction_type !== "void" &&
							(row?.booking_status !== "canceled" ||
								row.transaction_type === "refund");

						function _renderRowAcionButtons() {
							let statusBasedDisable =
								row?.booking_status === "canceled" ||
								row?.payment_status === "complete";

							if (row?.transaction_type === "void") {
								return (
									<button
										className="button-primary button-red"
										onClick={() => {
											handleVoidPayment(row, "", true);
										}}
									>
										Revert Void
									</button>
								);
							} else {
								return (
									<>
										<button
											className="button-wrapper payment-history-edit-button"
											onClick={() =>
												paymentPrintView(row)
											}
											disabled={statusBasedDisable}
										>
											<i className="fas fa-print"></i>
										</button>
										<button
											className="button-wrapper payment-history-edit-button"
											onClick={() => {
												onEditClick(row);
											}}
											disabled={statusBasedDisable}
										>
											<i className="fas fa-edit edit"></i>
										</button>

										{/* voiding payment logic */}
										<button
											className="button-wrapper payment-history-edit-button"
											onClick={handlePaymentVoidModalShow}
											disabled={statusBasedDisable}
										>
											<i className="fas fa-ban edit"></i>
										</button>

										{/* delete payment logic */}
										{rowDeleteButtonCondition && (
											<button
												className="button-wrapper payment-history-edit-button"
												onClick={
													handleDeletePaymentModalShow
												}
												disabled={
													company_role !== "admin"
												}
											>
												<i className="fas fa-trash-alt edit"></i>
											</button>
										)}
									</>
								);
							}
						}

						return (
							<tbody key={row.payment_id}>
								<tr
									className="data-table-row bg-default text-capitalize"
									key={row.payment_id}
									onClick={() => toggleNew(row.payment_id)}
									style={onClickStyle}
								>
									<td
										className={`booking-time-cell ${row.transaction_type}`}
									>
										{row.transaction_type
											.charAt(0)
											.toUpperCase()}
									</td>
									<td className="text-center">
										{row.booking_id}
									</td>
									<td className="text-center">
										{row.payment_id}
									</td>
									<td>
										{moment(row.createdAt).format(
											"YYYY-MM-DD"
										)}{" "}
									</td>
									<td>
										{paymentDateNep.format("DD MMMM")}{" "}
										{paymentDateNep.format("YYYY")}{" "}
									</td>

									<td>{row.venue_name}</td>
									<td>
										{row.customer_fname}{" "}
										{row.customer_lname}
									</td>
									<td>{row.payment_type}</td>
									<td>
										{row.paymentEmployee_first_name +
											" " +
											row.paymentEmployee_last_name}
									</td>

									<td
										className={`body-table-cell paid_amount`}
									>
										{rupeeConverter(
											row.paid_amount.toFixed(2)
										)}
									</td>
								</tr>

								{detailsShown === row.payment_id && (
									<tr
										className="venue-permission-row text-capitalize"
										id={`permission-row-${row.payment_id}`}
									>
										<td colSpan="10">
											<div className="payment-detail-dropdown-wrapper">
												{bookingDetailsComponent(
													"Booking Date",
													moment(
														row?.booked_date_english
													).format("YYYY-MM-DD")
												)}
												{/* bookingDetailsComponent(
												"Booking Status",
												row.payment_status
											) */}
												{bookingDetailsComponent(
													"Booking Status",
													row?.booking_status
												)}
												{/* bookingDetailsComponent(
												"Related VAT",
												"nonnnnn"
											) */}
												{bookingDetailsComponent(
													"Category",
													row.transaction_type
												)}
												{bookingDetailsComponent(
													"Cheque #",
													row.cheque_number || "-"
												)}
												{/* bookingDetailsComponent(
												"User",
												"nonnnnee"
											) */}
												{/* {bookingDetailsComponent(
													"Note",
													row.payment_notes || "-"
												)} */}
												<div className="payment-history-button-wrapper">
													<button
														className="button-primary"
														onClick={() => {
															viewBooking(row);
														}}
													>
														View Booking
													</button>
													{_renderRowAcionButtons()}
												</div>
												<ConfirmationModal
													type="deletePayment"
													isVisible={
														confirmationModalFlag
													}
													handleClose={
														handleDeletePaymentModalClose
													}
													handleShow={
														handleDeletePaymentModalShow
													}
													onClickConfirmation={() =>
														onDeleteClick(row)
													}
												/>
												{/* void payment model */}
												<ConfirmationModalOnInput
													isVisible={
														voidConfirmationModalFlag
													}
													handleClose={() =>
														setVoidConfirmationModalFlag(
															false
														)
													}
													handleBookingCancel={(
														reason
													) =>
														handleVoidPayment(
															row,
															reason
														)
													}
													modalFor="Void Payment"
													modalMessage="*Note: payment will be voided not deleted"
													modalTitle="Void Payment"
												/>
											</div>
											<div className="payment-detail-dropdown-wrapper">
												{bookingDetailsComponent(
													"Note",
													row.payment_notes || "-"
												)}
											</div>
										</td>
									</tr>
								)}
							</tbody>
						);
					})}
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{paymentHistoryList.map((list) => mobilePaymentView(list))}
			</div>
		</div>
	);
}

import React, { useEffect, useState } from "react";
import { rupeeConverter } from "../../global/function";
import "./progressBlock.style.scss";
// import {rupeeConverter} from "../../global/function";

const ProgressBlock = ({
	label,
	total,
	value,
	startPosition = "",
	segments = false,
	breakpoint = [0, 25, 50, 100],
	// while clicking on value box
	_showTransactions,
	type,
}) => {
	/* Label: display label over the bar
    total: end value that bar is compaired to
    value: number that the bar will display
    startPosition: Displays left if empty, else displays from right
    segments: true will add segment on the bottom of the bar
    breakpoints: [4] values that the bar segments will be devided to */

	const [percent, setPercent] = useState();
	const [points, setPoints] = useState([]);

	useEffect(() => {
		breakpoints();
		//condition to remove negative or > 100 percent value
		if (segments) {
			if (value <= breakpoint[0]) {
				setPercent(1);
			} else if (value > breakpoint[3]) {
				setPercent(100);
			} else {
				setPercent(
					Math.floor(
						((value - breakpoint[0]) * 100) /
							(total - breakpoint[0])
					)
				);
			}
		} else setPercent(Math.floor((value * 100) / total));
	}, [total, value]);

	//creates [2] segments in a 100% base
	function breakpoints() {
		let progressPoints = [];
		let base = breakpoint[0];
		let max = breakpoint[breakpoint.length - 1] - base;
		breakpoint.forEach((point) => {
			let cal = ((point - base) * 100) / max;
			if (cal !== 0 && cal !== 100) {
				progressPoints.push(cal);
			}
		});
		setPoints(progressPoints);
	}

	//Display colors sets for bar
	function colorSet(num) {
		let color = "";
		if (num > points[0]) {
			if (num > points[1]) {
				color = "green";
			} else color = "orange";
		} else color = "red";

		return color;
	}

	return (
		<div className={`progress-wrapper`}>
			<p>{label}</p>
			<div
				className="progressbar-wrapper bg-default"
				onClick={() => _showTransactions(type)}
			>
				<div
					className={`progressbar ${startPosition}`}
					style={{
						width: `${percent}%`,
						backgroundColor: `${colorSet(percent)}`,
					}}
				>
					{percent > 50 && rupeeConverter(`${value}`)}
				</div>
				{percent <= 50 && (
					<p className={`${startPosition}`}>
						{rupeeConverter(`${value}`)}
					</p>
				)}
			</div>
			{segments && (
				<div className="segment-block">
					<div style={{ paddingLeft: "5px", width: `${points[0]}%` }}>
						{breakpoint[0]}
					</div>
					<div
						style={{
							paddingLeft: "5px",
							width: `${points[1] - points[0]}%`,
						}}
					>
						{breakpoint[1]}
					</div>
					<div
						style={{
							paddingLeft: "5px",
							width: `${100 - points[1]}%`,
						}}
					>
						{breakpoint[2]}
					</div>
				</div>
			)}
		</div>
	);
};

export default ProgressBlock;

import { FETCH_VENUE_SUCCESS, FETCH_VENUE_ERROR } from "../action-types";

import { isEmptyObject, sessionInfo } from "../../global/function";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import history from "../history";
import { fetchLoginError } from "./login-action";

export const fetchVenueSuccess = (venueInfo, venueDropDown) => {
	return {
		type: FETCH_VENUE_SUCCESS,
		payload: { venueInfo, venueDropDown },
	};
};

export const fetchVenueError = (error) => {
	return {
		type: FETCH_VENUE_ERROR,
		payload: error,
	};
};

export const fetchVenue = () => {
	return async (dispatch) => {
		let sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}user/venueInfos?company_employee_id=${sessionData.company_employee_id}&company_role=${sessionData.company_role}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					let venueList = [];
					let dropDownList = [];

					res.data.result.map((venue) => {
						if (venue.venue_employee_status === "active") {
							venueList.push({ ...venue, checked: true });
						}
						return null;
					});
					res.data.result.map((venue) => {
						if (venue.venue_employee_status === "active") {
							dropDownList.push({
								id: venue.venue_info_id,
								value: venue.venue_name,
							});
						}
						return null;
					});
					await dispatch(fetchVenueSuccess(venueList, dropDownList));
				} else {
					await dispatch(fetchVenueError(res.data.message));
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else if (status === 400) {
					showToastMessage(error.response.data.message, "E");
					await dispatch(
						fetchVenueError(error.response.data.message)
					);
				} else {
					await dispatch(fetchVenueError(error.message));
				}
			});
	};
};

import {
	FETCH_VENDOR_REQUEST,
	FETCH_VENDOR_SUCCESS,
	FETCH_VENDOR_ERROR,
	SET_VENDOR_ON_EDIT,
	CLEAR_VENDOR_ON_EDIT,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchVendorRequest = () => {
	return {
		type: FETCH_VENDOR_REQUEST,
	};
};

export const fetchVendorSuccess = (vendor) => {
	return {
		type: FETCH_VENDOR_SUCCESS,
		payload: vendor,
	};
};

export const fetchVendorError = (error) => {
	return {
		type: FETCH_VENDOR_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchVendorError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchVendorError(error.message));
	}
}

export const fetchVendors = () => {
	return async (dispatch) => {
		dispatch(fetchVendorRequest());
		const sessionData = await sessionInfo();

		axios
			.get(`${strings.inventoryURL}vendor/company`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchVendorSuccess(res.data.result));
				} else {
					await dispatch(fetchVendorError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
export const setVendorOnEdit = (vendor) => {
	return (dispatch) => {
		dispatch({
			type: SET_VENDOR_ON_EDIT,
			payload: vendor,
		});
	};
};
export const clearVendorOnEdit = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_VENDOR_ON_EDIT,
		});
	};
};

export const searchVendor = (vendor) => {
	return async (dispatch) => {
		dispatch(fetchVendorRequest());
		const sessionData = await sessionInfo();
		const url = `${strings.inventoryURL}vendor/search?vendor_code=${vendor.vendor_code}&vendor_name=${vendor.vendor_name}&contact_number=${vendor.contact_number}&vendor_status=${vendor.vendor_status}&location_id=${vendor.location_id}`;

		axios
			.get(url, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(
						fetchVendorSuccess({ vendors: res.data.result })
					);
				} else {
					await dispatch(fetchVendorError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addVendor = (vendor) => {
	return async (dispatch) => {
		dispatch(fetchVendorRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.inventoryURL}vendor`, vendor, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchVendorSuccess(response.data.result));
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateVendor = (vendor) => {
	return async (dispatch) => {
		dispatch(fetchVendorRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}vendor/company/update`, vendor, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchVendorSuccess(response.data.result));
				} else {
					await dispatch(fetchVendorError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteVendor = (vendor) => {
	return async (dispatch) => {
		dispatch(fetchVendorRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}vendor/company/delete`, vendor, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchVendors());
				} else {
					await dispatch(fetchVendorError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

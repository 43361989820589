import React, { useState } from "react";
import "./signup.style.scss";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/loader.component";
import SignupStepOne from "./signup-step-one";
import SignupStepTwo from "./signup-step-two";
import strings from "../../global/strings";

const Signup = () => {
	const [processStep, setProcessStep] = useState(1);
	const { success, loading } = useSelector((state) => state.signup);

	function returnEmailView() {
		return (
			<div className="form-main-content bg-default">
				<h2 className="email-confirmation-title">
					{strings.email_verification}
				</h2>

				<p className="email-confirmation-message">
					{strings.email_verification_message}
				</p>
			</div>
		);
	}

	const _returnNavView = () => {
		return (
			<div className="form-navigator-wrapper">
				<div
					className={
						processStep === 1
							? "form-navigator-title selected"
							: "form-navigator-title"
					}
				>
					Details
				</div>
				<div
					className={
						processStep === 2
							? "form-navigator-title selected"
							: "form-navigator-title"
					}
				>
					Subscription
				</div>
			</div>
		);
	};

	return (
		<div className="signin-page-wrapper">
			{loading && <Loader />}
			<div className="signup-main-container">
				<div className="company-title">CATER ME</div>

				{success ? (
					returnEmailView()
				) : processStep === 1 ? (
					<SignupStepOne
						setProcessStep={setProcessStep}
						nav={_returnNavView}
					/>
				) : (
					processStep === 2 && (
						<SignupStepTwo
							setProcessStep={setProcessStep}
							nav={_returnNavView}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default Signup;

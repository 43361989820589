import React from "react";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	fetchInventoriesByLocationId,
	searchInventory,
} from "../../redux/actions/inventory.action";

export default function SearchInventoryComponent() {
	const { register, handleSubmit, reset } = useForm();
	const dispatch = useDispatch();
	const _clearInventorySearch = () => {
		reset({
			item_name: "",
			total_quantity: "",
			sub_type: "",
			brand: "",
		});
		dispatch(
			fetchInventoriesByLocationId(localStorage.getItem("location_id"))
		);
	};

	function _searchInventory(data) {
		dispatch(searchInventory(data));
	}

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Inventory</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-lg hidden"
					name="location_id"
					ref={register}
					defaultValue={localStorage.getItem("location_id")}
				/>
				<SimpleTextInput
					variant="form-lg edit"
					label="Item Name"
					placeholder="Enter Item Name"
					type="text"
					name="item_name"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Inventory "
					placeholder="Enter Inventory"
					type="number"
					name="total_quantity"
					ref={register}
				/>

				<SimpleTextInput
					type="text"
					variant="form-md edit"
					placeholder="Enter Item Type"
					label="Item Type"
					name="sub_type"
					ref={register}
				/>
				<SimpleTextInput
					type="text"
					variant="form-md edit"
					placeholder="Enter Brand "
					label="Brand"
					name="brand"
					ref={register}
				/>
			</div>
			<div className="flex gap-x-15px justify-between">
				<div className="flex gap-10px">
					<Link to={"/inventory/add/category"}>
						<button className="button-primary">Add Category</button>
					</Link>
					<Link to={"/inventory/update/category"}>
						<button className="button-primary">
							View Category
						</button>
					</Link>
				</div>
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearInventorySearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={handleSubmit(_searchInventory)}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}

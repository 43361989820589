import React, { useEffect, useState } from "react";
import FormSelectBox from "../../components/common/select-box/form-select-input.component";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
	getUserData,
	updateInfo,
} from "../../redux/actions/complete-registration.action";

import strings from "../../global/strings";
import { sessionInfo } from "../../global/function";

const schema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	user_address: yup.string().required("required"),
	user_city: yup.string().required("required"),
	user_country: yup.string().required("required"),
	user_mobile: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	user_dob: yup.string().required("required"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	user_gender: yup.string().required("required"),
	identification_type: yup.string(),
	user_phone: yup
		.number()
		.typeError("Must be a number")
		.transform((value) => (value ? value : 0)),
});

export default function RegisterUserInformation({ setProcessStep }) {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});
	const dispatch = useDispatch();
	const [sessionData, setSessionData] = useState({});

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setSessionData({
					user_info_id: data.user_info_id,
					company_employee_id: data.company_employee_id,
				});
			});
		}

		fetchSessionData();

		const _getUserData = async () => {
			let userInfo = await dispatch(getUserData());
			reset(userInfo);
		};
		_getUserData();
	}, [dispatch, reset]);

	const _returnUserInfo = async (userinfo) => {
		userinfo = {
			...userinfo,
			user_info_id: sessionData.user_info_id,
			company_employee_id: sessionData.company_employee_id,
			user_verified: true,
			identification: {
				identification_type: userinfo.identification_type || null,
				identification_number: userinfo.identification_number || null,
				pan_number: userinfo.pan_number || null,
			},
		};
		let updateStatus = await dispatch(
			updateInfo(strings.USERINFO_UPDATE_URL, userinfo)
		);
		if (updateStatus) {
			setProcessStep(2);
		}
	};

	return (
		<form onSubmit={handleSubmit(_returnUserInfo)}>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Getting to know You</div>
				</div>
				<div className="form-body">
					<div className="form-description-title">
						Account Information
					</div>

					<div
						className="form-input-container"
						style={{ display: "none" }}
					>
						<FormTextInput name="user_info_id" ref={register} />
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormTextInput
								variant="form-md"
								type="text"
								placeholder="First Name*"
								label="First Name*"
								name="first_name"
								ref={register}
								error={errors?.first_name?.message}
							/>

							<FormTextInput
								variant="form-md"
								type="text"
								placeholder="Last Name*"
								label="Last Name*"
								name="last_name"
								ref={register}
								error={errors?.last_name?.message}
							/>
						</div>
						<div className="form-input-container inner">
							<FormTextInput
								type="date"
								label="DOB*"
								placeholder="DOB(DD/MM/YYYY)*"
								variant="form-md"
								name="user_dob"
								ref={register}
								error={errors?.user_dob?.message}
							/>

							<FormSelectBox
								label="Gender"
								variant="form-sm"
								options={strings.gender}
								name="user_gender"
								ref={register}
								type="Gender"
								error={errors?.user_gender?.message}
							/>
						</div>
					</div>
					<div className="form-description-title">
						Profile Information
					</div>
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							placeholder="Email"
							label="Email*"
							name="user_email"
							ref={register}
							type="email"
							error={errors?.user_email?.message}
						/>
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Address Line"
							label="Address*"
							name="user_address"
							ref={register}
							error={errors?.user_address?.message}
						/>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormTextInput
								variant="form-md"
								type="text"
								placeholder="Mobile Number*"
								label="Mobile*"
								name="user_mobile"
								ref={register}
								error={errors?.user_mobile?.message}
							/>
							<FormTextInput
								variant="form-md"
								type="text"
								placeholder="Seconday Number*"
								label="Landline"
								name="user_phone"
								ref={register}
								error={errors?.user_phone?.message}
							/>
						</div>

						<div className="form-input-container inner">
							<FormSelectBox
								label="Country"
								variant="form-sm"
								options={strings.country}
								name="user_country"
								ref={register}
								type="Country"
								error={errors?.user_country?.message}
							/>

							<FormSelectBox
								label="City"
								variant="form-sm"
								options={strings.city}
								name="user_city"
								ref={register}
								type="City"
								error={errors?.user_city?.message}
							/>
						</div>
					</div>
				</div>
				<div className="form-footer one">
					<button className="button-primary">Next</button>
				</div>
			</div>
		</form>
	);
}

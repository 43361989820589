import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sessionInfo } from "../../global/function";
import { fetchActivity } from "../../redux/actions/notification.action";

import Loader from "../../components/loader/loader.component";
import ActivityMonitorTable from "../../components/tables/activity-monitor.table";
import PaginationReact from "../../components/paginationReact/paginationReact.component";

function ActivityMonitor() {
	const dataPerPage = 20;
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(1);
	const [company_role, setCompanyRole] = useState("");

	const { isLoading, activity, count } = useSelector(
		(state) => state.notification
	);

	useEffect(() => {
		if (company_role === "admin") {
			dispatch(fetchActivity(dataPerPage, currentPage));
		}
	}, [dispatch, currentPage, company_role]);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, [count, dataPerPage]);

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentPage(currentPage);
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="inner-container full mt-44px">
				<div className="payment-history-content">
					<span className="payment-history-title">
						Activity Monitor
					</span>
					<ActivityMonitorTable list={activity} />
					<div className="table-controller-container">
						<PaginationReact
							totalCount={count}
							itemsPerPage={dataPerPage}
							onPageClick={onPageChange}
							forcePage={currentPage - 1}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ActivityMonitor;

import {
    FETCH_MENU_REQUEST,
    FETCH_SERVICE_REQUEST,
    FETCH_DRINK_REQUEST,
    FETCH_MENU_SUCCESS,
    FETCH_SERVICE_TYPE_SUCCESS,
    FETCH_SERVICE_SUCCESS,
    FETCH_DRINK_SUCCESS,
    FETCH_MENU_ERROR,
    FETCH_SERVICE_TYPE_ERROR,
    FETCH_SERVICE_ERROR,
    FETCH_DRINK_ERROR,
    FETCH_MENU_SEGREGATED_SEARCH_SUCCESS,
} from "../action-types";

const initial_state = {
    isLoadingMenu: false,
    isLoadingService: false,
    isLoadingDrink: false,
    menus: [],
    serviceTypes: [],
    services: [],
    drinks: [],
    error: "",
};

export default function employeeSettingReducer(state = initial_state, action) {
    switch (action.type) {
        case FETCH_MENU_REQUEST:
            return {
                ...state,
                isLoadingMenu: true,
                error: "",
            };

        case FETCH_MENU_SUCCESS:
            return {
                isLoadingMenu: false,
                menus: action.payload,
                serviceTypes: state.serviceTypes,
                services: state.services,
                drinks: state.drinks,
                error: "",
            };
        case FETCH_MENU_SEGREGATED_SEARCH_SUCCESS:
            let dessert = [];
            let meals = [];
            let salad = [];
            let snacks = [];
            let soup = [];

            action.payload.map((menu) => {
                if (menu.menu_category === "dessert") {
                    dessert.push(menu);
                } else if (menu.menu_category === "meals") {
                    meals.push(menu);
                } else if (menu.menu_category === "salad") {
                    salad.push(menu);
                } else if (menu.menu_category === "snacks") {
                    snacks.push(menu);
                } else if (menu.menu_category === "soup") {
                    soup.push(menu);
                }
                return null;
            });

            let searchedMenuList = {
                snacks: snacks,
                meals: meals,
                dessert: dessert,
                salad: salad,
                soup: soup,
            };
            return {
                isLoadingMenu: false,
                menus: searchedMenuList,
                serviceTypes: {},
                services: state.services,
                drinks: state.drinks,
                error: "",
            };
        case FETCH_MENU_ERROR:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: state.serviceTypes,
                services: state.services,
                drinks: state.drinks,
                error: action.payload,
            };

        case FETCH_SERVICE_REQUEST:
            return {
                ...state,
                isLoadingService: true,
                error: "",
            };
        case FETCH_SERVICE_TYPE_SUCCESS:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: action.payload,
                services: state.services,
                drinks: state.drinks,
                error: "",
            };

        case FETCH_SERVICE_SUCCESS:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: state.serviceTypes,
                services: action.payload,
                drinks: state.drinks,
                error: "",
            };

        case FETCH_SERVICE_TYPE_ERROR:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: state.serviceTypes,
                services: state.services,
                drinks: state.drinks,
                error: action.payload,
            };
        case FETCH_SERVICE_ERROR:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: state.serviceTypes,
                services: state.services,
                drinks: state.drinks,
                error: action.payload,
            };

        case FETCH_DRINK_REQUEST:
            return {
                ...state,
                isLoadingDrink: true,
                error: "",
            };
        case FETCH_DRINK_SUCCESS:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: state.serviceTypes,
                services: state.services,
                drinks: action.payload,
                error: "",
            };
        case FETCH_DRINK_ERROR:
            return {
                isLoadingMenu: false,
                menus: state.menus,
                serviceTypes: state.serviceTypes,
                services: state.services,
                drinks: state.drinks,
                error: action.payload,
            };

        default:
            return state;
    }
}

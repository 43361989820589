import React, { useState } from "react";
import Modal from "react-modal";
import TextInputWithIconComponent from "../common/text-input/text-input-with-icon.component";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
};
export default function DeleteConfirmation({
	isVisible,
	handleClose,
	handleShow,
	strings,
	confirmButton,
}) {
	const [error, setError] = useState("");
	const [value, setValue] = useState("");

	const onConfirmClick = () => {
		if (value === "yes") {
			setError("");
			confirmButton();
		} else {
			setError("Error! keyword does not match");
		}
	};

	return (
		<Modal
		ariaHideApp={false}
			isOpen={isVisible}
			onAfterOpen={handleShow}
			onRequestClose={handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="main-modal-wrapper deactivate-company bg-default">
				<div className="modal-title">{strings.title}</div>
				<div className="modal-message">
					Confirm Delete Request: Type "Yes" below
				</div>
				<div className="-mt-7px flex justify-center">
					<div className=" w-119">
						<TextInputWithIconComponent
							variant="form-md edit"
							onChange={(e) => setValue(e.target.value)}
							value={value}
							error={error}
						/>
					</div>
				</div>

				<div className="modal-message">{strings.message}</div>
				<div className="modal-two-button-wrapper">
					<button className="button-primary" onClick={handleClose}>
						Cancel
					</button>
					<button
						className="colored-button-wrapper bg-buttonFailed text-fontWhite"
						onClick={onConfirmClick}
					>
						Delete
					</button>
				</div>
			</div>
		</Modal>
	);
}

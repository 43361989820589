import showToastMessage from "../../global/showToastMessage";
import {
	FETCH_ORDER_REQUEST,
	FETCH_ORDER_SUCCESS,
	FETCH_ORDER_ERROR,
	FETCH_SINGLE_ORDER_SUCCESS,
	EDIT_ORDER,
} from "../action-types";

const initial_state = {
	isLoading: false,
	orderList: [],
	orderedItem: {},
	error: "",
};
export default function orderReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_ORDER_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_ORDER_SUCCESS:
			return {
				...state,
				isLoading: false,
				orderList: action.payload,
			};

		case FETCH_SINGLE_ORDER_SUCCESS:
			let list = action.payload.order_list;
			Object.keys(list).map((cat) => {
				list[cat].map((list) => {
					list.old_order_qty = list.ordered_quantity;
					list.order_change_quantity = 0;
					list.ordered_quantity = list.ordered_quantity;
					list.new_ordered_quantity = 0;
					return null;
				});
				return null;
			});

			return {
				...state,
				isLoading: false,
				orderedItem: { ...action.payload, order_list: list },
			};

		case FETCH_ORDER_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		case EDIT_ORDER:
			let orderItem = state.orderedItem.order_list;
			let index = action.payload.index;
			let value = action.payload.value;
			let key = action.payload.key;
			let field = action.payload.field;
			let selectedState = orderItem[key][index];
			let newState = {};

			if (field === "qty") {
				if (value < 0 || isNaN(value) || value === "") {
					newState = {
						...selectedState,
						qty_error: "Invalid",
					};
				} else {
					newState = {
						...selectedState,
						new_ordered_quantity: parseInt(value),
						ordered_quantity: parseInt(value),
						order_change_quantity:
							value - selectedState.old_order_qty,
						qty_error: "",
					};
				}
			}
			if (field === "rate") {
				if (value < 0 || isNaN(value) || value === "") {
					newState = {
						...selectedState,
						rate_error: "Invalid",
					};
				} else {
					newState = {
						...selectedState,
						ordered_price: parseInt(value),
						rate_error: "",
					};
				}
			}
			if (field === "status") {
				if (selectedState.ordered_price <= 0) {
					showToastMessage("Prices has not been set");
					newState = {
						...selectedState,
					};
				} else {
					newState = {
						...selectedState,
						item_status: value,
					};
				}
			}

			orderItem[key].splice(index, 1, newState);

			return {
				...state,
				order_list: { ...state.orderedItem, orderItem },
			};

		default:
			return state;
	}
}

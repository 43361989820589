import React from "react";
import "./menu-svg.scss";

export default function OtherSeaFood({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`icon-size ${style}`}
			viewBox="0 0 14.36 18.03"
			id="other-sea"
		>
			<g>
				<path d="M9.33,17.69a6.9,6.9,0,0,1-2,.3H5.84a3.94,3.94,0,0,1-.08-.77,3.66,3.66,0,0,1,.11-.88h.38a3.77,3.77,0,0,0,1.91-.52,3.85,3.85,0,0,0,.39.92A3.45,3.45,0,0,0,9.33,17.69Z" />
				<path d="M9.38,6.27a3.8,3.8,0,0,1-1.24,2.2,3.9,3.9,0,0,0-1.33-.55,4.22,4.22,0,0,0-.61-.07H6A3.82,3.82,0,0,1,2.94,4.12c0-.14,0-.28,0-.41h4.2A6.74,6.74,0,0,1,9.07,4,3.76,3.76,0,0,1,9.38,6.27Z" />
				<path d="M14.36,10.19h0a3.86,3.86,0,0,1-2.12,1.92,3.9,3.9,0,0,1-1.29.23,4,4,0,0,1-.6-.05,3.44,3.44,0,0,0,0-.46A3.82,3.82,0,0,0,8.65,8.65a3.79,3.79,0,0,0,.93-4.48,6.71,6.71,0,0,1,4.3,3.92c.08.18.14.37.2.55A7.19,7.19,0,0,1,14.36,10.19Z" />
				<path d="M14.35,11.14a6.67,6.67,0,0,1-2,4.73,6.76,6.76,0,0,1-2.75,1.67,4,4,0,0,1-.78-.94,4.14,4.14,0,0,1-.38-.93,3.74,3.74,0,0,0,.77-.59,3.83,3.83,0,0,0,1-1.81,3,3,0,0,0,.08-.43,4,4,0,0,0,.6.05,3.62,3.62,0,0,0,1.29-.23,3.81,3.81,0,0,0,2.12-1.91C14.35,10.88,14.35,11,14.35,11.14Z" />
				<path d="M5.56,16.38a3.65,3.65,0,0,0-.1.88,3.87,3.87,0,0,0,.07.77A1.63,1.63,0,0,1,5.17,18c-1.53-.39-3.36.24-3.58-.49a6.63,6.63,0,0,1,2-.74,3.17,3.17,0,0,1-2.08-1.06c1.24-.36,2.12.27,3.74.55.21,0,.19,0,.34.07Z" />
				<path
					className="cls-2"
					d="M3.23,4C-.65,4.21-1,.69,3.11,1.11c3.4.34,5.38.7,5.69-1.08"
				/>
			</g>
		</svg>
	);
}

import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { recordVatDetails } from "../../redux/actions/payment-action";
import { getSingleBookedEvent } from "../../redux/actions/booking-action";
import { fetchBookingPayment } from "../../redux/actions/payment-action";
import strings from "../../global/strings";
import TextInputWithErrorComponent from "../common/text-input/text-input-with-error.component";
import FormTextareaInputComponent from "../common/text-input/form-textarea-input.component";
import NepaliCalendarPopUp from "../common/calendar/nepali-cal-popup";

import moment from "moment";
import NibjarNepaliDate from "../../global/nibjar-nepali-date/nibjar-nepali-converter";

const vatSchema = yup.object().shape({
	a_pax: yup
		.number()
		.required("Pax is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	vat_amount: yup
		.number()
		.required("Vat Amount is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	total_bill: yup
		.number()
		.required("Total bill is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	issue_date: yup.string().required("Issue Date is required"),
	vat_bill_number: yup
		.number()
		.required("VAT Bill Number is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
});

export default function AddVatModal(props) {
	const dispatch = useDispatch();
	const { register, handleSubmit, setValue, errors } = useForm({
		resolver: yupResolver(vatSchema),
	});
	const [vatDetails, setVatDetails] = useState({});
	const [nepaliCalDisplay, setNepaliCalDisplay] = useState(false);
	const [nepaliDate, setNepaliDate] = useState("Select Date");

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
		h2: {
			color: "red",
		},
	};

	useEffect(() => {
		let vatAmount = parseFloat(
			(props.data.a_pax * vatDetails.a_rate * 0.13).toFixed(2)
		);

		setVatDetails({
			bookings: [
				{
					booking_info_id: props.data.booking_info_id,
					e_rate: props.data.e_rate,
					e_pax: props.data.e_pax,
				},
			],
			event_info_id: props.data.event_info_id,
			venue_info_id: props.data.venue_info_id,
			a_pax: props.data.a_pax,
			a_rate: props.data.a_rate,
			vat_amount: vatAmount,
			total_bill: props.data.a_pax * props.data.a_rate + vatAmount,
			total_cost: props.data.a_pax * props.data.a_rate,
		});
	}, [props.data, props.handleClose]);

	const adjustVat = () => {
		let a_pax,
			a_rate,
			vat_amount,
			paxForVatAmount,
			tempDecimal,
			total_cost,
			total_bill;

		switch (vatDetails.flag) {
			case "vat_amount":
				paxForVatAmount =
					vatDetails.vat_amount / (vatDetails.a_rate * 0.13);
				tempDecimal = paxForVatAmount % 1;

				if (tempDecimal === 0) {
					a_pax = paxForVatAmount;
					a_rate = vatDetails.a_rate;
				} else {
					a_pax = vatDetails.a_pax;
					a_rate = parseFloat(
						(vatDetails.vat_amount / (a_pax * 0.13)).toFixed(2)
					);
				}

				vat_amount = parseFloat((a_pax * a_rate * 0.13).toFixed(2));

				setVatDetails({
					...vatDetails,
					a_rate,
					a_pax,
					vat_amount,
					total_bill: a_pax * a_rate + vat_amount,
					total_cost: a_pax * a_rate,
				});
				break;
			case "total_bill":
				total_cost = parseFloat(
					(vatDetails.total_bill / 1.13).toFixed(2)
				);
				vat_amount = total_cost * 0.13;
				paxForVatAmount = vat_amount / (vatDetails.a_rate * 0.13);
				tempDecimal = paxForVatAmount % 1;

				if (tempDecimal === 0) {
					a_pax = paxForVatAmount;
					a_rate = vatDetails.a_rate;
				} else {
					a_pax = vatDetails.a_pax;
					a_rate = parseFloat((total_cost / a_pax).toFixed(2));
				}

				vat_amount = parseFloat((a_pax * a_rate * 0.13).toFixed(2));
				total_cost = parseFloat(total_cost.toFixed(0));
				total_bill = total_cost + vat_amount;

				setVatDetails({
					...vatDetails,
					a_rate,
					a_pax,
					vat_amount,
					total_bill,
					total_cost,
				});
				break;
			case "a_pax":
				setVatDetails({
					...vatDetails,
					a_rate: vatDetails.a_rate,
					a_pax: vatDetails.a_pax,
					vat_amount: parseFloat(
						(vatDetails.a_pax * vatDetails.a_rate * 0.13).toFixed(2)
					),
					total_bill:
						vatDetails.a_pax * vatDetails.a_rate +
						parseFloat(
							(
								vatDetails.a_pax *
								vatDetails.a_rate *
								0.13
							).toFixed(2)
						),
					total_cost: vatDetails.a_pax * vatDetails.a_rate,
				});
				break;
			default:
				break;
		}
	};

	const _handleVatSubmit = async (data) => {
		let vatInfo = {
			...data,
			...vatDetails,
			a_pax: +data.a_pax,
			a_rate: +data.a_rate,
			booking_vat_status: "c_vat",
		};

		await dispatch(recordVatDetails(vatInfo));
		await dispatch(getSingleBookedEvent(props.data.booking_info_id));
		await dispatch(
			fetchBookingPayment(props.data.booking_info_id, "booking")
		);
		props.handleClose();
	};

	const dateADtoBS = (vatDateEng) => {
		let decreasedMonth = moment(vatDateEng)
			.subtract(1, "month")
			.format("YYYY-MM-DD");
		let a = decreasedMonth.split("-");
		let b = new NibjarNepaliDate(new Date(a[0], a[1], a[2])).format(
			"YYYY-MM-DD"
		);

		return b;
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Add Booking VAT</div>
				</div>
				<div className="form-body hide-scroll">
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								name="a_pax"
								type="number"
								variant="form-md edit "
								placeholder="Actual Pax"
								label="Actual Pax"
								value={vatDetails.a_pax}
								error={errors?.a_pax?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										flag: "a_pax",
										a_pax: e.target.value,
									})
								}
							/>
							<TextInputWithErrorComponent
								name="a_rate"
								type="number"
								variant="form-md edit "
								placeholder="Actual Rate"
								label="Actual Rate"
								value={vatDetails.a_rate}
								error={errors?.a_rate?.message}
								ref={register}
								// disabled={true}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										a_rate: e.target.value,
									})
								}
							/>
						</div>
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								name="vat_amount"
								type="number"
								variant="form-md edit "
								placeholder="VAT Amount"
								label="VAT Amount"
								value={vatDetails.vat_amount}
								error={errors?.vat_amount?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										flag: "vat_amount",
										vat_amount: e.target.value,
									})
								}
							/>
							<TextInputWithErrorComponent
								name="total_bill"
								type="number"
								variant="form-md edit "
								placeholder="Total Bill"
								label="Total Bill"
								value={vatDetails.total_bill}
								error={errors?.total_bill?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										flag: "total_bill",
										total_bill: e.target.value,
									})
								}
							/>
						</div>
					</div>

					<div className="form-input-container ">
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant="form-md edit "
								placeholder="Total Cost"
								label="Total Cost"
								value={vatDetails.total_cost}
								error={errors?.total_cost?.message}
								disabled={true}
							/>
							<button
								onClick={() => {
									adjustVat();
								}}
								className="button-primary vat-rec-button"
								disabled={
									vatDetails.a_pax * vatDetails.a_rate <= 0
								}
							>
								Re-calculate VAT
							</button>
						</div>
						<div className="form-input-container inner">
							<FormTextareaInputComponent
								label="VAT RECORD GUIDE"
								variant="form-notes-sm"
								value={strings.vatAdjustmentGuide}
								disabled={true}
							/>
						</div>
					</div>
					<hr />
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant="form-md edit "
								placeholder="VAT Issue Date"
								label="Date of Issue"
								name="issue_date"
								ref={register}
								error={errors?.issue_date?.message}
								type="date"
								onBlur={(e) => {
									if (e.target.value) {
										setNepaliDate(
											dateADtoBS(
												moment(e.target.value).format(
													"YYYY-MM-DD"
												)
											)
										);
									}
								}}
							/>
							<div
								onClick={() => {
									setNepaliCalDisplay(!nepaliCalDisplay);
								}}
								className="button-primary nep-button-tigger"
							>
								<p>Nep Date</p>
								<p>{nepaliDate}</p>
							</div>
						</div>
						<div className="form-input-container inner">
							<TextInputWithErrorComponent
								variant="form-md edit "
								placeholder="VAT Bill Number"
								label="VAT Bill Number"
								name="vat_bill_number"
								ref={register}
								error={errors?.vat_bill_number?.message}
								type="number"
							/>
							<TextInputWithErrorComponent
								variant="form-md edit "
								label="Client VAT/PAN"
								placeholder="Client VAT/PAN"
								name="customer_vat_number"
								ref={register}
								type="text"
							/>
						</div>
					</div>
					<div className="form-input-container ">
						{nepaliCalDisplay && (
							<NepaliCalendarPopUp
								display={true}
								type="both"
								dateSubmitFunction={(date) => {
									setValue("issue_date", date.EngDate);
									setNepaliDate(date.NepDate);
									setNepaliCalDisplay(false);
								}}
							/>
						)}
					</div>
				</div>
				<div className="form-footer two">
					<button
						onClick={() => {
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						className="button-primary"
						onClick={handleSubmit(_handleVatSubmit)}
					>
						Add VAT
					</button>
				</div>
			</div>
		</Modal>
	);
}

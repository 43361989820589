import React, { useEffect, useState } from "react";
import "./extra-page.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";

import { fetchCompanyCaterSubscription } from "../../redux/actions/caterme-subscritpion.action";

export const SubscriptionPage = () => {
	const currentDate = moment().format("YYYY-MM-DD");
	const history = useHistory();
	const dispatch = useDispatch();

	const { latestSubscription, subscriptions } = useSelector(
		(state) => state.caterSubscriptions
	);

	useEffect(() => {
		async function callfunction() {
			await dispatch(fetchCompanyCaterSubscription());
		}

		callfunction();
	}, []);

	return (
		<div className="main-container">
			<div className="inner-container full">
				<div className="page-wrapper">
					<div className="bg-default extra-page-container">
						<h2 className="extra-page-title">
							CaterMe Subscription
						</h2>
						<span className="extra-page-sub-title">
							{latestSubscription?.company_info?.company_name} is
							subscribed to CaterMe{" "}
							{latestSubscription?.subscription_type} for{" "}
							{latestSubscription?.subscription_duration} months
						</span>
						<h4 className="extra-page-message">
							Subscription Expires:{" "}
							{latestSubscription?.expiration_date}
							<p>
								{moment(
									latestSubscription?.expiration_date
								).diff(currentDate, "days")}{" "}
								Days Remaining
							</p>
						</h4>

						<span className="extra-page-button-container">
							<button
								className="button-wrapper page-notfound-back-button"
								onClick={() => history.goBack()}
							>
								Back
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionPage;

import React, { useState, useEffect } from "react";
import TextInput from "../../common/text-input/text-input-with-error.component";
import strings from "../../../global/strings";

import { updateVenueDetails } from "../../../redux/actions/company-setting.action";
import { useDispatch } from "react-redux";
// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmptyObject, sessionInfo } from "../../../global/function";
import showToastMessage from "../../../global/showToastMessage";
// import { isEmptyObject } from "../../../../global/function";
import AddImageModal from "../../../components/modals/add-image.modal";
import { uploadImage } from "../../../redux/actions/image-upload.action";
import TextInputWithErrorComponent from "../../common/text-input/text-input-with-error.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import TextInputWithIconComponent from "../../common/text-input/text-input-with-icon.component";
import VenueColorSelector from "../../common/checkbox-input/venue-color/venue-color.selector";

const venueSchema = yup.object().shape({
	venue_name: yup.string().required("required"),
	venue_address: yup.string().required("required"),
	venue_phone: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	venue_email: yup.string().email("Please enter a valid email"),
});

export default function VenueDetails({
	venue,
	changeHallStatus,
	showAddHallConfirmation,
}) {
	const { register, handleSubmit, errors, watch, reset } = useForm({
		defaultValues: {
			venue_name: venue.venue_name,
			venue_email: venue.venue_email,
			venue_facebook: venue.venue_facebook,
			venue_address: venue.venue_address,
			venue_pan: venue.venue_pan,
			venue_vat: venue.venue_vat,
			venue_phone: venue.venue_phone,
			// venue_color_code: venue.venue_color_code,
		},
		mode: "onSubmit",
		resolver: yupResolver(venueSchema),
	});
	watch("venue_facebook", null);

	const [activeHallNum, setActiveHallNum] = useState(1);
	const [showAddImageModal, setShowAddImageModal] = useState(false);
	const [venueEditFlag, setVenueEditFlag] = useState(false);
	const [venueColor, setVenueColor] = useState("");

	const [role, setCompanyRole] = useState("");
	const [company_info_id, setCompId] = useState();

	const [toggleVenueColor, setToggleVenueColor] = useState(false); // to toggle venue color option

	const dispatch = useDispatch();

	useEffect(() => {
		let num = 0;
		reset(venue);

		if (!isEmptyObject(venue)) {
			venue.hall_infos.map((hall) => {
				if (hall.hall_status === "active") {
					num++;
				}
				setActiveHallNum(num);
				return null;
			});
		}

		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
				setCompId(data.company_info_id);
			});
		}

		fetchSessionData();
	}, [venue, reset]);

	useEffect(() => {
		let venue_color = watch("venue_color_code", venue.venue_color_code);
		setVenueColor(venue_color);
	}, [venue, watch]);

	const updateVenueDetail = (venueDetails) => {
		venueDetails["company_info_id"] = company_info_id;
		venueDetails["venue_info_id"] = venue.venue_info_id;
		if (venueDetails.venue_facebook === "") {
			venueDetails["venue_facebook"] = null;
		}
		if (venueDetails.venue_pan === "") {
			venueDetails["venue_pan"] = null;
		}
		if (venueDetails.venue_vat === "") {
			venueDetails["venue_vat"] = null;
		}
		if (venueDetails.venue_email === "") {
			venueDetails["venue_email"] = null;
		}
		if (venueDetails.venue_mobile === "") {
			venueDetails["venue_mobile"] = null;
		}
		dispatch(updateVenueDetails(venueDetails));
		setVenueEditFlag(false);
		setToggleVenueColor(false);
	};

	const onCancelClick = () => {
		setVenueEditFlag(false);
		setToggleVenueColor(false);
	};

	const updateHallStatus = (halls, e) => {
		if (activeHallNum === 1 && e.target.value === "inactive") {
			showToastMessage("You cannot disable only active Hall", "W");
		} else {
			changeHallStatus(
				venue.venue_name,
				venue.venue_info_id,
				halls.hall_name,
				halls.hall_info_id,
				e,
				venue.hall_infos.length
			);
		}
	};

	const handleImageModalClose = () => {
		setShowAddImageModal(false);
	};
	const handleImageModalShow = () => setShowAddImageModal(true);

	const onSaveImage = (image) => {
		let data = { image, id: venue.venue_info_id, type: "venue" };
		dispatch(uploadImage(data));
	};

	const toggle = (val) => {
		setToggleVenueColor(val);
		setVenueColor(watch("venue_color_code"));
	};

	return (
		<div className="inner-container mt-15px mb-15px" key={venue.venue_id}>
			<div className="main-inner-container">
				<div
					className="bg-default venue-logo-container "
					onClick={venueEditFlag ? handleImageModalShow : undefined}
				>
					{venue.venue_logo === null ? (
						<span className="venue-initial-content">
							{venue.venue_name.charAt(0)}
						</span>
					) : (
						<img src={venue.venue_logo} alt="" />
					)}
				</div>
				<VenueColorSelector
					color={venueColor}
					ref={register}
					name="venue_color_code"
					disabled={!venueEditFlag}
					variant="update-venue"
					toggleFlag={toggleVenueColor}
					toggle={toggle}
				/>

				<div className=" bg-default company-name-container mt-5px">
					{venueEditFlag ? (
						<TextInput
							variant="form-md edit mt-7px"
							ref={register}
							name="venue_name"
							placeholder="Venue Name"
							error={errors?.venue_name?.message}
						/>
					) : (
						<span className="company-info-label text-capitalize">
							{venue.venue_name}
						</span>
					)}
				</div>
			</div>
			<div className="main-inner-container ">
				<div className="inner-content rows">
					<div className="id-details-container bg-default">
						<div className="id-details-title">Venue ID</div>
						<div className="id-details-message">
							{venue.venue_id}
						</div>
					</div>
					<div className="id-details-container bg-default">
						<div className="id-details-title">Venue Status</div>
						<div className="id-details-message status text-capitalize">
							{venue.venue_status}
						</div>
					</div>
				</div>
				<div className="inner-content cols mt-30px">
					<div className="inner-content cols ">
						<TextInputWithErrorComponent
							variant={venueEditFlag ? "form-lg edit" : "form-lg"}
							label="PAN Number"
							name="venue_pan"
							ref={register}
							error={errors?.venue_pan?.message}
							type={venueEditFlag ? "number" : "password"}
							disabled={!venueEditFlag}
						/>
					</div>
					<div className="inner-content cols ">
						<TextInputWithErrorComponent
							variant={venueEditFlag ? "form-lg edit" : "form-lg"}
							label="VAT Number"
							name="venue_vat"
							ref={register}
							error={errors?.venue_vat?.message}
							type={venueEditFlag ? "number" : "password"}
							disabled={!venueEditFlag}
						/>
					</div>
				</div>
			</div>

			<div className="main-inner-container">
				<div className="inner-content rows ">
					<TextInputWithErrorComponent
						variant={venueEditFlag ? "form-md edit" : "form-md"}
						label="Venue Phone*"
						name="venue_phone"
						ref={register}
						error={errors?.venue_phone?.message}
						type="number"
						disabled={!venueEditFlag}
					/>
					<TextInputWithErrorComponent
						variant={venueEditFlag ? "form-md edit" : "form-md"}
						label="Venue Mobile"
						name="venue_mobile"
						ref={register}
						error={errors?.venue_mobile?.message}
						type="number"
						disabled={!venueEditFlag}
					/>
				</div>

				<div className="inner-content cols ">
					<TextInputWithErrorComponent
						variant={venueEditFlag ? "form-lg edit" : "form-lg"}
						label="Venue Email  "
						name="venue_email"
						ref={register}
						error={errors?.venue_email?.message}
						type="email"
						disabled={!venueEditFlag}
					/>
				</div>

				<div className="inner-content cols  mt-15px">
					<TextInputWithErrorComponent
						variant={venueEditFlag ? "form-lg edit" : "form-lg"}
						label="Street Address"
						name="venue_address"
						ref={register}
						error={errors?.venue_address?.message}
						disabled={!venueEditFlag}
					/>
				</div>

				<div className="inner-content rows mb-15">
					<FormSelectBox
						label="City*"
						variant="form-sm"
						options={strings.city}
						name="venue_city"
						ref={register}
						type="City"
						error={errors?.venue_city?.message}
						disabled={!venueEditFlag}
					/>
					<FormSelectBox
						label="Country*"
						variant="form-sm"
						options={strings.country}
						name="venue_country"
						ref={register}
						type="Country"
						error={errors?.venue_country?.message}
						disabled={!venueEditFlag}
					/>
				</div>
			</div>

			<div className="main-inner-container">
				<div className="inner-content cols">
					<span className="form-label">Social Media</span>

					<TextInputWithIconComponent
						variant={venueEditFlag ? "icon-lg edit" : "icon-lg"}
						icon="fb"
						name={venue.venue_facebook || "venue_facebook"}
						ref={register}
						error={errors?.venue_facebook?.message}
						disabled={!venueEditFlag}
					/>
					<div className="toggle-wrapper mt-15px">
						<div className="form-label">Hall Activation</div>

						{venueEditFlag ? (
							<div className="toggle-container">
								<div className="halls-wrapper">
									{venue.hall_infos.map((halls) => (
										<span
											className="halls-container"
											key={halls.hall_info_id}
										>
											<input
												id={halls.hall_info_id}
												type="checkbox"
												name={halls.hall_info_id}
												className="hall-checkbox"
												value={
													halls.hall_status ===
													"active"
														? "inactive"
														: "active"
												}
												onChange={(e) =>
													updateHallStatus(halls, e)
												}
												checked={
													halls.hall_status ===
													"active"
												}
											/>
											<label
												htmlFor={halls.hall_info_id}
												className="halls-content-label"
											>
												{halls.hall_name}
											</label>
										</span>
									))}
								</div>

								<div className="add-hall-container">
									<button
										className="add-hall-button"
										disabled={venue.hall_infos.length === 3}
										onClick={() =>
											showAddHallConfirmation(
												venue.venue_info_id,
												venue.hall_infos[
													venue.hall_infos.length - 1
												].hall_name
											)
										}
									>
										<i className="fas fa-plus"></i>
									</button>
								</div>
							</div>
						) : (
							<div className="toggle-container">
								{venue.hall_infos.map((halls) => (
									<span
										className={`static-halls-container ${halls.hall_status}`}
										key={halls.hall_info_id}
									>
										{halls.hall_name}
									</span>
								))}
							</div>
						)}
					</div>
				</div>

				{role === "admin" &&
					(venueEditFlag ? (
						<div className="inner-content cols button">
							<button
								className="button-primary"
								onClick={onCancelClick}
							>
								Cancel
							</button>
							<button
								className="button-primary"
								onClick={handleSubmit(updateVenueDetail)}
							>
								Save
							</button>
						</div>
					) : (
						<div className="inner-content cols button">
							<button
								className="button-primary"
								onClick={() => setVenueEditFlag(true)}
							>
								Edit
							</button>
						</div>
					))}
			</div>
			<AddImageModal
				isVisible={showAddImageModal}
				type="venue"
				handleClose={handleImageModalClose}
				handleShow={handleImageModalShow}
				onSaveClick={onSaveImage}
			/>
		</div>
	);
}

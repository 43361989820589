import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";
import { isEmptyObject } from "../../../global/function";

function SideModalDateFix({
	isVisible,
	onModalClose,
	containerStyle,
	modelFor = "dateFix",
	sideModalData,
	deselectorFunction,
}) {
	const [bookingByTime, setBookingByTime] = useState({});
	const bookingdates = useMemo(() => {
		if (Array.isArray(sideModalData) && sideModalData.length > 0) {
			return {
				np: sideModalData[0].booked_date_nepali,
				en: sideModalData[0].booked_date_english,
			};
		} else if (!isEmptyObject(sideModalData)) {
			return {
				np: sideModalData.booked_date_nepali,
				en: sideModalData.booked_date_english,
			};
		}
	}, [sideModalData]);

	useEffect(() => {
		if (isVisible) {
			if (Array.isArray(sideModalData) && sideModalData.length > 0) {
				let time = { morning: [], afternoon: [], evening: [] };
				sideModalData.forEach((element) => {
					element["removed"] = false;
					time[element.booked_time].push(element);
				});

				setBookingByTime({ ...time });
			} else if (!isEmptyObject(sideModalData)) {
				let time = { [sideModalData.booked_time]: [sideModalData] };
				setBookingByTime({ ...time });
			}
		}
	}, [sideModalData, isVisible]);

	function _handleToggleSelection(booking, bookTimeIndex) {
		if (Array.isArray(sideModalData) && sideModalData.length > 0) {
			let time = { ...bookingByTime };
			if (time[booking.booked_time][bookTimeIndex].removed) {
				time[booking.booked_time][bookTimeIndex].removed = false;
			} else {
				time[booking.booked_time][bookTimeIndex].removed = true;
			}
			setBookingByTime({ ...time });
		}
	}
	function _handleSaveSelection() {
		let bookArr = [
			...bookingByTime.morning,
			...bookingByTime.afternoon,
			...bookingByTime.evening,
		];
		bookArr.forEach((bookObj) => {
			if (bookObj.removed) {
				deselectorFunction(bookObj, bookObj.bookIndex);
			}
		});
		onModalClose();
	}
	return (
		<div className={`s-modal-wrapper ${isVisible ? "expanded" : ""}`}>
			<div
				className="s-modal_overlay"
				onClick={() => {
					onModalClose();
				}}
			></div>
			<div className="s-modal-container" style={containerStyle}>
				<div className="s-modal-headder">
					<div className="s-headder-title">{modelFor}</div>
				</div>
				{!isEmptyObject(bookingByTime) && (
					<div className="s-modal-body">
						<div className="booking-date-header">
							<div className="booking-date">
								{moment(bookingdates?.en).format(
									"DD MMMM, YYYY"
								)}
							</div>
							<div className="booking-date">
								{new NibjarNepaliDate(bookingdates?.np).format(
									"DD MMMM, YYYY"
								)}
							</div>
						</div>
						{Object.keys(bookingByTime).map(
							(timeVal, timeIndex) => {
								if (bookingByTime[timeVal].length > 0) {
									return (
										<div
											className="s-data-fix-wrapper"
											key={timeIndex}
										>
											<div
												className={`time-header ${timeVal}`}
											>
												{timeVal}
											</div>
											<div className="s-booking-container">
												{bookingByTime[timeVal]?.map(
													(booking, i) => (
														<div
															className={`s-booking ${
																booking.removed &&
																"strikeout"
															}`}
															onClick={() =>
																_handleToggleSelection(
																	booking,
																	i
																)
															}
														>
															<div>
																#
																{
																	booking.booking_id
																}
															</div>
															<div className="col-style">
																<div
																	style={{
																		fontWeight:
																			"bold",
																	}}
																>
																	{
																		booking.customer_fname
																	}{" "}
																	{
																		booking.customer_lname
																	}
																</div>
															</div>
															<div className="col-style">
																<div>
																	{
																		booking.customer_mobile
																	}
																</div>
																<div>
																	{
																		booking.customer_phone
																	}
																</div>
															</div>
															<div>
																{
																	booking.customer_email
																}
															</div>
															<hr />
															<div className="col-style">
																<div className="s-label">
																	Booking
																	Status:
																</div>

																{
																	booking.booking_status
																}
															</div>
															<div className="col-style">
																<div className="s-label">
																	Payment
																	Status:
																</div>
																{booking.payment_status ===
																"exists"
																	? "TRUE"
																	: "NONE"}
															</div>
															<div className="col-style">
																<div className="s-label">
																	VAT Status:
																</div>
																{booking.vat_status ===
																"none"
																	? "NONE"
																	: "TRUE"}
															</div>
															<hr />
															<div className="col-style">
																<div className="s-label">
																	Halls:
																</div>
																{booking?.booking_halls?.map(
																	(
																		hall,
																		i
																	) => (
																		<div
																			key={
																				i
																			}
																		>
																			{
																				hall.hall_name
																			}
																		</div>
																	)
																)}
															</div>
															<div className="col-style">
																<div className="s-label">
																	Details:
																</div>
																<div>
																	{
																		booking?.e_pax
																	}{" "}
																	pax
																</div>
																@
																<div>
																	Rs.
																	{
																		booking?.e_rate
																	}
																</div>
															</div>
															<div>
																<div className="s-label">
																	Booking
																	Notes:
																</div>
																{booking?.booking_notes
																	? booking?.booking_notes
																	: "-"}
															</div>
														</div>
													)
												)}
											</div>
										</div>
									);
								} else {
									return <></>;
								}
							}
						)}
						{Array.isArray(sideModalData) &&
							sideModalData.length > 0 && (
								<div className="s-booking-button-wrapper">
									<div
										className="s-booking-button"
										onClick={_handleSaveSelection}
									>
										SAVE CHANGES
									</div>
								</div>
							)}
					</div>
				)}
			</div>
		</div>
	);
}

export default SideModalDateFix;

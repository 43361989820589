/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputText from "../../common/text-input/form-text-input.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import { sessionInfo } from "../../../global/function";
import strings from "../../../global/strings";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerBasicUpdate } from "../../../redux/actions/add-customer.action";

const customerSchema = yup.object().shape({
	customer_fname: yup
		.string()
		.required("Required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	customer_lname: yup
		.string()
		.when("customer_type", {
			is: (value) => value === "individual",
			then: yup.string().required("Required"),
		})
		.matches(/^([^0-9]*)$/, "No numbers allowed"),

	customer_mobile: yup
		.number()
		.typeError("must be number")
		.required("mobile number is required")
		.positive("Must be greater than zero"),
	customer_email: yup.string().email("Please enter a valid email"),
	// .required("required"),

	customer_address: yup.string().required("address required"),
	customer_type: yup.string().required("customer type required"),
	customer_city: yup.string().required("address required"),
	customer_country: yup.string().required("address required"),
	customer_gender: yup.string().when("customer_type", {
		is: (value) => value === "individual",
		then: yup.string().required("Gender Required"),
	}),
});

export default function Basic(props) {
	const dispatch = useDispatch();
	const [company_info_id, setCompanyInfoId] = useState();

	const { basic, advance } = useSelector((state) => state.addCustomer);

	const { register, handleSubmit, errors, reset, watch } = useForm({
		defaultValues: {
			company_info_id: company_info_id,
			customer_country: "Nepal",
		},
		mode: "onSubmit",
		resolver: yupResolver(customerSchema),
	});
	let customer_type = watch("customer_type");

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyInfoId(data.company_info_id);
			});
		}

		fetchSessionData();
		reset(basic);
	}, [props, reset]);

	const returnCustomer = async (customerInfo) => {
		if (customerInfo.customer_type === "business") {
			customerInfo["customer_gender"] = "other";
		}
		if (!customerInfo.customer_email) {
			// Replace all non-alphanumeric characters (including spaces) with an empty string
			let initials =
				customerInfo.customer_fname + customerInfo.customer_mobile;
			let cleaned = initials.replace(/[^a-zA-Z0-9]/g, "");

			// Convert the string to lowercase

			let tempMail = cleaned.toLowerCase();

			customerInfo["customer_email"] = `${tempMail}@nibjar.com`;
		}
		let customerData = { ...customerInfo, ...advance };

		dispatch(addCustomerBasicUpdate(customerInfo));
		await props.onClickSave(customerData);
	};

	const advanceProcess = (customerInfo) => {
		if (customerInfo.customer_type === "business") {
			customerInfo["customer_gender"] = "other";
		}
		if (!customerInfo.customer_email) {
			// Replace all non-alphanumeric characters (including spaces) with an empty string
			let initials =
				customerInfo.customer_fname + customerInfo.customer_mobile;
			let cleaned = initials.replace(/[^a-zA-Z0-9]/g, "");

			// Convert the string to lowercase

			let tempMail = cleaned.toLowerCase();

			customerInfo["customer_email"] = `${tempMail}@nibjar.com`;
		}
		props.setProcessStep(2);

		dispatch(addCustomerBasicUpdate(customerInfo));
	};

	function formStepOne() {
		return (
			<div>
				<div className="form-body">
					<div
						className="form-input-container "
						style={{ display: "none" }}
					>
						<FormInputText name="company_info_id" ref={register} />
					</div>
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<FormSelectBox
								label="Customer Type*"
								variant="form-sm"
								options={strings.customerType}
								name="customer_type"
								ref={register}
								type="Customer Type"
								error={errors?.customer_type?.message}
							/>
							<FormSelectBox
								label="Gender"
								variant="form-sm"
								options={strings.gender}
								name="customer_gender"
								ref={register}
								type="Gender"
								error={errors?.customer_gender?.message}
								disabled={customer_type === "business"}
							/>
						</div>
					</div>
					<div className="form-input-container">
						<FormInputText
							label={
								customer_type === "business"
									? "Company Name*"
									: "First Name*"
							}
							type="text"
							placeholder={
								customer_type === "business"
									? "Enter Company Name"
									: "Enter First Name"
							}
							variant="form-lg"
							name="customer_fname"
							ref={register}
							error={errors?.customer_fname?.message}
						/>

						<FormInputText
							placeholder={
								customer_type === "business"
									? ""
									: "Enter Last Name"
							}
							label={
								customer_type === "business" ? "" : "Last Name*"
							}
							variant="form-lg"
							type="text"
							name="customer_lname"
							ref={register}
							disabled={customer_type === "business"}
							error={errors?.customer_lname?.message}
						/>
					</div>
					<div className="form-input-container">
						<FormInputText
							placeholder="Enter Mobile Number"
							label="Mobile No.*"
							variant="form-lg"
							type="number"
							name="customer_mobile"
							ref={register}
							error={errors?.customer_mobile?.message}
						/>
						<FormInputText
							placeholder="Email"
							label="Email"
							variant="form-lg"
							type="email"
							name="customer_email"
							ref={register}
							error={errors?.customer_email?.message}
						/>
					</div>

					<div className="form-input-container">
						<FormInputText
							label="Address*"
							placeholder="Address Line"
							variant="form-lg"
							type="text"
							name="customer_address"
							ref={register}
							error={errors?.customer_address?.message}
						/>
						<div className="form-input-container inner">
							<FormSelectBox
								label="City*"
								variant="form-sm"
								options={strings.city}
								name="customer_city"
								ref={register}
								type="City"
								error={errors?.customer_city?.message}
							/>
							<FormSelectBox
								label="Country*"
								variant="form-sm"
								options={strings.country}
								name="customer_country"
								ref={register}
								type="Country"
								error={errors?.customer_country?.message}
							/>
						</div>
					</div>
				</div>
				<div className="form-footer two">
					<button
						onClick={() => {
							reset({});
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<div className="three-button">
						<button
							onClick={handleSubmit(advanceProcess)}
							className="button-primary"
						>
							More Details
						</button>
						<button
							className="button-primary"
							onClick={handleSubmit(returnCustomer)}
						>
							{props.actionType}
						</button>
					</div>
				</div>
			</div>
		);
	}

	return formStepOne();
}

import React, { useState, useEffect } from "react";
import {
	getCompanyData,
	getUserData,
	updateInfo,
} from "../../redux/actions/complete-registration.action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import strings from "../../global/strings";

export default function SkipVenueComponent({
	setProcessStep,
	// setVenueOptions,
}) {
	const dispatch = useDispatch();
	const [venueNumber, setVenueNumber] = useState(0);
	const [userInfo, setUserInfo] = useState();
	const history = useHistory();

	useEffect(() => {
		const _getCompanyData = async () => {
			let companyData = await dispatch(getCompanyData());

			setVenueNumber(companyData.venue_infos.length);
		};
		const _getUserData = async () => {
			let user = await dispatch(getUserData());
			setUserInfo(user);
		};

		_getUserData();
		_getCompanyData();
	}, [dispatch]);

	const _toVenueEdit = () => {
		setProcessStep(4);
		// setVenueOptions(true);
	};
	const _toDashboad = async () => {
		let settingsData = {
			email: userInfo.user_email,
			phone: userInfo.user_mobile,
			win_loss_id: userInfo.wid,
		};
		let updateStatus = await dispatch(
			updateInfo(strings.SETTINGS_UPDATE_URL, settingsData)
		);
		if (updateStatus === true) {
			history.push({
				pathname: "/dashboard",
			});
		}
	};

	return (
		<div className="form-main-content bg-default">
			<div className="form-notice-title">WE ARE ALMOST DONE</div>
			<div className="form-notice-sub-title">
				Your company has {venueNumber} venues
			</div>
			<div className="form-notice-message">
				Would you like to set up your venues?
			</div>

			<div className="form-notice-controller two">
				<button className="button-primary" onClick={_toDashboad}>
					Skip
				</button>
				<button className="button-primary" onClick={_toVenueEdit}>
					Let's Go
				</button>
			</div>
		</div>
	);
}

import {
	FETCH_VENDOR_REQUEST,
	FETCH_VENDOR_SUCCESS,
	FETCH_VENDOR_ERROR,
	SET_VENDOR_ON_EDIT,
	CLEAR_VENDOR_ON_EDIT,
} from "../action-types";

const initial_state = {
	isLoading: false,
	vendor: [],
	vendorDropDown: [],
	vendorToEdit: {},
	error: "",
};
export default function vendorReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_VENDOR_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_VENDOR_SUCCESS:
			let vendorDropdown = [];

			action.payload.vendors.map((v) => {
				vendorDropdown.push({
					id: v?.vendor,
					value: v?.vendor_name,
				});
				return 0;
			});

			return {
				...state,
				isLoading: false,
				vendor: action.payload.vendors,
				vendorDropDown: vendorDropdown,
			};

		case SET_VENDOR_ON_EDIT:
			return {
				...state,
				isLoading: false,
				vendorToEdit: action.payload,
			};
		case CLEAR_VENDOR_ON_EDIT:
			return {
				...state,
				isLoading: false,
				vendorToEdit: {},
			};

		case FETCH_VENDOR_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}

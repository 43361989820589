import React, { useState, useEffect } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
	showSupportModal,
	hideSupportModal,
	submitIssue,
} from "../../redux/actions/support-action";
// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import SelectBox from "../common/select-box/select-box.component";
import CheckBox from "../common/checkbox-container/checkbox-container.component";
import { sessionInfo } from "../../global/function";
// import FormInputText from "../../common/text-input/form-text-input.component";
import FormInputTextArea from "../common/text-input/form-textarea-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const supportSchema = yup.object().shape({
	support_page: yup.string().required("Support Page required"),
	support_type: yup.string().required("Support Type required"),
	grant_access: yup.string().required("Grant Access or Not"),
	support_while: yup.string().required("Support While required"),
	support_message: yup.string().required("Support Message required"),
});

const ContactSupportModal = (props) => {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(supportSchema),
	});
	const dispatch = useDispatch();
	const { modalStatus } = useSelector((state) => state.support);
	const [callOption, setCallOption] = useState(false);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};

	useEffect(() => {
		dispatch(hideSupportModal());
	}, [dispatch, props]);

	const onChangeCallOption = (e) => {
		setCallOption(e.target.checked);
	};

	async function _returnsupportInfo(supportData) {
		let sessionData = await sessionInfo();

		supportData["user_info_id"] = sessionData.user_info_id;
		supportData["company_info_id"] = sessionData.company_info_id;
		supportData["support_call"] = callOption;
		supportData["support_status"] = "open";
		supportData["grant_access"] =
			supportData.grant_access === "true" ? 1 : 0;

		dispatch(submitIssue(supportData));
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={modalStatus}
			onAfterOpen={() => dispatch(showSupportModal())}
			onRequestClose={() => dispatch(hideSupportModal())}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(_returnsupportInfo)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">
							{strings.supportModalTitle}
						</div>
					</div>
					<div className="form-body">
						<div className="form-input-container ">
							<div className="form-input-container inner">
								<FormSelectBox
									options={strings.supportPage}
									name="support_page"
									variant="form-sm"
									ref={register}
									label="Issue Page"
									error={errors?.support_page?.message}
								/>
								<FormSelectBox
									options={strings.supportType}
									name="support_type"
									variant="form-sm"
									ref={register}
									label="Support Type"
									error={errors?.support_type?.message}
								/>
							</div>
							<div className="form-input-container inner">
								<FormSelectBox
									options={strings.overrideType}
									name="grant_access"
									variant="form-sm"
									ref={register}
									label="Grant Access"
									error={errors?.grant_access?.message}
								/>
							</div>
						</div>

						<div className="form-input-container ">
							<FormInputTextArea
								label="Issue"
								placeholder="Please specify when the issue was encountered…."
								variant="form-notes"
								name="support_while"
								ref={register}
								error={errors?.support_while?.message}
							/>

							<FormInputTextArea
								label="Issue Description"
								placeholder="Please give a description of the error message that was generated…."
								variant="form-notes"
								name="support_message"
								ref={register}
								error={errors?.support_message?.message}
							/>
						</div>

						<div className="form-input-container">
							<CheckBox
								label="Expect Call From Us?"
								variant="checkbox-input"
								name="support_call"
								onChange={onChangeCallOption}
								checked={callOption}
							/>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => {
								reset({});
								dispatch(hideSupportModal());
							}}
						>
							Close
						</button>
						<button type="submit" className="button-primary">
							Submit
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ContactSupportModal;

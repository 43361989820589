/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormSelectInputComponent from "../../components/common/select-box/form-select-input.component";
import FormTextInput from "../../components/common/text-input/text-input-with-error.component";
import Loader from "../../components/loader/loader.component";
import { isEmptyObject, sessionInfo } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";
import {
	addOrder,
	fetchInventoriesByLocationId,
} from "../../redux/actions/inventory.action";
import { addOrderItem } from "../../redux/actions/order.action";
import { fetchVendors } from "../../redux/actions/vendor.action";
// import { addRequest } from "../../redux/actions/request.action";
import { fetchVenue } from "../../redux/actions/venue-action";

function AddOrderPage(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [locationId, setLocationId] = useState("");
	const [userInfo, setUserInfo] = useState({});

	const { inventoriesForOrder, isLoading } = useSelector((s) => s.inventory);
	const { vendorDropDown } = useSelector((s) => s.vendor);
	const req = useSelector((s) => s.request);
	// const [orderType, setOrderType] = useState("");

	useEffect(() => {
		setLocationId(localStorage.getItem("location_id"));
		// setOrderType(props.location.state.type);
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setUserInfo({
					user_info_id: data.user_info_id,
					first_name: data.first_name,
					last_name: data.last_name,
				});
			});
		}

		fetchSessionData();
		dispatch(fetchVenue());
		dispatch(fetchVendors());
		dispatch(
			fetchInventoriesByLocationId(localStorage.getItem("location_id"))
		);
	}, [dispatch, props, locationId]);

	const validation = () => {
		let validation = true;
		// console.log({ inventoriesForOrder });
		// if (isEmptyObject(inventoriesForOrder)) {
		// 	showToastMessage("No inventories", "W");
		// 	validation = false;
		// 	return;
		// }
		Object.keys(inventoriesForOrder).map((cat) => {
			let item = inventoriesForOrder[cat]["order_list"];
			// if (
			// 	inventoriesForOrder[cat]["vendor_id"] === "" ||
			// 	inventoriesForOrder[cat]["due_date"] === ""
			// ) {
			// 	showToastMessage("Vendor or Due Date Not Selected");
			// 	validation = false;
			// 	return;
			// }

			for (var i = 0; i < item.length; i++) {
				if (item[i].error !== "") {
					validation = false;
					break;
				}
				if (item[i].add_quantity > 0) {
					if (inventoriesForOrder[cat]["vendor_id"] === "") {
						dispatch(addOrder("", cat, "vendor_id"));
						validation = false;
						break;
					}
					if (inventoriesForOrder[cat]["due_date"] === "") {
						dispatch(addOrder("", cat, "due_date"));
						validation = false;
						break;
					}
				}
			}
		});
		return validation;
	};

	const saveOrder = async (orderStatus = "ordered") => {
		if (validation()) {
			if (localStorage.getItem("location_id") === null) {
				showToastMessage(
					"Venue not selected. Please return to inventory page and select venue",
					"W"
				);
				return;
			}
			let orderObj = {};
			orderObj.ordered_by = [
				{
					user_info_id: userInfo.user_info_id,
					name: userInfo.first_name + " " + userInfo.last_name,
				},
			];
			orderObj.location_id = locationId;
			orderObj.list = inventoriesForOrder;
			orderObj.order_type = "inventory";
			orderObj.order_status = orderStatus;

			Object.keys(orderObj.list).map((cat) => {
				if (
					orderObj.list[cat]["vendor_id"] === "" ||
					orderObj.list[cat]["due_date"] === ""
				) {
					orderObj.list[cat]["selected"] = false;
				} else {
					orderObj.list[cat]["selected"] = true;
				}
			});

			let status = await dispatch(addOrderItem(orderObj));
			if (status) {
				_back();
			}
		}
	};

	const _back = () => {
		history.push({
			pathname: "/inventory",
			state: {
				tab: 3,
			},
		});
	};

	return (
		<div className="main-container ">
			{req.isLoading && <Loader />}
			{isLoading && <Loader />}

			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => _back()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt; <span className="current-page-path">Add Order</span>
				</div>
			</div>

			{!isEmptyObject(inventoriesForOrder) ? (
				Object.keys(inventoriesForOrder).map((cat, i) => (
					<div className="inner-container full mt-20px" key={i}>
						<label htmlFor="">{cat}</label>
						<div className="inner-container full mt-20px flex flex-row">
							<FormSelectInputComponent
								label="Vendor Name"
								variant="form-sm"
								name="vendor_id"
								options={vendorDropDown}
								value={inventoriesForOrder[cat]["vendor_id"]}
								onChange={(e) =>
									dispatch(
										addOrder(
											e.target.value,
											cat,
											"vendor_id"
										)
									)
								}
								error={inventoriesForOrder[cat]["vendor_error"]}
							/>
							<FormTextInput
								label="Due Date"
								type="date"
								variant="form-sm edit"
								name="due_date"
								value={inventoriesForOrder[cat]["due_date"]}
								onChange={(e) =>
									dispatch(
										addOrder(
											e.target.value,
											cat,
											"due_date"
										)
									)
								}
								error={inventoriesForOrder[cat]["date_error"]}
							/>
						</div>
						<table
							cellPadding={0}
							cellSpacing={0}
							className="table-container -mt-44px"
						>
							<thead>
								<tr>
									<td className="table-header ">
										<div className="table-header-container">
											Item Name
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Item Type
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Brand
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Request
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											Inventory
										</div>
									</td>
									<td className="table-header ">
										<div className="table-header-container">
											T.Stock
										</div>
									</td>

									<td className="table-header ">
										<div className="table-header-container">
											Unit
										</div>
									</td>

									<td className="table-header ">
										<div className="table-header-container">
											Quantity
										</div>
									</td>
								</tr>
							</thead>

							<tbody>
								{inventoriesForOrder[cat].order_list.length >
									0 &&
									inventoriesForOrder[cat].order_list.map(
										(list, i) => (
											<tr
												className="data-table-row inventory text-capitalize text-fontSemiDark"
												key={i}
											>
												<td>{list.item_name}</td>
												<td>
													{
														list?.item_description
															?.sub_type
													}
												</td>
												<td>
													{
														list?.item_description
															?.brand
													}
												</td>
												<td>
													{list.requested_quantity}
												</td>
												<td>{list.total_quantity}</td>
												<td>
													{list.total_quantity -
														list.requested_quantity +
														list.ordered_quantity}
												</td>
												<td>{list.unit}</td>

												<td>
													<FormTextInput
														variant="form-sm edit"
														type="number"
														value={
															list.add_quantity
														}
														name="add_quantity"
														onChange={(e) =>
															dispatch(
																addOrder(
																	e.target
																		.value,
																	cat,
																	"add_quantity",
																	i
																)
															)
														}
														error={list.error}
													/>
												</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</div>
				))
			) : (
				<div className="inner-container full">No Inventories Added</div>
			)}

			<div className="inner-container button">
				<button
					className="button-primary"
					onClick={() => saveOrder("draft")}
				>
					Draft
				</button>
				<button
					className="colored-button-wrapper bg-buttonSuccess text-whiteText"
					onClick={() => saveOrder()}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default AddOrderPage;

import React, { useMemo, useState } from "react";

// for date purpose
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";
import {
	convertToNepali,
	fromBsToAd,
	getMonthlyDateRange,
	isEmptyObject,
	sessionInfo,
} from "../../../global/function";
import moment from "moment";

//components
import SideModalBooking from "../../../components/modals/side-modal/side-modal-booking.modal";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchBookingByDateRange,
	setCalendarDate,
} from "../../../redux/actions/calendar-info.action";
import SideModal from "../../../components/modals/side-modal/side-modal.modal";
import showToastMessage from "../../../global/showToastMessage";

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default function MonthlyCalendar({ onAddBooking }) {
	const calendarInfo = useSelector((state) => state.calendarInfo);

	const [dateSelection, setDateSelection] = useState({});

	const [showBookingSideModal, setShowBookingSideModal] = useState(false);
	const [showPaymentSideModal, setShowPaymentSideModal] = useState(false);
	const [showPaymentSideModalData, setShowPaymentSideModalData] = useState(
		{}
	);

	const dispatch = useDispatch();

	// select date -> stores both Nepali and English date in "YYYY-MM-DD" format
	const handleDayPress = async (dateObj) => {
		//checking permissions
		const sessionData = await sessionInfo();
		const bookingArr =
			calendarInfo.monthlyCalendarBooking[dateObj.stringEngDate];

		if (sessionData.company_role !== "viewer") {
			setShowBookingSideModal(true);
			setDateSelection(dateObj);
		} else if (
			moment(dateObj.stringEngDate).format("YYYY-MM-DD") >=
				moment().format("YYYY-MM-DD") ||
			(Array.isArray(bookingArr) && bookingArr.length > 0)
		) {
			setShowBookingSideModal(true);
			setDateSelection(dateObj);
		} else {
			showToastMessage("Cannot create past bookings", "E");
		}
	};

	// to add booking to the selected date
	const handleNavigateToAddBooking = async (date) => {
		setShowBookingSideModal(false);
		dispatch(setCalendarDate(date));
		onAddBooking();
	};

	function _renderWeekDays() {
		return (
			<div className="week-container">
				{weekDays.map((d, key) => (
					<div className="week-item-container" key={key}>
						{d}
					</div>
				))}
			</div>
		);
	}

	function _renderDates(viewedDate) {
		let daysArray = [];

		// new NibjarNepaliDate() takes year, monthIndex i.e 0-11, and date
		// viewed nepali date -> should be in string "YYYY-MM-DD" format for consistency -> if empty current date is used
		let viewDate = viewedDate
			? new NibjarNepaliDate(new Date(viewedDate)).getBS()
			: new NibjarNepaliDate().getBS();

		// previous month nepali last date
		let lastDayPrevMonth = new NibjarNepaliDate(
			viewDate.year,
			viewDate.month,
			0 // this will return one month earlier last date
		).getBS();
		// next month nepali first date
		let firstDayNextMonth = new NibjarNepaliDate(
			viewDate.year,
			viewDate.month + 1,
			1
		).getBS();

		// first date of viewed date month
		let firstDayViewedMonth = new NibjarNepaliDate(
			viewDate.year,
			viewDate.month,
			1
		).getBS();
		// last date of viewed date month
		let lastDayViewedMonth = new NibjarNepaliDate(
			viewDate.year,
			viewDate.month + 1,
			0
		).getBS();

		// adding days to calendar array
		// stringNepaliDate is nepali date in "YYYY-MM-DD" format
		// stringEngDate is corresponding english date in "YYYY-MM-DD" format
		// npDate is nepali date i.e; "DD"
		// enDate is its corresponding english date i.e; "DD"
		// adding past month days
		for (let j = 0; j < firstDayViewedMonth.day; j++) {
			let npDate = lastDayPrevMonth.date - lastDayPrevMonth.day + j;
			let stringNepaliDate = new NibjarNepaliDate(
				lastDayPrevMonth.year,
				lastDayPrevMonth.month,
				npDate
			).format("YYYY-MM-DD");
			let stringEngDate = fromBsToAd(stringNepaliDate);
			let enDate = parseInt(stringEngDate.split("-")[2]);

			daysArray.push(
				<div key={stringNepaliDate} className="day-container inactive">
					<div className="day-mainText">
						{convertToNepali(npDate)}
					</div>
					<div className="day-subText">{enDate}</div>
				</div>
			);
		}

		//adding viewed month days
		for (let i = 0; i < lastDayViewedMonth.date; i++) {
			let npDate = firstDayViewedMonth.date + i;
			let stringNepaliDate = new NibjarNepaliDate(
				firstDayViewedMonth.year,
				firstDayViewedMonth.month,
				npDate
			).format("YYYY-MM-DD");
			let stringEngDate = fromBsToAd(stringNepaliDate);
			let enDate =
				parseInt(stringEngDate.split("-")[2]) === 1
					? `${moment(stringEngDate).format("MMM")} 1`
					: parseInt(stringEngDate.split("-")[2]);

			const isToday =
				stringNepaliDate ===
				new NibjarNepaliDate().format("YYYY-MM-DD");
			const isSelected =
				stringNepaliDate === dateSelection.stringNepaliDate;

			const isBooked =
				!isEmptyObject(calendarInfo.monthlyCalendarBooking) &&
				Array.isArray(
					calendarInfo.monthlyCalendarBooking[stringEngDate]
				) &&
				calendarInfo.monthlyCalendarBooking[stringEngDate].length > 0;

			let bookedTime;

			if (isBooked) {
				bookedTime = calendarInfo.monthlyCalendarBooking[
					stringEngDate
				].map((booking) => booking.booked_time);
			}

			daysArray.push(
				<div
					key={stringNepaliDate}
					onClick={() =>
						handleDayPress({
							stringNepaliDate,
							stringEngDate,
						})
					}
					className={`day-container ${isToday && "today"} ${
						isBooked && "booked"
					} ${isSelected && "selected"}`}
				>
					<div className="day-mainText">
						{convertToNepali(npDate)}
					</div>
					<div className="booked-marker-container">
						{Array.isArray(bookedTime) &&
							bookedTime.length > 0 &&
							bookedTime.map((time, i) => (
								<div
									key={i}
									className={`booked-marker ${time}`}
								>
									{time === "morning" && (
										<i className="fa fa-sun"></i>
									)}
									{time === "afternoon" && (
										<i className="fa fa-cloud-sun"></i>
									)}
									{time === "evening" && (
										<i className="fa fa-moon"></i>
									)}
								</div>
							))}
					</div>
					<div className="day-subText">{enDate}</div>
				</div>
			);
		}

		//adding future month days
		let maxRows = 35;
		if (daysArray.length > 35) {
			maxRows = 42;
		}
		if (daysArray.length !== maxRows) {
			let rem = maxRows - daysArray.length;
			for (let i = 0; i < rem; i++) {
				let npDate = 1 + i;
				let stringNepaliDate = new NibjarNepaliDate(
					firstDayNextMonth.year,
					firstDayNextMonth.month,
					npDate
				).format("YYYY-MM-DD");
				let stringEngDate = fromBsToAd(stringNepaliDate);
				let enDate = parseInt(stringEngDate.split("-")[2]);

				daysArray.push(
					<div
						key={stringNepaliDate}
						className="day-container inactive"
					>
						<div className="day-mainText">
							{convertToNepali(npDate)}
						</div>
						<div className="day-subText">{enDate}</div>
					</div>
				);
			}
		}
		return daysArray;
	}

	// function to call after updates inside sidemodal
	const reGetMonthlyBooking = async (bookingDate, acordType = {}) => {
		if (acordType?.type === "payment") {
			setShowPaymentSideModal(true);
			setShowBookingSideModal(false);
			setShowPaymentSideModalData(acordType.data);
		}
		let newViewDateObj = new NibjarNepaliDate(
			new Date(bookingDate)
		).getBS();
		const dateRange = getMonthlyDateRange(newViewDateObj);
		await dispatch(fetchBookingByDateRange(dateRange));
	};

	const sideModalData = useMemo(() => {
		const bookingArr =
			calendarInfo.monthlyCalendarBooking[dateSelection.stringEngDate];

		if (Array.isArray(bookingArr) && bookingArr.length > 0) {
			return bookingArr;
		} else {
			return {
				en: dateSelection.stringEngDate,
				np: dateSelection.stringNepaliDate,
			};
		}
	}, [calendarInfo.monthlyCalendarBooking, dateSelection]);

	return (
		<div className="calendar-container">
			{_renderWeekDays()}
			<div className="dates-container">
				{_renderDates(calendarInfo.currDate)}
			</div>
			<SideModalBooking
				isVisible={showBookingSideModal}
				onModalClose={() => {
					setShowBookingSideModal(false);
					setDateSelection({});
				}}
				onAfterSuccessCall={reGetMonthlyBooking}
				containerStyle={{ minWidth: "310px" }}
				title="Bookings"
				sideModalData={sideModalData}
				modalType="monthlyBooking" // to determine which redux to update
				onAddBooking={handleNavigateToAddBooking}
			/>
			<SideModal
				isVisible={showPaymentSideModal}
				onModalClose={() => {
					setShowPaymentSideModal(!showPaymentSideModal);
					setShowPaymentSideModalData({});
				}}
				containerStyle={{ minWidth: "310px" }}
				title="Payment Preview"
				sideModalData={showPaymentSideModalData}
				modelFor="payment"
			></SideModal>
		</div>
	);
}

import React from "react";
import Modal from "react-modal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import strings from "../../global/strings";
import FormInputTextArea from "../common/text-input/form-textarea-input.component";
import { useDispatch, useSelector } from "react-redux";
import { refundPayment } from "../../redux/actions/payment-action";
import moment from "moment";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		overflow: "hidden",
	},
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const refundSchema = yup.object().shape({
	payment_option: yup.string().required("Please select payment option"),
	paid_amount: yup
		.number()
		.required("Amount is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	cheque_number: yup.string().when("payment_option", {
		is: (value) => value === "cheque",
		then: yup.string().required("Please enter cheque number"),
	}),
});

function RefundModal(props) {
	const dispatch = useDispatch();
	const { bookingData } = useSelector((state) => state.booking);
	const { bookingPayment } = useSelector((state) => state.payment);
	const { register, handleSubmit, setError, errors, reset, watch } = useForm({
		defaultValues: { createdAt: moment().format("YYYY-MM-DD") },
		mode: "onSubmit",
		resolver: yupResolver(refundSchema),
	});
	let paymentType = watch("payment_option");

	const _returnRefund = (details) => {
		if (details.paid_amount > bookingPayment.totalBookingPayment) {
			setError(
				"paid_amount",
				{ type: "custom", message: "Value Greater than Payment" },
				{ shouldFocus: true }
			);
		} else {
			dispatch(
				refundPayment({
					...details,
					booking_info_id: bookingData.booking_info_id,
					venue_info_id: bookingData.venue_info_id,
					transaction_type: "refund",
				})
			);
			props.handleClose();
		}
	};
	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(_returnRefund)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">Refund</div>
					</div>
					<div className="form-body">
						<div className="form-input-container">
							<div className="form-input-container inner">
								<FormTextInput
									label="Total Amount"
									variant="form-md"
									defaultValue={
										bookingPayment.totalBillAmount
									}
									disabled
								/>
								<FormTextInput
									variant="form-md"
									label="Total Paid"
									defaultValue={
										bookingPayment.totalBookingPayment
									}
									disabled={true}
								/>
							</div>
						</div>

						<div className="form-input-container">
							<div className="form-input-container inner">
								<FormSelectBox
									label="Payment Type*"
									options={strings.paymentTypeObj}
									name="payment_option"
									variant="form-sm"
									ref={register}
									type="Payment Type"
									error={errors?.payment_option?.message}
								/>
								<FormTextInput
									type="date"
									label="Refund Date"
									name="createdAt"
									variant="form-md"
									ref={register}
									error={errors?.createdAt?.message}
								/>
							</div>
						</div>

						<div className="form-input-container">
							<div className="form-input-container inner">
								<FormTextInput
									type="number"
									label="Refund Amount*"
									name="paid_amount"
									variant="form-md"
									ref={register}
									error={errors?.paid_amount?.message}
								/>
								<FormTextInput
									label="Cheque Number"
									name="cheque_number"
									variant="form-md"
									ref={register}
									error={errors?.cheque_number?.message}
									disabled={paymentType !== "cheque"}
								/>
							</div>
							<div className="form-input-container inner">
								<FormInputTextArea
									label="Notes"
									placeholder="Notes"
									variant="form-notes"
									name="notes"
									ref={register}
									error={errors?.notes?.message}
								/>
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							type="button"
							className="button-primary"
							onClick={() => {
								reset({});
								props.handleClose();
							}}
						>
							Close
						</button>
						<button type="submit" className="button-primary">
							Refund
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
}

export default RefundModal;

import {
	ADD_CUSTOMER_BASIC_UPDATE,
	ADD_CUSTOMER_ADVANCE_UPDATE,
	ADD_CUSTOMER_CANCELED,
} from "../action-types";

export const addCustomerBasicUpdate = (payload) => {
	return {
		type: ADD_CUSTOMER_BASIC_UPDATE,
		payload: payload,
	};
};

export const addCustomerAdvanceUpdate = (payload) => {
	return {
		type: ADD_CUSTOMER_ADVANCE_UPDATE,
		payload: payload,
	};
};

export const addCustomerCanceled = () => {
	return {
		type: ADD_CUSTOMER_CANCELED,
	};
};

import React, { useEffect, useState } from "react";
import { rupeeConverter } from "../../global/function";
import "./table-menu-services.style.scss";
import "./table.scss";
import MenuSvg from "../../assets/images/icons/menu svg/menu-svg";
// import MenuServicesComponent from "../settings/menu-services-settings/menu-service.component";

import PaginationReact from "../paginationReact/paginationReact.component";
import MenuItemContainer from "../add-menu-services/menu-item-container/menu-item-container.component";
import ServiceItemContainer from "../add-menu-services/service-item-container/service-item-container.component";

const TableMenuServices = (props) => {
	const [currentMenuPage, setCurrentMenuPage] = useState(1);
	const [listPerMenuPage] = useState(10);

	const [currentServicePage, setCurrentServicePage] = useState(1);
	const [listPerServicePage] = useState(10);

	const [currentDrinkPage, setCurrentDrinkPage] = useState(1);
	const [listPerDrinkPage] = useState(10);

	const indexOfLastMenuList = currentMenuPage * listPerMenuPage;
	const indexOfFirstMenuList = indexOfLastMenuList - listPerMenuPage;
	const currentMenuList = props.data.slice(
		indexOfFirstMenuList,
		indexOfLastMenuList
	);

	const indexOfLastServiceList = currentServicePage * listPerServicePage;
	const indexOfFirstServiceList = indexOfLastServiceList - listPerServicePage;
	const currentServiceList = props.data.slice(
		indexOfFirstServiceList,
		indexOfLastServiceList
	);

	const indexOfLastDrinkList = currentDrinkPage * listPerDrinkPage;
	const indexOfFirstDrinkList = indexOfLastDrinkList - listPerDrinkPage;
	const currentDrinkList = props.data.slice(
		indexOfFirstDrinkList,
		indexOfLastDrinkList
	);

	// since all the data comes at once for all menu, service and drinks... so if filter is used then pagination will set to page 1 for all
	useEffect(() => {
		setCurrentMenuPage(1);
	}, [props.type === "menu"]);
	useEffect(() => {
		setCurrentServicePage(1);
	}, [props.type === "service"]);
	useEffect(() => {
		setCurrentDrinkPage(1);
	}, [props.type === "drink"]);

	function _updateMenuItem(params) {
		props.updateItemOnClick(params);
	}
	function _updateServiceItem(params) {
		props.updateItemOnClick(params);
	}
	function _updateDrinkItem(params) {
		props.updateItemOnClick(params);
	}

	function _deleteMenuItem(params) {
		props.deleteItemOnClick(params);
	}
	function _deleteServiceItem(params) {
		props.deleteItemOnClick(params);
	}
	function _deleteDrinkItem(params) {
		props.deleteItemOnClick(params);
	}

	function _returnMenuRow() {
		return (
			<div className="table-wrapper menu-service">
				<table
					cellPadding={0}
					cellSpacing={0}
					className="table-container text-capitalize md:hidden"
				>
					<thead>
						<tr>
							<td className="table-header menu-icon"></td>
							<td className="table-header menu-name">
								<div className="table-header-container ">
									Name
								</div>
							</td>
							<td
								className="table-header menu-cartilage"
								style={{ width: "20px" }}
							></td>
							<td className="table-header menu-type">
								<div className="table-header-container">
									Item Type
								</div>
							</td>
							<td className="table-header menu-type">
								<div className="table-header-container">
									Menu Group
								</div>
							</td>

							<td className="table-header menu-category">
								<div className="table-header-container">
									Category
								</div>
							</td>
							<td className="table-header menu-price">
								<div className="table-header-container">
									Price
								</div>
							</td>
							<td className="table-header menu-action"></td>
						</tr>
					</thead>
					<tbody>
						{currentMenuList.map((row, i) => (
							<tr
								className="normal data-table-row bg-default"
								key={i}
							>
								<td className="icon-cell bg-default">
									<div
										className={`${`item-image-wrappers ${row.item_type}`}`}
									>
										<MenuSvg
											name={row.item_type}
											type="selected"
										/>
									</div>
								</td>
								<td>{row.menu_item}</td>
								<td>
									{row.menu_cartilage === "WB" && (
										<i className="fa fa-bone"></i>
									)}
								</td>
								<td>{row.item_type}</td>
								<td>{row.menu_group}</td>
								<td>{row.menu_category}</td>
								<td>Rs. {row.menu_price}</td>
								<td className="button-cell">
									{props.controlsEnabled && (
										<div>
											<button
												className="button-wrapper row-action-icon"
												onClick={() =>
													_updateMenuItem(row)
												}
											>
												<i className="fas fa-edit edit"></i>
											</button>
											<button
												className="button-wrapper row-action-icon"
												onClick={() =>
													_deleteMenuItem(row)
												}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="flex-wrap gap-15px py-15px hidden md:flex">
					{currentMenuList.map((list) => (
						<MenuItemContainer
							item={list}
							key={list.menu_info_id}
							type="selected"
							addOnClick={() => _updateMenuItem(list)}
						/>
					))}
				</div>

				<PaginationReact
					totalCount={props.data.length}
					itemsPerPage={listPerMenuPage}
					onPageClick={(currentMenuPage) => {
						let currentPage = currentMenuPage.selected + 1;
						setCurrentMenuPage(currentPage);
					}}
					forcePage={currentMenuPage - 1}
				/>
			</div>
		);
	}

	function _returnServiceRow() {
		return (
			<div className="table-wrapper menu-service">
				<table
					cellPadding={0}
					cellSpacing={0}
					className="table-container text-capitalize md:hidden"
				>
					<thead>
						<tr>
							<td className="table-header service-icon"></td>
							<td className="table-header service-name">
								<div className="table-header-container">
									Service Name
								</div>
							</td>
							<td className="table-header service-category">
								<div className="table-header-container">
									Service Category
								</div>
							</td>
							<td className="table-header service-override">
								<div className="table-header-container">
									Service Override
								</div>
							</td>
							<td className="table-header service-price">
								<div className="table-header-container">
									Service Price
								</div>
							</td>
							<td className="table-header service-action"></td>
						</tr>
					</thead>
					<tbody>
						{currentServiceList.map((row, i) => (
							<tr
								className="normal data-table-row bg-default"
								key={i}
							>
								<td className="icon-cell bg-default">
									{row.service_type.service_type_name ===
									"Entertainment" ? (
										<i className="fas fa-guitar"></i>
									) : row.service_type.service_type_name ===
									  "Transportation" ? (
										<i className="fas fa-shuttle-van"></i>
									) : (
										<i className="fas fa-ellipsis-h"></i>
									)}
								</td>
								<td>{row.service_name}</td>
								<td>{row.service_type.service_type_name}</td>
								<td>{row.override ? "Yes" : "No"}</td>
								<td>
									{rupeeConverter(
										row.service_price.toFixed(2)
									)}
								</td>
								<td className="button-cell">
									{props.controlsEnabled && (
										<div>
											<button
												className="button-wrapper row-action-icon"
												onClick={() =>
													_updateServiceItem(row)
												}
											>
												<i className="fas fa-edit edit"></i>
											</button>
											<button
												className="button-wrapper row-action-icon"
												onClick={() =>
													_deleteServiceItem(row)
												}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="flex-wrap gap-15px py-15px hidden md:flex">
					{currentServiceList.map((list, key) => (
						<ServiceItemContainer
							service_type={list.service_type_name}
							item={list}
							key={key}
							type="selected"
							addOnClick={() => _updateMenuItem(list)}
						/>
					))}
				</div>

				<PaginationReact
					totalCount={props.data.length}
					itemsPerPage={listPerServicePage}
					onPageClick={(currentServicePage) => {
						let currentPage = currentServicePage.selected + 1;
						setCurrentServicePage(currentPage);
					}}
					forcePage={currentServicePage - 1}
				/>
			</div>
		);
	}

	function _returnDrinkRow() {
		return (
			<div className="table-wrapper menu-service text-capitalize">
				<table
					cellPadding={0}
					cellSpacing={0}
					className="table-container"
				>
					<thead>
						<tr>
							<td className="table-header drink-icon"></td>
							<td className="table-header drink-name">
								<div className="table-header-container">
									Drink Name
								</div>
							</td>

							<td className="table-header drink-type">
								<div className="table-header-container">
									Drink Type
								</div>
							</td>
							<td className="table-header drink-type">
								<div className="table-header-container">
									Drink Override
								</div>
							</td>
							<td className="table-header drink-price">
								<div className="table-header-container">
									Drink Price
								</div>
							</td>
							<td className="table-header drink-action"></td>
						</tr>
					</thead>
					<tbody>
						{currentDrinkList.map((row, i) => (
							<tr
								className="normal data-table-row bg-default"
								key={i}
							>
								<td className="icon-cell bg-default">
									<i className="fas fa-wine-glass"></i>
								</td>
								<td>
									{row.drink_item} ({row.drink_unit})
								</td>
								<td>{row.drink_type}</td>
								<td>{row.override ? "Yes" : "No"}</td>
								<td>Rs. {row.drink_price}</td>
								<td className="button-cell">
									{props?.controlsEnabled && (
										<div>
											<button
												className="button-wrapper row-action-icon"
												onClick={() =>
													_updateDrinkItem(row)
												}
											>
												<i className="fas fa-edit edit"></i>
											</button>
											<button
												className="button-wrapper row-action-icon"
												onClick={() =>
													_deleteDrinkItem(row)
												}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<PaginationReact
					totalCount={props.data.length}
					itemsPerPage={listPerDrinkPage}
					onPageClick={(currentDrinkPage) => {
						let currentPage = currentDrinkPage.selected + 1;
						setCurrentDrinkPage(currentPage);
					}}
					forcePage={currentDrinkPage - 1}
				/>
			</div>
		);
	}

	switch (props.type) {
		case "menu":
			return _returnMenuRow();
		case "service":
			return _returnServiceRow();
		case "drink":
			return _returnDrinkRow();
		default:
	}
};

export default React.memo(TableMenuServices);

import {
	FETCH_MENU_REQUEST,
	FETCH_SERVICE_REQUEST,
	FETCH_DRINK_REQUEST,
	FETCH_MENU_SUCCESS,
	FETCH_SERVICE_TYPE_SUCCESS,
	FETCH_SERVICE_SUCCESS,
	FETCH_DRINK_SUCCESS,
	FETCH_MENU_ERROR,
	FETCH_SERVICE_TYPE_ERROR,
	FETCH_SERVICE_ERROR,
	FETCH_DRINK_ERROR,
	FETCH_MENU_SEGREGATED_SEARCH_SUCCESS,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchMenuRequest = () => {
	return {
		type: FETCH_MENU_REQUEST,
	};
};
export const fetchServiceRequest = () => {
	return {
		type: FETCH_SERVICE_REQUEST,
	};
};
export const fetchDrinkRequest = () => {
	return {
		type: FETCH_DRINK_REQUEST,
	};
};

export const fetchMenuSuccess = (menu) => {
	return {
		type: FETCH_MENU_SUCCESS,
		payload: menu,
	};
};
export const fetchServiceTypeSuccess = (serviceType) => {
	return {
		type: FETCH_SERVICE_TYPE_SUCCESS,
		payload: serviceType,
	};
};
export const fetchServiceSuccess = (service) => {
	return {
		type: FETCH_SERVICE_SUCCESS,
		payload: service,
	};
};
export const fetchDrinkSuccess = (drink) => {
	return {
		type: FETCH_DRINK_SUCCESS,
		payload: drink,
	};
};

export const fetchMenuError = (error) => {
	return {
		type: FETCH_MENU_ERROR,
		payload: error,
	};
};
export const fetchServiceTypeError = (error) => {
	return {
		type: FETCH_SERVICE_TYPE_ERROR,
		payload: error,
	};
};
export const fetchServiceError = (error) => {
	return {
		type: FETCH_SERVICE_ERROR,
		payload: error,
	};
};
export const fetchDrinkError = (error) => {
	return {
		type: FETCH_DRINK_ERROR,
		payload: error,
	};
};
export const fetchMenuSegregatedSearchSuccess = (searchList) => {
	return {
		type: FETCH_MENU_SEGREGATED_SEARCH_SUCCESS,
		payload: searchList,
	};
};

/* action for menu */

export const fetchMenuItem = (menuInfoId, menu_category) => {
	return async (dispatch) => {
		dispatch(fetchMenuRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}menu/by?menu_info_id=${menuInfoId}&menu_category=${menu_category}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchMenuSuccess(res.data.result));
				} else {
					await dispatch(fetchMenuError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchMenuError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const menuListSocket = (menuList) => {
	return (dispatch) => {
		dispatch(fetchMenuSuccess(menuList));
	};
};

export const searchMenuItem = (menu) => {
	return async (dispatch) => {
		dispatch(fetchMenuRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}menu/search/?menu_item=${menu.menu_item}&item_type=${menu.item_type}&menu_category=${menu.menu_category}&menu_group=${menu.menu_group}&menu_cartilage=${menu.menu_cartilage}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchMenuSuccess(res.data.result));
				} else {
					await dispatch(fetchMenuError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchMenuError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const addMenuItem = (menuData) => {
	return async (dispatch) => {
		dispatch(fetchMenuRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}menu/add`, menuData, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchMenuItem("", ""));
					showToastMessage("Menu Item Added", "S");
					return true;
				} else {
					await dispatch(fetchMenuError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchMenuError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const editMenuItem = (updatedMenuItem) => {
	return async (dispatch) => {
		dispatch(fetchMenuRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}menu/update`, updatedMenuItem, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchMenuItem("", ""));
					showToastMessage(response.data.message, "S");
					return true;
				} else {
					await dispatch(fetchMenuError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await fetchMenuError(error.message);
					showToastMessage(error.message, "E");
				}
			});
	};
};

/* action for services */

export const fetchServiceTypes = () => {
	return async (dispatch) => {
		dispatch(fetchServiceRequest());
		const sessionData = await sessionInfo();

		axios
			.get(`${strings.url}serviceType`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchServiceTypeSuccess(res.data.result));
				} else {
					await dispatch(fetchServiceTypeError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchServiceTypeError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const fetchServiceItem = () => {
	return async (dispatch) => {
		dispatch(fetchServiceRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}service/company/id`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchServiceSuccess(res.data.result));
					return res.data.result;
				} else {
					await dispatch(fetchServiceError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchServiceError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const searchServiceItem = (service) => {
	return async (dispatch) => {
		dispatch(fetchServiceRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}service/search/?service_name=${service.service_name}&service_type_id=${service.service_type_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchServiceSuccess(res.data.result));
				} else {
					await dispatch(fetchServiceError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchServiceError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const addServiceItem = (serviceData) => {
	return async (dispatch) => {
		dispatch(fetchServiceRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}service/add`, serviceData, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchServiceItem());
					showToastMessage("Service Item Added", "S");
					return true;
				} else {
					await dispatch(fetchServiceError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchServiceError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const editServiceItem = (updatedServiceItem) => {
	return async (dispatch) => {
		dispatch(fetchServiceRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}service/update`, updatedServiceItem, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchServiceItem());
					showToastMessage(response.data.message, "S");
					return true;
				} else {
					await dispatch(fetchServiceError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchServiceError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

/* action for drink */

export const fetchDrinkItem = () => {
	return async (dispatch) => {
		dispatch(fetchDrinkRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}drink/company/id`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchDrinkSuccess(res.data.result));
					return res.data.result;
				} else {
					await dispatch(fetchDrinkError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchDrinkError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const searchDrinkItem = (drink) => {
	return async (dispatch) => {
		dispatch(fetchDrinkRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}drink/search/?drink_item=${drink.drink_item}&drink_category=${drink.drink_category}&drink_type=${drink.drink_type}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchDrinkSuccess(res.data.result));
				} else {
					await dispatch(fetchDrinkError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchDrinkError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const addDrinkItem = (drinkData) => {
	return async (dispatch) => {
		dispatch(fetchDrinkRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}drink/add`, drinkData, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchDrinkItem());
					showToastMessage("Drink Item Added", "S");
					return true;
				} else {
					await dispatch(fetchDrinkError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchDrinkError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const editDrinkItem = (updatedDrinkItem) => {
	return async (dispatch) => {
		dispatch(fetchDrinkRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}drink/update`, updatedDrinkItem, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchDrinkItem());
					showToastMessage(response.data.message, "S");
					return true;
				} else {
					await dispatch(fetchDrinkError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchDrinkError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

/* get menu list for add menu service */

export const fetchSegregatedMenuItem = (
	typeFilter = "",
	cartilage = "",
	menu_group = "",
	menu_item = ""
) => {
	let url = `${strings.url}menu/booking`;
	if (
		typeFilter !== "" ||
		cartilage !== "" ||
		menu_group !== "" ||
		menu_item !== ""
	) {
		url = `${strings.url}menu/booking?item_type=${typeFilter}&menu_cartilage=${cartilage}&menu_group=${menu_group}&menu_item=${menu_item}`;
	}

	return async (dispatch) => {
		dispatch(fetchMenuRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(url, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchMenuSuccess(res.data.result));
					// return res.data.result;
				} else {
					await dispatch(fetchMenuError(res.data.message));
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					await dispatch(fetchMenuError(error.message));
				}
			});
	};
};

import {
	FETCH_BOOKING_REQUEST,
	FETCH_BOOKING_REQUEST_SUCCESS,
	FETCH_ADD_BOOKING_SUCCESS,
	FETCH_SINGLE_BOOKING_SUCCESS,
	FETCH_BOOKING_ERROR,
	FETCH_BOOKING_HISTORY_SUCCESS,
	GET_BOOKING_QUEUE_REQUEST,
	GET_BOOKING_QUEUE_ERROR,
	GET_BOOKING_QUEUE_SUCCESS,
	//REVIEW
	FETCH_BOOKING_REVIEW_REQUEST,
	FETCH_BOOKING_REVIEW_REQUEST_SUCCESS,
	FETCH_BOOKING_REVIEW_ERROR,
	// sanat
	SET_BOOKING_SEARCH_PARAMETERS,
} from "../action-types";

const initial_state = {
	isLoading: false,
	bookingSearchParameters: {
		booking_id: "",
		date_from: "",
		date_to: "",
		customer_fname: "",
		customer_lname: "",
		venue_info_id: "",
		booking_type: "",
		booking_catering: "",
		booking_status: "",
		payment_status: "",
		vat_status: "",
	},
	addedBooking: {},
	bookingList: [],
	bookingData: {},
	bookingHistoryList: [],
	bookingHistoryCount: 0,
	bookingQueueList: [],
	reviewBooking: {},
	error: "",
};

export default function bookingReducer(state = initial_state, action) {
	switch (action.type) {
		case SET_BOOKING_SEARCH_PARAMETERS:
			return {
				...state,
				bookingSearchParameters: action.payload,
			};
		case FETCH_BOOKING_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};

		case FETCH_BOOKING_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				bookingList: action.payload,
				bookingData: state.bookingData,
				error: "",
			};
		case GET_BOOKING_QUEUE_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};

		case GET_BOOKING_QUEUE_SUCCESS:
			return {
				...state,
				bookingQueueList: action.payload,
				isLoading: false,
				error: "",
			};

		case FETCH_ADD_BOOKING_SUCCESS:
			return {
				...state,
				isLoading: false,
				addedBooking: action.payload,
				bookingData: state.bookingData,
				bookingList: state.bookingList,
				error: "",
			};
		case FETCH_BOOKING_HISTORY_SUCCESS:
			return {
				...state,
				isLoading: false,
				bookingHistoryList: action.payload.rows,
				bookingHistoryCount: action.payload.count,
			};
		case FETCH_SINGLE_BOOKING_SUCCESS:
			let selectedHalls = [];
			let halls = [];
			let availableHallArray = [];

			action.payload.booking_halls.map((h) => halls.push(h.hall_info_id));

			action.payload.booking_halls.map((h) =>
				selectedHalls.push({
					booking_hall_id: h.booking_hall_id,
					hall_info_id: h.hall_info_id,
					hall_name: h.hall_name,
				})
			);

			if (action.payload.booking_status === "canceled") {
				availableHallArray = selectedHalls;
			} else {
				availableHallArray = selectedHalls.concat(
					action.payload.availableHallArray
				);
			}

			let bookingInfo = {
				...action.payload,
				availableHallArray,
				halls: halls,
			};

			return {
				...state,
				isLoading: false,
				bookingData: { ...bookingInfo },
				bookingList: state.bookingList,
				error: "",
			};

		case FETCH_BOOKING_ERROR:
			return {
				...state,
				isLoading: false,
				bookingData: state.bookingData,
				error: action.payload,
			};
		case GET_BOOKING_QUEUE_ERROR:
			return {
				...state,
				isLoading: false,
				bookingQueueList: state.bookingQueueList,
				error: action.payload,
			};

		// REVIEW
		case FETCH_BOOKING_REVIEW_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};

		case FETCH_BOOKING_REVIEW_REQUEST_SUCCESS:
			return {
				...state,
				reviewBooking: action.payload,
				isLoading: false,
				error: "",
			};

		case FETCH_BOOKING_REVIEW_ERROR:
			return {
				...state,
				isLoading: false,
				reviewBooking: state.reviewBooking,
				error: action.payload,
			};

		default:
			return state;
	}
}

import React from "react";
import "./analytics-component.style.scss";

import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import {
	Chart as ChartJS,
	BarController,
	BarElement,
	Legend,
	Tooltip,
} from "chart.js";

ChartJS.register(BarController, BarElement, Legend, Tooltip, annotationPlugin);

export default function DifferenceChart({
	anaDataSet = {},
	type = "difference",
	label = "",
	toolTipType = "Rs.",
	barColor = "#523563",
}) {
	function _renderDifferenceGrid() {
		let yearList = Object.keys(anaDataSet);
		const nepMonth = Object.keys(anaDataSet[yearList[0]].monthlyRevenue);

		const dataPast = Object.values(anaDataSet[yearList[0]].monthlyRevenue);
		const dataCurrent = Object.values(
			anaDataSet[yearList[1]]?.monthlyRevenue
		);

		let dataStructured = [];
		for (let i = 0; i < nepMonth.length; i++) {
			let cal = dataCurrent[i] - dataPast[i];
			dataStructured.push(cal);
		}

		let barData = {
			labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			datasets: [
				{
					label: "Difference",
					data: dataStructured,
					backgroundColor: (context) => {
						const value = context.dataset.data[context.dataIndex];
						return value < 0 ? "#D35A47" : "#3498db";
					},
				},
			],
		};
		let options = {
			maintainAspectRatio: false,
			scales: {
				y: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: "index",
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					callbacks: {
						title: (context) => {
							const index = nepMonth[context[0].dataIndex];
							return `${index}`;
						},
						label: (context) => {
							const value = context.parsed.y || 0;
							if (value < 0) {
								return `Downfall: Rs. ${Math.abs(value)}`;
							} else {
								return `Growth: Rs. ${value}`;
							}
						},
					},
				},
			},
		};
		return (
			<div className="ana-dg-dif-component">
				<div className="dg-dif-header">
					Current Revenue VS Past Revenue
				</div>
				<div className="ana-dg-dif-chart">
					<Bar type="bar" data={barData} options={options} />
				</div>
			</div>
		);
	}

	function _renderYearBarChart() {
		const dataYear = Object.values(anaDataSet);
		const nepYear = Object.keys(anaDataSet);
		const dataSet = {
			label: "Difference",
			data: dataYear,
			backgroundColor: (context) => {
				const value = context.dataset.data[context.dataIndex];
				if (label === "Revenue") {
					return value < dataYear[dataYear.length - 1]
						? "#D35A47"
						: "#3498DB";
				} else {
					return value < dataYear[dataYear.length - 1]
						? "#46BE46"
						: "#523563";
				}
			},
		};
		if (type !== "year") {
			dataSet.backgroundColor = barColor;
		}
		let barData = {
			labels: nepYear,
			datasets: [dataSet],
		};
		let options = {
			maintainAspectRatio: false,
			scales: {
				y: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: "index",
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					callbacks: {
						title: (context) => {
							const index = nepYear[context[0].dataIndex];
							return `${index}`;
						},
						label: (context) => {
							const value = context.parsed.y || 0;
							return `${label}: ${toolTipType} ${value?.toFixed(
								2
							)}`;
						},
					},
				},
				annotation: {
					annotations: [
						{
							type: "line",
							yMin: dataYear[dataYear.length - 1],
							yMax: dataYear[dataYear.length - 1],
							borderColor: "#3498DB",
							borderWidth: 1,
						},
					],
				},
			},
		};
		return (
			<div className="ana-dg-dif-component">
				<div className="dg-dif-header">{label}</div>
				<div className="ana-dg-dif-chart">
					<Bar type="bar" data={barData} options={options} />
				</div>
			</div>
		);
	}

	return (
		<div className="ana-dg-dif-container">
			{type === "difference"
				? _renderDifferenceGrid()
				: _renderYearBarChart()}{" "}
		</div>
	);
}

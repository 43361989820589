import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetails } from "../../../redux/actions/company-setting.action";
import { rupeeConverter } from "../../../global/function";

import * as DOMPurify from "dompurify";

function PrintFooter({
	customerName,
	cateringCost = -1,
	payment,
	enableAmount = true,
	enableSign = true,
	enableTerms = true,
	printInfo,
	companyRole,
	vatDisplay,
}) {
	const dispatch = useDispatch();
	const { companyDetails } = useSelector((state) => state.companySetting);

	useEffect(() => {
		async function fetchSessionData() {
			await dispatch(fetchCompanyDetails());
		}

		fetchSessionData();
	}, [dispatch]);

	const _renderFooterAmounts = () => {
		if (cateringCost > -1) {
			return _renderBookingAmounts();
		} else {
			return _renderEventAmounts();
		}
	};

	const _renderBookingAmounts = () => {
		return (
			<>
				<h3>Invoice Estimates</h3>
				<div className="amounts">
					<div>Catering</div>
					<div className="right">
						{cateringCost &&
							rupeeConverter(cateringCost?.toFixed(2))}
					</div>
					<div>Services</div>
					<div className="right">
						{(payment?.servicePaymentInfo &&
							rupeeConverter(
								payment?.servicePaymentInfo?.toFixed(2)
							)) ||
							0}
					</div>
					<div>Bar</div>
					<div className="right">
						{(payment?.drinksPaymentInfo &&
							rupeeConverter(
								payment?.drinksPaymentInfo?.toFixed(2)
							)) ||
							0}
					</div>
					<div className="highlight">Sub Total</div>
					<div className="right highlight">
						{rupeeConverter(
							(
								payment?.totalEstimatedBookingCost +
								payment?.totalBookingDiscount
							).toFixed(2)
						)}
					</div>
					<div>Discount</div>
					<div className="right">
						{payment?.totalBookingDiscount &&
							rupeeConverter(
								payment?.totalBookingDiscount?.toFixed(2)
							)}
					</div>
					<div className="highlight">
						{vatDisplay ? "Gross Total" : "Net Total"}
					</div>
					<div className="right highlight">
						{payment?.totalEstimatedBookingCost &&
							rupeeConverter(
								payment?.totalEstimatedBookingCost?.toFixed(2)
							)}
					</div>
					{vatDisplay && (
						<>
							<div>VAT</div>
							<div className="right">
								{rupeeConverter(
									(
										payment?.totalEstimatedBookingCost *
										0.13
									).toFixed(2)
								)}
							</div>
							<div className="highlight">Total</div>
							<div className="right highlight">
								{payment?.totalEstimatedBookingCost &&
									rupeeConverter(
										(
											payment?.totalEstimatedBookingCost +
											payment?.totalEstimatedBookingCost *
												0.13
										).toFixed(2)
									)}
							</div>
						</>
					)}
					<div>Payments</div>
					<div className="right">
						{payment?.totalBookingPayment &&
							rupeeConverter(
								payment?.totalBookingPayment?.toFixed(2)
							)}
					</div>
					{vatDisplay && (
						<>
							<div className="highlight">Balance</div>
							<div className="right highlight">
								{payment?.totalBalance &&
									rupeeConverter(
										payment?.totalBalance?.toFixed(2)
									)}
							</div>
						</>
					)}
					<div>Adv</div>
					<div className="right">
						{payment?.totalBookingPayment &&
							rupeeConverter(
								(
									payment?.totalEstimatedBookingCost -
									payment?.totalBookingPayment
								).toFixed(2)
							)}
					</div>
				</div>
			</>
		);
	};

	const _renderEventAmounts = () => {
		return (
			<>
				<h3>Invoice Breakdowns</h3>
				<div className="amounts">
					<div>Event Total</div>
					<div className="right">
						{rupeeConverter(
							(
								payment?.eventTotalCost -
								payment?.eventTotalDiscount
							).toFixed(2)
						)}
					</div>
					<div>Discount</div>
					<div className="right">
						{rupeeConverter(
							payment?.eventTotalDiscount?.toFixed(2)
						)}
					</div>
					<div className="highlight">
						{vatDisplay ? "Sub Total" : "Net Total"}
					</div>
					<div className="right highlight">
						{rupeeConverter(payment?.eventTotalCost?.toFixed(2))}
					</div>
					{vatDisplay && (
						<>
							<div>VAT</div>
							<div className="right">
								{rupeeConverter(
									payment?.eventTotalVat?.toFixed(2)
								)}
							</div>
							<div className="highlight">Total</div>
							<div className="right highlight">
								{rupeeConverter(
									payment?.eventTotalBillAmount?.toFixed(2)
								)}
							</div>
						</>
					)}
					<div>Payments</div>
					<div className="right">
						{rupeeConverter(payment?.eventTotalPayment?.toFixed(2))}
					</div>

					{vatDisplay && (
						<>
							<div className="highlight">Balance</div>
							<div className="right highlight">
								{rupeeConverter(
									payment?.eventTotalPaymentRemaining?.toFixed(
										2
									)
								)}
							</div>
						</>
					)}
					<div>Adv</div>
					<div className="right">
						{rupeeConverter(
							(
								payment?.eventTotalCost -
								payment?.eventTotalPayment
							).toFixed(2)
						)}
					</div>
				</div>
			</>
		);
	};

	const _renderTermsConditions = () => ({
		__html: DOMPurify.sanitize(companyDetails.company_terms_condition),
	});

	return (
		<div className="footer-wrapper">
			<div
				className={`footer-content ${
					(printInfo === "summary" || companyRole === "viewer") &&
					"summaryAgreement"
				}`}
			>
				<div className="term-condition-content">
					<h3>Terms and conditions</h3>
					<p dangerouslySetInnerHTML={_renderTermsConditions()} />
				</div>
				<div className="pr-15px">
					{(printInfo === "invoice" ||
						(printInfo === "booking" &&
							companyRole !== "viewer")) &&
						enableAmount &&
						_renderFooterAmounts()}
					{enableSign && (
						<div className="agreement">
							<p>Customer: {customerName}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default PrintFooter;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import {
	addDrinkItem,
	editDrinkItem,
} from "../../redux/actions/menu-service-settings.action";
import { useDispatch } from "react-redux";
// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { sessionInfo } from "../../global/function";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const drinkSchema = yup.object().shape({
	drink_category: yup.string().required("drink category required"),
	drink_item: yup.string().required("drink name required"),
	drink_type: yup.string().required("drink type required"),
	drink_unit: yup.string().required("drink unit required"),
	override: yup.string().required("drink override required"),
	drink_price: yup
		.number()
		.typeError("Must be a number")
		.required("price is required")
		.positive("Must be greater than zero"),
});

const AddDrinkModal = (props) => {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(drinkSchema),
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.actionType === "Update") {
			reset(props.updatedItem);
		} else {
			reset({
				drink_type: "",
				drink_category: "",
			});
		}
	}, [props.updatedItem]);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};

	async function _returndrink(data) {
		let drinkStatus = "";
		let drinkItem = {
			...data,
		};

		if (props.actionType === "Add") {
			drinkStatus = await dispatch(addDrinkItem(drinkItem));
		} else {
			let updatedDrink = {
				...drinkItem,
				drink_info_id: props.updatedItem.drink_info_id,
			};
			drinkStatus = await dispatch(editDrinkItem(updatedDrink));
		}
		if (drinkStatus) {
			props.handleClose();
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(_returndrink)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">
							{props.actionType} Drink
						</div>
					</div>
					<div className="form-body">
						<div className="form-description-title">
							Drink Details
						</div>
						<div className="form-input-container">
							<FormTextInput
								type="text"
								label="Drink Name"
								placeholder="Enter Drink Name"
								variant="form-lg"
								ref={register}
								name="drink_item"
								error={errors?.drink_item?.message}
							/>
							<FormTextInput
								type="number"
								placeholder="Price*"
								variant="form-lg"
								label="Price"
								ref={register}
								name="drink_price"
								error={errors?.drink_price?.message}
							/>
						</div>

						<div className="form-input-container">
							<div className="form-input-container inner">
								<FormSelectBox
									label="Drink Type*"
									options={strings.drinkType}
									name="drink_type"
									variant="form-sm"
									ref={register}
									type="Drink Type"
									error={errors?.drink_type?.message}
								/>

								<FormSelectBox
									label="Drinks Category*"
									options={strings.drinkCategory}
									name="drink_category"
									variant="form-sm"
									ref={register}
									type="Drink Category"
									error={errors?.drink_category?.message}
								/>
							</div>
							<div className="form-input-container inner">
								<FormSelectBox
									label="Drink Unit*"
									options={strings.drinkUnit}
									variant="form-sm"
									ref={register}
									name="drink_unit"
									type="Drink Unit"
									error={errors?.drink_unit?.message}
								/>
								<FormSelectBox
									label="Drink Override*"
									options={strings.overrideType}
									variant="form-sm"
									ref={register}
									name="override"
									type="Drink Override"
									error={errors?.override?.message}
								/>
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => {
								reset({});
								props.handleClose();
							}}
						>
							Close
						</button>
						<button
							type="submit"
							className="button-primary"
							onClick={handleSubmit(_returndrink)}
						>
							{props.actionType}
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddDrinkModal;

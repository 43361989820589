import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { resetPassword } from "../../redux/actions/login-action";
import Loader from "../../components/loader/loader.component";

import { useDispatch, useSelector } from "react-redux";
import strings from "../../global/strings";
import "../../components/modals/modal.style.scss";
import FormInput from "../../components/common/text-input/form-text-input.component";

const loginSchema = yup.object().shape({
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
});

export default function ResetPassword(props) {
	const [successState, setSuccessState] = useState(false);
	const history = useHistory();
	const { register, handleSubmit, errors } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(loginSchema),
	});

	const { isLoading } = useSelector((state) => state.login);
	const dispatch = useDispatch();

	const _saveData = async (resetPw) => {
		const { verificationToken } = props.match.params;
		resetPw["verificationToken"] = verificationToken;
		delete resetPw["confirm_password"];
		let status = await dispatch(resetPassword(resetPw));
		if (status) {
			setSuccessState(true);
		}
	};

	const resetPasswordForm = () => {
		return (
			<div className="form-main-content bg-default">
				<form onSubmit={handleSubmit(_saveData)}>
					<div className="form-header">
						<div className="form-sub-title">
							{strings.forgotPasswordModal.title}
						</div>
					</div>

					<div className="form-body">
						<div className="form-description-title">
							{strings.forgotPasswordModal.message}
						</div>
						<div className="form-input-container">
							<FormInput
								type="password"
								label="Password"
								placeholder="Enter Password"
								variant="form-lg"
								ref={register}
								name="password"
								error={errors?.password?.message}
							/>
							<FormInput
								type="password"
								label="Confirm Password"
								placeholder="Enter confirm Password"
								variant="form-lg"
								ref={register}
								name="confirm_password"
								error={errors?.confirm_password?.message}
							/>
						</div>

						<div className="form-input-container"></div>
					</div>

					<div className="form-footer one">
						<button type="submit" className="button-primary">
							Reset
						</button>
					</div>
				</form>
			</div>
		);
	};

	const resetPasswordSuccessView = () => {
		return (
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Success</div>
				</div>

				<div className="form-body">
					<div className="form-description-title">
						Your password is successfully updated
					</div>
				</div>

				<div className="form-footer one">
					<button
						type="submit"
						className="button-primary"
						onClick={() => history.push({ pathname: "/" })}
					>
						Sign In
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="signin-page-wrapper">
			{isLoading && <Loader />}
			<div className="signup-main-container">
				<div className="company-title">CATER ME</div>

				{successState
					? resetPasswordSuccessView()
					: resetPasswordForm()}
			</div>
		</div>
	);
}

import React, { forwardRef, useState } from "react";
import "./text-input.style.scss";

function FormTextInput(props, ref) {
	const [view, setView] = useState(false);
	return (
		<div className={`form-input-wrapper ${props.variant}`}>
			<label htmlFor={`${props.label}`} className="form-input-label">
				{props.label}
			</label>
			{props.type === "password" ? (
				<div className="password-container">
					<input
						{...props}
						ref={ref}
						className={`${props.variant} form-input password`}
						type={view ? "text" : "password"}
						id={`${props.name}`}
					/>
					<div
						className="password-visibility"
						onClick={() => setView(!view)}
					>
						{view ? (
							<i className="far fa-eye"></i>
						) : (
							<i className="far fa-eye-slash"></i>
						)}
					</div>
				</div>
			) : (
				<input
					{...props}
					ref={ref}
					className={`${props.variant} form-input ${props.inputvariant}`}
					id={`${props.name}`}
				/>
			)}
			<span className="form-validation-error">{props.error}</span>
		</div>
	);
}
export default forwardRef(FormTextInput);

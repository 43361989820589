import React, { useEffect } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import {
	addMenuItem,
	editMenuItem,
} from "../../redux/actions/menu-service-settings.action";
import { useDispatch } from "react-redux";
// form set up :D
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { sessionInfo } from "../../global/function";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const menuSchema = yup.object().shape({
	menu_item: yup.string().required("item name required"),
	menu_price: yup
		.number()
		.typeError("Must be a number")
		.required("price is required")
		.positive("Must be greater than zero"),
	menu_category: yup.string().required("menu category required"),
	menu_group: yup.string().required("menu group required"),
	item_type: yup.string().when("menu_category", {
		is: (value) => value !== "dessert",
		then: yup.string().required("Select Item Type"),
	}),
	menu_cartilage: yup.string().when(["menu_category", "item_type"], {
		is: (category, type) => category !== "dessert" && type !== "veg",
		then: yup.string().required("Select Menu Cartilage"),
	}),
});

const AddMenuModal = (props) => {
	const { register, handleSubmit, errors, reset, watch } = useForm({
		defaultValues: { menu_group: "Regular" },
		mode: "onSubmit",
		resolver: yupResolver(menuSchema),
	});
	const menuValues = watch(["menu_category", "menu_cartilage", "item_type"]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.actionType === "Update") {
			reset(props.updatedItem);
		} else {
			reset({
				item_type: "",
				menu_cartilage: "",
				menu_category: "",
			});
		}
	}, [props.actionType, props.updatedItem, reset]);
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};

	async function _returnMenu(data) {
		let menuStatus = "";

		if (
			data.item_type === "veg" ||
			data.item_type === "dal" ||
			data.item_type === "staple" ||
			data.item_type === "bread"
		) {
			data["menu_cartilage"] = "NONE";
		}
		let menuItem = {
			...data,
		};

		if (props.actionType === "Add") {
			menuStatus = await dispatch(addMenuItem(menuItem));
		} else {
			let updatedMenu = {
				...menuItem,
				menu_info_id: props.updatedItem.menu_info_id,
			};

			menuStatus = await dispatch(editMenuItem(updatedMenu));
		}
		if (menuStatus) {
			props.handleClose();
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(_returnMenu)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">
							{props.actionType} Menu
						</div>
					</div>
					<div className="form-body">
						<div className="form-description-title">
							Menu Details
						</div>

						<div className="form-input-container">
							<FormTextInput
								type="text"
								label="Menu Name"
								placeholder="Enter Menu Item Name"
								variant="form-lg"
								ref={register}
								name="menu_item"
								error={errors?.menu_item?.message}
							/>
							<FormTextInput
								type="number"
								placeholder="Price"
								label="Price"
								variant="item-price"
								ref={register}
								name="menu_price"
								error={errors?.menu_price?.message}
							/>
						</div>

						<div className="form-input-container">
							<div className="form-input-container inner">
								<FormSelectBox
									label="Menu Category*"
									options={strings.categoryType}
									name="menu_category"
									variant="form-sm"
									ref={register}
									error={errors?.menu_category?.message}
								/>

								<FormSelectBox
									label="Menu Item Type*"
									options={strings.itemTypes}
									variant="form-sm"
									name="item_type"
									ref={register}
									error={errors?.item_type?.message}
									disabled={menuValues.menu_category === ""}
								/>
							</div>
							<div className="form-input-container inner">
								<FormSelectBox
									label="Menu Cartilage*"
									options={strings.menuCartilage}
									name="menu_cartilage"
									variant="form-sm"
									ref={register}
									error={errors?.menu_cartilage?.message}
									disabled={
										menuValues.item_type === "veg" ||
										menuValues.item_type === "" ||
										menuValues.item_type === "dal" ||
										menuValues.item_type === "staple" ||
										menuValues.item_type === "bread"
									}
								/>

								<FormSelectBox
									label="Menu Group*"
									options={strings.menuGroup}
									name="menu_group"
									variant="form-sm"
									ref={register}
									error={errors?.menu_group?.message}
								/>
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => {
								reset({});
								props.handleClose();
							}}
						>
							Close
						</button>
						<button
							type="submit"
							className="button-primary"
							onClick={handleSubmit(_returnMenu)}
						>
							{props.actionType}
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddMenuModal;

import React, { useEffect, useState } from "react";
import "./company-settings.style.scss";
import { isEmptyObject, sessionInfo } from "../../../global/function";
import { useDispatch } from "react-redux";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateCompanyDetails } from "../../../redux/actions/company-setting.action";
import showToastMessage from "../../../global/showToastMessage";
import TextInputWithErrorComponent from "../../common/text-input/text-input-with-error.component";
import TextInputWithIconComponent from "../../common/text-input/text-input-with-icon.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import strings from "../../../global/strings";

// for terms and condition text editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const companySchema = yup.object().shape({
	company_name: yup.string().required("required"),
	company_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	company_mobile: yup
		.number()
		.typeError("must be number")
		.required("phone number is required")
		.positive("Must be greater than zero"),
	company_phone: yup
		.number()
		.typeError("must be number")
		.required("phone number is required")
		.positive("Must be greater than zero"),
	company_address: yup.string().required("address required"),
});

export default function CompanyDetailsComponent({
	data,
	handleAddVenueModalShow,
	changeVenueStatus,
}) {
	const dispatch = useDispatch();

	const [role, setCompanyRole] = useState("");
	const [company_info_id, setCompId] = useState();
	const [companyEditFlag, setCompanyEditFlag] = useState(false);
	const [termsCondition, setTermsCondition] = useState("");

	const { register, handleSubmit, reset, errors } = useForm({
		mode: "onSubmit",
		defaultValues: {
			company_website: null,
		},
		resolver: yupResolver(companySchema),
	});

	const [activeVenueNum, setActiveVenueNum] = useState(1);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
				setCompId(data.company_info_id);
			});
		}

		fetchSessionData();
	}, []);

	useEffect(() => {
		reset(data);
	}, [data, reset]);

	useEffect(() => {
		let num = 0;
		if (!isEmptyObject(data)) {
			data.venue_infos.map((venue) => {
				if (venue.venue_status === "active") {
					num++;
				}
				setActiveVenueNum(num);
				return null;
			});
		}
	}, [data]);

	const onClickUpdate = (companyInfo) => {
		companyInfo["company_terms_condition"] = termsCondition;
		companyInfo["company_info_id"] = company_info_id;

		setCompanyEditFlag(false);
		dispatch(updateCompanyDetails(companyInfo));
	};

	const onChangeVenueStatus = async (e, venue_info_id, venue_name) => {
		if (activeVenueNum === 1 && e.target.checked === false) {
			showToastMessage("You cannot disable only active Venue", "W");
		} else {
			let status = e.target.checked === true ? "active" : "inactive";
			changeVenueStatus(status, venue_info_id, venue_name);
		}
	};

	return (
		<div className="inner-container">
			<div className="main-inner-container company">
				<div className="bg-default company-name-container">
					{companyEditFlag ? (
						<TextInputWithErrorComponent
							variant="search company-md"
							ref={register}
							name="company_name"
							placeholder="Company Name*"
							error={errors?.company_name?.message}
						/>
					) : (
						<span className="company-info-label">
							{data.company_name}
						</span>
					)}
				</div>
				<div className="bg-default venue-container">
					<span className="venue-title">Venues</span>
					<p className="subscription-description">
						You currently have {activeVenueNum} active Venues.
					</p>

					{!isEmptyObject(data) &&
						data.venue_infos.map((venues) => (
							<label
								className={`bg-default venue-checkbox-container text-capitalize ${role}`}
								key={venues.venue_id}
							>
								{venues.venue_name}
								<input
									type="checkbox"
									className={`venue-checkbox-input ${role}`}
									onChange={(e) =>
										onChangeVenueStatus(
											e,
											venues.venue_info_id,
											venues.venue_name
										)
									}
									checked={
										venues.venue_status === "active"
											? true
											: false
									}
									disabled={
										role === "viewer" || !companyEditFlag
									}
									value={
										venues.venue_status === "active"
											? "inactive"
											: "active"
									}
								/>
								<span className="bg-inset venue-checkmark" />
							</label>
						))}

					{role === "admin" && (
						<button
							className={`add-venue-button button-primary ${role}`}
							disabled={true ||!companyEditFlag }
							onClick={false && handleAddVenueModalShow}
						>
							Add Venue
						</button>
					)}
				</div>
			</div>
			<div className="main-inner-container ">
				<div className="inner-content rows">
					<div className="id-details-container bg-default">
						<div className="id-details-title">Company ID</div>
						<div className="id-details-message">
							{data.company_id}
						</div>
					</div>
					<div className="id-details-container bg-default">
						<div className="id-details-title">Account Verified</div>
						<div className="id-details-message ">
							{data.company_verified ? (
								<i className="fas fa-check verified"></i>
							) : (
								<i className="fas fa-times unverified"></i>
							)}
						</div>
					</div>
				</div>
				<div className="inner-content cols mt-30px">
					{/* <FormTextArea
						label="Terms & Condition"
						variant="form-notes-md"
						name="company_terms_condition"
						ref={register}
						error={errors?.company_terms_condition?.message}
						disabled={!companyEditFlag}
					/> */}

					<CKEditor
						disabled={!companyEditFlag}
						editor={ClassicEditor}
						data={data.company_terms_condition}
						onChange={(event, editor) => {
							const data = editor.getData();
							setTermsCondition(data);
						}}
						config={{
							removePlugins: [
								"EasyImage",
								"ImageToolbar",
								"ImageUpload",
								"MediaEmbed",
								"Table",
							],
						}}
					/>
				</div>
			</div>
			<div className="main-inner-container">
				<div className="inner-content rows ">
					<TextInputWithErrorComponent
						variant={companyEditFlag ? "form-md edit" : "form-md"}
						label="Primay Phone*"
						name="company_mobile"
						ref={register}
						error={errors?.company_mobile?.message}
						type="number"
						disabled={!companyEditFlag}
					/>
					<TextInputWithErrorComponent
						variant={companyEditFlag ? "form-md edit" : "form-md"}
						label="Seconday Phone"
						name="company_phone"
						ref={register}
						error={errors?.company_phone?.message}
						type="number"
						disabled={!companyEditFlag}
					/>
				</div>

				<div className="inner-content cols ">
					<TextInputWithErrorComponent
						variant={companyEditFlag ? "form-lg edit" : "form-lg"}
						label="Company Email*"
						name="company_email"
						ref={register}
						error={errors?.company_email?.message}
						type="email"
						disabled={!companyEditFlag}
					/>
				</div>

				<div className="inner-content cols mt-15px ">
					<TextInputWithErrorComponent
						variant={companyEditFlag ? "form-lg edit" : "form-lg"}
						label="Street Address*"
						name="company_address"
						ref={register}
						error={errors?.company_address?.message}
						disabled={!companyEditFlag}
					/>
				</div>

				<div className="inner-content rows ">
					<FormSelectBox
						label="City*"
						variant="form-sm"
						options={strings.city}
						name="company_city"
						ref={register}
						type="City"
						error={errors?.company_city?.message}
						disabled={!companyEditFlag}
					/>
					<FormSelectBox
						label="Country*"
						variant="form-sm"
						options={strings.country}
						name="company_country"
						ref={register}
						type="Country"
						error={errors?.company_country?.message}
						disabled={!companyEditFlag}
					/>
				</div>
			</div>
			<div className="main-inner-container">
				<div className="inner-content cols">
					<span className="form-label">Social Media</span>
					<TextInputWithIconComponent
						variant={companyEditFlag ? "icon-lg edit" : "icon-lg"}
						icon="web"
						name="company_website"
						ref={register}
						error={errors?.company_website?.message}
						disabled={!companyEditFlag}
					/>
					<TextInputWithIconComponent
						variant={companyEditFlag ? "icon-lg edit" : "icon-lg"}
						icon="fb"
						name="company_facebook"
						ref={register}
						error={errors?.company_facebook?.message}
						disabled={!companyEditFlag}
					/>
				</div>
				<div className="inner-content cols button"></div>
				{role === "admin" &&
					(companyEditFlag ? (
						<div className="inner-content cols button">
							<button
								className="button-primary"
								onClick={() => setCompanyEditFlag(false)}
							>
								Cancel
							</button>
							<button
								className="button-primary"
								onClick={handleSubmit(onClickUpdate)}
							>
								Save
							</button>
						</div>
					) : (
						<div className="inner-content cols button">
							<button
								className="button-primary"
								onClick={() => setCompanyEditFlag(true)}
							>
								Edit
							</button>
						</div>
					))}
			</div>
		</div>
	);
}

/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
	fetchServiceTypes,
	fetchServiceItem,
	searchServiceItem,
} from "../../redux/actions/menu-service-settings.action";
import { useDispatch, useSelector } from "react-redux";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import SelectBox from "../common/select-box/form-select-input.component";
import { exportHistory } from "../../redux/actions/booking-action";

function SearchServiceComponent({
	handleAdd,
	uploadClick,
	controlsEnabled = true,
}) {
	const dispatch = useDispatch();

	const [searchServiceName, setSearchServiceName] = useState({
		service_name: "",
		service_type_id: "",
	});
	let serviceTypes = [];

	useSelector((state) => {
		return state.menuServiceSetting.serviceTypes.map((service) => {
			serviceTypes.push({
				id: service.service_type_id,
				value: service.service_type_name,
			});
		});
	});

	useEffect(() => {
		dispatch(fetchServiceTypes());
	}, [dispatch]);

	const setSearchService = (e) => {
		setSearchServiceName({
			...searchServiceName,
			[e.target.name]: e.target.value,
		});
	};
	const _clearServiceSearch = () => {
		setSearchServiceName({
			service_name: "",
			service_type_id: "",
		});
		dispatch(fetchServiceItem());
	};

	const _searchService = () => {
		dispatch(searchServiceItem(searchServiceName));
	};
	const uploadService = () => {
		uploadClick("service");
	};

	const exportCSV = async () => {
		await dispatch(exportHistory("Service", "", ""));
	};

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Service</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					label="Service Name"
					placeholder="Enter Service Name"
					type="text"
					name="service_name"
					value={searchServiceName.service_name}
					onChange={(e) => setSearchService(e)}
				/>
				<SelectBox
					options={serviceTypes}
					variant="search"
					label="Service Type"
					name="service_type_id"
					value={searchServiceName.service_type_id}
					onChange={(e) => setSearchService(e)}
					type="Service Type"
				/>
			</div>
			<div
				className={`flex gap-x-15px ${
					controlsEnabled ? "justify-between" : "justify-end"
				}`}
			>
				{controlsEnabled && (
					<div className="flex gap-10px">
						<button className="button-primary " onClick={handleAdd}>
							Add Service
						</button>
						<button
							className="button-primary sm:hidden"
							onClick={uploadService}
						>
							Upload Service
						</button>
						<button
							className="button-primary sm:hidden"
							onClick={exportCSV}
						>
							Export CSV
						</button>
					</div>
				)}
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearServiceSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={_searchService}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}
export default SearchServiceComponent;

import React, { useState } from "react";
import "./analytics-component.style.scss";
// charts
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	zoomPlugin,
	Filler
);

/**
 * Line Graph for analytics
 *
 * @param type "compare" | "trend"
 * * compare [default] => Shows multi line data sets
 * * trend => Shows all data as a singel line
 * * dataSet => let m = {2078: { month : value ...}, 2079:{..}}
 */
export default function LineHistoryGraph({ anaDataSet, type = "compare" }) {
	const [tickHighlight, setTickHighlight] = useState("");
	const [chartFullView, setChartFullView] = useState(true);

	const options = {
		scales: {
			y: {
				// display: false,
				ticks: {
					font: {
						size: 8,
					},
				},
				grid: {
					color: "#e7e7e722",
				},
			},
			x: {
				display: true,
				offset: true,
				ticks: {
					font: {
						size: 7,
					},
				},
				grid: {
					color: (context) => {
						// Color the vertical tick every 12 ticks
						const index = context.index;
						let color = "rgba(0, 0, 0, 0.05)";
						if (index % 12 === 0 && tickHighlight === "") {
							color = "#a0a0a0";
						} else {
							let cLabel = context.tick.label.split("-");
							if (cLabel[0] === tickHighlight) {
								color = "#E78106";
							}
						}
						return color;
					},
				},
			},
		},
		interaction: {
			intersect: false,
			mode: "index",
		},
		onClick: (event, elements) => {
			// Change tick color on click
			if (elements.length > 0) {
				let clickLabel = event.chart.tooltip.title[0];
				let clickMonth = clickLabel.split("-");

				if (clickMonth[0] === tickHighlight) {
					setTickHighlight("");
				} else setTickHighlight(clickMonth[0]);
			}
		},
		maintainAspectRatio: false,
		elements: {
			line: {
				tension: 0.1,
			},
		},
		responsive: true,
	};

	const colors = [
		"#3498DB",
		"#9d9d9d",
		"#A0A0A060",
		"#A0A0A050",
		"#A0A0A040",
		"#A0A0A020",
	];

	const yearList = Object.keys(anaDataSet);

	function _renderLineChart() {
		const nepMonth = Object.keys(anaDataSet[yearList[0]].monthlyRevenue);

		const dataList = dataLister();
		function dataLister() {
			let finData = [];
			yearList.reverse();
			yearList.forEach((year, i) => {
				let revData = Object.values(anaDataSet[year].monthlyRevenue);
				finData.push({
					label: year,
					data: revData,
					borderWidth: 2,
					borderColor: colors[i],
					backgroundColor: colors[i],
					pointRadius: 2,
					tension: 0.5,
				});
			});
			return finData;
		}

		const plugins = {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const value = context.formattedValue || 0;
						const label = context.dataset.label || "";
						return `${label}: Rs. ${value} || Booking Count: ${
							anaDataSet[label].monthlyBookingCount[context.label]
						}`;
					},
				},
			},
		};
		return (
			<>
				<div className="ana-line-chart">
					<Line
						options={{ ...options, plugins }}
						data={{
							labels: nepMonth,
							datasets: dataList,
						}}
					/>
				</div>
				<div className="ana-line-title">
					<div className="ana-legend-row">
						{yearList.map((year, i) => (
							<div className="ana-legend" key={i}>
								<div
									className="ana-legend-color"
									style={{ backgroundColor: colors[i] }}
								></div>
								<p>{year}</p>
							</div>
						))}
					</div>
					<p>Booking Trend compaired to past year</p>
				</div>
			</>
		);
	}

	function _renderTrendChart() {
		const dateLabels = yearlyGarph();
		let formatedData = _dataFormat();
		const chartData = [
			{
				label: "Revenue",
				data: formatedData,
				borderColor: "rgba(45, 185, 203 , 1.0)",
				tension: 0.4,
			},
		];

		function yearlyGarph() {
			const fiscal = yearList[0].split(".")?.length > 1;
			let monthLabel = fiscal
				? [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3]
				: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

			let dl = yearList.flatMap((year) =>
				monthLabel.map((month, index) => {
					if (index === 0) {
						let yearArr = year.split(".");

						let first = yearArr[0].slice(2);
						let second = fiscal ? `/${yearArr[1].slice(2)}` : "";

						let yearStr = `${first}${second}`;
						return `${month} - ${yearStr}`;
					} else {
						return `${month}`;
					}
				})
			);
			return dl;
		}
		function _dataFormat() {
			let dl = [];
			yearList.forEach((year) => {
				let arrayData = Object.values(anaDataSet[year].monthlyRevenue);
				dl = dl.concat(arrayData);
			});
			return dl;
		}

		const plugins = {
			legend: {
				display: false,
			},
		};

		return (
			<>
				<div className="ana-chart-scroller">
					<div
						className={`ana-line-chart ${
							chartFullView && "line-full"
						}`}
					>
						<Line
							options={{ ...options, plugins }}
							data={{
								labels: dateLabels,
								datasets: chartData,
							}}
						/>
					</div>
				</div>
				<div className="ana-line-title">
					<div
						className="ana-legend"
						onClick={() => setChartFullView(!chartFullView)}
					>
						<div
							className="ana-legend-color"
							style={{ backgroundColor: "#3498DB" }}
						></div>
						<p>
							{yearList[0]} - {yearList[yearList.length - 1]}
						</p>
					</div>
					<p>Booking trend for all past year</p>
				</div>
			</>
		);
	}

	return (
		<div className="ana-line-container">
			<div className="ana-line-header">
				<h3 className="ana-l-text">Monthly Revenue Breakdowns</h3>
			</div>
			{type === "trend" && _renderTrendChart()}
			{type === "compare" && _renderLineChart()}
		</div>
	);
}

import React, { useState, useEffect } from "react";
import "./settings.style.scss";
// import NavBar from "../../components/navbar/nav-bar/nav-bar.component";
// import SideNavComponent from "../../components/side-navigation/side-navigation.component.jsx";
import ProfileSettings from "../../components/settings/profile-settings/profile-settings.component";
import EmployeeSettings from "../../components/settings/employee-settings/employee-settings.component";
import CustomerSettings from "../../components/settings/customer-settings/customer-settings.component";
import MenuServiceSettings from "../../components/settings/menu-services-settings/menu-service.component";
// import PreferenceSettings from "../../components/settings/preference-settings/preference-settings.component";
import CompanySettings from "../../components/settings/company-settings/company-settings.component";
import { sessionInfo } from "../../global/function";

export default function Settings({ location }) {
	const [settingView, setSettingView] = useState(1);
	const [sessionData, setSessionData] = useState({});

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setSessionData({
					company_role: data.company_role,
					user_info_id: data.user_info_id,
				});
			});
		}

		fetchSessionData();

		if (location.state === undefined) {
			setSettingView(1);
		} else {
			let page_id = location.state.page_id;
			setSettingView(page_id);
		}
	}, [location]);

	const _renderSettingsView = () => {
		let view = [];
		if (settingView === 1) {
			view.push(
				<ProfileSettings
					key={settingView}
					employeeInfoId={sessionData.user_info_id}
					fromSrc="settings"
				/>
			);
		} else if (settingView === 2) {
			view.push(<CompanySettings key={settingView} />);
		} else if (settingView === 3) {
			view.push(
				<MenuServiceSettings
					key={settingView}
					access={sessionData.company_role}
				/>
			);
		} else if (settingView === 5) {
			view.push(<EmployeeSettings key={settingView} />);
		} else if (settingView === 6) {
			view.push(<CustomerSettings key={settingView} />);
		}
		// else if (settingView === 4) {
		// 	view.push(<PreferenceSettings key={settingView} />);
		// }

		return view;
	};

	const _changeView = (count) => {
		setSettingView(count);
	};

	function _renderSettingTypeView(viewId, viewTitle) {
		return (
			<div
				onClick={() => _changeView(viewId)}
				className={
					settingView === viewId
						? "settings-view-title selected"
						: "settings-view-title"
				}
			>
				{viewTitle}
			</div>
		);
	}

	return (
		<div className="main-container">
			<div className="profile-navigation-container">
				<div className="navigator-content">
					{_renderSettingTypeView(1, "Profile Settings")}
					{_renderSettingTypeView(2, "Company Settings")}
					{_renderSettingTypeView(3, "Menu and Services")}
					{/* {_renderSettingTypeView(4, "Preferences")} */}
					{_renderSettingTypeView(5, "Employees")}
					{_renderSettingTypeView(6, "Customers")}
				</div>
			</div>

			{_renderSettingsView()}
		</div>
	);
}

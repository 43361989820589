import {
	FETCH_BOOKING_REQUEST,
	FETCH_BOOKING_REQUEST_SUCCESS,
	FETCH_ADD_BOOKING_SUCCESS,
	FETCH_SINGLE_BOOKING_SUCCESS,
	FETCH_BOOKING_ERROR,
	FETCH_BOOKING_HISTORY_SUCCESS,
	GET_BOOKING_QUEUE_REQUEST,
	GET_BOOKING_QUEUE_SUCCESS,
	GET_BOOKING_QUEUE_ERROR,
	// REVIEW
	FETCH_BOOKING_REVIEW_REQUEST,
	FETCH_BOOKING_REVIEW_REQUEST_SUCCESS,
	FETCH_BOOKING_REVIEW_ERROR,
	// sanat
	SET_BOOKING_SEARCH_PARAMETERS,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import fileDownload from "js-file-download";

import history from "../history";

// for logout
import { fetchLoginError, setDateFixReduxState } from "./login-action";

import {
	sessionInfo,
	isEmptyObject,
	getDateRange,
} from "../../global/function";
import { clearAllBookings } from "./event-save.action";
import { fetchBookingPayment, fetchEventPayment } from "./payment-action";

export const setBookingSearchParameters = (bookingSearchParameters) => {
	return {
		type: SET_BOOKING_SEARCH_PARAMETERS,
		payload: bookingSearchParameters,
	};
};

export const fetchBookingRequest = () => {
	return {
		type: FETCH_BOOKING_REQUEST,
	};
};
export const fetchBookingRequestSuccess = (event) => {
	return {
		type: FETCH_BOOKING_REQUEST_SUCCESS,
		payload: event,
	};
};

export const fetchAddBookingSuccess = (booking) => {
	return {
		type: FETCH_ADD_BOOKING_SUCCESS,
		payload: booking,
	};
};
export const fetchSingleBookingSuccess = (booking) => {
	return {
		type: FETCH_SINGLE_BOOKING_SUCCESS,
		payload: booking,
	};
};

export const fetchBookingError = (error) => {
	return {
		type: FETCH_BOOKING_ERROR,
		payload: error,
	};
};

export const fetchBookingHistorySuccess = (bookingData) => {
	return {
		type: FETCH_BOOKING_HISTORY_SUCCESS,
		payload: bookingData,
	};
};

export const fetchBookingReviewRequest = () => {
	return {
		type: FETCH_BOOKING_REVIEW_REQUEST,
	};
};
export const fetchBookingReviewRequestSuccess = (event) => {
	return {
		type: FETCH_BOOKING_REVIEW_REQUEST_SUCCESS,
		payload: event,
	};
};
export const fetchBookingReviewError = (error) => {
	return {
		type: FETCH_BOOKING_REVIEW_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchBookingError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchBookingError(error.message));
	}
}

export const addBooking = (eventArray) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		let { weekStart, weekEnd } = getDateRange();

		return axios
			.post(
				`${strings.url}bookingInfo/add?booking_from=${weekStart}&booking_to=${weekEnd}&socket=1`, // added date for socket testing purpose
				eventArray,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchAddBookingSuccess(response.data.event));
					dispatch(clearAllBookings());

					let addedBooking = response.data.event;
					history.push({
						pathname: "/booking-event",
						search: `?event_id=${addedBooking.event_id}`,
						state: {
							event_info_id: addedBooking.event_info_id,
							id: addedBooking.event_info_id,
						},
					});
					return response.data;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");

					return response.data;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

//Booking QUEUE by Rajbin
export const getBookingQueueRequest = () => {
	return {
		type: GET_BOOKING_QUEUE_REQUEST,
	};
};
export const getBookingQueueSuccess = (queueBooking) => {
	return {
		type: GET_BOOKING_QUEUE_SUCCESS,
		payload: queueBooking,
	};
};
export const getBookingQueueError = (message) => {
	return {
		type: GET_BOOKING_QUEUE_ERROR,
		payload: message,
	};
};
export const addBookingQueue = (queueBooking) => {
	return async (dispatch) => {
		dispatch(getBookingQueueRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}/bookingQueue/add`, queueBooking, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						getBookingQueueSuccess(response.data.result)
					);

					return response.data.result;
				} else {
					await dispatch(getBookingQueueError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
export const fetchBookingQueue = (bookingInfoID) => {
	return async (dispatch) => {
		dispatch(getBookingQueueRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingQueue/information?booking_info_id=${bookingInfoID}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						getBookingQueueSuccess(response.data.result)
					);
					return response.data.result;
				} else {
					await dispatch(getBookingQueueError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
export const deleteBookingQueue = (
	queueID,
	bookingID,
	venue_info_id,
	type = "single"
) => {
	let urlLink;
	if (type === "single") {
		// urlLink = `/bookingQueue/delete?booking_info_id=""&booking_queue_id=${relatedID}`
		urlLink = `/bookingQueue/delete?booking_info_id=${bookingID}&booking_queue_id=${queueID}&venue_info_id=${venue_info_id}`;
	}
	if (type === "all") {
		urlLink = `/bookingQueue/delete?booking_info_id=${bookingID}&venue_info_id=${venue_info_id}`;
	}
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.delete(`${strings.url}${urlLink}`, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						getBookingQueueSuccess(response.data.result)
					);
				} else {
					await dispatch(getBookingQueueError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
export const swapBooking = (swapBookingData) => {
	return async (dispatch) => {
		dispatch(getBookingQueueRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}bookingQueue/to/booking`, swapBookingData, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					let newBooking = {
						...response.data.newBooking,
						event_id: response.data.event_id,
					};
					return newBooking;
				} else {
					await dispatch(getBookingQueueError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
//-----

export const addBookingToExistingEvent = (eventArray) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		let { weekStart, weekEnd } = getDateRange();

		return axios
			.post(
				`${strings.url}bookingInfo/add/existing/event?booking_from=${weekStart}&booking_to=${weekEnd}&socket=1`,
				eventArray,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					// dispatch(fetchAddBookingSuccess(response.data));
					showToastMessage(response.data.message, "S");
					dispatch(clearAllBookings());

					let val = response.data.result;

					history.push({
						pathname: "/booking-event",
						search: `?event_id=${val.event_id}`,
						state: {
							event_info_id: val.event_info_id,
							id: val.booking_informations[
								val.booking_informations.length - 1
							].booking_info_id,
						},
					});
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// new code by sanat
export const getBookedEvents = (eventInfoId) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}eventInfo/id?event_info_id=${eventInfoId}`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchBookingRequestSuccess(response.data.result)
					);
					return response.data.result;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateEventInfo = (eventInfo) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());

		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}eventInfo/update`, eventInfo, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchBookingRequestSuccess(response.data.result)
					);
					return response.data.result;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					dispatch(clearAllBookings());
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateBooking = (
	bookingDetails,
	path = null,
	eventId = null,
	updateInfo = null
) => {
	bookingDetails?.booking_services?.map((services) => {
		delete services.service;
		return null;
	});
	bookingDetails?.booking_drinks?.map((drinks) => {
		delete drinks.drink;
		return null;
	});
	// let { weekStart, weekEnd } = getDateRange();

	// need to get date range

	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}bookingInfo/update?booking_info_id=${bookingDetails.booking_info_id}&socket=1&updateInfo=${updateInfo}`,
				bookingDetails,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data;
					let res = {
						...data.result,
						availableHallArray: data.availableHalls,
					};
					await dispatch(
						fetchEventPayment(data.result.event_info_id)
					);
					await dispatch(
						fetchBookingPayment(data.result.booking_info_id)
					);
					await dispatch(fetchSingleBookingSuccess(res));
					showToastMessage("Booking Information Updated", "S");
					if (path === "addMenuService") {
						history.push({
							pathname: "/booking-event",
							search: `?event_id=${eventId}`,
							state: {
								event_info_id: data.result.event_info_id,
								booking_info_id: data.result.booking_info_id,
							},
						});
					}
					/*  return true used for updating menu and services */
					// return true;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

// update booking from sidemodal
export const updateBookingSideModal = (
	bookingDetails,
	updateInfo = null
) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}bookingInfo/update?booking_info_id=${bookingDetails.booking_info_id}&socket=1&updateInfo=${updateInfo}`,
				bookingDetails,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchBookingRequestSuccess());
					showToastMessage("Booking Information Updated", "S");

				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};


export const cancelBooking = (bookingDetails) => {
	let { weekStart, weekEnd } = getDateRange();

	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}bookingInfo/cancel?&booking_from=${weekStart}&booking_to=${weekEnd}&socket=1`,
				bookingDetails,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data;
					let res = {
						...data.result,
						availableHallArray: data.availableHalls,
					};
					await dispatch(fetchSingleBookingSuccess(res));

					await dispatch(getBookedEvents(data.result.event_info_id));
					showToastMessage(response.data.message, "S");
					/*  return true used for updating menu and services */
					return true;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const completeBooking = (bookingDetails) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}bookingInfo/complete?socket=1`,
				bookingDetails,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data;
					let res = {
						...data.result,
						availableHallArray: data.availableHalls,
					};
					await dispatch(fetchSingleBookingSuccess(res));

					await dispatch(getBookedEvents(data.result.event_info_id));
					showToastMessage(response.data.message, "S");
					/*  return true used for updating menu and services */
					return true;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const reopenBooking = (
	venue_info_id,
	bookingInfoId,
	hallInfoId,
	bookedTime,
	bookedEngDate,
	a_pax,
	a_rate
) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.url}bookingInfo/reopen?booking_info_id=${bookingInfoId}&hall_info_id=${hallInfoId}&booked_time=${bookedTime}&booked_date_english=${bookedEngDate}`,
				{
					venue_info_id,
					booking_info_id: bookingInfoId,
					hall_info_id: hallInfoId,
					booked_time: bookedTime,
					booked_date_english: bookedEngDate,
					a_pax,
					a_rate,
				},
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					let bookingDetails = {
						...response.data.result,
						availableHallArray: response.data.availableHalls,
					};
					await dispatch(fetchSingleBookingSuccess(bookingDetails));

					await dispatch(getBookedEvents(bookingDetails.event_info_id));
					//return true;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getSingleBookedEvent = (bookingInfoId) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/id?booking_info_id=${bookingInfoId}`,
				{
					"content-type": "application/json",
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data;
					let res = {
						...data.result,
						availableHallArray: data.availableHalls,
					};
					await dispatch(fetchSingleBookingSuccess(res));
					return res;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteBooking = (bookingInfo) => {
	let { weekStart, weekEnd } = getDateRange();
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}bookingInfo/delete/booking?booking_from=${weekStart}&booking_to=${weekEnd}&socket=1`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
					data: bookingInfo,
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					return true;
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getBookingHistory = (limit, page, companyCustomerId) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		dispatch(fetchBookingRequest());
		let cid = companyCustomerId || sessionData.company_employee_id;
		return axios
			.get(
				`${strings.url}bookingInfo/company/employee/id?company_employee_id=${cid}&limit=${limit}&page=${page}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchBookingHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getBookingHistoryByCustomer = (
	company_customer_id,
	limit,
	page
) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/company/customer/id?company_customer_id=${company_customer_id}&limit=${limit}&page=${page}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchBookingHistorySuccess(response.data.result)
					);
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const exportHistory = (
	searchInfo,
	tableName,
	booking_from,
	booking_to
) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();
		let string = "";
		switch (tableName) {
			case "Payment":
				string = `booking_id=${searchInfo.booking_id}&event_id=${searchInfo.event_id}&payment_type=${searchInfo.payment_type}&transaction_type=${searchInfo.transaction_type}&venue_info_id=${searchInfo.venue_info_id}`;
				break;
			case "Booking":
				string = `booking_type=${searchInfo.booking_type}&booking_catering=${searchInfo.booking_catering}&venue_info_id=${searchInfo.venue_info_id}&booking_status=${searchInfo.booking_status}&payment_status=${searchInfo.payment_status}&vat_status=${searchInfo.vat_status}`;
				break;
			default:
				//vat
				string = `vat_bill_number=${searchInfo.vat_bill_number}&event_id=${searchInfo.event_id}`;
				break;
		}

		return axios
			.get(
				`${strings.url}excel/file/download?tableName=${tableName}&booking_from=${booking_from}&booking_to=${booking_to}&customer_fname=${searchInfo.customer_fname}&customer_lname=${searchInfo.customer_lname}&${string}`,
				{
					responseType: "arraybuffer",
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				fileDownload(response.data, `${tableName}.xlsx`);
				showToastMessage("File Downloaded", "S");
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchBookingHistory = (limit, page, searchInfo) => {
	return async (dispatch) => {
		dispatch(fetchBookingRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/history?booking_id=${searchInfo.booking_id}&date_from=${searchInfo.date_from}&date_to=${searchInfo.date_to}
			&customer_fname=${searchInfo.customer_fname}&customer_lname=${searchInfo.customer_lname}
			&booking_type=${searchInfo.booking_type}&booking_catering=${searchInfo.booking_catering}&venue_info_id=${searchInfo.venue_info_id}
			&booking_status=${searchInfo.booking_status}&payment_status=${searchInfo.payment_status}&vat_status=${searchInfo.vat_status}
				&limit=${limit}&page=${page}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {

					await dispatch(
						fetchBookingHistorySuccess(response.data.result)
					);
					await dispatch(setBookingSearchParameters(searchInfo));
					return response.data.result.rows[0];
				} else {
					await dispatch(fetchBookingError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const reviewBooking = (bookingInfoId) => {
	return async (dispatch) => {
		dispatch(fetchBookingReviewRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/review?booking_info_id=${bookingInfoId}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(
						fetchBookingReviewRequestSuccess(response.data.result)
					);
					return response.data.result;
				} else {
					await dispatch(
						fetchBookingReviewError(response.data.message)
					);
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

//paradise special
export const getQuickPaymentBooking = (bookingId) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}bookingInfo/booking/id?booking_id=${bookingId}`,
				{
					"content-type": "application/json",
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data.result;
					return data;
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

//transfe bookings to date and payments to booking
export const transferBooking = (transferData) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();
		await dispatch(fetchBookingRequest())
		return axios
			.put(
				`${strings.url}bookingInfo/transfer`,
				transferData,
				{
					"content-type": "application/json",
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data;
					showToastMessage(response.data.message, "S");
					let res = {
						...data.result,
						availableHallArray: data.availableHalls,
					};
					await dispatch(fetchSingleBookingSuccess(res));
					await dispatch(getBookedEvents(data.result.event_info_id));
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchBookingError(response.data.message))

				}
				return response.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
}

// yearly date fix
export const getFutureBookingsForDateFix = (nextYear) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}bookingInfo/future?nextYear=${nextYear}`, {
				"content-type": "application/json",
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					let data = response.data?.result;
					return data;
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const saveFutureBookingsForDateFix = (bookings) => {
	return async (dispatch) => {
		const sessionData = await sessionInfo();
		return axios
			.put(
				`${strings.url}bookingInfo/future/update`,
				{ bookings },
				{
					"content-type": "application/json",
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					localStorage.setItem("dateFixState", "false");
					showToastMessage(response.data.message, "S");
					await dispatch(setDateFixReduxState(false));
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};



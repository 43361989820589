import React, { useState } from "react";
import "./deactivate-booking.style.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
	getBookedEvents,
	reopenBooking,
	deleteBooking,
	cancelBooking,
} from "../../../redux/actions/booking-action";
import ConfirmationModal from "../../../components/modals/confirmation-modal.component";
import RefundModal from "../../../components/modals/refund.modal";
import ConfirmationModalOnInput from "../../../components/modals/confirmation-modalOnInput.modal";
import TransferPaymentModal from "../../../components/modals/transferPayment.modal";

export default function DeactivateBooking() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { bookingList, bookingData } = useSelector((state) => state.booking);
	const { bookingPayment } = useSelector((state) => state.payment);
	const [deleteBookingModal, setDeleteBookingModal] = useState(false);
	const [reopenBookingModal, setReopenBookingModal] = useState(false);
	const [refundModal, setRefundModal] = useState(false);
	const [transferPayment, setTransferPayment] = useState(false);

	// cancel booking model
	const [cancelBookingModal, setCancelBookingModal] = useState(false);
	const [secModalData, setSecModalData] = useState({
		title: "",
		message: "",
		confirmFor: "",
		pinRequired: false,
	});

	// delete booking modal
	const _handleBookingDeleteConfirmationModalClose = () =>
		setDeleteBookingModal(false);
	const _handleBookingDeleteConfirmationModalShow = () =>
		setDeleteBookingModal(true);

	// cancel booking modal
	const _handleBookingCancelConfirmationModalClose = () =>
		setCancelBookingModal(false);

	// reopen booking modal
	const _handleBookingReopenConfirmationModalClose = () =>
		setReopenBookingModal(false);
	const _handleBookingReopenConfirmationModalShow = () =>
		setReopenBookingModal(true);

	// refund booking modal
	const _handleRefundClose = () => {
		setRefundModal(false);
	};
	const _handleRefundOpen = () => {
		setRefundModal(true);
	};

	//transfer Payments
	const _handleTransferOpen = () => {
		setTransferPayment(true);
	};
	const _handleTransferClose = () => {
		setTransferPayment(false);
	};
	/* function to cancel the booking */
	const handleBookingCancel = (booking_notes) => {
		dispatch(
			cancelBooking({
				booking_info_id: bookingData.booking_info_id,
				venue_info_id: bookingData.venue_info_id,
				booking_notes,
				e_pax: bookingData.e_pax,
				e_rate: bookingData.e_rate,
			})
		);
		_handleBookingCancelConfirmationModalClose();
	};

	// function to reopen booking
	const handleBookingReopen = async () => {
		let h = [];
		bookingData.booking_halls.map((halls) => h.push(halls.hall_info_id));
		let hallsStr = h.toString();

		_handleBookingReopenConfirmationModalClose();
		await dispatch(
			reopenBooking(
				bookingData.venue_info_id,
				bookingData.booking_info_id,
				hallsStr,
				bookingData.booked_time,
				bookingData.booked_date_english,
				bookingData.a_pax,
				bookingData.a_rate
			)
		);
	};

	const deleteBookedEvent = async () => {
		_handleBookingDeleteConfirmationModalClose();
		let deleteInfo = {};

		if (bookingList.booking_informations.length === 1) {
			deleteInfo = {
				event_info_id: bookingList.event_info_id,
			};
		} else {
			deleteInfo = {
				booking_info_id: bookingData.booking_info_id,
				event_info_id: bookingList.event_info_id,
				venue_info_id: bookingData.venue_info_id,
			};
		}

		let deleteVal = await dispatch(deleteBooking(deleteInfo));
		if (deleteVal) {
			if (bookingList.booking_informations.length === 1) {
				history.push({
					pathname: "/dashboard",
				});
			} else {
				const updatedEvent = await dispatch(
					getBookedEvents(bookingList.event_info_id)
				);

				history.push({
					pathname: "/booking-event",
					search: `?event_id=${updatedEvent.event_id}`,
					state: {
						event_info_id: updatedEvent.event_info_id,
						booking_info_id:
							updatedEvent.booking_informations[0]
								.booking_info_id,
					},
				});
			}
		}
	};

	const cancelBookingView = () => {
		if (
			bookingPayment.vat_status !== "none" ||
			bookingData.booking_status === "complete"
		) {
			return (
				<div
					className="delete-booking-wrapper canceled"
					id="cancel-booking"
				>
					<label htmlFor="cancel-booking">
						Do you want to cancel this booking ?
					</label>
					<label htmlFor="cancel-booking" style={{ float: "right" }}>
						{bookingPayment.vat_status !== "none"
							? "Please Delete Vat Record"
							: "Please Reopen Booking"}
					</label>
				</div>
			);
		} else {
			return (
				<div
					className="delete-booking-wrapper canceled"
					id="cancel-booking"
					onClick={() => _handleSecurityModalDetails("cancel")}
				>
					<label htmlFor="cancel-booking">
						Do you want to cancel this booking ?
					</label>
					<label htmlFor="cancel-booking" style={{ float: "right" }}>
						Cancel Booking
					</label>
				</div>
			);
		}
	};

	function _handleSecurityModalDetails(type) {
		let temp = {
			title: "",
			message: "",
			confirmFor: "",
			pinRequired: false,
		};
		switch (type) {
			case "cancel":
				temp.confirmFor = "Cancel Booking";
				temp.title = "Cancel Booking";
				temp.message = "Add a valid reason for cancelling booking";
				setCancelBookingModal(true);
				break;
			default:
				break;
		}
		setSecModalData(temp);
	}

	return (
		<div className="inner-main-view-wrapper">
			{bookingData.booking_status !== "canceled" ? (
				cancelBookingView()
			) : (
				<div className="change-status-button-wrapper">
					<div
						className="delete-booking-wrapper"
						id="delete"
						onClick={_handleBookingDeleteConfirmationModalShow}
					>
						<label htmlFor="delete">
							Do you want to Delete this booking ?
						</label>
						<label htmlFor="delete" style={{ float: "right" }}>
							Delete Booking
						</label>
					</div>
					{bookingPayment.payment_status !== "refund" && (
						<div
							className="delete-booking-wrapper reopen"
							id="reopen"
							onClick={_handleBookingReopenConfirmationModalShow}
						>
							<label htmlFor="reopen">
								Do you want to Reopen this booking ?
							</label>
							<label htmlFor="reopen" style={{ float: "right" }}>
								Reopen Booking
							</label>
						</div>
					)}
				</div>
			)}
			{bookingData.booking_status === "canceled" &&
				bookingPayment.vat_status === "none" &&
				bookingPayment.totalBookingPayment > 0 &&
				bookingPayment.payment_status !== "refund" && (
					<>
						{bookingList.booking_informations.length > 1 && (
							<div
								className="delete-booking-wrapper transfer"
								id="transfer-Payments"
								onClick={_handleTransferOpen}
							>
								<label htmlFor="transfer-Payments">
									Do you want to transfer payment to another
									booking in this event?
								</label>
								<label
									htmlFor="transfer-Payments"
									style={{ float: "right" }}
								>
									Transfer Payment
								</label>
							</div>
						)}
						<div
							className="delete-booking-wrapper canceled"
							id="cancel-booking"
							onClick={_handleRefundOpen}
						>
							<label htmlFor="cancel-booking">
								Do you want to refund paid amount for this
								booking ?
							</label>
							<label
								htmlFor="cancel-booking"
								style={{ float: "right" }}
							>
								Refund Amount
							</label>
						</div>
					</>
				)}

			<ConfirmationModalOnInput
				isVisible={cancelBookingModal}
				handleClose={_handleBookingCancelConfirmationModalClose}
				modalFor={secModalData.confirmFor}
				modalMessage={secModalData.message}
				modalTitle={secModalData.title}
				handleBookingCancel={handleBookingCancel}
			/>
			<ConfirmationModal
				isVisible={deleteBookingModal}
				handleClose={_handleBookingDeleteConfirmationModalClose}
				handleShow={_handleBookingDeleteConfirmationModalShow}
				onClickConfirmation={deleteBookedEvent}
				type="deleteBooking"
			/>
			<ConfirmationModal
				isVisible={reopenBookingModal}
				handleClose={_handleBookingReopenConfirmationModalClose}
				handleShow={_handleBookingReopenConfirmationModalShow}
				onClickConfirmation={handleBookingReopen}
				type="reopenBooking"
			/>
			<RefundModal
				isVisible={refundModal}
				handleClose={_handleRefundClose}
				handleShow={_handleRefundOpen}
			/>
			<TransferPaymentModal
				isVisible={transferPayment}
				handleClose={_handleTransferClose}
				handleShow={_handleTransferOpen}
			/>
		</div>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./booking-history.style.scss";

import Loader from "../../components/loader/loader.component";
import {
	// getBookingHistory,
	searchBookingHistory,
	setBookingSearchParameters,
} from "../../redux/actions/booking-action";
import { useDispatch, useSelector } from "react-redux";

import PaginationReact from "../../components/paginationReact/paginationReact.component";
import ExportCsvModal from "../../components/modals/export-csv-modal";
import BookingHistoryTable from "../../components/tables/booking-history.table";
import SearchBookingHistoryComponent from "../../components/search/search-booking-history";
// import { isEmptyObject } from "../../global/function";
// import { setErrorTrue } from "../../redux/actions/support-action";

export default function BookingHistory() {
	const dataPerPage = 20;
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(1);
	const [showModal, setShowModal] = useState(false);

	const {
		isLoading,
		bookingHistoryCount,
		bookingHistoryList,
		bookingSearchParameters,
	} = useSelector((state) => state.booking);

	useEffect(() => {
		dispatch(
			searchBookingHistory(
				dataPerPage,
				currentPage,
				bookingSearchParameters
			)
		);
	}, [dispatch, currentPage, dataPerPage]);

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentPage(currentPage);
	};

	const toggleShowModal = () => {
		setShowModal(true);
	};

	const toggleHideModal = () => {
		setShowModal(false);
	};

	const searchBooking = (searchData) => {
		setCurrentPage(1);
		dispatch(searchBookingHistory(dataPerPage, 1, searchData));
	};
	const resetSearch = () => {
		let search = {
			booking_id: "",
			date_from: "",
			date_to: "",
			customer_fname: "",
			customer_lname: "",
			booking_type: "",
			booking_catering: "",
			venue_info_id: "",
			booking_status: "",
			payment_status: "",
			vat_status: "",
		};
		dispatch(setBookingSearchParameters(search));
		dispatch(searchBookingHistory(dataPerPage, 1, search));
		setCurrentPage(1);
	};

	return (
		<div className="main-container">
			<div className="inner-container full mt-44px">
				{isLoading && <Loader />}

				<SearchBookingHistoryComponent
					searchBooking={searchBooking}
					resetSearch={resetSearch}
				/>
				<hr className="default-hr" />
				<div className="booking-history-content">
					<span className="booking-history-title">
						Booking History
					</span>
					{bookingHistoryList.length > 0 ? (
						<BookingHistoryTable
							bookingList={bookingHistoryList}
							filterObject={{
								dataPerPage,
								currentPage,
								bookingSearchParameters,
							}}
						/>
					) : (
						<div className="no-data-display">
							<i className="far fa-frown" />
							No Data
						</div>
					)}

					<div className="table-controller-container">
						<PaginationReact
							totalCount={bookingHistoryCount}
							itemsPerPage={dataPerPage}
							onPageClick={onPageChange}
							forcePage={currentPage - 1}
						/>

						<button
							className="button-primary float-right md:hidden"
							onClick={toggleShowModal}
						>
							Export CSV
						</button>
					</div>
				</div>
				<ExportCsvModal
					handleShow={toggleShowModal}
					handleHide={toggleHideModal}
					isVisible={showModal}
					filterData={bookingSearchParameters}
					tableName="Booking"
				/>
			</div>
		</div>
	);
}

import {
	FETCH_NOTIFICATION_REQUEST,
	FETCH_NOTIFICATION_SUCCESS,
	FETCH_ACTIVITY_SUCCESS,
	FETCH_NOTIFICATION_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
	notificationList: [],
	unseenNotificationList: [],
	activity: [],
	count: 0,
	error: "",
};

export default function notificationReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_NOTIFICATION_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case FETCH_NOTIFICATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				notificationList: action.payload.result.notifications || [],
				unseenNotificationList:
					action.payload.unseenNotification.notifications || [],
				error: "",
			};
		case FETCH_ACTIVITY_SUCCESS:
			return {
				...state,
				isLoading: false,
				activity: action.payload.result.rows,
				count: action.payload.result.count,
				error: "",
			};
		case FETCH_NOTIFICATION_ERROR:
			return {
				isLoading: false,
				error: action.payload,
				...state,
			};
		default:
			return state;
	}
}

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./modal.style.scss";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

export default function ChangeVenueStatusModal(props) {
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            borderRadius: "10px",
        },
        h2: {
            color: "red",
        },
    };

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [deleteView, setDeleteView] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState(false);

    useEffect(() => {
        setDeleteView(false);
        setDeleteFlag(false);

        if (props.modalInfo.type === "venue") {
            if (props.modalInfo.count >= 2) {
                setDeleteFlag(true);
            }
            if (props.modalInfo.status === "inactive") {
                setTitle("Deactivate " + props.modalInfo.name);
                setMessage(
                    "Are you sure you want to deactivate " +
                        props.modalInfo.name +
                        " ?"
                );
            } else {
                setTitle("Activate " + props.modalInfo.name);
                setMessage(
                    "Are you sure you want to activate " +
                        props.modalInfo.name +
                        " ?"
                );
            }
        } else if (props.modalInfo.type === "hall") {
            if (props.modalInfo.status === "inactive") {
                setTitle("Deactivate " + props.modalInfo.hallName);
                setMessage(
                    "Are you sure you want to deactivate " +
                        props.modalInfo.hallName +
                        " at " +
                        props.modalInfo.venueName +
                        "? Deactivation is temporary and you can re-activate the Hall at anytime.?"
                );
            } else {
                setTitle("Activate " + props.modalInfo.hallName);
                setMessage(
                    "Are you sure you want to activate " +
                        props.modalInfo.hallName +
                        " ?"
                );
            }
            if (props.modalInfo.count >= 2) {
                setDeleteFlag(true);
            }
        }
    }, [props.modalInfo, deleteFlag]);

    function onClickYes() {
        if (props.modalInfo.type === "venue") {
            props.onClickYes(props.modalInfo.status, props.modalInfo.id);
        } else if (props.modalInfo.type === "hall") {
            props.onClickYes(
                props.modalInfo.status,
                props.modalInfo.hallInfoId,
                props.modalInfo.venueInfoId
            );
        }

        props.handleClose();
    }
    function onClickDelete() {
        if (props.modalInfo.type === "venue") {
            props.onClickDelete(props.modalInfo.id);
        } else if (props.modalInfo.type === "hall") {
            props.onClickDelete(props.modalInfo.hallInfoId);
        }

        props.handleClose();
    }

    function returnDeleteView() {
        if (props.modalInfo.type === "venue") {
            return (
                <div className="main-modal-wrapper bg-default">
                    <div className="modal-title delete">Delete Venue</div>
                    <div className="modal-message delete">{`Are you sure you want to delete ${props.modalInfo.name} Venue? 
                    This will permanently remove ${props.modalInfo.name} venue and you will not be able to retrieve it back.
                     This will also delete all the bookings associated with this venue.`}</div>

                    <div className="modal-two-button-wrapper">
                        <button
                            className="button-primary"
                            onClick={props.handleClose}
                        >
                            No
                        </button>
                        <button
                            className="button-primary"
                            onClick={onClickDelete}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            );
        } else if (props.modalInfo.type === "hall") {
            return (
                <div className="main-modal-wrapper bg-default">
                    <div className="modal-title delete">Delete Hall</div>
                    <div className="modal-message delete">{`Are you sure you want to delete ${props.modalInfo.hallName} Hall from ${props.modalInfo.venueName} Venue ? 
                    This will permanently remove ${props.modalInfo.hallName} Hall and you will not be able to retrieve it back. This will also
                     delete all the bookings associated with this hall.`}</div>

                    <div className="modal-two-button-wrapper">
                        <button
                            className="button-primary"
                            onClick={props.handleClose}
                        >
                            No
                        </button>
                        <button
                            className="button-primary"
                            onClick={onClickDelete}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            );
        }
    }
    const onClickChangeDeleteView = () => {
        setDeleteView(true);
    };

    return (
        <Modal
            ariaHideApp={false}
            isOpen={props.isVisible}
            onAfterOpen={props.handleShow}
            onRequestClose={props.handleClose}
            style={customStyles}
            contentLabel="Example Modal"
        >
            {deleteView ? (
                returnDeleteView()
            ) : (
                <div className="main-modal-wrapper bg-default">
                    <div className="modal-title">{title}</div>
                    <div className="modal-message">{message}</div>

                    <div
                        className={
                            props.modalInfo.type === "venue"
                                ? "modal-three-button-wrapper"
                                : "modal-two-button-wrapper"
                        }
                    >
                        {props.modalInfo.type === "venue" ? (
                            <>
                                <button
                                    className={`button-primary delete ${
                                        deleteFlag === true ? "show" : "hide"
                                    }`}
                                    onClick={onClickChangeDeleteView}
                                    disabled={!deleteFlag}
                                >
                                    Delete
                                </button>
                                <div>
                                    <button
                                        className="button-primary"
                                        onClick={props.handleClose}
                                    >
                                        No
                                    </button>
                                    <button
                                        className={`button-primary ${props.modalInfo.status}`}
                                        onClick={onClickYes}
                                    >
                                        {props.modalInfo.status === "inactive"
                                            ? "Deactivate"
                                            : "Activate"}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <button
                                    className="button-primary"
                                    onClick={props.handleClose}
                                >
                                    No
                                </button>
                                <button
                                    className={`button-primary ${props.modalInfo.status}`}
                                    onClick={onClickYes}
                                >
                                    {props.modalInfo.status === "inactive"
                                        ? "Deactivate"
                                        : "Activate"}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
}

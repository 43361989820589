import React from "react";
import moment from "moment";
import "./notification.component.scss";
function Notification({ list, view }) {
	return (
		<div
			className={`notification-wrapper ${
				list.notification_status !== "seen" && "unseen"
			}`}
			onClick={view}
		>
			<div className="notification-header-container">
				<label className="notification-header-label text-capitalize">
					{list.venueName}
				</label>
				<label className="notification-header-label date">
					{moment(list.created_at).format(
						"ddd, DD MMMM YYYY, HH:mm:ss"
					)}
				</label>
			</div>

			<label className="notification-message">
				{list?.updateInfo && list.updateInfo.length !== 0
					? `${
							list.employeeName
					  } has ${list?.updateInfo?.toString()} of
				${list.classification} ID #${list.id}`
					: `${list.employeeName} has ${list.action} ${list.classification} with
				ID #${list.id}`}
			</label>
		</div>
	);
}

export default Notification;

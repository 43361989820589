import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { changPin } from "../../redux/actions/company-setting.action";
import "./open-shop.style.scss";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const ChangePinModal = (props) => {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};
	const dispatch = useDispatch();
	const [clearValues, setClearValues] = useState(true);
	const [oldPin, setOldPin] = useState([]); // old pin
	//new pin values and confirm values
	const [values, setValues] = useState([]);
	const [confValues, setConfValues] = useState([]);
	const [check, setCheck] = useState({});
	//ref for fields
	const oldPinRef = useRef(null);
	const confRef = useRef(null);
	const ref = useRef(null);

	useEffect(() => {
		setOldPin(Array(6).fill(""));
		setValues(Array(6).fill(""));
		setConfValues(Array(6).fill(""));
		setCheck({
			length: false,
			number: false,
			special: false,
			upper: false,
			lower: false,
			match: false,
		});
	}, [props]);

	useEffect(() => {
		if (!clearValues) {
			setValues(Array(6).fill(""));
			setConfValues(Array(6).fill(""));
			setClearValues(true);
		}
	}, [clearValues]);

	//focus next after input and reset check states
	const _handleChange = (e, type) => {
		let currentId = parseInt(e.target.id[1]);
		let tempArray = [];
		//checks and manages which input block to focus on
		function blockFocusChanger(reference, checkRequired = true) {
			if (e.target.value !== "") {
				if (currentId !== 5) {
					reference[currentId + 1].focus();
				}
			} else {
				if (currentId !== 0) {
					reference[currentId - 1].focus();
					if (checkRequired) {
						setCheck({
							length: false,
							number: false,
							special: false,
							upper: false,
							lower: false,
							match: false,
						});
					}
				}
			}
		}
		//check what input block set in triggering
		switch (type) {
			case "oldPin":
				tempArray = oldPin;
				blockFocusChanger(oldPinRef.current.children, false);
				//sets new input value
				tempArray[currentId] = e.target.value;
				setOldPin(tempArray);
				break;
			case "newPin":
				tempArray = values;
				blockFocusChanger(ref.current.children);
				//sets new input value
				tempArray[currentId] = e.target.value;
				setValues(tempArray);
				break;
			case "confirmPin":
				tempArray = confValues;
				blockFocusChanger(confRef.current.children);
				//sets new input value
				tempArray[currentId] = e.target.value;
				setConfValues(tempArray);
				break;
			default:
				break;
		}
	};

	const _handleSubmit = () => {
		function highlightBlocks() {
			for (var i = 0; i < values.length; i++) {
				if (values[i] !== confValues[i]) {
					ref.current.children[i].style.border = "2px solid red";
					confRef.current.children[i].style.border = "2px solid red";
				} else {
					ref.current.children[i].style.border = "none";
					confRef.current.children[i].style.border = "none";
				}
			}
		}
		if (values.join("") === oldPin.join("")) {
			let a = Array(6).fill("");
			setClearValues(false);
			setValues(a);
			setConfValues(a);
			return;
		} else {
			setClearValues(true);
		}
		//check pin state
		let re_num = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,}$/;
		let re_spe = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
		let re_lower = /^(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{6,}$/;
		let re_upper = /^(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,}$/;
		let pin = values.join("");
		let tempPin = confValues.join("");
		if (pin.length === 6) {
			let checks;
			if (pin === tempPin) {
				checks = {
					length: true,
					number: re_num.test(pin),
					special: re_spe.test(pin),
					upper: re_upper.test(pin),
					lower: re_lower.test(pin),
					match: true,
				};
			} else {
				checks = {
					length: true,
					number: re_num.test(pin),
					special: re_spe.test(pin),
					upper: re_upper.test(pin),
					lower: re_lower.test(pin),
					match: false,
				};
			}
			highlightBlocks();
			setCheck(checks);
			//check if all conditions met
			if (
				checks.length &&
				checks.number &&
				checks.special &&
				checks.upper &&
				checks.lower &&
				checks.match
			) {
				dispatch(changPin({ old_key: oldPin.join(""), new_key: pin }));
				props.handleClose();
			}
		}
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			shouldCloseOnOverlayClick={false}
			contentLabel="Example Modal"
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">{props.modalTitle}</div>
				</div>
				<div className="openshop new-pin">
					<p>{props.modalMessage}</p>
				</div>
				<div className="openshop new-pin">
					<p>Enter Old Pin</p>
				</div>
				<div className="openshop new-pin" ref={oldPinRef}>
					{oldPin.map((val, index) => (
						<input
							className="inputbox small"
							key={index}
							id={"o" + index}
							type={"text"}
							placeholder={""}
							defaultValue={val}
							maxLength={1}
							onFocus={(e) => {
								e.target.select();
							}}
							onChange={(e) => _handleChange(e, "oldPin")}
						></input>
					))}
				</div>
				<hr />
				<div className="openshop new-pin">
					<p>Enter New Pin</p>
				</div>
				{clearValues && (
					<div className="openshop new-pin" ref={ref}>
						{values.map((val, index) => (
							<input
								className="inputbox small"
								key={index}
								id={"i" + index}
								type={"text"}
								placeholder={""}
								defaultValue={val}
								maxLength={1}
								onFocus={(e) => {
									e.target.select();
								}}
								onChange={(e) => _handleChange(e, "newPin")}
							></input>
						))}
					</div>
				)}
				<div className="openshop new-pin">
					<p>Confirm New Pin</p>
				</div>
				{clearValues && (
					<div className="openshop new-pin" ref={confRef}>
						{confValues.map((val, index) => (
							<input
								className="inputbox small"
								key={index}
								id={"c" + index}
								type={"text"}
								placeholder={""}
								defaultValue={val}
								maxLength={1}
								onFocus={(e) => {
									e.target.select();
								}}
								onChange={(e) => _handleChange(e, "confirmPin")}
							></input>
						))}
					</div>
				)}
				{clearValues && (
					<div className="open-shop-warn">
						<div>
							<i
								className={
									check.match
										? "fas fa-thumbs-up green"
										: "fas fa-thumbs-down red"
								}
							></i>
							<p>Match</p>
						</div>
						<div>
							<i
								className={
									check.length
										? "fas fa-thumbs-up green"
										: "fas fa-thumbs-down red"
								}
							></i>
							<p>length</p>
						</div>
						<div>
							<i
								className={
									check.number
										? "fas fa-thumbs-up green"
										: "fas fa-thumbs-down red"
								}
							></i>
							<p>Number</p>
						</div>
						<div>
							<i
								className={
									check.special
										? "fas fa-thumbs-up green"
										: "fas fa-thumbs-down red"
								}
							></i>
							<p>Special</p>
						</div>
						<div>
							<i
								className={
									check.upper
										? "fas fa-thumbs-up green"
										: "fas fa-thumbs-down red"
								}
							></i>
							<p>Uppercase</p>
						</div>
						<div>
							<i
								className={
									check.lower
										? "fas fa-thumbs-up green"
										: "fas fa-thumbs-down red"
								}
							></i>
							<p>Lowercase</p>
						</div>
					</div>
				)}

				<div className="open-shop-buttons">
					<button
						className="button-primary"
						onClick={props.handleClose}
					>
						Cancel
					</button>
					<button
						className="button-primary"
						onClick={() => _handleSubmit()}
					>
						Change Pin
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ChangePinModal;

import React, { useEffect, useState } from "react";
import "./booking-smv-form.style.scss";

// form handle
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// global strings and functions
import strings from "../../../global/strings";

// components
import FormTextInputComponent from "../../common/text-input/form-text-input.component";
import FormTextareaInputComponent from "../../common/text-input/form-textarea-input.component";
import EnNpDatePicker from "../../common/en-np-datepicker/en-np-datepicker.component";

// redux
import { useDispatch } from "react-redux";
import { recordVatDetails } from "../../../redux/actions/payment-action";

const vatSchema = yup.object().shape({
	a_pax: yup
		.number()
		.required("Pax is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	vat_amount: yup
		.number()
		.required("Vat Amount is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	total_bill: yup
		.number()
		.required("Total bill is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	issue_date: yup.string().required("Issue Date is required"),
	vat_bill_number: yup
		.number()
		.required("VAT Bill Number is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
});

export default function BookingVATForm({
	booking,
	closeAccordion,
	onAfterSuccessCall,
}) {
	const [vatDetails, setVatDetails] = useState({});

	const dispatch = useDispatch();

	const { register, handleSubmit, reset, errors, setValue } = useForm({
		mode: "onSubmit",
		defaultValues: {},
		resolver: yupResolver(vatSchema),
	});

	useEffect(() => {
		setVatDetails({
			bookings: [
				{
					booking_info_id: booking?.booking_info_id,
					e_rate: booking?.e_rate,
					e_pax: booking?.e_pax,
				},
			],
			event_info_id: booking?.event_info_id,
			venue_info_id: booking?.venue_info_id,
			a_pax: null,
			a_rate: null,
			a_service: null,
			vat_amount: null,
			total_bill: null,
			total_cost: null,
			issue_date: "",
		});
	}, [booking]);

	const adjustVat = () => {
		let a_pax,
			a_rate,
			a_service,
			vat_amount,
			paxForVatAmount,
			tempDecimal,
			total_cost,
			total_bill;

		a_rate = parseFloat(vatDetails?.a_rate || 0);
		a_pax = parseFloat(vatDetails?.a_pax || 0);
		a_service = parseFloat(vatDetails?.a_service || 0);

		switch (vatDetails.flag) {
			case "vat_amount":
				vat_amount = parseFloat(vatDetails?.vat_amount || 0);
				paxForVatAmount = (vat_amount / 0.13 - a_service) / a_rate;
				tempDecimal = paxForVatAmount % 1;

				if (tempDecimal === 0) {
					a_pax = paxForVatAmount;
				} else {
					a_rate = parseFloat(
						(vat_amount / (a_pax * 0.13))?.toFixed(2)
					);
				}

				total_cost = parseFloat(a_pax * a_rate + a_service);
				vat_amount = parseFloat((total_cost * 0.13)?.toFixed(2));
				total_bill = parseFloat(total_cost + vat_amount);

				setVatDetails({
					...vatDetails,
					a_rate,
					a_pax,
					a_service,
					vat_amount,
					total_bill,
					total_cost,
				});
				break;
			case "total_bill":
				total_bill = parseFloat(vatDetails.total_bill);
				total_cost = parseFloat((total_bill / 1.13).toFixed(2));
				vat_amount = parseFloat(total_cost * 0.13);
				paxForVatAmount = (total_cost - a_service) / a_rate;
				tempDecimal = paxForVatAmount % 1;

				if (tempDecimal === 0) {
					a_pax = paxForVatAmount;
				} else {
					a_rate = parseFloat(
						((total_cost - a_service) / a_pax)?.toFixed(2)
					);
				}

				vat_amount = parseFloat(
					((a_pax * a_rate + a_service) * 0.13)?.toFixed(2)
				);
				total_cost = parseFloat(total_cost?.toFixed(2));
				total_bill = total_cost + vat_amount;

				setVatDetails({
					...vatDetails,
					a_rate,
					a_pax,
					a_service,
					vat_amount,
					total_bill,
					total_cost,
				});
				break;
			case "a_pax":
				total_cost = parseFloat(a_pax * a_rate + a_service);
				vat_amount = parseFloat(total_cost * 0.13);
				total_bill = parseFloat(total_cost + vat_amount);

				setVatDetails({
					...vatDetails,
					a_rate,
					a_pax,
					a_service,
					vat_amount: parseFloat(vat_amount?.toFixed(2)),
					total_bill: parseFloat(total_bill?.toFixed(2)),
					total_cost: parseFloat(total_cost?.toFixed(2)),
				});
				break;
			default:
				break;
		}
	};

	const handleAddBookingVat = async (vatData) => {
		let vatInfo = {
			...vatData,
			...vatDetails,
			a_pax: +vatData.a_pax,
			a_rate: +vatData.a_rate,
			booking_vat_status: "c_vat",
		};
		// console.log(vatInfo);

		const response = await dispatch(recordVatDetails(vatInfo));
		if (response?.success) {
			reset();

			closeAccordion();
		}
		onAfterSuccessCall(booking.booked_date_english);
	};
	return (
		<div>
			{booking?.vat_status === "none" ? (
				<div className="booking-smv-form-container">
					<div className="bsmv-form-row">
						<div className="bsmv-form-row">
							<FormTextInputComponent
								variant="bsmv-form-input"
								inputvariant="bsmv-form-input-field"
								name="a_pax"
								type="number"
								placeholder="Actual Pax"
								label="Actual Pax"
								value={vatDetails?.a_pax || ""}
								error={errors?.a_pax?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										flag: "a_pax",
										a_pax: e.target.value,
									})
								}
							/>
							<FormTextInputComponent
								variant="bsmv-form-input"
								inputvariant="bsmv-form-input-field"
								name="a_rate"
								type="number"
								placeholder="Actual Rate"
								label="Actual Rate"
								value={vatDetails?.a_rate || ""}
								error={errors?.a_rate?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										a_rate: e.target.value,
									})
								}
							/>
							<FormTextInputComponent
								variant="bsmv-form-input"
								inputvariant="bsmv-form-input-field"
								name="a_service"
								type="number"
								placeholder="Actual Service"
								label="Actual Service"
								value={vatDetails?.a_service || ""}
								error={errors?.a_service?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										a_service: e.target.value,
									})
								}
							/>
						</div>
						<div className="bsmv-form-row">
							<FormTextInputComponent
								variant="bsmv-form-input"
								inputvariant="bsmv-form-input-field"
								placeholder="Total Cost"
								label="Total Cost"
								value={vatDetails?.total_cost || ""}
								error={errors?.total_cost?.message}
								disabled={true}
							/>
							<FormTextInputComponent
								variant="bsmv-form-input"
								inputvariant="bsmv-form-input-field"
								name="vat_amount"
								type="number"
								placeholder="VAT Amount"
								label="VAT Amount"
								value={vatDetails?.vat_amount || ""}
								error={errors?.vat_amount?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										flag: "vat_amount",
										vat_amount: e.target.value,
									})
								}
							/>
							<FormTextInputComponent
								variant="bsmv-form-input"
								inputvariant="bsmv-form-input-field"
								name="total_bill"
								type="number"
								placeholder="Total Bill"
								label="Total Bill"
								value={vatDetails?.total_bill || ""}
								error={errors?.total_bill?.message}
								ref={register}
								onChange={(e) =>
									setVatDetails({
										...vatDetails,
										flag: "total_bill",
										total_bill: e.target.value,
									})
								}
							/>
						</div>
					</div>

					<div
						className="check-button"
						onClick={adjustVat}
						disabled={vatDetails.a_pax * vatDetails.a_rate <= 0}
					>
						<b>Re-calculate VAT</b>
						<i className="fas fa-calculator"></i>
					</div>

					<div className="bsmv-form-row">
						<FormTextareaInputComponent
							label="VAT RECORD GUIDE"
							variant="bsmv-form-input max-h-[64px] md:max-h-[84px]"
							value={strings.vatAdjustmentGuide}
							disabled={true}
						/>
					</div>

					<div className="bsmv-form-row">
						<EnNpDatePicker
							ref={register}
							name="issue_date"
							label="Date of Issue"
							type="date"
							onChange={(fieldName, fieldValue) => {
								setVatDetails((prev) => ({
									...prev,
									issue_date: fieldValue,
								}));
								setValue(fieldName, fieldValue);
							}}
							value={vatDetails?.issue_date || ""}
							error={errors?.issue_date?.message}
						/>
						<FormTextInputComponent
							variant="bsmv-form-input"
							inputvariant="bsmv-form-input-field"
							placeholder="VAT Bill Number"
							label="VAT Bill Number"
							name="vat_bill_number"
							ref={register}
							error={errors?.vat_bill_number?.message}
							type="number"
						/>
						<FormTextInputComponent
							variant="bsmv-form-input"
							inputvariant="bsmv-form-input-field"
							label="Client VAT/PAN"
							placeholder="Client VAT/PAN"
							name="customer_vat_number"
							ref={register}
							type="text"
						/>
					</div>

					<div className="bsmv-form-buttons">
						<button
							className="bsmv-button"
							onClick={handleSubmit(handleAddBookingVat)}
						>
							Add VAT Record
						</button>
					</div>
				</div>
			) : (
				<div className="details-message">
					VAT has been recorded for this booking
				</div>
			)}
		</div>
	);
}

import React from "react";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { updatePassword } from "../../../redux/actions/login-action";

import { useDispatch } from "react-redux";
import strings from "../../../global/strings";
import "../../../components/modals/modal.style.scss";

import Modal from "react-modal";
import FormInput from "../../../components/common/text-input/form-text-input.component";
import { sessionInfo } from "../../../global/function";

const passwordSchema = yup.object().shape({
	oldPassword: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
});

export default function UpdatePasswordModal(props) {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(passwordSchema),
	});

	const _saveData = async (passwordInfo) => {
		const sessionData = await sessionInfo();

		await dispatch(
			updatePassword({
				oldPassword: passwordInfo.oldPassword,
				password: passwordInfo.password,
				user_info_id: sessionData.user_info_id,
			})
		);
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
	};

	const updatePasswordForm = () => {
		return (
			<Modal
				isOpen={props.isVisible}
				onAfterOpen={props.handleShow}
				onRequestClose={props.handleClose}
				style={customStyles}
			>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">
							{strings.updatePasswordModal.title}
						</div>
					</div>

					<div className="form-body">
						<div
							className="form-description-title"
							style={{ color: "red", textAlign: "center" }}
						>
							{strings.updatePasswordModal.message}
						</div>
						<div className="form-input-container">
							<FormInput
								type="password"
								label="Old Password"
								placeholder="Enter Old Password"
								variant="form-lg"
								ref={register}
								name="oldPassword"
								error={errors?.oldPassword?.message}
							/>
						</div>
						<div className="form-input-container">
							<FormInput
								type="password"
								label="New Password"
								placeholder="Enter New Password"
								variant="form-lg"
								ref={register}
								name="password"
								error={errors?.password?.message}
							/>
							<FormInput
								type="password"
								label="Confirm New Password"
								placeholder="Confirm New Password"
								variant="form-lg"
								ref={register}
								name="confirm_password"
								error={errors?.confirm_password?.message}
							/>
						</div>

						<div className="form-input-container"></div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={props.handleClose}
						>
							Cancel
						</button>
						<button
							className="button-primary"
							onClick={handleSubmit(_saveData)}
						>
							Update
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	return <div className="signup-main-container">{updatePasswordForm()}</div>;
}

import React, { useEffect, useState } from "react";
import {
	changeEmployeeVenueStatus,
	logoutEmployee,
	resendEmailVerification,
} from "../../redux/actions/employee-setting.action";
import FormTextareaInputComponent from "../common/text-input/form-textarea-input.component";
import TextInputWithErrorComponent from "../common/text-input/text-input-with-error.component";
import { useDispatch } from "react-redux";
import { sessionInfo } from "../../global/function";

function ProfileComponent({
	type,
	details,
	showImageModal,
	showDeleteModal,
	editFlag,
	editable,
	transferOwnershipModal,
	employmentChange,
	// change password added by sanat
	showChangePasswordModal,
}) {
	const [id, setId] = useState("");
	const [image, setImage] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [status, setStatus] = useState("");
	const [mobile, setMobile] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [city, setCity] = useState();
	const [country, setCountry] = useState();
	const [dob, setDob] = useState();
	const [gender, setGender] = useState();
	const [pan, setPan] = useState();
	const [vat, setVat] = useState();
	const [identificationType, setIdentificationType] = useState();
	const [identificationNumber, setIdentificationNumber] = useState();

	// emp
	const [role, setRole] = useState();
	//customer
	const [customerType, setCustomerType] = useState();
	const [customerNotes, setCustomerMotes] = useState();
	const dispatch = useDispatch();
	const [company_role, setCompanyRole] = useState();
	const [user_id, setUserId] = useState();

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setUserId(data.user_id);
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();

		if (type === "customer") {
			setId(details.customer_id);
			setImage(details?.customer_image);
			setCustomerType(details.customer_type);
			setStatus("active");
			setFirstName(details.customer_fname);
			setLastName(details.customer_lname);
			setEmail(details.customer_email);
			setMobile(details.customer_mobile);
			setPhone(details.customer_phone);
			setAddress(details.customer_address);
			setCity(details.customer_city);
			setCountry(details.customer_country);
			setDob(details.customer_dob);
			setGender(details.customer_gender);
			setCustomerMotes(details.customer_notes);
			setIdentificationType(details?.identification?.identification_type);
			setIdentificationNumber(
				details?.identification?.identification_number
			);
			setPan(details?.identification?.pan_number);
			setVat(details?.identification?.vat_number);
		} else if (type === "user") {
			setRole(details.userData.company_employees[0].company_role);
			setId(details.userData.user_id);
			setImage(details.userData.user_image);
			setCustomerType(details.customer_type);
			setStatus(
				details.userData.company_employees[0].company_employee_status
			);
			setFirstName(details.userData.first_name);
			setLastName(details.userData.last_name);
			setEmail(details.userData.user_email);
			setMobile(details.userData.user_mobile);
			setPhone(details.userData.user_phone);
			setAddress(details.userData.user_address);
			setCity(details.userData.user_city);
			setCountry(details.userData.user_country);
			setDob(details.userData.user_dob);
			setGender(details.userData.user_gender);
			// setCustomerMotes(details.customer_notes);
			setIdentificationType(
				details?.userData?.identification?.identification_type
			);
			setIdentificationNumber(
				details?.userData?.identification?.identification_number
			);
			setPan(details?.userData?.identification?.pan_number);
			setVat(details?.userData?.identification?.vat_number);
		}
	}, [type, details]);

	function sendEmailVerification() {
		let val = {
			user_info_id: details.userData.user_info_id,
			user_email: details.userData.user_email,
		};
		dispatch(resendEmailVerification(val));
	}

	const empVenueDetails = () => {
		return (
			<div className="main-inner-container">
				<label htmlFor="" className="mb-15px">
					Employment
				</label>
				<div className="flex gap-12px flex-wrap flex-row">
					{details.userData.company_employees[0].venue_employees
						.length >= 1 ? (
						details.userData.company_employees[0].venue_employees.map(
							(venue) => (
								<div
									className={`bodered h-52 w-135 flex flex-col py-10px px-10px text-center capitalize text-fontWhite rounded-sm cursor-pointer ${
										venue.venue_employee_status === "active"
											? "bg-buttonSuccess"
											: "bg-buttonFailed"
									}`}
									key={venue.venue_employee_id}
									onClick={() =>
										changeEmpVenueStatus(
											venue.venue_employee_status,
											venue.venue_employee_id
										)
									}
								>
									<label
										htmlFor=""
										className="text-sm cursor-pointer"
									>
										{venue.venue_info.venue_name}
									</label>
									<label
										htmlFor=""
										className="text-md cursor-pointer"
									>
										{company_role !== "viewer"
											? venue.venue_role
											: "Staff"}
									</label>
								</div>
							)
						)
					) : (
						<div>No Venue</div>
					)}
				</div>
			</div>
		);
	};

	const changeEmpVenueStatus = async (status, venueEmpId) => {
		let emp_data = {
			user_info_id: details.userData.user_info_id,
			venue_employee_id: venueEmpId,
			venue_employee_status: status === "active" ? "inactive" : "active",
		};
		if (editable && employmentChange) {
			await dispatch(changeEmployeeVenueStatus(emp_data));
		}
	};

	return (
		<div className="inner-container mt-5">
			<div className="main-inner-container">
				<div
					className="h-255 bg-default flex justify-center items-center rounded-lg overflow-hidden"
					onClick={showImageModal}
				>
					{image ? (
						<img
							className="imagefixing"
							src={image}
							alt="profile pic"
						/>
					) : (
						<i className="fas fa-user-alt fa-10x"></i>
					)}
				</div>
			</div>
			<div className="main-inner-container">
				<div className="flex flex-auto space-x-15px h-119 max-w-full mb-4">
					<div className="space-y-15px capitalize">
						<div className="bg-default h-52 w-135 flex flex-col py-10px px-10px text-right rounded-sm">
							<label
								htmlFor=""
								className="text-fontSemiDark text-sm capitalize"
							>
								{type} ID
							</label>
							<label htmlFor="" className="text-fontDark text-md">
								{id}
							</label>
						</div>
						<div className="bg-default h-52 w-135 flex flex-col py-10px px-10px text-right rounded-sm">
							<label
								htmlFor=""
								className="text-fontSemiDark text-sm"
							>
								{type === "customer" ? "Customer Type" : "Role"}
							</label>
							<label htmlFor="" className="text-fontDark text-md">
								{type === "customer"
									? customerType
									: company_role !== "viewer"
									? role
									: "Employee"}
							</label>
						</div>
					</div>
					<div className="capitalize">
						<div className="bg-default h-119 w-135 flex flex-col justify-between pt-10px pb-24px text-center rounded-sm">
							<label
								htmlFor=""
								className="text-fontSemiDark text-sm"
							>
								{type} Status
							</label>
							<label htmlFor="" className="text-fontDark text-md">
								{status}
							</label>
						</div>
					</div>
				</div>
				<div className="mt-30px">
					<TextInputWithErrorComponent
						variant={"form-md"}
						label="First Name"
						value={firstName || "-"}
						disabled
					/>
					<TextInputWithErrorComponent
						variant={"form-md"}
						label="Last Name"
						value={lastName || "-"}
						disabled
					/>
				</div>
			</div>
			<div className="main-inner-container">
				<div className="flex ">
					<TextInputWithErrorComponent
						variant={"form-md"}
						label="Mobile"
						value={mobile || "-"}
						disabled
					/>
					<TextInputWithErrorComponent
						variant={"form-md"}
						label="Phone"
						value={phone || "-"}
						disabled
					/>
				</div>
				<TextInputWithErrorComponent
					variant={"form-lg"}
					label="Email"
					value={email || "-"}
					disabled
					type="email"
				/>
				<div className="mt-15px">
					<TextInputWithErrorComponent
						variant={"form-lg"}
						label="Address"
						value={address || "-"}
						disabled
					/>
					<div className="flex ">
						<TextInputWithErrorComponent
							variant={"form-md"}
							label="City"
							value={city || "-"}
							disabled
						/>
						<TextInputWithErrorComponent
							variant={"form-md"}
							label="Country"
							value={country || "-"}
							disabled
						/>
					</div>
				</div>
			</div>
			<div className="main-inner-container m-auto">
				<div className="flex ">
					<TextInputWithErrorComponent
						variant={"form-md"}
						label="DOB"
						value={dob || "-"}
						disabled
					/>
					<TextInputWithErrorComponent
						variant={"form-md"}
						label="Gender"
						value={gender || "-"}
						disabled
					/>
				</div>
				{type === "customer" && (
					<FormTextareaInputComponent
						label="Notes"
						variant="form-notes-md"
						value={customerNotes || "-"}
						disabled
					/>
				)}

				{editable && (
					<div className="inner-content button">
						{details?.userData?.user_id !== user_id &&
							type !== "customer" &&
							company_role === "admin" &&
							details?.userData?.user_verified === true && (
								<button
									className="button-lg-colored bg-primaryDark text-whiteText text-sm"
									onClick={transferOwnershipModal}
								>
									Transfer Ownership
								</button>
							)}
						{details?.userData?.user_verified === false &&
							type !== "customer" &&
							company_role === "admin" && (
								<button
									className="button-lg"
									onClick={sendEmailVerification}
								>
									Send Verification
								</button>
							)}
						{type !== "customer" && company_role === "admin" && (
							<button
								className="button-primary-icon tooltip-parent"
								onClick={() =>
									dispatch(
										logoutEmployee(
											details.userData.user_info_id
										)
									)
								}
							>
								<i className="fas fa-sign-out-alt"></i>
								<div className="tooltip">Sign Out</div>
							</button>
						)}
						{company_role === "admin" &&
							details?.userData?.user_id !== user_id && (
								<button
									className="button-primary-icon tooltip-parent"
									onClick={showDeleteModal}
								>
									<i className="fas fa-trash "></i>
									<div className="tooltip">Remove User</div>
								</button>
							)}

						<button
							className="button-primary-icon"
							onClick={editFlag}
						>
							<i className="fas fa-edit "></i>
						</button>

						{/* to update passoword modal show added by sanat */}
						{type !== "customer" &&
							details?.userData?.user_id === user_id && (
								<button
									className="button-primary-icon tooltip-parent"
									onClick={showChangePasswordModal}
								>
									<i className="fas fa-key "></i>
									<div className="tooltip">
										Change password
									</div>
								</button>
							)}
					</div>
				)}
			</div>

			{/* identification column */}
			{company_role === "admin" && (
				<div className="main-inner-container">
					<label htmlFor="" className="mb-15px">
						Identification
					</label>
					<TextInputWithErrorComponent
						variant={"form-lg"}
						label="Identification Type"
						value={identificationType || "-"}
						disabled
					/>
					<TextInputWithErrorComponent
						variant={"form-lg"}
						label="Identification Number"
						value={identificationNumber || "-"}
						disabled
					/>
					<div className="flex ">
						<TextInputWithErrorComponent
							variant={"form-md"}
							label="PAN Number"
							value={pan || "-"}
							disabled
						/>
						<TextInputWithErrorComponent
							variant={"form-md"}
							label="VAT Number"
							value={vat || "-"}
							disabled
						/>
					</div>
				</div>
			)}

			{type === "user" && empVenueDetails()}
		</div>
	);
}

export default ProfileComponent;

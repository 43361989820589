/* eslint-disable array-callback-return */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleTextInput from "../../components/common/text-input/text-input-with-error.component";
import Loader from "../../components/loader/loader.component";
import DeleteConfirmation from "../../components/modals/delete-confirmation.modal";
import UpdateOrderTable from "../../components/tables/update-order.table";
import { isEmptyObject, sessionInfo } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";
import strings from "../../global/strings";
import {
	deletOrder,
	fetchSingleOrder,
	updateOrder,
} from "../../redux/actions/order.action";

function UpdateOrderPage(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { orderedItem, isLoading } = useSelector((s) => s.order);
	const [modalFlag, setModalFlag] = useState(false);
	const [sessionData, setSessionData] = useState({});

	const showModal = () => {
		setModalFlag(true);
	};
	const hideModal = () => {
		setModalFlag(false);
	};

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setSessionData({
					user_info_id: data.user_info_id,
					first_name: data.first_name,
					last_name: data.last_name,
				});
			});
		}

		fetchSessionData();

		dispatch(
			fetchSingleOrder(
				props.location.state.location_id,
				props.location.state.id
			)
		);
	}, [dispatch, props]);

	const _validation = () => {
		let status = true;
		Object.keys(orderedItem.order_list).map((cat) => {
			for (
				let index = 0;
				index < orderedItem.order_list[cat].length;
				index++
			) {
				if (orderedItem.order_list[cat][index].item_status === false) {
					status = false;
					break;
				}
			}
		});
		return status;
	};

	const _updateOrder = async (orderStatus = null) => {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}
		orderedItem.ordered_by.push({
			user_info_id: sessionData.user_info_id,
			name: sessionData.first_name + " " + sessionData.last_name,
		});

		if (orderStatus === "completed") {
			if (!_validation()) {
				showToastMessage(
					"Please check the status before completeing order"
				);
				return;
			}
		}

		let status = await dispatch(
			updateOrder({
				...orderedItem,
				order_status:
					orderStatus === "completed"
						? orderStatus
						: orderedItem.order_status,
				location_id: localStorage.getItem("location_id"),
			})
		);
		if (status) {
			_back();
		}
	};

	const _deleteOrder = async () => {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}
		let status = await dispatch(
			deletOrder(
				{
					...orderedItem,
					location_id: localStorage.getItem("location_id"),
				},
				orderedItem._id
			)
		);
		if (status) {
			_back();
		}
	};
	const _back = () => {
		history.push({
			pathname: "/inventory",
			state: {
				tab: 3,
			},
		});
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => _back()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt; <span className="current-page-path">Update Order</span>
				</div>
			</div>
			{!isEmptyObject(orderedItem) && (
				<div className="inner-container mt-52px full">
					<div className="flex flex-row bg-default w-full mt-30px p-15px rounded-sm flex-wrap gap-24px">
						<SimpleTextInput
							variant="form-md"
							label="Order ID"
							defaultValue={orderedItem.order_id}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="Ordered Date"
							defaultValue={
								moment(orderedItem.created_at).format(
									"YYYY-MM-DD"
								) || 0
							}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="Due Date"
							defaultValue={
								moment(orderedItem.due_date).format(
									"YYYY-MM-DD"
								) || 0
							}
							disabled
						/>
						<SimpleTextInput
							variant="form-md"
							label="Order Status"
							defaultValue={orderedItem.order_status || 0}
							disabled
						/>
					</div>
				</div>
			)}

			<div className="inner-container full">
				{!isEmptyObject(orderedItem) &&
					Object.keys(orderedItem.order_list).map((item) => (
						<UpdateOrderTable
							list={orderedItem.order_list[item]}
							cat={item}
							key={item}
						/>
					))}
			</div>
			<div className="inner-container button ">
				<button
					className="colored-button-wrapper bg-buttonFailed text-whiteText"
					onClick={showModal}
				>
					Delete
				</button>
				<button
					className="colored-button-wrapper bg-buttonSuccess text-whiteText"
					onClick={() => _updateOrder("completed")}
				>
					Complete
				</button>
				<button
					className="colored-button-wrapper bg-buttonSuccess text-whiteText"
					onClick={() => _updateOrder()}
				>
					Update
				</button>
			</div>
			<DeleteConfirmation
				strings={strings.deleteOrder}
				isVisible={modalFlag}
				handleClose={hideModal}
				handleShow={showModal}
				confirmButton={_deleteOrder}
			/>
		</div>
	);
}

export default UpdateOrderPage;

import React, { forwardRef } from "react";
import "./text-input.style.scss";

function TextInputWithIcon(props, ref) {
	return (
		<div className={`text-input-container icon ${props.variant}`}>
			<div className="icon-input-content">
				<span className="input-icon">
					{props.icon === "fb" && (
						<i className="fab fa-facebook-f"></i>
					)}
					{props.icon === "web" && <i className="fas fa-globe"></i>}
					{props.icon === "ig" && (
						<i className="fas fa-instagram"></i>
					)}
				</span>
				<input
					{...props}
					ref={ref}
					className={`${props.variant} text-input`}
					id={`${props.name}`}
				/>
			</div>

			<span className="validation-error">{props.error}</span>
		</div>
	);
}

export default forwardRef(TextInputWithIcon);

/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
	fetchEmployees,
	fetchSearchEmployee,
} from "../../redux/actions/employee-setting.action";
import { useDispatch } from "react-redux";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import SelectBox from "../common/select-box/form-select-input.component";
import strings from "../../global/strings";

function SearchEmployeeComponent({ venueList, permission, handleAdd }) {
	const dispatch = useDispatch();

	const [searchEmployee, setSearchEmployee] = useState({
		user_id: "",
		first_name: "",
		last_name: "",
		user_mobile: "",
		user_email: "",
		venue_info_id: "",
		employee_role: "",
	});

	const handleSearchEmployee = (e) => {
		setSearchEmployee({
			...searchEmployee,
			[e.target.name]: e.target.value,
		});
	};
	const _clearEmployeeSearch = () => {
		setSearchEmployee({
			user_id: "",
			first_name: "",
			last_name: "",
			user_mobile: "",
			user_email: "",
			venue_info_id: "",
			employee_role: "",
		});
		dispatch(fetchEmployees());
	};

	function _searchEmployee() {
		dispatch(fetchSearchEmployee(searchEmployee));
	}

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Employee</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					label="Employee ID"
					placeholder=" Enter Employee ID"
					type="number"
					name="user_id"
					value={searchEmployee.user_id}
					onChange={(e) => handleSearchEmployee(e)}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="First Name"
					placeholder="Enter First Name"
					name="first_name"
					value={searchEmployee.first_name}
					onChange={(e) => handleSearchEmployee(e)}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Last Name"
					placeholder="Enter Last Name"
					name="last_name"
					value={searchEmployee.last_name}
					onChange={(e) => handleSearchEmployee(e)}
				/>
				<SimpleTextInput
					variant="form-lg edit"
					label="Mobile Number"
					placeholder="Enter Mobile Number"
					type="number"
					name="user_mobile"
					value={searchEmployee.user_mobile}
					onChange={(e) => handleSearchEmployee(e)}
				/>
				<SimpleTextInput
					variant="form-lg edit"
					label="Email"
					placeholder="Enter Email"
					name="user_email"
					value={searchEmployee.user_email}
					onChange={(e) => handleSearchEmployee(e)}
					type="email"
				/>

				<SelectBox
					variant="search"
					label="Venue Name"
					name="venue_info_id"
					value={searchEmployee.venue_info_id}
					options={venueList}
					onChange={(e) => handleSearchEmployee(e)}
					type="Venue"
				/>
				{permission !== "viewer" && (
					<SelectBox
						id="venue"
						label="Venue Role"
						variant="search"
						options={strings.venueRole}
						name="employee_role"
						value={searchEmployee.employee_role}
						onChange={(e) => handleSearchEmployee(e)}
						type="Employee Role"
					/>
				)}
			</div>
			<div className="flex gap-x-15px justify-between">
				<div className="flex gap-10px">
					{permission === "admin" && (
						<button className="button-primary" onClick={handleAdd}>
							Add Employee
						</button>
					)}
				</div>
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearEmployeeSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={_searchEmployee}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}
export default SearchEmployeeComponent;

import React, { useState, useEffect } from "react";
import "./dashboard.style.scss";
import { useHistory } from "react-router-dom";

// components
import Upcoming from "./calendar-upcoming/calendar-upcoming.component";
import Availability from "./calendar-availability/calendar-availability.component";
import CalendarMonthlyBooking from "./calendar-monthly-booking/calendar-monthly-booking.component";

// error page
import ErrorPage from "../extra-page/extra.page";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchVenue } from "../../redux/actions/venue-action";
import { setCalendarDate } from "../../redux/actions/calendar-info.action";

export default function Dashboard(props) {
	const dispatch = useDispatch();

	const [view, setView] = useState(3);
	const [customer, setCustomer] = useState({});
	const _changeView = (val) => {
		setView(val);
	};
	const history = useHistory();
	const { error } = useSelector((state) => state.support);
	const calendarInfo = useSelector((state) => state.calendarInfo);
	const { defaultFilter } = useSelector((state) => state.venue);

	useEffect(() => {
		if (calendarInfo.currDate === "") {
			dispatch(setCalendarDate());
		}
	}, [dispatch, calendarInfo]);

	useEffect(() => {
		dispatch(fetchVenue());
	}, []);

	useEffect(() => {
		if (props.location.state === undefined) {
			setView(3);
		} else {
			setView(2);
			setCustomer(props.location.state.customerInfo);
		}
	}, [props]);
	function _renderCalendarTypeView(viewId, viewTitle) {
		return (
			<div
				onClick={() => _changeView(viewId)}
				className={
					view === viewId
						? "settings-view-title selected"
						: "settings-view-title"
				}
			>
				{viewTitle}
			</div>
		);
	}

	const goBack = () => {
		history.push({
			pathname: "/booking-event",
			search: `event_id=${props.location.state.eventId}`,
			state: { event_info_id: props.location.state.event_info_id },
		});
	};

	const handleNavigateToAvailability = () => {
		setView(2);
	};

	return (
		<div className="main-container">
			{error && <ErrorPage type="error" />}
			{props.location.state === undefined ? (
				<div className="profile-navigation-container">
					<div className="navigator-content">
						{_renderCalendarTypeView(3, "Monthly Bookings")}
						{_renderCalendarTypeView(1, "Upcoming Events")}

						{_renderCalendarTypeView(2, "Availability ")}
					</div>
				</div>
			) : (
				<div className="profile-navigation-container">
					<div className="profile-path">
						<span onClick={goBack} className="previous-page-path">
							Booking Details
						</span>{" "}
						&gt;{" "}
						<span className="current-page-path">Availability</span>
					</div>
				</div>
			)}

			{view === 1 && <Upcoming key={view} />}
			{view === 2 && (
				<Availability key={view} selectedCustomer={customer} />
			)}
			{view === 3 && calendarInfo.currDate && (
				<CalendarMonthlyBooking
					key={view}
					onAddBooking={handleNavigateToAvailability}
				/>
			)}
		</div>
	);
}

import React, { useMemo } from "react";
import "./payment-receipt.scss";
import caterMeLogo from "../../../../assets/images/logo/CaterMe_logo_color_trans.png";
import { converToWord, currencyFormat } from "../../../../global/function";

export default function PaymentReceiptPrint({
	paymentData,
	type = "payment",
	ids,
}) {
	const TotalAmtInWords = useMemo(
		() => converToWord(paymentData?.paid_amount?.toFixed(2) || 0),
		[paymentData?.paid_amount]
	);
	return (
		<div className={`pr-wrapper ${type === "toc" && "pr-toc"}`}>
			{type === "payment" && (
				<>
					<div className="pr-header">
						<div className="pr-title-wrap">
							<div className="pr-title">Payment Receipt</div>
						</div>
						<div className="pr-message">
							Thank you, we have received your payment.
						</div>
						<div className="pr-caterme">
							<img
								className="pr-cater-me-logo"
								id="nav"
								src={caterMeLogo}
								alt="Logo"
							/>
							<div className="pr-caterme-name">CaterMe</div>
						</div>
					</div>
					<div className="pr-container">
						<div className="pr-sec">
							<div className="pr-data-block">
								<div className="pr-data-label">
									Customer Name
								</div>
								<div>{paymentData?.customer_name}</div>
							</div>
							<div className="pr-data-block">
								<div className="pr-data-label">Contact</div>
								<div>{paymentData?.contact_info}</div>
							</div>
							<div className="pr-data-block">
								<div className="pr-data-label">
									Booking Date
								</div>
								<div>{paymentData?.booked_date_nepali}</div>
							</div>
							<div className="pr-data-block">
								<div className="pr-data-label">
									Booking Time
								</div>
								<div>{paymentData?.book_time}</div>
							</div>
							{ids && (
								<>
									{" "}
									<div className="pr-data-block">
										<div className="pr-data-label">
											Booking ID
										</div>
										<div>{paymentData?.booking_id}</div>
									</div>
									{paymentData?.payment_id && (
										<div className="pr-data-block">
											<div className="pr-data-label">
												Payment ID
											</div>
											<div>{paymentData?.payment_id}</div>
										</div>
									)}
								</>
							)}
						</div>

						<div className="pr-sec pr-right">
							<div className="pr-data-block">
								<div className="pr-data-label pr-r">
									Amount Received
								</div>
								<div className="pr-data">
									Rs.{" "}
									{currencyFormat(paymentData?.paid_amount)}
								</div>
							</div>
						</div>
					</div>
					<div
						className="pr-container"
						style={{
							alignItems: "flex-start",
						}}
					>
						<div className="pr-sec">
							<div className="pr-data-block">
								<div className="pr-data-label">
									Amount in words
								</div>
								<div>{TotalAmtInWords}</div>
							</div>
						</div>

						<div
							className="pr-sec"
							style={{ justifyContent: "flex-end" }}
						>
							<div className="pr-verified-block">
								<i className="fas fa-check-circle"></i>
								Registered Staff
							</div>
							<div className="pr-paydate">
								Print Date: {paymentData?.payment_date}
							</div>
						</div>
					</div>
				</>
			)}
			{type === "toc" && (
				<div className="pr-toc">
					Terms and Conditions
					<div
						className="toc-data"
						dangerouslySetInnerHTML={{ __html: paymentData }}
					/>
				</div>
			)}
		</div>
	);
}

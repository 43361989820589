import React, { useState, useEffect } from "react";
import "./booking-info.style.scss";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInputWithErrorComponent from "../../common/text-input/text-input-with-error.component";
import FormSelectBox from "../../common/select-box/form-select-input.component";
import strings from "../../../global/strings";
import BookingHallsCheckbox from "../../common/checkbox-input/booking-halls/booking-halls.checkbox";
import { setErrorTrue } from "../../../redux/actions/support-action";
import { useDispatch } from "react-redux";
import { nepMonthFullDate } from "../../../global/function";
import moment from "moment";
import {
	deleteBooking,
	saveBooking,
} from "../../../redux/actions/event-save.action";

const bookingSchema = yup.object().shape({
	e_pax: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	e_rate: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	booking_type: yup.string().required("Booking Type Required"),
	booking_catering: yup.string().required("Catering Type Required"),
	halls: yup
		.mixed()
		.transform((_, value) =>
			typeof value === "string"
				? [value]
				: value.length > 0
				? value
				: null
		)
		.required("Booking Halls Required"),
});

function BookingInfoComponent({ event, type }) {
	const { register, handleSubmit, reset, errors } = useForm({
		mode: "onSubmit",
		defaultValues: { halls: [] },
		resolver: yupResolver(bookingSchema),
	});

	const dispatch = useDispatch();
	const [week, setWeek] = useState("");
	const [venue, setVenue] = useState("");
	const [availabelHalls, setAvailableHalls] = useState([]);
	const [venueColorCode, setVenueColorCode] = useState("");
	const [nepDate, setNepDate] = useState([]);
	const [editableFlag, setEditableFlag] = useState(false);

	useEffect(() => {
		try {
			setWeek(event.bookedDay);
			setVenue(event.bookedVenue);
			setAvailableHalls([...event.availableHallArray]);
			setVenueColorCode(event.colorCode);
			setEditableFlag(event.saved);
			setNepDate(nepMonthFullDate(event.booked_date_nepali).split(" "));
			reset(event);
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [event, type, dispatch, reset]);

	// useEffect(() => {
	// 	setEditableFlag(event.saved);
	// }, [event.saved]);

	const style = {
		backgroundColor: venueColorCode,
	};

	const timeStyle = {
		"--bg": `var(--${event.booked_time})`,
	};

	const _saveBooking = (booking) => {
		dispatch(
			saveBooking({
				...booking,
				tempBookingId: event.tempBookingId,
				saved: true,
			})
		);
	};
	const _onClickEdit = () => {
		dispatch(
			saveBooking({ saved: false, tempBookingId: event.tempBookingId })
		);
	};

	const _onCancelClick = () => {
		dispatch(deleteBooking(event.tempBookingId));
	};

	return (
		<div className="main-inner-container add-booking">
			<div className="inner-content booking-day-wrapper rows">
				<div
					className="booking-day-container bodered"
					style={timeStyle}
				>
					<div className="booking-day-icon">
						<i className="fa fa-2x fa-sun"></i>
					</div>
					<label className="booking-day-label">{week}</label>
					<label className="booking-time-label">
						{event.booked_time}
					</label>
				</div>

				<div className="inner-content booking-date-container cols">
					<div className="booking-date-content bg-default">
						<div className="booking-day">
							{moment(event?.booked_date_english).format("DD")}
						</div>
						<div className="booking-year">
							{moment(event?.booked_date_english).format("YYYY")}
						</div>
						<div className="booking-month">
							{moment(event?.booked_date_english).format("MMMM")}
						</div>
					</div>
					<div className="booking-date-content bg-default">
						<div className="booking-day">{nepDate[0]}</div>
						<div className="booking-year">{nepDate[1]}</div>
						<div className="booking-month">{nepDate[2]}</div>
					</div>
				</div>
			</div>
			<div className="inner-content cols mt-15px">
				<div className="venue-name-wrapper bg-default">
					<div
						className="venue-color-container bodered"
						style={style}
					/>
					<label htmlFor="" className="venue-name-label overflow">
						{venue}
					</label>
				</div>

				<div className="booking-halls-wrapper mt-15px">
					<div className="form-label">Hall Selection</div>
					<BookingHallsCheckbox
						tempBookingId={event.tempBookingId}
						halls={availabelHalls}
						name="halls[]"
						ref={register}
						error={errors?.halls?.message}
						disabled={editableFlag}
					/>
				</div>
			</div>

			<div className="inner-content rows mt-15px">
				<TextInputWithErrorComponent
					variant={!editableFlag ? "form-md edit " : "form-md"}
					label="Estd. Pax"
					name="e_pax"
					ref={register}
					error={errors?.e_pax?.message}
					type="number"
					disabled={editableFlag}
				/>
				<TextInputWithErrorComponent
					variant={!editableFlag ? "form-md edit " : "form-md"}
					label="Estd. Rate"
					name="e_rate"
					ref={register}
					error={errors?.e_rate?.message}
					type="number"
					disabled={editableFlag}
				/>
			</div>

			<div className="inner-content rows ">
				<FormSelectBox
					label="Booking Type*"
					variant="form-sm"
					options={strings.eventType}
					name="booking_type"
					ref={register}
					error={errors?.booking_type?.message}
					disabled={editableFlag}
				/>
				<FormSelectBox
					label="Catering Type*"
					variant="form-sm"
					options={strings.cateringType}
					name="booking_catering"
					ref={register}
					error={errors?.booking_catering?.message}
					disabled={editableFlag}
				/>
			</div>

			{!editableFlag ? (
				<div className="inner-content row gap-x-15px justify-end px-15px">
					<button
						className="button-primary-icon"
						onClick={_onCancelClick}
					>
						<i className="fas fa-trash text-buttonFailed"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={handleSubmit(_saveBooking)}
					>
						<i className="fas fa-save"></i>
					</button>
				</div>
			) : (
				<div className="inner-content row gap-x-15px justify-end px-15px">
					<button
						className="button-primary-icon"
						onClick={() => _onClickEdit()}
					>
						<i className="fas fa-pen"></i>
					</button>
				</div>
			)}
		</div>
	);
}

export default BookingInfoComponent;

import React from "react";

export default function DJ({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			style={style}
		>
			<path d="M502.6 39L473 9.4a32 32 0 0 0 -45.3 0L381.5 55.7a35.1 35.1 0 0 0 -8.5 13.8L360.8 106l-76.3 76.3c-12.2-8.8-25.5-15.7-40.1-19.1-33.5-7.8-67-.9-89.9 22a82.5 82.5 0 0 0 -20.2 33.5c-6 18.6-23.2 32.7-42.2 34.5-23.7 2.3-45.7 11.5-62.6 28.4C-16.1 327-7.9 409 47.6 464.5S185 528 230.6 482.5c17-16.9 26.2-38.9 28.5-62.7 1.8-18.9 15.9-36.1 34.4-42.1a82.6 82.6 0 0 0 33.5-20.3c22.9-22.9 29.7-56.4 22-89.8-3.4-14.6-10.4-28-19.2-40.2L406 151.2l36.5-12.2a35.1 35.1 0 0 0 13.8-8.5l46.3-46.3a32 32 0 0 0 0-45.2zM208 352a48 48 0 1 1 48-48 48 48 0 0 1 -48 48z" />
		</svg>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchCustomer } from "../../redux/actions/customer-setting.action";
import "./add-booking-queue.style.scss";

// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { addBookingQueue } from "../../redux/actions/booking-action";

const bookingQueueSchema = yup.object().shape({
	e_pax: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	e_rate: yup
		.number()
		.typeError("Must be Number")
		.moreThan(-1, "Must Be Positive"),
	booking_type: yup.string().required("Booking Type Required"),
	booking_catering: yup.string().required("Catering Type Required"),
});
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function AddBookingQueueModal(props) {
	const dispatch = useDispatch();
	const [searchCustomer, setCustomerSearch] = useState({
		customer_id: "",
		customer_fname: "",
		customer_lname: "",
		customer_mobile: "",
		customer_email: "",
	});
	const { customerList } = useSelector((state) => state.customerSetting);

	const { register, errors, handleSubmit, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(bookingQueueSchema),
	});

	const [selectedCustomer, setSelectedCustomer] = useState("");

	const customerSearch = (e) => {
		setCustomerSearch({
			...searchCustomer,
			[e.target.name]: e.target.value,
		});
	};

	const _handleSearch = async () => {
		if (
			searchCustomer.customer_id ||
			searchCustomer.customer_fname ||
			searchCustomer.customer_lname ||
			searchCustomer.customer_mobile ||
			searchCustomer.customer_email
		) {
			await dispatch(fetchSearchCustomer(searchCustomer));
		}
	};

	const _renderCustomerSelection = (value) => {
		setSelectedCustomer(value.company_customer_id);
	};

	async function dataUpdate(x) {
		if (selectedCustomer !== "") {
			let data = {
				...x,
				booking_info_id: props.bookingID,
				company_customer_id: selectedCustomer,
			};

			dispatch(addBookingQueue(data));
			setSelectedCustomer("");
			props.handleClose();
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Add Booking In Queue</div>
				</div>
				<div className="form-body">
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<FormSelectBox
								label="Booking Type*"
								variant="form-sm"
								options={strings.eventType}
								name="booking_type"
								ref={register}
								error={errors?.booking_type?.message}
								disabled={false}
							/>
							<FormSelectBox
								label="Catering Type*"
								variant="form-sm"
								options={strings.cateringType}
								name="booking_catering"
								ref={register}
								error={errors?.booking_catering?.message}
								disabled={false}
							/>
						</div>
						<div className="form-input-container inner">
							<FormTextInput
								label="Est Pax"
								placeholder="E_Pax"
								variant="form-md"
								type="number"
								name="e_pax"
								ref={register}
								error={errors?.e_pax?.message}
							/>

							<FormTextInput
								label="Est Rate"
								placeholder="E_Rate"
								variant="form-md"
								type="number"
								name="e_rate"
								ref={register}
								error={errors?.e_rate?.message}
							/>
						</div>
					</div>
					<hr />
					<div className="form-input-container ">
						<div className="form-input-container inner">
							<FormTextInput
								label="First Name"
								placeholder="Name"
								name="customer_fname"
								variant="form-md"
								onChange={(e) => customerSearch(e)}
							/>
							<FormTextInput
								label="Last Name"
								placeholder="Last Name"
								name="customer_lname"
								variant="form-md"
								onChange={(e) => customerSearch(e)}
							/>
						</div>
						<div className="form-input-container inner">
							<FormTextInput
								label="Phone Number"
								placeholder="Phone Number"
								variant="form-md"
								type="number"
								name="customer_mobile"
								onChange={(e) => customerSearch(e)}
							/>
							<button
								onClick={() => _handleSearch()}
								className="button-primary"
							>
								Search
							</button>
						</div>
					</div>
					<div className="form-input-container">
						{customerList?.length > 0 && (
							<div className="search-result-wrapper">
								{customerList.map((v, index) => (
									<div
										key={index}
										className={`bg-default customer-list-sm ${
											selectedCustomer ===
												v.company_customer_id &&
											"custom-selection"
										}`}
										onClick={() =>
											_renderCustomerSelection(v)
										}
									>
										<p className="customer-list-item-name">{`${v.customer_fname} ${v.customer_lname}`}</p>
										<p className="customer-list-item-num">
											{v.customer_mobile}
										</p>
									</div>
								))}
							</div>
						)}
					</div>
				</div>

				<div className="form-footer two">
					<button
						onClick={() => {
							setCustomerSearch({
								customer_id: "",
								customer_fname: "",
								customer_lname: "",
								customer_mobile: "",
								customer_email: "",
							});
							reset({});
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						onClick={handleSubmit(dataUpdate)}
						className="button-primary"
					>
						Complete
					</button>
				</div>
			</div>
		</Modal>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import {
	addServiceItem,
	editServiceItem,
} from "../../redux/actions/menu-service-settings.action";
import { useDispatch } from "react-redux";
// service form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { sessionInfo } from "../../global/function";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";
// Modal.setAppElement("#root");

const serviceSchema = yup.object().shape({
	service_type_id: yup.string().required("service type required"),
	service_name: yup.string().required("service name required"),
	override: yup.string().required("service override required"),
	service_price: yup
		.number()
		.typeError("Must be a number")
		.required("service price is required")
		.positive("Must be greater than zero"),
});

function AddServiceModal(props) {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(serviceSchema),
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.actionType === "Update") {
			reset(props.updatedItem);
		} else {
			reset({
				service_type_id: "",
				override: "",
			});
		}
	}, [props.updatedItem]);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};

	async function _returnServiceItem(data) {
		let serviceStatus = "";
		let serviceItem = {
			...data,
		};

		if (props.actionType === "Add") {
			serviceStatus = await dispatch(addServiceItem(serviceItem));
		} else {
			let updatedService = {
				...serviceItem,
				service_info_id: props.updatedItem.service_info_id,
			};
			serviceStatus = await dispatch(editServiceItem(updatedService));
		}

		if (serviceStatus) {
			props.handleClose();
		}
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(_returnServiceItem)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">
							{props.actionType} Service
						</div>
					</div>
					<div className="form-body">
						<div className="form-description-title">
							Service Details
						</div>
						<div className="form-input-container">
							<FormTextInput
								type="text"
								placeholder="Enter Service Item Name"
								label="Service Name*"
								variant="form-lg"
								ref={register}
								name="service_name"
								error={errors?.service_name?.message}
							/>
							<FormTextInput
								type="text"
								placeholder="Price*"
								label="Price"
								variant="form-lg"
								ref={register}
								name="service_price"
								error={errors?.service_price?.message}
							/>
						</div>

						<div className="form-input-container">
							<div className="form-input-container inner">
								<FormSelectBox
									label="Service Type*"
									options={props.serviceTypes}
									variant="form-sm"
									ref={register}
									name="service_type_id"
									type="Service Type"
									error={errors?.service_type_id?.message}
								/>
								<FormSelectBox
									label="Service Override*"
									options={strings.overrideType}
									variant="form-sm"
									ref={register}
									name="override"
									type="Service Override"
									error={errors?.override?.message}
								/>
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => {
								reset({});
								props.handleClose();
							}}
						>
							Close
						</button>
						<button
							className="button-primary"
							onClick={handleSubmit(_returnServiceItem)}
						>
							{props.actionType}
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
}

export default AddServiceModal;

import React, { useState, useEffect } from "react";
import EmployeeTable from "../../tables/table-employee-list.component";
import SearchEmployeeComponent from "../../search/search-employee.component";
import InviteEmployeeModal from "../../modals/invite-employee-modal.component";
import Loading from "../../loader/loader.component";

import { fetchEmployees } from "../../../redux/actions/employee-setting.action";
import { useDispatch, useSelector } from "react-redux";
import DuplicateCustomerModal from "../../modals/duplicate-customer.modal";
import { sessionInfo } from "../../../global/function";
import { fetchVenue } from "../../../redux/actions/venue-action";

function EmployeeSettings() {
	const dispatch = useDispatch();
	const { isLoading, employees, error } = useSelector(
		(state) => state.employeeSetting
	);
	const { venueDropDownList } = useSelector((s) => s.venue);

	const [showModal, setShowModal] = useState(false);
	const [role, setCompanyRole] = useState();
	const [showDuplicateUser, setShowDuplicateUser] = useState(false);

	const _handleClose = () => {
		setShowModal(false);
	};

	const _handleShow = () => setShowModal(true);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
		dispatch(fetchEmployees());
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchVenue());
	}, [dispatch]);

	useEffect(() => {
		if (error === "Error! Duplicate User") {
			setShowModal(false);
			setShowDuplicateUser(true);
		}
	}, [error]);

	const handleDuplicateModalClose = () => {
		setShowDuplicateUser(false);
	};
	const handleDuplicateModalShow = () => setShowDuplicateUser(true);

	return (
		<div className="inner-container full">
			{isLoading && <Loading />}

			<SearchEmployeeComponent
				venueList={venueDropDownList}
				permission={role}
				handleAdd={_handleShow}
			/>

			{employees.length > 0 ? (
				<EmployeeTable data={employees} role={role} />
			) : (
				<div>No employee</div>
			)}

			<InviteEmployeeModal
				isVisible={showModal}
				handleClose={_handleClose}
				handleShow={_handleShow}
				actionType="Add"
				venueList={venueDropDownList}
			/>

			<DuplicateCustomerModal
				isVisible={showDuplicateUser}
				handleClose={handleDuplicateModalClose}
				handleShow={handleDuplicateModalShow}
				onClickNo={_handleShow}
				type="user"
			/>
		</div>
	);
}

export default EmployeeSettings;

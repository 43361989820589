import React from "react";
import "./menu-svg.scss";

export default function Chicken({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 18.35 18.94"
            id="chicken">
            <g >
                <path d="M16.9,18.43l-.46-1.14a1.75,1.75,0,0,0-3.2-.07l-.79,1.72H11.07l-.67-1.65a1.75,1.75,0,0,0-3.2-.08l-.67,1.47a3.15,3.15,0,0,1-1.87-2.53L4.5,14.77.87,14a.89.89,0,0,1-.51-1.6L2.5,10.35l1.39-1a3.22,3.22,0,0,1,.47-1.7,1.6,1.6,0,0,1,.16-.23A3.12,3.12,0,0,1,6.11,6.28l.35-.09,1.33-.38.13,0,1-.28.24-.07L9.6,5.3A2.46,2.46,0,0,1,10,5.21a2.83,2.83,0,0,1,.71,0,3,3,0,0,1,1.87.75,3.2,3.2,0,0,1,.94,1.35l.14.23,4.52,7.27A3.12,3.12,0,0,1,16.9,18.43Z" />
                <path d="M14,5.82a.94.94,0,0,1-.23.73l-.14-.23A3.15,3.15,0,0,0,12.7,5a3.07,3.07,0,0,0-1.87-.75,2.5,2.5,0,0,0-.72,0,1.61,1.61,0,0,0-.38.08l-.47.13L9,4.54l-1,.28-.13,0-1.34.38-.35.1A3.12,3.12,0,0,0,4.64,6.44L4,6.4a1,1,0,0,1-1-.89L2.77,3.36A1,1,0,0,1,4,2.24l1.45.34A1,1,0,0,0,6.62,2L7.19.62A1,1,0,0,1,9,.49l.85,1.42a1,1,0,0,0,1.25.42l1.28-.51a1,1,0,0,1,1.39.87Z" />
            </g>
        </svg>
    );
}

import {
	FETCH_COMPANY_REQUEST,
	FETCH_COMPANY_SUCCESS,
	FETCH_COMPANY_ERROR,
	FETCH_COMPANY_REQUEST_SUCCESS,
	PIN_CHANGE_SUCCESS,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchCompanyRequest = () => {
	return {
		type: FETCH_COMPANY_REQUEST,
	};
};
export const fetchCompanyRequestSuccess = () => {
	return {
		type: FETCH_COMPANY_REQUEST_SUCCESS,
	};
};

export const fetchCompanySuccess = (company) => {
	return {
		type: FETCH_COMPANY_SUCCESS,
		payload: company,
	};
};

export const pinChangeSuccess = () => {
	return {
		type: PIN_CHANGE_SUCCESS,
	};
};

export const fetchCompanyError = (error) => {
	return {
		type: FETCH_COMPANY_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchCompanyError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCompanyError(error.message));
	}
}

export const fetchCompanyDetails = () => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}companyInfo/id?company_info_id=${sessionData.company_info_id}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompanySuccess(res.data.result));
					return res.data.result;
				} else {
					await dispatch(fetchCompanyError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addHalls = (hallDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}hallInfo/add`, hallDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyDetails());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateCompanyDetails = (companyDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}companyInfo/update`, companyDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyDetails());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateHallDetails = (hallDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}hallInfo/update`, hallDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyDetails());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/*--start venue details */
export const updateVenueDetails = (venueDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}venueInfo/update`, venueDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyDetails());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addVenue = (venueDetails) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}venueInfo/add`, venueDetails, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyDetails());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
				return response.data;
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteHall = (hallInfoId) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}hallInfo/delete?hall_info_id=${hallInfoId}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchCompanyDetails());
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteVenue = (venueInfoId) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}venueInfo/delete?venue_info_id=${venueInfoId}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(fetchCompanyDetails());
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

/*---end venue details */

/* --Start user Security Measures */
export const deactivateCompany = (companyInfo) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}companyInfo/deactivate`, companyInfo, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyRequestSuccess());
					history.push({ pathname: "/" });
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const changPin = (pins) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}companyInfo/update/key`, pins, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(pinChangeSuccess());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const closeShop = () => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(`${strings.url}user/close`, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					history.push({ pathname: "/" });
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const changeEmployeeRole = (companyInfo) => {
	return async (dispatch) => {
		dispatch(fetchCompanyRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.url}/user/change/role`, companyInfo, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					await dispatch(fetchCompanyRequestSuccess());
					showToastMessage(response.data.message, "S");
				} else {
					await dispatch(fetchCompanyError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};
/* --End user Security Measures */

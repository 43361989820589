import React, { forwardRef } from "react";
import "./select-box.style.scss";

function FormSelectBox(props, ref) {
	const returnOptions = () => {
		return props.options.map((row, index) => (
			<option
				value={row.id}
				key={index}
				style={{
					display: `${row.value === "None" ? "none" : "block"}`,
				}}
				disabled={row.disabled}
			>
				{row.value}
			</option>
		));
	};

	const style = {
		display: "hidden",
	};

	return (
		<div className={`form-select-wrapper ${props.variant}`}>
			<label
				htmlFor={`${props.label}`}
				className={`form-select-label ${props.variant}`}
			>
				{props.label}
			</label>
			<select
				key={props.id}
				className={`bg-default form-select-box ${props.variant} ${props.inputvariant}`}
				{...props}
				ref={ref}
			>
				<option value="">- Select -</option>
				{returnOptions()}
			</select>
			<span
				className={`form-validation-error ${props.variant}`}
				style={style}
			>
				{props.error}
			</span>
		</div>
	);
}
export default forwardRef(FormSelectBox);

import React, { useState, useEffect } from "react";
import ProfileSettings from "../../components/settings/profile-settings/profile-settings.component";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setErrorTrue } from "../../redux/actions/support-action";

export default function EmployeeProfile({ location }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [empId, setEmpId] = useState();

	useEffect(() => {
		try {
			setEmpId(location.state.employee_info_id);
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [location, dispatch]);

	const goBack = () => {
		history.push({ pathname: "/settings", state: { page_id: 5 } });
	};

	return (
		<div className="main-container">
			<div className="profile-navigation-container">
				<div className="profile-path text-capitalize">
					<span onClick={goBack} className="previous-page-path">
						Settings
					</span>{" "}
					&gt;{" "}
					<span className="current-page-path">Employee Details</span>
				</div>
			</div>

			<ProfileSettings employeeInfoId={empId} />
		</div>
	);
}

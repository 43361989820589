import React from "react";
import { useDispatch } from "react-redux";
import "./export-csv-modal.style.scss";
import Modal from "react-modal";
// import "../../assets/css/signup-modal.scss";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import { exportHistory } from "../../redux/actions/booking-action";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const dateSchema = yup.object().shape({
	start_date: yup.string().required("start date required"),
	end_date: yup.string().required("end date required"),
});

export default function ExportCsvModal(props) {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(dateSchema),
	});

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
		h2: {
			color: "red",
		},
	};

	const returnData = async (date) => {
		await dispatch(
			exportHistory(
				props.filterData,
				props.tableName,
				date.start_date,
				date.end_date
			)
		);
		props.handleHide();
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleHide}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(returnData)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">Export CSV</div>
					</div>
					<div className="form-body">
						<div className="form-description-title">
							Select the dates to export {props.tableName} history
							into a CSV
						</div>
						<div className="form-body-container">
							<div className="form-input-container">
								<div className="form-input-container inner">
									<FormTextInput
										type="date"
										label="Booking From Date*"
										placeholder="Enter Menu Item Name"
										variant="form-md"
										ref={register}
										name="start_date"
										error={errors?.start_date?.message}
									/>

									<FormTextInput
										type="date"
										label="Booking To Date*"
										placeholder=""
										variant="form-md"
										ref={register}
										name="end_date"
										error={errors?.end_date?.message}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => {
								reset({});
								props.handleHide();
							}}
						>
							Cancel
						</button>
						<button
							type="submit"
							className="button-primary"
							onClick={handleSubmit(returnData)}
						>
							Export
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
}

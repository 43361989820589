import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmptyObject, rupeeConverter } from "../../../global/function";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "./payment-summary.style.scss";
import strings from "../../../global/strings";
import { initiatePayment } from "../../../redux/actions/payment-action";
import completeLoader from "../../../assets/images/success-loader.gif";
import { updateBooking } from "../../../redux/actions/booking-action";
import ConfirmationModal from "../../../components/modals/confirmation-modal.component";
import NepaliCalendarPopUp from "../../../components/common/calendar/nepali-cal-popup";

// for date purpose
import moment from "moment";
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";

const paymentSchema = yup.object().shape(
	{
		payment_amount: yup.string().when("discount_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),
			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
		payment_option: yup.string().required("Please select payment option"),
		cheque_number: yup.string().when("payment_option", {
			is: (value) => value === "cheque",
			then: yup.string().required("Please enter cheque number"),
		}),
		discount_amount: yup.string().when("payment_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),
			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
	},
	["payment_amount", "discount_amount", "payment_option"]
);
function PaymentSummary({
	company_role,
	rerenderComplete,
	openVat,
	closeBooking,
	msbCondition,
	recentPaymentPrintView,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, watch, reset } = useForm({
		mode: "onSubmit",
		defaultValues: {},
		resolver: yupResolver(paymentSchema),
	});

	const { bookingPayment } = useSelector((state) => state.payment);
	const { bookingData } = useSelector((state) => state.booking);

	const [initiatePayView, setInitiatePayView] = useState(false);
	const [successFlag, setSuccessFlag] = useState(false);
	const [buttonLocker, setButtonLocker] = useState(false);
	const [recentPayID, setRecentPayID] = useState(0);
	const [recentPaymentData, setRecentPaymentData] = useState(0);
	const [dateSwitcher, setDateSwitcher] = useState("EN");
	const [paymentDate, setPaymentDate] = useState("");
	let paymentOption = watch("payment_option");

	const [reopenBookingModal, setReopenBookingModal] = useState(false);

	useEffect(() => {}, [dispatch, bookingPayment]);

	function _handleViewHistory(type = "") {
		if (type === "payment") {
			history.push({
				pathname: "/payment-history",
				state: {
					booking_id: bookingData.booking_id,
				},
			});
		} else {
			history.push({
				pathname: "/vat-history",
				state: {
					event_id: bookingData.event_id,
				},
			});
		}
	}
	const _handleAddPayment = () => {
		let x = moment().format("YYYY-MM-DD");
		setPaymentDate(x);
		setInitiatePayView(true);
	};
	const _handlePaymentSubmit = (paymentDetails) => {
		//for structuring payment as single booking payment
		let array = [];
		var bookingsPayment = {};
		bookingsPayment["ratio"] = 1;
		bookingsPayment["booking_info_id"] = bookingPayment.booking_info_id;
		bookingsPayment["venue_info_id"] = bookingPayment.venue_info_id;
		bookingsPayment["booking_status"] =
			bookingPayment.booking_status === "inquiry"
				? "booked"
				: "confirmed";
		array.push(bookingsPayment);
		paymentDetails["bookings"] = array;

		//BS and AD Date format
		let tempDate = paymentDate;
		if (dateSwitcher === "NP") {
			let x = new NibjarNepaliDate(tempDate).getAD();
			tempDate = moment(`${x.year}-${x.month + 1}-${x.date}`).format(
				"YYYY-MM-DD"
			);
		}
		paymentDetails["createdAt"] = tempDate;
		proceedPayment(paymentDetails);
	};

	async function _handleCompletePayment(type = "") {
		let completeData = {
			booking_id: bookingData.booking_id,
			booking_info_id: bookingData.booking_info_id,
			halls: [bookingData.booking_halls[0].hall_info_id],
			booked_date_nepali: bookingData.booked_date_nepali,
			booked_date_english: bookingData.booked_date_english,
			booked_time: bookingData.booked_time,
			a_pax: bookingData.a_pax,
			a_rate: bookingData.a_rate,
			a_service: bookingData.a_service,
			booking_status: bookingData.booking_status,
			vat_status: bookingData.vat_status,
			payment_status: "exists",
			discount_status: bookingPayment.discount_status,
			revert: true,
		};

		if (type !== "undo") {
			completeData = {
				...completeData,
				payment_status: "complete",
			};
		}

		await dispatch(updateBooking(completeData));
		rerenderComplete();
	}

	const proceedPayment = async (paymentData) => {
		setPaymentDate("");
		setDateSwitcher("EN");
		setButtonLocker(true);
		let success = await dispatch(initiatePayment(paymentData));
		if (!isEmptyObject(success)) {
			setRecentPayID(success.result.bookings[0].payment_id);
			setRecentPaymentData(success.result.bookings[0]);
			setSuccessFlag(true);
			setInitiatePayView(false);
			setButtonLocker(false);
			setTimeout(() => {
				setSuccessFlag(false);
				rerenderComplete();
			}, 3000);
		}
	};

	function _renderTitleBlock() {
		if (initiatePayView) {
			let advBal =
				bookingPayment?.totalBookingCost -
				bookingPayment?.totalHoldingPayment;
			if (bookingPayment?.vat_status !== "none") {
				advBal =
					bookingData?.e_pax * bookingData?.e_rate +
					(bookingPayment?.totalAdditionalCost -
						bookingPayment?.totalHoldingPayment);
			}

			return (
				<div className="bp-title-row title-bal">
					<div className="bp-title">Payment</div>
					<div
						className="bp-balance tooltip-parent"
						onClick={() => {
							navigator.clipboard.writeText(advBal);
						}}
					>
						<div className="tooltip">Adv. Balance Pending</div>
						<i className="fas fa-coins" />
						Rs. {advBal}
					</div>
				</div>
			);
		} else {
			return (
				<div className="bp-title-row">
					<div className="bp-title">Quotation</div>
					{bookingPayment?.vat_status === "none" &&
					bookingPayment?.booking_status !== "canceled" &&
					bookingPayment?.booking_status !== "complete" ? (
						<div
							className="bp-addvat-button tooltip-parent"
							onClick={openVat}
						>
							<div className="tooltip">Add VAT Record</div>
							<i className="fas fa-plus" />
							VAT Bill
						</div>
					) : (
						<div
							className="bp-addvat-button tooltip-parent"
							onClick={() => _handleViewHistory("payment")}
						>
							<div className="tooltip">Payment View</div>
							<i className="fas fa-eye" />
							Payments
						</div>
					)}
				</div>
			);
		}
	}

	function _renderDataBlock() {
		if (bookingData.booking_status === "canceled") {
			return (
				<>
					<div className="bp-cost-block">
						<p>Total Payment</p>
						<p>Rs.</p>
						<p className="bp-amounts">
							{rupeeConverter(
								bookingPayment?.totalBookingPayment.toFixed(2),
								"summary"
							)}
						</p>
						<p>Refund</p>
						<p>Rs.</p>
						<p className="bp-amounts">
							{rupeeConverter(
								bookingPayment?.totalBookingRefund.toFixed(2),
								"summary"
							)}
						</p>
					</div>
					<div
						className="bp-mid-status-block bp-payment"
						onClick={() => _handleViewHistory("payment")}
					>
						<p>Holding Payment</p>
						<p>Rs.</p>
						<p className="bp-amounts">
							{rupeeConverter(
								bookingPayment?.totalHoldingPayment.toFixed(2),
								"summary"
							)}
						</p>
					</div>
				</>
			);
		} else {
			if (bookingPayment.vat_status === "none") {
				return (
					<>
						<div className="bp-cost-block">
							<p>Estimated Cost</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									bookingPayment?.totalBookingCost.toFixed(2),
									"summary"
								)}
							</p>
							<p>VAT 13%</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									(
										bookingPayment?.totalBookingCost * 0.13
									).toFixed(2),
									"summary"
								)}
							</p>
							<p>Total Bill</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									bookingPayment?.totalBillAmount.toFixed(2),
									"summary"
								)}
							</p>
						</div>
						<div
							className="bp-mid-status-block bp-payment"
							onClick={() => _handleViewHistory("payment")}
						>
							<p>Payment</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									bookingPayment?.totalHoldingPayment.toFixed(
										2
									),
									"summary"
								)}
							</p>
						</div>
						<div className="bp-cost-block">
							<p>Adv.Balance</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									(
										bookingPayment?.totalBookingCost -
										bookingPayment?.totalHoldingPayment
									).toFixed(2),
									"summary"
								)}
							</p>
							<p>Total Balance</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									bookingPayment?.totalBalance.toFixed(2),
									"summary"
								)}
							</p>
							{recentPayID > 0 && (
								<>
									<p>Last Payment</p>
									<p>ID</p>
									<p
										className="bp-amounts temp-id"
										onClick={() =>
											recentPaymentPrintView(
												recentPaymentData
											)
										}
									>
										{recentPayID}
									</p>
								</>
							)}
						</div>
					</>
				);
			} else {
				var actual_cost = bookingPayment?.vatAmount / 0.13;
				var actual_totalBill = bookingPayment?.vatAmount + actual_cost;
				var estimate_cost =
					bookingData?.e_pax * bookingData?.e_rate +
					bookingPayment?.totalAdditionalCost -
					bookingPayment?.totalBookingDiscount;

				return (
					<>
						<div className="bp-cost-block">
							<p>Cost</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									actual_cost.toFixed(2),
									"summary"
								)}
							</p>
							<p>VAT 13%</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									bookingPayment?.vatAmount?.toFixed(2),
									"summary"
								)}
							</p>
							<p>Total Bill</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									actual_totalBill.toFixed(2),
									"summary"
								)}
							</p>
						</div>
						<div
							className="bp-mid-status-block bp-vat"
							onClick={() => _handleViewHistory()}
						>
							<p>VAT Recorded</p>
							<i className="fas fa-check" />
						</div>
						<div className="bp-cost-block">
							<p>Estimate Cost</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									estimate_cost.toFixed(2),
									"summary"
								)}
							</p>
							<p>Total Payment</p>
							<p>Rs.</p>
							<p className="bp-amounts line">
								{rupeeConverter(
									bookingPayment?.totalHoldingPayment.toFixed(
										2
									),
									"summary"
								)}
							</p>
							<p>Adv Balance</p>
							<p>Rs.</p>
							<p className="bp-amounts">
								{rupeeConverter(
									(
										estimate_cost -
										bookingPayment?.totalHoldingPayment
									).toFixed(2),
									"summary"
								)}
							</p>
							{recentPayID > 0 && (
								<>
									<p>Last Payment</p>
									<p>ID</p>
									<p className="bp-amounts">{recentPayID}</p>
								</>
							)}
						</div>
					</>
				);
			}
		}
	}
	function _renderPaymentBlock() {
		const _handleSwitchCalendar = () => {
			//check to switch between AD and BS dates for display
			if (dateSwitcher === "NP") {
				let x = new NibjarNepaliDate(paymentDate).getAD();
				let p = moment(`${x.year}-${x.month + 1}-${x.date}`).format(
					"YYYY-MM-DD"
				);

				setPaymentDate(p);
				setDateSwitcher("EN");
			} else {
				let decreasedMonth = moment(paymentDate)
					.subtract(1, "month")
					.format("YYYY-MM-DD");
				let a = decreasedMonth.split("-");

				let b = new NibjarNepaliDate(new Date(a[0], a[1], a[2])).format(
					"YYYY-MM-DD"
				);

				setPaymentDate(b);
				setDateSwitcher("NP");
			}
		};

		const dateOnchange = (data) => {
			setPaymentDate(data);
		};
		return (
			<div className="bp-payment-block">
				<div className="option-block">
					{strings.paymentType.map((p) => {
						return (
							<div className={`bp-options`} key={p}>
								<input
									type="radio"
									ref={register}
									id={p}
									value={p}
									name={"payment_option"}
									className="bp-option-checkbox"
								/>
								<label
									htmlFor={p}
									className={`bg-default bp-option-label ${
										errors?.payment_option?.message &&
										"errorlogger"
									}`}
								>
									{p === "cash" && (
										<i className="fas fa-coins"></i>
									)}
									{p === "cheque" && (
										<i className="fa fa-money-check-alt"></i>
									)}
									{p === "transfer" && (
										<i className="fas fa-exchange-alt"></i>
									)}
									{p}
								</label>
							</div>
						);
					})}
				</div>
				<div className="fields-block">
					<div className="payment-field-wrapper">
						<p>{dateSwitcher === "NP" ? "Nep Date" : "Eng Date"}</p>
						<p
							className="payment-date-switch"
							onClick={_handleSwitchCalendar}
						>
							<i className="fas fa-exchange-alt"></i>
						</p>
						{dateSwitcher === "NP" ? (
							<NepaliCalendarPopUp
								dateSubmitFunction={dateOnchange}
								type="np"
								field={true}
								date={paymentDate}
							/>
						) : (
							<input
								name="date"
								type="date"
								value={paymentDate}
								onChange={(d) => setPaymentDate(d.target.value)}
								placeholder="Payment Amount"
							/>
						)}
					</div>
					{paymentOption === "cheque" && (
						<div className={`payment-field-wrapper`}>
							<p>Cheque #</p>
							<p></p>
							<input
								name="cheque_number"
								type="number"
								ref={register}
								className={
									errors?.cheque_number?.message &&
									"errorlogger"
								}
								placeholder="Cheque Number"
							/>
						</div>
					)}
					<div className="payment-field-wrapper">
						<p>Payment</p>
						<p>Rs.</p>
						<input
							name="payment_amount"
							type="number"
							ref={register}
							className={
								errors?.payment_amount?.message && "errorlogger"
							}
							placeholder="Payment Amount"
						/>
					</div>
					<div className="payment-field-wrapper">
						<p>Discount</p>
						<p>Rs.</p>
						<input
							name="discount_amount"
							type="number"
							ref={register}
							className={
								errors?.discount_amount?.message &&
								"errorlogger"
							}
							placeholder="Discount Amount"
						/>
					</div>
					<div className="payment-field-wrapper">
						<p>Notes</p>
						<p></p>
						<input
							name="notes"
							type="text"
							ref={register}
							className=""
							placeholder="Payment Notes"
						/>
					</div>
				</div>
			</div>
		);
	}
	function _renderButtons() {
		let advBal = 0;
		if (bookingData?.vat_status !== "none") {
			advBal =
				bookingData?.e_pax * bookingData?.e_rate +
				bookingPayment?.totalAdditionalCost -
				bookingPayment?.totalHoldingPayment;
		} else {
			advBal =
				bookingPayment?.totalBookingCost -
				bookingPayment?.totalHoldingPayment;
		}

		if (initiatePayView) {
			return (
				<div className="bp-button-block">
					<div
						className="bp-buttons cancel"
						onClick={() => {
							setInitiatePayView(false);
							setPaymentDate("");
							setDateSwitcher("EN");
							reset();
						}}
					>
						Cancel
					</div>
					<div
						className={`bp-buttons ${
							buttonLocker && "bp-disabled"
						}`}
						onClick={
							!buttonLocker
								? handleSubmit(_handlePaymentSubmit)
								: undefined
						}
					>
						<i className="fas fa-check" />
						Submit
					</div>
				</div>
			);
		} else {
			return (
				<div className="bp-button-block">
					{bookingPayment?.payment_status !== "complete" ? (
						<>
							<div
								className={`bp-buttons ${
									advBal > 0 && "bp-disabled"
								} tooltip-parent`}
								onClick={() =>
									advBal <= 0 && _handleCompletePayment()
								}
							>
								<div className="tooltip">
									{advBal > 0
										? "Balance Pending!"
										: "All Paid"}
								</div>
								<i className="fas fa-check" />
								Complete
							</div>
							<div
								className="bp-buttons tooltip-parent"
								onClick={_handleAddPayment}
							>
								<div className="tooltip">Add New Payment</div>
								<i className="fas fa-plus" />
								Payment
							</div>
						</>
					) : (
						<>
							<div
								className="bp-buttons comp"
								onClick={closeBooking}
							>
								<i className="fas fa-plus" />
								Complete Booking
							</div>
							<div
								className="bp-buttons tooltip-parent"
								onClick={() => _handleCompletePayment("undo")}
							>
								<div className="tooltip">
									Payment Not complete
								</div>
								<i className="fas fa-undo" />
							</div>
						</>
					)}
				</div>
			);
		}
	}
	const _handleReopenBooking = async () => {
		let bookingStatus = "pending";
		if (
			new Date().getTime() -
				new Date(bookingData.booked_date_english).getTime() <
			0
		) {
			if (msbCondition) {
				bookingStatus = "confirmed";
			} else bookingStatus = "booked";
		}

		let completeData = {
			booking_id: bookingData.booking_id,
			booking_info_id: bookingData.booking_info_id,
			halls: [bookingData.booking_halls[0].hall_info_id],
			booked_date_nepali: bookingData.booked_date_nepali,
			booked_date_english: bookingData.booked_date_english,
			booked_time: bookingData.booked_time,
			a_pax: bookingData.a_pax,
			a_rate: bookingData.a_rate,
			a_service: bookingData.a_service,
			booking_status: bookingStatus,
			vat_status: bookingData.vat_status,
			payment_status: bookingPayment.payment_status,
			discount_status: bookingPayment.discount_status,
			revert: true,
		};
		setReopenBookingModal(false);
		await dispatch(updateBooking(completeData));
		rerenderComplete();
	};
	function _renderReopenButton() {
		return (
			<div className="bp-button-block">
				<div
					className="bp-buttons reopen tooltip-parent"
					onClick={() => setReopenBookingModal(true)}
				>
					<div className="tooltip">Re-open Booking</div>
					<i className="fas fa-undo" />
					Reopen Booking
				</div>
			</div>
		);
	}
	function _renderSuccess() {
		return (
			<div className="bp-success-block">
				<p>Payment ID: {recentPayID}</p>
				<img src={completeLoader} alt="Success..." />
				<p>Success</p>
			</div>
		);
	}
	return (
		<div className="bg-default booking-payment-summary-wrapper">
			{!successFlag && _renderTitleBlock()}
			{!successFlag &&
				!isEmptyObject(bookingPayment) &&
				!initiatePayView &&
				_renderDataBlock()}
			{!successFlag && initiatePayView && _renderPaymentBlock()}
			{!successFlag &&
				bookingData.booking_status !== "complete" &&
				bookingData.booking_status !== "canceled" &&
				_renderButtons()}
			{bookingData.booking_status === "complete" && _renderReopenButton()}
			{successFlag && _renderSuccess()}

			<ConfirmationModal
				isVisible={reopenBookingModal}
				handleClose={() => setReopenBookingModal(false)}
				handleShow={() => setReopenBookingModal(true)}
				onClickConfirmation={_handleReopenBooking}
				type="reopenBooking"
			/>
		</div>
	);
}

export default PaymentSummary;

/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rupeeConverter, sessionInfo } from "../../global/function";

import Calendar from "../../components/calendar/calendar-event-detail/calendar-event-detail.component";
import Loader from "../../components/loader/loader.component";
import { isEmptyObject } from "../../global/function";
import {
	fetchBookingQueue,
	getBookedEvents,
	reviewBooking,
	getSingleBookedEvent,
	transferBooking,
} from "../../redux/actions/booking-action";
import {
	fetchEventPayment,
	fetchBookingPayment,
} from "../../redux/actions/payment-action";

import DeactivateBooking from "./deactivate-booking/deactivate-booking.component";
import { SelectedMenuServiceComponent } from "./selected-menu-service-component/selected-menu-service.component";
// import EventReportInvoice from "../../components/print-preview/invoice/event-report/event-report.invoice";
// import BookingInvoice from "../../components/print-preview/invoice/booking-invoice/booking.invoice";
// import KitchenSummary from "../../components/print-preview/invoice/kitchen-summary/kitchen.summary";
// import { useReactToPrint } from "react-to-print";
import ErrorPage from "../extra-page/extra.page";
import { setErrorTrue } from "../../redux/actions/support-action";
import PaymentSummary from "./payment-summary/payment-summary.component";
import BookingInfoComponent from "../../components/booking/booking-info/booking-info.component";
//----New components
import ProgressBlock from "../../components/analytics-components/progressBlock.component";
import DataBlock from "../../components/analytics-components/dataBlock.component";
import "./booking-event.style.scss";
import ConfirmationModal from "../../components/modals/confirmation-modal.component";
import { completeBooking } from "../../redux/actions/booking-action";
import AddBookingQueueModal from "../../components/modals/add-booking-queue.modal";
import { fetchCustomerRequestSuccess } from "../../redux/actions/customer-setting.action";
import ViewBookingQueueModal from "../../components/modals/view-booking-queue.modal";
import AddVatModal from "../../components/modals/add-vat-modal";
import ReviewBooking from "../../components/modals/review-booking-modal";
import VatCalculator from "../../components/modals/vat-calculator.modal";
import SideModal from "../../components/modals/side-modal/side-modal.modal";
import moment from "moment";
import showToastMessage from "../../global/showToastMessage";

// import ProfileSummary from "../../components/profile/profile-summary.component";
// import PaymentReceivedModal from "../../components/modals/payment-selection.modal";
// import PaymentReceipt from "../../components/print-preview/invoice/payment-receipt/payment-receipt";

export default function BookingEvent(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	const {
		isLoading,
		bookingList,
		bookingData,
		bookingQueueList,
		reviewBooking: reviewData,
	} = useSelector((state) => state.booking);

	const payment = useSelector((state) => state.payment);

	const { error } = useSelector((state) => state.support);
	const [eventInfoId, setEventInfoId] = useState("");
	const [bookingInfoId, setBookingInfoId] = useState("");
	const [editPermission, setEditPermission] = useState(true);

	const [openCompleteModel, setCompleteModel] = useState(false);
	//booking vat Trigger
	const [vatAdjRecord, setVatAdjRecord] = useState(false);
	// const [cVat, setCvat] = useState(bookingData.vat_status);

	//Booking queue triggre states
	const [openQueueModel, setOpenQueueModel] = useState(false);
	const [openQueueAddModel, setOpenQueueAddModel] = useState(false);
	const [selectedQueue, setSelectedQueue] = useState("");

	//Payment triggers booking render
	const [rerenderSuccess, setRerenderSuccess] = useState(false);
	const [bookingHasMSB, setBookingHasMSB] = useState(false);

	const [company_role, setCompanyRole] = useState();

	//Review Booking Modal
	const [showReviewBookingModal, setShowReviewBookingModal] = useState(false);
	const [moveBooking, setMoveBooking] = useState("");

	//side Panel
	const [showSideModal, setSideModal] = useState(false);
	const [sideModalData, setSideModalData] = useState({});

	// const componentRef = useRef();
	// const receiptRef = useRef();
	// const bookingRef = useRef();
	// const kitchenRef = useRef();

	useEffect(() => {
		try {
			let event_info_id = props.location.state.event_info_id;
			if (event_info_id) {
				const fun = async () => {
					let val = await dispatch(getBookedEvents(event_info_id));
					setEventInfoId(event_info_id);
					if (
						props.location.state.booking_info_id === undefined ||
						props.location.state.booking_info_id === null
					) {
						setBookingInfoId(
							val.booking_informations[0].booking_info_id
						);
					} else {
						setBookingInfoId(props.location.state.booking_info_id);
					}
					await dispatch(fetchEventPayment(event_info_id));
				};
				fun();
			}
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [dispatch, props.location.state]);

	useEffect(() => {
		async function getBooking() {
			await dispatch(getSingleBookedEvent(bookingInfoId));
			await dispatch(fetchBookingPayment(bookingInfoId));
			await dispatch(fetchBookingQueue(bookingInfoId));
		}
		if (bookingInfoId !== "") {
			// console.log(
			// 	"Old Function",
			// 	bookingData.booking_info_id,
			// 	bookingInfoId
			// );
			getBooking();
		}
	}, [bookingInfoId, dispatch, rerenderSuccess, payment.refundSuccess]);

	useEffect(() => {
		if (!isEmptyObject(bookingData) && !isEmptyObject(payment)) {
			if (
				bookingData.booking_status === "canceled" ||
				bookingData.booking_status === "complete" ||
				payment.bookingPayment.payment_status === "complete"
			) {
				setEditPermission(false);
			} else {
				setEditPermission(true);
			}
			setBookingHasMSB(
				bookingData?.booking_snacks.length +
					bookingData?.booking_desserts.length +
					bookingData?.booking_services.length +
					bookingData?.booking_drinks.length
			);
		}
	}, [bookingData, payment]);

	useEffect(() => {
		if (
			bookingInfoId !== "" &&
			bookingInfoId !== bookingData.booking_info_id &&
			!isEmptyObject(bookingData)
		) {
			// console.log(
			// 	"New Set ID",
			// 	bookingData.booking_info_id,
			// 	bookingInfoId
			// );
			setBookingInfoId(bookingData.booking_info_id);
		} else {
			// console.log("New Rejected");
		}
	}, [bookingData]);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, [dispatch]);

	const _onCalendarEventClick = (val) => {
		setBookingInfoId(val.data.booking_info_id);
	};

	const _navigateToAddMenuServiceDrink = (type) => {
		history.push({
			pathname: "/add-service",
			search: `?booking_id=${bookingData.booking_id}`,
			state: {
				type: type,
				customer_details: bookingList.company_customer,
				bookingInfoId: bookingData.booking_info_id,
				eventId: bookingList.event_id,
			},
		});
	};
	const _renderMenu = () => {
		let snacks = [];
		var dessert = [];
		if (!isEmptyObject(bookingData)) {
			snacks = bookingData.booking_snacks;
			dessert = bookingData.booking_snacks;

			return (
				<div className="inner-container full mt-30px">
					<div className="flex justify-between">
						Menu
						<button
							className="button-primary-icon"
							onClick={() =>
								_navigateToAddMenuServiceDrink("menu")
							}
							disabled={!editPermission}
						>
							{snacks.length === 0 && dessert.length === 0 ? (
								<i className="fas fa-plus"></i>
							) : (
								<i className="fas fa-pen"></i>
							)}
						</button>
					</div>
					{bookingData?.booking_snacks?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="snacks"
							list={bookingData.booking_snacks}
						/>
					)}
					{bookingData?.booking_snacks?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="meals"
							list={bookingData.booking_snacks}
						/>
					)}
					{bookingData?.booking_snacks?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="soup"
							list={bookingData.booking_snacks}
						/>
					)}
					{bookingData?.booking_snacks?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="salad"
							list={bookingData.booking_snacks}
						/>
					)}
					{bookingData?.booking_desserts?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="dessert"
							list={bookingData.booking_desserts}
						/>
					)}
				</div>
			);
		}
	};
	const _renderService = () => {
		let service = [];
		if (!isEmptyObject(bookingData)) {
			service = bookingData.booking_services;
			return (
				<div className="inner-container full mt-30px">
					<div className="flex justify-between">
						Service
						<button
							className="button-primary-icon"
							onClick={() =>
								_navigateToAddMenuServiceDrink("service")
							}
							disabled={!editPermission}
						>
							{service.length === 0 ? (
								<i className="fas fa-plus"></i>
							) : (
								<i className="fas fa-pen"></i>
							)}
						</button>
					</div>
					{bookingData?.booking_services?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="service"
							list={bookingData.booking_services}
						/>
					)}
				</div>
			);
		}
	};
	const _renderDrink = () => {
		let drink = [];
		if (!isEmptyObject(bookingData)) {
			drink = bookingData.booking_drinks;
			return (
				<div className="inner-container full mt-30px">
					<div className="flex justify-between">
						Drink
						<button
							className="button-primary-icon"
							onClick={() =>
								_navigateToAddMenuServiceDrink("drink")
							}
							disabled={!editPermission}
						>
							{drink.length === 0 ? (
								<i className="fas fa-plus"></i>
							) : (
								<i className="fas fa-pen"></i>
							)}
						</button>
					</div>
					{bookingData?.booking_drinks?.length > 0 && (
						<SelectedMenuServiceComponent
							listTitle="drink"
							list={bookingData.booking_drinks}
						/>
					)}
				</div>
			);
		}
	};

	const addBookingToEvent = () => {
		let customerInfo = {
			...bookingList,
			company_customers: {
				customer_id: bookingList.customer_id,
				company_customer_id: bookingList.company_customer_id,
				customer_fname: bookingList.customer_fname,
				customer_lname: bookingList.customer_lname,
				customer_email: bookingList.customer_email,
				customer_type: bookingList.customer_type,
				customer_gender: bookingList.customer_gender,
				customer_address: bookingList.customer_address,
				customer_city: bookingList.customer_city,
				customer_country: bookingList.customer_country,
				customer_mobile: bookingList.customer_mobile,
				customer_phone: bookingList.customer_phone,
				customer_image: bookingList.customer_image,
			},
			event_info_id: eventInfoId,
			event_id: bookingList.event_id,
		};
		// dispatch(setSelectedCustomer(customerInfo));

		history.push({
			pathname: "/dashboard",
			search: `event_id=${bookingList.event_id}`,
			state: {
				event_info_id: eventInfoId,
				eventId: bookingList.event_id,
				customerInfo: customerInfo,
			},
		});
	};

	//Add for margin
	const _renderMarginData = () => {
		if (bookingData.e_pax > 0 && bookingData.e_pax > 0) {
			let margins = [1, 1.3, 1.5, 1.8];
			let menuPrices = 0;
			let servicePrices = 0;
			let totalvalue = {
				actual: 0,
				margin_menu: [],
				margin_service: [],
				difference: [],
			};
			if (bookingData.booking_snacks.length > 0) {
				bookingData.booking_snacks.forEach((item) => {
					menuPrices += parseInt(item.menu_price);
				});
			}
			if (bookingData.booking_desserts.length > 0) {
				bookingData.booking_desserts.forEach((item) => {
					menuPrices += parseInt(item.menu_price);
				});
			}
			if (bookingData.booking_services.length > 0) {
				bookingData.booking_services.forEach((item) => {
					servicePrices += parseInt(item.booking_service_price);
				});
			}
			margins.forEach((m) => {
				//margin on menu only
				totalvalue.margin_menu.push((menuPrices * m).toFixed(2));
				//margin on menu and service
				totalvalue.margin_service.push(
					parseInt(
						(
							menuPrices * m +
							servicePrices / bookingData.e_pax
						).toFixed(2)
					)
				);
			});
			return (
				<div className="main-inner-container mx-auto">
					<div className="h-255">
						<div className="inner-content rows">
							<DataBlock
								label="Menu"
								subLabel={`Count ${bookingData.booking_snacks.length}`}
								data={menuPrices}
								iconic={false}
								type="currency"
								size="sm"
							/>
							<DataBlock
								label="Service"
								subLabel={`Count ${bookingData.booking_services.length}`}
								data={servicePrices}
								iconic={false}
								type="currency"
								size="sm"
							/>
						</div>
						<ProgressBlock
							label="Margins considering Menu"
							total={totalvalue.margin_menu[3]}
							value={bookingData?.e_rate}
							segments={true}
							breakpoint={totalvalue.margin_menu}
						/>
						<ProgressBlock
							label="Margins considering Menu and service"
							total={totalvalue.margin_service[3]}
							value={bookingData?.e_rate}
							segments={true}
							breakpoint={totalvalue.margin_service}
						/>
					</div>
				</div>
			);
		}
	};

	//Popup for complete
	const _handleBookingCompleteModelOpen = () => setCompleteModel(true);
	const _handleBookingCompleteModelClose = () => setCompleteModel(false);
	const _handleBookingComplete = async (type) => {
		let updatedBooking = {
			a_pax: bookingData.a_pax || 0,
			a_rate: bookingData.a_rate || 0,
			booking_info_id: bookingData.booking_info_id,
			venue_info_id: bookingData.venue_info_id,
		};
		setCompleteModel(false);

		await dispatch(completeBooking(updatedBooking));
	};

	//Modal Control for VAT ADJ
	const _handleBookingVatAdjModelOpen = () => {
		if (bookingData.a_pax * bookingData.a_rate) {
			setVatAdjRecord(true);
		}
	};

	const _handleBookingVatAdjModelClose = () => setVatAdjRecord(false);
	// const _handleCvat = () => setCvat("c_vat");

	// const handlePrint = useReactToPrint({
	// 	content: () => componentRef.current,
	// });

	// const handlePrintReceipt = useReactToPrint({
	// 	content: () => receiptRef.current,
	// 	// dataList: eventDetails,
	// 	details: "detailt",
	// });

	// const handleBookingReceipt = useReactToPrint({
	// 	content: () => bookingRef.current,
	// });

	// const handleKitchenReceipt = useReactToPrint({
	// 	content: () => kitchenRef.current,
	// 	// dataList: eventDetails,
	// 	details: "detailt",
	// });

	const _renderBookingDetailsView = () => {
		return (
			<BookingInfoComponent
				event={bookingData}
				event_vat_status={payment.eventPayment.eventVatStatus}
				type="edit"
				company_role={company_role}
				closeBooking={() => _handleBookingCompleteModelOpen()}
			/>
		);
	};

	// Review Booking
	const _handleReviewBookingShow = () => setShowReviewBookingModal(true);
	const _handleReviewBookingClose = () => setShowReviewBookingModal(false);
	const _reviewBooking = async (bookingInfoId) => {
		setShowReviewBookingModal(true);
		await dispatch(reviewBooking(bookingInfoId));
	};

	const _renderPrintPage = async (printAction) => {
		history.push({
			pathname: "/print",
			search: `${location.search}`,
			state: {
				action: printAction,
				event: bookingList,
				booking_data: bookingData,
				payment: payment.bookingPayment,
				eventPayment: payment.eventPayment,
				// eventPayment: data.result,
			},
		});
	};

	//booking queue set up
	const _handleQueueModelClose = () => {
		dispatch(fetchCustomerRequestSuccess());
		setOpenQueueModel(false);
		setOpenQueueAddModel(false);
	};
	const _handleQueueSelection = (data) => {
		setOpenQueueModel(true);
		setSelectedQueue(data);
	};
	const _handleQueueSwap = (data) => {
		_handleQueueModelClose();
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${data.event_id}`,
			state: {
				event_info_id: data.event_info_id,
				booking_info_id: data.booking_info_id,
			},
		});
	};

	const _renderBookingQueue = () => {
		return (
			<div className="main-inner-container md:auto">
				<div className="inner-content cols">
					<div className="queue-booking">
						Booking In Queue
						<div
							className="button-primary-icon"
							onClick={() => setOpenQueueAddModel(true)}
						>
							<i className="fas fa-plus"></i>
						</div>
					</div>
					<div className="q-head">
						<div>Customer</div>
						<div>E Pax</div>
						<div>E Rate</div>
					</div>
					<div className={`q-data`}>
						{bookingQueueList?.length > 0 &&
							bookingQueueList.map((item, i) => (
								<div
									className="q-row"
									onClick={() => _handleQueueSelection(item)}
								>
									<div>{`${item.customer_fname} ${item.customer_lname}`}</div>
									<div>{item.e_pax}</div>
									<div>{item.e_rate}</div>
								</div>
							))}
					</div>
				</div>
			</div>
		);
	};

	//booking Request
	const _handleBookingRequest = () => {
		history.push({
			pathname: "/test",
			state: {
				event_id: bookingList.event_id,
				event_info_id: eventInfoId,
				booking_info_id: bookingInfoId,
				venueid: bookingData.venue_info_id,
				venue_name: bookingData.venue_name,
				bookingid: bookingData.booking_id,
				booking_date: bookingData.booked_date_english,
				requestType: "booking",
			},
		});
	};
	const _renderBookingRequest = () => {
		return (
			<div className="main-inner-container md:auto">
				<div className="inner-content cols">
					<div>Request List</div>
					<div
						className="primary-button"
						onClick={() => _handleBookingRequest()}
					>
						add
					</div>
				</div>
			</div>
		);
	};

	const _renderPaymentSummary = () => {
		if (company_role !== "viewer") {
			return (
				<PaymentSummary
					company_role={company_role}
					rerenderComplete={() =>
						setRerenderSuccess(!rerenderSuccess)
					}
					openVat={() => setVatAdjRecord(true)}
					closeBooking={() => _handleBookingCompleteModelOpen()}
					msbCondition={bookingHasMSB > 0}
					recentPaymentPrintView={(data) => {
						setSideModalData({
							customer_name: `${bookingData.customer_fname} ${bookingData.customer_lname}`,
							contact_info: bookingData.customer_mobile,
							booked_date_nepali: bookingData.booked_date_nepali,
							booked_date_english:
								bookingData.booked_date_english,
							book_time: bookingData.booked_time,
							customerEmail: bookingList.customer_email,
							payment_date: moment().format("YYYY-MM-DD"),
							...data,
						});
						setSideModal(true);
					}}
				/>
			);
		} else {
			var actual_cost = payment.bookingPayment?.vatAmount / 0.13;
			var actual_totalBill =
				payment.bookingPayment?.vatAmount + actual_cost;
			let vat = payment.bookingPayment?.vatAmount;
			return (
				<div className="bg-default view-vat-wrapper">
					<div className="view-title">
						<p>Vat Record</p>
						{payment.bookingPayment?.vat_status === "none" && (
							<div
								className="view-addvat-button tooltip-parent"
								onClick={() => setVatAdjRecord(true)}
							>
								<div className="tooltip">Add VAT Record</div>
								<i className="fas fa-plus" />
								VAT Bill
							</div>
						)}
					</div>

					<div className="cost-block">
						<p>Cost</p>
						<p>Rs.</p>
						<p className="cost-block-amounts">
							{payment.bookingPayment.vat_status !== "none"
								? rupeeConverter(
										actual_cost.toFixed(2),
										"summary"
								  )
								: "0.00"}
						</p>
						<p>VAT 13%</p>
						<p>Rs.</p>
						<p className="cost-block-amounts">
							{payment.bookingPayment.vat_status !== "none"
								? rupeeConverter(vat.toFixed(2), "summary")
								: "0.00"}
						</p>
						<p>Total Bill</p>
						<p>Rs.</p>
						<p className="cost-block-amounts">
							{payment.bookingPayment.vat_status !== "none"
								? rupeeConverter(
										actual_totalBill.toFixed(2),
										"summary"
								  )
								: "0.00"}
						</p>
					</div>
					{payment.bookingPayment.vat_status !== "none" ? (
						<div
							className="view-status-block vat"
							onClick={() =>
								history.push({
									pathname: "/vat-history",
									state: {
										event_id: bookingData.event_id,
									},
								})
							}
						>
							<p>VAT Recorded</p>
							<i className="fas fa-eye" />
						</div>
					) : (
						<div className="bg-default view-status-block">
							<p>VAT Pending</p>
							<i className="fas fa-pause-circle" />
						</div>
					)}
				</div>
			);
		}
	};
	// Test Vat Calculator
	const [vatCalculator, setVatCalculator] = useState(false);
	const _handleVatCalculator = () => {};

	// const _handleSideModalTest = () => {
	// 	setSideModalData({
	// 		customer_name: `${bookingData.customer_fname} ${bookingData.customer_lname}`,
	// 		contact_info: bookingData.customer_mobile,
	// 		booked_date_nepali: bookingData.booked_date_nepali,
	// 		booked_date_english: bookingData.booked_date_english,
	// 		book_time: bookingData.booked_time,
	// 		customerEmail: bookingList.v_company_customer.customer_email,
	// 		paid_amount: 1111,
	// 		booking_id: 4,
	// 		payment_id: 8,
	// 		transaction_type: "payment",
	// 		payment_type: "cash",
	// 		payment_date: moment().format("YYYY-MM-DD"),
	// 	});
	// 	setSideModal(true);
	// };

	//transfer Booking to date
	const _handleChangeDate = async () => {
		if (moveBooking !== "") {
			let transferData = {
				transfer_type: "booking",
				booking_info_id: bookingData.booking_info_id,
				booking_halls: bookingData.booking_halls,
				booked_time: bookingData.booked_time,
				booked_date_english: moveBooking,
			};

			let returned = await dispatch(transferBooking(transferData));
			if (returned.success) {
				setMoveBooking("");
			}
		} else {
			showToastMessage("Invalid Move Date.", "E");
		}
	};
	return (
		<div className="main-container">
			{isLoading && <Loader />}
			{error && <ErrorPage type="error" />}
			<div className="inner-container full mt-44px ">Event Bookings</div>
			<div className="inner-container full">
				<div className="flex flex-row gap-15px items-center flex-wrap ">
					{bookingList?.booking_informations?.map((map) => (
						<Calendar
							key={map.booking_info_id}
							type="booked"
							data={map}
							onClick={_onCalendarEventClick}
							selection={
								map.booking_info_id ===
								bookingData.booking_info_id
							}
						/>
					))}
					<button
						className="button-primary-icon ml-10px"
						onClick={addBookingToEvent}
					>
						<i className="fas fa-plus text-buttonSuccess "></i>
					</button>
				</div>
			</div>
			{!isEmptyObject(bookingList) && _renderBookingDetailsView()}

			<div className="inner-container full ">
				<hr className="default-hr" />
			</div>

			<div className="inner-container mt-30px ">
				{company_role && _renderPaymentSummary()}

				{((bookingData.booking_status === "complete" &&
					company_role !== "viewer") ||
					bookingData.booking_status !== "complete") && (
					<div className="main-inner-container md:hidden">
						<div className="inner-content cols">
							<button
								className="button-primary"
								style={{
									width: "100%",
									marginBottom: "15px",
								}}
								onClick={() =>
									_renderPrintPage(["Booking", "Invoice"])
								}
							>
								Print Booking Page
							</button>
							<button
								className="button-primary"
								style={{
									width: "100%",
									marginBottom: "15px",
								}}
								onClick={() =>
									_renderPrintPage(["Event", "Invoice"])
								}
							>
								Print Event Page
							</button>
							<hr />
							{bookingData.booking_status !== "canceled" && (
								<div
									style={{
										width: "100%",
										marginBottom: "15px",
										display: "flex",
										justifyContent: "space-evenly",
										alignItems: "center",
									}}
								>
									{bookingData?.booking_status !==
										"complete" &&
										bookingData?.booking_status !==
											"canceled" &&
										bookingData?.payment_status !==
											"complete" &&
										bookingData?.vat_status === "none" && (
											<>
												<button
													className="button-primary"
													style={{
														display: "flex",
														justifyContent:
															"space-evenly",
														alignItems: "center",
													}}
													onClick={() =>
														_handleChangeDate()
													}
												>
													Move Booking
												</button>
												<input
													name="Test"
													style={{
														textAlign: "center",
														backgroundColor:
															"transparent",
														borderBottom:
															"1px solid lightgrey",
													}}
													value={moveBooking}
													onChange={(e) =>
														setMoveBooking(
															e.target.value
														)
													}
													type="date"
												/>
											</>
										)}
								</div>
							)}

							{process.env.REACT_APP_ENV !== "production" && (
								<>
									<button
										className="button-primary"
										style={{
											width: "100%",
											marginBottom: "15px",
										}}
										onClick={() =>
											_reviewBooking(bookingInfoId)
										}
									>
										Review Booking
									</button>
									{/* <button
										className="button-primary"
										style={{
											width: "100%",
											marginBottom: "15px",
										}}
										onClick={() => _handleBookingRequest()}
									>
										Create Request
									</button> */}
									<button
										className="button-primary"
										style={{
											width: "100%",
											marginBottom: "15px",
										}}
										onClick={() => setVatCalculator(true)}
									>
										Calculator
									</button>
								</>
							)}
						</div>
					</div>
				)}
				{!isEmptyObject(bookingData) &&
					bookingData.booking_snacks.length > 0 &&
					(payment.bookingPayment.payment_status !== "complete" ||
						company_role !== "viewer") &&
					_renderMarginData()}
				{payment.bookingPayment.payment_status === "none" &&
					bookingData.booking_status !== "pending" &&
					_renderBookingQueue()}
			</div>

			{company_role !== "viewer" &&
				bookingData.booking_status === "complete" && (
					<div className="inner-container full ">
						<hr className="default-hr" />
					</div>
				)}
			{company_role !== "viewer" ? (
				<>
					{_renderMenu()}
					<div className="inner-container full ">
						<hr className="default-hr" />
					</div>
					{_renderService()}
					<div className="inner-container full ">
						<hr className="default-hr" />
					</div>
					{_renderDrink()}
					<div className="inner-container full ">
						<hr className="default-hr" />
					</div>
				</>
			) : (
				payment.bookingPayment.payment_status !== "complete" && (
					<>
						{_renderMenu()}
						<div className="inner-container full ">
							<hr className="default-hr" />
						</div>
						{_renderService()}
						<div className="inner-container full ">
							<hr className="default-hr" />
						</div>
						{_renderDrink()}
						<div className="inner-container full ">
							<hr className="default-hr" />
						</div>
					</>
				)
			)}
			<div className="inner-container full ">
				<DeactivateBooking />
			</div>

			<ConfirmationModal
				isVisible={openCompleteModel}
				handleClose={_handleBookingCompleteModelClose}
				handleShow={_handleBookingCompleteModelOpen}
				onClickConfirmation={_handleBookingComplete}
				type="completeBooking"
			/>
			{openQueueAddModel && (
				<AddBookingQueueModal
					bookingID={bookingData.booking_info_id}
					isVisible={openQueueAddModel}
					handleClose={_handleQueueModelClose}
				/>
			)}
			{openQueueModel && (
				<ViewBookingQueueModal
					data={selectedQueue}
					isVisible={openQueueModel}
					handleClose={_handleQueueModelClose}
					handleSwap={_handleQueueSwap}
				/>
			)}
			<AddVatModal
				data={bookingData}
				isVisible={vatAdjRecord}
				handleClose={_handleBookingVatAdjModelClose}
			/>
			{/* Test Calculator */}
			<VatCalculator
				isVisible={vatCalculator}
				handleClose={() => setVatCalculator(false)}
			/>

			{/* Review Booking */}
			<ReviewBooking
				reviewData={reviewData}
				isVisible={showReviewBookingModal}
				handleClose={_handleReviewBookingClose}
				handleShow={_handleReviewBookingShow}
			/>

			<SideModal
				isVisible={showSideModal}
				onModalClose={() => setSideModal(!showSideModal)}
				containerStyle={{ minWidth: "310px" }}
				title="Payment Preview"
				sideModalData={sideModalData}
				modelFor="payment"
			></SideModal>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import strings from "../../global/strings";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

export default function ConfirmationModal({
	type,
	onClickConfirmation,
	isVisible,
	handleClose,
	handleShow,
	modalInfo,
}) {
	const [title, setTitle] = useState("");
	const [message, setMessage] = useState("");

	useEffect(() => {
		switch (type) {
			case "menu":
				setTitle(strings.deleteMenu.title);
				setMessage(strings.deleteMenu.message);
				break;
			case "service":
				setTitle(strings.deleteService.title);
				setMessage(strings.deleteService.message);
				break;
			case "drink":
				setTitle(strings.deleteDrink.title);
				setMessage(strings.deleteDrink.message);
				break;
			case "deleteBooking":
				setTitle(strings.deleteBooking.title);
				setMessage(strings.deleteBooking.message);
				break;
			case "cancelBooking":
				setTitle(strings.cancelBooking.title);
				setMessage(strings.cancelBooking.message);
				break;
			case "reopenBooking":
				setTitle(strings.reopenBooking.title);
				setMessage(strings.reopenBooking.message);
				break;
			case "addHall":
				if (modalInfo.hallName !== undefined) {
					let hallNamArr = modalInfo.hallName.split("H");
					let num = parseInt(hallNamArr[1]) + 1;
					setTitle("Add New Hall?");
					setMessage(`Are you sure you want to add H${num}?`);
				}
				break;
			case "deleteCustomer":
				setTitle(strings.deleteCustomer.title);
				setMessage(strings.deleteCustomer.message);
				break;
			case "deleteEmployee":
				setTitle(strings.deleteEmployee.title);
				setMessage(strings.deleteEmployee.message);
				break;
			case "deletePayment":
				setTitle(strings.deletePayment.title);
				setMessage(strings.deletePayment.message);
				break;
			case "deleteVatPayment":
				setTitle(strings.deleteVatPayment.title);
				setMessage(strings.deleteVatPayment.message);
				break;
			case "changeEmpStatus":
				setTitle(strings.changeEmpStatus.title);
				setMessage(strings.changeEmpStatus.message);
				break;
			case "transferOwnership":
				setTitle(strings.transferOwnership.title);
				setMessage(strings.transferOwnership.message);
				break;

			case "deleteVendor":
				setTitle(strings.deleteVendor.title);
				setMessage(strings.deleteVendor.message);
				break;

			case "completeBooking":
				setTitle(strings.completeBooking.title);
				setMessage(strings.completeBooking.message);
				break;

			case "addQueueBooking":
				setTitle(strings.addQueueBooking.title);
				setMessage(strings.addQueueBooking.message);
				break;

			default:
				break;
		}
	}, [type, modalInfo]);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
		},
	};
	const onConfirmClick = () => {
		if (type === "menu" || type === "service") {
			onClickConfirmation(type);
		} else if (type === "addHall") {
			let hallNamArr = modalInfo.hallName.split("H");
			let num = parseInt(hallNamArr[1]) + 1;
			let hallInfo = {
				venue_info_id: modalInfo.venueInfoId,
				hall_name: "H" + num,
			};
			onClickConfirmation(hallInfo);
		} else {
			onClickConfirmation();
		}
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isVisible}
			onAfterOpen={handleShow}
			onRequestClose={handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="main-modal-wrapper bg-default">
				<div className="modal-title">{title}</div>
				<div className="modal-message">{message}</div>
				<div className="modal-two-button-wrapper">
					<button className="button-primary" onClick={handleClose}>
						Cancel
					</button>
					<button className="button-primary" onClick={onConfirmClick}>
						Yes
					</button>
				</div>
			</div>
		</Modal>
	);
}

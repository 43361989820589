import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
// import FormSelectBox from "../common/select-box/form-select-input.component";
import strings from "../../global/strings";
import FormInputTextArea from "../common/text-input/form-textarea-input.component";
import { useDispatch } from "react-redux";
import { editPayment } from "../../redux/actions/payment-action";
import "./modal.style.scss";
import PaymentTypeRadio from "../common/radio-button/payment-type.radio";
import NepaliCalendarPopUp from "../common/calendar/nepali-cal-popup";
import moment from "moment";

import NibjarNepaliDate from "../../global/nibjar-nepali-date/nibjar-nepali-converter";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		overflow: "hidden",
	},
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const refundSchema = yup.object().shape({
	payment_type: yup.string().required("Please select payment option"),
	paid_amount: yup
		.number()
		.required("Amount is required")
		.typeError("Must be a number")
		.positive("Must be greater than zero"),
	cheque_number: yup.string().when("payment_type", {
		is: (value) => value === "cheque",
		then: yup.string().required("Please enter cheque number"),
	}),
});

function EditPaymentModal(props) {
	const [dateSwitcher, setDateSwitcher] = useState("EN");
	const [paymentDate, setPaymentDate] = useState("");

	const dispatch = useDispatch();
	// co?nst { bookingData } = useSelector((state) => state.booking);
	// const { bookingPayment } = useSelector((state) => state.payment);
	const { register, handleSubmit, errors, reset, watch } = useForm({
		defaultValues: {
			payment_type: "",
			paid_amount: "",
			cheque_number: "",
			createdAt: "",
		},
		mode: "onSubmit",
		resolver: yupResolver(refundSchema),
	});
	let paymentType = watch("payment_type");

	useEffect(() => {
		reset({
			...props.paymentInfo,
			createdAt: moment(props.paymentInfo.createdAt).format("YYYY-MM-DD"),
		});

		setPaymentDate(
			moment(props.paymentInfo.createdAt).format("YYYY-MM-DD")
		);
	}, [props, reset]);

	const _editPayment = (details) => {
		let info = {
			payment_info_id: props.paymentInfo.payment_info_id,
			booking_info_id: props.paymentInfo.booking_info_id,
			venue_info_id: props.paymentInfo.venue_info_id,
			...details,
			createdAt: paymentDate,
		};

		dispatch(editPayment(info));
		props.handleClose();
	};

	const _handleSwitchCalendar = () => {
		//check to switch between AD and BS dates for display
		if (dateSwitcher === "NP") {
			setDateSwitcher("EN");
		} else {
			setDateSwitcher("NP");
		}
	};

	const dateOnchange = (data) => {
		setPaymentDate(data);
	};

	const dateADtoBS = (paymentDateEng) => {
		let decreasedMonth = moment(paymentDateEng)
			.subtract(1, "month")
			.format("YYYY-MM-DD");
		let a = decreasedMonth.split("-");
		let b = new NibjarNepaliDate(new Date(a[0], a[1], a[2])).format(
			"YYYY-MM-DD"
		);

		return b;
	};
	return (
		<Modal
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<form onSubmit={handleSubmit(_editPayment)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">Edit Payment</div>
					</div>
					<div className="form-body">
						{/* <div className="form-input-container">
							<div className="form-input-container inner">
								<FormTextInput
									label="Total Amount"
									variant="form-md"
									
									disabled
								/>
								<FormTextInput
									variant="form-md"
									label="Total Paid"
									
									disabled={true}
								/>
							</div>
						</div> */}

						<div className="form-input-container">
							<div className="form-input-container inner">
								<PaymentTypeRadio
									name="payment_type"
									ref={register}
									paymentType={strings.paymentType}
									error={errors?.payment_type?.message}
								/>
							</div>
						</div>

						<div className="form-input-container">
							<div
								className="form-input-container inner"
								style={{
									flexWrap: "wrap",
									justifyContent: "flex-start",
									gap: "10px",
								}}
							>
								<FormTextInput
									type="text"
									label="Paid Amount*[Rs.]"
									name="paid_amount"
									variant="form-md"
									ref={register}
									error={errors?.paid_amount?.message}
								/>
								<FormTextInput
									label="Cheque Number"
									name="cheque_number"
									variant="form-md"
									ref={register}
									error={errors?.cheque_number?.message}
									disabled={paymentType !== "cheque"}
								/>
								<div className="m-payment-field-wrapper">
									<label>
										{dateSwitcher === "NP"
											? "Date of Issue (B.S)"
											: "Date of Issue (A.D)"}
									</label>
									<div className="payment-date-input">
										<div
											className="date-inputs"
											style={{
												marginTop:
													dateSwitcher === "NP" &&
													"8px",
											}}
										>
											{dateSwitcher === "NP" ? (
												<NepaliCalendarPopUp
													dateSubmitFunction={
														dateOnchange
													}
													type="en"
													field={true}
													date={
														paymentDate &&
														dateADtoBS(paymentDate)
													}
												/>
											) : (
												<FormTextInput
													name="createdAt"
													type="date"
													variant="form-md fullWidth"
													value={paymentDate}
													onChange={(d) => {
														setPaymentDate(
															d.target.value
														);
													}}
													error={
														errors?.createdAt
															?.message
													}
												/>
											)}
										</div>

										<div
											className="payment-date-switch"
											onClick={_handleSwitchCalendar}
										>
											<i className="fas fa-exchange-alt"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="form-input-container inner">
								<FormInputTextArea
									label="Notes"
									placeholder="Notes"
									variant="form-notes"
									name="payment_notes"
									ref={register}
									error={errors?.payment_notes?.message}
								/>
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => props.handleClose()}
						>
							Close
						</button>
						<button type="submit" className="button-primary">
							Save
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
}

export default EditPaymentModal;

import React from "react";
import { Router } from "react-router";
import history from "./redux/history";
import { Provider } from "react-redux";
import store from "./redux/store";

// import { socket, SocketContext } from "./Socket";

const Root = (props) => {
	return (
		// <Router history={history}>
		// 	<Provider store={store}>{props.children}</Provider>
		// </Router>
		// <SocketContext.Provider value={socket}>
		<Router history={history}>
			<Provider store={store}>{props.children}</Provider>
		</Router>
		// </SocketContext.Provider>
	);
};

export default Root;

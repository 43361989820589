import {
	FETCH_COMPANY_REQUEST,
	FETCH_COMPANY_SUCCESS,
	FETCH_COMPANY_REQUEST_SUCCESS,
	PIN_CHANGE_SUCCESS,
	FETCH_COMPANY_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
	companyDetails: {},
	error: "",
};
export default function companySettingReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_COMPANY_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_COMPANY_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case FETCH_COMPANY_SUCCESS:
			return {
				isLoading: false,
				companyDetails: action.payload,
				error: "",
			};
		case PIN_CHANGE_SUCCESS:
			return {
				isLoading: false,
				companyDetails: state.companyDetails,
				error: "",
			};
		case FETCH_COMPANY_ERROR:
			return {
				isLoading: false,
				companyDetails: state.companyDetails,
				error: action.payload,
			};

		default:
			return state;
	}
}

import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./vat-calculator.style.scss";

import TextInputWithErrorComponent from "../common/text-input/text-input-with-error.component";

const vatSchema = yup.object().shape({
	a_pax: yup.number().typeError("Must be a number"),
	a_rate: yup.number().typeError("Must be a number"),
	a_service: yup.number().typeError("Must be a number"),
	vat_amount: yup.number().typeError("Must be a number"),
	total_bill: yup.number().typeError("Must be a number"),
});

export default function VatCalculator({
	from = "",
	returnTo = "",
	isVisible,
	handleClose,
	returnFunction,
}) {
	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(vatSchema),
	});
	//input store state
	const [calcInputs, setCalcInputs] = useState({});
	//condition to disable PRS or V TB
	const [disable, setDisable] = useState([false, false]);
	//Error message if any
	const [message, setMessage] = useState("");
	//main calculation store state
	const [calculation, setCalculation] = useState([]);
	//hide inputs show options or hide options and show input
	const [calc, setCalc] = useState(true);

	// let paymentOption = watch("payment_option");

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
		h2: {
			color: "red",
		},
	};
	const possibleCombinationMessage = {
		label: "Possible Combinations",
		message: [
			"Enter Pax & Rate & Service to get Calculated Vat",
			"Enter Pax & Vat to calculate Rate options",
			"Enter Vat to calculate Pax and Rate combinations",
		],
	};

	useEffect(() => {
		setCalcInputs({
			a_pax: 0,
			a_rate: 0,
			a_service: 0,
			vat_amount: 0,
			total_bill: 0,
		});
		// Set Static options state
		let temp = [
			{
				vat_amount: 13000,
				total_bill: 25000,
				options: [
					{
						a_pax: 400,
						a_rate: 500,
						a_service: 0,
						selection: false,
					},
					{
						a_pax: 401,
						a_rate: 500,
						a_service: 0,
						selection: false,
					},
					{
						a_pax: 402,
						a_rate: 500,
						a_service: 0,
						selection: true,
					},
					{
						a_pax: 403,
						a_rate: 500,
						a_service: 0,
						selection: false,
					},
					{
						a_pax: 404,
						a_rate: 500,
						a_service: 0,
						selection: true,
					},
				],
			},
			{
				vat_amount: 13001,
				total_bill: 25001,
				options: [
					{
						a_pax: 410,
						a_rate: 500,
						a_service: 0,
						selection: false,
					},
					{
						a_pax: 411,
						a_rate: 500,
						a_service: 0,
						selection: false,
					},
					{
						a_pax: 412,
						a_rate: 500,
						a_service: 0,
						selection: true,
					},
					{
						a_pax: 413,
						a_rate: 500,
						a_service: 0,
						selection: false,
					},
					{
						a_pax: 414,
						a_rate: 500,
						a_service: 0,
						selection: true,
					},
				],
			},
		];
		setCalculation(temp);
	}, []);

	useEffect(() => {}, [calculation]);

	function _handleInputChange(e, type) {
		switch (type) {
			case "a_pax":
				setCalcInputs({ ...calcInputs, a_pax: e.target.value });
				break;
			case "a_rate":
				setCalcInputs({ ...calcInputs, a_rate: e.target.value });
				break;
			case "a_service":
				setCalcInputs({ ...calcInputs, a_service: e.target.value });
				break;
			case "vat_amount":
				setCalcInputs({ ...calcInputs, vat_amount: e.target.value });
				break;
			case "total_bill":
				setCalcInputs({ ...calcInputs, total_bill: e.target.value });
				break;
			default:
				break;
		}
	}

	//Main calculate trigger state
	const _handleCalculate = ({
		a_pax,
		a_rate,
		a_service,
		vat_amount,
		total_bill,
	}) => {
		/*
		let prs = a_pax > 0 && a_rate > 0 && a_service > 0;
		let vb = vat_amount > 0 && total_bill > 0;

		if (prs && vb) {
			setMessage(
				"Please remove vat and total bill or Pax Rate service amounts"
			);
		} else {
			console.log("submitted");
		}*/
		calc ? setCalc(false) : setCalc(true);
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={isVisible}
			onRequestClose={handleClose}
			contentLabel="Calculator Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">VAT CALCULATOR</div>
				</div>
				<div className="form-body overflow-hidden">
					{calc && (
						<div className="form-input-container ">
							<div className="form-input-container inner">
								<TextInputWithErrorComponent
									name="a_pax"
									type="number"
									variant="form-xs edit "
									placeholder="Actual Pax"
									label="Actual Pax"
									value={calcInputs.a_pax}
									error={errors?.a_pax?.message}
									ref={register}
									disabled={disable[0]}
									onChange={(e) =>
										_handleInputChange(e, "a_pax")
									}
								/>
								<TextInputWithErrorComponent
									name="a_rate"
									type="number"
									variant="form-xs edit "
									placeholder="Actual Rate"
									label="Actual Rate"
									value={calcInputs.a_rate}
									error={errors?.a_rate?.message}
									ref={register}
									disabled={disable[0]}
									onChange={(e) =>
										_handleInputChange(e, "a_rate")
									}
								/>
								<TextInputWithErrorComponent
									name="a_service"
									type="number"
									variant="form-xs edit "
									placeholder="Actual Service"
									label="Actual Service"
									value={calcInputs.a_service}
									error={errors?.a_service?.message}
									ref={register}
									disabled={disable[0]}
									onChange={(e) =>
										_handleInputChange(e, "a_service")
									}
								/>
							</div>
							<div className="form-input-container inner">
								<TextInputWithErrorComponent
									name="vat_amount"
									type="number"
									variant="form-md edit "
									placeholder="VAT Amount"
									label="VAT Amount"
									value={calcInputs.vat_amount}
									error={errors?.vat_amount?.message}
									ref={register}
									disabled={disable[1]}
									onChange={(e) =>
										_handleInputChange(e, "vat_amount")
									}
								/>
								<TextInputWithErrorComponent
									name="total_bill"
									type="number"
									variant="form-md edit "
									placeholder="Total Bill"
									label="Total Bill"
									value={calcInputs.total_bill}
									error={errors?.total_bill?.message}
									ref={register}
									disabled={disable[1]}
									onChange={(e) =>
										_handleInputChange(e, "total_bill")
									}
								/>
							</div>
						</div>
					)}
					<div>{message}</div>
					<div
						className="bg-default vat-calculate-button"
						onClick={handleSubmit(_handleCalculate)}
					>
						{calc ? "Calculate" : "Re-Calculate"}
					</div>
					<hr />
					{!calc && (
						<div className="option-display">
							{calculation.length > 0 &&
								calculation.map((v, i) => (
									<div
										className="option-title-container"
										key={i}
									>
										<div className="option-title">
											<div>
												{"Vat : Rs. "}
												{v.vat_amount}
											</div>
											<div>
												{" Bill : Rs. "}
												{v.total_bill}
											</div>
										</div>
										<div className="option-items-container">
											{v.options.length > 0 &&
												v.options.map((o) => (
													<div
														className={`bg-default option-items ${
															o.selection &&
															"selected"
														}`}
													>
														<div>
															Pax : {o.a_pax}
														</div>
														<div>
															Rate : {o.a_rate}
														</div>
														<div>
															Service :{" "}
															{o.a_service}
														</div>
													</div>
												))}
										</div>
									</div>
								))}
						</div>
					)}
					{calc && (
						<div className="option-display">
							<div className="option-message-title">
								{possibleCombinationMessage.label}
							</div>
							<div className="option-message">
								{possibleCombinationMessage.message.map(
									(msg, i) => (
										<li key={i}>{msg}</li>
									)
								)}
							</div>
						</div>
					)}
				</div>
				<div className="form-footer two">
					<button
						onClick={() => {
							handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						className="button-primary"
						// onClick={console.log("do Nothing")}
					>
						Complete
					</button>
				</div>
			</div>
		</Modal>
	);
}

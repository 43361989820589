/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./switch-user.style.scss";
import { useHistory } from "react-router-dom";

import Loader from "../../components/loader/loader.component";
import strings from "../../global/strings";
import "../../components/modals/modal.style.scss";
import axios from "axios";
import showToastMessage from "../../global/showToastMessage";
import { isEmptyObject, sessionInfo } from "../../global/function";
import { fetchLoginError } from "../../redux/actions/login-action";
import { useDispatch } from "react-redux";
import { clearAllBookings } from "../../redux/actions/event-save.action";

// import { socket } from "../../Socket";

export default function SwitchUser() {
	const [isLoading, setIsLoading] = useState(false);
	const [companyEmployees, setCompanyEmployees] = useState([]);
	const history = useHistory();
	const dispatch = useDispatch();
	const [sessionData, setSessionData] = useState({});

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setSessionData({
					currentCompanyEmpId: data.company_employee_id,
					user_info_id: data.user_info_id,
					company_info_id: data.company_info_id,
					venues: data.venues,
					token: data.token,
				});
				getCompanyEmployee(data);
			});
		}

		fetchSessionData();
	}, []);

	const getCompanyEmployee = (data) => {
		setIsLoading(true);
		axios
			.get(
				`${strings.url}user/company/employees?user_info_id=${data.user_info_id}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: data.token,
					},
				}
			)
			.then(async (response) => {
				setIsLoading(false);

				if (response.data.success) {
					setCompanyEmployees(response.data.result.company_employees);
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;
				setIsLoading(false);
				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					showToastMessage(error.message, "E");
				}
			});
	};

	const switchs = (company) => {
		setIsLoading(true);
		axios
			.get(
				`${strings.url}user/switch/company?company_info_id=${company.company_info_id}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: sessionData.token,
					},
				}
			)
			.then(async (response) => {
				setIsLoading(false);
				if (response.data.success) {
					// socket.emit("joinRoom", `${company.company_info_id}`); // need venueIDs also
					dispatch(clearAllBookings());

					localStorage.setItem("token", response.data.token);
					let venue = response.data.venues || [];

					let venuesArray = [];
					venue.map((v) => {
						if (v.venue_employee_status === "active") {
							venuesArray.push(v.venue_info_id);
						}
						return null;
					});
					// joining room for logged in user
					// socket.emit(
					// 	"joinRoom",
					// 	`${sessionData.company_info_id}`,
					// 	venuesArray
					// );

					history.push({ pathname: "/dashboard" });
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;
				setIsLoading(false);

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					showToastMessage(error.message, "E");
				}
			});
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="inner-container full">
				<div className="switch-user-wrapper">
					<div className="switch-user-form-wrapper bg-default">
						<div className="modal-title">
							{strings.switchUser.title}
						</div>
						<div className="modal-sub-title">
							{strings.switchUser.message}
						</div>
						<div className="company-list-container">
							{companyEmployees.map((c) => {
								return (
									<div
										className={
											c.company_employee_id ===
											sessionData.currentCompanyEmpId
												? "company-list-wrapper selected"
												: "company-list-wrapper"
										}
										key={c.company_info_id}
										onClick={() => switchs(c)}
									>
										<div className="company-name-wrapper">
											{c.company_info.company_name}
										</div>
										<div className="company-address-wrapper">
											{c.company_info.company_address}
										</div>
										<div className="company-role-wrapper">
											{c.company_role}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchRequestComponent from "../../../components/search/search-request";
import RequestTable from "../../../components/tables/request.table";
import { fetchRequestByLocationId } from "../../../redux/actions/request.action";

function RequestTab() {
	const { requestList } = useSelector((s) => s.request);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchRequestByLocationId(localStorage.getItem("location_id")));
	}, [dispatch]);
	return (
		<div className="">
			<div className="inner-container full">
				<SearchRequestComponent />
			</div>

			<div className="inner-container full">
				{requestList.length > 0 ? (
					<RequestTable list={requestList} />
				) : (
					"No Request or venue has not been selected"
				)}
			</div>
		</div>
	);
}

export default RequestTab;

import React, { useState, useEffect } from "react";
import AddVendorModal from "../../components/modals/add-vendor.modal";
import SearchVendorComponent from "../../components/search/search-vendor.component";
import VendorTable from "../../components/tables/vendor.table";
import { useDispatch, useSelector } from "react-redux";
import { deleteVendor, fetchVendors } from "../../redux/actions/vendor.action";
import Loader from "../../components/loader/loader.component";
import ConfirmationModal from "../../components/modals/confirmation-modal.component";
import { useHistory } from "react-router-dom";

function VendorPage() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [showItemModal, setShowItemModal] = useState(false);
	const [confirmationModalFlag, setConfirmationModalFlag] = useState(false);

	const { isLoading, vendor, vendorToEdit } = useSelector(
		(state) => state.vendor
	);

	useEffect(() => {
		dispatch(fetchVendors());
	}, [dispatch]);

	const toggleShowItemModal = () => {
		setShowItemModal(true);
	};

	const toggleHideItemModal = () => {
		setShowItemModal(false);
	};

	const handleDeleteEmployeeModalShow = () => setConfirmationModalFlag(true);

	const handleDeleteEmployeeModalClose = () => {
		setConfirmationModalFlag(false);
	};

	const _deleteEmployee = async () => {
		await dispatch(deleteVendor(vendorToEdit));
		handleDeleteEmployeeModalClose();
	};
	return (
		<div className="main-container">
			{isLoading && <Loader />}
			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => history.goBack()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt; <span className="current-page-path">Vendor Page</span>
				</div>
			</div>
			<div className="inner-container full mt-52px">
				<SearchVendorComponent addVendorClick={toggleShowItemModal} />
			</div>
			<div className="inner-container full mt-52px">
				<VendorTable
					list={vendor}
					onEditClick={toggleShowItemModal}
					onClickDelete={handleDeleteEmployeeModalShow}
				/>
			</div>
			<AddVendorModal
				handleShow={toggleShowItemModal}
				handleClose={toggleHideItemModal}
				isVisible={showItemModal}
			/>
			<ConfirmationModal
				type="deleteVendor"
				isVisible={confirmationModalFlag}
				handleClose={handleDeleteEmployeeModalClose}
				handleShow={handleDeleteEmployeeModalShow}
				onClickConfirmation={_deleteEmployee}
			/>
		</div>
	);
}

export default VendorPage;

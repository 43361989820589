import React from "react";
import "./menu-svg.scss";

export default function OtherNonVeg({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 15.1 20"
            id="meat">
            <g >
                <path d="M14.18,15a1.83,1.83,0,0,1-1.83,3.18.13.13,0,0,1-.06,0v.07a1.84,1.84,0,1,1-3.67,0,3.66,3.66,0,0,1,.56-1.79L6.91,12.45A9.7,9.7,0,0,1,.75,8.18C-.67,5.73,0,2,2.4.54A4.55,4.55,0,0,1,6.05.24,3.52,3.52,0,0,0,5.56,1a3.48,3.48,0,0,0,.11,3.31A3.47,3.47,0,0,0,7.31,5.76a4.22,4.22,0,0,0,.6.2,3.5,3.5,0,0,0,2.57-.38l.06,0a8.44,8.44,0,0,1-.44,5.31l2.14,3.7A3.81,3.81,0,0,1,14.18,15Z" />
            </g>
        </svg>
    );
}

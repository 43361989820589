import {
	convertFromAD,
	convertFromBS,
	format,
	parse
} from "./nibjar-nepali-helper.js";

const npDateSymbol = Symbol('npDate')
const convertToBSMethod = Symbol('convertFromAD()')
const convertToADMethod = Symbol('convertFromBS()')
const setAdBs = Symbol('setADBS()')
const setDayYearMonth = Symbol('setDayYearMonth()')

export default class NibjarNepaliDate {
	#jsDate;
	#date;
	#year;
	#month;
	#day;

	[npDateSymbol];

	constructor(...args) {
		const constructorError = new Error('Invalid constructor arguments')
		if (args.length === 0) {
			// No arguments provided
			this[convertToBSMethod](new Date())
		} else if (args.length === 1 && (typeof args[0] === 'string' || typeof args[0] === 'number' || args[0] instanceof Date)) {
			// Single argument of type string, number, or Date
			const argument = args[0]
			switch (typeof argument) {
				case 'number':
					this[convertToBSMethod](new Date(argument))
					break
				case 'string':
					const { date, year, month } = parse(argument)
					this[setDayYearMonth](year, month, date)
					this[convertToADMethod]()
					break
				case 'object':
					if (argument instanceof Date) {
						this[convertToBSMethod](argument)
					} else {
						throw constructorError
					}
					break
				default:
					throw constructorError
			}
		} else if (args.length === 3 && typeof args[0] === 'number' && typeof args[1] === 'number' && typeof args[2] === 'number') {
			// Three arguments for year, monthIndex, and date
			this[setDayYearMonth](args[0], args[1], args[2])
			this[convertToADMethod]()
		} else {
			throw constructorError;
		}
	}

	[setDayYearMonth](year, month = 0, date = 1, day = 0) {
		this.#year = year
		this.#month = month
		this.#date = date
		this.#day = day

		this[npDateSymbol] = this.format('ddd DD, MMMM YYYY');
	}

	/**
	 * Returns Javascript Date converted from nepali date.
	 */
	toJsDate() {
		return this.#jsDate
	}
	/**
	 * Get Nepali date for the month
	 */
	getDate() {
		return this.#date
	}
	/**
	 * Get Nepali date year.
	 */
	getYear() {
		return this.#year
	}

	/**
	 * Get Week day index for the date.
	 */
	getDay() {
		return this.#day
	}

	/**
	 * Get Nepali month index.
	 *
	 * ```
	 * Baisakh => 0
	 * Jestha => 1
	 * Asar => 2
	 * Shrawan => 3
	 * Bhadra => 4
	 * Aswin => 5
	 * Kartik => 6
	 * Mangsir => 7
	 * Poush => 8
	 * Magh => 9
	 * Falgun => 10
	 * Chaitra => 11
	 * ```
	 */
	getMonth() {
		return this.#month
	}

	/**
	 * Returns an object with AD and BS object implementing IYearMonthDate
	 *
	 * Example:
	 *
	 * ```js
	 * {
	 *     BS: {
	 *         year: 2052,
	 *         month: 10,
	 *         date: 10,
	 *         day: 0
	 *     },
	 *     AD: {
	 *         year: 2019,
	 *         month: 10,
	 *         date: 10,
	 *         day: 0
	 *     },
	 *
	 * }
	 * ```
	 */
	getDateObject() {
		return {
			BS: this.getBS(),
			AD: this.getAD()
		}
	}
	/**
	 * Returns Nepali date fields in an object implementing IYearMonthDate
	 *
	 * ```js
	 * {
	 *     year: 2052,
	 *     month: 10,
	 *     date: 10,
	 *     day: 0
	 * }
	 * ```
	 */
	getBS() {
		return {
			year: this.#year,
			month: this.#month,
			date: this.#date,
			day: this.#day
		}
	}
	/**
	 * Returns AD date fields in an object implementing IYearMonthDate
	 *
	 * ```js
	 * {
	 *     year: 2019,
	 *     month: 10,
	 *     date: 10,
	 *     day: 0
	 * }
	 * ```
	 */
	getAD() {
		return {
			year: this.#jsDate.getFullYear(),
			month: this.#jsDate.getMonth(),
			date: this.#jsDate.getDate(),
			day: this.#jsDate.getDay()
		}
	}

	/**
	 * Set date in the current date object. It can be positive or negative. Positive values within the month
	 * will update the date only and more then month mill increment month and year. Negative value will deduct month and year depending on the value.
	 * It is similar to javascript Date API.
	 *
	 * Example:
	 * ```js
	 * let a = new NibjarNepaliDate(2054,10,10);
	 * a.setDate(11); // will make date NepaliDate(2054,10,11);
	 * a.setDate(-1); // will make date NepaliDate(2054,9,29);
	 * a.setDate(45); // will make date NepaliDate(2054,10,15);
	 * ```
	 * @param date positive or negative integer value to set date
	 */
	setDate(date) {
		const oldDate = this.#date;
		try {
			this.#date = date
			this[convertToADMethod]()
		} catch (e) {
			this.#date = oldDate
			throw e
		}
	}

	/**
	 * Set month in the current date object. It can be positive or negative. Positive values within the month
	 * will update the month only and more then month mill increment month and year. Negative value will deduct month and year depending on the value.
	 * It is similar to javascript Date API.
	 *
	 * Example:
	 * ```js
	 * let a = new NibjarNepaliDate(2054,10,10);
	 * a.setMonth(1); // will make date NepaliDate(2054,11,10);
	 * a.setMonth(-1); // will make date NepaliDate(2053,11,10);
	 * a.setMonth(12); // will make date NepaliDate(2054,0,10);
	 * ```
	 * @param date positive or negative integer value to set month
	 */
	setMonth(month) {
		const oldMonth = this.#month
		try {
			this.#month = month
			this[convertToADMethod]()
		} catch (e) {
			this.#month = oldMonth
			throw e
		}
	}

	/**
	 * Set year in the current date object. It only takes positive value i.e Nepali Year
	 *
	 * Example:
	 * ```js
	 * let a = new NibjarNepaliDate(2054,10,10);
	 * a.setYear(2053); // will make date NepaliDate(2053,10,15);
	 * ```
	 * @param date positive integer value to set year
	 */
	setYear(year) {
		const oldYear = this.#year
		try {
			this.#year = year
			this[convertToADMethod]()
		} catch (e) {
			this.#year = oldYear
			throw e
		}
	}

	/**
	 * Format Nepali date string based on format string.
	 * ```
	 * YYYY - 4 digit of year (2077)
	 * YYY  - 3 digit of year (077)
	 * YY   - 2 digit of year (77)
	 * M    - month number (1 - 12)
	 * MM   - month number with 0 padding (01 - 12)
	 * MMM  - short month name (Bai, Jes, Asa, Shr, etc.)
	 * MMMM - full month name (Baisakh, Jestha, Asar, ...)
	 * D    - Day of Month (1, 2, ... 31, 32)
	 * DD   - Day of Month with zero padding (01, 02, ...)
	 * d    - Week day (0, 1, 2, 3, 4, 5, 6)
	 * dd   - Week day in short format (Sun, Mon, ..)
	 * ddd  - Week day in long format (Sunday, Monday, ...)
	 * ```
	 * Set language to 'np' for nepali format. The strings can be combined in any way to create desired format.
	 * ```js
	 * let a = new NibjarNepaliDate(2054,10,10);
	 * a.format('YYYY/MM/DD') // '2054/11/10'
	 * a.format('YYYY MM DD') // '2054 11 10'
	 * a.format('YYYY') // '2054'
	 * a.format('ddd DD, MMMM YYYY') // 'Sunday 10, Falgun 2054'
	 * a.format('To\\day is ddd DD, MMMM YYYY') // 'Today is Sunday 10, Falgun 2054', Note: use '\\' to escape [YMDd]
	 * a.format('DD/MM/YYYY', 'np') //' १०/११/२०५४'
	 * a.format('dd', 'np') // 'आइतबार'
	 * a.format('ddd DD, MMMM YYYY','np') // 'आइतबार १०, फाल्गुण २०५४'
	 * // Set static variable to 'np' for default Nepali language
	 * NepaliDate.language = 'np'
	 * a.format('ddd DD, MMMM YYYY') // 'आइतबार १०, फाल्गुण २०५४'
	 * ```
	 * @param formatString
	 * @param language en | np
	 */
	format(formatString, language) {
		return format(this.getBS(), formatString, language)
	}

	[convertToBSMethod](date) {
		const { AD, BS } = convertFromAD(date)
		this[setAdBs](AD, BS)
	}

	[setAdBs](AD, BS) {
		this[setDayYearMonth](BS.year, BS.month, BS.date, BS.day)
		this.#jsDate = new Date(AD.year, AD.month, AD.date)
	}

	[convertToADMethod]() {
		const { AD, BS } = convertFromBS({
			year: this.#year,
			month: this.#month,
			date: this.#date
		})
		this[setAdBs](AD, BS)
	}

	toString() {
		return this.format('ddd DD, MMMM YYYY')
	}

}
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SearchOrderComponent from "../../../components/search/search-order";
import OrderTable from "../../../components/tables/order.table";
import { fetchOrderByLocationId } from "../../../redux/actions/order.action";

function OrderTab() {
	const { orderList } = useSelector((s) => s.order);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchOrderByLocationId(localStorage.getItem("location_id")));
	}, [dispatch]);

	return (
		<div className="main-container">
			<div className="inner-container full">
				<SearchOrderComponent />
			</div>
			<div className="inner-container full">
				{orderList.length > 0 ? (
					<OrderTable list={orderList} />
				) : (
					"No Order or else venue has not been selecteds"
				)}
			</div>
		</div>
	);
}

export default OrderTab;

import React from "react";
import { useSelector } from "react-redux";
import SearchInventoryComponent from "../../../components/search/search-inventory";
import InventoryTable from "../../../components/tables/inventory-item.table";
import { isEmptyObject } from "../../../global/function";

function InventoryTab({ toggleShowItemModal }) {
	const { inventories, dropdownCat } = useSelector((s) => s.inventory);

	return (
		<div>
			<div className="inner-container full">
				<SearchInventoryComponent />
			</div>

			<div className="inner-container full">
				{!isEmptyObject(inventories) ? (
					Object.keys(inventories).map((cat) => (
						<div key={cat}>
							<label htmlFor="">{cat}</label>
							<InventoryTable
								list={inventories[cat]}
								onEditClick={toggleShowItemModal}
							/>
						</div>
					))
				) : (
					<div className="inner-container full">
						Please select venue or there are no inventories.
						<br />
						{dropdownCat.length <= 0 &&
							"Please add categories as well."}
					</div>
				)}
			</div>

			{/*<AddCategoryModal
				handleShow={toggleShowCategoryModal}
				handleClose={toggleHideCategoryModal}
				isVisible={showCategoryModal}
			/>
			 <AddInventoryItemModal
				handleShow={toggleShowItemModal}
				handleClose={toggleHideItemModal}
				isVisible={showItemModal}
			/> */}
		</div>
	);
}

export default InventoryTab;

import React from "react";
import "./select-box.style.scss";

function SelectInput(props) {
	const returnOptions = () => {
		return props.options.map((row, i) => (
			<option value={row.id} key={i}>
				{row.value}
			</option>
		));
	};

	return (
		<div className="select-input-item">
			<p className="select-input-label">{props.label}</p>
			<div
				className={`custom-select-wrapper ${
					props.disabled && "disabled"
				}`}
			>
				<select
					key={props.id}
					className="custom-select-box-wrapper"
					{...props}
				>
					<option value="">- Select -</option>
					{returnOptions()}
				</select>
			</div>
			{props.error && (
				<div className="custom-validation-error">{props.error}</div>
			)}
		</div>
	);
}
export default SelectInput;

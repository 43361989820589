import React, { useEffect } from "react";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

export default function SearchVatHistoryComponent({
	searchPayment,
	resetSearch,
}) {
	const dispatch = useDispatch();

	const { register, handleSubmit, reset } = useForm();

	const { vatSearchParameters } = useSelector((state) => state.payment);

	useEffect(() => {
		reset(vatSearchParameters);
	}, [vatSearchParameters]);

	const _clearPaymentHistorySearch = () => {
		reset({
			vat_payment_id: "",
			event_id: "",
			date_from: "",
			date_to: "",
			customer_fname: "",
			customer_lname: "",
			vat_bill_number: "",
		});
		resetSearch();
	};

	function _searchPaymentHistory(data) {
		searchPayment(data);
	}

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search VAT Payment</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					label="VAT Payment ID"
					placeholder="Enter VAT Payment ID"
					type="number"
					name="vat_payment_id"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="VAT Bill Number"
					placeholder="Enter VAT Bill Number"
					type="number"
					name="vat_bill_number"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Event ID"
					placeholder="Enter Event ID"
					type="number"
					name="event_id"
					ref={register}
				/>

				<SimpleTextInput
					type="date"
					variant="form-md edit"
					placeholder="Enter Date From"
					label="Date From"
					name="date_from"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					type="date"
					placeholder="Enter Date To"
					label="Date To"
					name="date_to"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Customer First Name"
					label="Customer First Name"
					name="customer_fname"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Customer Last Name"
					label="Customer Last Name"
					name="customer_lname"
					ref={register}
				/>
			</div>
			<div className="flex gap-x-15px justify-end">
				<button
					className="button-primary-icon"
					onClick={_clearPaymentHistorySearch}
				>
					<i className="fas fa-eraser"></i>
				</button>
				<button
					className="button-primary-icon"
					onClick={handleSubmit(_searchPaymentHistory)}
				>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	);
}

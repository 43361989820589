import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/loader.component";
import { useDispatch, useSelector } from "react-redux";
import { verifyUser } from "../../redux/actions/email-verification.action";
import strings from "../../global/strings";

export default function EmailVerification(props) {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState();
	const emailVerification = useSelector((state) => state.emailVerification);

	// useEffect(() => {
	// 	const { token, email } = props.match.params;
	// 	dispatch(verifyUser(token, email));
	// }, [props, dispatch]);

	useEffect(() => {
		const { token, email } = props.match.params;

		const verify = async () => {
			const verification = await dispatch(verifyUser(token, email));

			if (typeof verification !== "object") {
				setErrorMessage(verification);
			}
		};

		verify();
	}, []);

	if (emailVerification.isLoading) {
		return <Loader />;
	}

	function errorView() {
		return (
			<div className="bg-default form-main-content">
				<div className="form-notice-title">
					{strings.emailVerifiedError.title}
				</div>

				<div className="form-notice-message">{errorMessage}</div>
				<div className="form-notice-controller two">
					<Link to="/">
						<button className="button-primary">Sign In</button>
					</Link>
				</div>
			</div>
		);
	}

	return (
		<div className="signin-page-wrapper">
			<div className="signup-main-container">
				<div className="company-title">CATER ME</div>

				{emailVerification.success === false ? (
					errorView()
				) : (
					<div className="bg-default form-main-content">
						<div className="form-notice-title">
							{emailVerification.msg.title_message}
						</div>
						<div className="form-notice-sub-title">
							{emailVerification.msg.sub_title_message}
						</div>
						<div className="form-notice-message">
							{emailVerification.msg.description_message}
						</div>

						{emailVerification.error === "" && (
							<div className="form-notice-controller two">
								<Link to="/user/complete-registration">
									<button className="button-primary">
										Next
									</button>
								</Link>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

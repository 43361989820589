import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./profile-settings.style.scss";

import { isEmptyObject, sessionInfo } from "../../../global/function";

import Loader from "../../loader/loader.component";
import AddImageModal from "../../../components/modals/add-image.modal";
import InviteEmployeeModal from "../../modals/invite-employee-modal.component";
import AddEmployeeModal from "../../modals/add-employee.modal";
import BookingHistoryTable from "../../tables/booking-history.table";
import ConfirmationModal from "../../modals/confirmation-modal.component";
import ProfileComponent from "../../profile/profle.component";

import ErrorPage from "../../../pages/extra-page/extra.page";
import PaginationReact from "../../../components/paginationReact/paginationReact.component";

import {
	getMyDetails,
	deleteEmployee,
	transferOwnership,
} from "../../../redux/actions/employee-setting.action";
import { fetchVenue } from "../../../redux/actions/venue-action";
import { setErrorTrue } from "../../../redux/actions/support-action";
import { getBookingHistory } from "../../../redux/actions/booking-action";
import { uploadImage } from "../../../redux/actions/image-upload.action";

// new modal for updating password
import UpdatePasswordModal from "../../modals/update-password-modal/update-password.modal";

function ProfileSettings({ employeeInfoId, fromSrc }) {
	const dataPerPage = 10;
	const dispatch = useDispatch();
	const history = useHistory();

	const { isLoading, userInfo, deletedEmployee } = useSelector(
		(state) => state.employeeSetting
	);
	const { error } = useSelector((state) => state.support);
	const { bookingHistoryCount, bookingHistoryList } = useSelector(
		(state) => state.booking
	);

	const [showInviteModal, setShowInviteModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [session, setSession] = useState({});

	const [showAddImageModal, setShowAddImageModal] = useState(false);
	const [editable, setEditable] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [confirmationModalFlag, setConfirmationModalFlag] = useState(false);
	const [confirmationModalType, setConfirmationModalType] = useState("");

	// password change modal added by sanat
	const [passwordModalFlag, setPasswordModalFlag] = useState(false);

	useEffect(() => {
		dispatch(fetchVenue());
	}, [dispatch]);

	useEffect(() => {
		const getDetails = async () => {
			const sessionData = await sessionInfo();
			await dispatch(getMyDetails(employeeInfoId));
			setSession(sessionData);
			if (sessionData.company_role === "viewer") {
				setEditable(false);
			}
		};
		if (employeeInfoId) {
			getDetails();
		}
	}, [dispatch, employeeInfoId]);

	useEffect(() => {
		if (
			!isEmptyObject(userInfo) &&
			userInfo?.userData?.user_info_id === employeeInfoId
		) {
			dispatch(
				getBookingHistory(
					dataPerPage,
					currentPage,
					userInfo?.userData?.company_employees[0]
						?.company_employee_id
				)
			);
		}
	}, [dispatch, currentPage, userInfo, employeeInfoId]);

	useEffect(() => {
		if (deletedEmployee) {
			history.goBack();
		}
	}, [deletedEmployee, history]);

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentPage(currentPage);
	};

	const _handleInviteModalClose = () => {
		setShowInviteModal(false);
	};

	const _handleInviteModalShow = () => setShowInviteModal(true);

	const _handleAddModalClose = () => {
		setShowAddModal(false);
	};
	const onSaveImage = (image) => {
		let data = { image, id: employeeInfoId, type: "employee" };
		dispatch(uploadImage(data));
	};

	const _handleAddModalShow = () => setShowAddModal(true);

	function showModal() {
		if (fromSrc === "settings") {
			_handleAddModalShow();
		} else {
			session.company_role &&
				session.company_role !== "viewer" &&
				_handleInviteModalShow();
		}
	}
	const handleDeleteEmployeeModalShow = () => {
		setConfirmationModalType("deleteEmployee");
		setConfirmationModalFlag(true);
	};
	const handleTransferOwnershipModalShow = () => {
		setConfirmationModalType("transferOwnership");
		setConfirmationModalFlag(true);
	};

	const handleDeleteEmployeeModalClose = () => {
		setConfirmationModalFlag(false);
	};

	const handleImageModalClose = () => {
		setShowAddImageModal(false);
	};
	//Display Image Upload Model
	const handleImageModalShow = () => editable && setShowAddImageModal(true);

	const _deleteEmployee = () => {
		let id = userInfo?.userData?.company_employees[0]?.company_employee_id;

		let company_id = session.company_info_id;
		dispatch(deleteEmployee(id, company_id));

		history.push({
			pathname: "/settings",
			state: { page_id: 5 },
		});
	};

	const _transferOwnership = () => {
		let details = {};
		details["old_admin_id"] = session.company_employee_id;
		details["new_admin_id"] =
			userInfo?.userData?.company_employees[0]?.company_employee_id;
		details["old_admin_user_info_id"] = session.user_info_id;
		details["new_admin_user_info_id"] = userInfo?.userData?.user_info_id;
		dispatch(transferOwnership(details));
	};

	// password change modal function listed below
	const _openPasswordModal = () => {
		setPasswordModalFlag(true);
	};

	const _closePasswordModal = () => {
		setPasswordModalFlag(false);
	};

	return (
		<div>
			{isLoading && <Loader />}
			{error && <ErrorPage type="error" />}

			{!isEmptyObject(userInfo) && (
				<ProfileComponent
					type="user"
					details={userInfo}
					editFlag={showModal}
					showImageModal={handleImageModalShow}
					showDeleteModal={handleDeleteEmployeeModalShow}
					transferOwnershipModal={handleTransferOwnershipModalShow}
					editable={editable}
					employmentChange={fromSrc === "settings" ? false : true}
					// change password added by sanat
					showChangePasswordModal={_openPasswordModal}
				/>
			)}

			<AddEmployeeModal
				isVisible={showAddModal}
				handleClose={_handleAddModalClose}
				handleShow={_handleAddModalShow}
			/>
			<hr className="default-hr" />
			{!isEmptyObject(bookingHistoryList) && (
				<div className="inner-container full mt-24px">
					<div className="w-full flex flex-row justify-between px-15px text-fontSemiDark">
						<label htmlFor="">Bookings</label>
						<label
							htmlFor=""
							className="cursor-pointer"
							onClick={() =>
								history.push({ pathname: "/booking-history" })
							}
						>
							View All
						</label>
					</div>
					<BookingHistoryTable
						bookingList={bookingHistoryList}
						filterObject={{
							dataPerPage,
							currentPage,
							companyEmployeeId:
								userInfo?.userData?.company_employees[0]
									?.company_employee_id,
						}}
						from="userProfile"
					/>
					<div className="mt-5 w-full flex justify-center">
						<PaginationReact
							totalCount={bookingHistoryCount}
							itemsPerPage={dataPerPage}
							onPageClick={onPageChange}
							forcePage={currentPage - 1}
						/>
					</div>
				</div>
			)}

			<InviteEmployeeModal
				isVisible={showInviteModal}
				handleClose={_handleInviteModalClose}
				handleShow={_handleInviteModalShow}
				actionType="Update"
			/>
			<AddImageModal
				isVisible={showAddImageModal}
				type="User"
				handleClose={handleImageModalClose}
				handleShow={handleImageModalShow}
				onSaveClick={onSaveImage}
			/>
			<ConfirmationModal
				type={confirmationModalType}
				isVisible={confirmationModalFlag}
				handleClose={handleDeleteEmployeeModalClose}
				handleShow={
					confirmationModalType === "deleteEmployee"
						? handleDeleteEmployeeModalShow
						: handleTransferOwnershipModalShow
				}
				onClickConfirmation={
					confirmationModalType === "deleteEmployee"
						? _deleteEmployee
						: _transferOwnership
				}
			/>

			{/* update password added by sanat */}
			<UpdatePasswordModal
				isVisible={passwordModalFlag}
				handleShow={_openPasswordModal}
				handleClose={_closePasswordModal}
			/>
		</div>
	);
}

export default ProfileSettings;

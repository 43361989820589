import {
	FETCH_EMAIL_VERIFICATION_REQUEST,
	FETCH_EMAIL_VERIFICATION_SUCCESS,
	FETCH_EMAIL_VERIFICATION_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
	msg: {},
	error: "",
	success: true,
};
export default function emailVerificationReducer(
	state = initial_state,
	action
) {
	switch (action.type) {
		case FETCH_EMAIL_VERIFICATION_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case FETCH_EMAIL_VERIFICATION_SUCCESS:
			return {
				isLoading: false,
				msg: action.payload,
				error: "",
			};
		case FETCH_EMAIL_VERIFICATION_ERROR:
			return {
				isLoading: false,
				msg: action.payload,
				error: action.payload.sub_title_message,
				success: false,
			};
		default:
			return state;
	}
}

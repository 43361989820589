import React, { Fragment } from "react";
import { rupeeConverter } from "../../../global/function";
import PrintFooter from "./printFooter.component";
import PrintHeader from "./printHeader.component";

class PrintEvent extends React.Component {
	render() {
		const infos = this.props;

		return (
			<div className="print-wrapper">
				<PrintHeader
					type="Event"
					category={infos.printInfo}
					showCompany={infos.companyName}
				/>
				<hr />
				<div className="print-body-wrapper sign">
					<div className="oneline-customer">
						{infos.companyRole !== "viewer" ? (
							<div className="customer-oneline-data">
								Event ID
								<p>{infos.event.event_id}</p>
							</div>
						) : (
							<div className="customer-oneline-data">
								Event Bookings
								<p>{infos.bookingData?.length}</p>
							</div>
						)}
						<div className="customer-oneline-data">
							Customer
							<p>
								{infos.client.customer_fname}{" "}
								{infos.client.customer_lname}
							</p>
						</div>
						<div className="customer-oneline-data">
							Number
							<p>{infos.client.customer_mobile}</p>
						</div>
						<div className="customer-oneline-data">
							Email
							<p>{infos.client.customer_email}</p>
						</div>
					</div>
					{infos.printInfo === "invoice" ? (
						<div className="print-table-grid">
							<div className="print-table-title">S.N.</div>
							<div className="print-table-title">
								Booking Information
							</div>
							<div className="print-table-title text-right">
								Total Booking Cost
							</div>
							<div className="print-table-title text-right">
								Total Bill Amount
							</div>
							<div className="print-table-title text-right">
								Total Balance
							</div>
							{infos?.bookingData?.map((val, index) => (
								<Fragment key={index}>
									<div className="amountBlock">
										<p>{val.booking_id}</p>
									</div>
									<div className="print-table-booking">
										<div className="main-text">
											{/* <p className="capitalize">
												{val.venue_name}
											</p> */}
											<p>{val.booked_date_nepali}</p>
											<p>{val.booked_date_english}</p>
											<p className="capitalize">
												{val.booking_status}
											</p>
										</div>
										<div className="main-text details">
											<p className="capitalize">
												{val.booked_time}
											</p>
											<p>Pax: {val.e_pax}</p>
											<p>Rate: Rs. {val.e_rate}</p>
										</div>
									</div>
									<div className="amountBlock">
										{rupeeConverter(
											val.totalBookingCost.toFixed(2)
										)}
									</div>
									<div className="amountBlock">
										{rupeeConverter(
											val.totalBillAmount.toFixed(2)
										)}
									</div>
									<div className="amountBlock">
										<p>
											{rupeeConverter(
												val.totalBalance.toFixed(2)
											)}
										</p>
										<p className="sub-details">
											Adv:{" "}
											{rupeeConverter(
												(
													val.totalBookingCost -
													val.totalBookingPayment
												).toFixed(2)
											)}
										</p>
									</div>
								</Fragment>
							))}
						</div>
					) : (
						<div className="print-table-grid three-grid">
							<div className="print-table-title">S.N.</div>
							<div className="print-table-title text-right">
								Booking Information
							</div>
							{infos?.bookingData?.map((val) => (
								<>
									<div className="amountBlock">
										<p>{val.booking_id}</p>
									</div>
									<div className="event-summary-grid">
										<p>{val.booked_date_nepali}</p>
										<p>{val.booked_date_english}</p>
										<p className="capitalize">
											{val.booking_type}
										</p>
										<p className="capitalize">
											{val.booking_status}
										</p>
										<p className="capitalize">
											{val.booked_time}
										</p>
										<p>Pax: {val.e_pax}</p>
									</div>
								</>
							))}
						</div>
					)}
				</div>
				<hr />
				<PrintFooter
					printInfo={infos.printInfo}
					customerName={
						infos.client.customer_fname +
						" " +
						infos.client.customer_lname
					}
					payment={infos.eventPayments}
					companyRole={infos.companyRole}
					vatDisplay={infos.vatDisplay}
				/>
			</div>
		);
	}
}

export default PrintEvent;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import strings from "../../global/strings";

import Modal from "react-modal";
import { forgotPassword } from "../../redux/actions/login-action";
import { useDispatch } from "react-redux";
// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../common/text-input/form-text-input.component";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

const userSchema = yup.object().shape({
	user_mobile: yup
		.number()
		.typeError("must be number")
		.required("mobile number is required")
		.positive("Must be greater than zero"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
});

const ForgotPasswordModal = (props) => {
	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(userSchema),
	});
	const dispatch = useDispatch();

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			overflow: "hidden",
		},
	};

	async function _returnUserInfo(data) {
		let status = await dispatch(forgotPassword(data));
		if (status) {
			props.handleClose();
		}
	}

	return (
		<Modal
		ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<form onSubmit={handleSubmit(_returnUserInfo)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">
							{strings.forgotPasswordModal.title}
						</div>
					</div>
					<div className="form-body">
						<div className="form-description-title">
							{strings.forgotPasswordModal.message}
						</div>
						<div className="form-input-container">
							<FormInput
								type="email"
								label="Email Address"
								placeholder="Enter Your Email Address"
								variant="form-lg"
								ref={register}
								name="user_email"
								error={errors?.user_email?.message}
							/>
						</div>
						<div className="form-input-container">
							<FormInput
								type="number"
								label="Mobile Number"
								placeholder="Enter Valid Mobile Number"
								variant="form-lg"
								ref={register}
								name="user_mobile"
								error={errors?.user_mobile?.message}
							/>
						</div>
					</div>

					<div className="form-footer two">
						<button
							className="button-primary"
							onClick={() => {
								reset({});
								props.handleClose();
							}}
						>
							Close
						</button>
						<button type="submit" className="button-primary">
							Reset
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ForgotPasswordModal;

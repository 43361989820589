import React from "react";
import { Bar } from "react-chartjs-2";

const StackedBarChartWithGroups = ({ data, barStyle }) => {
	const stackedGroupOptions = {
		// indexAxis: "y",
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				grid: {
					color: "#e7e7e744",
				},
			},
			y: {
				stacked: true,
				display: false,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	return <Bar data={data} options={stackedGroupOptions} style={barStyle} />;
};

export default StackedBarChartWithGroups;

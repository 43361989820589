import React, { useState } from "react";
import {
	fetchMenuItem,
	// searchMenuItemForBooking,
	searchMenuItem,
	// fetchSegregatedMenuItem,
} from "../../redux/actions/menu-service-settings.action";
import { useDispatch } from "react-redux";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import SelectBox from "../common/select-box/form-select-input.component";
import strings from "../../global/strings";
// import FileUpload from "../settings/file-upload/file-upload.component";
import { exportHistory } from "../../redux/actions/booking-action";

function SearchMenuComponent({
	handleAdd,
	uploadClick,
	controlsEnabled = true,
}) {
	const dispatch = useDispatch();

	const [searchMenuName, setSearchMenuName] = useState({
		menu_item: "",
		menu_category: "",
		item_type: "",
		menu_group: "",
		menu_cartilage: "",
	});

	const setSearchMenu = (e) => {
		setSearchMenuName({
			...searchMenuName,
			[e.target.name]: e.target.value,
		});
	};
	const _clearMenuSearch = () => {
		setSearchMenuName({
			menu_item: "",
			menu_category: "",
			item_type: "",
			menu_group: "",
			menu_cartilage: "",
		});
		// if (route === "settings") {
		// } else {
		// 	dispatch(fetchSegregatedMenuItem());
		// }
		dispatch(fetchMenuItem("", ""));
	};
	const _searchMenu = () => {
		// if (route === "settings") {
		// }
		// else {
		// 	dispatch(searchMenuItemForBooking(searchMenuName));
		// }
		dispatch(searchMenuItem(searchMenuName));
	};

	const uploadMenu = () => {
		uploadClick("menu");
	};

	const exportCSV = async () => {
		await dispatch(exportHistory("Menu", "", ""));
	};

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Menu</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					label="Menu Name"
					placeholder="Enter Menu Name"
					type="text"
					name="menu_item"
					value={searchMenuName.menu_item}
					onChange={(e) => setSearchMenu(e)}
				/>

				<SelectBox
					value={searchMenuName.menu_group}
					options={strings.menuGroup}
					variant="search"
					label="Menu Group"
					name="menu_group"
					onChange={(e) => setSearchMenu(e)}
					type="Menu Group"
				/>
				<SelectBox
					value={searchMenuName.menu_category}
					options={strings.categoryType}
					variant="search"
					label="Menu Category"
					name="menu_category"
					onChange={(e) => setSearchMenu(e)}
					type="Menu Category"
				/>

				<SelectBox
					value={searchMenuName.item_type}
					options={strings.itemTypes}
					variant="search"
					label="Item Type"
					name="item_type"
					onChange={(e) => setSearchMenu(e)}
					type="Item Type"
				/>

				<SelectBox
					value={searchMenuName.menu_cartilage}
					options={strings.menuCartilage}
					variant="search"
					label="Menu Cartilage"
					name="menu_cartilage"
					onChange={(e) => setSearchMenu(e)}
					type="Menu Cartilage"
				/>
			</div>
			<div
				className={`flex gap-x-15px ${
					controlsEnabled ? "justify-between" : "justify-end"
				}`}
			>
				{controlsEnabled && (
					<div className="flex gap-10px">
						<button className="button-primary" onClick={handleAdd}>
							Add Menu
						</button>
						<button
							className="button-primary sm:hidden"
							onClick={uploadMenu}
						>
							Upload Menu
						</button>

						<button
							className="button-primary sm:hidden"
							onClick={exportCSV}
						>
							Export CSV
						</button>
					</div>
				)}

				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearMenuSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={_searchMenu}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}
export default SearchMenuComponent;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./booking-side-modal-view.style.scss";

// for date purpose
import moment from "moment";

// global strings and functions
import { sessionInfo } from "../../global/function";

// components
import BookingDetailsForm from "./booking-smv-form/booking-details-form.component";
import BookingPaymentForm from "./booking-smv-form/booking-payments-form.component";
import BookingVATForm from "./booking-smv-form/booking-vat-form.component";

export default function BookingSideModalView({
	booking,
	modalFrom,
	onAfterSuccessCall,
}) {
	const [companyRole, setCompanyRole] = useState("");

	const [accordionType, setAccordionType] = useState(null); // <"details" | "payment" | "vat">

	const history = useHistory();

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, []);

	const handleToggleAccordion = (acType) => {
		if (accordionType === acType) {
			setAccordionType(null);
		} else {
			setAccordionType(acType);
		}
	};

	const closeAccordion = () => {
		setAccordionType(null);
	};

	const handleNavigateToBooking = (bookingDetails) => {
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${bookingDetails.event_id}`,
			state: {
				event_info_id: bookingDetails.event_info_id,
				booking_info_id: bookingDetails.booking_info_id,
			},
		});
	};

	function _renderDetailsDisplay() {
		let viewer_rate = booking.e_rate;
		let viewer_pax = booking?.e_pax;
		if (companyRole === "viewer") {
			if (booking?.e_rate > 600) {
				viewer_rate = `${
					booking.e_rate - Math.round(booking.e_rate * 0.5)
				} - ${booking.e_rate}`;
			}
			if (booking.e_pax > 200) {
				viewer_pax = `${
					booking.e_pax - Math.round(booking.e_pax * 0.5)
				} - ${booking.e_pax}`;
			}
		}
		/*Render Conditions:
		All users will see Estimates when booking is open
		Viewers will not see Estimates if booking is open and vat is recorded and payment is complete
		All users will see Actuals only when vat is recorded */
		return (
			<div className="smv-booking-details-container">
				<div className="smv-details">
					<div className="details-label">Estd. Pax</div>
					<div className="details-value">
						<i className="fas fa-user-friends"></i>
						<b>{viewer_pax}</b>
					</div>
				</div>
				<div className="smv-details">
					<div className="details-label">Estd. Rate</div>
					<div className="details-value">
						Rs. <b>{viewer_rate}</b>
					</div>
				</div>
				<div className="smv-details">
					<div className="details-label">Booking Type</div>
					<div className="details-value">
						<b>{booking?.booking_type}</b>
					</div>
				</div>
				<div className="smv-details">
					<div className="details-label">Catering Type</div>
					<div className="details-value">
						<b>{booking?.booking_catering || "Full Catering"}</b>
					</div>
				</div>
			</div>
		);
	}

	function _renderBookingTimings() {
		return (
			<div className="booking-smv-timing-container">
				<div className="smv-booking-container">
					<div
						className="smv-time"
						style={{
							backgroundColor: `var(--${booking.booked_time})`,
						}}
					>
						<div className="time-icon">
							{booking.booked_time === "morning" && (
								<i className="fa fa-sun"></i>
							)}
							{booking.booked_time === "afternoon" && (
								<i className="fa fa-cloud-sun"></i>
							)}
							{booking.booked_time === "evening" && (
								<i className="fa fa-moon"></i>
							)}
						</div>
						<div className="time-text-container">
							<p className="time-day">
								{moment(booking.booked_date_english).format(
									"dddd"
								)}
							</p>
							<h4 className="time-text">{booking.booked_time}</h4>
						</div>
					</div>
					<div
						className="smv-status"
						style={{
							backgroundColor: `var(--${booking.booking_status})`,
						}}
					>
						{booking.booking_status}
					</div>
				</div>
				<div className="smv-booking-container flex">
					<div>
						<div className="smv-customer">
							{booking?.customer_fname} {booking?.customer_lname}
						</div>
						<div className="smv-bookingId">
							Booking ID: # {booking.booking_id}
						</div>
					</div>
					<div>{_renderDetailsDisplay()}</div>
					<div
						className="smv-button"
						onClick={() => handleNavigateToBooking(booking)}
					>
						<span>View Booking</span>
						<i className="fas fa-arrow-right"></i>
					</div>
				</div>
			</div>
		);
	}

	function _renderAccordions(acType, header) {
		const active = accordionType === acType;
		return (
			<div className="rc-accordion-card">
				<div
					className={`rc-accordion-toggle ${active ? "active" : ""}`}
					onClick={() => handleToggleAccordion(acType)}
				>
					<h5 className="rc-accordion-title">{header}</h5>
					<i className="fa fa-chevron-down rc-accordion-icon"></i>
				</div>
				<div className={`rc-collapse ${active ? "show" : ""}`}>
					<div className="rc-accordion-body">
						{active && accordionType === "details" && (
							<BookingDetailsForm
								booking={booking}
								closeAccordion={closeAccordion}
								onAfterSuccessCall={onAfterSuccessCall}
							/>
						)}
						{active && accordionType === "payment" && (
							<BookingPaymentForm
								booking={booking}
								companyRole={companyRole}
								closeAccordion={closeAccordion}
								modalFrom={modalFrom}
								onAfterSuccessCall={onAfterSuccessCall}
							/>
						)}
						{active && accordionType === "vat" && (
							<BookingVATForm
								booking={booking}
								closeAccordion={closeAccordion}
								onAfterSuccessCall={onAfterSuccessCall}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className="booking-side-modal-view-container">
			{_renderBookingTimings()}
			{booking?.payment_status !== "complete" &&
				booking?.booking_status !== "complete" &&
				booking?.booking_status !== "canceled" &&
				_renderAccordions("details", "Edit Booking Details")}
			{booking?.payment_status !== "complete" &&
				booking?.booking_status !== "complete" &&
				booking?.booking_status !== "canceled" &&
				_renderAccordions("payment", "Booking Payments")}
			{booking?.booking_status !== "canceled" &&
				booking?.booking_status !== "complete" &&
				_renderAccordions("vat", "Add VAT Record")}
		</div>
	);
}

export const vatHistoryHeader = [
	{
		name: "VAT Payment ID",
		field: "vat_payment_id",
		sortable: true,
		searchable: false,
	},
	{
		name: "VAT Bill Number",
		field: "vat_bill_number",
		sortable: false,
		searchable: true,
	},
	{
		name: "VAT Date",
		field: "vat_date",
		sortable: true,
		searchable: false,
	},
	{
		name: "Nep Date",
		field: "nep_date",
		sortable: true,
		searchable: false,
	},
	{
		name: "Customer Name",
		field: "customer_name",
		sortable: true,
		searchable: true,
	},

	{
		name: "Employee Name",
		field: "employee_name",
		sortable: true,
		searchable: true,
	},
	{
		name: "VAT Amount",
		field: "vat_amount",
		sortable: false,
		searchable: false,
	},
];

import React, { forwardRef } from "react";
import "./select-box.style.scss";

function SelectBox(props, ref) {
	const returnOptions = () => {
		return props.options.map((row) => (
			<option
				value={row.id}
				key={row.id}
				style={{
					display: `${row.value === "None" ? "none" : "block"}`,
				}}
			>
				{row.value}
			</option>
		));
	};

	const style = {
		display: "hidden",
	};

	return (
		<div
			className={`select-wrapper ${
				props.variant === "form-sm" && "mb-2"
			}`}
		>
			<select
				key={props.id}
				className={`bg-default select-box-wrapper ${props.variant}`}
				{...props}
				ref={ref}
			>
				<option value="">- Select {props.type} -</option>
				{returnOptions()}
			</select>
			<span className={`validation-error ${props.variant}`} style={style}>
				{props.error}
			</span>
		</div>
	);
}
export default forwardRef(SelectBox);

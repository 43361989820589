import React, { useEffect, useState } from "react";
import "./company-settings.style.scss";
import Loader from "../../loader/loader.component";

import {
	fetchCompanyDetails,
	addHalls,
	updateHallDetails,
	updateVenueDetails,
	deleteHall,
	deleteVenue,
} from "../../../redux/actions/company-setting.action";
import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject, sessionInfo } from "../../../global/function";
import ConfirmationModal from "../../modals/confirmation-modal.component";

import CompanyDetails from "./company-details.component";
import ChangeVenueStatusModal from "../../modals/change-venue-status.modal";
import AddVenueModal from "../../modals/add-venue-modal";

import VenueDetails from "./venue-details.component";
import ConfirmationModalOnInput from "../../modals/confirmation-modalOnInput.modal";
import ChangePinModal from "../../modals/change-pin.modal";

export default function CompanySettings(props) {
	const dispatch = useDispatch();
	const companySetting = useSelector((state) => state.companySetting);
	const imageUpload = useSelector((state) => state.image);

	const [addHallModalStatus, setAddHallModalStatus] = useState(false);
	const [addVenueModalStatus, setAddVenueModalStatus] = useState(false);
	const [changeStatusModal, setChangeStatusModal] = useState(false);
	const [modalInfo, setModalInfo] = useState({});
	const [company_info_id, setCompId] = useState();
	const [showSecConfirmModal, setShowSecConfirmModal] = useState(false);
	const [showSecPinModal, setShowSecPinModal] = useState(false);
	const [role, setCompanyRole] = useState("");
	const [secModalData, setSecModalData] = useState({
		title: "",
		message: "",
		confirmFor: "",
		pinRequired: false,
	});

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
				setCompId(data.company_info_id);
			});
		}

		fetchSessionData();
		dispatch(fetchCompanyDetails());
	}, [dispatch]);

	const _handleAddVenueModalClose = () => {
		setAddVenueModalStatus(false);
	};
	const _handleAddVenueModalShow = () => setAddVenueModalStatus(true);

	function handleHideAddHallModalVisibility() {
		setAddHallModalStatus(false);
	}

	const handleShowAddHallModalVisibility = () => {
		setAddHallModalStatus(true);
	};

	const handleDeactivationModalShow = () => {
		setChangeStatusModal(true);
	};
	const handleDeactivationModalHide = () => {
		setChangeStatusModal(false);
	};

	const changeHallStatus = (
		venueName,
		venueInfoId,
		hallName,
		hallInfoId,
		event,
		hallCount
	) => {
		setModalInfo({
			status: event.target.value,
			venueInfoId: venueInfoId,
			hallInfoId: hallInfoId,
			hallName: hallName,
			venueName: venueName,
			type: "hall",
			count: hallCount,
		});

		handleDeactivationModalShow();
	};

	const onChangeHallStatus = (status, hallId, venueId) => {
		let hallDetails = {
			hall_info_id: hallId,
			venue_info_id: venueId,
			hall_status: status,
		};
		dispatch(updateHallDetails(hallDetails));
	};

	const showAddHallConfirmation = (venueInfo, hallName) => {
		let mm = { venueInfoId: venueInfo, hallName: hallName };
		setModalInfo(mm);
		handleShowAddHallModalVisibility();
	};

	const addHallOnClick = (hallDetails) => {
		dispatch(addHalls(hallDetails));
		handleHideAddHallModalVisibility();
	};

	const renderVenueDetails = () =>
		companySetting.companyDetails.venue_infos.map((venue, i) => {
			return (
				<div key={i}>
					<VenueDetails
						venue={venue}
						changeHallStatus={changeHallStatus}
						showAddHallConfirmation={showAddHallConfirmation}
						key={venue.venue_info_id}
					/>
					<hr className="default-hr" />
				</div>
			);
		});

	function _handleSecurityModalDetails(type) {
		let temp = {
			title: "",
			message: "",
			confirmFor: "",
			pinRequired: false,
		};
		switch (type) {
			case "close":
				temp.confirmFor = "Close Shop";
				temp.title = "Close Shop";
				temp.message = "Are you sure you want to log all users off?";
				setShowSecConfirmModal(true);
				break;
			case "switch":
				temp.confirmFor = "Switch Role";
				temp.title = "Switch Employee Roles";
				temp.message =
					"All the employee roles will be switched to viewer";
				setShowSecConfirmModal(true);
				break;
			case "pin":
				temp.confirmFor = "Change Pin";
				temp.title = "Change Pin";
				temp.message =
					"Changing Pin will Log all Users off. To login you need the new Pin.";
				setShowSecPinModal(true);
				break;
			case "deactivate":
				temp.confirmFor = "Deactivate";
				temp.title = "Deactivate Company";
				temp.message =
					"Once deactivated you must contact us to reactivate account";
				setShowSecConfirmModal(true);
				break;
			default:
				break;
		}
		setSecModalData(temp);
	}

	function _renderSecurityFeature() {
		return (
			<div className="sec-fe">
				<p>Security Control</p>
				<div className="sec-fe-wrapper">
					<div className="bg-default sec-fe-container close-shop">
						<div className="sec-fe-title close-shop">
							<i className="fas fa-store-slash"></i>
							<p>Close Shop</p>
							<i className="fas fa-smile"></i>
						</div>
						<p className="sec-fe-sub">
							Close access and sign out all logged in users. Users
							must re-enter pin to login in.
						</p>
						<button
							className="bg-default"
							onClick={() => _handleSecurityModalDetails("close")}
						>
							Lock up
						</button>
					</div>
					<div className="bg-default sec-fe-container switch">
						<div className="sec-fe-title switch">
							<i className="fas fa-exchange-alt"></i>
							<p>Switch Role</p>
							<i className="fas fa-smile"></i>
						</div>
						<p className="sec-fe-sub">
							Switch all employee roles to Viewer.
						</p>
						{role === "admin" && (
							<button
								className="bg-default"
								onClick={() =>
									_handleSecurityModalDetails("switch")
								}
							>
								Switch
							</button>
						)}
					</div>
					<div className="bg-default sec-fe-container change-pin">
						<div className="sec-fe-title change-pin">
							<i className="fas fa-key"></i>
							<p>Change Pin</p>
							<i className="fas fa-smile"></i>
						</div>
						<p className="sec-fe-sub">
							Changing Security Pin will re-encrypt your data.
						</p>
						{role === "admin" && (
							<button
								className="bg-default"
								onClick={() =>
									_handleSecurityModalDetails("pin")
								}
							>
								Change Pin
							</button>
						)}
					</div>
					<div className="bg-default sec-fe-container deactivate">
						<div className="sec-fe-title deactivate">
							<i className="fas fa-fire"></i>
							<p>Deactivate</p>
							<i className="fas fa-skull-crossbones"></i>
						</div>
						<p className="sec-fe-sub">
							Deactivating your account will remove all access to
							the software. Recovery pin is required to Activate
							service again.
						</p>
						{role === "admin" && (
							<button
								className="bg-default"
								onClick={() =>
									_handleSecurityModalDetails("deactivate")
								}
							>
								Deactivate
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}

	function changeVenueStatus(status, venueInfoId, venueName) {
		setModalInfo({
			status: status,
			id: venueInfoId,
			name: venueName,
			type: "venue",
			count: companySetting.companyDetails.venue_infos.length,
		});
		handleDeactivationModalShow();
	}

	const onChangeVenueStatus = async (status, venue_info_id) => {
		let venueInfo = {
			venue_info_id: venue_info_id,
			company_info_id: company_info_id,
			venue_status: status,
		};
		await dispatch(updateVenueDetails(venueInfo));
	};

	const onClickDeleteHall = (hallInfoId) => {
		dispatch(deleteHall(hallInfoId));
	};
	function onClickDeleteVenue(venueInfoId) {
		dispatch(deleteVenue(venueInfoId));
	}

	return (
		<div className="company-setting-wrapper">
			{companySetting.isLoading && <Loader />}
			{imageUpload.isLoading && <Loader />}
			{/* renderCompanyDetails() */}
			<CompanyDetails
				data={companySetting.companyDetails}
				handleAddVenueModalShow={_handleAddVenueModalShow}
				changeVenueStatus={changeVenueStatus}
			/>
			{/* role === "admin" && <hr className="default-hr"/> */}
			{/* role === "admin" && (
				<span className="venue-section-title">
					Payment And Subscription
				</span>
			) */}
			{/* role === "admin" && <PaymentDetailsComponent /> */}
			<hr className="default-hr" />

			{role !== "viewer" && _renderSecurityFeature()}
			<hr className="default-hr" />
			{!isEmptyObject(companySetting.companyDetails) &&
				renderVenueDetails()}

			<ConfirmationModal
				type="addHall"
				onClickConfirmation={addHallOnClick}
				isVisible={addHallModalStatus}
				handleClose={handleHideAddHallModalVisibility}
				handleShow={handleShowAddHallModalVisibility}
				modalInfo={modalInfo}
			/>

			<AddVenueModal
				isVisible={addVenueModalStatus}
				handleClose={_handleAddVenueModalClose}
				handleShow={_handleAddVenueModalShow}
				venueInfo={companySetting.companyDetails.venue_infos}
			/>
			<ChangeVenueStatusModal
				isVisible={changeStatusModal}
				handleClose={handleDeactivationModalHide}
				handleShow={handleDeactivationModalShow}
				modalInfo={modalInfo}
				onClickYes={
					modalInfo.type === "venue"
						? onChangeVenueStatus
						: onChangeHallStatus
				}
				onClickDelete={
					modalInfo.type === "venue"
						? onClickDeleteVenue
						: onClickDeleteHall
				}
			/>
			<ConfirmationModalOnInput
				isVisible={showSecConfirmModal}
				handleClose={() => setShowSecConfirmModal(false)}
				modalFor={secModalData.confirmFor}
				modalMessage={secModalData.message}
				modalTitle={secModalData.title}
			/>
			<ChangePinModal
				isVisible={showSecPinModal}
				handleClose={() => setShowSecPinModal(false)}
				modalFor={secModalData.confirmFor}
				modalMessage={secModalData.message}
				modalTitle={secModalData.title}
			/>
		</div>
	);
}

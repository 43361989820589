import React, { useEffect, useState } from "react";

import moment from "moment";
import "./side-navigation.style.scss";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import packageJson from "../../../package.json";

import { sessionInfo } from "../../global/function";
import QuickAddPaymentModal from "../modals/quick-add-payment.modal";
import { switchToViewer } from "../../redux/actions/login-action";
import SideModal from "../modals/side-modal/side-modal.modal";
import { setBookingSearchParameters } from "../../redux/actions/booking-action";
import {
	setPaymentSearchParameters,
	setVatSearchParameters,
} from "../../redux/actions/payment-action";

function SideNavigationComponent(props) {
	const history = useHistory();
	const APP = process.env.REACT_APP_ENV;
	const dispatch = useDispatch();

	const [venues, setVenues] = useState([]);
	const [company_role, setCompanyRole] = useState();
	const [quickAddState, setQuickAddState] = useState(false);
	const [refesher, setRefresher] = useState(false);

	const [showSideModal, setSideModal] = useState(false);
	const [sideModalData, setSideModalData] = useState({});

	const adminAccess = venues.some(
		(venue) =>
			venue.venue_role === "a_partner" || venue.venue_role === "p_partner"
	);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setVenues(data.venues);
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, [refesher]);

	const _handleModelOpen = () => {
		setQuickAddState(true);
	};
	const _handleModelClose = () => {
		setQuickAddState(false);
	};
	//switch current user to viewer
	const _handleSwitchToViewer = () => {
		let response = Promise.resolve(dispatch(switchToViewer()));

		response.then((userData) => {
			localStorage.setItem("token", userData.token);
			setRefresher(!refesher);

			history.push({
				pathname: "/dashboard",
			});
		});
	};

	return (
		<div
			className="side-navigation-container bg-default"
			id="side-navigation-container"
		>
			<div className="side-nav-container">
				{/* booking */}
				<div
					className="btn-menu-sidenav"
					onClick={() =>
						history.push({
							pathname: "/dashboard",
						})
					}
				>
					<i
						className="button-icon-container far fa-calendar-check"
						style={{ color: "var(--afternoon)" }}
					></i>
					<span className="btn-text" id="btn-text">
						Upcoming Events
					</span>
				</div>
				<div className="brk"></div>
				<div
					className="btn-menu-sidenav"
					onClick={async () => {
						await dispatch(
							setBookingSearchParameters({
								booking_id: "",
								date_from: "",
								date_to: "",
								customer_fname: "",
								customer_lname: "",
								venue_info_id: "",
								booking_type: "",
								booking_catering: "",
								booking_status: "",
								payment_status: "",
								vat_status: "",
							})
						);
						history.push({
							pathname: "/booking-history",
						});
					}}
				>
					<i className="button-icon-container fas fa-history"></i>
					<span className="btn-text" id="btn-text">
						Event history
					</span>
				</div>
				<div
					className="btn-menu-sidenav"
					onClick={async () => {
						await dispatch(
							setVatSearchParameters({
								vat_payment_id: "",
								vat_bill_number: "",
								event_id: "",
								date_from: "",
								date_to: "",
								customer_fname: "",
								customer_lname: "",
							})
						);
						history.push({
							pathname: "/vat-history",
						});
					}}
				>
					<i
						className="button-icon-container fas fa-file-invoice-dollar"
						style={{ color: "var(--veg)" }}
					></i>
					<span className="btn-text" id="btn-text">
						VAT History
					</span>
				</div>
				{company_role !== "viewer" && (
					<div
						className="btn-menu-sidenav"
						onClick={async () => {
							await dispatch(
								setPaymentSearchParameters({
									payment_id: "",
									event_id: "",
									date_from: "",
									date_to: "",
									customer_fname: "",
									customer_lname: "",
									payment_type: "",
									transaction_type: "",
									venue_info_id: "",
									booking_id: "",
								})
							);
							history.push({
								pathname: "/payment-history",
							});
						}}
					>
						<i
							className="button-icon-container fas fa-money-bill"
							style={{ color: "var(--highlight-green)" }}
						></i>
						<span className="btn-text" id="btn-text">
							Payment history
						</span>
					</div>
				)}
				<div className="brk"></div>
				<div
					className="btn-menu-sidenav"
					onClick={() => _handleModelOpen()}
				>
					<i
						className="button-icon-container fa fa-usd"
						style={{ color: "var(--highlight-green)" }}
					></i>
					<span className="btn-text" id="btn-text">
						Quick payments
					</span>
				</div>

				{APP !== "production" && adminAccess && (
					<>
						<div
							className="btn-menu-sidenav"
							onClick={() =>
								history.push({
									pathname: "/inventory",
								})
							}
						>
							<i className="button-icon-container fas fa-boxes"></i>
							<span className="btn-text" id="btn-text">
								Inventory
							</span>
						</div>
						<div
							className="btn-menu-sidenav"
							onClick={() =>
								history.push({
									pathname: "/vendor",
								})
							}
						>
							<i className="button-icon-container fas fa-truck"></i>
							<span className="btn-text" id="btn-text">
								Vendor
							</span>
						</div>
					</>
				)}
				<div className="brk"></div>
				{adminAccess && (
					<>
						<div
							className="btn-menu-sidenav"
							onClick={() =>
								history.push({
									pathname: "/analytics",
								})
							}
						>
							<i
								className="button-icon-container far fas fa-chart-line"
								style={{ color: "var(--afternoon)" }}
							></i>
							<span className="btn-text" id="btn-text">
								Dashboard
							</span>
						</div>
						<div
							className="btn-menu-sidenav"
							onClick={() =>
								history.push({
									pathname: "/subscription",
								})
							}
						>
							<i
								className="button-icon-container far fas fa-address-card"
								style={{ color: "var(--primary-color)" }}
							></i>

							<span className="btn-text" id="btn-text">
								Subscription
							</span>
						</div>
					</>
				)}
				<div
					className="btn-menu-sidenav"
					onClick={() =>
						history.push({
							pathname: "/settings",
							state: { page_id: 5 },
						})
					}
				>
					<i
						className="button-icon-container far fas fa-user-alt"
						style={{ color: "var(--highlight-orange)" }}
					></i>

					<span className="btn-text" id="btn-text">
						Employee List
					</span>
				</div>
				{company_role !== "viewer" && (
					<div
						className="btn-menu-sidenav"
						style={{
							backgroundColor: "var(--highlight-red)",
							color: "white",
						}}
						onClick={_handleSwitchToViewer}
					>
						<i
							className="button-icon-container fa fa-mask"
							style={{
								color: "white",
							}}
						></i>
						<span
							className="btn-text"
							id="btn-text"
							style={{
								color: "white",
							}}
						>
							Switch To Viewer
						</span>
					</div>
				)}
			</div>
			<div className="version-content" id="version-content">
				Nibjar v{packageJson.version} | {moment().format("YYYY")}
			</div>
			<QuickAddPaymentModal
				isVisible={quickAddState}
				handleOpen={_handleModelOpen}
				handleClose={_handleModelClose}
				paymentPrintView={(data) => {
					setSideModalData(data);
					setSideModal(true);
				}}
			/>
			<SideModal
				isVisible={showSideModal}
				onModalClose={() => {
					setSideModal(!showSideModal);
					setSideModalData({});
				}}
				containerStyle={{ minWidth: "310px" }}
				title="Payment Preview"
				sideModalData={sideModalData}
				modelFor="payment"
			></SideModal>
		</div>
	);
}

export default SideNavigationComponent;

import React from "react";
import "./switch-customer.modal.scss";
import Modal from "react-modal";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

export default function ReviewBookingModal(props) {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			// marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="bg-default switch-customer-modal-wrapper">
				<h3>Automated Review Booking!</h3>
				<ul>
					<li>
						{props.reviewData.firstPaidPercent} was paid as the
						initial payment for this booking which is Rs.
						{props?.reviewData?.firstPaidAmount} recorded on{" "}
						{props?.reviewData?.firstPaidDate?.split("T")[0]}.
					</li>
					<li>
						Total Payment recevied till date is Rs.
						{props?.reviewData?.totalPayment} which is{" "}
						{props?.reviewData?.totalPaidPercent} of booking cost.
					</li>
					<li>
						The lastest payment from the customer is Rs.
						{props?.reviewData?.lastPaidAmount} received on{" "}
						{props?.reviewData?.lastPaidDate?.split("T")[0]}.
					</li>
					<li>
						The pending amount for this booking is Rs.
						{props?.reviewData?.remainingAmount} which is{" "}
						{props?.reviewData?.remainingAmountPercent}.
					</li>
					{props?.reviewData?.vatable_customer ? (
						<li>Vatable Customer</li>
					) : (
						<li>Non Vatable Customer</li>
					)}
				</ul>
				{/* <p>
					{props.reviewData.firstPaidPercent} was paid as the initial
					payment for this booking which is Rs.
					{props.reviewData.firstPaidAmount} recorded on{" "}
					{props.reviewData.firstPaidDate.split("T")[0]}. Total
					Payment recevied till date is Rs.
					{props.reviewData.totalPayment} which is{" "}
					{props.reviewData.totalPaidPercent} of booking cost. The
					lastest payment from the customer is Rs.
					{props.reviewData.lastPaidAmount} received on{" "}
					{props.reviewData.lastPaidDate.split("T")[0]}. The pending
					amount for this booking is Rs.
					{props.reviewData.remainingAmount} which is{" "}
					{props.reviewData.remainingAmountPercent}.
				</p> */}
				{/* <div className="modal-action-button-container">
					<button
						className="button-wrapper button-container"
						onClick={props.handleClose}
					>
						No
					</button>
					<button
						className="button-wrapper button-container"
						onClick={props.yesOnClick}
					>
						Yes
					</button>
				</div> */}
			</div>
		</Modal>
	);
}

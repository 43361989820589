import {
	FETCH_INVENTORY_REQUEST,
	FETCH_INVENTORY_CAT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_INVENTORY_ERROR,
	SET_INVENTORY_ON_EDIT,
	CLEAR_INVENTORY_ON_EDIT,
	CHANGE_ADD_REQUEST,
	ADD_ORDER,
	GET_INVENTORY_COUNT,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchInventoryRequest = () => {
	return {
		type: FETCH_INVENTORY_REQUEST,
	};
};

export const fetchInventoryCatSuccess = (category) => {
	return {
		type: FETCH_INVENTORY_CAT_SUCCESS,
		payload: category,
	};
};

export const fetchInventorySuccess = (inventory) => {
	return {
		type: FETCH_INVENTORY_SUCCESS,
		payload: inventory,
	};
};

export const fetchInventoryError = (error) => {
	return {
		type: FETCH_INVENTORY_ERROR,
		payload: error,
	};
};

export const getInventoryCount = (data) => {
	return {
		type: GET_INVENTORY_COUNT,
		payload: data,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchInventoryError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchInventoryError(error.message));
	}
}

export const fetchInventoriesByLocationId = (location_id) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.inventoryURL}inventory?location_id=${location_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchInventorySuccess(res.data.result));
				} else {
					await dispatch(fetchInventoryError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const setInventoryOnEdit = (inventory) => {
	return (dispatch) => {
		dispatch({
			type: SET_INVENTORY_ON_EDIT,
			payload: inventory,
		});
	};
};
export const clearInventoryOnEdit = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_INVENTORY_ON_EDIT,
		});
	};
};

export const searchInventory = (inventory) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();
		const url = `${strings.inventoryURL}inventory/search?item_name=${inventory.item_name}&total_quantity=${inventory.total_quantity}&brand=${inventory.brand}&sub_type=${inventory.sub_type}&location_id=${inventory.location_id}`;
		axios
			.get(url, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchInventorySuccess(res.data.result));
				} else {
					await dispatch(fetchInventoryError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addInventories = (inventories) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.inventoryURL}inventory`, inventories, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchInventoriesByLocationId(inventories.location_id)
					);
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const changeAddRequest = (value, index, key) => {
	return {
		type: CHANGE_ADD_REQUEST,
		payload: { value, index, key },
	};
};

export const addOrder = (value, key, field, index = 0) => {
	return {
		type: ADD_ORDER,
		payload: { value, key, field, index },
	};
};

export const fetchCategoriesByLocationId = (location_id) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.inventoryURL}inventory/category?location_id=${location_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchInventoryCatSuccess(res.data.result));
				} else {
					await dispatch(fetchInventoryError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateInventory = (inventory) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}inventory/update`, inventory, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchInventoriesByLocationId(inventory.location_id)
					);
				} else {
					await dispatch(fetchInventoryError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchInventoriesCount = (location_id) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.inventoryURL}inventory/item/count?location_id=${location_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(getInventoryCount(res.data.result));
				} else {
					await dispatch(fetchInventoryError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateCategories = (categories) => {
	return async (dispatch) => {
		dispatch(fetchInventoryRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.inventoryURL}inventory/category/update`,
				categories,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					// await dispatch(
					// 	fetchInventoriesByLocationId(categories.location_id)
					// );
					return true;
				} else {
					await dispatch(fetchInventoryError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

import React from "react";
import "./extra-page.style.scss";
import { useHistory } from "react-router";
import strings from "../../global/strings";
// import { useSelector } from "react-redux";

export const ExtraPage = ({ type, accessDenied = false, message }) => {
    const history = useHistory();
    // const { errorMessage } = useSelector((state) => state.support);

    return (
        <div className="main-container">
            {accessDenied ? (
                <div className="inner-container full">
                    <div className="page-wrapper">
                        <div className="bg-default extra-page-container">
                            <h2 className="extra-page-title">
                                Access Denied
                            </h2>
                            <span className="extra-page-sub-title">
                                {message}
                            </span>
                            <h2 className="extra-page-message">
                                Let’s Get You Back To Where You Were.
                            </h2>
                            <span className="extra-page-button-container">
                                <button
                                    className="button-wrapper page-notfound-back-button"
                                    onClick={() => history.goBack()}
                                >
                                    Back
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="inner-container full">
                    <div className="page-wrapper">
                        <div className="bg-default extra-page-container">
                            <h2 className="extra-page-title">
                                {type === "error"
                                    ? strings.errorPage.title
                                    : strings.pageNotFound.title}
                            </h2>
                            <span className="extra-page-sub-title">
                                {type === "error"
                                    ? strings.errorPage.subTitle
                                    : strings.pageNotFound.subTitle}
                            </span>
                            <h2 className="extra-page-message">
                                Let’s Get You Back To Where You Were.
                            </h2>
                            <span className="extra-page-button-container">
                                <button
                                    className="button-wrapper page-notfound-back-button"
                                    onClick={() => history.goBack()}
                                >
                                    Back
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExtraPage;

import React, { useEffect, useState } from "react";
import strings from "../../global/strings";
import FormSelectBox from "../../components/common/select-box/form-select-input.component";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
	updateInfo,
	getVenueData,
} from "../../redux/actions/complete-registration.action";
import VenueColorSelector from "../../components/common/checkbox-input/venue-color/venue-color.selector";

const schema = yup.object().shape({
	venue_name: yup.string().required("required"),
	venue_address: yup.string().required("required"),
	venue_city: yup.string().required("required"),
	venue_country: yup.string().required("required"),
	venue_phone: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
});

export default function RegisterVenueInformation({
	venueInfoId,
	setProcessStep,
	processStep,
	venueProcessStep,
	setVenueProcessStep,
	venueCount,
	venueNav,
}) {
	const { register, handleSubmit, errors, reset, watch } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});
	const dispatch = useDispatch();
	const [toggleVenueColor, setToggleVenueColor] = useState(false); // to toggle venue color option
	const [selectedVenueColor, setSelectedVenueColor] = useState("");
	let venue_color = watch("venue_color_code");

	useEffect(() => {
		const _getVenueData = async () => {
			let venueData = await dispatch(getVenueData(venueInfoId));
			reset(venueData);
		};
		if (venueInfoId) {
			_getVenueData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venueInfoId]);

	const onPrevClick = () => {
		if (venueProcessStep <= venueCount - 1 && venueProcessStep >= 1) {
			setVenueProcessStep(venueProcessStep - 1);
		} else {
			setProcessStep(processStep - 1);
		}
	};

	const _returnVenueInfo = async (venueInfo) => {
		let updateStatus = await dispatch(
			updateInfo(strings.VENUE_UPDATE_URL, venueInfo)
		);
		if (updateStatus) {
			if (venueCount > venueProcessStep + 1) {
				setVenueProcessStep(venueProcessStep + 1);
			} else {
				setProcessStep(processStep + 1);
			}
		}
	};

	//sanat guarentee
	const toggle = (val) => {
		if (val) {
			setSelectedVenueColor("");
		} else {
			setSelectedVenueColor(watch("venue_color_code"));
		}
		setToggleVenueColor(val);
	};

	return (
		<form onSubmit={handleSubmit(_returnVenueInfo)}>
			<div className="form-main-content bg-default">
				<div className="form-header">
					{/* <div className="form-sub-title">Company Information</div> */}
					<div className="form-sub-title">Venue Settings</div>
					{venueNav()}
				</div>
				<div className="form-body">
					<div
						className="form-input-container"
						style={{ display: "none" }}
					>
						<FormTextInput
							variant="form-lg"
							name="venue_info_id"
							ref={register}
						/>
					</div>
					{/* <div className="form-input-container" style={{ display: "none" }}>
						<FormTextInput name="no_of_halls"ref={register}/>
					</div> */}
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Venue Name*"
							label="Venue Name*"
							name="venue_name"
							ref={register}
							error={errors?.venue_name?.message}
						/>
						<FormTextInput
							variant="form-lg"
							type="number"
							placeholder="Venue Contact*"
							label="Venue Primary Contact*"
							name="venue_phone"
							ref={register}
							error={errors?.venue_phone?.message}
						/>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<VenueColorSelector
								color={venue_color}
								ref={register}
								name="venue_color_code"
								variant="registration"
								toggleFlag={toggleVenueColor}
								toggle={toggle}
							/>

							<FormSelectBox
								label="Number of Halls*"
								variant="form-sm"
								options={strings.halls}
								name="no_of_halls"
								ref={register}
								type="Halls"
								error={errors?.no_of_halls?.message}
							/>
						</div>
						<FormTextInput
							variant="form-lg"
							type="email"
							placeholder="Venue email*"
							label="Venue email"
							name="venue_email"
							ref={register}
							error={errors?.venue_email?.message}
						/>
					</div>

					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Venue address*"
							label="Venue address*"
							name="venue_address"
							ref={register}
							error={errors?.venue_address?.message}
						/>
						<FormTextInput
							variant="form-lg"
							placeholder="Venue Website*"
							label="Venue Website"
							name="venue_website"
							ref={register}
							error={errors?.venue_website?.message}
						/>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormSelectBox
								variant="form-sm"
								label="City*"
								options={strings.city}
								name="venue_city"
								ref={register}
								type="Country"
								error={errors?.venue_city?.message}
							/>
							<FormSelectBox
								variant="form-sm"
								label="Country*"
								options={strings.country}
								name="venue_country"
								ref={register}
								type="Country"
								error={errors?.venue_country?.message}
							/>
						</div>
						<FormTextInput
							variant="form-lg"
							placeholder="Social Media*"
							label="Social Media"
							name="social_media"
							ref={register}
							error={errors?.social_media?.message}
						/>
					</div>
				</div>
				<div className="form-footer two">
					<button className="button-primary" onClick={onPrevClick}>
						Back
					</button>
					<button className="button-primary" type="submit">
						Next
					</button>
				</div>
			</div>
		</form>
	);
}

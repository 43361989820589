import {
	FETCH_IMAGE_UPLOAD_REQUEST,
	FETCH_IMAGE_UPLOAD_SUCCESS,
	FETCH_IMAGE_UPLOAD_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
};

export default function imageUploadReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_IMAGE_UPLOAD_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_IMAGE_UPLOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case FETCH_IMAGE_UPLOAD_ERROR:
			return {
				...state,
				isLoading: false,
			};

		default:
			return state;
	}
}

import React from "react";
import "./menu-svg.scss";

export default function Pork({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 20 13.82"
            id="pork">
            <g>
                <path d="M8.61,7.62c-.27.48-.73.73-1,.56s-.3-.69,0-1.16.74-.74,1-.57S8.89,7.14,8.61,7.62Z" />
                <path d="M12.4,8.18c-.29.17-.75-.08-1-.56s-.25-1,0-1.17.75.08,1,.57S12.69,8,12.4,8.18Z" />
                <path d="M15.94,5.05V9a4.88,4.88,0,0,1-4.88,4.87H8.93A4.88,4.88,0,0,1,4.06,9V5.05a4.6,4.6,0,0,1,.08-.84H8.08a5.13,5.13,0,0,0-2.5,4.55c0,2.79,2.79.64,4.44.64s4.46,2.23,4.39-.76A5.24,5.24,0,0,0,12,4.22h3.91A4.6,4.6,0,0,1,15.94,5.05Z" />
                <path d="M20,0a4.5,4.5,0,0,1-4.14,4.21H12a4,4,0,0,0-1-.39,3.92,3.92,0,0,0-2.91.39H4.14A4.5,4.5,0,0,1,0,0Z" />
            </g>
        </svg>
    );
}

/* eslint-disable array-callback-return */
import {
	FETCH_EMAIL_VERIFICATION_REQUEST,
	FETCH_EMAIL_VERIFICATION_SUCCESS,
	FETCH_EMAIL_VERIFICATION_ERROR,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import history from "../history";
import { fetchLoginError } from "./login-action";
import { isEmptyObject } from "../../global/function";

export const fetchEmailVerificationRequest = () => {
	return {
		type: FETCH_EMAIL_VERIFICATION_REQUEST,
	};
};

export const fetchEmailVerificationSuccess = () => {
	return {
		type: FETCH_EMAIL_VERIFICATION_SUCCESS,
		payload: {
			title_message: "CONGRATULATIONS!",
			sub_title_message: "Your Account has been created",
			description_message:
				"LETS FINALIZE YOUR COMPANY INFORMATION AND SETUP YOUR VENUES",
		},
	};
};

export const fetchEmailVerificationError = (error) => {
	return {
		type: FETCH_EMAIL_VERIFICATION_ERROR,
		payload: {
			title_message: "ERROR!",
			sub_title_message: error,
		},
	};
};

export const verifyUser = (token, email) => {
	return (dispatch) => {
		dispatch(fetchEmailVerificationRequest());
		return axios
			.post(
				`${strings.url}user/verification?verificationToken=${token}&user_email=${email}`
			)
			.then((res) => {
				if (res.data.success) {
					localStorage.clear();
					localStorage.setItem("token", res.data.token);
					localStorage.setItem("isLoggedIn", true);

					dispatch(fetchEmailVerificationSuccess());
					return res.data;
				} else {
					dispatch(fetchEmailVerificationError(res.data.message));
					showToastMessage(res.data.message, "W");
					return res.data.message;
				}
			})
			.catch((error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					dispatch(fetchLoginError(error.response.data.message));
					showToastMessage(error.response.data.message, "E");
				} else {
					dispatch(fetchEmailVerificationError(error.message));
					showToastMessage(error.message, "E");
					return error.response.data.message;
				}
			});
	};
};

export const verifyNewEmployee = (token, email) => {
	return (dispatch) => {
		dispatch(fetchEmailVerificationRequest());
		return axios
			.post(
				`${strings.url}user/employee/verification?verificationToken=${token}&user_email=${email}`
			)
			.then((res) => {
				if (res.data.success) {
					localStorage.clear();
					localStorage.setItem("token", res.data.token);
					localStorage.setItem("isLoggedIn", true);
					dispatch(fetchEmailVerificationSuccess());
					/*Later Needs to reconfigure this  */
					return res.data;
				} else {
					dispatch(fetchEmailVerificationError(res.data.message));
					showToastMessage(res.data.message, "W");
					return res.data.message;
				}
			})
			.catch((error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					dispatch(fetchLoginError(error.response.data.message));
					showToastMessage(error.response.data.message, "E");
				} else {
					dispatch(fetchEmailVerificationError(error.message));
					showToastMessage(error.response.data.message, "E");
					return error.response.data.message;
				}
			});
	};
};

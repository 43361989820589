/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
// import "./payment.style.scss";
import { initiatePayment } from "../../redux/actions/payment-action";
import { useDispatch, useSelector } from "react-redux";

import strings from "../../global/strings";
import BookingSummaryPayment from "../../components/booking/booking-summary/booking-summary-payment-component";
import FormTextArea from "../../components/common/text-input/form-textarea-input.component";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmptyObject, rupeeConverter } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";
import TextInputWithErrorComponent from "../../components/common/text-input/text-input-with-error.component";
import PaymentTypeRadio from "../../components/common/radio-button/payment-type.radio";

const paymentSchema = yup.object().shape(
	{
		payment_option: yup.string().required("Please select payment option"),
		cheque_number: yup.string().when("payment_option", {
			is: (value) => value === "cheque",
			then: yup.string().required("Please enter cheque number"),
		}),

		discount_amount: yup.string().when("payment_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),
			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
		payment_amount: yup.string().when("discount_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),

			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
	},
	["payment_amount", "discount_amount", "payment_option"]
);

export default function AddPaymentComponent(props) {
	const { register, handleSubmit, errors, watch, reset } = useForm({
		mode: "onSubmit",
		defaultValues: {},
		resolver: yupResolver(paymentSchema),
	});
	const dispatch = useDispatch();
	const { bookingPayment, eventPayment } = useSelector(
		(state) => state.payment
	);

	const [paymentType, setPaymentType] = useState();
	const [blockStatus, setBlockStatus] = useState(false);
	const [blockMessage, setBlockMessage] = useState("Cannot initiate payment");
	let paymentOption = watch("payment_option");

	useEffect(() => {
		setPaymentType(props.paymentType);
		setBlockStatus(props.blockStatus);
		setBlockMessage(props.blockMessage);
		if (props.paymentType === "booking") {
			if (!isEmptyObject(bookingPayment)) {
				if (
					bookingPayment.booking_status === "cancelled" ||
					bookingPayment.booking_status === "hold" ||
					bookingPayment.booking_status === "complete"
				) {
					setBlockStatus(true);
					setBlockMessage(
						`{Booking status is ${bookingPayment.booking_status}. So you cannot initiate payment}`
					);
				}
				if (bookingPayment.totalBalance <= 0) {
					setBlockStatus(true);
					setBlockMessage(
						"Payment has been completed for this booking"
					);
				}
			}
		} else if (props.paymentType === "event") {
			setBlockStatus(true);
			setBlockMessage("All payment has been recorded");
			if (!isEmptyObject(eventPayment)) {
				eventPayment.bookingPayments.map((payment) => {
					if (
						payment.selection_status === "unselected" ||
						payment.selection_status === "selected"
					) {
						setBlockStatus(false);
					}
				});
				if (eventPayment.eventTotalPaymentRemaining <= 0) {
					setBlockStatus(true);
					setBlockMessage(
						"Payment has been completed for this booking"
					);
				}
			}
		}
	}, [props, bookingPayment, eventPayment]);

	/* function to arrange data for event payment */
	const eventOnClickPayment = (paymentDetails) => {
		let array = [];
		var selection_status = false;
		eventPayment.bookingPayments.map((paym) => {
			if (paym.selection_status === "selected") {
				selection_status = true;

				array.push({
					booking_info_id: paym["booking_info_id"],
					venue_info_id: paym["venue_info_id"],

					ratio: (paym["ratio"] = parseFloat(
						paym["totalBalance"] / eventPayment.selectedPayment
					)),
					status: paym["selection_status"],
					booking_status:
						paym["booking_status"] === "inquiry"
							? "booked"
							: "confirmed",
				});
			}
		});

		paymentDetails["bookings"] = array;
		if (selection_status === true) {
			proceedPayment(paymentDetails);
		} else {
			showToastMessage("Please select any of the bookings", "E");
		}
	};

	/* function to arrange data for booking payment */
	const bookingPayOnClick = (paymentDetails) => {
		let array = [];
		var bookingsPayment = {};
		bookingsPayment["ratio"] = 1;
		bookingsPayment["booking_info_id"] = bookingPayment.booking_info_id;
		bookingsPayment["venue_info_id"] = bookingPayment.venue_info_id;
		bookingsPayment["booking_status"] =
			bookingPayment.booking_status === "inquiry"
				? "booked"
				: "confirmed";
		array.push(bookingsPayment);
		paymentDetails["bookings"] = array;
		proceedPayment(paymentDetails);
	};

	const payOnClick = async (paymentDetails) => {
		if (paymentType === "booking") {
			bookingPayOnClick(paymentDetails);
		} else if (paymentType === "event") {
			eventOnClickPayment(paymentDetails);
		}
	};

	const proceedPayment = async (paymentData) => {
		let success = await dispatch(initiatePayment(paymentData));
		if (!isEmptyObject(success)) {
			props.setPrintData(success.result);
			props.setPaymentSuccessFlag(true);
		}
	};

	const _renderBookingDetailsView = () => {
		return (
			<div className="inner-container mt-30px">
				{props.detailsView}
				<div className="main-inner-container">
					<div className="inner-content rows ">
						<PaymentTypeRadio
							name="payment_option"
							ref={register}
							paymentType={strings.paymentType}
							error={errors?.payment_option?.message}
						/>
					</div>
					<div className="inner-content rows mt-15px">
						<TextInputWithErrorComponent
							variant="form-lg edit "
							label="Cheque number"
							name="cheque_number"
							ref={register}
							error={errors?.cheque_number?.message}
							type="text"
							disabled={paymentOption !== "cheque"}
						/>
					</div>
				</div>

				<div className="main-inner-container">
					<div className="inner-content rows ">
						<TextInputWithErrorComponent
							variant="form-lg edit "
							label="Payment Amount"
							name="payment_amount"
							ref={register}
							error={errors?.payment_amount?.message}
							type="number"
						/>
					</div>
					<div className="inner-content rows ">
						<TextInputWithErrorComponent
							variant="form-lg edit "
							label="Discount Amount"
							name="discount_amount"
							ref={register}
							error={errors?.discount_amount?.message}
							type="number"
						/>
					</div>
					<div className="inner-content cols">
						<FormTextArea
							label="Payment Notes"
							variant="form-notes"
							name="notes"
							ref={register}
							placeholder="Notes"
							// disabled={!editableFlag}
						/>
					</div>
				</div>

				<div className="main-inner-container">
					{!isEmptyObject(eventPayment) && (
						<div className="inner-content rows ">
							<TextInputWithErrorComponent
								variant="form-lg "
								label="Selected Balance Amount"
								// name="discount_amount"
								value={rupeeConverter(
									eventPayment?.selectedPayment?.toFixed(2)
								)}
								disabled
							/>
						</div>
					)}
					{!blockStatus && (
						<div className="inner-content button ">
							<button
								className="button-primary"
								id="clear-button"
								onClick={() => reset({})}
							>
								Clear
							</button>
							<button
								className="colored-button-wrapper bg-buttonSuccess text-fontWhite"
								onClick={handleSubmit(payOnClick)}
							>
								Pay
							</button>
						</div>
					)}
				</div>
				{blockStatus && (
					<div className="payment-button-container message">
						{blockMessage}
					</div>
				)}
			</div>
		);
	};
	return (
		<div>
			<div className="new-payment-header mt-15px mb-15px">Bookings</div>
			<div className="inner-container full">
				<div className="flex flex-wrap flex-row gap-15px ">
					{paymentType === "booking"
						? !isEmptyObject(bookingPayment) && (
								<BookingSummaryPayment event={bookingPayment} />
						  )
						: !isEmptyObject(eventPayment) &&
						  eventPayment.bookingPayments.map((b) => {
								return (
									<BookingSummaryPayment
										event={b}
										key={b.booking_info_id}
										paymentFor="payment"
									/>
								);
						  })}
				</div>
			</div>
			{_renderBookingDetailsView()}
		</div>
	);
}

export const FETCH_BOOKING_REQUEST = "fetchBookingRequest";
export const FETCH_BOOKING_REQUEST_SUCCESS = "fetchBookingRequestSuccess";
export const FETCH_SINGLE_BOOKING_SUCCESS = "fetchSingleBookingSuccess";
export const FETCH_ADD_BOOKING_SUCCESS = "fetchAddBookingSuccess";
export const FETCH_BOOKING_ERROR = "fetchBookingError";
export const FETCH_BOOKING_HISTORY_SUCCESS = "fetchBookingHistorySuccess";
export const SET_BOOKING_SEARCH_PARAMETERS = "setBookingSearchParameters";

export const EVENT_ADDED = "eventAdded";
export const MENU_ADDED = "menuAdded";

/* Action types for employee settings */
export const FETCH_EMPLOYEE_REQUEST = "fetchEmployeeRequest";
export const FETCH_EMPLOYEE_SUCCESS = "fetchEmployeeSuccess";
export const FETCH_EMPLOYEE_ERROR = "fetchEmployeeError";
export const FETCH_DUPLICATE_EMPLOYEE_ERROR = "fetchDuplicateEmployeeError";
export const FETCH_EMPLOYEE_SUCCESS_REQUEST = "fetchEmployeeSuccessRequest";
export const FETCH_USER_INFO_SUCCESS = "fetchUserInfoSuccess";
export const FETCH_DELETE_EMPLOYEE_SUCCESS = "fetchDeleteEmployeeSuccess";

/* Action types for customer settings */
export const FETCH_CUSTOMER_REQUEST = "fetchCustomerRequest";
export const FETCH_CUSTOMER_SUCCESS = "fetchCustomerSuccess";
export const FETCH_CUSTOMER_ERROR = "fetchCustomerError";
export const FETCH_DUPLICATE_CUSTOMER_ERROR = "fetchDuplicateCustomerError";
export const FETCH_DELETE_CUSTOMER_SUCCESS = "fetchDeleteCustomerSuccess";

export const FETCH_CUSTOMER_DETAILS_SUCCESS = "fetchCustomerDetailsSuccess";
export const FETCH_CUSTOMER_REQUEST_SUCCESS = "fetchCustomerRequestSuccess"; // stops toggle
/* Action types for dashboard settings */
export const FETCH_CALENDAR_REQUEST = "fetchCalendarRequest";
export const FETCH_CALENDAR_SUCCESS = "fetchCalendarSuccess";
export const FETCH_MONTHLY_CALENDAR_SUCCESS = "fetchMonthlyCalendarSuccess";
export const FETCH_CALENDAR_ERROR = "fetchCalendarError";
export const SET_CALENDAR_DATE = "setCalendarDate";
export const SET_CALENDAR_FILTER = "setCalendarFilter";

/* Action types for menu and service settings */
export const FETCH_MENU_REQUEST = "fetchMenuRequest";
export const FETCH_SERVICE_REQUEST = "fetchServiceRequest";
export const FETCH_DRINK_REQUEST = "fetchDrinkRequest";

export const FETCH_MENU_SUCCESS = "fetchMenuSuccess";
export const FETCH_MENU_SEGREGATED_SEARCH_SUCCESS =
	"fetchMenuSegregatedSearchSuccess";
export const FETCH_SERVICE_TYPE_SUCCESS = "fetchServiceTypeSuccess";
export const FETCH_SERVICE_SUCCESS = "fetchServiceSuccess";
export const FETCH_DRINK_SUCCESS = "fetchDrinkSuccess";

export const FETCH_MENU_ERROR = "fetchMenuError";
export const FETCH_SERVICE_TYPE_ERROR = "fetchServiceTypeError";
export const FETCH_SERVICE_ERROR = "fetchServiceError";
export const FETCH_DRINK_ERROR = "fetchDrinkError";

export const FETCH_MENU_UPDATE_SUCCESS = "fetchMenuUpdateSuccess";
export const FETCH_SERVICE_UPDATE_SUCCESS = "fetchServiceUpdateSuccess";
export const FETCH_DRINK_UPDATE_SUCCESS = "fetchDrinkUpdateSuccess";

export const FETCH_MENU_DELETE_SUCCESS = "fetchMenuDeleteSuccess";
export const FETCH_SERVICE_DELETE_SUCCESS = "fetchServiceDeleteSuccess";
export const FETCH_DRINK_DELETE_SUCCESS = "fetchDrinkDeleteSuccess";

/* Action types for email verification */
export const FETCH_EMAIL_VERIFICATION_REQUEST = "fetchEmailVerificationRequest";
export const FETCH_EMAIL_VERIFICATION_SUCCESS = "fetchEmailVerificationSuccess";
export const FETCH_EMAIL_VERIFICATION_ERROR = "fetchEmailVerificationError";

/* Action types for company settings */
export const FETCH_COMPANY_REQUEST = "fetchCompanyRequest";
export const FETCH_COMPANY_REQUEST_SUCCESS = "fetchCompanyRequestSuccess";
export const FETCH_COMPANY_SUCCESS = "fetchCompanySuccess";
export const PIN_CHANGE_SUCCESS = "pinChangeSuccess";
export const FETCH_COMPANY_ERROR = "fetchCompanyError";

export const SIGNUP_FORM_SUBMIT = "SIGNUP_FORM_SUBMIT";
export const SIGNUP_STEP_ONE_UPDATE = "SIGNUP_STEP_ONE_UPDATE";
export const SIGNUP_STEP_TWO_UPDATE = "SIGNUP_STEP_TWO_UPDATE";
export const SIGNUP_SUBMIT_SUCCESS = "SIGNUP_SUBMIT_SUCCESS";
export const SIGNUP_SUBMIT_FAILED = "SIGNUP_SUBMIT_FAILED";
export const SIGNUP_SUBMIT_START = "SIGNUP_SUBMIT_START";

/* Action types for login */
export const FETCH_LOGIN_REQUEST = "fetchLoginRequest";
export const FETCH_LOGOUT = "fetchLogout";
export const FETCH_LOGIN_SUCCESS = "fetchLoginSuccess";
export const FETCH_FORGOT_PASSWORD_SUCCESS = "fetchForgotPasswordSuccess";
export const FETCH_RESET_PASSWORD_SUCCESS = "fetchResetPasswordSuccess";
export const FETCH_LOGIN_ERROR = "fetchLoginError";

/* Action types for complete registration */
export const FETCH_COMPLETE_REGISTRATION_REQUEST =
	"fetchCompleteRegistrationRequest";
// export const FETCH_COMPLETE_REGISTRATION_USER_SUCCESS = "fetchCompleteRegistrationUserSuccess";
// export const FETCH_COMPLETE_REGISTRATION_COMPANY_SUCCESS = "fetchCompleteRegistrationCompanySuccess";
export const FETCH_COMPLETE_REGISTRATION_ERROR =
	"fetchCompleteRegistrationError";
export const FETCH_COMPLETE_REGISTRATION_SUCCESS =
	"fetchCompleteRegistrationSuccess";

/* Action types for event save */
export const SET_TEMP_BOOKING = "setTempBooking";
export const SAVE_BOOKING = "saveBooking";
// export const ON_CHANGE_BOOKING_VAL = "onChangeBookingVal";
export const SET_SELECTED_CUSTOMER = "setSelectedCustomer";
export const DELETE_BOOKING = "deleteBooking";
export const CLEAR_ALL_BOOKING_INFO = "clearAllBookingInfo";

/* Action to show support modal */
export const SHOW_SUPPORT_MODAL = "showSupportModal";
export const HIDE_SUPPORT_MODAL = "hideSupportModal";
export const FETCH_SUPPORT_REQUEST = "fetchSupportRequest";
export const FETCH_SUPPORT_SUCCESS = "fetchSupportSuccess";
export const FETCH_SUPPORT_ERROR = "fetchSupportError";

/* action types for error handling */
export const SET_ERROR_TRUE = "setErrorTrue";

/* Action types for payment */
export const SET_PAYMENT_SEARCH_PARAMETERS = "setPaymentSearchParameters";
export const SET_VAT_SEARCH_PARAMETERS = "setVatSearchParameters";
export const FETCH_PAYMENT_REQUEST = "fetchPaymentRequest";
export const FETCH_EVENT_PAYMENT_SUCCESS = "fetchEventPaymentSuccess";
export const FETCH_REFUND_PAYMENT_SUCCESS = "fetchRefundPaymentSuccess";
export const FETCH_BOOKING_PAYMENT_SUCCESS = "fetchBookingPaymentSuccess";
export const FETCH_PAYMENT_HISTORY_SUCCESS = "fetchPaymentHistorySuccess";
export const FETCH_VAT_HISTORY_SUCCESS = "fetchVatHistorySuccess";
export const UPDATE_BOOKING_PAYMENT_SELECTION_STATUS =
	"updateBookingPaymentSelectionStatus";
export const FETCH_PAYMENT_ERROR = "fetchPaymentError";
export const FETCH_PAYMENT_REQUEST_SUCCESS = "fetchPaymentRequestSuccess";

/* Action types for venue info */
export const FETCH_VENUE_REQUEST = "fetchVenueRequest";
export const FETCH_VENUE_SUCCESS = "fetchVenueSuccess";
export const FETCH_VENUE_ERROR = "fetchVenueError";

/* image upload action type */
export const FETCH_IMAGE_UPLOAD_REQUEST = "fetchImageUploadRequest";
export const FETCH_IMAGE_UPLOAD_SUCCESS = "fetchImageUploadSuccess";
export const FETCH_IMAGE_UPLOAD_ERROR = "fetchImageUploadError";

/* add customer action type */
// export const SIGNUP_FORM_SUBMIT = "SIGNUP_FORM_SUBMIT";
export const ADD_CUSTOMER_BASIC_UPDATE = "addCustomerBasicUpdate";
export const ADD_CUSTOMER_ADVANCE_UPDATE = "addCustomerAdvanceUpdate";
export const ADD_CUSTOMER_CANCELED = "addCustomerCanceled";
// export const SIGNUP_SUBMIT_SUCCESS = "SIGNUP_SUBMIT_SUCCESS";
// export const SIGNUP_SUBMIT_FAILED = "SIGNUP_SUBMIT_FAILED";
// export const SIGNUP_SUBMIT_START = "SIGNUP_SUBMIT_START";

/* notification action types */
export const FETCH_NOTIFICATION_REQUEST = "fetchNotificationRequest";
export const FETCH_NOTIFICATION_SUCCESS = "fetchNotificationSuccess";
export const FETCH_ACTIVITY_SUCCESS = "fetchActivitySuccess";
export const FETCH_NOTIFICATION_ERROR = "fetchNotificationError";

/* vendor action types */
export const FETCH_VENDOR_REQUEST = "fetchVendorRequest";
export const FETCH_VENDOR_SUCCESS = "fetchVendorSuccess";
export const FETCH_VENDOR_ERROR = "fetchVendorError";

// analytics action types
export const FETCH_ANALYTICS_REQUEST = "fetchAnalyticsRequest";
export const FETCH_CUSTOMER_ANALYTICS_REQUEST = "fetchCustomerAnalyticsRequest";
export const FETCH_CUSTOMER_ANALYTICS_REQUEST_SUCCESS =
	"fetchCustomerAnalyticsRequestSuccess";
export const FETCH_ANALYTICS_REQUEST_SUCCESS = "fetchAnalyticsSuccess";
export const FETCH_ANALYTICS_ERROR = "fetchAnalyticsError";
export const FETCH_CUSTOMER_ANALYTICS_ERROR = "fetchCustomerAnalyticsError";

export const SET_VENDOR_ON_EDIT = "setVendorOnEdit";
export const CLEAR_VENDOR_ON_EDIT = "clearVendorOnEdit";

export const FETCH_INVENTORY_REQUEST = "fetchInventoryRequest";
export const FETCH_INVENTORY_CAT_SUCCESS = "fetchInventoryCatSuccess";
export const FETCH_INVENTORY_SUCCESS = "fetchInventorySuccess";
export const FETCH_INVENTORY_ERROR = "fetchInventoryError";
export const SET_INVENTORY_ON_EDIT = "setInventoryOnEdit";
export const CLEAR_INVENTORY_ON_EDIT = "clearInventoryOnEdit";
export const GET_INVENTORY_COUNT = "getInventoryCount";

export const FETCH_REQUEST_REQUEST = "fetchRequestRequest";
export const FETCH_REQUEST_SUCCESS = "fetchRequestSuccess";
export const FETCH_SINGLE_REQUEST_SUCCESS = "fetchSingleRequestSuccess";
export const FETCH_REQUEST_ERROR = "fetchRequestError";
export const CHANGE_REQUEST = "changeRequest";
// export const SET_INVENTORY_ON_EDIT = "setInventoryOnEdit";
// export const CLEAR_INVENTORY_ON_EDIT = "clearInventoryOnEdit";

export const FETCH_ORDER_REQUEST = "fetchOrderRequest";
export const FETCH_ORDER_SUCCESS = "fetchOrderSuccess";
export const FETCH_ORDER_ERROR = "fetchOrderError";
export const ADD_ORDER = "addOrder";
export const EDIT_ORDER = "editOrder";
export const FETCH_SINGLE_ORDER_SUCCESS = "fetchSingleOrderSuccess";

export const CHANGE_ADD_REQUEST = "changeAddReq";
// export const FETCH_ORDER_ERROR = "fetchOrderError";

//Booking Queue
export const GET_BOOKING_QUEUE_REQUEST = "bookingQueueRequested";
export const GET_BOOKING_QUEUE_SUCCESS = "bookingQueueSuccess";
export const GET_BOOKING_QUEUE_ERROR = "bookingQueueError";
export const ADD_BOOKING_QUEUE = "addBookingQueue";

//Booking Review
export const FETCH_BOOKING_REVIEW_REQUEST = "fetchBookingReviewRequest";
export const FETCH_BOOKING_REVIEW_REQUEST_SUCCESS =
	"fetchBookingReviewRequestSuccess";
export const FETCH_BOOKING_REVIEW_ERROR = "fetchBookingReviewError";

//Caterme Subscription
export const FETCH_COMPANY_SUBSCRIPTION_REQUEST =
	"fetchCompanySubscriptionRequest";
export const FETCH_COMPANY_SUBSCRIPTION_SUCCESS =
	"fetchCompanySubscriptionSuccess";
export const ADD_COMPANY_SUBSCRIPTION = "dddCompanySubscription";
export const UPDATE_COMPANY_SUBSCRIPTION = "updateCompanySubscription";
export const DELETE_COMPANY_SUBSCRIPTION = "deleteCompanySubscription";
export const FETCH_COMPANY_SUBSCRIPTION_ERROR = "fetchCompanySubscriptionError";

//LockOut Feature
export const SET_DATEFIX = "setDateFixRedux";

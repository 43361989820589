import {
	FETCH_ANALYTICS_REQUEST,
	FETCH_ANALYTICS_REQUEST_SUCCESS,
	FETCH_CUSTOMER_ANALYTICS_REQUEST_SUCCESS,
	FETCH_CUSTOMER_ANALYTICS_REQUEST,
	FETCH_ANALYTICS_ERROR,
	FETCH_CUSTOMER_ANALYTICS_ERROR,
} from "../action-types";

const initial_state = {
	isLoading: false,
	bookingAnalytics: {},
	customerAnalytics: {},
	error: "",
};

export default function analyticsReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_ANALYTICS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};

		case FETCH_CUSTOMER_ANALYTICS_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};

		case FETCH_ANALYTICS_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				bookingAnalytics: action.payload,
				error: "",
			};

		case FETCH_CUSTOMER_ANALYTICS_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				customerAnalytics: action.payload,
				error: "",
			};

		case FETCH_ANALYTICS_ERROR:
			return {
				...state,
				isLoading: false,
				bookingAnalytics: state.bookingAnalytics,
				error: action.payload,
			};

		case FETCH_CUSTOMER_ANALYTICS_ERROR:
			return {
				...state,
				isLoading: false,
				customerAnalytics: state.customerAnalytics,
				error: action.payload,
			};

		default:
			return state;
	}
}

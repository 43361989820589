import React, { useEffect, useState } from "react";
import TextInputWithErrorComponent from "../../components/common/text-input/text-input-with-error.component";
import "./inventory-item.style.scss";

export default function InventoryItem(props) {
	const [itemQuantity, setItemQuantity] = useState(props.item.add_quantity);

	useEffect(() => {}, [props]);
	return (
		<div className="bg-default rq-item-wrapper">
			<div className="rq-data-display-wrapper">
				<div className="rq-title">{props.item.item_name}</div>
				<div className="rq-body">
					<span>{props.item.item_description.sub_type}</span> -{" "}
					<span>{props.item.item_description.brand}</span>
				</div>
				<div className="rq-footer">
					{" "}
					Stock: {props.item.total_quantity}{" "}
				</div>
			</div>
			<div className="rq-data-display-wrapper">
				<div className="rq-title">
					<div
						className={`button-primary-icon rq-check ${
							itemQuantity > 0 && "edited"
						}`}
					>
						<i className="fas fa-plus"></i>
					</div>
				</div>
				<div className="rq-body"></div>
				<div className="rq-footer base">
					<input
						className="rq-input"
						placeholder="0"
						type="number"
						value={itemQuantity}
						onChange={(e) => {
							setItemQuantity(
								e.target.value > 0 ? e.target.value : 0
							);
						}}
						onBlur={(e) => {
							let obj = {
								val: e.target.value,
								id: props.id,
								cat: props.category,
							};
							props.handleChange(obj);
						}}
					/>
					<span>{props.item.unit}</span>
				</div>
			</div>
		</div>
	);
}

import React, { useEffect, useRef } from "react";
import "./side-modal.style.scss";
import ReactToPrint from "react-to-print";
import PaymentReceiptPrint from "../../print-preview/invoice/payment-receipt/payment-receipt.component";
import FormTextInputComponent from "../../common/text-input/form-text-input.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import {
	currencyFormat,
	isEmptyObject,
	sessionInfo,
} from "../../../global/function";
import { sendPaymentEmail } from "../../../redux/actions/payment-action";
import { useDispatch } from "react-redux";

// const paymentSchema = yup.object().shape({
// 	company_email: yup
// 		.string()
// 		.email("Enter Valid Email")
// 		.required("Req Please select valid email"),
// 	customer_email: yup
// 		.string()
// 		.test(
// 			"isNotGmailOrOutlookOrHotmail",
// 			"Customer Emails have invalid emails",
// 			(value) => {
// 				if (!value) return false; // Allow empty email
// 				const emails = value.split(",").map((email) => email.trim());
// 				const isNotGmailOrOutlookOrHotmail = emails.every(
// 					(email) =>
// 						email.endsWith("@gmail.com") ||
// 						email.endsWith("@outlook.com") ||
// 						email.endsWith("@hotmail.com")
// 				);
// 				return isNotGmailOrOutlookOrHotmail;
// 			}
// 		)
// 		.required("Please select valid emails"),
// });
export default function SideModalList({
	isVisible,
	onModalClose,
	containerStyle,
	modelFor = "payment",
	sideModalData,
}) {
	const dispatch = useDispatch();
	/*
	// const { register, handleSubmit, errors, reset } = useForm({
	// 	mode: "onSubmit",
	// 	defaultValues: {
	// 		company_email: "",
	// 		customer_email: "",
	// 	},
	// 	resolver: yupResolver(paymentSchema),
	// });
	// const sideModalPrintRef = useRef();
	// const multiPrintRef = useRef();


	// useEffect(() => {
	// 	async function setEmailForm() {
	// 		let data = await sessionInfo();
	// 		reset({
	// 			company_email: data.company_email,
	// 			customer_email: sideModalData?.customerEmail,
	// 		});
	// 	}
	// 	//reset to add customer email as default
	// 	if (!isEmptyObject(sideModalData)) {
	// 		setEmailForm();
	// 	}
	// }, [sideModalData, reset]);

	// function _sendEmail(emailData) {
	// 	//Sending email to customer using user specified ids
	// 	let emails = emailData["customer_email"]
	// 		.split(",")
	// 		.map((email) => email.trim());

	// 	const sendEmailData = {
	// 		...emailData,
	// 		...sideModalData,
	// 		customer_email: emails,
	// 	};

	// 	dispatch(sendPaymentEmail(sendEmailData));
	// 	reset();
	// }

	// function _printMulti() {
	// 	//print 3 copies
	// 	return (
	// 		<div style={{ display: "none" }}>
	// 			<div
	// 				className="s-preview-container pay-receipt"
	// 				ref={multiPrintRef}
	// 			>
	// 				<div className="s-preview-container-inner">
	// 					<PaymentReceiptPrint paymentData={sideModalData} />
	// 					<hr />
	// 					<PaymentReceiptPrint paymentData={sideModalData} />
	// 					<hr />
	// 					<PaymentReceiptPrint paymentData={sideModalData} />
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// }
	// const _handlePaymentReceipt = () => {
	// 	return (
	// 		<>
	// 			<div className="s-modal-headder">
	// 				<div className="s-headder-title">{title}</div>
	// 				<div className="s-buttons-wrapper">
	// 					<ReactToPrint
	// 						trigger={() => (
	// 							<div className="s-button iconic">
	// 								<i className="fas fa-print"></i>copies
	// 							</div>
	// 						)}
	// 						content={() => multiPrintRef.current}
	// 					/>
	// 					{_printMulti()}
	// 					<ReactToPrint
	// 						trigger={() => (
	// 							<div className="s-button iconic">
	// 								<i className="fas fa-print"></i>
	// 							</div>
	// 						)}
	// 						content={() => sideModalPrintRef.current}
	// 					/>
	// 				</div>
	// 			</div>
	// 			<div className="s-modal-body">
	// 				<div
	// 					className="s-preview-container pay-receipt"
	// 					ref={sideModalPrintRef}
	// 				>
	// 					<div className="s-preview-container-inner">
	// 						<PaymentReceiptPrint paymentData={sideModalData} />
	// 					</div>
	// 				</div>
	// 				<hr />
	// 				<p>Email Payment Receipt</p>
	// 				<div className="s-form">
	// 					<FormTextInputComponent
	// 						label={"From Email"}
	// 						name={"company_email"}
	// 						type="email"
	// 						ref={register}
	// 						error={errors?.company_email?.message}
	// 					/>
	// 					<FormTextInputComponent
	// 						label={"Customer Email"}
	// 						name={"customer_email"}
	// 						placeholder={sideModalData?.customerEmail}
	// 						type="text"
	// 						ref={register}
	// 						error={errors?.customer_email?.message}
	// 					/>
	// 				</div>
	// 				<div className="s-buttons-wrapper">
	// 					<div
	// 						className="s-button action"
	// 						onClick={handleSubmit(_sendEmail)}
	// 					>
	// 						Send Email
	// 					</div>
	// 					<div
	// 						className="s-button"
	// 						onClick={() => {
	// 							reset();
	// 							onModalClose();
	// 						}}
	// 					>
	// 						Close
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</>
	// 	);
	// };
	*/
	const messages = {
		revenue: {
			first: "Revenue from all passed bookings",
			second: "Revenue from all future bookings for the period",
		},
		payment: {
			first: "Payment Received for Bookings",
			second: "Payments remaining for all bookings",
		},
	};

	const _handleRevenueSummary = () => {
		// console.log(sideModalData);
		const vatView = {
			amount:
				sideModalData.amountData.first - sideModalData.amountData.third,
			pending:
				sideModalData.amountData.first -
					sideModalData.amountData.third >
				0
					? true
					: false,
			message: {
				warn: "You may have revenue that has not been billed",
				safe: "Your have all revenue billed",
			},
		};
		return (
			<div className="s-modal-body-ana-breakdown">
				<div className="breakdown-block">
					<div className="breakdown-title">
						{sideModalData?.sublabels.third}
					</div>
					<div className="breakdown-data">
						Rs. {currencyFormat(sideModalData?.amountData.third)}
					</div>
					<div className="breakdown-subtitle">
						Revenue based on VAT bill.
					</div>
				</div>
				<div className="breakdown-block">
					<div className="breakdown-title">
						Realized VS VAT Billed
					</div>
					<div
						className={`breakdown-data ${
							vatView.pending && "alert-warn"
						}`}
					>
						Rs. {currencyFormat(vatView.amount)}
					</div>
					<div className="breakdown-subtitle">
						{vatView.pending
							? vatView.message.warn
							: vatView.message.safe}
					</div>
				</div>
			</div>
		);
	};
	const _handlePaymentSummary = () => {
		// console.log(sideModalData);
		const excessView = {
			amount:
				sideModalData.amountData.first - sideModalData.amountData.third,
			pending:
				sideModalData.amountData.first -
					sideModalData.amountData.third >
				0
					? true
					: false,
			message: {
				warn: "You may have !! that has not been billed",
				safe: "Your have all !! billed",
			},
		};
		return (
			<div className="s-modal-body-ana-breakdown">
				<div className="breakdown-block">
					<div className="breakdown-title">
						{sideModalData?.sublabels.third}
					</div>
					<div className="breakdown-data">
						Rs. {currencyFormat(sideModalData?.amountData.third)}
					</div>
					<div className="breakdown-subtitle">
						Payments include tax paymnets, advance payments.
					</div>
				</div>
				<div className="breakdown-block">
					<div className="breakdown-title">
						Realized VS VAT Billed
					</div>
					<div
						className={`breakdown-data ${
							excessView.pending && "alert-warn"
						}`}
					>
						Rs. {currencyFormat(excessView.amount)}
					</div>
					<div className="breakdown-subtitle">
						{excessView.pending
							? excessView.message.warn
							: excessView.message.safe}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className={`s-modal-wrapper ${isVisible ? "expanded" : ""}`}>
			<div
				className="s-modal_overlay"
				onClick={() => {
					onModalClose();
				}}
			></div>
			<div className="s-modal-container" style={containerStyle}>
				<div className="s-modal-headder">
					<div className="s-headder-title">
						{sideModalData?.modalTitle}
					</div>
					{/* <div className="s-buttons-wrapper">
						<div className="s-button iconic">
							<i className="fas fa-print"></i>copies
						</div>
						<div className="s-button iconic">
							<i className="fas fa-print"></i>copies
						</div>
					</div> */}
				</div>
				<div className="s-modal-body-ana-breakdown-wrapper">
					<div className="s-modal-body-ana-breakdown">
						<div className="breakdown-block sub-text">
							Description of {sideModalData?.modalTitle} for data
							from {sideModalData?.displayYear}
						</div>
					</div>
					<div className="s-modal-body-ana-breakdown">
						<div className="breakdown-block">
							<div className="breakdown-title">
								Total {sideModalData?.type}
							</div>
							<div className="breakdown-data">
								Rs.{" "}
								{currencyFormat(
									sideModalData?.amountData?.main
								)}
							</div>
						</div>
						<div className="breakdown-block">
							<div className="breakdown-title">
								{sideModalData?.sublabels?.first}
							</div>
							<div className="breakdown-data">
								Rs.{" "}
								{currencyFormat(
									sideModalData?.amountData?.first
								)}
							</div>
							<div className="breakdown-subtitle">
								{messages[sideModalData?.type]?.first}
							</div>
						</div>
						<div className="breakdown-block">
							<div className="breakdown-title">
								{sideModalData?.sublabels?.second}
							</div>
							<div className="breakdown-data">
								Rs.
								{currencyFormat(
									sideModalData?.amountData?.second
								)}
							</div>
							<div className="breakdown-subtitle">
								{messages[sideModalData?.type]?.second}
							</div>
						</div>
					</div>

					<hr />
					{sideModalData?.type === "revenue" &&
						_handleRevenueSummary()}
					{sideModalData?.type === "payment" &&
						_handlePaymentSummary()}

					<div className="breakdown-blocked">
						<i className="fas fa-star"></i>
						Detailed analysis is a CaterMe premium feature.
					</div>
					<div className="s-buttons-wrapper">
						<div
							className="s-button"
							onClick={() => {
								onModalClose();
							}}
						>
							Close
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import {
	FETCH_PAYMENT_REQUEST,
	FETCH_EVENT_PAYMENT_SUCCESS,
	FETCH_REFUND_PAYMENT_SUCCESS,
	FETCH_BOOKING_PAYMENT_SUCCESS,
	FETCH_VAT_HISTORY_SUCCESS,
	FETCH_PAYMENT_ERROR,
	FETCH_PAYMENT_HISTORY_SUCCESS,
	UPDATE_BOOKING_PAYMENT_SELECTION_STATUS,
	FETCH_PAYMENT_REQUEST_SUCCESS,
	// sanat
	SET_PAYMENT_SEARCH_PARAMETERS,
	SET_VAT_SEARCH_PARAMETERS,
} from "../action-types";
const payment = {
	issue_date: "",
	totalBookingCost: 0,
	vatAmount: 0,
	totalBillAmount: 0,
	totalHoldingPayment: 0,
	totalBalance: 0,
	totalBookingPayment: 0,
	totalBookingDiscount: 0,
	totalBookingRefund: 0,
	booked_time: "",
	booked_date_english: "",
	venue_name: "",
	venue_info_id: "",
	venue_color_code: "",
	servicePaymentInfo: 0,
	drinksPaymentInfo: 0,
	totalAdditionalCost: 0,
};
const initial_state = {
	isLoading: false,
	paymentSearchParameters: {
		payment_id: "",
		event_id: "",
		date_from: "",
		date_to: "",
		customer_fname: "",
		customer_lname: "",
		payment_type: "",
		transaction_type: "",
		venue_info_id: "",
		booking_id: "",
	},
	vatSearchParameters: {
		vat_payment_id: "",
		vat_bill_number: "",
		event_id: "",
		date_from: "",
		date_to: "",
		customer_fname: "",
		customer_lname: "",
	},
	eventPayment: {
		issue_date: "",
		selectedPayment: 0,
		bookingPayments: [payment],
		eventTotalCost: 0,
		eventTotalPayment: 0,
		eventTotalPaymentRemaining: 0,
		eventTotalBillAmount: 0,
		eventTotalVat: 0,
		eventTotalDiscount: 0,
	},
	bookingPayment: payment,
	paymentHistoryList: [],
	paymentHistoryCount: 0,
	refundSuccess: "",
	vatHistoryList: [],
	vatHistoryCount: 0,
	error: "",
};

export default function paymentReducer(state = initial_state, action) {
	switch (action.type) {
		case SET_PAYMENT_SEARCH_PARAMETERS:
			return {
				...state,
				paymentSearchParameters: action.payload,
			};
		case SET_VAT_SEARCH_PARAMETERS:
			return {
				...state,
				vatSearchParameters: action.payload,
			};
		case FETCH_PAYMENT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_PAYMENT_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case FETCH_PAYMENT_HISTORY_SUCCESS:
			return {
				...state,
				isLoading: false,
				paymentHistoryList: action.payload.rows,
				paymentHistoryCount: action.payload.count,
			};
		case FETCH_REFUND_PAYMENT_SUCCESS:
			return {
				...state,
				isLoading: false,
				refundSuccess: action.payload,
			};
		case FETCH_BOOKING_PAYMENT_SUCCESS:
			let bookingPayment = {
				...action.payload,
				selection_status: "disabled",
			};

			return {
				...state,
				isLoading: false,
				eventPayment: {
					...state.eventPayment,
					selectedPayment: action.payload.totalBalance,
				},
				bookingPayment: bookingPayment,
				error: "",
			};
		case FETCH_EVENT_PAYMENT_SUCCESS:
			if (action.paymentFor === "payment") {
				action.payload.selectedPayment = 0;
				action.payload.bookingPayments.map((m) => {
					if (
						m.booking_status === "canceled" ||
						m.booking_status === "hold" ||
						m.booking_status === "complete" ||
						m.totalBalance <= 1
					) {
						m["selection_status"] = "disabled";
					} else {
						m["selection_status"] = "unselected";
					}
					return 0;
					//else if(m.booking_status)
				});
			} else if (action.paymentFor === "vat") {
				// action.payload.selectedPayment = 0;
				action.payload.selectedTotalPayment = 0;
				action.payload.selectedTotalVat = 0;
				action.payload.bookingPayments.map((m) => {
					if (m.vat_status === "none" && m.totalBalance <= 1) {
						m["selection_status"] = "unselected";
					} else {
						m["selection_status"] = "disabled";
					}
					return 0;
					//else if(m.booking_status)
				});
			}

			return {
				...state,
				isLoading: false,
				eventPayment: { ...state.eventPayment, ...action.payload },
				bookingPayment: state.bookingPayment,
				error: "",
			};

		case UPDATE_BOOKING_PAYMENT_SELECTION_STATUS:
			// let payment = state.bookingPayment
			let data = action.payload;
			if (action.payload.paymentFor === "payment") {
				state.eventPayment.bookingPayments.map((m) => {
					if (m.booking_info_id === data.bookingInfoId) {
						m.selection_status = data.value;
						if (data.value === "selected") {
							state.eventPayment.selectedPayment =
								m.totalBalance +
								state.eventPayment.selectedPayment;
						}
						if (data.value === "unselected") {
							state.eventPayment.selectedPayment =
								state.eventPayment.selectedPayment -
								m.totalBalance;
						}
					}
					return 0;
				});
			} else if (action.payload.paymentFor === "vat") {
				state.eventPayment.bookingPayments.map((m) => {
					if (m.booking_info_id === data.bookingInfoId) {
						m.selection_status = data.value;
						if (data.value === "selected") {
							state.eventPayment.selectedTotalPayment =
								m.totalBookingCost +
								state.eventPayment.selectedTotalPayment;
							state.eventPayment.selectedTotalVat =
								m.vatAmount +
								state.eventPayment.selectedTotalVat;
						}
						if (data.value === "unselected") {
							// state.eventPayment.selectedPayment =
							// 	state.eventPayment.selectedPayment - m.totalBalance;
							state.eventPayment.selectedTotalPayment =
								state.eventPayment.selectedTotalPayment -
								m.totalBookingCost;
							state.eventPayment.selectedTotalVat =
								state.eventPayment.selectedTotalVat -
								m.vatAmount;
						}
					}
					return 0;
				});
			}

			return {
				...state,
				isLoading: false,
				eventPayment: state.eventPayment,
				bookingPayment: state.bookingPayment,
				error: "",
			};
		case FETCH_VAT_HISTORY_SUCCESS:
			return {
				...state,
				isLoading: false,
				vatHistoryList: action.payload.rows,
				vatHistoryCount: action.payload.count,
			};
		case FETCH_PAYMENT_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
}

import React from "react";
import Boar from "./boar";
import Buff from "./buff";
import Beef from "./beef";
import Chicken from "./chicken";
import Duck from "./duck";
import Fish from "./fish";
import Lamb from "./lamb";
import Mutton from "./mutton";
import OtherNonVeg from "./other-non-veg";
import OtherSeaFood from "./other-sea-food";
import Pork from "./pork";
import Veg from "./veg";
import Staple from "./staple";
import Dal from "./dal";
import Bread from "./bread";

export default function MenuSvg({ name, type }) {
	switch (name) {
		case "boar":
			return <Boar style={type} />;
		case "bread":
			return <Bread style={type} />;
		case "dal":
			return <Dal style={type} />;
		case "staple":
			return <Staple style={type} />;
		case "buff":
			return <Buff style={type} />;
		case "beef":
			return <Beef style={type} />;
		case "chicken":
			return <Chicken style={type} />;
		case "duck":
			return <Duck style={type} />;
		case "fish":
			return <Fish style={type} />;
		case "lamb":
			return <Lamb style={type} />;
		case "mutton":
			return <Mutton style={type} />;
		case "other non-veg":
			return <OtherNonVeg style={type} />;
		case "other sea-food":
			return <OtherSeaFood style={type} />;
		case "pork":
			return <Pork style={type} />;
		case "veg":
			return <Veg style={type} />;

		default:
			return <Buff style={type} />;
	}
}

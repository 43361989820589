import React from "react";
import "./menu-svg.scss";

export default function Boar({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`icon-size ${style}`}
			viewBox="0 0 20 13.82"
			id="boar"
		>
			<g>
				<path d="M9.26,10.39a.31.31,0,0,0-.34,0,.78.78,0,0,0-.26.28c-.15.28-.14.59,0,.69s.44-.06.6-.34a.63.63,0,0,0,.1-.3A.33.33,0,0,0,9.26,10.39Zm2.24.32c-.16-.28-.43-.43-.6-.32s-.17.4,0,.67.42.43.59.34S11.66,11,11.5,10.71Zm0,0c-.16-.28-.43-.43-.6-.32s-.17.4,0,.67.42.43.59.34S11.66,11,11.5,10.71Zm-2.58-.28a.78.78,0,0,0-.26.28c-.15.28-.14.59,0,.69s.44-.06.6-.34a.63.63,0,0,0,.1-.3.33.33,0,0,0-.12-.37A.31.31,0,0,0,8.92,10.43Zm6.94-6.22a4.89,4.89,0,0,0-4.8-4H8.93a4.88,4.88,0,0,0-4.79,4,4.6,4.6,0,0,0-.08.84V9a4.88,4.88,0,0,0,4.87,4.87h2.13A4.89,4.89,0,0,0,15.94,9V5.05A4.6,4.6,0,0,0,15.86,4.21ZM6.92,11.78c-1-.92-1.33-2.49-1.26-4.33a.35.35,0,0,1,.63-.18,13,13,0,0,0,1.2,1.49A3.64,3.64,0,0,0,6.72,11,1.12,1.12,0,0,0,6.92,11.78Zm3.18.33c-.59,0-1.41.47-2,.47h0a.54.54,0,0,1-.31-.09.28.28,0,0,1-.12-.1,1,1,0,0,1-.17-.65,3.2,3.2,0,0,1,.67-2,2.39,2.39,0,0,1,2.47-.89,2.89,2.89,0,0,1,2,2.81C12.7,13.41,11.06,12.11,10.1,12.11Zm3.13-.18a1.12,1.12,0,0,0,.2-.75,3.64,3.64,0,0,0-.77-2.27,13,13,0,0,0,1.2-1.49.35.35,0,0,1,.63.18C14.56,9.44,14.28,11,13.23,11.93ZM10.9,10.39c-.16.1-.17.4,0,.67s.42.43.59.34.18-.41,0-.69S11.07,10.28,10.9,10.39Zm-2,0a.78.78,0,0,0-.26.28c-.15.28-.14.59,0,.69s.44-.06.6-.34a.63.63,0,0,0,.1-.3.33.33,0,0,0-.12-.37A.31.31,0,0,0,8.92,10.43Z" />
				<path d="M20,0a4.51,4.51,0,0,1-4.21,4.22H4.22A4.51,4.51,0,0,1,0,0Z" />
			</g>
		</svg>
	);
}

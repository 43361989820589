import {
	FETCH_COMPLETE_REGISTRATION_REQUEST,
	FETCH_COMPLETE_REGISTRATION_ERROR,
	FETCH_COMPLETE_REGISTRATION_SUCCESS,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchCompleteRegistrationRequest = () => {
	return {
		type: FETCH_COMPLETE_REGISTRATION_REQUEST,
	};
};
export const fetchCompleteRegistrationSuccess = () => {
	return {
		type: FETCH_COMPLETE_REGISTRATION_SUCCESS,
	};
};

export const fetchCompleteRegistrationError = (error) => {
	return {
		type: FETCH_COMPLETE_REGISTRATION_ERROR,
		payload: error,
	};
};
async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCompleteRegistrationError(error.message));
	}
}

export const getUserData = () => {
	return async (dispatch) => {
		dispatch(fetchCompleteRegistrationRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}user/id?user_info_id=${sessionData.user_info_id}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompleteRegistrationSuccess());
					let wid = res.data.result.win_loss_id;
					return { ...res.data.result.userData, wid: wid };
				} else {
					await dispatch(
						fetchCompleteRegistrationError(res.data.message)
					);
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getCompanyData = () => {
	return async (dispatch) => {
		dispatch(fetchCompleteRegistrationRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}companyInfo/id?company_info_id=${sessionData.company_info_id}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompleteRegistrationSuccess());
					return res.data.result;
				} else {
					await dispatch(
						fetchCompleteRegistrationError(res.data.message)
					);
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getVenueData = (venueInfoId) => {
	return async (dispatch) => {
		dispatch(fetchCompleteRegistrationRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(`${strings.url}venueInfo/id?venue_info_id=${venueInfoId}`, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompleteRegistrationSuccess());
					return res.data.result;
				} else {
					await dispatch(
						fetchCompleteRegistrationError(res.data.message)
					);
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateInfo = (url, updateInfo) => {
	return async (dispatch) => {
		dispatch(fetchCompleteRegistrationRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(url, updateInfo, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCompleteRegistrationSuccess());
					return true;
				} else {
					await dispatch(
						fetchCompleteRegistrationError(res.data.message)
					);
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

import {
	FETCH_IMAGE_UPLOAD_REQUEST,
	FETCH_IMAGE_UPLOAD_SUCCESS,
	FETCH_IMAGE_UPLOAD_ERROR,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo, isEmptyObject } from "../../global/function";
import { getCustomerDetailsById } from "./customer-setting.action";
import { fetchCompanyDetails } from "./company-setting.action";
import { getMyDetails } from "./employee-setting.action";
import { fetchLoginError } from "./login-action";
import history from "../history";

export const fetchImageUploadRequest = () => {
	return {
		type: FETCH_IMAGE_UPLOAD_REQUEST,
	};
};

export const fetchImageUploadSuccess = () => {
	return {
		type: FETCH_IMAGE_UPLOAD_SUCCESS,
	};
};

export const fetchImageUploadError = () => {
	return {
		type: FETCH_IMAGE_UPLOAD_ERROR,
	};
};

export const uploadImage = (imageData) => {
	const data = new FormData();
	data.append("image", imageData.image);
	data.append("id", imageData.id);
	data.append("type", imageData.type);
	return async (dispatch) => {
		dispatch(fetchImageUploadRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.fileUploadUrl}excel/image/upload`, data, {
				headers: {
					authorization: `${sessionData.token}`,
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				dispatch(fetchImageUploadSuccess());

				if (res.data.success) {
					showToastMessage(res.data.message, "S");
					if (imageData.type === "customer") {
						dispatch(getCustomerDetailsById(imageData.id));
					} else if (imageData.type === "venue") {
						dispatch(fetchCompanyDetails());
					} else if (imageData.type === "employee") {
						dispatch(getMyDetails(imageData.id));
					}
				} else {
					showToastMessage(res.data.message, "E");
					dispatch(fetchImageUploadError());
				}
			})
			.catch((error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					dispatch(fetchLoginError(error.response.data.message));
					showToastMessage(error.response.data.message, "E");
				} else if (status === 400) {
					showToastMessage(error.response.data.message, "E");
					dispatch(
						fetchImageUploadError(error.response.data.message)
					);
				} else {
					dispatch(fetchImageUploadError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

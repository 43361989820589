import React from "react";
import "./boooking-history-table.style.scss";
import moment from "moment";
import "./table.scss";
import { useHistory } from "react-router-dom";

export default function ActivityMonitorTable({ list }) {
	const history = useHistory();

	const onClickEvent = (list) => {
		if (list.id) {
			history.push({
				pathname: "/booking-event",
				search: `?event_id=${list.event_id}`,
				state: {
					event_info_id: list.event_info_id,
					booking_info_id: list.booking_info_id,
				},
			});
		}
	};

	return (
		<table cellPadding={0} cellSpacing={0} className="table-container">
			<thead>
				<tr>
					<td className="table-header ">
						<div className="table-header-container">Date</div>
					</td>
					<td className="table-header ">
						<div className="table-header-container">Type</div>
					</td>
					<td className="table-header ">
						<div className="table-header-container">Info ID</div>
					</td>
					<td className="table-header ">
						<div className="table-header-container">Activity</div>
					</td>
					<td className="table-header ">
						<div className="table-header-container">Emp Name</div>
					</td>
				</tr>
			</thead>

			<tbody>
				{list.length > 0 &&
					list.map((list, key) => (
						<tr
							className="bg-default data-table-row normal text-capitalize"
							key={key}
							onClick={() => onClickEvent(list)}
						>
							<td>{moment(list.date).format("DD MMM YYYY")}</td>
							<td>{list.type}</td>
							<td>{list.id}</td>
							<td>{list.activity}</td>
							<td>{list.emp_name}</td>
						</tr>
					))}
			</tbody>
		</table>
	);
}

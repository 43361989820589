import React from "react";
import "./menu-svg.scss";

export default function Mutton({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 11.97 19.19"
            id="goat">
            <g >
                <path d="M12,7.05a.38.38,0,0,1-.52.43l-.06,0-1.62-1a.54.54,0,0,1-.22-.59l.08-.28.07-.25a.55.55,0,0,1,.6-.37.56.56,0,0,1,.18.07l.12.07h0l.92.56a.54.54,0,0,1,.23.34Z" />
                <path d="M11.71,8.41l-.09-.32L9.21,6.55a.8.8,0,0,1-.34-.89l.07-.26.16-.54A.78.78,0,0,1,10,4.29a.76.76,0,0,1,.32.11l.22.13a6.9,6.9,0,0,0,0-4.25A.38.38,0,0,0,9.78.23,12.29,12.29,0,0,1,8,3.24,4.08,4.08,0,0,0,4.12,4.41l-3,3.43A4.11,4.11,0,0,0,4.7,14.75,3.63,3.63,0,0,0,6,14.36l3.64-1.14A4.13,4.13,0,0,0,11.71,8.41ZM6.93,7.69A.67.67,0,0,1,6,8a.68.68,0,0,1-.34-.9.67.67,0,0,1,.89-.34A.67.67,0,0,1,6.93,7.69Z" />
                <path d="M5.66,19.19C3,18.47,1.6,17,1.61,14.54a4.09,4.09,0,0,0,3,.71C4.09,16.25,4.89,17.73,5.66,19.19Z" />
            </g>

        </svg>
    );
}

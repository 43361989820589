import React, { useState } from "react";
import "./analytics-component.style.scss";
// charts
//
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { isEmptyObject } from "../../../global/function";
import SelectInput from "../../../components/common/select-box/select-input.component";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

export default function ScatterGraph({ anaDataSet }) {
	const [scatterInput, setScatterInput] = useState({
		threshold: 500000,
		paxRateType: "e_paxRate",
	});

	function _renderScatterPlot() {
		const data = {
			datasets: [
				{
					label: "Pax",
					data: anaDataSet[scatterInput?.paxRateType],
					backgroundColor: "rgba(75, 192, 192, 0.8)", // Set the marker color
					pointBackgroundColor: (context) => {
						const thresholdValue = scatterInput?.threshold; // Define your threshold value here
						const dataIndex = context.dataIndex;
						const xValue = context.dataset.data[dataIndex].x;
						const yValue = context.dataset.data[dataIndex].y;
						return yValue * xValue < thresholdValue
							? "#D35A47"
							: "#3498DB";
					},
				},
			],
		};

		const options = {
			maintainAspectRatio: false,
			scales: {
				x: {
					display: true,
					type: "linear", // Use linear scale for x-axis
					position: "bottom", // Position x-axis at the bottom
					ticks: {
						font: {
							size: 7,
						},
						stepSize: 100,
					},
				},
				y: {
					display: true,
					type: "linear", // Use linear scale for y-axis
					position: "left", // Position y-axis at the left
					ticks: {
						font: {
							size: 7,
						},
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
			},
		};
		return (
			<>
				<div className="ana-line-header">
					<h3 className="ana-l-text">Pax Rate Distribution</h3>

					<div className="ana-inputs-container">
						<div className="ana-l-field">
							<label htmlFor="threshold">Threshold Rs:</label>
							<input
								type="number"
								id="threshold"
								name="threshold"
								value={scatterInput.threshold}
								onChange={(e) => {
									const { name, value } = e.target;
									setScatterInput((prev) => ({
										...prev,
										[name]: value || 0,
									}));
								}}
							/>
						</div>
						<div className="ana-l-field">
							<SelectInput
								name="paxRateType"
								onChange={(e) => {
									const { name, value } = e.target; // <-- moved outside asynchronous context
									setScatterInput((prev) => ({
										...prev,
										[name]: value ? value : "e_paxRate",
									}));
								}}
								value={scatterInput.paxRateType}
								options={[
									{
										id: "e_paxRate",
										value: "E-pax & E-rate",
									},
									{
										id: "a_paxRate",
										value: "A-pax & A-rate",
									},
								]}
								style={{ height: "28px" }}
							/>
						</div>
					</div>
				</div>
				<div className="ana-scatter-chart">
					<Scatter data={data} options={options} />
				</div>
				<div className="ana-line-title">
					<div className="ana-legend-row col">
						<div className="ana-legend">
							<div
								className="ana-legend-color"
								style={{ backgroundColor: "#D35A47" }}
							></div>
							<p>Under Perform</p>
						</div>
						<div className="ana-legend">
							<div
								className="ana-legend-color"
								style={{ backgroundColor: "#3498db" }}
							></div>
							<p>Standard Perform</p>
						</div>
					</div>
					<p>Booking Pax Rate Distribution</p>
				</div>
			</>
		);
	}

	return (
		<div className="ana-line-container">
			{!isEmptyObject(anaDataSet) && _renderScatterPlot()}
		</div>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./customer-profile.style.scss";

import Loader from "../../components/loader/loader.component";
import AddImageModal from "../../components/modals/add-image.modal";
import ProfileComponent from "../../components/profile/profle.component";
import BookingHistoryTable from "../../components/tables/booking-history.table";
import ConfirmationModal from "../../components/modals/confirmation-modal.component";
import AddCustomerModal from "../../components/modals/add-customer-modal/add-customer.modal";

import ErrorPage from "../extra-page/extra.page";
import { isEmptyObject, sessionInfo } from "../../global/function";
import { setErrorTrue } from "../../redux/actions/support-action";
import { uploadImage } from "../../redux/actions/image-upload.action";
import { getCustomerAnalytics } from "../../redux/actions/analytics.action";
import { getBookingHistoryByCustomer } from "../../redux/actions/booking-action";
import {
	deleteCustomer,
	getCustomerDetailsById,
} from "../../redux/actions/customer-setting.action";
import {
	addCustomerAdvanceUpdate,
	addCustomerBasicUpdate,
} from "../../redux/actions/add-customer.action";

import PaginationReact from "../../components/paginationReact/paginationReact.component";

export default function CustomerProfile({ location }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoading, customerDetails, deletedCustomer } = useSelector(
		(state) => state.customerSetting
	);
	const imageUpload = useSelector((state) => state.image);
	const { error } = useSelector((state) => state.support);
	const { bookingHistoryCount, bookingHistoryList } = useSelector(
		(state) => state.booking
	);

	const { customerAnalytics } = useSelector((state) => state.analytics);

	const dataPerPage = 10;
	const [show, setShow] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [fromPage, setFromPage] = useState("Settings");
	const [showAddImageModal, setShowAddImageModal] = useState(false);
	const [companyCustomerId, setCompanyCustomerId] = useState("");
	const [confirmationModalFlag, setConfirmationModalFlag] = useState(false);

	useEffect(() => {
		try {
			let company_role;

			setCompanyCustomerId(location.state.company_customer_id);
			setFromPage(location.state.from_page);
			const getDetails = async () => {
				await sessionInfo().then((data) => {
					company_role = data.company_role;
				});
				await dispatch(getCustomerDetailsById(companyCustomerId));
			};
			const getAnalytics = async () => {
				await dispatch(getCustomerAnalytics(companyCustomerId));
			};
			if (companyCustomerId) {
				getDetails();
				company_role === "admin" && getAnalytics();
			}
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [companyCustomerId, location]);

	useEffect(() => {
		if (companyCustomerId) {
			dispatch(
				getBookingHistoryByCustomer(
					companyCustomerId,
					dataPerPage,
					currentPage
				)
			);
		}
	}, [dispatch, currentPage, companyCustomerId]);

	useEffect(() => {
		if (deletedCustomer) {
			history.goBack();
		}
	}, [deletedCustomer]);

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentPage(currentPage);
	};

	const handleClose = () => {
		setShow(false);
	};

	const handleShow = () => {
		dispatch(
			addCustomerBasicUpdate({
				customer_type: customerDetails.customer_type,
				customer_gender: customerDetails.customer_gender,
				customer_country: customerDetails.customer_country,
				customer_city: customerDetails.customer_city,
				customer_address: customerDetails.customer_address,
				customer_email: customerDetails.customer_email,
				customer_mobile: customerDetails.customer_mobile,
				customer_lname: customerDetails.customer_lname,
				customer_fname: customerDetails.customer_fname,
				company_info_id: customerDetails.company_info_id,
			})
		);
		dispatch(
			addCustomerAdvanceUpdate({
				identification_type: customerDetails.identification_type,
				identification_number: customerDetails.identification_number,
				customer_notes: customerDetails.customer_notes,
				customer_dob: customerDetails.customer_dob,
				customer_phone: customerDetails.customer_phone,
				pan_number: customerDetails.pan_number,
				vat_number: customerDetails.vat_number,
			})
		);
		setShow(true);
	};

	const handleImageModalClose = () => {
		setShowAddImageModal(false);
	};
	const handleDeleteCustomerModalShow = () => setConfirmationModalFlag(true);

	const handleDeleteCustomerModalClose = () => {
		setConfirmationModalFlag(false);
	};
	const handleImageModalShow = () => setShowAddImageModal(true);

	const _deleteCustomer = async () => {
		await dispatch(deleteCustomer(customerDetails.company_customer_id));
		handleDeleteCustomerModalClose();
	};

	const goBack = () => {
		if (fromPage === "settings") {
			history.push({ pathname: "/settings", state: { page_id: 6 } });
		} else {
			history.goBack();
		}
	};

	const onSaveImage = (image) => {
		let data = { image, id: companyCustomerId, type: "customer" };
		dispatch(uploadImage(data));
	};

	return (
		<div className="main-container">
			{isLoading && <Loader />}
			{imageUpload.isLoading && <Loader />}
			{error && <ErrorPage type="error" />}
			<div className="profile-navigation-container">
				<div className="profile-path text-capitalize">
					<span onClick={goBack} className="previous-page-path">
						{fromPage}
					</span>
					&gt;{" "}
					<span className="current-page-path">Customer Details</span>
				</div>
			</div>
			{!isEmptyObject(customerDetails) && (
				<ProfileComponent
					type="customer"
					details={customerDetails}
					editFlag={handleShow}
					showImageModal={handleImageModalShow}
					showDeleteModal={handleDeleteCustomerModalShow}
					editable={true}
				/>
			)}

			{!isEmptyObject(bookingHistoryList) && (
				<div className="inner-container full mt-24px">
					<div className="w-full flex flex-row justify-between px-15px text-fontSemiDark">
						<label htmlFor="">Bookings</label>
						<label
							htmlFor=""
							onClick={() =>
								history.push({ pathname: "/booking-history" })
							}
						>
							View All
						</label>
					</div>
					<BookingHistoryTable
						bookingList={bookingHistoryList}
						filterObject={{
							companyCustomerId,
							dataPerPage,
							currentPage,
						}}
						from="customerProfile"
					/>
					<div className="mt-5 w-full flex justify-center">
						<PaginationReact
							totalCount={bookingHistoryCount}
							itemsPerPage={dataPerPage}
							onPageClick={onPageChange}
							forcePage={currentPage - 1}
						/>
					</div>
				</div>
			)}

			{/* renderMainView() */}
			<AddCustomerModal
				isVisible={show}
				handleClose={handleClose}
				handleShow={handleShow}
				actionType="Update"
			/>

			<AddImageModal
				isVisible={showAddImageModal}
				type="customer"
				handleClose={handleImageModalClose}
				handleShow={handleImageModalShow}
				onSaveClick={onSaveImage}
			/>
			<ConfirmationModal
				type="deleteCustomer"
				isVisible={confirmationModalFlag}
				handleClose={handleDeleteCustomerModalClose}
				handleShow={handleDeleteCustomerModalShow}
				onClickConfirmation={_deleteCustomer}
			/>
		</div>
	);
}

// import React, { useEffect, useState } from "react";
// import { sessionInfo } from "../../../global/function";
import React from "react";
import "../print-page.style.scss";
import caterMeLogo from "../../../assets/images/logo/CaterMe_logo_color_trans.png";

function PrintHeader({ type, category, showCompany }) {
	// const [sessionData, setSessionData] = useState({});

	// useEffect(() => {
	// 	async function fetchSessionData() {
	// 		await sessionInfo().then((data) => {
	// 			setSessionData({
	// 				company_name: data.company_name,
	// 				company_mobile: data.company_mobile,
	// 				company_email: data.company_email,
	// 				company_address: data.company_address,
	// 				company_city: data.company_city,
	// 				company_country: data.company_country,
	// 			});
	// 		});
	// 	}

	// 	fetchSessionData();
	// }, []);

	return (
		<div className="header-wrapper">
			<div className="title">
				<h1>{showCompany ? showCompany : type}</h1>
				<h2>
					{showCompany && type} {category}
				</h2>
			</div>
			<div className="company">
				<div className="logo">
					<img src={caterMeLogo} alt="CM" />
				</div>
				<div className="company-contact">
					<h3>CaterMe</h3>
				</div>
				{/* <div className="company-contact">
					<h3>{sessionData.company_name}</h3>
					<div>
						<i className="fas fa-phone-alt"></i>
						<span>{sessionData.company_mobile}</span>
						<i className="fas fa-envelope"></i>
						<span>{sessionData.company_email}</span>
						<i className="fas fa-map-marker-alt"></i>
						<span>
							{sessionData.company_address}{" "}
							{sessionData.company_city},{" "}
							{sessionData.company_country}
						</span>
					</div>
				</div> */}
			</div>
		</div>
	);
}

export default PrintHeader;

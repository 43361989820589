/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import "./login.style.scss";
import { Link, useHistory } from "react-router-dom";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../../components/common/text-input/form-text-input.component";

import { processLogin } from "../../redux/actions/login-action";
import Loader from "../../components/loader/loader.component";

import { useDispatch, useSelector } from "react-redux";
import { isEmptyObject, sessionInfo } from "../../global/function";
import ForgotPasswordModal from "../../components/modals/forgot-password.modal";
import OpenShopModal from "../../components/modals/open-shop.modal";
import caterMeLogo from "../../assets/images/logo/cater_me_small.png";

const loginSchema = yup.object().shape({
	user_email: yup
		.string()
		.required("Email cannot be empty")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("Password cannot be empty")
		.min(8, "Password must be atleast 8 letters"),
});

export default function Login() {
	const history = useHistory();
	const { register, handleSubmit, errors } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(loginSchema),
	});
	const { isLoading } = useSelector((state) => state.login);
	const dispatch = useDispatch();
	const [sessionData, setSessionData] = useState({});

	const [showForgotPw, setForgotPw] = useState(false);
	const [showOpenShop, setShowOpenShop] = useState(false);
	const [login, setLogin] = useState({}); //stores login info for pin entry

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setSessionData({
					token: data.token,
				});
			});
		}

		fetchSessionData();

		if (sessionData.token) {
			history.push({
				pathname: "/dashboard",
			});
		}
	}, [history]);

	const handleClose = () => setForgotPw(false);
	const handleShowForgotPw = () => setForgotPw(true);

	const _handleLogin = async (loginData) => {
		let loginInfo = await dispatch(processLogin(loginData));
		if (!isEmptyObject(loginInfo)) {
			if (
				loginInfo.hasOwnProperty("shopStatus") &&
				!loginInfo?.shopStatus
			) {
				// COMPANY ENCRYPTED BUT NOT OPENED
				setShowOpenShop(true);
				setLogin(loginData);
			}

			if (loginInfo.success) {
				let user_data = loginInfo.result;
				let role = user_data.company_employees[0].company_role;
				if (
					loginInfo.result.user_status === "inactive" ||
					loginInfo.result.user_verified === false
				) {
					if (role === "admin") {
						history.push({
							pathname: "/user/complete-registration",
						});
					} else if (role === "viewer") {
						history.push({
							pathname:
								"/employee/verification/" +
								loginInfo.token +
								"/" +
								user_data.user_email,
							state: {
								type: "verified",
							},
						});
					}
				} else {
					if (loginInfo.bookingFix) {
						history.push({ pathname: "/yearly-date-fix" });
					} else {
						history.push({ pathname: "/dashboard" });
					}
				}
			}
		}
	};

	return (
		<div className="signin-page-wrapper">
			{isLoading && <Loader />}
			<div className="signup-main-container">
				<div className="bg-default login-form-content">
					<div className="sign-in-content">
						<label className="signin-form-title">SIGN IN</label>
						<FormTextInput
							label="Email"
							placeholder="Email"
							variant="signin"
							name="user_email"
							type="email"
							ref={register}
							error={errors?.user_email?.message}
						/>
						<FormTextInput
							label="Password"
							placeholder="Password"
							variant="signin"
							name="password"
							type="password"
							ref={register}
							error={errors?.password?.message}
						/>
						<span className="signin-controller">
							<span
								className="form-subtitle-container forgot-password"
								onClick={handleShowForgotPw}
							>
								Forgot Password?
							</span>
							<button
								onClick={handleSubmit(_handleLogin)}
								className="button-primary"
								type="submit"
							>
								SIGN IN
							</button>
						</span>
					</div>
					<div className="sign-up-content">
						<label className="signin-form-title signup">
							NEW USER?
						</label>
						<img
							className="cater-me-logo"
							src={caterMeLogo}
							alt="CM"
						/>
						<span className="signup-message">CaterMe</span>
						<Link to="/signup">
							<button className="colored-button-wrapper signup-button">
								SIGN UP
							</button>
						</Link>
					</div>
				</div>
				<ForgotPasswordModal
					isVisible={showForgotPw}
					handleClose={handleClose}
					handleShow={handleShowForgotPw}
				/>
				<OpenShopModal
					isVisible={showOpenShop}
					handleClose={() => {
						setShowOpenShop(false);
						setLogin({});
					}}
					login={login}
					loginSubmit={_handleLogin}
				/>
			</div>
		</div>
	);
}

import { createStore, combineReducers, applyMiddleware } from "redux";
import bookingReducer from "./reducer/booking-reducer";
import employeeSettingReducer from "./reducer/employee-setting.reducer";
import customerSettingReducer from "./reducer/customer-setting.reducer";
import calendarInfo from "./reducer/calendar-info.reducer";
import menuServiceSetting from "./reducer/menu-service-setting.reducer";
import emailVerification from "./reducer/email-verification.reducer";
import signupReducer from "./reducer/signup.reducer";
import companySettingReducer from "./reducer/company-setting.reducer";
import completeRegistrationReducer from "./reducer/complete-registration.reducer";
import savedBookingReducer from "./reducer/event-save.reducer";
import loginReducer from "./reducer/login-reducer";
import supportReducer from "./reducer/support-reducer";
import paymentReducer from "./reducer/payment-reducer";
import venueReducer from "./reducer/venue-reducer";
import addCustomerReducer from "./reducer/add-customer.reducer";
import catermeSubscriptionReducer from "./reducer/caterme-subscription.reducer";

// import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import imageUploadReducer from "./reducer/image-upload.reducer";
import notificationReducer from "./reducer/notification.reducer";
import analyticsReducer from "./reducer/analytics.reducer";
import vendorReducer from "./reducer/vendor.reducer";
import inventoryReducer from "./reducer/inventory.reducer";
import requestReducer from "./reducer/request.reducer";
import orderReducer from "./reducer/order.reducer";

const rootReducer = combineReducers({
	analytics: analyticsReducer,
	booking: bookingReducer,
	signup: signupReducer,
	employeeSetting: employeeSettingReducer,
	customerSetting: customerSettingReducer,
	calendarInfo: calendarInfo,
	menuServiceSetting: menuServiceSetting,
	emailVerification: emailVerification,
	companySetting: companySettingReducer,
	completeRegistration: completeRegistrationReducer,
	savedBooking: savedBookingReducer,
	support: supportReducer,
	login: loginReducer,
	payment: paymentReducer,
	venue: venueReducer,
	image: imageUploadReducer,
	addCustomer: addCustomerReducer,
	notification: notificationReducer,
	vendor: vendorReducer,
	inventory: inventoryReducer,
	request: requestReducer,
	order: orderReducer,

	caterSubscriptions: catermeSubscriptionReducer,
});

let middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
	middleware = [...middleware];
	// const logger = createLogger();
	// middleware = [...middleware, logger];
}

export const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;

import React, { forwardRef } from "react";
import strings from "../../../../global/strings";
import "./venue-color.style.scss";

function VenueColorSelector(props, ref) {
	const toggleColorPicker = (e) => {
		e.preventDefault();

		props.toggle(!props.toggleFlag);
	};

	return (
		<div className={`venue-color-select-wrapper ${props.variant}`}>
			<button
				className={`bodered venue-color-select-button ${props.variant}`}
				onClick={(e) => toggleColorPicker(e)}
				style={{
					backgroundColor: props.color,
					color: `${props.color ? "white" : "#474747"}`,
				}}
				disabled={props.disabled}
			>
				<span className="venue-color-label">Venue Color</span>
				<i
					className={
						props.toggleFlag
							? `fas fa-chevron-down`
							: `fas fa-chevron-right`
					}
				></i>
			</button>
			{props.toggleFlag && (
				<div
					className={`bg-default venue-color-option-wrapper ${props.variant}`}
					id="venue-color-option-wrapper"
				>
					<label htmlFor="" className="venue-color-picker-label">
						Venue Color Picker
					</label>
					<div
						className={`venue-color-selector-wrapper ${props.variant}`}
					>
						{strings.color.map((color) => (
							<span
								className="venue-color-select-container"
								key={color}
							>
								<input
									id={color}
									type="radio"
									className="venue-color-checkbox"
									name="venue_color_code"
									value={color}
									ref={ref}
								/>
								<label
									htmlFor={color}
									className={`bg-bodered color-selector-label ${props.variant}`}
									// onClick={(e) => toggleColorPicker(e)}
									style={{
										backgroundColor: color,
									}}
								></label>
							</span>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

export default forwardRef(VenueColorSelector);

import React from "react";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import { useForm } from "react-hook-form";
import SelectBox from "../common/select-box/form-select-input.component";
import { useDispatch } from "react-redux";

import strings from "../../global/strings";
import {
	fetchRequestByLocationId,
	searchRequest,
} from "../../redux/actions/request.action";

export default function SearchRequestComponent() {
	const { register, handleSubmit, reset } = useForm();
	const dispatch = useDispatch();
	const _clearRequestSearch = () => {
		reset({
			request_id: "",
			request_type: "",
			user: "",
			due_days: "",
			request_status: "",
		});

		dispatch(fetchRequestByLocationId(localStorage.getItem("location_id")));
	};

	function _searchRequest(data) {
		dispatch(searchRequest(data));
	}

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Request</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-lg hidden"
					name="location_id"
					ref={register}
					defaultValue={localStorage.getItem("location_id")}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Request ID"
					placeholder="Enter Request ID"
					name="request_id"
					ref={register}
				/>
				<SelectBox
					options={strings.requestType}
					variant="search"
					label="Request Type"
					name="request_type"
					ref={register}
				/>

				<SimpleTextInput
					variant="form-lg edit"
					label="User "
					placeholder="Enter User"
					type="text"
					name="user"
					ref={register}
				/>

				<SimpleTextInput
					type="number"
					variant="form-md edit"
					placeholder="Enter Due Days"
					label="Enter Due Days"
					name="due_days"
					ref={register}
				/>
				<SelectBox
					options={strings.requestStatus}
					variant="search"
					label="Request Status"
					name="request_status"
					type="Status"
				/>
			</div>
			<div className="flex gap-x-15px justify-end">
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearRequestSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={handleSubmit(_searchRequest)}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}

import {
	FETCH_NOTIFICATION_REQUEST,
	FETCH_NOTIFICATION_SUCCESS,
	FETCH_ACTIVITY_SUCCESS,
	FETCH_NOTIFICATION_ERROR,
} from "../action-types";
import axios from "axios";
import showToastMessage from "../../global/showToastMessage";
import history from "../history";
import strings from "../../global/strings";
import { isEmptyObject, sessionInfo } from "../../global/function";

export const fetchNotificationRequest = () => {
	return {
		type: FETCH_NOTIFICATION_REQUEST,
	};
};

export const fetchNotificationSuccess = (notification) => {
	return {
		type: FETCH_NOTIFICATION_SUCCESS,
		payload: notification,
	};
};

export const fetchActivitySuccess = (activity) => {
	return {
		type: FETCH_ACTIVITY_SUCCESS,
		payload: activity,
	};
};

export const fetchNotificationError = (error) => {
	return {
		type: FETCH_NOTIFICATION_ERROR,
		payload: error,
	};
};

export const fetchActivity = (limit, page) => {
	return async (dispatch) => {
		dispatch(fetchNotificationRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}activity/company?company_info_id=${sessionData.company_info_id}&limit=${limit}&page=${page}`,

				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchActivitySuccess(res.data));
				} else {
					await dispatch(fetchNotificationError(res.data.message));
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else if (status === 400) {
					showToastMessage(error.response.data.message, "E");
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
				} else {
					await dispatch(fetchNotificationError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

// mongo database data
export const fetchNotification = () => {
	return async (dispatch) => {
		let sessionData = await sessionInfo();

		dispatch(fetchNotificationRequest());
		axios
			.get(
				`${strings.inventoryURL}notification/employee?company_employee_id=${sessionData.company_employee_id}`,

				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchNotificationSuccess(res.data));
				} else {
					await dispatch(fetchNotificationError(res.data.message));
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else if (status === 400) {
					showToastMessage(error.response.data.message, "E");
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
				} else {
					await dispatch(fetchNotificationError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const viewNotification = (info) => {
	return async (dispatch) => {
		dispatch(fetchNotificationRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}notification/update`, info, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchNotificationSuccess(res.data));
				} else {
					await dispatch(fetchNotificationError(res.data.message));
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else if (status === 400) {
					showToastMessage(error.response.data.message, "E");
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
				} else {
					await dispatch(fetchNotificationError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

export const clearNotification = (info) => {
	return async (dispatch) => {
		dispatch(fetchNotificationRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}notification/clear`, info, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchNotificationSuccess(res.data));
				} else {
					await dispatch(fetchNotificationError(res.data.message));
				}
			})
			.catch(async (error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else if (status === 400) {
					showToastMessage(error.response.data.message, "E");
					await dispatch(
						fetchNotificationError(error.response.data.message)
					);
				} else {
					await dispatch(fetchNotificationError(error.message));
					showToastMessage(error.message, "E");
				}
			});
	};
};

import React, { forwardRef } from "react";
import "./text-input.style.scss";

function SimpleTextInput(props, ref) {
	return (
		<div className={`${props.variant} text-input-container search`}>
			<input
				{...props}
				placeholder={props.placeholder}
				name={props.name}
				className={`${props.variant} text-input`}
				ref={ref}
			/>
		</div>
	);
}

export default forwardRef(SimpleTextInput);

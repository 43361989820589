import React, { useMemo, useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./transferPayment.style.scss";
import { isEmptyObject } from "../../global/function";
import { transferBooking } from "../../redux/actions/booking-action";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		overflow: "hidden",
	},
};

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

function TransferPaymentModal(props) {
	const dispatch = useDispatch();
	const { bookingData } = useSelector((state) => state.booking);
	const { bookingPayment } = useSelector((state) => state.payment);
	const { bookingList } = useSelector((state) => state.booking);
	const [selected, setSelected] = useState("");
	const filteredValue = useMemo(
		() =>
			!isEmptyObject(bookingList) &&
			bookingList.booking_informations.filter(
				(v) =>
					v.booking_status !== "canceled" &&
					v.payment_status !== "complete" &&
					v.booking_info_id !== bookingData.booking_info_id
			),
		[bookingList, bookingData]
	);

	const _transferPayments = async () => {
		if (selected !== "") {
			let transferData = {
				transfer_type: "payment",
				old_booking_info_id: bookingData.booking_info_id,
				booking_info_id: selected,
			};

			let returned = await dispatch(transferBooking(transferData));

			if (returned.success) {
				setSelected("");
				props.handleClose();
			}
		}
	};
	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title">Transfer Payment</div>
					</div>
					<div className="form-body">
						<div className="tran-pay-wrapper columed">
							<div
								className={`transfer-payment-block bg-default`}
							>
								<div className="tran-pay-title">
									Transfer From:
								</div>
								<div>BID: {bookingData.booking_id}</div>
								<div>EN: {bookingData.booked_date_english}</div>
								<div>NP: {bookingData.booked_date_nepali}</div>
								<div
									style={{
										textTransform: "capitalize",
									}}
								>
									{bookingData.booked_time}
								</div>
							</div>
							<div className="tran-pay-title">
								Transfer Amount:{" "}
								{bookingPayment.totalBookingPayment}
							</div>
						</div>
						<hr />
						<div className="tran-pay-wrapper">
							<div className="tran-pay-title">Transfer To:</div>
							<div className="tran-pay-items">
								{Array.isArray(filteredValue) &&
								filteredValue.length > 0 ? (
									filteredValue?.map((v, k) => (
										<div
											key={k}
											className={`transfer-payment-block bg-default ${
												selected ===
													v.booking_info_id &&
												"selectTrans"
											}`}
											onClick={() =>
												setSelected(v.booking_info_id)
											}
										>
											<div>BID: {v.booking_id}</div>
											<div>
												EN: {v.booked_date_english}
											</div>
											<div>
												NP: {v.booked_date_nepali}
											</div>
											<div
												style={{
													textTransform: "capitalize",
												}}
											>
												{v.booked_time}
											</div>
											<div>
												Payments: {v.payment_status}
											</div>
										</div>
									))
								) : (
									<div>No Active Bookings in this Event</div>
								)}
							</div>
						</div>
					</div>

					<div className="form-footer two">
						<button
							type="button"
							className="button-primary"
							onClick={() => {
								setSelected("");
								props.handleClose();
							}}
						>
							Close
						</button>
						<button
							className="button-primary"
							onClick={_transferPayments}
						>
							Transfer
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default TransferPaymentModal;

import React, { useState, useEffect } from "react";
// import "./payment.style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchEventPayment,
	fetchBookingPayment,
} from "../../redux/actions/payment-action";
import PaymentSuccessModal from "../../components/modals/payment-success.modal";
import Loader from "../../components/loader/loader.component";
import { useHistory } from "react-router-dom";
import PaymentDetailsComponent from "./payment-details.component";
import AddPaymentComponent from "./add-payment.component";
import VatPaymentComponent from "./vat-payment.component";
import ErrorPage from "../extra-page/extra.page";

export default function Payment({ location }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoading } = useSelector((state) => state.payment);
	const { error } = useSelector((state) => state.support);

	const [paymentSuccessFlag, setPaymentSuccessFlag] = useState(false);
	const [paymentType, setPaymentType] = useState("");
	const [customerInfo, setCustomerInfo] = useState({});

	const [printData, setPrintData] = useState({});
	const [paymentFor, setPaymentFor] = useState("");

	useEffect(() => {
		setCustomerInfo(location.state.customerInfo);
		setPaymentType(location.state.paymentType);
		setPaymentFor(location.state.paymentFor);

		const getPaymentInfo = async () => {
			if (paymentType === "booking") {
				await dispatch(
					fetchBookingPayment(location.state.id, paymentFor)
				);
			} else if (paymentType === "event") {
				await dispatch(
					fetchEventPayment(location.state.id, paymentFor)
				);
			}
		};
		getPaymentInfo();
	}, [paymentType, location, dispatch, paymentFor]);

	const clickToPrint = () => {
		history.push({
			pathname: "/payment-receipt",
			state: {
				receiptData: printData,
				customerInfo,
			},
		});
	};

	const returnToBooking = () => {
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${location.state.event_id}`,
			state: {
				event_info_id: location.state.event_info_id,
				booking_info_id: location.state.booking_info_id,
			},
		});
	};

	return (
		<div className="main-container">
			{error && <ErrorPage type="error" />}
			<div className="profile-navigation-container">
				<div className="profile-path text-capitalize">
					<span
						onClick={returnToBooking}
						className="previous-page-path"
					>
						Booking Details
					</span>{" "}
					&gt;{" "}
					<span className="current-page-path">
						{paymentFor === "payment"
							? "Add Payment"
							: "Add VAT Payment"}
					</span>
				</div>
			</div>

			{paymentFor === "vat" && (
				<VatPaymentComponent
					paymentType={paymentType}
					setPaymentSuccessFlag={setPaymentSuccessFlag}
					detailsView={
						<PaymentDetailsComponent paymentType={paymentType} />
					}
				/>
			)}
			{paymentFor === "payment" && (
				<AddPaymentComponent
					paymentType={paymentType}
					setPrintData={setPrintData}
					setPaymentSuccessFlag={setPaymentSuccessFlag}
					detailsView={
						<PaymentDetailsComponent paymentType={paymentType} />
					}
				/>
			)}

			{isLoading && <Loader />}

			<PaymentSuccessModal
				isVisible={paymentSuccessFlag}
				handleClose={() => setPaymentSuccessFlag(false)}
				handleShow={() => setPaymentSuccessFlag(true)}
				printOnClick={clickToPrint}
				paymentFor={paymentFor}
				onBack={returnToBooking}
			/>
		</div>
	);
}

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "./menu-service.component.scss";
import TableMenuService from "../../tables/table-menu-services.component";
import AddMenuModal from "../../modals/add-menu-modal.component";
import AddServiceModal from "../../modals/add-service-modal.component";
import AddDrinkModal from "../../modals/add-drink-modal.component";
import SearchMenuComponent from "../../search/search-menu.component";
import SearchServiceComponent from "../../search/search-service.component";
import SearchDrinkComponent from "../../search/search-drink.component";
import ConfirmationModal from "../../modals/confirmation-modal.component";
import Loader from "../../loader/loader.component";

import {
	fetchMenuItem,
	editMenuItem,
	fetchServiceTypes,
	fetchServiceItem,
	fetchDrinkItem,
	editServiceItem,
	editDrinkItem,
} from "../../../redux/actions/menu-service-settings.action";
import { useDispatch, useSelector } from "react-redux";

// import { socket } from "../../../Socket";
import FileUpload from "../file-upload/file-upload.component";

export default function MenuServicesComponent(props) {
	const [
		showMenuDeleteConfirmationModal,
		setShowMenuDeleteConfirmationModal,
	] = useState(false);
	const [deleteMenuItem, setDeleteMenuItem] = useState("");

	const [
		showServiceDeleteConfirmationModal,
		setShowServiceDeleteConfirmationModal,
	] = useState(false);
	const [deleteServiceItem, setDeleteServiceItem] = useState("");

	const [
		showDrinkDeleteConfirmationModal,
		setShowDrinkDeleteConfirmationModal,
	] = useState(false);
	const [deleteDrinkItem, setDeleteDrinkItem] = useState("");

	const [showMenuModal, setShowMenuModal] = useState(false);
	const [showServiceModal, setShowServiceModal] = useState(false);
	const [showDrinkModal, setShowDrinkModal] = useState(false);

	const [updatedMenuItem, setUpdatedMenuItem] = useState({});
	const [updatedServiceItem, setUpdatedServiceItem] = useState({});
	const [updatedDrinkItem, setUpdatedDrinkItem] = useState({});

	const [menuActionType, setMenuActionType] = useState("Add"); //default state to be null
	const [serviceActionType, setServiceActionType] = useState("Add");
	const [drinkActionType, setDrinkActionType] = useState("Add");

	const [uploadType, setUploadType] = useState("s");
	const [uploadModalFlag, setUploadModalFlag] = useState(false);

	const dispatch = useDispatch();
	const menuService = useSelector((state) => state.menuServiceSetting);

	let serviceTypes = [];

	useSelector((state) => {
		return state.menuServiceSetting.serviceTypes.map((service) => {
			serviceTypes.push({
				id: service.service_type_id,
				value: service.service_type_name,
			});
		});
	});

	const _handleMenuModalShow = () => setShowMenuModal(true);
	const _handleServiceModalShow = () => setShowServiceModal(true);
	const _handleDrinkModalShow = () => setShowDrinkModal(true);

	const _handleMenuModalClose = () => {
		setShowMenuModal(false);
		setUpdatedMenuItem({});
		setMenuActionType();
		// need to clear these because they are dependent props passed to the modal
	};
	const _handleServiceModalClose = () => {
		setShowServiceModal(false);
		setUpdatedServiceItem({});
		setServiceActionType();
	};
	const _handleDrinkModalClose = () => {
		setShowDrinkModal(false);
		setUpdatedDrinkItem({});
		setDrinkActionType();
	};

	const _handleMenuDeleteConfirmationModalClose = () =>
		setShowMenuDeleteConfirmationModal(false);
	const _handleMenuDeleteConfirmationModalShow = () =>
		setShowMenuDeleteConfirmationModal(true);

	const _handleServiceDeleteConfirmationModalClose = () =>
		setShowServiceDeleteConfirmationModal(false);
	const _handleServiceDeleteConfirmationModalShow = () =>
		setShowServiceDeleteConfirmationModal(true);

	const _handleDrinkDeleteConfirmationModalClose = () =>
		setShowDrinkDeleteConfirmationModal(false);
	const _handleDrinkDeleteConfirmationModalShow = () =>
		setShowDrinkDeleteConfirmationModal(true);

	const _handleCloseUploadModal = () => setUploadModalFlag(false);
	const _handleOpenUploadModal = () => {
		setUploadModalFlag(true);
	};

	useEffect(() => {
		dispatch(fetchMenuItem("", ""));
		dispatch(fetchServiceTypes());
		dispatch(fetchServiceItem());
		dispatch(fetchDrinkItem());
	}, [dispatch]);

	// useEffect(() => {
	// 	socket.on("menuUpdated", (data) => {
	// 		dispatch(fetchMenuSuccess(data.result));
	// 	});

	// 	socket.on("connect_error", (err) => {
	// 		console.log(`connect_error due to ${err.message}`);
	// 	});
	// }, [dispatch]);

	/* function to show add menu modal and set action type */
	function _addMenuItem() {
		setMenuActionType("Add");
		_handleMenuModalShow();
	}

	/* function to show add menu modal with selected menu data to update and set action type*/
	function _updateMenuItem(val) {
		setUpdatedMenuItem(val);
		setMenuActionType("Update");
		_handleMenuModalShow();
	}

	function _deleteMenuItem(val) {
		setDeleteMenuItem(val);
		_handleMenuDeleteConfirmationModalShow();
	}

	/* function to show add menu modal and set action type */
	function _addServiceItem() {
		setServiceActionType("Add");
		_handleServiceModalShow();
	}

	/* function to show add service modal with selected service data to update and set action type*/
	function _updateServiceItem(val) {
		setServiceActionType("Update");
		setUpdatedServiceItem(val);
		_handleServiceModalShow();
	}

	function _deleteServiceItem(val) {
		setDeleteServiceItem(val);
		_handleServiceDeleteConfirmationModalShow();
	}

	/* function to show add drink modal and set action type */
	function _addDrinkItem() {
		setDrinkActionType("Add");
		_handleDrinkModalShow();
	}

	/* function to show add Drink modal with selected Drink data to update and set action type*/
	function _updateDrinkItem(val) {
		setUpdatedDrinkItem(val);
		setDrinkActionType("Update");
		_handleDrinkModalShow();
	}

	function _deleteDrinkItem(val) {
		setDeleteDrinkItem(val);
		_handleDrinkDeleteConfirmationModalShow();
	}

	async function deleteData(type) {
		let deletedData = {};
		if (type === "menu") {
			deletedData["company_info_id"] = deleteMenuItem.company_info_id;
			deletedData["menu_id"] = deleteMenuItem.menu_id;
			deletedData["menu_info_id"] = deleteMenuItem.menu_info_id;
			deletedData["menu_status"] = "inactive";
			dispatch(editMenuItem(deletedData));
			_handleMenuDeleteConfirmationModalClose();
		} else if (type === "service") {
			deletedData["company_info_id"] = deleteServiceItem.company_info_id;
			deletedData["service_id"] = deleteServiceItem.service_id;
			deletedData["service_info_id"] = deleteServiceItem.service_info_id;
			deletedData["service_type_id"] = deleteServiceItem.service_type_id;
			deletedData["service_status"] = "inactive";
			dispatch(editServiceItem(deletedData));
			_handleServiceDeleteConfirmationModalClose();
		} else if (type === "drink") {
			deletedData["company_info_id"] = deleteDrinkItem.company_info_id;
			deletedData["drink_id"] = deleteDrinkItem.drink_id;
			deletedData["drink_info_id"] = deleteDrinkItem.drink_info_id;
			deletedData["drink_type"] = deleteDrinkItem.drink_type;
			deletedData["drink_status"] = "inactive";
			dispatch(editDrinkItem(deletedData));
			_handleDrinkDeleteConfirmationModalClose();
		}
	}

	const _handleUpload = (type) => {
		setUploadType(type);
		setUploadModalFlag(true);
	};

	//Access Check//
	function checkAccess() {
		if (props.access === "viewer") {
			return false;
		} else return true;
	}

	return (
		<div className="menu-settings-content-wrapper">
			{/* ------------------------menu---------------------------------- */}
			<div className="inner-container full">
				{menuService.isLoadingMenu && <Loader type="menu" />}

				<SearchMenuComponent
					handleAdd={_addMenuItem}
					uploadClick={_handleUpload}
					controlsEnabled={checkAccess()}
				/>

				{menuService.menus.length > 0 ? (
					<TableMenuService
						data={menuService.menus}
						type="menu"
						updateItemOnClick={_updateMenuItem}
						deleteItemOnClick={_deleteMenuItem}
						controlsEnabled={checkAccess()}
					/>
				) : (
					<div>No Menu</div>
				)}
				<hr className="default-hr" />
			</div>
			<AddMenuModal
				isVisible={showMenuModal}
				handleClose={_handleMenuModalClose}
				handleShow={_handleMenuModalShow}
				updatedItem={updatedMenuItem}
				actionType={menuActionType}
			/>

			<ConfirmationModal
				isVisible={showMenuDeleteConfirmationModal}
				handleClose={_handleMenuDeleteConfirmationModalClose}
				handleShow={_handleMenuDeleteConfirmationModalShow}
				onClickConfirmation={() => deleteData("menu")}
				type="menu"
			/>

			{/* -----------------------service----------------------------------------- */}
			<div className="inner-container full">
				{menuService.isLoadingService && <Loader type="menu" />}

				<SearchServiceComponent
					handleAdd={_addServiceItem}
					uploadClick={_handleUpload}
					controlsEnabled={checkAccess()}
				/>

				{menuService.services.length > 0 ? (
					<TableMenuService
						data={menuService.services}
						type="service"
						updateItemOnClick={_updateServiceItem}
						deleteItemOnClick={_deleteServiceItem}
						controlsEnabled={checkAccess()}
					/>
				) : (
					<div>No Services</div>
				)}
				<hr className="default-hr" />
			</div>
			<AddServiceModal
				isVisible={showServiceModal}
				handleClose={_handleServiceModalClose}
				handleShow={_handleServiceModalShow}
				serviceTypes={serviceTypes}
				updatedItem={updatedServiceItem}
				actionType={serviceActionType}
			/>
			<ConfirmationModal
				isVisible={showServiceDeleteConfirmationModal}
				handleClose={_handleServiceDeleteConfirmationModalClose}
				handleShow={_handleServiceDeleteConfirmationModalShow}
				onClickConfirmation={() => deleteData("service")}
				type="service"
			/>

			{/* ------------------------------------------------drinks---------------------- */}
			<div className="inner-container full">
				{menuService.isLoadingDrink && <Loader type="menu" />}

				<SearchDrinkComponent
					handleAdd={_addDrinkItem}
					uploadClick={_handleUpload}
					controlsEnabled={checkAccess()}
				/>

				{menuService.drinks.length > 0 ? (
					<TableMenuService
						data={menuService.drinks}
						type="drink"
						updateItemOnClick={_updateDrinkItem}
						deleteItemOnClick={_deleteDrinkItem}
						controlsEnabled={checkAccess()}
					/>
				) : (
					<div>No Drinks</div>
				)}
			</div>
			<AddDrinkModal
				isVisible={showDrinkModal}
				handleClose={_handleDrinkModalClose}
				handleShow={_handleDrinkModalShow}
				updatedItem={updatedDrinkItem}
				actionType={drinkActionType}
			/>
			<ConfirmationModal
				isVisible={showDrinkDeleteConfirmationModal}
				handleClose={_handleDrinkDeleteConfirmationModalClose}
				handleShow={_handleDrinkDeleteConfirmationModalShow}
				onClickConfirmation={() => deleteData("drink")}
				type="drink"
			/>
			<FileUpload
				isVisible={uploadModalFlag}
				handleClose={_handleCloseUploadModal}
				handleShow={_handleOpenUploadModal}
				type={uploadType}
			/>
		</div>
	);
}

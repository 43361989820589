import {
	FETCH_INVENTORY_REQUEST,
	FETCH_INVENTORY_CAT_SUCCESS,
	FETCH_INVENTORY_SUCCESS,
	FETCH_INVENTORY_ERROR,
	SET_INVENTORY_ON_EDIT,
	CLEAR_INVENTORY_ON_EDIT,
	CHANGE_ADD_REQUEST,
	ADD_ORDER,
	GET_INVENTORY_COUNT,
} from "../action-types";

const initial_state = {
	isLoading: false,
	inventories: [],
	inventoriesForOrder: [],
	categories: [],
	dropdownCat: [],
	inventoryToEdit: {},
	error: "",
	itemCount: {},
};
export default function inventoryReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_INVENTORY_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case FETCH_INVENTORY_SUCCESS:
			let inventoriesList = action.payload;
			let orderInventories = {};

			Object.keys(inventoriesList).map((keys) => {
				inventoriesList[keys].map((i) => {
					i["error"] = "";
					i["item_id"] = i._id;
					i["add_quantity"] = 0;
					return null;
				});
				return null;
			});

			Object.keys(inventoriesList).map((keys, i) => {
				orderInventories[keys] = {
					order_list: inventoriesList[keys],
					vendor_id: "",
					vendor_error: "",
					due_date: "",
					date_error: "",
					order_status: "ordered",
					count: 0,
				};
				orderInventories[keys]["order_list"].map((i) => {
					i["error"] = "";
					i["add_quantity"] = 0;
					return null;
				});

				return null;
			});

			return {
				...state,
				isLoading: false,
				inventories: inventoriesList,
				inventoriesForOrder: orderInventories,
			};

		case FETCH_INVENTORY_ERROR:
			return {
				...state,
				isLoading: false,
				inventoryToEdit: action.payload,
			};
		case SET_INVENTORY_ON_EDIT:
			return {
				...state,
				isLoading: false,
				inventoryToEdit: action.payload,
			};

		case CHANGE_ADD_REQUEST:
			let inventories = state.inventories;
			let index = action.payload.index;
			let value = action.payload.value;
			let key = action.payload.key;
			let selectedState = inventories[key][index];
			let newState = {};
			if (value < 0 || isNaN(value) || value === "") {
				newState = {
					...selectedState,
					error: "Invalid",
					add_quantity: parseInt(value),
				};
			} else {
				newState = {
					...selectedState,
					add_quantity: parseInt(value),
					error: "",
				};
			}
			inventories[key].splice(index, 1, newState);
			return {
				...state,
				inventories: inventories,
			};

		case ADD_ORDER:
			let orderInventory = state.inventoriesForOrder;
			let orderIndex = action.payload.index;
			let orderValue = action.payload.value;
			let orderKey = action.payload.key;
			let orderField = action.payload.field;
			let orderSelState = orderInventory[orderKey];
			let newOrderState = {};

			if (orderField === "vendor_id") {
				if (orderValue) {
					newOrderState = {
						...orderSelState,
						vendor_id: orderValue,
						vendor_error: "",
					};
				} else {
					newOrderState = {
						...orderSelState,
						vendor_error: "Required",
					};
				}

				orderInventory[orderKey] = newOrderState;
			}

			if (orderField === "due_date") {
				if (orderValue) {
					newOrderState = {
						...orderSelState,
						due_date: orderValue,
						date_error: "",
					};
				} else {
					newOrderState = {
						...orderSelState,
						date_error: "Required",
					};
				}

				orderInventory[orderKey] = newOrderState;
			}

			if (orderField === "add_quantity") {
				if (orderValue < 0 || isNaN(orderValue) || orderValue === "") {
					newOrderState = {
						...orderSelState["order_list"][orderIndex],
						error: "Invalid",
						add_quantity: orderValue,
					};
				} else {
					let addQuantity =
						orderSelState["order_list"][orderIndex][
							"add_quantity"
						] !== ""
							? parseInt(
								orderSelState["order_list"][orderIndex][
								"add_quantity"
								]
							)
							: 0;

					orderSelState.count =
						orderSelState.count -
						addQuantity +
						parseInt(orderValue);

					newOrderState = {
						...orderSelState["order_list"][orderIndex],
						add_quantity: parseInt(orderValue),
						error: "",
					};
				}

				orderSelState["order_list"].splice(
					orderIndex,
					1,
					newOrderState
				);
			}

			return {
				...state,
				inventoriesForOrder: orderInventory,
			};

		case CLEAR_INVENTORY_ON_EDIT:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		case GET_INVENTORY_COUNT:
			return {
				...state,
				isLoading: false,
				itemCount: action.payload,
			};

		case FETCH_INVENTORY_CAT_SUCCESS:
			let dropdown = [];

			action.payload.category_set.map((cat) => {
				dropdown.push({ id: cat._id, value: cat.name });
				return 0;
			});
			return {
				...state,
				isLoading: false,
				categories: action.payload.category_set,
				dropdownCat: dropdown,
			};
		default:
			return state;
	}
}

import React from "react";
import "./loader.style.scss";
// import loaderImage from "../../assets/images/loader.gif";

export default function Loader({ type }) {
	return (
		// <div className={`loader-wrapper ${type}`}>
		//     <div>
		//         <img src={loaderImage} alt="ima" height="150" width="180" />
		//     </div>
		// </div>
		<div className={`loader-wrapper ${type}`}>
			<main id="container">
				<div className="dots">
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
					<div className="dot"></div>
				</div>
				<div className="dots2">
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
					<div className="dot2"></div>
				</div>
				<div className="circle"></div>
			</main>
		</div>
	);
}

import React from "react";
import "./toggle.style.scss";
import { setCalendarFilter } from "../../../redux/actions/calendar-info.action";
import { fetchVenueSuccess } from "../../../redux/actions/venue-action";
import { useDispatch, useSelector } from "react-redux";

function Toggle(props) {
	const dispatch = useDispatch();
	const { venueList } = useSelector((state) => state.venue);

	const venueToggle = (val) => {
		let venues = [],
			falseCount = 0,
			defaultData = [];

		let updateToggle = venueList.map((venue) => {
			defaultData.push({
				...venue,
				checked: true,
			});

			if (venue.venue_info_id === val.target.name) {
				venue.checked = !venue.checked;

				if (venue.checked) {
					venues.push(val.target.name);
				} else {
					falseCount++;
				}
			} else {
				if (venue.checked) {
					venues.push(venue.venue_info_id);
				} else {
					falseCount++;
				}
			}

			return venue;
		});

		dispatch(setCalendarFilter(venues));

		falseCount === venueList.length
			? dispatch(fetchVenueSuccess(defaultData))
			: dispatch(fetchVenueSuccess(updateToggle));
	};
	const style = {
		color: props.color,
	};

	return (
		<div className="toggle-button-wrapper">
			<div className="toggle">
				<label className="switch">
					<input
						type="checkbox"
						onChange={venueToggle}
						name={props.name}
						checked={props.checked}
					/>
					<span className="slider round">
						<div className="number"></div>
					</span>
				</label>
			</div>
			<div className="toggle-label text-capitalize" style={style}>
				{props.label}
			</div>
		</div>
	);
}

export default Toggle;

import React from "react";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import { useForm } from "react-hook-form";
import SelectBox from "../common/select-box/form-select-input.component";
import { useDispatch } from "react-redux";

import strings from "../../global/strings";
import {
	fetchOrderByLocationId,
	searchOrder,
} from "../../redux/actions/order.action";

export default function SearchOrderComponent() {
	const { register, handleSubmit, reset } = useForm();
	const dispatch = useDispatch();
	const _clearOrderSearch = () => {
		reset({
			order_id: "",
			order_type: "",
			vendor_name: "",
			due_days: "",
			order_status: "",
		});
		dispatch(fetchOrderByLocationId(localStorage.getItem("location_id")));
	};

	function _searchOrder(data) {
		dispatch(searchOrder(data));
	}

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Order</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-lg hidden"
					name="location_id"
					ref={register}
					defaultValue={localStorage.getItem("location_id")}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Order ID"
					placeholder="Enter Order ID"
					name="order_id"
					ref={register}
				/>
				<SelectBox
					options={strings.orderType}
					variant="search"
					label="Order Type"
					name="order_type"
					// type="Status"
				/>
				<SimpleTextInput
					variant="form-lg edit"
					label="Vendor Name"
					placeholder="Vendor Name"
					type="text"
					name="vendor_name"
					ref={register}
				/>

				<SimpleTextInput
					type="number"
					variant="form-md edit"
					placeholder="Enter Due Days"
					label="Enter Due Days"
					name="due_days"
					ref={register}
				/>
				<SelectBox
					options={strings.orderStatus}
					variant="search"
					label="Order Status"
					name="status"
					type="Status"
				/>
			</div>
			<div className="flex gap-x-15px justify-end">
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearOrderSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={handleSubmit(_searchOrder)}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}

import {
    SIGNUP_STEP_ONE_UPDATE,
    SIGNUP_STEP_TWO_UPDATE,
    SIGNUP_SUBMIT_START,
    SIGNUP_SUBMIT_SUCCESS,
    SIGNUP_SUBMIT_FAILED,
} from "../action-types";

const signupReducer = (
    state = {
        stepOne: {},
        stepTwo: {},
        loading: false,
        error: "",
        success: "",
    },
    action
) => {
    switch (action.type) {
        case SIGNUP_STEP_ONE_UPDATE:
            return { ...state, stepOne: action.payload, error: "" };
        case SIGNUP_STEP_TWO_UPDATE:
            return { ...state, stepTwo: action.payload, error: "" };
        case SIGNUP_SUBMIT_START:
            return {
                ...state,
                loading: true,
                stepTwo: action.payload,
                error: "",
            };
        case SIGNUP_SUBMIT_SUCCESS:
            return {
                stepOne: {},
                stepTwo: {},
                loading: false,
                error: "",
                success: "true",
            };
        case SIGNUP_SUBMIT_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default signupReducer;

import React from "react";
import "./switch-customer.modal.scss";
import Modal from "react-modal";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

export default function SwitchCustomerModal(props) {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			// marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="bg-default switch-customer-modal-wrapper">
				<h3>You are about to switch customer !</h3>
				<p>
					Are you sure you want to replace this customer with another
					customer ? This will remove current customer and let you
					search for the next customer OR add a New customer.
				</p>
				<div className="modal-action-button-container">
					<button
						className="button-wrapper button-container"
						onClick={props.handleClose}
					>
						No
					</button>
					<button
						className="button-wrapper button-container"
						onClick={props.yesOnClick}
					>
						Yes
					</button>
				</div>
			</div>
		</Modal>
	);
}

import React from "react";
import "./menu-svg.scss";

export default function Veg({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 20 13.98"
            id="veg">
            <g >
                <path d="M20,0c-3,7.74-1.7,16.37-9.26,12.73,0,0,.6-4.36,3.1-6.53-2.37,1.39-4.62,5.41-4.62,5.41h0A5.42,5.42,0,0,1,8.05,7.8C8.76,2.41,20,0,20,0Z" />
                <path d="M8.78,11.81A11.41,11.41,0,0,0,4.93,9.38a7.73,7.73,0,0,1,3.13,3.4c-3.92,3.66-4.79-1.84-8.06-6,0,0,5.09-.46,7.62,1.22a5.37,5.37,0,0,0,1.16,3.8Z" />
            </g>
        </svg>
    );
}

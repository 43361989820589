import React, { useEffect } from "react";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateSignupStepOne } from "../../redux/actions/signupActions";
import { Link } from "react-router-dom";

const schema = yup.object().shape({
	first_name: yup
		.string()
		.matches(/^([^0-9]*)$/, "No numbers allowed")
		.required("required"),
	last_name: yup
		.string()
		.required("required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	company_name: yup.string().required("required"),
	user_mobile: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
	company_phone: yup
		.number()
		.required("required")
		.typeError("Must be a number"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
});

const SignupStepOne = ({ setProcessStep, nav }) => {
	const dispatch = useDispatch();
	const { stepOne } = useSelector((state) => state.signup);

	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		reset(stepOne);
	}, [stepOne, reset]);

	const onSubmit = (data) => {
		dispatch(updateSignupStepOne(data));
		setProcessStep(2);
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-main-content bg-default">
				<div className="form-header">{nav()}</div>
				<div className="form-body">
					<div className="form-description-title">
						Company Information
					</div>
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Company Name"
							label="Company Name*"
							name="company_name"
							ref={register}
							error={errors?.company_name?.message}
						/>
						<FormTextInput
							variant="form-lg"
							type="number"
							placeholder="Company Number"
							label="Company Number*"
							name="company_phone"
							ref={register}
							error={errors?.company_phone?.message}
						/>
					</div>
					<div className="form-description-title">
						Contact Information
					</div>
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="First Name"
							label="First Name*"
							ref={register}
							name="first_name"
							error={errors?.first_name?.message}
						/>

						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Last Name"
							label="Last Name*"
							name="last_name"
							ref={register}
							error={errors?.last_name?.message}
						/>
					</div>

					<div className="form-input-container">
						<FormTextInput
							type="number"
							variant="form-lg"
							placeholder="Mobile Number"
							label="Mobile No*"
							name="user_mobile"
							ref={register}
							error={errors?.user_mobile?.message}
						/>
						<FormTextInput
							variant="form-lg"
							type="email"
							placeholder="Email Address"
							label="Email*"
							name="user_email"
							ref={register}
							error={errors?.user_email?.message}
						/>
					</div>
				</div>
				<div className="form-footer two">
					<Link to="/">
						<button className="button-primary">Sign-In</button>
					</Link>

					<button className="button-primary">Next</button>
				</div>
			</div>
		</form>
	);
};

export default SignupStepOne;

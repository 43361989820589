import {
	FETCH_CUSTOMER_REQUEST,
	FETCH_CUSTOMER_SUCCESS,
	FETCH_CUSTOMER_ERROR,
	FETCH_CUSTOMER_DETAILS_SUCCESS,
	FETCH_CUSTOMER_REQUEST_SUCCESS,
	FETCH_DUPLICATE_CUSTOMER_ERROR,
	FETCH_DELETE_CUSTOMER_SUCCESS,
} from "../action-types";

const initial_state = {
	isLoading: false,
	customerList: [],
	customerDetails: {},
	bookingList: [],
	duplicateCustomer: {},
	customerToAdd: {},
	deletedCustomer: "",
	error: "",
};
export default function customerSettingReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_CUSTOMER_REQUEST:
			return {
				...state,
				isLoading: true,
				deletedCustomer: "",
				error: "",
			};
		case FETCH_CUSTOMER_REQUEST_SUCCESS:
			return {
				...state,
				customerList: [],
				isLoading: false,
			};
		case FETCH_CUSTOMER_SUCCESS:
			return {
				isLoading: false,
				customerList: action.payload,
				error: "",
				duplicateCustomer: {},
				customerToAdd: {},
			};
		case FETCH_CUSTOMER_ERROR:
			return {
				...state,
				isLoading: false,
				customerList: state.customerList,
				error: action.payload,
			};

		case FETCH_CUSTOMER_DETAILS_SUCCESS:
			let customerData = action.payload.customerData;

			let new_customer_data = {
				...customerData.customer_info,
				...customerData,
				...customerData.customer_info.identification,
			};
			delete customerData["customer_info"];
			delete customerData["event_infos"];

			return {
				...state,
				isLoading: false,
				customerDetails: new_customer_data,
				bookingList: action.payload.booking_informations,
				error: "",
			};
		case FETCH_DUPLICATE_CUSTOMER_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload.message,
				duplicateCustomer: action.payload.customer,
				customerToAdd: action.payload.addedCustomer,
			};
		case FETCH_DELETE_CUSTOMER_SUCCESS:
			return {
				...state,
				isLoading: false,
				deletedCustomer: action.payload,
			};
		default:
			return state;
	}
}

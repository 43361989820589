import {
	FETCH_CUSTOMER_REQUEST,
	FETCH_CUSTOMER_SUCCESS,
	FETCH_CUSTOMER_ERROR,
	FETCH_CUSTOMER_DETAILS_SUCCESS,
	FETCH_CUSTOMER_REQUEST_SUCCESS,
	FETCH_DUPLICATE_CUSTOMER_ERROR,
	FETCH_DELETE_CUSTOMER_SUCCESS,
} from "../action-types";

import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";

import { sessionInfo, isEmptyObject } from "../../global/function";
import { addCustomerCanceled } from "./add-customer.action";
import { setSelectedCustomer } from "./event-save.action";

export const fetchCustomerRequest = () => {
	return {
		type: FETCH_CUSTOMER_REQUEST,
	};
};
/* just to stop loader */
export const fetchCustomerRequestSuccess = () => {
	return {
		type: FETCH_CUSTOMER_REQUEST_SUCCESS,
	};
};
export const fetchCustomerSuccess = (customer) => {
	return {
		type: FETCH_CUSTOMER_SUCCESS,
		payload: customer,
	};
};

export const fetchCustomerError = (error) => {
	return {
		type: FETCH_CUSTOMER_ERROR,
		payload: error,
	};
};

export const fetchDuplicateCustomerError = (error) => {
	return {
		type: FETCH_DUPLICATE_CUSTOMER_ERROR,
		payload: {
			addedCustomer: error.addedCustomer,
			message: error.message,
			customer: error.customer,
		},
	};
};

export const fetchCustomerDetailsSuccess = (customer) => {
	return {
		type: FETCH_CUSTOMER_DETAILS_SUCCESS,
		payload: customer,
	};
};

export const fetchDeleteCustomerSuccess = (response) => {
	return {
		type: FETCH_DELETE_CUSTOMER_SUCCESS,
		payload: response,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchCustomerError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchCustomerError(error.message));
	}
}

export const fetchCustomer = () => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.url}customerInfo/company/id?company_info_id=${sessionData.company_info_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCustomerSuccess(res.data.result));
				} else {
					await dispatch(fetchCustomerError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchSearchCustomer = (searchCustomer) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();
		const url = `${strings.url}customerInfo/search?customer_id=${searchCustomer.customer_id}&customer_fname=${searchCustomer.customer_fname}&customer_lname=${searchCustomer.customer_lname}&customer_email=${searchCustomer.customer_email}&customer_phone=${searchCustomer.customer_mobile}`;

		return axios
			.get(url, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchCustomerSuccess(res.data.result));
					return res.data.result;
				} else {
					await dispatch(fetchCustomerError(res.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const saveCustomer = (customerData, from) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}customerInfo/add`, customerData, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				if (res.data.success) {
					showToastMessage(res.data.message, "S");
					if (from === "list") {
						await dispatch(fetchCustomer());
					} else if (from === "calendar") {
						dispatch(fetchCustomerRequestSuccess());
						dispatch(setSelectedCustomer(res.data.result));
					}
					dispatch(addCustomerCanceled()); //reset form after success
				} else {
					showToastMessage(res.data.message, "E");
					if (res.data.message === "Error! Duplicate Customer") {
						await dispatch(
							fetchDuplicateCustomerError({
								addedCustomer: {
									...customerData,
									customer_info_id:
										res.data.duplicateCustomer
											.customer_info_id,
								},
								customer: res.data.duplicateCustomer,
								message: res.data.message,
							})
						);
					} else {
						await dispatch(
							fetchDuplicateCustomerError(res.data.message)
						);
					}
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const saveDuplicateCustomer = (from) => {
	return async (dispatch, getState) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();

		const { customerToAdd } = getState().customerSetting;
		return axios
			.post(`${strings.url}customerInfo/add/duplicate`, customerToAdd, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (res) => {
				showToastMessage(res.data.message, "S");

				if (res.data.success) {
					if (from === "calendar") {
						dispatch(fetchCustomerRequestSuccess());
						dispatch(setSelectedCustomer(res.data.result));
					} else if (from === "list") {
						await dispatch(fetchCustomer());
					}
					dispatch(addCustomerCanceled()); //reset form after success
				} else {
					await dispatch(fetchCustomerError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const getCustomerDetailsById = (companyCustomerId) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();

		return axios
			.get(
				`${strings.url}customerInfo/id?company_customer_id=${companyCustomerId}`,
				{
					headers: { authorization: `${sessionData.token}` },
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(
						fetchCustomerDetailsSuccess(res.data.result)
					);
					return res.data.result;
				} else {
					await dispatch(fetchCustomerError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const updateCustomerDetails = (customerInfo) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();

		axios
			.put(`${strings.url}customerInfo/update`, customerInfo, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchCustomerDetailsSuccess(response.data.result)
					);
				} else {
					await dispatch(fetchCustomerError(response.data.message));
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteCustomer = (companyCustomerId) => {
	return async (dispatch) => {
		dispatch(fetchCustomerRequest());
		const sessionData = await sessionInfo();

		return axios
			.delete(
				`${strings.url}customerInfo/delete?company_customer_id=${companyCustomerId}`,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					dispatch(fetchDeleteCustomerSuccess(response.data.message));
					// return true;
				} else {
					await dispatch(fetchCustomerError(response.data.message));
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

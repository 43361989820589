import React, { useEffect, useRef, useState } from "react";
import "./side-modal.style.scss";
import ReactToPrint from "react-to-print";
import PaymentReceiptPrint from "../../print-preview/invoice/payment-receipt/payment-receipt.component";
import FormTextInputComponent from "../../common/text-input/form-text-input.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { isEmptyObject, sessionInfo } from "../../../global/function";
import { sendPaymentEmail } from "../../../redux/actions/payment-action";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetails } from "../../../redux/actions/company-setting.action";

const paymentSchema = yup.object().shape({
	company_email: yup
		.string()
		.email("Enter Valid Email")
		.required("Req Please select valid email"),
	customer_email: yup
		.string()
		.test(
			"isNotGmailOrOutlookOrHotmail",
			"Customer Emails have invalid emails",
			(value) => {
				if (!value) return false; // Allow empty email
				const emails = value.split(",").map((email) => email.trim());
				const isNotGmailOrOutlookOrHotmail = emails.every(
					(email) =>
						email.endsWith("@gmail.com") ||
						email.endsWith("@outlook.com") ||
						email.endsWith("@hotmail.com")
				);
				return isNotGmailOrOutlookOrHotmail;
			}
		)
		.required("Please select valid emails"),
});
export default function SideModal({
	isVisible,
	onModalClose,
	containerStyle,
	title = "Modal Title",
	modelFor = "payment",
	sideModalData,
}) {
	const { register, handleSubmit, errors, reset } = useForm({
		mode: "onSubmit",
		defaultValues: {
			company_email: "",
			customer_email: "",
		},
		resolver: yupResolver(paymentSchema),
	});
	const { companyDetails } = useSelector((state) => state.companySetting);

	const [dataDisplaySelection, setDataDisplaySelection] = useState({
		toc: false,
		ids: false,
	});

	const sideModalPrintRef = useRef();
	const multiPrintRef = useRef();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCompanyDetails());
	}, [dispatch]);

	useEffect(() => {
		async function setEmailForm() {
			let data = await sessionInfo();
			reset({
				company_email: data.company_email,
				customer_email: sideModalData?.customerEmail,
			});
		}
		//reset to add customer email as default
		if (!isEmptyObject(sideModalData)) {
			setEmailForm();
		}
	}, [sideModalData, reset]);

	function _sendEmail(emailData) {
		//Sending email to customer using user specified ids
		let emails = emailData["customer_email"]
			.split(",")
			.map((email) => email.trim());

		const sendEmailData = {
			...emailData,
			...sideModalData,
			customer_email: emails,
		};

		dispatch(sendPaymentEmail(sendEmailData));
		reset();
	}

	function _printMulti() {
		//print 3 copies
		return (
			<div style={{ display: "none" }}>
				<div
					className="s-preview-container pay-receipt"
					ref={multiPrintRef}
				>
					<div className="s-preview-container-inner">
						<PaymentReceiptPrint
							paymentData={sideModalData}
							ids={dataDisplaySelection.ids}
						/>
						<PaymentReceiptPrint
							paymentData={sideModalData}
							ids={dataDisplaySelection.ids}
						/>
						<PaymentReceiptPrint
							paymentData={sideModalData}
							ids={dataDisplaySelection.ids}
						/>
					</div>
				</div>
			</div>
		);
	}
	function _printSingle() {
		return (
			<div style={{ display: "none" }}>
				<div
					className="s-preview-container pay-receipt"
					ref={sideModalPrintRef}
				>
					<div className="s-preview-container-inner">
						<PaymentReceiptPrint
							paymentData={sideModalData}
							ids={dataDisplaySelection.ids}
						/>

						{dataDisplaySelection.toc && (
							<PaymentReceiptPrint
								paymentData={
									companyDetails.company_terms_condition
								}
								type="toc"
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
	function _handleDataDisplaySelection(e) {
		const { name, checked } = e.target;
		setDataDisplaySelection((prev) => ({ ...prev, [name]: checked }));
	}
	const _handlePaymentReceipt = () => {
		return (
			<>
				<div className="s-modal-headder">
					<div className="s-headder-title">{title}</div>
					<div className="s-buttons-wrapper">
						<ReactToPrint
							trigger={() => (
								<div className="s-button iconic">
									<i className="fas fa-print"></i>copies
								</div>
							)}
							content={() => multiPrintRef.current}
						/>
						{_printMulti()}
						<ReactToPrint
							trigger={() => (
								<div className="s-button iconic">
									<i className="fas fa-print"></i>
								</div>
							)}
							content={() => sideModalPrintRef.current}
						/>
						{_printSingle()}
					</div>
				</div>
				<div className="s-modal-body">
					<div className="s-modal-checkbox-container">
						<div className="s-modal-checkbox">
							<input
								type="checkbox"
								checked={dataDisplaySelection.toc}
								name="toc"
								id="dataDisplaySelection_toc"
								onChange={_handleDataDisplaySelection}
							/>
							<label htmlFor="dataDisplaySelection_toc">
								Terms & Condition
							</label>
						</div>
						<div className="s-modal-checkbox">
							<input
								type="checkbox"
								name="ids"
								checked={dataDisplaySelection.ids}
								id="dataDisplaySelection_ids"
								onChange={_handleDataDisplaySelection}
							/>
							<label htmlFor="dataDisplaySelection_ids">
								Display IDs
							</label>
						</div>
					</div>
					<div className="s-preview-container pay-receipt">
						<div className="s-preview-container-inner">
							<PaymentReceiptPrint
								paymentData={sideModalData}
								ids={dataDisplaySelection.ids}
							/>
						</div>
					</div>
					<hr />
					<p>Email Payment Receipt</p>
					<div className="s-form">
						<FormTextInputComponent
							label={"From Email"}
							name={"company_email"}
							type="email"
							ref={register}
							error={errors?.company_email?.message}
						/>
						<FormTextInputComponent
							label={"Customer Email"}
							name={"customer_email"}
							placeholder={sideModalData?.customerEmail}
							type="text"
							ref={register}
							error={errors?.customer_email?.message}
						/>
					</div>
					<div className="s-buttons-wrapper">
						<div
							className="s-button action"
							onClick={handleSubmit(_sendEmail)}
						>
							Send Email
						</div>
						<div
							className="s-button"
							onClick={() => {
								reset();
								onModalClose();
								setDataDisplaySelection({
									toc: false,
									ids: false,
								});
							}}
						>
							Close
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<div className={`s-modal-wrapper ${isVisible ? "expanded" : ""}`}>
			<div
				className="s-modal_overlay"
				onClick={() => {
					reset();
					onModalClose();
					setDataDisplaySelection({
						toc: false,
						ids: false,
					});
				}}
			></div>
			<div className="s-modal-container" style={containerStyle}>
				{_handlePaymentReceipt()}
				<div></div>
			</div>
		</div>
	);
}

import React, { useEffect, useState } from "react";

// import SearchBar from "../search-bar/search-bar.component";
import Notification from "../notification/notification.component";
import { showSupportModal } from "../../../redux/actions/support-action";

import "./nav-bar.style.scss";
import caterMeLogo from "../../../assets/images/logo/cater_me_small.png";
// import userImage from "../../../assets/images/cesc.png";
import { Link, useHistory } from "react-router-dom";
import { logout as LogoutAction } from "../../../redux/actions/login-action";
import { useDispatch, useSelector } from "react-redux";
import { sessionInfo } from "../../../global/function";
import Loader from "../../loader/loader.component";

import {
	fetchActivity,
	fetchNotification,
	viewNotification,
	clearNotification,
} from "../../../redux/actions/notification.action";

// import { socket } from "../../../Socket";

function NavbarComponent(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { isLoading, notificationList, unseenNotificationList } = useSelector(
		(state) => state.notification
	);

	const [company_name, setCompanyName] = useState();
	const [company_role, setCompanyRole] = useState();

	const [company_employee_id, setCompanyEmpId] = useState();

	const [userImage, setUserImage] = useState(null);

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyName(data.company_name);
				setCompanyRole(data.company_role);
				setCompanyEmpId(data.company_employee_id);
			});

			setUserImage(localStorage.getItem("user_image"));
			dispatch(fetchNotification());
			if (company_role === "admin") {
				dispatch(fetchActivity(20, 1));
			}
		}

		fetchSessionData();
	}, [dispatch]);

	// useEffect(() => {
	// 	socket.on("notification", (data) => {}); // need venueIDs also

	// 	socket.on("connect_error", (err) => {
	// 		console.log(`app connect_error due to ${err.message}`);
	// 	});
	// }, []);

	const _navigateToNext = (page_id) => {
		history.push({
			pathname: "/settings",
			state: { page_id: page_id },
		});
	};

	const logout = async () => {
		await dispatch(LogoutAction());
	};

	const viewDetailNotification = async (list) => {
		let updatedNotification = {
			company_employee_id,
			notification: { ...list, notification_status: "seen" },
		};
		dispatch(viewNotification(updatedNotification));

		history.push({
			pathname: "/booking-event",
			search: `?event_id=${list.event_id}`,
			state: {
				event_info_id: list.event_info_id,
				booking_info_id: list.booking_info_id,
			},
		});
	};

	return (
		<div className="navbar-container">
			<ul>
				<li
					className="left-nav cater-me-logo"
					onClick={props.toggleSideNav}
				>
					<img
						className="cater-me-logo"
						id="nav"
						src={caterMeLogo}
						alt="Logo"
					/>
				</li>
				<Link to="/dashboard">
					<li className="left-nav cater-me-name ">
						<label htmlFor="cater_me" style={{ cursor: "pointer" }}>
							{company_name}
						</label>
					</li>
				</Link>

				<li className="right-nav user-settings">
					{userImage === null || userImage === "" ? (
						<i className="fas fa-user-alt"></i>
					) : (
						<img
							className="user-dp"
							src={userImage}
							alt="user-dp"
						/>
					)}
					<div className="settings-wrapper">
						<div className="settings">
							<ul>
								<li onClick={() => _navigateToNext(1)}>
									Profile Settings
								</li>
								<li onClick={() => _navigateToNext(2)}>
									Company Settings
								</li>
								<li onClick={() => _navigateToNext(3)}>
									Menu and Service Settings
								</li>
								{/* <li onClick={() => _navigateToNext(4)}>
								Preferences
							</li> */}
								<li onClick={() => _navigateToNext(5)}>
									Employees
								</li>
								<li onClick={() => _navigateToNext(6)}>
									Clients
								</li>
								<li
									onClick={() =>
										history.push({
											pathname: "/switch-company",
										})
									}
								>
									Switch Company
								</li>
								<li onClick={logout}>Log Out</li>
							</ul>
						</div>
					</div>
				</li>
				<li
					className="right-nav notification-container"
					onClick={() => dispatch(showSupportModal())}
				>
					<i className="fas fa-headset"></i>
				</li>

				<li className="right-nav notification-container">
					<div className="notifyIcon">
						<i className="far fa-bell notification-icon">
							<span className="notifyCount">
								<label className="unseen-count">
									{unseenNotificationList.length}{" "}
								</label>
								<span className="visually-hidden">
									New alerts
								</span>
							</span>
						</i>
					</div>
					<div className="notification-list-wrapper">
						<div className="notification-content bg-default">
							<div className="notification-header">
								<label
									className="notification-title-label"
									htmlFor="notification-title-label"
								>
									Notifications
									{/* clear all the notifications */}
									<span
										className="refresh-container"
										onClick={() => {
											dispatch(
												clearNotification({
													company_employee_id,
												})
											);
										}}
									>
										<i className="fa fa-eraser"></i>
									</span>
									{/* fetching new notification */}
									<span
										className="refresh-container"
										onClick={() => {
											dispatch(fetchNotification());
										}}
									>
										<i className="fa fa-redo"></i>
									</span>
								</label>

								{company_role === "admin" && (
									<div
										style={{
											display: "flex",
										}}
									>
										<Link to="/notification">
											<label
												className="notification-read-label"
												htmlFor="notification-read-label"
											>
												View Activites
											</label>
										</Link>
									</div>
								)}
							</div>

							<div className="show-notification">
								{isLoading && <Loader />}
								{notificationList.map((list, key) => (
									<Notification
										list={list}
										key={key}
										view={() =>
											viewDetailNotification(list)
										}
									/>
								))}
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
	);
}

export default NavbarComponent;

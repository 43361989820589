import React, { useState, useEffect } from "react";
import "./table-customer-list.style.scss";
import { useHistory } from "react-router-dom";
import PaginationReact from "../paginationReact/paginationReact.component";

export default function TableCustomerList(props) {
	const history = useHistory();
	const [buttonTitle, setButtonTitle] = useState("View Profile");

	const [currentCustomerPage, setCurrentCustomerPage] = useState(1);
	const [listPerCustomerPage] = useState(8);

	const indexOfLastCustomerList = currentCustomerPage * listPerCustomerPage;
	const indexOfFirstCustomerList =
		indexOfLastCustomerList - listPerCustomerPage;
	const currentCustomerList = props.data.slice(
		indexOfFirstCustomerList,
		indexOfLastCustomerList
	);

	useEffect(() => {
		if (props.onCustomerSelect) {
			setButtonTitle("Select");
		}
		setCurrentCustomerPage(1);
	}, [props]);

	function viewCustomer(customer) {
		if (props.onCustomerSelect) {
			props.onCustomerSelect(customer);
		} else {
			history.push({
				pathname: "/customer-profile",
				search: `?customer_id=${customer.customer_info.customer_id}`,
				state: {
					company_customer_id: customer.company_customer_id,
					from_page: "settings",
				},
			});
		}
	}

	const mobileCustomerView = (info) => {
		return (
			<div
				className={`h-255 w-135 bodered flex flex-col rounded-sm relative `}
				key={info.customer_info.customer_id}
			>
				<span className="h-119 w-135 bg-default rounded-sm flex justify-center items-center overflow-hidden">
					{info.customer_image ? (
						<img src={info.customer_image} alt="" />
					) : (
						<i className="fas fa-user-alt fa-5x"></i>
					)}
				</span>
				<span className="h-23 text-sm mt-10px text-center capitalize mx-7px">
					{info.customer_fname} {info.customer_lname}
				</span>
				<span className="text-xs border-t border-fontLight mx-7px text-center text-fontSemiDark pt-7px mt-7px">
					{info.customer_mobile}
				</span>
				<span className="text-xs mt-5px text-center px-7px text-fontSemiDark overflow">
					{info.customer_email}
				</span>

				<button
					className={`absolute button-primary-icon bottom-15px left-0px right-0px m-auto `}
					onClick={() => viewCustomer(info)}
				>
					<i className="fas fa-check text-buttonSuccess"></i>
				</button>
			</div>
		);
	};

	const onPageChange = (activePage) => {
		let currentPage = activePage.selected + 1;
		setCurrentCustomerPage(currentPage);
	};

	return (
		<div className="table-wrapper">
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						<td className="table-header-cell customer-image"></td>
						<td className="table-header-cell customer-id">
							<div className="table-header-container">ID</div>
						</td>
						<td className="table-header-cell customer-name">
							<div className="table-header-container">Name</div>
						</td>
						<td className="table-header-cell customer-phone">
							<div className="table-header-container">Phone</div>
						</td>
						<td className="table-header-cell customer-email">
							<div className="table-header-container">Email</div>
						</td>
						<td className="table-header-cell customer-ratings">
							<div className="table-header-container">
								Ratings
							</div>
						</td>
						<td className="table-header-cell customer-action"></td>
					</tr>
				</thead>
				<tbody>
					{currentCustomerList.map((row) => (
						<tr
							className="data-table-row bg-default"
							key={row.customer_info.customer_id}
							onClick={() => viewCustomer(row)}
						>
							<td className="image-cell">
								{row.customer_image ? (
									<img
										src={row.customer_image}
										alt=""
										height="50"
										width="50"
									/>
								) : (
									<i className="fas fa-user-alt fa-2x"></i>
								)}
							</td>
							<td className="id-cell">
								{row.customer_info.customer_id}
							</td>
							<td>
								{row.customer_fname} {row.customer_lname}
							</td>
							<td>{row.customer_mobile}</td>
							<td>{row.customer_email}</td>
							<td>{row.customer_ratings || 0}</td>
							<td
								className="view-profile-cell"
								onClick={() => viewCustomer(row)}
							>
								<span className="view-profile-text">
									{buttonTitle}
								</span>
								<span className="view-profile-icon">
									<i className="fas fa-ellipsis-v"></i>
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{currentCustomerList.map((list) => mobileCustomerView(list))}
			</div>

			<PaginationReact
				totalCount={props.data.length}
				itemsPerPage={listPerCustomerPage}
				onPageClick={onPageChange}
				forcePage={currentCustomerPage - 1}
			/>
		</div>
	);
}

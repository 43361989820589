/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { addVendor, updateVendor } from "../../redux/actions/vendor.action";
import { isEmptyObject } from "../../global/function";

const vendorSchema = yup.object().shape({
	vendor_name: yup.string().required("required"),
	primary_contact: yup
		.number()
		.typeError("must be number")
		.required("mobile number is required")
		.positive("Must be greater than zero"),

	location: yup.object().shape({
		province: yup.string().required("required"),
		city: yup.string().required("required"),
		address: yup.string().required("required"),
	}),

	contact_person: yup.object().shape({
		name: yup.string().required("Contact Person Name Required"),
		mobile_number: yup
			.number()
			.typeError("must be number")
			.required("mobile number is required")
			.positive("Must be greater than zero"),
	}),
});
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function AddVendorModal(props) {
	const dispatch = useDispatch();
	const { vendorToEdit } = useSelector((state) => state.vendor);
	const [type, setType] = useState("edit");

	const { register, errors, handleSubmit, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(vendorSchema),
	});

	useEffect(() => {
		if (isEmptyObject(vendorToEdit)) {
			setType("add");
			reset({});
		} else {
			setType("edit");
			reset({
				vendor_name: vendorToEdit.vendor_name,
				vendor_email: vendorToEdit.vendor_email,
				primary_contact: vendorToEdit.contact_number[0],
				secondary_contact: vendorToEdit.contact_number[1],
				location: {
					province: vendorToEdit.location.province,
					city: vendorToEdit.location.city,
					address: vendorToEdit.location.address,
				},
				contact_person: {
					name: vendorToEdit.contact_person[0].name,
					mobile_number: vendorToEdit.contact_person[0].mobile_number,
				},
			});
		}
	}, [vendorToEdit]);

	async function _addItem(vendor) {
		let contact = [];
		contact.push(vendor.primary_contact);
		if (vendor.secondary_contact) {
			contact.push(vendor.secondary_contact);
		}
		vendor.contact_number = contact;
		delete vendor.primary_contact;
		delete vendor.secondary_contact;

		if (type === "edit") {
			dispatch(updateVendor({ ...vendor, _id: vendorToEdit._id }));
		} else if (type === "add") {
			dispatch(addVendor(vendor));
		}
		props.handleClose();
	}

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title capitalize">
						{type} Vendor
					</div>
				</div>
				<div className="form-body">
					<div className="form-input-container ">
						<FormTextInput
							type="text"
							placeholder="Vendor Name"
							label="Vendor Name*"
							variant="form-lg"
							name="vendor_name"
							ref={register}
							error={errors?.vendor_name?.message}
						/>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormTextInput
								type="number"
								placeholder="Enter Mobile"
								label="Primary Number*"
								variant="form-md"
								name="primary_contact"
								ref={register}
								error={errors?.primary_contact?.message}
							/>

							<FormTextInput
								type="number"
								placeholder="Enter Secondary Contact"
								label="Secondary Number*"
								variant="form-md"
								name="secondary_contact"
								ref={register}
							/>
						</div>

						<div className="form-input-container inner">
							<FormTextInput
								label="Vendor Email"
								placeholder="Enter Vendor Email Address"
								variant="form-lg"
								type="email"
								name="vendor_email"
								ref={register}
							/>
						</div>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormSelectBox
								label="Province"
								variant="form-sm"
								options={strings.province}
								name="location.province"
								ref={register}
								type="Province"
								error={errors?.location?.province?.message}
							/>

							<FormSelectBox
								label="City"
								variant="form-sm"
								options={strings.city}
								name="location.city"
								ref={register}
								type="City"
								error={errors?.location?.city?.message}
							/>
						</div>

						<div className="form-input-container inner">
							<FormTextInput
								label="Address"
								placeholder="Enter Address"
								variant="form-lg"
								type="text"
								name="location.address"
								ref={register}
								error={errors?.location?.address?.message}
							/>
						</div>
					</div>
					<label htmlFor="" className="form-description-title">
						Contact Person
					</label>

					<div className="form-input-container ">
						<FormTextInput
							label="Contact Person Name* "
							placeholder="Enter Contact Person Name"
							variant="form-lg"
							type="text"
							name="contact_person.name"
							ref={register}
							error={errors?.contact_person?.name?.message}
						/>

						<FormTextInput
							label="Contact Person Mobile* "
							placeholder="Enter Contact Person Mobile"
							variant="form-lg"
							type="number"
							name="contact_person.mobile_number"
							ref={register}
							error={
								errors?.contact_person?.mobile_number?.message
							}
						/>
					</div>
				</div>

				<div className="form-footer two">
					<button
						onClick={() => {
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						onClick={handleSubmit(_addItem)}
						className="button-primary capitalize"
					>
						{type}
					</button>
				</div>
			</div>
		</Modal>
	);
}

import React, { useState, useEffect } from "react";

function ProfileSummary({ details, switchCustomer }) {
	const [id, setId] = useState("");
	const [image, setImage] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mobile, setMobile] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [city, setCity] = useState();

	useEffect(() => {
		setId(details?.customer_id || details?.customer_info?.customer_id);
		setImage(details?.customer_image);
		setFirstName(details.customer_fname);
		setLastName(details.customer_lname);
		setEmail(details.customer_email);
		setMobile(details.customer_mobile);
		setPhone(details.customer_phone);
		setAddress(details.customer_address);
		setCity(details.customer_city);
	}, [details]);

	return (
		<div className="main-inner-container">
			<div className="bg-default w-285 h-255 rounded-sm p-10px">
				<div className="flex flex-row gap-x-15px">
					<div className="w-135 h-135 rounded-sm overflow-hidden">
						{image ? (
							<img src={image} alt="profile pic" />
						) : (
							<i className="fas fa-user-alt fa-8x"></i>
						)}
					</div>
					<div className="w-135 relative capitalize">
						<label className="absolute top-0px left-0px text-fontSemiDark text-sm">
							ID: {id}
						</label>
						<label className="absolute top-30px left-0px text-lg text-fontDark w-135 capitalize">
							{firstName}
						</label>
						<label className="absolute top-56px left-0px text-md text-fontSemiDark overflow capitalize">
							{lastName}
						</label>
						{switchCustomer && (
							<button
								className="button-primary-icon absolute bottom-0px right-0px "
								onClick={switchCustomer}
							>
								<i className="fas fa-redo"></i>
							</button>
						)}
					</div>
				</div>

				<div className="flex flex-col gap-x-15px border-t border-fontLight pt-15px mt-10px text-fontSemiDark gap-y-10px">
					<div className="w-285 rounded-sm flex gap-x-15px">
						<span className="w-135 gap-x-10px flex">
							<i className="fa fa-mobile"></i>
							<label htmlFor="" className="text-md">
								{mobile}
							</label>
						</span>
						<span className="w-135 gap-x-10px flex">
							<i className="fa fa-phone-alt"></i>
							<label htmlFor="" className="text-md">
								{phone || "-"}
							</label>
						</span>
					</div>
					<span className="w-285 gap-x-10px flex">
						<i className="fas fa-envelope"></i>
						<label htmlFor="" className="text-md overflow">
							{email || "-"}
						</label>
					</span>
					<span className="w-285 gap-x-10px flex">
						<i className="fas fa-map-marker-alt"></i>
						<label htmlFor="" className="text-md">
							{address}, {city}
						</label>
					</span>
				</div>
			</div>
		</div>
	);
}

export default ProfileSummary;

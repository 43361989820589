import React from "react";
import DJ from "../services/dj";
import Transportation from "../services/transportation";
import FoodStall from "../services/foodStall";
import Other from "../services/other";
import Drinks from "../drinks/drinks";

export function bookingTypeMapper(name) {
	switch (name) {
		case "wedding":
			return (
				<i
					className="fas fa-heart"
					style={{ color: "var(--highlight-red)" }}
				></i>
			);
		case "birthday":
			return (
				<i
					className="fas fa-birthday-cake"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "bratabandha":
			return (
				<i
					className="fas fa-male"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "gufa":
			return (
				<i
					className="fas fa-female"
					style={{ color: "var(--meat)" }}
				></i>
			);
		case "sharaddha":
			return (
				<i
					className="fas fa-heart-broken"
					style={{ color: "var(--secondary-color)" }}
				></i>
			);
		case "seminar":
			return (
				<i
					className="fas fa-microphone"
					style={{ color: "var(--secondary-color)" }}
				></i>
			);
		case "social event":
			return (
				<i
					className="fas fa-people-arrows"
					style={{ color: "var(--secondary-color)" }}
				></i>
			);
		default:
			return "";
	}
}

export function serviceTypeMapper(name, style) {
	switch (name) {
		case "Entertainment":
			return <i className="fas fa-guitar" style={style}></i>;
		case "Transportation":
			return <i className="fas fa-shuttle-van" style={style}></i>;
		case "Live Kitchen":
			return <i className="fas fa-ellipsis-h" style={style}></i>;
		case "Food Stall":
			return <i className="fas fa-campground" style={style}></i>;
		default:
			return <i className="fas fa-ellipsis-h" style={style}></i>;
	}
}

export function serviceTypeMapperSvg(name, style) {
	switch (name) {
		case "Entertainment":
			return <DJ style={style} />;
		case "Transportation":
			return <Transportation style={style} />;
		case "Food Stall":
			return <FoodStall style={style} />;

		default:
			return <Other style={style} />;
	}
}

export function drinkTypeMapperSvg(name, style) {
	return <Drinks style={style} />;
}

export function drinkTypeMapper(name) {
	switch (name) {
		case "beer":
			return (
				<i
					className="fas fa-beer"
					style={{ color: "var(--highlight-red)" }}
				></i>
			);
		case "coke":
			return (
				<i
					className="fas fa-wine-bottle"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "fanta":
			return (
				<i
					className="fas fa-wine-bottle"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "soda":
			return (
				<i
					className="fas fa-wine-bottle"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "whiskey":
			return (
				<i
					className="fas fa-glass-whiskey"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "wine":
			return (
				<i
					className="fas fa-wine-glass-alt"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		case "vodka":
			return (
				<i
					className="fas fa-glass-martini-alt"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
		default:
			return (
				<i
					className="fas fa-wine-bottle"
					style={{ color: "var(--afternoon)" }}
				></i>
			);
	}
}

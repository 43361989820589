import {
	FETCH_REQUEST_REQUEST,
	FETCH_REQUEST_SUCCESS,
	FETCH_SINGLE_REQUEST_SUCCESS,
	FETCH_REQUEST_ERROR,
	CHANGE_REQUEST,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";

import history from "../history";

// for logout
import { fetchLoginError } from "./login-action";
import { sessionInfo, isEmptyObject } from "../../global/function";

export const fetchRequestRequest = () => {
	return {
		type: FETCH_REQUEST_REQUEST,
	};
};

export const fetchRequestSuccess = (request) => {
	return {
		type: FETCH_REQUEST_SUCCESS,
		payload: request,
	};
};

export const fetchSingleRequestSuccess = (request) => {
	return {
		type: FETCH_SINGLE_REQUEST_SUCCESS,
		payload: request,
	};
};

export const fetchRequestError = (error) => {
	return {
		type: FETCH_REQUEST_ERROR,
		payload: error,
	};
};

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		history.push({ pathname: "/" });
		await dispatch(fetchLoginError(error.response.data.message));
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch(fetchRequestError(error.response.data.message));
	} else {
		showToastMessage(error.message, "E");
		await dispatch(fetchRequestError(error.message));
	}
}

export const fetchRequestByLocationId = (location_id) => {
	return async (dispatch) => {
		dispatch(fetchRequestRequest());
		const sessionData = await sessionInfo();

		axios
			.get(`${strings.inventoryURL}request?location_id=${location_id}`, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchRequestSuccess(res.data.result));
				} else {
					await dispatch(fetchRequestError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const searchRequest = (request) => {
	return async (dispatch) => {
		dispatch(fetchRequestRequest());
		const sessionData = await sessionInfo();
		const url = `${strings.inventoryURL}request/search?request_id=${request.request_id}&request_type=${request.request_type}&user=${request.user}&due_days=${request.due_days}&request_status=${request.request_status}&location_id=${request.location_id}`;

		axios
			.get(url, {
				headers: {
					authorization: `${sessionData.token}`,
				},
			})
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchRequestSuccess(res.data.result));
				} else {
					await dispatch(fetchRequestError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const addRequest = (request) => {
	return async (dispatch) => {
		dispatch(fetchRequestRequest());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.inventoryURL}request`, request, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchRequestByLocationId(request.location_id)
					);
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchRequestError(response.data.message));
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const fetchSingleRequest = (location_id, request_id) => {
	return async (dispatch) => {
		dispatch(fetchRequestRequest());
		const sessionData = await sessionInfo();

		axios
			.get(
				`${strings.inventoryURL}request/id?location_id=${location_id}&requestId=${request_id}`,
				{
					headers: {
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (res) => {
				if (res.data.success) {
					await dispatch(fetchSingleRequestSuccess(res.data.result));
				} else {
					await dispatch(fetchRequestError(res.data.message));
					showToastMessage(res.data.message, "E");
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const changeRequest = (value, index, key, field) => {
	return {
		type: CHANGE_REQUEST,
		payload: { value, index, key, field },
	};
};

export const updateRequest = (request) => {
	return async (dispatch) => {
		dispatch(fetchRequestRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(`${strings.inventoryURL}request/update`, request, {
				headers: { authorization: `${sessionData.token}` },
			})
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchRequestByLocationId(request.location_id)
					);
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchRequestError(response.data.message));
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

export const deleteRequest = (request, id) => {
	return async (dispatch) => {
		dispatch(fetchRequestRequest());
		const sessionData = await sessionInfo();

		return axios
			.put(
				`${strings.inventoryURL}request/delete?deleteRequest=true&_id=${id}`,
				request,
				{
					headers: {
						"content-type": "application/json",
						authorization: `${sessionData.token}`,
					},
				}
			)
			.then(async (response) => {
				if (response.data.success) {
					showToastMessage(response.data.message, "S");
					await dispatch(
						fetchRequestByLocationId(request.location_id)
					);
					return true;
				} else {
					showToastMessage(response.data.message, "E");
					await dispatch(fetchRequestError(response.data.message));
					return false;
				}
			})
			.catch(async (error) => {
				await _handleError(error, dispatch);
			});
	};
};

import {
	SHOW_SUPPORT_MODAL,
	HIDE_SUPPORT_MODAL,
	FETCH_SUPPORT_REQUEST,
	FETCH_SUPPORT_SUCCESS,
	SET_ERROR_TRUE,
} from "../action-types";
import axios from "axios";
import strings from "../../global/strings";
import showToastMessage from "../../global/showToastMessage";
import { sessionInfo, isEmptyObject } from "../../global/function";
import { fetchLoginError } from "./login-action";
import history from "../history";

/* action for error handling */
export const setErrorTrue = (message) => {
	return {
		type: SET_ERROR_TRUE,
		payload: message,
	};
};

/* action for support */
export const showSupportModal = () => {
	return {
		type: SHOW_SUPPORT_MODAL,
	};
};

export const hideSupportModal = () => {
	return {
		type: HIDE_SUPPORT_MODAL,
	};
};

export const fetchSupportRequest = () => {
	return {
		type: FETCH_SUPPORT_REQUEST,
	};
};

export const fetchSupportSuccess = () => {
	return {
		type: FETCH_SUPPORT_SUCCESS,
	};
};

export const submitIssue = (supportData) => {
	return async (dispatch) => {
		dispatch(hideSupportModal());
		const sessionData = await sessionInfo();

		return axios
			.post(`${strings.url}support/add`, supportData, {
				headers: {
					"content-type": "application/json",
					authorization: `${sessionData.token}`,
				},
			})
			.then((res) => {
				dispatch(fetchSupportSuccess());
				if (res.data.success) {
					showToastMessage(res.data.message, "S");
				} else {
					showToastMessage(res.data.message, "E");
				}
			})
			.catch((error) => {
				var status = 504;

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					dispatch(fetchLoginError(error.response.data.message));
					showToastMessage(error.response.data.message, "E");
				} else {
					showToastMessage(error.message, "E");
				}
			});
	};
};

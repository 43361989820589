import React, { useState, useEffect } from "react";
import CustomerTable from "../../tables/table-customer-list.component";
import DuplicateCustomerModal from "../../modals/duplicate-customer.modal";
import AddCustomerModal from "../../modals/add-customer-modal/add-customer.modal";
import Loader from "../../loader/loader.component";

import { fetchCustomer } from "../../../redux/actions/customer-setting.action";
import { useDispatch, useSelector } from "react-redux";
import SearchCustomerComponent from "../../search/search-customer.component";

function CustomerSettings(props) {
	const dispatch = useDispatch();
	const { error, customerList, isLoading } = useSelector(
		(state) => state.customerSetting
	);

	const [show, setShow] = useState(false);
	const [showDuplicateCustomer, setShowDuplicateCustomer] = useState(false);

	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => setShow(true);

	const handleDuplicateModalClose = () => {
		setShowDuplicateCustomer(false);
	};
	const handleDuplicateModalShow = () => setShowDuplicateCustomer(true);

	useEffect(() => {
		dispatch(fetchCustomer());
	}, [dispatch]);

	useEffect(() => {
		if (error === "Error! Duplicate Customer") {
			setShowDuplicateCustomer(true);
		}
	}, [error]);

	return (
		<div className="inner-container full">
			{isLoading && <Loader />}

			<SearchCustomerComponent type="settings" handleAdd={handleShow} />

			{customerList.length > 0 ? (
				<CustomerTable data={customerList} />
			) : (
				<div> No Customer Added</div>
			)}

			<AddCustomerModal
				isVisible={show}
				handleClose={handleClose}
				handleShow={handleShow}
				actionType="Add"
				from="list"
			/>

			<DuplicateCustomerModal
				isVisible={showDuplicateCustomer}
				handleClose={handleDuplicateModalClose}
				handleShow={handleDuplicateModalShow}
				onClickNo={handleShow}
				from="list"
				type="customer"
			/>
		</div>
	);
}

export default CustomerSettings;

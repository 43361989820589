import React, { useState, useEffect } from "react";
import "./calendar-event-detail.style.scss";
import { nepMonthAbbreviation } from "../../../global/function";
import moment from "moment";
import { setBookings } from "../../../redux/actions/event-save.action";
import { useDispatch } from "react-redux";

function CalendarEventDetail(props) {
	const dispatch = useDispatch();
	const [time, setTime] = useState("");
	const [hallsArray, setHallsArray] = useState([]);
	const [eventTitle, setEventTitle] = useState("");
	const [eventSubTitle, setSubEventTitle] = useState("");
	const [colorCode, setColorCode] = useState();
	const [bookingStatus, setBookingStatus] = useState("inquiry");

	useEffect(() => {
		if (props.type === "upcoming") {
			setTime(props.data.booked_time);
			setEventTitle(props.data.customer_name);
			setSubEventTitle(props.data.booked_time);
			setHallsArray(props.data.hall_infos);
			setColorCode(props.colorCode);
			setBookingStatus(props.data.booking_status);
		} else if (props.type === "booked") {
			setTime(props.data.booked_time);
			setEventTitle(
				moment(props.data.booked_date_english).format("DD MMM YYYY")
			);
			setSubEventTitle(
				nepMonthAbbreviation(props.data.booked_date_nepali)
			);
			setColorCode(props.data.venue_color_code);
			setHallsArray(props.data.booking_halls);
			setBookingStatus(props.data.booking_status);
			//}
		} else if (props.type === "available") {
			setTime(props.time);
			setHallsArray(props.halls);
			setColorCode(props.colorCode);
			setEventTitle(props.venueName);
		}
	}, [props]);

	const venueStyle = {
		backgroundColor: colorCode,
	};
	const hoverStyle = {
		"--bgColor": colorCode,
	};
	const statusStyle = {
		"--bg": `var(--${bookingStatus})`,
	};

	async function _returnTime(event) {
		let valueObject = {};

		if (props.type === "upcoming") {
			valueObject["event_info_id"] = props.data.event_info_id;
			valueObject["booking_info_id"] = props.data.booking_info_id;
			valueObject["event_id"] = props.data.event_id;
			props.onClick(valueObject);
		} else if (props.type === "booked") {
			valueObject["data"] = props.data;
			props.onClick(valueObject);
		} else {
			valueObject["day"] = props.day;
			valueObject["time"] = props.time;
			valueObject["venue"] = props.venue;
			valueObject["venue_info_id"] = props.venueID;
			valueObject["halls"] = props.halls;
			valueObject["tempBookingId"] =
				Math.floor(Math.random() * 90000) + 10000;
			valueObject["colorCode"] = props.colorCode;

			valueObject["booked_date_nepali"] = props.date_np;
			valueObject["booked_date_english"] = props.date_en;
			await dispatch(setBookings(valueObject));
			await props.scroll();
		}
	}

	//if booking is active page component shows selection icon
	const _renderStatusOrSelect = (selection) => {
		return !selection ? (
			<div className="event-status-container">
				<div className="status-content bg-inset">
					<div className="event-status" style={statusStyle} />
				</div>
			</div>
		) : (
			<div className="selected-booking">
				<i className="fas fa-eye"></i>
			</div>
		);
	};

	return (
		<div
			className={
				props.disabled
					? "disableDiv event-detail-wrapper"
					: " event-detail-wrapper"
			}
		>
			<div className={`left-border-container ${time}`} />

			<div
				className="event-details-container bg-default"
				onClick={(e) => _returnTime(e)}
				style={hoverStyle}
				before={colorCode}
			>
				<div className="venue-details-container">
					<div className="hall-details-container" style={venueStyle}>
						{props.type === "booked" ? (
							hallsArray?.length > 2 ? (
								<div className="halls-name">A</div>
							) : (
								hallsArray?.map((halls) => {
									return halls.hall_info !== undefined ? (
										<div
											className="halls-name"
											key={halls.hall_info_id}
										>
											{
												halls.hall_info.hall_name.split(
													"H"
												)[1]
											}
										</div>
									) : (
										<div
											className="halls-name"
											key={halls.hall_info_id}
										>
											{halls.hall_name.split("H")[1]}
										</div>
									);
								})
							)
						) : hallsArray.length > 2 ? (
							<div className="halls-name">A</div>
						) : (
							hallsArray.map((halls) => {
								return (
									<div
										className="halls-name"
										key={halls.hall_info_id}
									>
										{halls.hall_name.split("H")[1]}
									</div>
								);
							})
						)}
					</div>
					{props.type === "available" ? (
						<div
							className="event-details-content"
							id="event-details-content"
						>
							<label
								htmlFor="event-details-content"
								className="event-title"
							>
								{eventTitle}
							</label>
						</div>
					) : (
						<div
							className="event-details-content"
							id="event-details-content"
						>
							<label className="event-title capitalize">
								{eventTitle}
							</label>
							<label className="event-title text-capitalize venue">
								{eventSubTitle}
							</label>
							<label className="event-title text-capitalize status">
								{bookingStatus}
							</label>
						</div>
					)}
				</div>

				{props.type !== "available" &&
					_renderStatusOrSelect(props.selection)}
			</div>
		</div>
	);
}

export default CalendarEventDetail;

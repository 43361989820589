import React from "react";
import { rupeeConverter } from "../../../global/function";
import "./data-container.style.scss";

export default function DataDisplayBlock({
	data,
	type = "currency",
	title = false,
	subtitle = false,
	size = "sm",
	clickable = false,
	handleOnclick,
	addStyle = {},
}) {
	function dataFormat() {
		let value = data;
		if (type === "currency") {
			value = rupeeConverter(`${data}`);
		}
		return <div style={{ textAlign: "right" }}>{value}</div>;
	}
	return (
		<div className={`bg-default data-display-wrapper ${size}`}>
			<div className={`data-display-container ${size}`}>
				<div style={addStyle}>{title}</div>
				{dataFormat()}
			</div>
		</div>
	);
}

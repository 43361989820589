import React from "react";
import { useDispatch } from "react-redux";
import { setInventoryOnEdit } from "../../redux/actions/inventory.action";
import "./boooking-history-table.style.scss";
import "./table.scss";

export default function InventoryTable({ list, onEditClick }) {
	const dispatch = useDispatch();
	const mobileInventory = (info) => {
		return (
			<div
				className={`h-119 w-135  relative bg-default rounded-sm capitalize`}
				key={info._id}
			>
				<span className="absolute h-20 w-20 top-10px left-10px text-fontSemiDark">
					{info.unit}
				</span>
				<span className=" absolute h-23 w-65 top-10px right-10px text-sm text-right text-fontSemiDark">
					{info.id}
				</span>

				<span className=" absolute h-10 w-119 top-52px right-10px left-10px text-xs text-right overflow text-fontSemiDark">
					{info.item_name}
				</span>
				<span className=" absolute h-10 w-65 bottom-30px right-10px text-xs text-right text-fontSemiDark">
					Pending
				</span>
				<span
					className={`absolute h-12 bottom-10px right-10px left-10px text-xs text-right`}
				>
					{info.requested_quantity} / {info.ordered_quantity}
				</span>
			</div>
		);
	};

	const onEdit = async (val) => {
		await dispatch(setInventoryOnEdit(val));
		onEditClick();
	};

	return (
		<div>
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						<td className="table-header ">
							<div className="table-header-container">ID</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Item Name
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Item Type
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">Brand</div>
						</td>

						<td className="table-header ">
							<div className="table-header-container">Unit</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Inventory
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Ag Rate
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Pending
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">
								Minimum
							</div>
						</td>
						<td className="table-header ">
							<div className="table-header-container">Status</div>
						</td>
						<td className="table-header "></td>
					</tr>
				</thead>

				<tbody>
					{list.length > 0 &&
						list.map((list, key) => (
							<tr
								className="data-table-row inventory text-capitalize text-fontSemiDark"
								key={key}
							>
								<td>{key + 1}</td>
								<td>{list.item_name}</td>
								<td>{list?.item_description?.sub_type}</td>
								<td>{list?.item_description?.brand}</td>
								<td>{list.unit}</td>
								<td>{list.total_quantity}</td>
								<td>{list.average_rate}</td>
								<td>
									{list.requested_quantity}/
									{list.ordered_quantity}
								</td>
								<td>{list.min_quantity}</td>
								<td>
									{list.total_quantity > list.min_quantity
										? "In-Stotck"
										: list.total_quantity <
										  list.min_quantity
										? "Min-Stock"
										: "No-Stock"}
								</td>
								<td>
									<div className="flex gap-10px justify-end pr-7px">
										<button
											className="button-primary-icon"
											onClick={() => onEdit(list)}
										>
											<i className="fas fa-edit edit"></i>
										</button>
										{/* <button className="button-primary-icon">
											<i className="fas fa-search"></i>
										</button> */}
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{list.map((list) => mobileInventory(list))}
			</div>
		</div>
	);
}

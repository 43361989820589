import React, { useState, useEffect } from "react";
import "./file-upload.style.scss";

import axios from "axios";
import strings from "../../../global/strings";
import showToastMessage from "../../../global/showToastMessage";
import { useDispatch } from "react-redux";
import {
	fetchMenuItem,
	fetchServiceItem,
	fetchDrinkItem,
} from "../../../redux/actions/menu-service-settings.action";
import { fetchLoginError } from "../../../redux/actions/login-action";
import { useHistory } from "react-router-dom";
import { isEmptyObject, sessionInfo } from "../../../global/function";
import Modal from "react-modal";

const FileUpload = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [uploadFlag, setUploadFlag] = useState(false);
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
			background:
				"linear-gradient(128deg, var(--backgroung-color), var(--backgroung-color))",
		},
		h2: {
			color: "red",
		},
	};

	const [file, setFile] = useState(null);

	const saveFile = async (e) => {
		e.persist();
		setFile(e.target.files[0]);
	};

	const [sessionData, setSessionData] = useState();

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setSessionData({
					token: data.token,
				});
			});
		}

		fetchSessionData();
	}, []);

	const upload = async (e) => {
		setUploadFlag(true);
		const formData = new FormData();
		formData.append("excelFile", file);

		// const options = {
		// 	onUploadProgress: (progressEvent) => {
		// 		const { loaded, total } = progressEvent;
		// 		let percent = Math.floor((loaded * 100) / total);
		// 		console.log(`${loaded}kb of ${total}kb | ${percent}%`);
		// 		setProgress(Math.round((100 * loaded) / total));
		// 	},
		// };
		e.preventDefault(); //prevent the form from submitting
		await axios
			.post(`${strings.fileUploadUrl}excel/file/upload`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					authorization: sessionData.token,
				},
			})
			.then(async (response) => {
				setUploadFlag(false);
				if (response.data.success) {
					props.handleClose();
					switch (props.type) {
						case "menu":
							await dispatch(fetchMenuItem("", ""));
							break;
						case "service":
							await dispatch(fetchServiceItem());
							break;
						case "drink":
							await dispatch(fetchDrinkItem());
							break;
						default:
							break;
					}

					showToastMessage(response.data.message, "S");
				} else {
					showToastMessage(response.data.message, "E");
				}
			})
			.catch(async (error) => {
				var status = 504;
				setUploadFlag(false);

				if (!isEmptyObject(error.response)) {
					status = error.response.status;
				}

				if (status === 403) {
					history.push({ pathname: "/" });
					await dispatch(
						fetchLoginError(error.response.data.message)
					);
					showToastMessage(error.response.data.message, "E");
				} else {
					showToastMessage(error.message, "E");
				}
			});
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="bg-default add-image-modal-wrapper">
				<div className="add-image-wrapper flex items-center justify-center">
					{uploadFlag ? (
						<label htmlFor="">Uploading...</label>
					) : (
						<input
							id="excelFile"
							name="excelFile"
							type="file"
							onChange={saveFile}
						/>
					)}
				</div>
				<div className="description-wrapper">
					<div className="description-title text-capitalize">
						Upload {props.type} File
					</div>
					<div className="description-message">
						All the {props.type} list of the file will be saved in
						bulk
					</div>
					<div className="description-button-container">
						<button
							className="button-primary"
							onClick={props.handleClose}
						>
							Cancel
						</button>
						<button
							className="button-primary"
							onClick={upload}
							disabled={uploadFlag}
						>
							Upload
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default FileUpload;

import React from "react";
import PrintHeader from "./printHeader.component";
import "../print-page.style.scss";
import MenuSvg from "../../../assets/images/icons/menu svg/menu-svg";
import {
	serviceTypeMapperSvg,
	drinkTypeMapperSvg,
} from "../../../assets/images/icons/iconList/icon-distributer.map";
import PrintFooter from "./printFooter.component";

class PrintBooking extends React.Component {
	constructor(props) {
		super(props);
		this.state = { snacks: [], meals: [], soup: [], salad: [] };
	}

	static getDerivedStateFromProps(props, state) {
		let data = { snacks: [], meals: [], soup: [], salad: [] };
		props.bookingData.booking_snacks.forEach((item) => {
			switch (item.menu_category) {
				case "snacks":
					data.snacks.push(item);
					break;
				case "meals":
					data.meals.push(item);
					break;
				case "soup":
					data.soup.push(item);
					break;
				case "salad":
					data.salad.push(item);
					break;
				default:
					break;
			}
		});
		return data;
	}
	render() {
		const infos = this.props;
		return (
			<div className="print-wrapper">
				<PrintHeader
					type="Booking"
					category="Invoice"
					showCompany={infos.companyName}
				/>
				<hr />
				<div className="print-body-wrapper sign">
					<div className="print-booking-wrapper">
						<div className="print-content">
							<div className="print-text">
								<h3>Client</h3>
								<p className="caps">
									{infos.client.customer_fname +
										" " +
										infos.client.customer_lname}
									{" - "}
									{infos.client.customer_mobile}
								</p>
								<h3>Email contact</h3>
								<p>{infos.client.customer_email}</p>
								{/* <h3>Phone contact</h3>
								<p>{infos.client.customer_mobile}</p> */}
							</div>
						</div>
						<div className="print-content">
							{/* <div className="print-text">
                                <h3>Venue</h3>
                                <p className="caps">
                                    {
                                        infos.bookingData.booking_halls[0]
                                            .hall_info.venue_info.venue_name
                                    }
                                </p>
                            </div> */}
							<div className="print-text grids">
								<div>
									<h3>Halls</h3>
									<p>
										{infos.bookingData.booking_halls.map(
											(value) => value.hall_name + " "
										)}
									</p>
								</div>
								<div>
									<h3>Time</h3>
									<p className="caps">
										{infos.bookingData.booked_time}
									</p>
								</div>
							</div>
							<div className="print-text grids">
								<div>
									<h3>NP Date</h3>
									<p>
										{infos.bookingData.booked_date_nepali}
									</p>
								</div>
								<div>
									<h3>EN Date</h3>
									<p>
										{infos.bookingData.booked_date_english}
									</p>
								</div>
							</div>
						</div>
						<div className="print-content">
							<div
								className="status-content"
								style={{
									backgroundColor: `var(--${infos.bookingData.booking_status})`,
								}}
							>
								{infos.bookingData.booking_status}
							</div>
							<div className="print-text grids">
								<div>
									<h3>Pax</h3>
									<p>
										{infos.companyRole === "viewer"
											? infos.bookingData.a_pax
											: infos.bookingData.e_pax}
									</p>
								</div>
								<div>
									<h3> Rate</h3>
									<p>
										Rs.{" "}
										{infos.companyRole === "viewer"
											? infos.bookingData.a_rate
											: infos.bookingData.e_rate}
									</p>
								</div>
							</div>
							{/* <div className="print-text">
								<div>
									<h3>Sales Rep</h3>
									<p className="caps">
										{
											infos.bookingData.company_employee
												.user.first_name
										}{" "}
										{
											infos.bookingData.company_employee
												.user.last_name
										}
									</p>
								</div>
							</div> */}
						</div>
					</div>
					<hr />
					<div className="print-menu-wrapper">
						<div className="list-wrapper">
							{this.state.snacks.length > 0 && (
								<div className="itemlist">
									<h2>Snacks</h2>
									<div className="itemlist-container">
										{this.state.snacks.map(
											(item, index) => (
												<div
													key={index}
													className="menu-small"
												>
													<div className="item-icon">
														<MenuSvg
															name={
																item.item_type
															}
															type={
																item.item_type ===
																	"veg" &&
																"veg"
															}
														/>
													</div>
													<p className="menu-item">
														{item.item_type !==
															"veg" &&
															item.menu_cartilage ===
																"WB" &&
															`${item.menu_cartilage} - `}
														{item.menu_item}
													</p>
												</div>
											)
										)}
									</div>
								</div>
							)}
							<hr className="hrtag" />
							{this.state.meals.length > 0 && (
								<div className="itemlist">
									<h2>Meals</h2>
									<div className="itemlist-container">
										{this.state.meals.map((item, i) => (
											<div key={i} className="menu-small">
												<div className="item-icon">
													<MenuSvg
														name={item.item_type}
														type={
															item.item_type ===
																"veg" && "veg"
														}
													/>
												</div>
												<p className="menu-item">
													{item.item_type !== "veg" &&
														item.menu_cartilage ===
															"WB" &&
														`${item.menu_cartilage} - `}
													{item.menu_item}
												</p>
											</div>
										))}
									</div>
								</div>
							)}
							<hr className="hrtag" />
							{this.state.soup.length > 0 && (
								<div className="itemlist">
									<h2>Soups</h2>
									<div className="itemlist-container">
										{this.state.soup.map((item, i) => (
											<div key={i} className="menu-small">
												<div className="item-icon">
													<MenuSvg
														name={item.item_type}
														type={
															item.item_type ===
																"veg" && "veg"
														}
													/>
												</div>
												<p className="menu-item">
													{item.menu_item}
												</p>
											</div>
										))}
									</div>
								</div>
							)}
							<hr className="hrtag" />
							{this.state.salad.length > 0 && (
								<div className="itemlist">
									<h2>Salads</h2>
									<div className="itemlist-container">
										{this.state.salad.map((item, i) => (
											<div key={i} className="menu-small">
												<div className="item-icon">
													<MenuSvg
														name={item.item_type}
														type={
															item.item_type ===
																"veg" && "veg"
														}
													/>
												</div>
												<p className="menu-item">
													{item.menu_item}
												</p>
											</div>
										))}
									</div>
								</div>
							)}
							<hr className="hrtag" />
							{infos.bookingData.booking_desserts.length > 0 && (
								<div className="itemlist">
									<h2>Desserts</h2>
									<div className="itemlist-container">
										{infos.bookingData.booking_desserts.map(
											(item, i) => (
												<div
													key={i}
													className="menu-small"
												>
													<div className="item-icon">
														<MenuSvg
															name={
																item.item_type
															}
															type={
																item.item_type ===
																	"veg" &&
																"veg"
															}
														/>
													</div>
													<p className="menu-item">
														{item.menu_item}
													</p>
												</div>
											)
										)}
									</div>
								</div>
							)}
							<hr className="hrtag" />
							{infos.bookingData.booking_services.length > 0 && (
								<div className="itemlist">
									<h2>Services</h2>
									<div className="itemlist-container">
										{infos.bookingData.booking_services.map(
											(serv, i) => (
												<div
													key={i}
													className="menu-small"
												>
													<div className="item-icon">
														{serviceTypeMapperSvg(
															serv?.service_type_name,
															{
																color: "var(--highlight-red)",
															}
														)}
													</div>
													<p className="menu-item">
														{serv.service_name}
													</p>
												</div>
											)
										)}
									</div>
								</div>
							)}
							<hr className="hrtag" />
							{infos.bookingData.booking_drinks.length > 0 && (
								<div className="itemlist">
									<h2>Drinks</h2>
									<div className="itemlist-container">
										{infos.bookingData.booking_drinks.map(
											(drink, i) => (
												<div
													key={i}
													className="menu-small"
												>
													<div className="item-icon">
														{drinkTypeMapperSvg(
															drink.drink_type,
															{
																color: "var(--afternoon)",
															}
														)}
													</div>
													<p className="menu-item">
														{drink.drink_item}
													</p>
												</div>
											)
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					<hr />
				</div>
				<PrintFooter
					printInfo={infos.printInfo}
					customerName={
						infos.client.customer_fname +
						" " +
						infos.client.customer_lname
					}
					cateringCost={
						infos.bookingData.e_pax * infos.bookingData.e_rate
					}
					payment={infos.paymentData}
					enableAmount={true}
					enableTerms={true}
					enableSign={true}
					companyRole={infos.companyRole}
					vatDisplay={infos.vatDisplay}
				/>
			</div>
		);
	}
}

export default PrintBooking;

// // old service and drink design
// <div className="print-menu-wrapper">
// 	<div className="list-wrapper">
// 		<div>
// 			Services
// 			<div className="itemlist-container cols">
// 				{infos.bookingData.booking_services.map((serv) => (
// 					<div className="menu-small">
// 						{serviceTypeMapper(
// 							serv.service.service_type.service_type_name
// 						)}
// 						<p className="menu-item">{serv.service_name}</p>
// 					</div>
// 				))}
// 			</div>
// 		</div>
// 	</div>
// </div>

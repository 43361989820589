import React, { useState } from "react";
import "./test-design.style.scss";

// for date purposes
import moment from "moment";

// components
import SideModalDateFix from "../../components/modals/side-modal/side-modal-dateFix.modal";
import NibjarNepaliDate from "../../global/nibjar-nepali-date/nibjar-nepali-converter";

const bookArr = [
	{
		event_id: 265,
		event_info_id: "f870061e-368a-4764-a134-beb3d0e91328",
		booking_id: 675,
		booking_info_id: "8fe59414-7347-44b2-bd37-7b4633f18a6e",
		booked_time: "morning",
		booked_date_english: "2024-07-16",
		booked_date_nepali: "2081-04-01",
		booking_type: "wedding",
		booking_catering: "full catering",
		customer_fname: "Reya",
		customer_lname: "Shakya",
		venue_name: "VIVA Banquet",
		e_pax: 200,
		e_rate: 1500,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-02-07",
		updatedAt: "2024-02-07",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-02-07",
		totalBookingCost: 300000,
		totalPendingAmount: 290000,
	},
	{
		event_id: 265,
		event_info_id: "f870061e-368a-4764-a134-beb3d0e91328",
		booking_id: 675,
		booking_info_id: "8fe59414-7347-44b2-bd37-7b4633f18a6e",
		booked_time: "morning",
		booked_date_english: "2024-07-10",
		booked_date_nepali: "2081-03-27",
		booking_type: "wedding",
		booking_catering: "full catering",
		customer_fname: "Prativa",
		customer_lname: "Shakyaaaaaaaaa",
		venue_name: "VIVA Banquet",
		e_pax: 200,
		e_rate: 1500,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-02-07",
		updatedAt: "2024-02-07",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-02-07",
		totalBookingCost: 300000,
		totalPendingAmount: 290000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
	{
		event_id: 265,
		event_info_id: "f870061e-368a-4764-a134-beb3d0e91328",
		booking_id: 674,
		booking_info_id: "3b1c3077-35f1-4094-94e1-1113f77930b8",
		booked_time: "evening",
		booked_date_english: "2024-07-15",
		booked_date_nepali: "2081-03-32",
		booking_type: "wedding",
		booking_catering: "full catering",
		customer_fname: "Prativa",
		customer_lname: "Shakya",
		venue_name: "VIVA Banquet",
		e_pax: 400,
		e_rate: 1400,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-02-07",
		updatedAt: "2024-02-07",
		payments: 0,
		discounts: 5000,
		refunds: 0,
		lastTransactionDate: "2024-02-07",
		totalBookingCost: 560000,
		totalPendingAmount: 555000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
	{
		event_id: 269,
		event_info_id: "458ab854-0dba-41d3-a4f0-51a06635e644",
		booking_id: 679,
		booking_info_id: "a21469c8-09b5-4dfd-a33b-b4f89f5e6dac",
		booked_time: "evening",
		booked_date_english: "2024-06-13",
		booked_date_nepali: "2081-02-31",
		booking_type: "social event",
		booking_catering: "full catering",
		customer_fname: "Prasim",
		customer_lname: " SHAKYA",
		venue_name: "VIVA Banquet",
		e_pax: 500,
		e_rate: 1400,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-02-21",
		updatedAt: "2024-02-23",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-02-23",
		totalBookingCost: 700000,
		totalPendingAmount: 690000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
	{
		event_id: 273,
		event_info_id: "1c683871-9ec5-4f50-841c-e5cee090adfd",
		booking_id: 683,
		booking_info_id: "374c12b5-8dce-4ba4-96c0-83f9468c6a4e",
		booked_time: "evening",
		booked_date_english: "2024-06-14",
		booked_date_nepali: "2081-03-01",
		booking_type: "bratabandha",
		booking_catering: "full catering",
		customer_fname: "Anil",
		customer_lname: "Maharjan",
		venue_name: "VIVA Banquet",
		e_pax: 300,
		e_rate: 1540,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-03-03",
		updatedAt: "2024-03-03",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-03-03",
		totalBookingCost: 462000,
		totalPendingAmount: 452000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
	{
		event_id: 273,
		event_info_id: "1c683871-9ec5-4f50-841c-e5cee090adfd",
		booking_id: 6803,
		booking_info_id: "374c12b5-8dce-4ba4-96c0-83f9468c6a4e",
		booked_time: "evening",
		booked_date_english: "2024-06-15",
		booked_date_nepali: "2081-03-02",
		booking_type: "bratabandha",
		booking_catering: "full catering",
		customer_fname: "SANAK",
		customer_lname: "Shrestha",
		venue_name: "VIVA Banquet",
		e_pax: 300,
		e_rate: 1540,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-03-03",
		updatedAt: "2024-03-03",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-03-03",
		totalBookingCost: 462000,
		totalPendingAmount: 452000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
	{
		event_id: 273,
		event_info_id: "1c683871-9ec5-4f50-841c-e5cee090adfd",
		booking_id: 6813,
		booking_info_id: "374c12b5-8dce-4ba4-96c0-83f9468c6a4e",
		booked_time: "evening",
		booked_date_english: "2024-06-16",
		booked_date_nepali: "2081-03-03",
		booking_type: "bratabandha",
		booking_catering: "full catering",
		customer_fname: "Rajbin",
		customer_lname: "Shrestha",
		venue_name: "VIVA Banquet",
		e_pax: 300,
		e_rate: 1540,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-03-03",
		updatedAt: "2024-03-03",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-03-03",
		totalBookingCost: 462000,
		totalPendingAmount: 452000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
	{
		event_id: 273,
		event_info_id: "1c683871-9ec5-4f50-841c-e5cee090adfd",
		booking_id: 6823,
		booking_info_id: "374c12b5-8dce-4ba4-96c0-83f9468c6a4e",
		booked_time: "evening",
		booked_date_english: "2024-07-14",
		booked_date_nepali: "2081-03-31",
		booking_type: "bratabandha",
		booking_catering: "full catering",
		customer_fname: "Kumar",
		customer_lname: "Shrestha",
		venue_name: "VIVA Banquet",
		e_pax: 300,
		e_rate: 1540,
		a_pax: 0,
		a_rate: 0,
		a_service: 0,
		booking_halls: [{ hall_info_id: 1, hall_name: "H1" }],
		booking_status: "booked",
		payment_status: "exists",
		vat_status: "none",
		createdAt: "2024-03-03",
		updatedAt: "2024-03-03",
		payments: 10000,
		discounts: 0,
		refunds: 0,
		lastTransactionDate: "2024-03-03",
		totalBookingCost: 462000,
		totalPendingAmount: 452000,

		customer_address: "ktm",
		customer_city: "Kathmandu",
		customer_country: "Nepal",
		customer_email: "customerfind@yopmail.com",
		customer_gender: "male",
		customer_id: 2,
		customer_image: null,
		customer_info_id: "551e9089-d3f2-476e-a6c3-a22f0872bfed",
		customer_mobile: "9862515251",
		customer_phone: null,
		customer_type: "individual",
	},
];

//Neplai Month for 2081 calendar
const monthdays = [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31];
const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const NEPMONTH = [
	"Baisakh",
	"Jestha",
	"Ashar",
	"Shrawan",
	"Bhadra",
	"Ashoj",
	"Kartik",
	"Mangsir",
	"Poush",
	"Magh",
	"Falgun",
	"Chaitra",
];

/*
need to fix:
x1. on click right when datechanger active --> remove date and extra fields from book array
x2. make all calendar dates clickable
x3. highlight calendar if more than one booking: make it orange
x4. highlight calendar if conflict: red
5. add customer data to sidemodal in calendar clicks
x6. create sidemodal for customer click
x7. add cancel booking button in sidemodal for number 6
	xa. add cancel booking flag and send cancel booking list
8. display payment status and booking status for bookings in left and sidemodal
9. display time in calendar dates for bookings
10. submit button to submit all changes.
	a. warn for final submit --> api Call here
11. send back to dashboard.
----
8. API call for bookings
	a. Must have customer details like number and email
	b. hall array
9. API for post call check to see if all data is updated
	a. must update dates
	b. must check if cancel booking lists
	c. change calendar fix flag to false.
*/

function TestDesign() {
	//API Call Needed
	const [book, setBook] = useState(bookArr);
	//12 month object to hold fixed booking dates
	const [calMonth, setCalMonth] = useState([
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
		{},
	]);
	//SideModal Assists
	const [showSideModal, setShowSideModal] = useState(false);
	const [sideModalData, setSideModalData] = useState([]);
	//Core States
	const [manualDateChanger, setManualDateChanger] = useState({
		state: false,
		index: {},
	});
	//rerenderer
	const [test, setTest] = useState(false);

	//Hero Function: Click to add booking to calMonth
	function onClicker(newdates, data, bookListIndex) {
		let currentSelectedBooking = { ...data };
		//Remember month and day index of selected date option
		let dayIndex;
		let tempCalMonth = [...calMonth];
		let bookingArr = book;

		if (newdates === "neweng") {
			//keeping old nepali date and adding new eng date based on selection
			let bfix_en = new NibjarNepaliDate(data.booked_date_nepali).getAD();
			bfix_en = {
				year: bfix_en.year,
				month: bfix_en.month,
				day: bfix_en.date,
			};
			bfix_en = moment(bfix_en).format("YYYY-MM-DD");
			//setting flag to know new english option was selected
			currentSelectedBooking["selection_flag"] = "en";

			let nepaliDateFix = new NibjarNepaliDate(
				currentSelectedBooking.booked_date_nepali
			).format("YYYY-MM-DD");
			dayIndex = nepaliDateFix.split("-");
			//storing index of where booking is placed in calendar
			currentSelectedBooking["index"] = {
				m: parseInt(dayIndex[1]) - 1,
				d: parseInt(dayIndex[2]) - 1,
			};
			//Setting the booking in the main booking array with index memory
			bookingArr[bookListIndex] = { ...currentSelectedBooking };
			//Setting new dates for storage in calendar
			currentSelectedBooking.booked_date_nepali = nepaliDateFix;
			currentSelectedBooking.booked_date_english = bfix_en;
		} else if (newdates === "newnep") {
			//keeping old eng date and adding new np date based on selection
			let bfix_np = new NibjarNepaliDate(
				new Date(data.booked_date_english)
			).format("YYYY-MM-DD");
			//setting flag to know new nepali option was selected
			currentSelectedBooking["selection_flag"] = "np";
			dayIndex = bfix_np.split("-");
			//storing index of where booking is placed in calendar
			currentSelectedBooking["index"] = {
				m: parseInt(dayIndex[1]) - 1,
				d: parseInt(dayIndex[2]) - 1,
			};
			//Setting the booking in the main booking array with index memory
			bookingArr[bookListIndex] = { ...currentSelectedBooking };
			//Setting new dates for storage in calendar
			currentSelectedBooking.booked_date_nepali = bfix_np;
		} else {
			//manual change date based on selection: new np and eng dates
			let bfix_en = new NibjarNepaliDate(data.new_date).getAD();
			bfix_en = {
				year: bfix_en.year,
				month: bfix_en.month,
				day: bfix_en.date,
			};
			bfix_en = moment(bfix_en).format("YYYY-MM-DD");
			//removing default selection option flag if exists
			delete currentSelectedBooking["selection_flag"];
			//storing index of where booking is placed in calendar
			dayIndex = data.new_date.split("-");
			currentSelectedBooking["index"] = {
				m: parseInt(dayIndex[1]) - 1,
				d: parseInt(dayIndex[2]) - 1,
			};
			//Setting the booking in the main booking array with index memory
			bookingArr[bookListIndex] = { ...currentSelectedBooking };
			//Setting new dates for storage in calendar
			currentSelectedBooking.booked_date_nepali = data.new_date;
			currentSelectedBooking.booked_date_english = bfix_en;
		}
		//adding index of booking in main Book List
		currentSelectedBooking["bookIndex"] = bookListIndex;

		//Check if Calendar[month][day] has data
		if (
			!tempCalMonth[currentSelectedBooking.index.m][
				`${currentSelectedBooking.index.d}`
			]
		) {
			//set default values
			tempCalMonth[currentSelectedBooking.index.m][
				`${currentSelectedBooking.index.d}`
			] = {
				conflict: {
					flag: false,
					morning: {},
					afternoon: {},
					evening: {},
				},
				calBooking: [],
			};
		}
		//Previous selection clearing: Check if booking has prior selection indexes
		if (data?.index) {
			//removing current selected booking from prev selected calendar placement
			let filteredArr = tempCalMonth[data.index.m][
				`${data.index.d}`
			].calBooking.filter((item) => item.booking_id !== data.booking_id);
			//reduce hall count for previous selection
			data.booking_halls.forEach((hall) => {
				tempCalMonth[data.index.m][`${data.index.d}`].conflict[
					data.booked_time
				][hall.hall_info_id] -= 1;
				//check if hall value could is less than 0 to remove the hall key for future conflict detection
				if (
					tempCalMonth[data.index.m][`${data.index.d}`].conflict[
						data.booked_time
					][hall.hall_info_id] <= 0
				) {
					delete tempCalMonth[data.index.m][`${data.index.d}`]
						.conflict[data.booked_time][hall.hall_info_id];
				}
				//check if conflict flag is still true after removeing current booking from prev
				if (
					tempCalMonth[data.index.m][`${data.index.d}`].conflict[
						data.booked_time
					][hall.hall_info_id] > 1
				) {
					tempCalMonth[data.index.m][
						`${data.index.d}`
					].conflict.flag = true;
				} else {
					tempCalMonth[data.index.m][
						`${data.index.d}`
					].conflict.flag = false;
				}
			});
			//set the array of bookings after REMOVAL of current booking
			tempCalMonth[data.index.m][`${data.index.d}`].calBooking =
				filteredArr;
		}
		//Check hall conflict for new selected date
		currentSelectedBooking.booking_halls.forEach((hall) => {
			//check if hall id key exists in specified time
			if (
				tempCalMonth[currentSelectedBooking.index.m][
					`${currentSelectedBooking.index.d}`
				].conflict[currentSelectedBooking.booked_time][
					hall.hall_info_id
				]
			) {
				tempCalMonth[currentSelectedBooking.index.m][
					`${currentSelectedBooking.index.d}`
				].conflict[currentSelectedBooking.booked_time][
					hall.hall_info_id
				] += 1;
			} else {
				tempCalMonth[currentSelectedBooking.index.m][
					`${currentSelectedBooking.index.d}`
				].conflict[currentSelectedBooking.booked_time][
					hall.hall_info_id
				] = 1;
			}
			//Check if same Time-hall is reserved for multiple bookings to set conflict status
			if (
				tempCalMonth[currentSelectedBooking.index.m][
					`${currentSelectedBooking.index.d}`
				].conflict[currentSelectedBooking.booked_time][
					hall.hall_info_id
				] > 1
			) {
				tempCalMonth[currentSelectedBooking.index.m][
					`${currentSelectedBooking.index.d}`
				].conflict.flag = true;
			}
		});
		//add the current booking to selected time with all added memory keys
		tempCalMonth[currentSelectedBooking.index.m][
			`${currentSelectedBooking.index.d}`
		].calBooking.push(currentSelectedBooking);

		setCalMonth(tempCalMonth);
		setBook(bookingArr);
		setTest(!test);
	}

	//Remove dates option selection from bookList and calendar
	function deselector(data, index) {
		let bookingArr = book;
		let tempCalMonth = [...calMonth];
		//Removing Booking: filtering Calendar[month][day]
		let filteredArr = tempCalMonth[data.index.m][
			data.index.d
		].calBooking.filter(
			(booking) => booking.booking_id !== data.booking_id
		);
		//reduce hall count for previous selection
		data.booking_halls.forEach((hall) => {
			tempCalMonth[data.index.m][`${data.index.d}`].conflict[
				data.booked_time
			][hall.hall_info_id] -= 1;
			//check if hall value could is less than 0 to remove the hall key for future conflict detection
			if (
				tempCalMonth[data.index.m][`${data.index.d}`].conflict[
					data.booked_time
				][hall.hall_info_id] <= 0
			) {
				delete tempCalMonth[data.index.m][`${data.index.d}`].conflict[
					data.booked_time
				][hall.hall_info_id];
			}
			//check if conflict flag is still true after removeing current booking from prev
			if (
				tempCalMonth[data.index.m][`${data.index.d}`].conflict[
					data.booked_time
				][hall.hall_info_id] > 1
			) {
				tempCalMonth[data.index.m][
					`${data.index.d}`
				].conflict.flag = true;
			} else {
				tempCalMonth[data.index.m][
					`${data.index.d}`
				].conflict.flag = false;
			}
		});
		//set the array of bookings after REMOVAL of current booking
		tempCalMonth[data.index.m][`${data.index.d}`].calBooking = filteredArr;

		//removing all added memory keys and data from booking
		let tempBookingData = { ...bookingArr[index] };
		delete tempBookingData.index;
		delete tempBookingData.selection_flag;
		delete tempBookingData.new_date;
		delete tempBookingData.manualChanger;
		//Setting booking back to the main book list
		bookingArr[index] = tempBookingData;
		setBook(bookingArr);
		setCalMonth(tempCalMonth);
		setTest(!test);
	}

	//Handling Manual Date Change Option
	function _handleDateChange(bookingIndex) {
		let bookArr = [...book];
		bookArr[bookingIndex].manualChanger = true;

		setBook(bookArr);
		setManualDateChanger({ state: true, index: bookingIndex });
	}

	//Handling Booking Cancel
	function _handleToggleBookingCancel(bookingIndex) {
		let bookArr = [...book];
		//Undo Cancel
		if (bookArr[bookingIndex]?.bookingCanceled) {
			delete bookArr[bookingIndex].bookingCanceled;
		} else {
			bookArr[bookingIndex].bookingCanceled = true;
		}
		setBook(bookArr);
	}

	function _handleOnClickSideModal(bookingDataArr) {
		setShowSideModal(true);
		setSideModalData(bookingDataArr);
	}

	//render for each current Booking list on left
	function _renderBookings(booking, monthIndex, bookingIndex) {
		let bookingMonthIndex = parseInt(
			booking.booked_date_nepali.split("-")[1]
		);
		//Render only if month index match
		if (bookingMonthIndex === monthIndex + 1) {
			let fixedNepaliDate = new NibjarNepaliDate(
				booking.booked_date_nepali
			).format("YYYY-MM-DD");

			let bfix_np;
			let bfix_en;
			if (booking?.manualChanger && booking?.new_date) {
				bfix_np = new NibjarNepaliDate(booking?.new_date);
				bfix_en = bfix_np.getAD();
				bfix_np = bfix_np.format("YYYY-MM-DD");
			} else {
				bfix_np = new NibjarNepaliDate(
					new Date(booking.booked_date_english)
				).format("YYYY-MM-DD");
				bfix_en = new NibjarNepaliDate(
					booking.booked_date_nepali
				).getAD();
			}
			bfix_en = moment({
				year: bfix_en.year,
				month: bfix_en.month,
				day: bfix_en.date,
			}).format("YYYY-MM-DD");
			//check if new year calendar np date is same as stored in booking
			let dateChangeCondition = bfix_np === booking.booked_date_nepali;

			return (
				<div className="bfix-booking-wrp" key={`r${bookingIndex}`}>
					<div
						className="bfix-booking-container"
						style={{
							opacity: booking?.bookingCanceled ? 0.5 : 1,
						}}
					>
						<div
							className={`book-container ${
								!booking.selection_flag &&
								!booking.manualChanger &&
								"bfix-close"
							}`}
							onClick={() => _handleOnClickSideModal(booking)}
							disabled={booking?.bookingCanceled}
						>
							<div className="bfix-id">
								# {booking.booking_id}
								<div className="bfix-time">
									{booking.booked_time}
								</div>
							</div>
							<div className="bfix-details">
								<div className="bfix-elipsis">
									{booking.customer_fname}{" "}
									{booking.customer_lname}
								</div>
							</div>
							<div className="bfix-details">
								<div>{booking.customer_mobile}</div>
							</div>
						</div>
						<div className="bfix-dates">
							<div className="bfix-indicators">
								<div className="bfix-en lit">E</div>
								<div className="bfix-np lit">N</div>
							</div>
							<div className="bfix-current">
								<div className="bfix-en lit">
									{booking.booked_date_english}
								</div>
								<div className="bfix-np lit">
									{booking.booked_date_nepali}
								</div>
							</div>
							{booking?.manualChanger ? (
								<>
									{booking?.new_date ? (
										<div className="bfix-manual-wrapper">
											<div className="bfix-manual">
												<div>{bfix_en}</div>
												<div>{bfix_np}</div>
											</div>
											<div
												className="manual-indication"
												onClick={() =>
													deselector(
														booking,
														bookingIndex
													)
												}
											>
												M
											</div>
										</div>
									) : (
										<div className="manual-processing">
											Make Selection...
										</div>
									)}
								</>
							) : (
								<>
									{!dateChangeCondition ? (
										<>
											<div
												className={`bfix-current new ${
													booking.selection_flag ===
														"np" && "bfix-lit"
												}`}
												onClick={() =>
													booking.selection_flag !==
													"np"
														? onClicker(
																"newnep",
																booking,
																bookingIndex
														  )
														: deselector(
																booking,
																bookingIndex
														  )
												}
												disabled={
													booking?.bookingCanceled
												}
											>
												<div className="bfix-en lit">
													{
														booking.booked_date_english
													}
												</div>
												<div className="bfix-np">
													{bfix_np}
												</div>
											</div>
											<div
												className={`bfix-current new ${
													booking.selection_flag ===
														"en" && "bfix-lit"
												}`}
												onClick={() =>
													booking.selection_flag !==
													"en"
														? onClicker(
																"neweng",
																booking,
																bookingIndex
														  )
														: deselector(
																booking,
																bookingIndex
														  )
												}
												disabled={
													booking?.bookingCanceled
												}
											>
												<div className="bfix-en">
													{bfix_en}
												</div>
												<div className="bfix-np lit">
													{fixedNepaliDate}
												</div>
											</div>
										</>
									) : (
										<div
											className={`bfix-current new all-good ${
												booking.selection_flag ===
													"np" && "bfix-lit"
											}`}
											onClick={() =>
												booking.selection_flag !== "np"
													? onClicker(
															"newnep",
															booking,
															bookingIndex
													  )
													: deselector(
															booking,
															bookingIndex
													  )
											}
											disabled={booking?.bookingCanceled}
										>
											All Good
										</div>
									)}
								</>
							)}
						</div>
					</div>

					<div className="bfix-buttons">
						<div
							className={"bfix-date-changer"}
							onClick={() =>
								_handleToggleBookingCancel(bookingIndex)
							}
							disabled={manualDateChanger.state}
						>
							{booking?.bookingCanceled
								? "Undo Cancel"
								: "Cancel Booking"}
						</div>
						<div
							className={`bfix-date-changer`}
							onClick={() => _handleDateChange(bookingIndex)}
							disabled={
								booking?.bookingCanceled ||
								manualDateChanger.state
							}
						>
							Manual Date
						</div>
					</div>
				</div>
			);
		}
	}
	//render fixer calendar bookings on right
	function _renderCalBooking(monthIndex, dateIndex) {
		let day = new NibjarNepaliDate(2081, monthIndex, 1).getDay();
		let actualDateIndex = dateIndex - day;
		let conditionalColor = "";
		//Enabling and disabling date box clicks
		if (manualDateChanger.state) {
			conditionalColor = "clickable-log";
		} else {
			//Conflict highlighter
			if (calMonth[monthIndex][actualDateIndex]?.conflict?.flag) {
				conditionalColor = "err-log";
			} else if (
				calMonth[monthIndex][actualDateIndex]?.calBooking?.length > 1
			) {
				conditionalColor = "multi-log";
			}
		}
		function _handleCalClickOptions() {
			if (manualDateChanger.state) {
				_handleSelectNewDate(monthIndex, actualDateIndex + 1);
			} else {
				if (calMonth[monthIndex][actualDateIndex]?.calBooking)
					_handleOnClickSideModal(
						calMonth[monthIndex][actualDateIndex]?.calBooking
					);
			}
		}

		function _handleSelectNewDate(monthInd, date) {
			let newDates = new NibjarNepaliDate(2081, monthInd, date);
			let booking = book[manualDateChanger.index];
			booking.new_date = newDates.format("YYYY-MM-DD");
			onClicker("newDate", booking, manualDateChanger.index);
			setManualDateChanger({ state: false, index: {} });
		}
		if (day > dateIndex) {
			return <div className="bfix-days blanks"></div>;
		} else {
			return (
				<div
					className={`bfix-days ${conditionalColor}`}
					onClick={_handleCalClickOptions}
				>
					<div>{actualDateIndex + 1}</div>
					{typeof calMonth[monthIndex][actualDateIndex]
						?.calBooking === "object" &&
						calMonth[monthIndex][actualDateIndex]?.calBooking.map(
							(b, l) => (
								<div>
									BID {b.booking_id}:{b.customer_fname}
								</div>
							)
						)}
				</div>
			);
		}
	}
	//Helper Function to assign number of days to calendar block
	function renderDays(monthIndex) {
		let day = new NibjarNepaliDate(2081, monthIndex, 1).getDay();
		return monthdays[monthIndex] + day;
	}
	return (
		<div className="bfix-wrapper">
			{calMonth.map((_, monthIndex) => (
				<div className="bfix-main" key={monthIndex}>
					<div className="bfix-left">
						<div className="bfix-booking-wrp">
							<div className="bfix-booking-container left-title">
								<div className="book-container">Customer</div>
								<div className="bfix-dates">
									<div className="bfix-indicators"></div>
									<div className="bfix-current">Current</div>
									<div className="bfix-current">
										New Nepali
									</div>
									<div className="bfix-current"> New Eng</div>
								</div>
							</div>
						</div>
						{book.map((booking, bookingIndex) => (
							<>
								{_renderBookings(
									booking,
									monthIndex,
									bookingIndex
								)}
							</>
						))}
					</div>
					<div className="bfix-right">
						<div className="bfix-months">
							<div className="title">
								Month : {monthIndex + 1} {NEPMONTH[monthIndex]}
							</div>
							<div className="day-title-container">
								{DAYS.map((day, i) => (
									<div className="day-title" key={i}>
										{day}
									</div>
								))}
							</div>
							<div className="dates-container">
								{[...Array(renderDays(monthIndex))].map(
									(_, dayIndex) =>
										_renderCalBooking(monthIndex, dayIndex)
								)}
							</div>
						</div>
					</div>
				</div>
			))}

			<SideModalDateFix
				isVisible={showSideModal}
				onModalClose={() => setShowSideModal(!showSideModal)}
				containerStyle={{ minWidth: "310px" }}
				sideModalData={sideModalData}
				modelFor="Booking Review"
				deselectorFunction={deselector}
			/>
		</div>
	);
}

export default TestDesign;

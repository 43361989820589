import React from "react";
import "./menu-svg.scss";

export default function Buff({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 20 19.52"
            id="buff">
            <g>
                <path d="M19.89,0c-.35,1-1.5,3.64-2.56,3.64a2.45,2.45,0,0,1-1.83-.82A2.28,2.28,0,0,0,13.8,2H13a1.33,1.33,0,0,0-1.07.54,2.42,2.42,0,0,1-2.05.71,2.54,2.54,0,0,1-2.1-.69A1.41,1.41,0,0,0,6.62,2H5.83a2.28,2.28,0,0,0-1.7.82,2.45,2.45,0,0,1-1.83.82C1.24,3.64.59,1,.25,0,.39,2.19-1.69,6.92,4.06,6.13A4.49,4.49,0,0,0,4,7v3.83a4.76,4.76,0,0,0,2.07,3.92,4.33,4.33,0,0,0-.1.89,3.93,3.93,0,0,0,7.85,0,3.85,3.85,0,0,0-.12-1,4.73,4.73,0,0,0,2-3.84V7a4.49,4.49,0,0,0-.08-.83C21.4,7.4,19.74,2.19,19.89,0ZM5.75,8.67a1.41,1.41,0,1,1,1.41,1.41A1.41,1.41,0,0,1,5.75,8.67Zm4.12,8.89a2,2,0,0,1-2-2,.37.37,0,0,1,0-.11,4.8,4.8,0,0,0,.85.08h2.09a5.23,5.23,0,0,0,1-.1.57.57,0,0,1,0,.13A2,2,0,0,1,9.87,17.56Zm2.68-7.51A1.41,1.41,0,1,1,14,8.65,1.41,1.41,0,0,1,12.55,10.05Z" />
            </g>
        </svg>
    );
}

/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
	fetchDrinkItem,
	searchDrinkItem,
} from "../../redux/actions/menu-service-settings.action";
import { useDispatch } from "react-redux";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import SelectBox from "../common/select-box/form-select-input.component";
import strings from "../../global/strings";

function SearchDrinkComponent({ handleAdd, uploadClick,controlsEnabled= true }) {
	const dispatch = useDispatch();

	const [searchDrinkName, setSearchDrinkName] = useState({
		drink_item: "",
		drink_category: "",
		drink_type: "",
	});
	const setSearchDrink = (e) => {
		setSearchDrinkName({
			...searchDrinkName,
			[e.target.name]: e.target.value,
		});
	};
	const _clearDrinkSearch = () => {
		setSearchDrinkName({
			drink_item: "",
			drink_category: "",
			drink_type: "",
		});
		dispatch(fetchDrinkItem());
	};

	const _searchDrink = () => {
		dispatch(searchDrinkItem(searchDrinkName));
	};
	const uploadDrink = () => {
		uploadClick("drink");
	};
	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Drink</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					label="Drink Name"
					placeholder="Enter Drink Name"
					type="text"
					name="drink_item"
					value={searchDrinkName.drink_item}
					onChange={(e) => setSearchDrink(e)}
				/>
				<SelectBox
					value={searchDrinkName.drink_category}
					options={strings.drinkCategory}
					variant="search"
					name="drink_category"
					onChange={(e) => setSearchDrink(e)}
					label="Drink Category"
				/>
				<SelectBox
					value={searchDrinkName.drink_type}
					options={strings.drinkType}
					variant="search"
					name="drink_type"
					onChange={(e) => setSearchDrink(e)}
					label="Drink Type"
				/>
			</div>
			<div className={`flex gap-x-15px ${controlsEnabled ? "justify-between" : "justify-end"}`}>
				{controlsEnabled && <div className="flex gap-10px">
					<button className="button-primary " onClick={handleAdd}>
						Add Drink
					</button>
					<button
						className="button-primary sm:hidden"
						onClick={uploadDrink}
					>
						Upload Drink
					</button>
				</div>}
				<div className="flex gap-10px">
					<button
						className="button-primary-icon"
						onClick={_clearDrinkSearch}
					>
						<i className="fas fa-eraser"></i>
					</button>
					<button
						className="button-primary-icon"
						onClick={_searchDrink}
					>
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</div>
	);
}
export default SearchDrinkComponent;

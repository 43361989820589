import React, { useState } from "react";
import "./booking-smv-form.style.scss";

// form handle
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// global strings and functions
import strings from "../../../global/strings";
import { currencyFormat, isEmptyObject } from "../../../global/function";

// for date purpose
import moment from "moment";

// components
import FormTextInputComponent from "../../common/text-input/form-text-input.component";
import FormTextareaInputComponent from "../../common/text-input/form-textarea-input.component";
import EnNpDatePicker from "../../common/en-np-datepicker/en-np-datepicker.component";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchBookingPayment,
	initiatePayment,
	searchPaymentHistory,
} from "../../../redux/actions/payment-action";

const paymentSchema = yup.object().shape(
	{
		payment_option: yup
			.string()
			.nullable()
			.required("Please select payment option"),
		cheque_number: yup.string().when("payment_option", {
			is: (value) => value === "cheque",
			then: yup.string().required("Please enter cheque number"),
		}),

		discount_amount: yup.string().when("payment_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),
			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
		payment_amount: yup.string().when("discount_amount", {
			is: (value) => value === "" || !value || value.length === 0,
			then: yup
				.string()
				.required("Required")
				.test(
					"positive",
					"Must be more than 0",
					(val) => parseFloat(val) > 0
				),

			otherwise: yup
				.string()
				.transform((value) =>
					value === "" ? value : parseFloat(value) < 0 ? "" : value
				),
		}),
	},
	["payment_amount", "discount_amount", "payment_option"]
);

export default function BookingPaymentForm({
	booking,
	companyRole,
	modalFrom,
	closeAccordion,
	onAfterSuccessCall,
}) {
	const { bookingPayment, paymentHistoryList, isLoading } = useSelector(
		(state) => state.payment
	);

	const [checkPayment, setCheckPayment] = useState({
		isEnabled: false,
		isAllowed: false,
	});
	const [viewPaymentsList, setViewPaymentsList] = useState(false);
	const [paymentsListFetched, setPaymentsListFetched] = useState(false);

	const [paymentDate, setPaymentDate] = useState(
		moment().format("YYYY-MM-DD")
	);

	const dispatch = useDispatch();

	const { register, handleSubmit, watch, errors } = useForm({
		mode: "onSubmit",
		defaultValues: {},
		resolver: yupResolver(paymentSchema),
	});
	let paymentOption = watch("payment_option");

	const handlePaymentAdd = async (paymentDetails) => {
		let array = [];
		let bookingsPayment = {};
		bookingsPayment["ratio"] = 1;
		bookingsPayment["booking_info_id"] = booking.booking_info_id;
		bookingsPayment["venue_info_id"] = booking.venue_info_id;
		bookingsPayment["booking_status"] =
			booking.booking_status === "inquiry" ? "booked" : "confirmed";

		array.push(bookingsPayment);
		paymentDetails["bookings"] = array;
		paymentDetails["createdAt"] = paymentDate;

		let response = await dispatch(initiatePayment(paymentDetails));
		//structuring data for print modal
		let printdata = {
			customer_name: `${booking.customer_fname} ${booking.customer_lname}`,
			contact_info: "",
			booked_date_nepali: booking.booked_date_nepali,
			booked_date_english: booking.booked_date_english,
			book_time: booking.booked_time,
			customerEmail: "",
			booking_id: booking.booking_id,
			payment_id: "",
			transaction_type: "payment",
			payment_type: paymentDetails.payment_option,
			payment_date: moment(paymentDetails.createdAt).format("YYYY-MM-DD"),
			paid_amount: paymentDetails.payment_amount,
		};

		if (response?.success) {
			setPaymentDate(moment().format("YYYY-MM-DD"));
			setCheckPayment({
				isEnabled: false,
				isAllowed: false,
			});

			onAfterSuccessCall(booking.booked_date_english, {
				type: "payment",
				data: printdata,
			});
			closeAccordion();
		} else {
			onAfterSuccessCall(booking.booked_date_english, {});
		}
	};

	const handleCheckBalance = async () => {
		if (
			booking?.booking_status !== "canceled" &&
			booking?.payment_status !== "complete" &&
			booking?.booking_status !== "complete"
		) {
			await dispatch(fetchBookingPayment(booking.booking_info_id));
			setCheckPayment({
				isEnabled: true,
				isAllowed: true,
			});
		} else {
			setCheckPayment({
				isEnabled: true,
				isAllowed: false,
			});
		}
	};

	const hanldeViewPaymentsList = async () => {
		if (viewPaymentsList) {
			setViewPaymentsList(false);
		} else {
			setViewPaymentsList(true);
			if (!paymentsListFetched) {
				await dispatch(
					searchPaymentHistory(20, 1, {
						payment_id: "",
						event_id: "",
						date_from: "",
						date_to: "",
						customer_fname: "",
						customer_lname: "",
						payment_type: "",
						transaction_type: "",
						venue_info_id: "",
						booking_id: booking.booking_id,
					})
				);
				setPaymentsListFetched(true);
			}
		}
	};

	function _renderPaymentDetails() {
		let g_Bal, a_Bal, payment;
		if (modalFrom === "bookingHistory" && companyRole !== "viewer") {
			let additionals =
				(booking?.totalDrinkAmount || 0) +
				(booking?.totalServiceAmount || 0);

			payment = booking?.totalPayment || 0;
			g_Bal = booking?.e_pax * booking?.e_rate + additionals - payment;
			a_Bal = g_Bal + (booking?.totalVat || 0);
		} else {
			payment = bookingPayment?.totalHoldingPayment;
			g_Bal =
				bookingPayment?.totalEstimatedBookingCost -
				bookingPayment?.totalHoldingPayment;
			a_Bal =
				bookingPayment?.totalEstimatedBookingCost +
				bookingPayment?.vatAmount -
				bookingPayment?.totalHoldingPayment;
		}

		return (
			<div className="payment-details">
				<div className="payment-detail-block">
					<div className="detail-label">Total Payment</div>
					<div className="detail-value">
						Rs. <b>{currencyFormat(payment)}</b>
					</div>
				</div>

				<div className="payment-detail-block">
					<div className="detail-label">Gross Balance</div>
					<div className="detail-value">
						Rs. <b>{currencyFormat(g_Bal)}</b>
					</div>
				</div>

				<div className="payment-detail-block">
					<div className="detail-label">Total Balance</div>
					<div className="detail-value">
						Rs. <b>{currencyFormat(a_Bal)}</b>
					</div>
				</div>
			</div>
		);
	}

	function _renderPaymentForm() {
		if (
			booking?.booking_status !== "canceled" &&
			booking?.payment_status !== "complete" &&
			booking?.booking_status !== "complete"
		) {
			return (
				<div className="booking-smv-form-container">
					<div className="section-header">Make Booking Payment</div>
					<div className="payment-options-container">
						<div className="options-label">Payment Options</div>
						<div className="option-block">
							{strings.paymentType.map((p) => {
								return (
									<div className={`bp-options`} key={p}>
										<input
											type="radio"
											ref={register}
											id={p}
											value={p}
											name={"payment_option"}
											className="bp-option-checkbox"
										/>
										<label
											htmlFor={p}
											className={`bg-default bp-option-label ${
												errors?.payment_option
													?.message && "errorlogger"
											}`}
										>
											{p === "cash" && (
												<i className="fas fa-coins"></i>
											)}
											{p === "cheque" && (
												<i className="fa fa-money-check-alt"></i>
											)}
											{p === "transfer" && (
												<i className="fas fa-exchange-alt"></i>
											)}
											{p}
										</label>
									</div>
								);
							})}
						</div>
						<div className="validation-err">
							{errors?.payment_option?.message}
						</div>
					</div>
					<div className="bsmv-form-row">
						<EnNpDatePicker
							name="createdAt"
							label="Payment Date"
							type="date"
							onChange={(fieldName, fieldValue) =>
								setPaymentDate(fieldValue)
							}
							value={paymentDate || moment().format("YYYY-MM-DD")}
						/>
						<FormTextInputComponent
							variant="bsmv-form-input"
							inputvariant="bsmv-form-input-field"
							label="Cheque number"
							name="cheque_number"
							ref={register}
							error={errors?.cheque_number?.message}
							type="text"
							disabled={paymentOption !== "cheque"}
						/>
						<FormTextInputComponent
							variant="bsmv-form-input"
							inputvariant="bsmv-form-input-field"
							label="Payment Amount"
							name="payment_amount"
							ref={register}
							error={errors?.payment_amount?.message}
							type="number"
						/>
						<FormTextInputComponent
							variant="bsmv-form-input"
							inputvariant="bsmv-form-input-field"
							label="Discount Amount"
							name="discount_amount"
							ref={register}
							error={errors?.discount_amount?.message}
							type="number"
						/>
					</div>

					<div className="bsmv-form-row">
						<FormTextareaInputComponent
							// label="Terms & Condition"
							variant="bsmv-form-input bsmv-form-textarea"
							name="notes"
							ref={register}
							placeholder="Payment Notes"
						/>
					</div>

					<div className="bsmv-form-buttons">
						<button
							className="bsmv-button"
							onClick={handleSubmit(handlePaymentAdd)}
							disabled={isLoading}
						>
							{isLoading ? "Adding Payment..." : "Add Payment"}
						</button>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	function _renderConditionalCheckPayment() {
		if (checkPayment.isEnabled) {
			if (checkPayment.isAllowed) {
				return _renderPaymentDetails();
			} else {
				return <div>Booking is either canceled or all cleared</div>;
			}
		} else {
			return (
				<div className="check-button" onClick={handleCheckBalance}>
					<b>Check Booking Payment</b>
					<i className="fas fa-arrow-right"></i>
				</div>
			);
		}
	}

	// function _renderConditionalViewPaymentsList() {
	// 	if (companyRole === "viewer") {
	// 		return _renderConditionalCheckPayment();
	// 	} else {
	// 		return (
	// 			<>
	// 				{viewPaymentsList ? (
	// 					<div>list</div>
	// 				) : (
	// 					<div
	// 						className="check-button"
	// 						onClick={hanldeViewPaymentsList}
	// 					>
	// 						<b>View Booking Payments</b>
	// 						<i className="fas fa-arrow-right"></i>
	// 					</div>
	// 				)}
	// 				{_renderPaymentDetails()}
	// 			</>
	// 		);
	// 	}
	// }
	function _renderConditionalViewPaymentsList() {
		if (viewPaymentsList) {
			return (
				<div className="payment-list-wrapper">
					<div className="section-header">Booking Payment List</div>
					{isLoading ? (
						<div className="details-message">Loading...</div>
					) : (
						<div className="payment-list-container">
							{Array.isArray(paymentHistoryList) &&
								paymentHistoryList.length > 0 &&
								paymentHistoryList.map((payment, index) => (
									<div
										key={index}
										className="payment-item-container"
									>
										<div className="payment-list-item bodered rounded-sm relative">
											<div className="mainText">
												{payment.transaction_type}
											</div>
											<div className="subText">
												Method: {payment.payment_type}
											</div>
											<div className="subText">
												Date:{" "}
												{moment(
													payment.createdAt
												).format("DD MMM YYYY")}
											</div>
											<div className="subText">
												Payment ID: #{" "}
												{payment.payment_id}
											</div>
											<div className="highlightText">
												Rs. <b>{payment.paid_amount}</b>
											</div>
										</div>
									</div>
								))}
						</div>
					)}
				</div>
			);
		} else {
			return _renderPaymentForm();
		}
	}

	return (
		<div className="booking-payment-details-container">
			{(modalFrom !== "bookingHistory" || companyRole === "viewer") &&
				_renderConditionalCheckPayment()}

			{modalFrom === "bookingHistory" &&
				companyRole !== "viewer" &&
				_renderPaymentDetails()}

			{modalFrom === "bookingHistory" && companyRole !== "viewer"
				? _renderConditionalViewPaymentsList()
				: _renderPaymentForm()}

			{modalFrom === "bookingHistory" &&
				companyRole !== "viewer" &&
				booking?.totalPayment > 0 && (
					<div
						className="check-button"
						onClick={hanldeViewPaymentsList}
					>
						{viewPaymentsList ? (
							<b>Add Booking Payment</b>
						) : (
							<b>View Booking Payments</b>
						)}
						<i className="fas fa-arrow-right"></i>
					</div>
				)}
		</div>
	);
}

import React, { useState, useEffect } from "react";
import "./add-menu-services.style.scss";

import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/loader.component";

import {
	fetchSegregatedMenuItem,
	fetchServiceItem,
	fetchDrinkItem,
} from "../../redux/actions/menu-service-settings.action";
import {
	getSingleBookedEvent,
	updateBooking,
} from "../../redux/actions/booking-action";
import { useDispatch, useSelector } from "react-redux";
import strings from "../../global/strings";

import AddServices from "./add-services.component";
import AddDrinks from "./add-drinks.component";

import ErrorPage from "../extra-page/extra.page";
import { setErrorTrue } from "../../redux/actions/support-action";
import AddBookingMenu from "./add-booking-menu.component";

function AddMenuService(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const menuService = useSelector((state) => state.menuServiceSetting);
	const { bookingData, isLoading } = useSelector((state) => state.booking);
	const { error } = useSelector((state) => state.support);

	const [addFormType, setAddFormType] = useState("");
	const [eventId, setEventId] = useState("");
	const [itemTypeFilterList, setItemTypeFilterList] = useState([]);
	const [cartilageFilter, setCartilageFilter] = useState("");
	const [menuItem, setMenuItem] = useState("");
	const [menuGroup, setMenuGroup] = useState("regular");

	useEffect(() => {
		const fetchMenuServiceDrink = async () => {
			switch (props.location.state.type) {
				case "menu":
					await dispatch(fetchSegregatedMenuItem());
					break;
				case "service":
					await dispatch(fetchServiceItem());
					break;
				default:
					await dispatch(fetchDrinkItem());
					break;
			}
		};

		const getBookingDetails = async () => {
			let bookingInfoId = props.location.state.bookingInfoId;
			await dispatch(getSingleBookedEvent(bookingInfoId));
		};
		try {
			setEventId(props.location.state.eventId);
			fetchMenuServiceDrink();
			getBookingDetails();
			setAddFormType(props.location.state.type);
		} catch (e) {
			dispatch(setErrorTrue(e.message));
		}
	}, [props, dispatch]);

	const _saveOnClick = async (data, type) => {
		let booking = { ...bookingData, ...data };

		if (
			booking["booking_desserts"].length > 0 ||
			booking["booking_services"].length > 0 ||
			booking["booking_snacks"].length > 0
		) {
			if (booking["booking_status"] === "inquiry") {
				booking["booking_status"] = "process";
			} else if (booking["booking_status"] === "booked") {
				booking["booking_status"] = "confirmed";
			}
		} else {
			// booking["booking_status"] = "inquiry";
			if (booking["booking_status"] === "confirmed") {
				booking["booking_status"] = "booked";
			} else if (booking["booking_status"] === "process") {
				booking["booking_status"] = "inquiry";
			}
		}
		/* deleting booking halls as we do not update halls from this page */
		delete booking["booking_halls"];
		// new code added by sanat to remove other except which was updated
		switch (type) {
			case "menu":
				delete booking.booking_drinks;
				delete booking.booking_services;
				break;
			case "service":
				delete booking.booking_snacks;
				delete booking.booking_desserts;
				delete booking.booking_drinks;
				break;
			case "drink":
				delete booking.booking_snacks;
				delete booking.booking_desserts;
				delete booking.booking_services;
				break;
		}

		dispatch(updateBooking(booking, "addMenuService", eventId));
	};

	/* New Menu Control added
	const toggleNew = (rowId) => {
		const shownState = itemTypeFilterList.slice();
		const index = shownState.indexOf(rowId);

		if (index >= 0) {
			shownState.splice(index, 1);
			setItemTypeFilterList(shownState);
		} else {
			shownState.push(rowId);
			setItemTypeFilterList(shownState);
		}
	};

	async function _handleFilter() {
		// if (cartilageFilter === "BL") {
			await dispatch(
				fetchSegregatedMenuItem(
					itemTypeFilterList,
					// cartilageFilter,
					menuGroup,
					menuItem
				)
			);
		} else {
			await dispatch(
				fetchSegregatedMenuItem(
					itemTypeFilterList,
					"",
					menuGroup,
					menuItem
				)
			);
		}
	}

	async function clearFilter() {
		setItemTypeFilterList([]);
		// setCartilageFilter("");
		setMenuItem("");
		await dispatch(fetchSegregatedMenuItem([], "", menuGroup, ""));
	}*/

	async function _changeMenuGroup(group) {
		setMenuGroup(group);
		await dispatch(
			fetchSegregatedMenuItem(
				itemTypeFilterList,
				cartilageFilter,
				group,
				menuItem
			)
		);
	}

	return (
		<div className="main-container">
			{error && <ErrorPage type="error" />}
			{menuService.isLoading && <Loader />}
			{isLoading && <Loader />}
			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => history.goBack()}
						className="previous-page-path"
					>
						Booking Details
					</span>{" "}
					&gt;{" "}
					<span className="current-page-path">
						{`Add ${addFormType}s`}
					</span>
				</div>
			</div>
			{addFormType === "menu" && (
				<div className="inner-container full bg-default menuset-container">
					<label htmlFor="" className="text-sm">
						Menu Set Select
					</label>
					<div className="flex flex-row flex-wrap gap-20px">
						{strings.menuGroup.map((menu) => {
							return (
								<div
									className={`menuset-button ${
										menuGroup === menu.id
											? "bodered bg-buttonSuccess text-fontWhite"
											: "bg-default "
									}`}
									key={menu.id}
									onClick={() => _changeMenuGroup(menu.id)}
								>
									{menu.value}
								</div>
							);
						})}
					</div>
				</div>
			)}

			{addFormType === "menu" &&
				Object.keys(menuService?.menus).length && (
					<AddBookingMenu
						onClickSave={_saveOnClick}
						menuList={menuService.menus}
						menuGroup={menuGroup}
					/>
				)}
			{addFormType === "service" &&
				Object.keys(menuService).length &&
				menuService?.services.length && (
					<AddServices
						onClickSave={_saveOnClick}
						serviceList={menuService}
					/>
				)}
			{addFormType === "drink" &&
				Object.keys(menuService).length &&
				menuService?.drinks.length && (
					<AddDrinks
						onClickSave={_saveOnClick}
						drinkList={menuService}
					/>
				)}
		</div>
	);
}
export default AddMenuService;

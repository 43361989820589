import React, { forwardRef } from "react";
import "./text-input.style.scss";

function FormTextAreaInput(props, ref) {
	return (
		<div className={`form-input-wrapper ${props.variant}`}>
			<label htmlFor={`${props.label}`} className="form-input-label">
				{props.label}
			</label>

			<textarea
				{...props}
				ref={ref}
				className={`${props.variant} form-input`}
				id={`${props.name}`}
				rows={20}
				cols={100}
				maxLength={230}
			/>
			{props.variant === "form-notes" && (
				<span className="form-validation-error">{props.error}</span>
			)}
		</div>
	);
}
export default forwardRef(FormTextAreaInput);

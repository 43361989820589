import React, { useMemo } from "react";
import "./analytics-summary-text.style.scss";

// global functions
import { currencyFormat } from "../../../global/function";

function AnalyticsSummaryText({ label, data, changeData, type }) {
	const summaryDesign = useMemo(() => {
		if (type === "booking") {
			return {
				icon: <i className="fas fa-book"></i>,
				color: "#3498db",
			};
		} else if (type === "vat") {
			return {
				icon: <i className="fas fa-landmark"></i>,
				color: "#523563",
			};
		} else if (type === "collection") {
			return {
				icon: <i className="fas fa-dollar-sign"></i>,
				color: "#52be7f",
			};
		} else if (type === "pending") {
			return {
				icon: <i className="fas fa-search-dollar"></i>,
				color: "#FF8774",
			};
		} else if (type === "revenue") {
			return {
				icon: <i className="fas fa-chart-line"></i>,
				color: "#3498db",
			};
		} else {
			return {};
		}
	}, [type]);

	const percentChange = useMemo(() => {
		if (changeData) {
			let percent = changeData * 100;
			if (percent > 0) {
				return {
					color: "#46BE46",
					value: percent,
					type: "increase",
				};
			} else if (percent < 0) {
				return {
					color: "#B53E2C",
					value: Math.abs(percent),
					type: "decrease",
				};
			} else {
				return {
					color: "#3498db",
					value: percent,
					type: "stagnant",
				};
			}
		} else {
			return null;
		}
	}, [changeData]);

	return (
		<div className="ana-summary-container">
			<div
				className="summary-icon-container"
				style={{ backgroundColor: summaryDesign?.color }}
			>
				{summaryDesign?.icon}
			</div>
			<div className="summary-details">
				<div className="summary-main-value">
					{type === "booking" ? (
						<>{data}</>
					) : (
						<>
							<span>Rs. </span> {currencyFormat(data)}
						</>
					)}
				</div>
				<div className="summary-main-label">{label}</div>
				{percentChange ? (
					<div
						className="summary-change-data"
						style={{
							color: percentChange?.color,
						}}
					>
						{percentChange?.type === "increase" && (
							<i className="fas fa-arrow-circle-up"></i>
						)}
						{percentChange?.type === "decrease" && (
							<i className="fas fa-arrow-circle-down"></i>
						)}
						<span>{percentChange?.value?.toFixed(2)}%</span>
					</div>
				) : (
					<div
						className="summary-change-data"
						style={{
							visibility: "hidden",
						}}
					>
						0
					</div>
				)}
			</div>
		</div>
	);
}

export default AnalyticsSummaryText;

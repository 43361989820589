import React, { useEffect } from "react";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import { useSelector, useDispatch } from "react-redux";
import {
	fetchCategoriesByLocationId,
	updateCategories,
} from "../../redux/actions/inventory.action";
import { useHistory } from "react-router-dom";
import showToastMessage from "../../global/showToastMessage";

const itemSchema = yup.object().shape({
	category_set: yup.array().of(
		yup.object().shape({
			name: yup.string().required("required"),
			other_details: yup
				.object()
				.shape({ description: yup.string().required("required") }),
		})
	),
});

function UpdateInventoryCategory() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { categories } = useSelector((s) => s.inventory);

	const { register, errors, control, handleSubmit, reset } = useForm({
		defaultValues: {},
		mode: "onSubmit",
		resolver: yupResolver(itemSchema),
	});

	const { fields } = useFieldArray({
		control,
		name: "category_set",
	});

	const _updateCategories = async (data) => {
		if (localStorage.getItem("location_id") === null) {
			showToastMessage(
				"Venue not selected. Please return to inventory page and select venue",
				"W"
			);
			return;
		}

		// data["inventories"] = [];
		data["location_id"] = localStorage.getItem("location_id");

		let status = await dispatch(updateCategories(data));
		if (status) {
			_back();
		}
	};

	useEffect(() => {
		dispatch(
			fetchCategoriesByLocationId(localStorage.getItem("location_id"))
		);
	}, [dispatch]);

	useEffect(() => {
		reset({ category_set: categories });
	}, [categories, reset]);

	const _back = () => {
		history.push({
			pathname: "/inventory",
			state: {
				tab: 1,
			},
		});
	};

	return (
		<div className="main-container">
			<div className="profile-navigation-container">
				<div className="profile-path">
					<span
						onClick={() => _back()}
						className="previous-page-path"
					>
						Inventory
					</span>{" "}
					&gt;{" "}
					<span className="current-page-path">
						Update Bulk Category
					</span>
				</div>
			</div>
			<div className="inner-container full mt-30px">
				<table className="table-container">
					<thead>
						<tr>
							<td className="table-header">
								<div className="table-header-container">
									Category Name
								</div>
							</td>
							<td className="table-header ">
								<div className="table-header-container">
									Category Description
								</div>
							</td>
							<td className="hidden" style={{ width: "4%" }}></td>
						</tr>
					</thead>
					<tbody>
						{fields.map((item, i) => (
							<tr key={item.id}>
								<td>
									<FormTextInput
										type="text"
										placeholder="Category Name"
										variant="form-lg"
										name={`category_set[${i}].name`}
										ref={register()}
										error={
											errors?.category_set?.[i]?.name
												?.message
										}
										defaultValue={item.name}
									/>
								</td>
								<td>
									<FormTextInput
										placeholder="Category Description"
										variant="form-lg"
										type="text"
										name={`category_set[${i}].other_details.description`}
										ref={register()}
										error={
											errors?.category_set?.[i]
												?.other_details?.description
												?.message
										}
										defaultValue={
											item.other_details.description
										}
									/>
								</td>

								<td className="hidden">
									<FormTextInput
										placeholder="Category Description"
										variant="form-lg"
										type="text"
										name={`category_set[${i}]._id`}
										ref={register()}
										defaultValue={item._id}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="inner-container button">
				<button
					className="button-primary"
					onClick={handleSubmit(_updateCategories)}
				>
					Update
				</button>
			</div>
		</div>
	);
}

export default UpdateInventoryCategory;

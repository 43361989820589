import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyNewEmployee } from "../../redux/actions/email-verification.action";
import {
	getUserData,
	updateInfo,
} from "../../redux/actions/complete-registration.action";
import strings from "../../global/strings";
//form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSelectBox from "../../components/common/select-box/form-select-input.component";
import FormTextInput from "../../components/common/text-input/form-text-input.component";
import { useHistory } from "react-router-dom";

import Loader from "../../components/loader/loader.component";
import { isEmptyObject, sessionInfo } from "../../global/function";
import { Link } from "react-router-dom";

const userSchema = yup.object().shape({
	first_name: yup
		.string()
		.required("first name required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	last_name: yup
		.string()
		.required("last name required")
		.matches(/^([^0-9]*)$/, "No numbers allowed"),
	user_phone: yup
		.number()
		.typeError("Must be a number")
		.transform((value) => (value ? value : 0)),
	user_mobile: yup
		.number()
		.typeError("must be number")
		.required("mobile number is required")
		.positive("Must be greater than zero"),
	user_email: yup
		.string()
		.required("required")
		.email("Please enter a valid email"),
	user_dob: yup.string().required("date of birth required"),
	user_country: yup.string().required("Country required"),
	user_city: yup.string().required("City required"),
	user_gender: yup.string().required("gender required"),
	user_address: yup.string().required("address required"),
	password: yup
		.string()
		.required("required")
		.min(8, "Min characters should be 8"),
	confirm_password: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("required"),
	// for identification
	// identification_type: yup.string(),
	// identification_number: yup
	// 	.number()
	// 	.typeError("Must be a number")
	// 	.positive("Must be greater than zero")
	// 	.transform((value) => (isNaN(value) ? undefined : value))
	// 	.when("identification_type", {
	// 		is: (value) => value !== "",
	// 		then: yup
	// 			.number()
	// 			.required()
	// 			.typeError("Must be a number")
	// 			.positive("Must be greater than zero")
	// 			.transform((value) => (isNaN(value) ? undefined : value)),
	// 	}),
	// pan_number: yup
	// 	.number()
	// 	.typeError("Must be a number")
	// 	.positive("Must be greater than zero")
	// 	.transform((value) => (isNaN(value) ? undefined : value)),
});

export default function EmailVerification(props) {
	const dispatch = useDispatch();
	const emailVerification = useSelector((state) => state.emailVerification);
	const userDetails = useSelector((state) => state.completeRegistration);
	const history = useHistory();

	const { register, handleSubmit, errors, reset } = useForm({
		defaultValues: {
			user_gender: "",
			identification_type: "",
		},
		mode: "onSubmit",
		resolver: yupResolver(userSchema),
	});
	const [companyName, setCompanyName] = useState("");
	const [processStep, setProcessStep] = useState(1);
	const [venueList, setVenueList] = useState([]);
	const [errorMessage, setErrorMessage] = useState();

	useEffect(() => {
		const { token, email } = props.match.params;
		const { location } = props;

		const verify = async () => {
			let verification = await dispatch(verifyNewEmployee(token, email));

			if (
				typeof verification === "object" &&
				verification !== undefined
			) {
				getUserInfo();
			} else {
				setErrorMessage(verification);
			}
			// getUserInfo();
		};

		const getUserInfo = async () => {
			let userInfo = await dispatch(getUserData());

			reset({
				first_name: userInfo.first_name,
				last_name: userInfo.last_name,
				user_mobile: userInfo.user_mobile,
				user_phone: userInfo.user_phone,
				user_email: userInfo.user_email,
				user_dob: userInfo.user_dob,
				user_gender: userInfo.user_gender,
				user_city: userInfo.user_city,
				user_country: userInfo.user_country,
				user_address: userInfo.user_address,
				pan_number: userInfo.company_employees[0].pan_number,
			});
			setCompanyName(
				userInfo.company_employees[0].company_info.company_name
			);
			setVenueList(userInfo.company_employees[0].venue_employees);
		};

		if (isEmptyObject(location.state)) {
			verify();
		} else {
			getUserInfo();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const returnUser = async (data) => {
		const sessionData = await sessionInfo();

		if (data.identification_type !== "") {
			data = {
				...data,
				user_info_id: sessionData.user_info_id,
				company_employee_id: sessionData.company_employee_id,
				identification: {
					identification_type: data.identification_type,
					identification_number: data.identification_number,
					pan_number: data.pan_number || null,
				},
			};
		} else {
			data = {
				...data,
				user_info_id: sessionData.user_info_id,
				company_employee_id: sessionData.company_employee_id,
				identification: {
					// identification_type: data.identification_type,
					// identification_number: data.identification_number,
					pan_number: data.pan_number || null,
				},
			};
		}
		data["user_status"] = "active";
		data["user_verified"] = true;
		delete data["confirm_password"];
		delete data["identification_number"];
		delete data["identification_type"];
		delete data["pan_number"];
		let updateStatus = await dispatch(
			updateInfo(strings.USERINFO_UPDATE_URL, data)
		);
		if (updateStatus) {
			history.push({ pathname: "/dashboard" });
		}
	};

	function errorView() {
		return (
			<div className="bg-default form-main-content">
				<div className="form-notice-title">
					{strings.emailVerifiedError.title}
				</div>

				<div className="form-notice-message">{errorMessage}</div>
				<div className="form-notice-controller two">
					<Link to="/">
						<button className="button-primary">Sign In</button>
					</Link>
				</div>
			</div>
		);
	}

	function successView() {
		return (
			<div className="bg-default form-main-content">
				<div className="form-notice-title">Congratulations!</div>
				<div className="form-notice-sub-title">
					Welcome to {companyName}
				</div>

				<div className="form-notice-message">
					You have been invited to access CaterMe:
				</div>
				{venueList.map((v) => (
					<div
						className="text-capitalize"
						style={{
							height: "40px",
							fontSize: "14px",
							textAlign: "center",
							marginTop: "8px",
						}}
					>
						{v.venue_info.venue_name}
					</div>
				))}
				<div className="form-notice-controller two">
					<button
						className="button-primary"
						onClick={() => setProcessStep(2)}
					>
						Procceed
					</button>
				</div>
			</div>
		);
	}

	function formView() {
		return (
			<form onSubmit={handleSubmit(returnUser)}>
				<div className="form-main-content bg-default">
					<div className="form-header">
						<div className="form-sub-title"></div>
					</div>

					<div className="form-body">
						<div className="form-input-container ">
							<div className="form-input-container inner">
								<FormTextInput
									type="text"
									placeholder="First Name"
									label="First Name*"
									variant="form-md"
									name="first_name"
									ref={register}
									error={errors?.first_name?.message}
								/>

								<FormTextInput
									label="Last Name*"
									placeholder="Enter Last Name"
									variant="form-md"
									type="text"
									name="last_name"
									ref={register}
									error={errors?.last_name?.message}
								/>
							</div>
							<div className="form-input-container inner">
								<FormTextInput
									label="DOB*"
									placeholder="(DD/MM/YYYY)"
									variant="form-md"
									type="date"
									name="user_dob"
									ref={register}
									error={errors?.user_dob?.message}
								/>

								<FormSelectBox
									label="Gender*"
									variant="form-sm"
									options={strings.gender}
									name="user_gender"
									ref={register}
									type="Gender"
									error={errors?.user_gender?.message}
								/>
							</div>
						</div>
						<div className="form-input-container">
							<FormTextInput
								label="Email*"
								placeholder="Enter Email Address"
								variant="form-lg"
								type="email"
								name="user_email"
								ref={register}
								error={errors?.user_email?.message}
							/>

							<div className="form-input-container inner">
								<FormTextInput
									label="Mobile No. *"
									placeholder="Mobile Number"
									variant="form-md"
									type="number"
									name="user_mobile"
									ref={register}
									error={errors?.user_mobile?.message}
								/>

								<FormTextInput
									label="Phone No. "
									placeholder="Phone Number"
									variant="form-md"
									type="number"
									name="user_phone"
									ref={register}
									error={errors?.user_phone?.message}
								/>
							</div>
						</div>
						<div className="form-input-container">
							<FormTextInput
								label="Password*"
								placeholder="Enter Password"
								variant="form-lg"
								type="password"
								name="password"
								ref={register}
								error={errors?.password?.message}
							/>
							<FormTextInput
								placeholder="Confirm Password*"
								label="Confirm Password"
								variant="form-lg"
								type="password"
								name="confirm_password"
								ref={register}
								error={errors?.confirm_password?.message}
							/>
						</div>

						<div className="form-input-container address">
							<FormTextInput
								label="Address*"
								placeholder="Address"
								variant="form-lg"
								type="text"
								name="user_address"
								ref={register}
								error={errors?.user_address?.message}
							/>
							<div className="form-input-container inner">
								<FormSelectBox
									label="Country*"
									variant="form-sm"
									options={strings.country}
									name="user_country"
									ref={register}
									type="Country"
									error={errors?.user_country?.message}
								/>

								<FormSelectBox
									label="City*"
									variant="form-sm"
									options={strings.city}
									name="user_city"
									ref={register}
									type="City"
									error={errors?.user_city?.message}
								/>
							</div>
						</div>

						{/* <div className="form-input-container">
							<div className="form-input-container inner">
								<FormSelectBox
									label="Identification Type"
									variant="form-sm"
									options={strings.identification}
									name="identification_type"
									ref={register}
									type="ID Type"
									error={errors?.identification_type?.message}
								/>

								<FormTextInput
									label="ID Number"
									placeholder="Identification Number"
									variant="form-md"
									name="identification_number"
									type="text"
									ref={register}
									error={
										errors?.identification_number?.message
									}
								/>
							</div>
							<FormTextInput
								label="PAN Number"
								placeholder="PAN Number"
								variant="form-lg"
								name="pan_number"
								ref={register}
								error={errors?.pan_number?.message}
							/>
						</div> */}
					</div>
					<div className="form-footer one">
						<button
							onClick={handleSubmit(returnUser)}
							className="button-primary"
						>
							Complete
						</button>
					</div>
				</div>
			</form>
		);
	}
	return (
		<div className="signin-page-wrapper">
			{userDetails.isLoading && <Loader />}
			{emailVerification.isLoading && <Loader />}
			<div className="signup-main-container">
				<div className="company-title">CATER ME</div>
				{emailVerification.success === false
					? errorView()
					: processStep === 1
					? successView()
					: formView()}
			</div>
		</div>
	);
}

import React from "react";
import "./switch-customer.modal.scss";
import Modal from "react-modal";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";

export default function PaymentSuccessModal(props) {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			// marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
	};

	const vatAction = () => {
		return (
			<div className="modal-two-button-wrapper">
				<button className="button-primary" onClick={props.onBack}>
					Close
				</button>
			</div>
		);
	};
	const paymentAction = () => {
		return (
			<div className="modal-two-button-wrapper">
				<button className="button-primary" onClick={props.onBack}>
					Close
				</button>
				{/* <button
					className="button-primary"
					onClick={props.printOnClick}
				>
					Print
				</button> */}
			</div>
		);
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="bg-default switch-customer-modal-wrapper">
				<h3>Payment Complete</h3>
				<p className="modal-sub-title">Payment has been Recorded.</p>
				{props.paymentFor === "payment" ? paymentAction() : vatAction()}
			</div>
		</Modal>
	);
}

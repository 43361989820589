import React from "react";
import "./menu-svg.scss";

export default function Lamb({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 20 19.56"
            id="lamb">
            <g>
                <path d="M20,7.54l-3.45-.46-3-.39a4.59,4.59,0,0,0-1.51-.64A4.64,4.64,0,0,0,11,5.91H8.84A4.56,4.56,0,0,0,7.73,6a5,5,0,0,0-1.6.7h0l-2.84.36L0,7.54C.14,9.79,1.82,9.81,4.05,10a3.86,3.86,0,0,0-.08.84v3.89a4.89,4.89,0,0,0,4.87,4.88H11a4.9,4.9,0,0,0,4.88-4.88V10.79a3.86,3.86,0,0,0-.08-.84C18,9.81,19.86,9.78,20,7.54ZM7.49,12.41a1.13,1.13,0,1,1,0-2.25,1.13,1.13,0,0,1,0,2.25Zm5,0a1.13,1.13,0,1,1,1.12-1.13A1.13,1.13,0,0,1,12.52,12.41Z" />
                <path d="M17,4.86a3.08,3.08,0,0,1-.48,1.67l-3-.39a4.59,4.59,0,0,0-1.51-.64A4.64,4.64,0,0,0,11,5.36H8.84a4.56,4.56,0,0,0-1.11.13,5,5,0,0,0-1.6.7h0l-2.84.36a3.08,3.08,0,0,1-.5-1.7A3.19,3.19,0,0,1,6,1.68,3.11,3.11,0,0,1,7,1.86a3.18,3.18,0,0,1,5.78,0,3,3,0,0,1,1-.18A3.18,3.18,0,0,1,17,4.86Z" />
            </g>
        </svg>
    );
}

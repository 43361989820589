import React, { forwardRef } from "react";
import "./checkbox-container.component.css";

const CheckboxContainerComponent = (props, ref) => (
    <label className="checkbox-container">
        {props.label}
        <input
            type="checkbox"
            className={`checkbox-input ${props.variant}`}
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}
            ref={ref}
        />
        <span className={`checkmark ${props.variant}`} />
    </label>
);

export default forwardRef(CheckboxContainerComponent);

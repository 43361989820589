import React from "react";

export default function FoodStall({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512"
			style={style}
		>
			<path d="M624 448h-24.7L359.5 117.8l53.4-73.6c5.2-7.2 3.6-17.2-3.5-22.4l-25.9-18.8c-7.2-5.2-17.2-3.6-22.4 3.6L320 63.3 278.8 6.6c-5.2-7.2-15.2-8.7-22.4-3.6l-25.9 18.8c-7.2 5.2-8.7 15.2-3.5 22.4l53.4 73.6L40.7 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h608c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM320 288l116.4 160H203.6L320 288z" />
		</svg>
	);
}

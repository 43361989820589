/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import strings from "../../global/strings";
import Modal from "react-modal";
import VenueColorSelector from "../common/checkbox-input/venue-color/venue-color.selector";
import { addVenue } from "../../redux/actions/company-setting.action";
// form set up
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextInput from "../common/text-input/form-text-input.component";
import FormSelectBox from "../common/select-box/form-select-input.component";
import { useDispatch } from "react-redux";
import { sessionInfo } from "../../global/function";

const venueSchema = yup.object().shape({
	venue_name: yup.string().required("required"),
	venue_address: yup.string().required("required"),
	venue_city: yup.string().required("required"),
	venue_country: yup.string().required("required"),
	no_of_halls: yup.string().required("required"),
	venue_phone: yup
		.number()
		.typeError("Must be a number")
		.required("required"),
});

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
	},
	h2: {
		color: "red",
	},
};

export default function AddVenueModal(props) {
	const [venueNum, setVenueNum] = useState(0);
	const dispatch = useDispatch();

	const [toggleVenueColor, setToggleVenueColor] = useState(false); // to toggle venue color option
	const [selectedVenueColor, setSelectedVenueColor] = useState("");

	const { register, errors, handleSubmit, reset, watch } = useForm({
		defaultValues: { venue_color_code: "#CD78E2" },
		mode: "onSubmit",
		resolver: yupResolver(venueSchema),
	});
	let venueColor = watch("venue_color_code");

	useEffect(() => {
		if (props.venueInfo !== undefined) {
			setVenueNum(props.venueInfo.length);
		}
	}, [props]);

	async function _returnVenueInfo(venueInfo) {
		let sessionData = await sessionInfo();

		venueInfo = {
			...venueInfo,
			venue_color_code: selectedVenueColor
				? selectedVenueColor
				: "#CD78E2",
			company_info_id: sessionData.company_info_id,
		};

		props.handleClose();
		dispatch(addVenue(venueInfo));
	}

	//sanat guarentee
	const toggle = (val) => {
		if (val) {
			setSelectedVenueColor("");
		} else {
			setSelectedVenueColor(watch("venue_color_code"));
		}
		setToggleVenueColor(val);
	};
	const returnAddVenueView = () => {
		return (
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">Add Venue</div>
				</div>
				<div className="form-body">
					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Venue Name*"
							label="Venue Name*"
							name="venue_name"
							ref={register}
							error={errors?.venue_name?.message}
						/>
						<FormTextInput
							variant="form-lg"
							type="number"
							placeholder="Venue Contact*"
							label="Venue Primary Contact*"
							name="venue_phone"
							ref={register}
							error={errors?.venue_phone?.message}
						/>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<VenueColorSelector
								color={
									selectedVenueColor !== ""
										? selectedVenueColor
										: venueColor
								}
								ref={register}
								name="venue_color_code"
								variant="registration"
								toggleFlag={toggleVenueColor}
								toggle={toggle}
							/>
							<FormSelectBox
								label="Number of Halls*"
								variant="form-sm"
								options={strings.halls}
								name="no_of_halls"
								ref={register}
								type="Halls"
								error={errors?.no_of_halls?.message}
							/>
						</div>
						<FormTextInput
							variant="form-lg"
							type="email"
							placeholder="Enter Venue Email"
							label="Venue Email"
							name="venue_email"
							ref={register}
							error={errors?.venue_email?.message}
						/>
					</div>

					<div className="form-input-container">
						<FormTextInput
							variant="form-lg"
							type="text"
							placeholder="Venue address*"
							label="Venue address*"
							name="venue_address"
							ref={register}
							error={errors?.venue_address?.message}
						/>
						<FormTextInput
							variant="form-lg"
							placeholder="Venue Website"
							label="Venue Website"
							name="venue_website"
							ref={register}
							error={errors?.venue_website?.message}
						/>
					</div>
					<div className="form-input-container">
						<div className="form-input-container inner">
							<FormSelectBox
								variant="form-sm"
								label="City*"
								options={strings.city}
								name="venue_city"
								ref={register}
								type="Country"
								error={errors?.venue_city?.message}
							/>
							<FormSelectBox
								variant="form-sm"
								label="Country*"
								options={strings.country}
								name="venue_country"
								ref={register}
								type="Country"
								error={errors?.venue_country?.message}
							/>
						</div>
						<FormTextInput
							variant="form-lg"
							placeholder="Social Media*"
							label="Social Media"
							name="social_media"
							ref={register}
							error={errors?.social_media?.message}
						/>
					</div>
				</div>
				<div className="form-footer two">
					<button
						onClick={() => {
							setToggleVenueColor(false);
							setSelectedVenueColor("");
							reset({});
							props.handleClose();
						}}
						className="button-primary"
					>
						Cancel
					</button>
					<button
						className="button-primary"
						onClick={handleSubmit(_returnVenueInfo)}
					>
						Add
					</button>
				</div>
			</div>
		);
	};

	const returnAddVenueConformation = () => {
		return (
			<div className="main-modal-wrapper bg-default">
				<div className="modal-title ">Add Venue</div>
				<div className="modal-message ">
					You currently have {venueNum} active Venues. Contact us to
					add more than 3 Venues.
				</div>

				<div className="modal-two-button-wrapper">
					<button
						className="button-primary"
						onClick={props.handleClose}
					>
						Cancel
					</button>
					<a href="mailto:nibjarcater@gmail.com">
						<button className="button-primary">Contact Us</button>
					</a>
				</div>
			</div>
		);
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			{venueNum < 3 ? returnAddVenueView() : returnAddVenueConformation()}
		</Modal>
	);
}

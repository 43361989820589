import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "./drink-item-container.style.scss";

import SimpleTextInput from "../../common/text-input/simple-text.component";
import SelectBox from "../../common/select-box/select-box.component";

import strings from "../../../global/strings";
import { drinkTypeMapper } from "../../../assets/images/icons/iconList/icon-distributer.map";

export default function DrinkItemContainer(props) {
	const drinkInfoSchema = yup.object().shape({
		drinkUnit: yup.string().required(),
		drinkPrice: yup
			.number()
			.typeError("Must be Number")
			.moreThan(-1, "Must Be Positive")
			.default(props.item.drink_price),
		drinkQty: yup
			.number()
			.typeError("Must be Number")
			.moreThan(-1, "Must Be Positive")
			.required(),
		drinkSize: yup
			.number()
			.typeError("Must be Number")
			.moreThan(-1, "Must Be Positive")
			.required(),
	});

	const [drinkDetails, setDrinkDetails] = useState({});
	const [drinkName, setDrinkName] = useState("");
	const [drinkType, setDrinkType] = useState("name");
	const [drinkInfo, setDrinkInfo] = useState({});

	const [editState, setEditState] = useState(true);
	const [override, setOverride] = useState(false);

	const { register, handleSubmit } = useForm({
		mode: "onSubmit",
		defaultValues: {
			drinkPrice: props.item?.hasOwnProperty("booking_drink_price")
				? props.item?.booking_drink_price
				: props.item.drink_price,
			drinkUnit: props.item?.boking_drink_unit || props.item.drink_unit,
			drinkQty: props.item?.hasOwnProperty("booking_drink_qty")
				? props.item?.booking_drink_qty
				: props.item.drink_qty,
			drinkSize: props.item?.hasOwnProperty("booking_drink_size")
				? props.item?.booking_drink_size
				: props.item.drink_size,
		},
		resolver: yupResolver(drinkInfoSchema),
	});

	useEffect(() => {
		setDrinkDetails(props.item);
		setDrinkName(props.item.drink_item);
		setDrinkType(props.item.drink_type);
		setDrinkInfo({
			drinkPrice: props.item?.hasOwnProperty("booking_drink_price")
				? props.item?.booking_drink_price
				: props.item.drink_price,
			drinkUnit: props.item?.boking_drink_unit || props.item.drink_unit,
			drinkQty: props.item?.hasOwnProperty("booking_drink_qty")
				? props.item?.booking_drink_qty
				: props.item.drink_qty,
			drinkSize: props.item?.hasOwnProperty("booking_drink_size")
				? props.item?.booking_drink_size
				: props.item.drink_size,
		});
		props.item.drink
			? setOverride(props.item.drink.override)
			: setOverride(props.item.override);
	}, [props, drinkDetails]);

	function addClick() {
		props.addOnClick(drinkDetails);
	}

	const _handleSave = (drinkBookingInfo) => {
		setEditState(!editState);
		props.overrideDrink(drinkDetails, drinkBookingInfo);
	};

	const _handleEdit = (e) => {
		e.stopPropagation();
		setEditState(true);
	};

	return (
		<div
			className="drink-item-wrapper bg-default"
			onClick={() => {
				!props.action && addClick();
			}}
		>
			<div className="drink-info-container">
				<div className={`drink-icon bg-default ${props.type}`}>
					{drinkTypeMapper(drinkType)}
				</div>
				<div className="drink-info">
					<div>{drinkName}</div>
					<div className="item-price">
						{props.action &&
						props.type === "selected" &&
						!props.view &&
						override &&
						editState ? (
							<SimpleTextInput
								type="number"
								variant="form-xs edit"
								name="drinkPrice"
								disabled={false}
								ref={register}
							/>
						) : (
							<>
								<div className="text-fontSemiDark text-md">
									{drinkInfo.drinkSize}
									{drinkInfo.drinkUnit} | {drinkInfo.drinkQty}{" "}
									Bottle | Rs.{drinkInfo.drinkPrice}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{props.action && !props.view && (
				<div className="drink-details-container">
					<div className="drink-details-wrapper">
						<div className="drink-fields">
							<SimpleTextInput
								type="number"
								variant="form-xs"
								name="drinkQty"
								placeholder="quantity"
								disabled={!editState}
								ref={register}
							/>
							<SimpleTextInput
								type="number"
								variant="form-xs"
								name="drinkSize"
								placeholder="volume"
								disabled={!editState}
								ref={register}
							/>
							<SelectBox
								variant="form-sm"
								name="drinkUnit"
								options={strings.drinkUnit}
								type="Unit"
								disabled={!editState}
								ref={register}
							/>
						</div>
					</div>
					<div className="drink-action-wrapper">
						{editState ? (
							<div
								className="save-drink bg-default"
								onClick={handleSubmit(_handleSave)}
							>
								Save
							</div>
						) : (
							<div
								className="edit-drink bg-default"
								onClick={(e) => _handleEdit(e)}
							>
								Edit
							</div>
						)}

						<div
							className="delete-drink bg-default"
							onClick={addClick}
						>
							Delete
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

import {
	FETCH_CALENDAR_REQUEST,
	FETCH_CALENDAR_SUCCESS,
	FETCH_MONTHLY_CALENDAR_SUCCESS,
	FETCH_CALENDAR_ERROR,
	SET_CALENDAR_DATE,
	SET_CALENDAR_FILTER,
} from "../action-types";

const initial_state = {
	isLoading: false,
	calendarInfo: [],
	monthlyCalendarBooking: [],
	dateHeader: "",
	tempBookings: [],
	weekStart: "",
	weekEnd: "",
	filters: [],
	currDate: "",
	error: "",
};
export default function dashboardReducer(state = initial_state, action) {
	switch (action.type) {
		case FETCH_CALENDAR_REQUEST:
			return {
				...state,
				isLoading: true,
				error: "",
			};
		case FETCH_CALENDAR_SUCCESS:
			return {
				...state,
				isLoading: false,
				calendarInfo: action.payload,
				dateHeader: action.date,
				error: "",
			};
		case FETCH_MONTHLY_CALENDAR_SUCCESS:
			return {
				...state,
				isLoading: false,
				monthlyCalendarBooking: action.payload,
				error: "",
			};
		case FETCH_CALENDAR_ERROR:
			return {
				...state,
				isLoading: false,
				calendarInfo: [],
				dateHeader: "",
				error: action.payload,
			};
		case SET_CALENDAR_DATE:
			var weekStart = action.payload
				.clone()
				.startOf("week")
				.format("YYYY-MM-DD");
			var weekEnd = action.payload
				.clone()
				.endOf("week")
				.format("YYYY-MM-DD");

			return {
				...state,
				weekStart,
				weekEnd,
				currDate: action.payload,
			};
		case SET_CALENDAR_FILTER:
			return {
				...state,
				filters: action.payload,
			};
		default:
			return state;
	}
}

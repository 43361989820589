/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import SimpleTextInput from "../common/text-input/text-input-with-error.component";
import SelectBox from "../common/select-box/form-select-input.component";
import strings from "../../global/strings";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { fetchVenue } from "../../redux/actions/venue-action";

export default function SearchPaymentHistoryComponent({
	searchPayment,
	resetSearch,
}) {
	const dispatch = useDispatch();

	const { paymentSearchParameters } = useSelector((state) => state.payment);

	const { register, handleSubmit, reset } = useForm();
	const { venueDropDownList } = useSelector((s) => s.venue);

	useEffect(() => {
		dispatch(fetchVenue());
	}, [dispatch]);

	useEffect(() => {
		reset(paymentSearchParameters);
	}, [paymentSearchParameters, reset]);

	const _clearPaymentHistorySearch = () => {
		reset({
			payment_id: "",
			booking_id: "",
			event_id: "",
			date_from: "",
			date_to: "",
			customer_fname: "",
			customer_lname: "",
			payment_type: "",
			transaction_type: "",
			venue_info_id: "",
		});
		resetSearch();
	};

	function _searchPaymentHistory(data) {
		searchPayment(data);
	}

	return (
		<div className="flex flex-col bg-default w-full mt-30px p-15px rounded-sm">
			<label htmlFor="">Search Payment</label>
			<div className="flex my-10px gap-24px flex-wrap">
				<SimpleTextInput
					variant="form-md edit"
					label="Payment ID"
					placeholder="Enter Payment ID"
					type="number"
					name="payment_id"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Enter Booking ID"
					label="Booking ID"
					type="number"
					name="booking_id"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					label="Event ID"
					placeholder="Enter Event ID"
					type="number"
					name="event_id"
					ref={register}
				/>

				<SimpleTextInput
					type="date"
					variant="form-md edit"
					placeholder="Enter Date From"
					label="Date From"
					name="date_from"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					type="date"
					placeholder="Enter Date To"
					label="Date To"
					name="date_to"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Customer First Name"
					label="Customer First Name"
					name="customer_fname"
					ref={register}
				/>
				<SimpleTextInput
					variant="form-md edit"
					placeholder="Customer Last Name"
					label="Customer Last Name"
					name="customer_lname"
					ref={register}
				/>
				<SelectBox
					variant="search"
					options={strings.paymentTypeDropDown}
					name="payment_type"
					ref={register}
					label="Payment Type"
				/>
				<SelectBox
					variant="search"
					options={strings.transactionType}
					name="transaction_type"
					ref={register}
					label="Transaction Type"
				/>
				<SelectBox
					variant="search"
					name="venue_info_id"
					options={venueDropDownList}
					ref={register}
					label="Venue"
				/>
			</div>
			<div className="flex gap-x-15px justify-end">
				<button
					className="button-primary-icon"
					onClick={_clearPaymentHistorySearch}
				>
					<i className="fas fa-eraser"></i>
				</button>
				<button
					className="button-primary-icon"
					onClick={handleSubmit(_searchPaymentHistory)}
				>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	);
}

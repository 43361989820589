import React, { useState, useEffect } from "react";
import DrinkItemContainer from "../../components/add-menu-services/drink-item-container/drink-item-container.component";
import showToastMessage from "../../global/showToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrinkItem } from "../../redux/actions/menu-service-settings.action";
import { isEmptyObject } from "../../global/function";

function AddDrink({ onClickSave, drinkList }) {
	const dispatch = useDispatch();
	const { bookingData } = useSelector((state) => state.booking);
	const [deletedDrinkList, setDeletedDrinkList] = useState([]);
	const [selectedDrinkList, setSelectedDrinkList] = useState([]);
	const [toggleDrinkView, setToggleDrinkView] = useState(true);

	useEffect(() => {
		dispatch(fetchDrinkItem());
	}, [dispatch]);

	useEffect(() => {
		if (!isEmptyObject(bookingData)) {
			setSelectedDrinkList(bookingData.booking_drinks);
			bookingData.booking_drinks.map(
				(drink) => _highlightSelectedInList(drinkList?.drinks, drink) // new code added by sanat
			);
		}
	}, [bookingData, drinkList]);

	const _toggleList = () => {
		setToggleDrinkView(!toggleDrinkView);
	};

	// new code added by sanat for highlighting and unhighlighting
	const _highlightSelectedInList = (array, drinkItem) => {
		array.forEach(
			(item) =>
				item.drink_info_id === drinkItem.drink_info_id &&
				(item.type = "selected")
		);
	};
	const _unhighlightSelectedInList = (array, drinkItem) => {
		array.forEach(
			(item) =>
				item.drink_info_id === drinkItem.drink_info_id &&
				(item.type = "")
		);
	};

	// handling add, remove and override feature for services
	const _handleAddDrink = (drink) => {
		const existingItem = selectedDrinkList.filter(
			(item) => item.drink_info_id === drink.drink_info_id
		);

		drink["drink"] = drink;
		if (existingItem.length === 0) {
			setSelectedDrinkList([...selectedDrinkList, drink]);
			_highlightSelectedInList(drinkList?.drinks, drink); // new code added by sanat
		} else {
			showToastMessage("Drink item already added", "W");
		}
	};
	const _handleRemoveDrink = (drink) => {
		const updatedList = selectedDrinkList.filter(
			(item) => item.drink_info_id !== drink.drink_info_id
		);
		setSelectedDrinkList(updatedList);
		setDeletedDrinkList([...deletedDrinkList, drink.booking_drink_id]);
		_unhighlightSelectedInList(drinkList?.drinks, drink); // new code added by sanat
	};

	const _handleOverrideDrink = (drink, drinkBookingInfo) => {
		const updatedList = selectedDrinkList.map((old) => {
			if (old.drink_info_id === drink.drink_info_id) {
				if (old.hasOwnProperty("booking_drink_price")) {
					old.booking_drink_price = drinkBookingInfo.drinkPrice;
					old.booking_drink_qty = drinkBookingInfo.drinkQty;
					old.booking_drink_size = drinkBookingInfo.drinkSize;
					old.booking_drink_unit = drinkBookingInfo.drinkUnit;
				} else {
					old.drink_price = drinkBookingInfo.drinkPrice;
					old.drink_qty = drinkBookingInfo.drinkQty;
					old.drink_size = drinkBookingInfo.drinkSize;
					old.drink_unit = drinkBookingInfo.drinkUnit;
				}

				return old;
			} else {
				return old;
			}
		});

		setSelectedDrinkList(updatedList);
	};

	const save = () => {
		let drinks = {};

		drinks["booking_drinks"] = selectedDrinkList;
		drinks["delete_booking_drinks"] = deletedDrinkList;

		onClickSave(drinks, "drink");
	};

	return (
		<div className=" mt-56px">
			<div className="inner-container full">
				<label onClick={() => _toggleList()} className="cursor-pointer">
					Drink{"   "}
					<i
						className={`ml-10px fas fa-chevron-${
							toggleDrinkView ? "down" : "up"
						} `}
					></i>
				</label>
				<label className=" text-sm text-fontSemiDark">
					Selected Items
				</label>

				<div className="flex flex-row flex-wrap gap-24px">
					{selectedDrinkList.map((item, index) => (
						<DrinkItemContainer
							action={true}
							item={item}
							key={index}
							type="selected"
							view={false}
							addOnClick={_handleRemoveDrink}
							overrideDrink={_handleOverrideDrink}
						/>
					))}
				</div>
				<label className=" text-sm text-fontSemiDark">All Items</label>
				{toggleDrinkView && (
					<div className="flex flex-row flex-wrap gap-24px cursor-pointer">
						{drinkList?.drinks?.map((item) => (
							<DrinkItemContainer
								action={false}
								type={item.type}
								view={false}
								item={item}
								key={item.drink_info_id}
								addOnClick={_handleAddDrink}
							/>
						))}
					</div>
				)}
			</div>

			<div className="inner-container full mt-10px flex items-end">
				<button
					className="colored-button-wrapper bg-buttonSuccess text-fontWhite"
					onClick={save}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default AddDrink;

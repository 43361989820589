import React, { useEffect, useState } from "react";
import "./paginationReact.style.scss";

import ReactPaginate from "react-paginate";

function PaginationReact({
	totalCount,
	itemsPerPage,
	onPageClick,
	forcePage = 0,
}) {
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		setTotalPages(Math.ceil(totalCount / itemsPerPage));
	}, [totalCount, itemsPerPage]);

	return (
		<ReactPaginate
			breakLabel="..."
			nextLabel="next&nbsp;>"
			onPageChange={onPageClick}
			pageRangeDisplayed={3}
			pageCount={totalPages}
			previousLabel="<&nbsp;prev"
			marginPagesDisplayed={2} // Number of margin pages to display
			containerClassName={"pagination"}
			activeClassName={"active"}
			pageClassName={"pageLink"}
			breakClassName={"pageLink"}
			previousClassName={"navLink"}
			nextClassName={"navLink"}
			forcePage={forcePage}
		/>
	);
}

export default PaginationReact;

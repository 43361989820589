import React, { useState } from "react";
// import strings from "../../global/strings";
import Modal from "react-modal";
import "./add-image.style.scss";
import ImageUpload from "image-upload-react";
import "image-upload-react/dist/index.css";
import { isEmptyObject } from "../../global/function";
import showToastMessage from "../../global/showToastMessage";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0px",
		borderRadius: "10px",
		background:
			"linear-gradient(128deg, var(--backgroung-color), var(--backgroung-color))",
	},
	h2: {
		color: "red",
	},
};

export default function AddImageModal(props) {
	const [imageSrc, setImageSrc] = useState();
	const [imageDetails, setImageDetails] = useState({});

	const handleImageSelect = (e) => {
		setImageSrc(URL.createObjectURL(e.target.files[0]));
		setImageDetails(e.target.files[0]);
	};

	const onSaveClick = () => {
		if (isEmptyObject(imageSrc)) {
			showToastMessage("Image not selected", "E");
		} else {
			props.onSaveClick(imageDetails);
			props.handleClose();
		}
	};
	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal"
			style={customStyles}
		>
			<div className="bg-default add-image-modal-wrapper">
				<div className="add-image-wrapper">
					<div className="add-image-container">
						<ImageUpload
							handleImageSelect={handleImageSelect}
							imageSrc={imageSrc}
							setImageSrc={setImageSrc}
							style={{
								width: 250,
								height: 250,
								// backgroundColor: "#F8F8F8",
								background:
									"linear-gradient(128deg, var(--backgroung-color), var(--backgroung-color))",
								borderRadius: "10px",
							}}
						/>
					</div>
				</div>
				<div className="description-wrapper">
					<div className="description-title text-capitalize">
						{props.type} Image
					</div>
					<div className="description-message">
						Image upload will be resized to 100kb. Updated aspect
						ration of 250 x 250 pixels
					</div>
					<div className="description-button-container">
						<button
							className="button-wrapper add-image-cancel-button"
							onClick={props.handleClose}
						>
							Cancel
						</button>
						<button
							className="colored-button-wrapper add-image-save-button"
							onClick={onSaveClick}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

import React from "react";
import "./menu-svg.scss";

export default function Beef({ style }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`icon-size ${style}`}
			viewBox="0 0 20 16.88"
			id="beef"
		>
			<g>
				<path d="M20,3.06H14.87A14.68,14.68,0,0,0,15.13.34.34.34,0,0,0,14.47.2c-.51,1.18-1.26,2.61-2,2.85H7.53C6.8,2.81,6,1.38,5.53.2a.35.35,0,0,0-.66.14,14.68,14.68,0,0,0,.26,2.72H0A4.5,4.5,0,0,0,4.14,7.27a4.6,4.6,0,0,0-.08.84V12a4.89,4.89,0,0,0,4.88,4.87h2.13A4.88,4.88,0,0,0,15.94,12V8.11a4.6,4.6,0,0,0-.08-.84A4.5,4.5,0,0,0,20,3.06ZM7.26,11.29A1.43,1.43,0,1,1,8.69,9.86,1.43,1.43,0,0,1,7.26,11.29Zm8.09-.88A10.41,10.41,0,0,1,14,14.48c-2.47,2.26-3.37.52-3.37.17A2.29,2.29,0,0,1,11.25,13c.66-.7.45-1.73,0-1.9a2.3,2.3,0,0,1-1.16-1.79,4,4,0,0,1,.84-2,.55.55,0,0,1,.17-.11C12.39,6.63,13.36,5.7,14.8,7a1,1,0,0,1,.22.29A7.46,7.46,0,0,1,15.35,10.41Z" />
				<path d="M14.18,9.83A1.43,1.43,0,1,1,12.75,8.4,1.43,1.43,0,0,1,14.18,9.83Z" />
			</g>
		</svg>
	);
}

import React from "react";
import { rupeeConverter } from "../../global/function";
import "./dataBlock.style.scss";

const DataBlock = (props) => {
	/*props
    - label: Main Lable
    - subLabel: Sub lable
    - size: sm, sm-l, lg default:small wide
    - iconic: True/False to switch component
    - icon: Icon to display
    - type: Number Default or currency
    - onclick: If click functionality
     */
	const resizer = () => {
		let currency = props.data;

		if (props.type === "currency") {
			currency = rupeeConverter(`${props.data}`);
		}

		if (props.size === "sm") {
			return <p className="drop-size">{currency}</p>;
		} else {
			return <p className="data-content">{currency}</p>;
		}
	};

	return (
		<div
			className={`bg-default datablock-wrapper ${
				(props.label.length > 13) & (props.size === "sm")
					? ""
					: props.size
			}`}
			onClick={() => props._showBookings(props.label)}
		>
			<p className="label">{props.label}</p>
			<div className="data-container">
				{props.iconic & (props.size !== "sm") ? (
					<i class="fas fa-address-card "></i>
				) : null}
				<p className="sub">{props.subLabel}</p>
				{resizer()}
			</div>
		</div>
	);
};

export default DataBlock;

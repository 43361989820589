import React from "react";
import "./menu-svg.scss";

export default function Duck({ style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`icon-size ${style}`}
            viewBox="0 0 20 17.35"
            id="duck">
            <g >
                <path d="M16.66,17.3l-5.09.05a6.06,6.06,0,0,1-2.71-.79,1.41,1.41,0,0,1-.74-1l-.18-1.06a1.24,1.24,0,0,0,.44.38,6.14,6.14,0,0,0,2.72.78l5.08-.05A3.36,3.36,0,0,0,19.52,12l.3.9A3.35,3.35,0,0,1,16.66,17.3Z" />
                <path d="M19.32,11A3.35,3.35,0,0,1,16,14.57l-5.08.06a6.19,6.19,0,0,1-2.71-.78,1.4,1.4,0,0,1-.45-.38,1.42,1.42,0,0,1-.29-.65L7,10.39,1.26,9.53A1.26,1.26,0,0,1,0,8.27,1,1,0,0,1,0,8.1,1.26,1.26,0,0,1,1.18,7a11.61,11.61,0,0,0,5.3-1.95,4.9,4.9,0,0,1,1-2.51A5.54,5.54,0,0,1,12.2,0c3.08,0,5.57,3.18,5.57,6.25,0,.15.23,2.25.27,2.35l.56,1.85C18.68,10.71,19.32,11,19.32,11Z" />
            </g>
        </svg>
    );
}

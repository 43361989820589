import React, { useEffect, useState } from "react";
import "./booking-request.style.scss";
import { useHistory, useLocation } from "react-router-dom";
import InventoryItem from "./inventory-item.component";
import { useDispatch, useSelector } from "react-redux";
import { sessionInfo } from "../../global/function";
import { fetchVenue } from "../../redux/actions/venue-action";
import {
	changeAddRequest,
	fetchInventoriesByLocationId,
} from "../../redux/actions/inventory.action";
import { isEmptyObject } from "jquery";
import { addRequest } from "../../redux/actions/request.action";

export default function BookingRequest() {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { inventories, isLoading } = useSelector((s) => s.inventory);
	const req = useSelector((s) => s.request);

	const [locationId, setLocationId] = useState("");
	const [requestedType, setRequestedType] = useState("");
	const [userInfo, setUserInfo] = useState({});
	const [inventory, setInventory] = useState([]);

	useEffect(() => {
		setLocationId(location.state.venueid);
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setUserInfo({
					user_info_id: data.user_info_id,
					first_name: data.first_name,
					last_name: data.last_name,
				});
			});
		}
		fetchSessionData();
		// dispatch(fetchVenue());
		dispatch(fetchInventoriesByLocationId(location.state.venueid));
		// setRequestedType(location.state.type);
	}, [dispatch, location]);

	useEffect(() => {
		if (!isEmptyObject(inventories)) {
			setInventory(Object.entries(inventories));
		}
	}, [inventories]);

	const _handleItemQuantity = (data) => {
		dispatch(changeAddRequest(data.val, data.id, data.cat));
	};
	async function _handleSubmitRequest(reqStatus, reqType) {
		let requestObj = {};
		requestObj.location_id = locationId;
		requestObj.request_status = reqStatus;
		requestObj.request_list = inventories;
		requestObj.request_type = reqType;
		requestObj.requested_by = [
			{
				user_info_id: userInfo.user_info_id,
				name: userInfo.first_name + " " + userInfo.last_name,
			},
		];
		requestObj.due_date = location.state.booking_date;
		requestObj.requested_id = location.state.booking_info_id;

		let status = await dispatch(addRequest(requestObj));
		if (status) {
			_back();
		}
	}
	const _back = () => {
		history.push({
			pathname: "/booking-event",
			search: `event_id=${location.state.event_id}`,
			state: { event_info_id: location.state.event_info_id },
		});
	};

	const _renderItems = (itemList, key) => {
		return (
			<div>
				<p>{key}</p>
				<div className="items-wrapper">
					{itemList.map((item, id) => (
						<InventoryItem
							id={id}
							category={key}
							item={item}
							handleChange={_handleItemQuantity}
						/>
					))}
				</div>
			</div>
		);
	};

	return (
		<div className="main-container">
			<div className="inner-container full test">test is block</div>
			<div className="inner-container full test">
				{inventory.length > 0 &&
					inventory.map((value) => _renderItems(value[1], value[0]))}
			</div>

			<button
				className="colored-button-wrapper bg-buttonSuccess text-whiteText"
				onClick={() => _handleSubmitRequest("requested", "booking")}
			>
				Save
			</button>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import ServiceItemContainer from "../../components/add-menu-services/service-item-container/service-item-container.component";
import showToastMessage from "../../global/showToastMessage";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceItem } from "../../redux/actions/menu-service-settings.action";
import { isEmptyObject } from "../../global/function";

function AddServices({ onClickSave, serviceList }) {
	const dispatch = useDispatch();
	const { bookingData } = useSelector((state) => state.booking);
	const [deletedServiceList, setDeletedServiceList] = useState([]);
	const [selectedServiceList, setSelectedServiceList] = useState([]);
	const [toggleServiceView, setToggleServiceView] = useState(true);

	useEffect(() => {
		dispatch(fetchServiceItem());
	}, [dispatch]);

	useEffect(() => {
		if (!isEmptyObject(bookingData)) {
			setSelectedServiceList(bookingData.booking_services);
			bookingData.booking_services.forEach((service) => {
				_highlightSelectedInList(serviceList?.services, service); // new code added by sanat
			});
		}
	}, [bookingData, serviceList]);

	const _toggleList = () => {
		setToggleServiceView(!toggleServiceView);
	};

	// new code added by sanat for highlighting and unhighlighting
	const _highlightSelectedInList = (array, serviceItem) => {
		array.forEach(
			(item) =>
				item.service_info_id === serviceItem.service_info_id &&
				(item.type = "selected")
		);
	};
	const _unhighlightSelectedInList = (array, serviceItem) => {
		array.forEach(
			(item) =>
				item.service_info_id === serviceItem.service_info_id &&
				(item.type = "")
		);
	};

	// handling add, remove and override feature for services
	const _handleAddService = (service) => {
		const existingItem = selectedServiceList.filter(
			(item) => item.service_info_id === service.service_info_id
		);

		service["service"] = service;

		if (existingItem.length === 0) {
			setSelectedServiceList([...selectedServiceList, service]);
			_highlightSelectedInList(serviceList?.services, service); // new code added by sanat
		} else {
			showToastMessage("Service item already added", "W");
		}
	};
	const _handleRemoveService = (service) => {
		const updatedList = selectedServiceList.filter(
			(item) => item.service_info_id !== service.service_info_id
		);
		setSelectedServiceList(updatedList);
		setDeletedServiceList([
			...deletedServiceList,
			service.booking_service_id,
		]);
		_unhighlightSelectedInList(serviceList?.services, service); // new code added by sanat
	};
	const _handleOverrideService = (service, newServicePrice) => {
		const updatedList = selectedServiceList.map((old) => {
			if (old.service_info_id === service.service_info_id) {
				if (old.hasOwnProperty("booking_service_price")) {
					old.booking_service_price = newServicePrice;
				} else {
					old.service_price = newServicePrice;
				}
				return old;
			} else {
				return old;
			}
		});

		setSelectedServiceList(updatedList);
	};

	const save = () => {
		let services = {};
		services["booking_services"] = selectedServiceList;
		services["delete_booking_services"] = deletedServiceList;
		onClickSave(services, "service");
	};
	return (
		<div className=" mt-56px">
			<div className="inner-container full">
				<label onClick={() => _toggleList()} className="cursor-pointer">
					Service{"   "}
					<i
						className={`ml-10px fas fa-chevron-${
							toggleServiceView ? "down" : "up"
						} `}
					></i>
				</label>
				<label className=" text-sm text-fontSemiDark">
					Selected Items
				</label>

				<div className="flex flex-row flex-wrap gap-24px">
					{selectedServiceList.map((item, index) => (
						<ServiceItemContainer
							service_type={item.service_type_name}
							action={true}
							item={item}
							key={index}
							type="selected"
							view={false}
							addOnClick={_handleRemoveService}
							overrideService={_handleOverrideService}
						/>
					))}
				</div>
				<label className=" text-sm text-fontSemiDark">All Items</label>
				{toggleServiceView && (
					<div className="flex flex-row flex-wrap gap-24px">
						{serviceList?.services?.map((item) => (
							<ServiceItemContainer
								service_type={item.service_type_name}
								type={item.type}
								view={false}
								item={item}
								key={item.service_info_id}
								addOnClick={_handleAddService}
							/>
						))}
					</div>
				)}
			</div>

			<div className="inner-container full mt-10px flex items-end">
				<button
					className="colored-button-wrapper bg-buttonSuccess text-fontWhite"
					onClick={save}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default AddServices;

let API_URL, FILE_UPLOAD_URL, INVENOTRY_API_URL;

const APP = process.env.REACT_APP_ENV;

if (APP === "production") {
	API_URL = "https://api-caterme.nibjar.com/reservation/";
	INVENOTRY_API_URL = "https://api-caterme.nibjar.com/storehouse/";
	FILE_UPLOAD_URL = "https://socket.nibjar.com/";
} else if (APP === "testing") {
	API_URL = "https://test-api-caterme.nibjar.com/reservation/";
	INVENOTRY_API_URL = "https://test-api-caterme.nibjar.com/storehouse/";
	FILE_UPLOAD_URL = "https://test-socket.nibjar.com/";
} else if (APP === "development") {
	// development environment
	API_URL = "http://localhost:3000/reservation/";
	INVENOTRY_API_URL = "http://localhost:3000/storehouse/";
	FILE_UPLOAD_URL = "http://localhost:3001/";
}

export { API_URL, FILE_UPLOAD_URL, INVENOTRY_API_URL };

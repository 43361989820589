import React, { forwardRef } from "react";
import "./payment-type.style.scss";
function PaymentTypeRadio(props, ref) {
	return (
		<div className="payment-option-wrapper">
			<div className="form-label">Payment Option</div>
			<div className={`payment-option-container ${props.containerClass}`}>
				{props.paymentType.map((p) => {
					return (
						<div className="payment-option-content" key={p}>
							<input
								type="radio"
								ref={ref}
								id={p}
								value={p}
								name={props.name}
								className="payment-option-checkbox"
							/>

							<label
								htmlFor={p}
								className="payment-option-label text-capitalize"
								id={props.id}
							>
								{p === "cash" && (
									<i className="fas fa-coins"></i>
								)}
								{p === "cheque" && (
									<i className="fa fa-money-check-alt"></i>
								)}
								{p === "transfer" && (
									<i className="fas fa-exchange-alt"></i>
								)}
								{p}
							</label>
						</div>
					);
				})}
			</div>
			<div className="validation-error">{props.error}</div>
		</div>
	);
}

export default forwardRef(PaymentTypeRadio);

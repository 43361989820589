/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import "./employee-venue-permission.style.scss";

export default function EmployeeVenuePermissionComponent(props) {
	const [role, setRole] = useState("s");
	const [venueName, setVenueName] = useState("");
	const [venueColor, setVenueColor] = useState("");
	const [status, setStatus] = useState(false);
	const style = {
		backgroundColor: venueColor,
	};

	useEffect(() => {
		setRole(props.role);
		setVenueName(props.venueName);
		setVenueColor(props.venueColor);
		setStatus(props.status);
	}, [props]);

	const venuePermissionOnChange = (event) => {
		props.changeEmpVenueStatus(event.target.value, props.venueEmpId);
	};

	return (
		<div className="venue-activation-wrapper bg-default">
			<div className="venue-color-container">
				<div className="venue-color-wrapper">
					<div className="venue-color" style={style}></div>
				</div>
			</div>
			<div className="venue-information-container">
				<div className="first venue-details text-capitalize">
					{venueName}
				</div>
				<div className="first venue-details text-capitalize">
					{role}
				</div>
				{/* <div className="venue-details text-capitalize">
                    Start Date:{startDate}
                </div> */}
				<div className="status venue-details">
					{/*  <VenuePermissionSwitch /> */}
					<span className="venue-permission-container">
						<input
							type="checkbox"
							id={props.venueEmpId}
							name="venue_status"
							disabled={props.type == "disabled"}
							className="venue-permission-checkbox"
							checked={status === "active"}
							value={status == "active" ? "inactive" : "active"}
							onChange={(e) => venuePermissionOnChange(e)}
						/>
						<label
							htmlFor={props.venueEmpId}
							className={`venue-permission-content-label text-capitalize ${props.type}`}
						>
							{status}
						</label>
					</span>
				</div>
			</div>
		</div>
	);
}

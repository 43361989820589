import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

// components
import Toggle from "../../../components/common/toggle/toggle.component";
import NepaliCalendarPopUp from "../../../components/common/calendar/nepali-cal-popup";

// for date purpose
import moment from "moment";
import NibjarNepaliDate from "../../../global/nibjar-nepali-date/nibjar-nepali-converter";
import { getMonthlyDateRange } from "../../../global/function";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
	fetchBookingByDateRange,
	fetchCalendarInfo,
	setCalendarDate,
} from "../../../redux/actions/calendar-info.action";

function CalendarController({ controlType = "weekly" }) {
	const dispatch = useDispatch();
	const { venueList } = useSelector((state) => state.venue);
	const [startDate, setStartDate] = useState(new Date());
	const calendarInfo = useSelector((state) => state.calendarInfo);

	useEffect(() => {
		if (controlType === "weekly") {
			if (calendarInfo.weekStart !== "") {
				setStartDate(new Date(calendarInfo.currDate));
				dispatch(
					fetchCalendarInfo(
						calendarInfo.weekStart,
						calendarInfo.weekEnd,
						calendarInfo.filters
					)
				);
			}
		}
	}, [
		dispatch,
		calendarInfo.weekStart,
		calendarInfo.weekEnd,
		calendarInfo.filters,
		calendarInfo.currDate,
		controlType,
	]);

	const dateOnchange = async (date) => {
		dispatch(setCalendarDate(date));
		if (controlType === "monthly") {
			let newViewDateObj = new NibjarNepaliDate(new Date(date)).getBS();
			const dateRange = getMonthlyDateRange(newViewDateObj);
			await dispatch(fetchBookingByDateRange(dateRange));
		}
	};

	function getCurrDate(selectedDate = null) {
		dispatch(setCalendarDate(selectedDate));
	}

	function getNextWeek() {
		let nextWeekDate = moment(calendarInfo.currDate).add(7, "days");
		dispatch(setCalendarDate(nextWeekDate));
	}

	function getPrevWeek() {
		let prevWeekDate = moment(calendarInfo.currDate).subtract(7, "days");

		dispatch(setCalendarDate(prevWeekDate));
	}

	const handleMonthChange = async (movement) => {
		let currentViewedDateObj = new NibjarNepaliDate(
			new Date(calendarInfo.currDate)
		).getBS();

		let newViewDateObj;
		switch (movement) {
			case "prevMonth":
				newViewDateObj = new NibjarNepaliDate(
					currentViewedDateObj.year,
					currentViewedDateObj.month,
					0 // this will return one month earlier last date
				).getBS();
				break;
			case "nextMonth":
				newViewDateObj = new NibjarNepaliDate(
					currentViewedDateObj.year,
					currentViewedDateObj.month + 1,
					1
				).getBS();
				break;
			case "currentMonth":
				newViewDateObj = new NibjarNepaliDate().getBS();
				break;
			default:
				break;
		}

		const dateRange = getMonthlyDateRange(newViewDateObj);
		// english date from nepali date in string format to set the currDate in redux
		if (movement === "currentMonth") {
			dispatch(setCalendarDate(moment()));
		} else {
			dispatch(setCalendarDate(dateRange.booking_from));
		}

		await dispatch(fetchBookingByDateRange(dateRange));
	};

	function _renderWeeklyController() {
		return (
			<div className="date-controller">
				<div className=" bg-default calendar-controller-container">
					<div className="prev week-change-btn" onClick={getPrevWeek}>
						<i className="fas fa-chevron-left"></i>
					</div>
					<div className="btn-divider" />
					<div className="today-btn" onClick={getCurrDate}>
						Today
					</div>
					<div className="btn-divider" />
					<div className="next week-change-btn" onClick={getNextWeek}>
						<i className="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		);
	}

	function _renderMonthlyController() {
		return (
			<div className="date-controller">
				<div className=" bg-default calendar-controller-container">
					<div
						className="prev week-change-btn"
						onClick={() => handleMonthChange("prevMonth")}
					>
						<i className="fas fa-chevron-left"></i>
					</div>
					<div className="btn-divider" />
					<div
						className="today-btn"
						onClick={() => handleMonthChange("currentMonth")}
					>
						Today
					</div>
					<div className="btn-divider" />
					<div
						className="next week-change-btn"
						onClick={() => handleMonthChange("nextMonth")}
					>
						<i className="fas fa-chevron-right"></i>
					</div>
				</div>
			</div>
		);
	}

	function _renderWeeklyHeader() {
		return (
			<div className="month-wrapper">
				{calendarInfo?.dateHeader?.toUpperCase()}
			</div>
		);
	}

	function _renderMonthlyHeader() {
		const viewedMonthYearNep = () => {
			const month = new NibjarNepaliDate(
				new Date(calendarInfo.currDate)
			).format("MMMM");
			const year = new NibjarNepaliDate(
				new Date(calendarInfo.currDate)
			).getYear();

			return `${month} ${year}`;
		};

		const viewedMonthYearEng = () => {
			const viewedDateObj = new NibjarNepaliDate(
				new Date(calendarInfo.currDate)
			).getBS();
			const dateRange = getMonthlyDateRange(viewedDateObj);

			const startDateMonth = moment(dateRange.booking_from).format("MMM");
			const startDateYear = moment(dateRange.booking_from).format("YYYY");

			const endDateMonth = moment(dateRange.booking_to).format("MMM");
			const endDateYear = moment(dateRange.booking_to).format("YYYY");

			return startDateYear === endDateYear
				? `${startDateMonth}/${endDateMonth} ${startDateYear}`
				: `${startDateMonth}/${endDateMonth} ${startDateYear}-${endDateYear}`;
		};
		return (
			<div className="flex px-4 items-center justify-between">
				<div className="month-wrapper">{viewedMonthYearNep()}</div>
				<div className="month-wrapper">{viewedMonthYearEng()}</div>
			</div>
		);
	}

	return (
		<div>
			{controlType === "weekly"
				? _renderWeeklyHeader()
				: _renderMonthlyHeader()}
			<div className="calendar-controller-wrapper">
				{controlType === "weekly"
					? _renderWeeklyController()
					: _renderMonthlyController()}

				<div className="calendar-controller">
					<div className="calendar-button-container">
						<NepaliCalendarPopUp
							dateSubmitFunction={dateOnchange}
						/>
					</div>
					<div className="calendar-button-container">
						<button className="button-primary">Eng Date</button>
						<div className="dropdown-wrapper calendar">
							<div className="bg-default dropdown">
								<Calendar
									className="calendar-conainer"
									onChange={dateOnchange}
									value={startDate}
								/>
							</div>
						</div>
					</div>
					<div className="calendar-button-container">
						<button className="button-primary">Filters</button>
						<div className="dropdown-wrapper calendar">
							<div className="bg-default dropdown venue">
								{venueList.map((venue, i) => {
									return (
										<Toggle
											index={i}
											label={venue.venue_name}
											name={venue.venue_info_id}
											key={venue.venue_info_id}
											checked={venue.checked}
											currDate={calendarInfo.currDate}
											color={venue.venue_color_code}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CalendarController;

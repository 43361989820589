import React from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { saveDuplicateEmployeeToCompany } from "../../redux/actions/employee-setting.action";
import { saveDuplicateCustomer } from "../../redux/actions/customer-setting.action";
import TextInputWithErrorComponent from "../common/text-input/text-input-with-error.component";

Modal.defaultStyles.overlay.backgroundColor = "rgba(102,107,122,0.2)";
/* 
    @Description
        Modal Component that show duplicate customer or user
    @propsTypes:
        isVisible[bool] // Checks visibillty value of modal 
        handleClose[function] // function to close modal
        handleShow[function] // function to open modal
        onClickNo[function] //function to run when no button clicked
        type[string] // either user or customer
        from[string] // either list or calendar only user for customer tyoe

*/

export default function DuplicateCustomerModal(props) {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			borderRadius: "10px",
		},
		h2: {
			color: "red",
		},
	};
	const dispatch = useDispatch();
	const { duplicateUser } = useSelector((state) => state.employeeSetting);
	const { duplicateCustomer } = useSelector((state) => state.customerSetting);

	function onClickYes() {
		props.handleClose();
		if (props.type === "user") {
			dispatch(saveDuplicateEmployeeToCompany(duplicateUser));
		} else {
			// props.onClickYes(props.duplicateData);
			dispatch(saveDuplicateCustomer(props.from));
		}
	}

	function onClickNo() {
		props.onClickNo();
		props.handleClose();
	}

	const returnView = () => {
		if (props.type === "user") {
			return (
				<div className="form-body duplication">
					<div className="form-panel-left">
						<div className="image-container">
							{duplicateUser?.user_image === null ? (
								<i className="fas fa-user-alt fa-10x"></i>
							) : (
								<img
									src={duplicateUser?.user_image}
									alt="profile pic"
								/>
							)}
						</div>
					</div>
					<div className="form-panel-right">
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateUser?.first_name}
								label="First Name"
								disabled
							/>
						</div>
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateUser?.last_name}
								label="Last Name"
								disabled
							/>
						</div>
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateUser?.user_mobile}
								label="Primary Mobile*"
								disabled
							/>
						</div>
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateUser?.user_email}
								label="Email "
								disabled
								type="email"
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="form-body duplication">
					<div className="form-panel-left">
						<div className="image-container">
							{duplicateCustomer?.customer_image === null ? (
								<i className="fas fa-user-alt fa-10x"></i>
							) : (
								<img
									src={duplicateCustomer?.customer_image}
									alt="profile pic"
								/>
							)}
						</div>
					</div>
					<div className="form-panel-right">
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateCustomer?.customer_fname}
								label="First Name"
								disabled
							/>
						</div>
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateCustomer?.customer_lname}
								label="Last Name"
								disabled
							/>
						</div>
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={
									duplicateCustomer?.customer_mobile
								}
								label="Primary Mobile*"
								disabled
							/>
						</div>
						<div className="form-input-container">
							<TextInputWithErrorComponent
								variant="form-lg"
								defaultValue={duplicateCustomer?.customer_email}
								label="Email"
								disabled
								type="email"
							/>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<Modal
			ariaHideApp={false}
			isOpen={props.isVisible}
			onAfterOpen={props.handleShow}
			onRequestClose={props.handleClose}
			style={customStyles}
			contentLabel="Example Modal"
		>
			<div className="form-main-content bg-default">
				<div className="form-header">
					<div className="form-sub-title">
						Is this your {props.type}?
					</div>
				</div>
				{returnView()}
				<div className="form-footer two">
					<button onClick={onClickNo} className="button-primary">
						No
					</button>

					<button className="button-primary" onClick={onClickYes}>
						Yes
					</button>
				</div>
			</div>
		</Modal>
	);
}

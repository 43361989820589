/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom";

// components
import CalendarDetail from "../../../components/calendar/calendar-event/calendar-event.component";
import CalendarController from "../calendar-controller/calendar-controller.component";
import Loader from "../../../components/loader/loader.component";

// redux
import { useSelector } from "react-redux";

// global functions
import { isEmptyObject } from "../../../global/function";

// import { socket } from "../../../Socket";

function CalendarUpcoming() {
	const calendarInfo = useSelector((state) => state.calendarInfo);
	const history = useHistory();

	// useEffect for socket
	// useEffect(() => {
	// 	socket.on("bookingCreated", (data) => {
	// 		if (data.success) {
	// 			if (calendarInfo.weekStart !== "") {
	// 				dispatch(
	// 					fetchCalendarInfo(
	// 						calendarInfo.weekStart,
	// 						calendarInfo.weekEnd,
	// 						calendarInfo.filters,
	// 						"socket"
	// 					)
	// 				);
	// 			}
	// 		}
	// 	});

	// 	socket.on("bookingUpdated", (data) => {
	// 		if (data.success) {
	// 			if (calendarInfo.weekStart !== "") {
	// 				dispatch(
	// 					fetchCalendarInfo(
	// 						calendarInfo.weekStart,
	// 						calendarInfo.weekEnd,
	// 						calendarInfo.filters,
	// 						"socket"
	// 					)
	// 				);
	// 			}
	// 		}
	// 	});

	// 	socket.on("connect_error", (err) => {
	// 		console.log(`connect_error due to ${err.message}`);
	// 	});
	// }, [dispatch]);

	function onClickChild(bookingDetails) {
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${bookingDetails.event_id}`,
			state: {
				event_info_id: bookingDetails.event_info_id,
				booking_info_id: bookingDetails.booking_info_id,
			},
		});
	}

	return (
		<div className="inner-container full">
			{calendarInfo.isLoading && <Loader />}
			<CalendarController controlType="weekly" />
			<div className="calendar-content">
				{!isEmptyObject(calendarInfo.calendarInfo) &&
					Object.keys(calendarInfo.calendarInfo.upcomingBooking).map(
						(date) => {
							return (
								<CalendarDetail
									parentCallBack={onClickChild}
									date={date}
									key={date}
									bookingData={
										calendarInfo.calendarInfo
											.upcomingBooking[date]
									}
									type="upcoming"
								/>
							);
						}
					)}
			</div>
		</div>
	);
}

export default CalendarUpcoming;

import React, { useState, useEffect } from "react";
import "./payment-history-table.style.scss";
import "./table.scss";
import { vatHistoryHeader } from "../../global/table-headers";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deleteVat } from "../../redux/actions/payment-action";
import { useHistory } from "react-router-dom";
import { sessionInfo, rupeeConverter } from "../../global/function";

import ConfirmationModal from "../../components/modals/confirmation-modal.component";

import NibjarNepaliDate from "../../global/nibjar-nepali-date/nibjar-nepali-converter";

export default function VatHistoryTable({ vatHistoryList }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const [detailsShown, setDetailShown] = useState([]);
	const [company_role, setCompanyRole] = useState();
	const [confirmationModalFlag, setConfirmationModalFlag] = useState(false);

	const handleDeleteVatPaymentModalShow = () =>
		setConfirmationModalFlag(true);

	const handleDeleteVatPaymentModalClose = () => {
		setConfirmationModalFlag(false);
	};

	useEffect(() => {
		async function fetchSessionData() {
			await sessionInfo().then((data) => {
				setCompanyRole(data.company_role);
			});
		}

		fetchSessionData();
	}, []);

	const toggleNew = (rowId) => {
		const shownState = detailsShown.slice();
		const index = shownState.indexOf(rowId);
		if (index >= 0) {
			shownState.splice(index, 1);

			setDetailShown(shownState);
		} else {
			shownState.push(rowId);
			setDetailShown(shownState);
		}
	};

	const onClickStyle = {
		cursor: "pointer",
	};

	const deleteVatPayment = (id, history) => {
		let bookings = history.bookings.map((vat) => {
			return {
				venue_info_id: vat.venue_info_id,
			};
		});

		dispatch(deleteVat(id, bookings));
		setConfirmationModalFlag(false);
	};

	const viewBooking = (booking) => {
		history.push({
			pathname: "/booking-event",
			search: `?event_id=${booking.event_id}`,
			state: {
				event_info_id: booking.event_info_id,
				// booking_info_id: booking.booking_info_id,
			},
		});
	};
	const mobileVatView = (info, i) => {
		const vatDateEng = moment(info["vat_date"]).format("YYYY-MM-DD");

		let decreasedMonth = moment(vatDateEng)
			.subtract(1, "month")
			.format("YYYY-MM-DD");
		let a = decreasedMonth.split("-");
		let vatDateNep = new NibjarNepaliDate(new Date(a[0], a[1], a[2]));

		return (
			<div
				className={`h-119 w-135 bodered rounded-sm relative text-fontSemiDark`}
				key={i}
				onClick={() => {
					viewBooking(info);
				}}
			>
				<span className=" absolute h-23 w-119 top-10px right-10px border-b pb-7px border-fontWhite text-sm text-right">
					{vatDateNep.format("DD MMMM")} {vatDateNep.format("YYYY")}
				</span>
				<span className=" absolute h-10 w-20 top-10px left-10px text-xs">
					{info.vat_payment_id}
				</span>

				<span className=" absolute h-10 w-119 bottom-44px right-10px text-xs text-right overflow">
					#{info.vat_bill_number}
				</span>
				<span className=" absolute h-10 w-119 bottom-30px right-10px text-xs text-right capitalize">
					{info.customer_name}
				</span>
				<span
					className={`absolute h-12 bottom-10px right-10px left-10px text-xs text-right capitalize text-primaryBlack`}
				>
					{rupeeConverter(info.vat_amount.toFixed(2))}
				</span>
			</div>
		);
	};

	return (
		<div className="table-wrapper">
			<table
				cellPadding={0}
				cellSpacing={0}
				className="table-container md:hidden"
			>
				<thead>
					<tr>
						{vatHistoryHeader.map(({ name }) => (
							<td className="table-header" key={name}>
								<div className="table-header-container">
									{name}
								</div>
							</td>
						))}
					</tr>
				</thead>
				{vatHistoryList.length > 0 &&
					vatHistoryList.map((history, key) => {
						const vatDateEng = moment(history["vat_date"]).format(
							"YYYY-MM-DD"
						);

						let decreasedMonth = moment(vatDateEng)
							.subtract(1, "month")
							.format("YYYY-MM-DD");
						let a = decreasedMonth.split("-");
						let vatDateNep = new NibjarNepaliDate(
							new Date(a[0], a[1], a[2])
						);

						return (
							<tbody key={key}>
								<tr
									className="data-table-row bg-default normal text-capitalize"
									key={history.vat_payment_id}
									onClick={() =>
										toggleNew(history.vat_payment_id)
									}
									style={onClickStyle}
								>
									{vatHistoryHeader.map(({ field }) => (
										<td
											className={`body-table-cell ${field}`}
											key={field}
											id={field}
										>
											{field === "nep_date" ? (
												<span>
													{vatDateNep.format(
														"DD MMMM"
													)}{" "}
													{vatDateNep.format("YYYY")}
												</span>
											) : field === "vat_amount" ? (
												rupeeConverter(
													history[field].toFixed(2)
												)
											) : (
												history[field]
											)}
										</td>
									))}
								</tr>
								{detailsShown.includes(
									history["vat_payment_id"]
								) && (
									<tr
										className="text-capitalize"
										id={`permission-row-${history["vat_payment_id"]}`}
									>
										<td colSpan="7">
											<div className="payment-detail-dropdown-wrapper">
												{company_role !== "viewer" && (
													<table width="100%">
														<thead>
															<tr>
																<th>
																	Booking ID{" "}
																</th>
																<th>
																	Booking Date
																</th>
																<th>
																	Booking Time
																</th>
															</tr>
														</thead>
														<tbody>
															{history[
																"bookings"
															].map(
																(
																	booking,
																	key
																) => (
																	<tr
																		key={
																			key
																		}
																	>
																		<td>
																			{
																				booking.booking_id
																			}
																		</td>
																		<td>
																			{
																				booking.booked_date_english
																			}
																		</td>
																		<td>
																			{
																				booking.booked_time
																			}
																		</td>
																	</tr>
																)
															)}
														</tbody>
													</table>
												)}
												<div className="payment-history-button-wrapper ">
													<button
														className="button-primary"
														onClick={() => {
															viewBooking(
																history
															);
														}}
													>
														View Event
													</button>
													<button
														className="button-wrapper payment-history-edit-button"
														onClick={
															handleDeleteVatPaymentModalShow
														}
														disabled={
															company_role !==
															"admin"
														}
													>
														<i className="fas fa-trash-alt"></i>
													</button>
												</div>
												<ConfirmationModal
													type="deleteVatPayment"
													isVisible={
														confirmationModalFlag
													}
													handleClose={
														handleDeleteVatPaymentModalClose
													}
													handleShow={
														handleDeleteVatPaymentModalShow
													}
													onClickConfirmation={() =>
														deleteVatPayment(
															history.vat_payment_info_id,
															history
														)
													}
												/>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						);
					})}
			</table>
			<div className="flex-wrap gap-15px py-15px hidden md:flex">
				{vatHistoryList.map((list, index) =>
					mobileVatView(list, index)
				)}
			</div>
		</div>
	);
}

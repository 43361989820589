import {
	SET_TEMP_BOOKING,
	SAVE_BOOKING,
	DELETE_BOOKING,
	SET_SELECTED_CUSTOMER,
	CLEAR_ALL_BOOKING_INFO,
} from "../action-types";

export const setBookings = (bookings) => {
	return {
		type: SET_TEMP_BOOKING,
		payload: bookings,
	};
};

//
export const saveBooking = (bookingInfo) => {
	return {
		type: SAVE_BOOKING,
		payload: bookingInfo,
	};
};

export const deleteBooking = (tempBookingId) => {
	return {
		type: DELETE_BOOKING,
		payload: tempBookingId,
	};
};

export const setSelectedCustomer = (customer) => {
	return {
		type: SET_SELECTED_CUSTOMER,
		payload: customer,
	};
};

export const clearAllBookings = () => {
	return {
		type: CLEAR_ALL_BOOKING_INFO,
	};
};
